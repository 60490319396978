<template>
  <v-main>
    <navigation v-if="!liffUser"/>
    <guide-tour config-key="reschedule"/>
    <reschedule-setting v-if="clinicUser" class="pl-4"/>
    <v-tabs v-model="tab" right>
      <v-tab>未處理</v-tab>
      <v-tab>已處理</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab" touchless>
      <v-tab-item>
        <reschedule-data-table ref="unConcat"/>
      </v-tab-item>
      <v-tab-item>
        <reschedule-data-table ref="concat" kind="2"/>
      </v-tab-item>
    </v-tabs-items>
  </v-main>
</template>

<script>
import Navigation from "../components/Navigation";
import { mapGetters } from "vuex";
import RescheduleDataTable from '../components/Reschedule/RescheduleDataTable';
import GuideTour from "../components/utils/GuideTour.vue";
import RescheduleSetting from "../components/Reschedule/RescheduleSetting.vue";

export default {
  name: "Reschedule",

  components: {RescheduleSetting, GuideTour, RescheduleDataTable, Navigation},

  data() {
    return {
      tab: null
    }
  },

  computed: {
    ...mapGetters([
      'liffUser',
      'clinicUser'
    ])
  },
  watch: {
    tab(v) {
      if (v === 0 && this.$refs.unConcat) {
        this.$refs.unConcat.getRescheduleList()
      } else if (v === 1 && this.$refs.concat) {
        this.$refs.concat.getRescheduleList()
      }
    }
  }
}
</script>

<style scoped>

</style>
