<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="4" md="2" class="text-center outlined">{{ $t('FreeTimeSetting.date') }}</v-col>
      <v-col cols="4" md="5" class="text-center outlined">{{ $t('FreeTimeSetting.everyWeek') }}</v-col>
      <v-col cols="4" md="5" class="text-center outlined">{{ $t('FreeTimeSetting.single') }}</v-col>
    </v-row>
    <v-row v-for="d in dateList" dense :key="d.date">
      <v-col cols="4" md="2" class="outlined d-flex text-center align-center justify-center">
        {{ d.date }}<br>{{ weekdays[d.weekday] }}
      </v-col>
      <v-col cols="4" md="5" class="outlined">
        <v-row v-if="manualWeekly[d.weekday].length" class="" style="margin: 0;">
          <v-col cols="9" md="10" lg="11" class="px-1">
            <v-chip
              :class="[{'text-decoration-line-through' : freeTimeClosed.includes(d.date)}, 'mr-2 my-1']"
              :small="$vuetify.breakpoint.width < 1040"
              v-for="(time, key) in manualWeekly[d.weekday]"
              :key="key"
              v-text="time"
              :color="freeTimeClosed.includes(d.date) ? '#FF4444' : 'primary'"
              :text-color="freeTimeClosed.includes(d.date) ? 'white' : 'black'"
            ></v-chip>
          </v-col>
          <v-col cols="3" md="2" lg="1" class="text-center px-1" align-self="center">
            <v-btn icon small @click="toggleClosed(d.date)">
              <v-icon>{{ freeTimeClosed.includes(d.date) ? 'mdi-cached' : 'mdi-delete' }}</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="4" md="5" class="outlined">
        <v-select
          v-model="freeTimeSingle[d.date]"
          :items="timeOptions"
          :small-chips="$vuetify.breakpoint.width < 1040"
          :clearable="!$vuetify.breakpoint.xsOnly"
          multiple
          flat
          hide-details
          class="select-single py-2 mt-0"
          color="primary"
        >
          <template v-slot:selection="{ attrs, item, select, selected }">
            <v-chip
              v-bind="attrs"
              :input-value="selected"
              :small="$vuetify.breakpoint.width < 1040"
              @click="select"
              color="primary"
              text-color="black"
            >
              {{ item }}
            </v-chip>
          </template>
        </v-select>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ManualSingle",

  props: {
    timeOptions: {
      type: Array,
      required: true
    },
    dateList: {
      type: Array,
      required: true
    },
    weekdays: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      freeTimeClosed: [],
      freeTimeSingle: {}
    }
  },

  computed: {
    ...mapGetters([
      'manualSetting',
      'manualWeekly',
    ]),
  },

  watch: {
    manualSetting: {
      handler(v) {
        if (v && v.weekly) {
          this.freeTimeSingle = Array.isArray(v.single) ? {} : JSON.parse(JSON.stringify(v.single))
        }
        if (v && v.closed) {
          this.freeTimeClosed = JSON.parse(JSON.stringify(v.closed))
        }
      },
      deep: true,
      immediate: true
    },
    freeTimeSingle: {
      handler(v) {
        this.processManualSingle(v)
      },
      deep: true
    },
    freeTimeClosed: {
      handler(v) {
        this.processManualClosed(v)
      },
      deep: true
    }
  },

  methods: {
    ...mapActions({
      processManualSingle: 'processManualSingle',
      processManualClosed: 'processManualClosed',
    }),
    toggleClosed(date) {
      const index = this.freeTimeClosed.indexOf(date)
      if (index !== -1) {
        this.freeTimeClosed.splice(index, 1)
      } else {
        this.freeTimeClosed.push(date)
      }
    }
  }
}
</script>

<style scoped>
.outlined {
  border: 1px solid #707070;
}
</style>
