<template>
  <v-card class="py-8 px-8" rounded="xl">
    <v-overlay :value="loading" absolute z-index="3">
      <v-progress-circular indeterminate size="64"/>
    </v-overlay>
    <v-card-actions>
      <v-spacer/>
      <v-btn color="grey" outlined class="text-subtitle-2 rounded-lg" @click="onClose">
        取消
      </v-btn>
      <v-btn color="teal lighten-2" class="text-subtitle-2 rounded-lg white--text" @click="onConfirm">
        儲存
      </v-btn>
    </v-card-actions>
    <v-form ref="form" v-model="formValid">
      <v-row>
        <!-- 基本資料區 -->
        <v-col cols="12" md="6">
          <upload-image-dialog
            size="200"
            img-class="py-2 px-2"
            color="white"
            :disabled="avatarLoading"
            @submit="$emit('uploadDoctorAvatar', $event)"
          >
            <v-overlay :value="avatarLoading" absolute z-index="3">
              <v-progress-circular indeterminate size="64"/>
            </v-overlay>
            <img
              :src="doctor.profile"
              alt=""
              class="grey"
              style="height: 100%; z-index: 2;"
            >
          </upload-image-dialog>
          <h3 class="text-subtitle-1 font-weight-bold mb-8 mt-4">
            基本資料
          </h3>
          <div class="d-flex flex-column mb-6" style="gap:0.6rem">
            <div class="text-subtitle-1 grey--text text--darken-2">
              姓名*
            </div>
            <v-text-field
              v-model="name"
              placeholder="請輸入姓名（限制12字內）"
              dense
              hide-details="auto"
              outlined
              required
              :rules="[rules.required]"
              maxlength="12"
            >
              <template #prepend-inner>
                <v-icon class="px-1" color="grey">
                  mdi-account
                </v-icon>
              </template>
            </v-text-field>
          </div>

          <div class="d-flex flex-column mb-6" style="gap:0.6rem">
            <div class="text-subtitle-1 grey--text text--darken-2">
              電子信箱
            </div>
            <v-text-field
              v-model="email"
              placeholder="請輸入信箱"
              dense
              hide-details="auto"
              outlined
              required
              :rules="[rules.email]"
            >
              <template #prepend-inner>
                <v-icon class="px-1" color="grey">
                  mdi-email
                </v-icon>
              </template>
            </v-text-field>
          </div>
          <h3 class="text-subtitle-1 font-weight-bold mb-8">
            看診資訊
          </h3>
          <div class="d-flex flex-column mb-6" style="gap:0.6rem">
            <div class="text-subtitle-1 grey--text text--darken-2">
              服務區域 (系統自動判斷值診所位置)
            </div>
            <div class="text-body-2 grey--text text--darken-4">
              {{ serviceArea.join(",") }}
            </div>
          </div>
          <h3 class="text-subtitle-1 font-weight-bold mb-8">
            服務診所
          </h3>
          <draggable
            v-model="clinicList"
            tag="ul"
            class="px-0"
            handle=".draggable"
            animation="200"
            @start="drag = true"
            @end="drag = false"
          >
            <v-slide-y-transition v-for="(e ,i) in clinicList" :key="JSON.stringify(e)">
              <v-list-item class="grey lighten-4 rounded-lg mb-5" dense>
                <v-btn text class="px-0" small style="min-width: fit-content">
                  <v-icon size="20" class="draggable">
                    mdi-cursor-move
                  </v-icon>
                </v-btn>
                <v-list-item-title class="flex-grow-1 px-2">
                  <v-icon :color="e.color" size="10" class="mx-1">
                    mdi-circle
                  </v-icon>
                  {{ e.clinic_name }}
                </v-list-item-title>
                <delete-clinic-dialog :clinic="e" @confirm="deleteClinic(i)"/>
              </v-list-item>
            </v-slide-y-transition>
          </draggable>
          <add-clinic-dialog class="mt-9" @change="addClinic"/>
        </v-col>
        <!-- 社群連結區 -->
        <v-col cols="12" md="6">
          <div>
            <h3 class="text-subtitle-1 font-weight-bold mb-8">
              LINE 官方帳號
            </h3>
            <v-btn
              v-if="!hasGuideLinePermission"
              color="error"
              class="text-none rounded-md my-3"
              block
              :href="$enum.DENTCO_CS_LINE"
              target="_blank"
            >
              此為付費功能，聯繫客服升級付費方案
            </v-btn>
            <div :style="{opacity: hasGuideLinePermission > 0 ? 1 : 0.2}">
              <v-radio-group
                v-model="lineLink.line_page_status"
                style="width: 100%;"
                class="px-2 mt-0"
              >
                <v-radio
                  label="不開放"
                  :value="0"
                  :disabled="!hasGuideLinePermission"
                ></v-radio>
                <v-radio
                  label="使用串接帳號（建議選項）"
                  :value="1"
                  :disabled="!hasGuideLinePermission"
                  @click="checkChannelExist"
                ></v-radio>
                <v-radio
                  label="自行輸入連結"
                  :value="2"
                  :disabled="!hasGuideLinePermission"
                ></v-radio>
                <v-text-field
                  v-model="lineLink.url"
                  :disabled="!(hasGuideLinePermission && lineLink.line_page_status === 2)"
                  class="pl-6"
                  outlined
                  dense
                  placeholder="請輸入連結（注意：自行輸入將無法從小幫手系統追蹤來源）"
                  hide-details="auto"
                >
                  <template #prepend-inner>
                    <v-icon class="px-1" color="grey">
                      {{ getSocialIcon(lineLink.label) }}
                    </v-icon>
                  </template>
                </v-text-field>
              </v-radio-group>
            </div>
          </div>
          <div>
            <h3 class="text-subtitle-1 font-weight-bold mb-8">
              社群連結
            </h3>
            <v-btn
              v-if="!hasAdvPermission"
              color="error"
              class="text-none rounded-md my-3"
              block
              :href="$enum.DENTCO_CS_LINE"
              target="_blank"
            >
              此為付費功能，聯繫客服升級付費方案
            </v-btn>
            <div
              v-for="link in socialLinks"
              :key="link.label"
              class="d-flex flex-column mb-6"
              style="gap:0.6rem"
              :style="{
              opacity: hasAdvPermission > 0 ? 1 : 0.2
            }"
            >
              <div class="text-subtitle-1 grey--text text--darken-2">
                {{ link.label }}
              </div>
              <v-text-field
                v-model="link.name"
                dense
                hide-details
                placeholder="請輸入欲顯示的名稱，若無則顯示標題名稱"
                outlined
                :disabled="!hasAdvPermission"
                clearable
                maxlength="18"
              />
              <v-text-field
                v-model="link.url"
                dense
                hide-details
                :placeholder="getHint(link.label)"
                outlined
                :disabled="!hasAdvPermission"
              >
                <template #prepend-inner>
                  <v-icon class="px-1" color="grey">
                    {{ getSocialIcon(link.label) }}
                  </v-icon>
                </template>
              </v-text-field>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-form>
    <v-card-actions>
      <v-spacer/>
      <v-btn color="grey" outlined class="text-subtitle-2 rounded-lg" @click="onClose">
        取消
      </v-btn>
      <v-btn color="teal lighten-2" class="text-subtitle-2 rounded-lg white--text" @click="onConfirm">
        儲存
      </v-btn>
    </v-card-actions>

    <v-dialog v-model="lineAlertDialog" max-width="400">
      <v-card>
        <v-card-title>
          <div>
            您沒有串接的LINE官方賬號，請輸入其他連結，或是詢問 <a :href="$enum.DENTCO_CS_LINE"
                                                                target="_blank">客服人員</a>
          </div>
        </v-card-title>
      </v-card>
    </v-dialog>
    <v-dialog v-model="emailAlertDialog" max-width="fit-content">
      <v-card class="px-12 py-6">
        <v-card-title class="justify-center font-weight-bold text-h5">
          您尚未輸入電子信箱
        </v-card-title>
        <v-card-text class="red--text">
          建議您輸入「電子信箱」不漏掉最新牙醫小幫手資訊
        </v-card-text>
        <v-card-actions class="d-flex flex-column" style="gap: 8px;">
          <div style="width: 100%;">
            <v-btn color="primary" block @click="emailAlertDialog = false; onConfirm(false);">
              儲存當前設定，暫時不輸入信箱
            </v-btn>
          </div>
          <div style="width: 100%;">
            <v-btn color="grey-4" dark block @click="emailAlertDialog = false">繼續編輯</v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import draggable from 'vuedraggable';
import DeleteClinicDialog from './DeleteClinicDialog.vue';
import AddClinicDialog from '@/components/settings/doctor/v2/AddClinicDialog.vue';
import { getSocialIcon } from "@/utils/tool";
import { createDoctorClinicAPI, getMessageSettingAPI } from "@/api";
import { mapGetters } from "vuex";
import UploadImageDialog from "@/components/utils/UploadImageDialog.vue";

export default {
  name: 'DoctorForm',

  components: {UploadImageDialog, DeleteClinicDialog, AddClinicDialog, draggable},

  props: {
    show: {
      type: Boolean,
      default: false
    },
    hasAdvPermission: {
      type: Boolean,
      default: false
    },
    hasGuideLinePermission: {
      type: Boolean,
      default: false
    },
    doctor: {
      type: Object,
      default: () => {
        return {
          name: '',
          email: '',
          serviceArea: [],
          clinicList: [],
          socialLinks: []
        };
      }
    },
    avatarLoading: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      loading: false,
      drag: true,
      formValid: true,
      name: '',
      email: '',
      serviceArea: [],
      rules: {
        required: value => !!value || '*請輸入有效文字，不可輸入空白內容',
        email: v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || '*請輸入正確的Email格式'
      },
      clinicList: [],
      lineAlertDialog: false,
      emailAlertDialog: false,

      defaultSocialLinksStructure: [
        {
          label: 'Instagram',
          name: '',
          url: ''
        },
        {
          label: 'Facebook',
          name: '',
          url: ''
        },
        {
          label: 'Tiktok',
          name: '',
          url: ''
        },
        {
          label: 'YouTube',
          name: '',
          url: ''
        },
        {
          label: '其他連結1',
          name: '',
          url: ''
        },
        {
          label: '其他連結2',
          name: '',
          url: ''
        },
        {
          label: '其他連結3',
          name: '',
          url: ''
        }
      ],
      socialLinks: [],
      defaultLineLink: {
        label: 'LINE',
        name: '',
        url: '',
        line_page_status: 0
      },
      lineLink: {}
    };
  },

  computed: {
    ...mapGetters([
      'axiosParamData',
    ]),
  },

  watch: {
    show: {
      handler(show) {
        console.log(show)
        if (show && this.doctor) {
          this.name = this.doctor.alias || this.doctor.doctor_name;
          this.email = this.doctor.email;
          if (this.doctor.social_item_structure.length) {
            this.lineLink = {
              ...this.defaultLineLink,
              ...this.doctor.social_item_structure[0]
            };
            if (this.doctor.social_item_structure.length > 1) {
              this.socialLinks = JSON.parse(JSON.stringify(this.doctor.social_item_structure.slice(1)));
            } else {
              this.socialLinks = JSON.parse(JSON.stringify(this.defaultSocialLinksStructure));
            }
          } else {
            this.socialLinks = JSON.parse(JSON.stringify(this.defaultSocialLinksStructure));
            this.lineLink = JSON.parse(JSON.stringify(this.defaultLineLink));
          }
          this.serviceArea = this.doctor.service_area;
          this.clinicList = JSON.parse(JSON.stringify(this.doctor.clinics));
        }
      },
      immediate: true,
      deep: true
    }
  },

  methods: {
    getSocialIcon,
    getHint(label) {
      switch (label) {
        case 'YouTube':
          return '請輸入單部YouTube影片連結';
        default:
          return '請輸入' + label + '連結';
      }
    },
    async checkChannelExist() {
      const res = await getMessageSettingAPI(this.axiosParamData)
      const hasChannel = !!res.data.own_channel
      if (!hasChannel) {
        this.lineAlertDialog = true
        this.$nextTick(() => {
          this.lineLink.line_page_status = 0
        });
      }
    },
    onConfirm(checkEmail = true) {
      this.$refs.form.validate()
      if (!this.formValid) {
        return
      }

      if (checkEmail && !this.email) {
        this.emailAlertDialog = true
        return
      }

      this.loading = true
      this.$emit('update', {
        data: {
          alias: this.name,
          email: this.email,
          social_item_structure: [this.lineLink, ...this.socialLinks],
          clinics: this.clinicList
        },
        callback: (status) => {
          this.loading = false
          if (status === 'success') {
            this.onClose();
          } else {
            alert('更新失敗，請再試一次')
          }
        }
      });
    },
    onClose() {
      this.$emit('update:show', false);
    },
    addClinic(clinic) {
      this.clinicList.push(clinic);
    },
    deleteClinic(index) {
      this.clinicList.splice(index, 1);
    }
  }
};
</script>

<style scoped>
.flip-list-move {
  transition: transform 0.5s;
}
</style>
