<template>
  <div>
    <div class="d-flex">
      <div>
        <div class="bullet text-subtitle-1 black--text">官網治療分類</div>
        <div style="color: var(--v-grey-5-base);">患者透過「牙醫小幫手線上預約平台」搜尋引擎所顯示的治療項目類別。</div>
      </div>
      <div class="ml-auto">
        <v-btn v-if="!isEdit" color="tiffany-7" dark :disabled="loading" @click="edit">編輯</v-btn>
      </div>
    </div>
    <v-sheet max-height="400" :height="loading ? 400 : 'unset'" color="grey-8" class="pa-5 overflow-y-auto mt-2" style="position: relative;">
      <v-overlay :value="loading" absolute>
        <v-progress-circular
          indeterminate
          size="32"
        ></v-progress-circular>
      </v-overlay>
      <div v-if="!loading" v-for="(treatment, idx) in treatmentList" :class="{'mt-2': idx}">
        <div>{{ treatment.name }}</div>
        <div v-for="lv2 in treatment.lv2_appointment_items">
          <v-checkbox
            v-model="selectedTreatment"
            :value="lv2.id"
            hide-details
            dense
            class="mt-0"
            :disabled="!isEdit"
          >
            <template v-slot:label>
              <span style="font-size: 14px;">{{ lv2.name }}</span>
            </template>
          </v-checkbox>
        </div>
      </div>
    </v-sheet>

    <div v-if="isEdit" class="d-flex mt-2">
      <v-spacer/>
      <v-btn outlined @click="cancel">取消</v-btn>
      <v-btn color="red-6" dark class="ml-2" @click="save">儲存</v-btn>
    </div>
  </div>
</template>

<script>
import { getDoctorSearchTreatmentSettingAPI, updateDoctorSearchTreatmentSettingAPI } from "@/api";
import { mapGetters } from "vuex";

export default {
  name: "SearchTreatmentSetting",

  props: {
    treatmentList: {
      type: Array,
      required: true
    },
    selectCode: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      selectedTreatment: [],
      originalSelectedTreatmentList: [],
      isEdit: false,
      loading: false
    }
  },

  computed: {
    ...mapGetters([
      'axiosParamData',
      'clinicUser',
    ]),
  },

  watch: {
    selectCode: {
      immediate: true,
      handler() {
        this.cancel();
        this.getDoctorSearchTreatmentSetting()
      }
    }
  },

  methods: {
    edit() {
      this.originalSelectedTreatmentList = JSON.parse(JSON.stringify(this.selectedTreatment))
      this.isEdit = true
    },
    cancel() {
      this.selectedTreatment = JSON.parse(JSON.stringify(this.originalSelectedTreatmentList))
      this.isEdit = false
    },
    save() {
      this.loading = true
      const data = {
        arry_item_lv2_id: this.selectedTreatment
      }
      if (this.clinicUser) {
        data.doctor_code = this.selectCode
      } else {
        data.doctor_code = this.axiosParamData.doctor_code
      }
      updateDoctorSearchTreatmentSettingAPI(data).then(() => {
        this.getDoctorSearchTreatmentSetting()
        this.isEdit = false
      }).catch(e => {
        console.error(e)
        alert('儲存失敗')
        this.loading = false
      })
    },
    getDoctorSearchTreatmentSetting() {
      this.loading = true
      const doctor_code = this.clinicUser ? this.selectCode : this.axiosParamData.doctor_code
      getDoctorSearchTreatmentSettingAPI(doctor_code, this.axiosParamData).then(res => {
        this.selectedTreatment = res.data?.arry_item_lv2_id || []
        this.loading = false
      }).catch(e => {
        console.error(e)
        alert('取得設定失敗')
      })
    }
  }
}
</script>

<style scoped>

</style>