<template>
  <v-card class="py-8 px-8" rounded="xl">
    <v-row>
      <!-- 基本資料區 -->
      <v-col cols="12" class="py-2">
        <div class="d-flex justify-space-between mb-8">
          <h6 class="text-subtitle-1 font-weight-bold">
            擅長治療
          </h6>
          <div class="d-flex justify-end" style="gap: 8px">
            <v-btn
              color="grey"
              outlined
              class="text-subtitle-2 rounded-lg"
              @click="onClose"
            >
              取消
            </v-btn>
            <v-btn
              color="teal lighten-2"
              class="text-subtitle-2 rounded-lg white--text"
              @click="onConfirm"
            >
              儲存
            </v-btn>
          </div>
        </div>
        <v-form ref="form" v-model="valid">
          <draggable
            v-model="hashtagItems"
            tag="ul"
            class="px-0"
            handle=".draggable"
            animation="200"
            @start="drag = true"
            @end="drag = false"
          >
            <v-slide-y-transition v-for="(e, i) in hashtagItems" :key="i">
              <v-list-item class="grey lighten-4 rounded-lg mb-5" dense>
                <v-btn text class="px-0" small style="min-width: fit-content">
                  <v-icon size="20" class="draggable">
                    mdi-cursor-move
                  </v-icon>
                </v-btn>
                <v-list-item-title class="flex-grow-1 px-2">
                  <div class="d-flex justify-center py-6" style="gap: 0.6rem">
                    <!--                    <v-select-->
                    <!--                      v-model="e.category"-->
                    <!--                      :items="treatmentCategory"-->
                    <!--                      filled-->
                    <!--                      dense-->
                    <!--                      hide-details-->
                    <!--                      outlined-->
                    <!--                      item-value="id"-->
                    <!--                      item-text="name"-->
                    <!--                      required-->
                    <!--                      placeholder="選擇擅長治療"-->
                    <!--                      background-color="white"-->
                    <!--                      aria-required="true"-->
                    <!--                      style="width: 45%"-->
                    <!--                      @change="onChangeCategory(e, $event)"-->
                    <!--                    />-->
                    <v-text-field
                      v-model="e.title"
                      background-color="white"
                      style="width: 45%"
                      placeholder="請輸入擅長治療名稱（限制20字內）"
                      dense
                      :rules="[rules.required]"
                      hide-details="auto"
                      outlined
                      required
                      aria-required="true"
                      maxlength="20"
                    />
                  </div>
                </v-list-item-title>
                <delete-dialog @confirm="hashtagItems.splice(i, 1)"/>
              </v-list-item>
            </v-slide-y-transition>
          </draggable>
        </v-form>
      </v-col>
      <!-- 社群連結區 -->
    </v-row>
    <v-card-actions class="px-0">
      <v-btn class="rounded-lg flex-grow-1" depressed style="text-transform: none !important;" @click="addTreatments">
        <v-icon color="grey" size="15">
          mdi-plus-circle
        </v-icon>
        點我新增擅長治療
      </v-btn>
      <v-btn
        color="grey"
        outlined
        class="text-subtitle-2 rounded-lg"
        @click="onClose"
      >
        取消
      </v-btn>
      <v-btn
        color="teal lighten-2"
        class="text-subtitle-2 rounded-lg white--text"
        @click="onConfirm"
      >
        儲存
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import draggable from 'vuedraggable';
import DeleteDialog from './DeleteDialog.vue';
// import { treatmentCategory } from "@/utils/sharedData";

export default {
  name: 'TreatmentSectionFreeForm',

  components: {DeleteDialog, draggable},

  props: {
    show: {
      type: Boolean,
      default: false
    },
    doctor: {
      type: Object,
      default: () => {
        return {
          hashtagItems: []
        };
      }
    }
  },

  data() {
    return {
      loading: false,
      drag: true,
      valid: true,
      hashtagItems: [],
      rules: {
        required: value => !!value || '*請輸入有效文字，不可輸入空白內容'
      }
    };
  },

  watch: {
    show: {
      handler(show) {
        if (show && this.doctor) {
          this.hashtagItems = JSON.parse(JSON.stringify(this.doctor.hashtag_item_structure));
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    // onChangeCategory(e, event) {
    //   const category = this.treatmentCategory.find((s) => s.id === event)
    //   e.title = category.title;
    //   e.description = category.description;
    //   e.dental_icon_id = category.icon_id || 1;
    // },
    onConfirm() {
      this.$refs.form.validate()
      if (!this.valid) return
      this.loading = true
      this.$emit('update', {
        hashtags: this.hashtagItems,
        callback: (status) => {
          this.loading = false
          if (status === 'success') {
            this.onClose();
          } else {
            alert('更新失敗，請再試一次')
          }
        }
      });
    },
    onClose() {
      this.$emit('update:show', false);
    },
    addTreatments() {
      this.hashtagItems.push({
        title: '',
        category: 999,
        dental_icon_id: 1,
        description: ''
      });
    }
  }
};
</script>

<style scoped lang="scss">
.flip-list-move {
  transition: transform 0.5s;
}

.custom-input {
  ::v-deep &.success--text {
    color: var(--v-primary-base) !important; /* 自訂成功文字顏色 */
  }
}
</style>
