<template>
  <v-card>
    <v-card-title>{{ $t('ForgetPassword.forgetPassword') }}</v-card-title>
    <v-card-text>
      <v-form ref="form" v-model="valid">
        <v-container fluid class="pa-1">
          <v-row dense>
            <v-col cols="7" sm="8">
              <v-text-field
                :label="$t('ForgetPassword.phone')"
                v-model.trim="formData.phone"
                hide-details="auto"
                ref="phoneField"
                :rules="phoneRule"
                required
                dense
                outlined
              />
            </v-col>
            <v-col cols="5" sm="4">
              <v-btn block color="primary" class="px-1" :disabled="disabledSendOTP" @click="sendOtp">
                {{ timerCount > 0 ? timerCount : $t('ForgetPassword.sendOtp') }}
              </v-btn>
            </v-col>
            <v-col v-if="showOtp" cols="12">
              <v-text-field
                :label="$t('ForgetPassword.otp')"
                v-model.trim="formData.otp_code"
                hide-details="auto"
                :rules="[v => !!v || $t('ForgetPassword.inputOtp')]"
                :prefix="prefix"
                required
                dense
                outlined
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                :label="$t('ForgetPassword.password')"
                v-model.trim="formData.password"
                required
                :rules="[v => !!v || $t('ForgetPassword.inputPassword')]"
                hide-details="auto"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                dense
                outlined
                @click:append="showPassword = !showPassword"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                :label="$t('ForgetPassword.confirmPassword')"
                v-model.trim="formData.password2"
                required
                :rules="[v => !!v || $t('ForgetPassword.inputConfirmPassword'), v => v === formData.password || $t('ForgetPassword.inputConfirmPassword2')]"
                hide-details="auto"
                :append-icon="showPassword2 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword2 ? 'text' : 'password'"
                dense
                outlined
                validate-on-blur
                @click:append="showPassword2 = !showPassword2"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn block :disabled="!valid || !formData.otp_code" color="primary" @click="submit">
        {{ $t('ForgetPassword.confirm') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { forgetPasswordAPI, sendOtpAPI } from "../api";

export default {
  name: "ForgetPassword",
  data() {
    return {
      valid: true,
      showPassword: false,
      showPassword2: false,
      showOtp: false,
      timerCount: 0,
      disabledSendOTP: true,
      prefix: null,
      formData: {
        phone: '',
        password: '',
        password2: '',
        otp_code: ''
      },
      phoneRule: [
        v => !!v || this.$t('ForgetPassword.inputPhone'),
        v => (v.startsWith('09') && v.trim().length === 10) || this.$t('ForgetPassword.inputPhoneFormat'),
      ]
    }
  },
  watch: {
    timerCount: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.timerCount--;
          }, 1000);
        } else {
          this.disabledSendOTP = false
        }
      },
      immediate: true
    },
    'formData.phone': {
      handler() {
        this.disabledSendOTP = !(this.$refs.phoneField.validate() && this.timerCount <= 0);
      }
    }
  },
  methods: {
    submit() {
      this.$refs.form.validate()
      if (!this.valid) return
      if (this.formData.password2 !== this.formData.password) {
        return
      }
      forgetPasswordAPI(this.formData).then(() => {
        alert(this.$t('ForgetPassword.success'))
        this.$emit('close')
      }).catch(e => {
        if (e.response && e.response.data && e.response.data.messages) {
          alert(e.response.data.messages)
        } else {
          alert(this.$t('GENERAL.oops'))
          console.error(e)
        }
      })
    },
    sendOtp() {
      const data = {
        phone: this.formData.phone,
        suffix_message: this.$t('ForgetPassword.suffixMessage')
      }
      this.disabledSendOTP = true
      this.timerCount = 30
      sendOtpAPI(data).then(res => {
        if (res.data.status === -1) {
          alert(res.data.message)
          this.timerCount = 0
          this.disabledSendOTP = false
          return
        }
        this.showOtp = true
        this.prefix = `${res.data.prefix} -`
      }).catch((e) => {
        if (e.response && e.response.data && e.response.data.status === -1) {
          alert(e.response.data.message || this.$t('GENERAL.oops'))
        } else {
          alert(this.$t('GENERAL.oops'))
        }
        this.disabledSendOTP = false
        this.timerCount = 0
      })
    },
  }
}
</script>

<style scoped>

</style>