<template>
  <v-main>
    <v-card width="412" class="mx-auto" :elevation="$vuetify.breakpoint.xsOnly ? 0 : 2" style="margin-top: 130px; z-index: 1">
      <v-card-title class="justify-center">{{ $t('LOGIN.title') }}</v-card-title>
      <v-card-subtitle class="text-center">{{ $t('LOGIN.description') }}</v-card-subtitle>
      <v-card-text>
        <v-container fluid>
          <v-tabs v-model="loginType" grow>
            <v-tab href="#1" @click="resetValidation">
              {{ $t('LOGIN.clinicLogin') }}
            </v-tab>
            <v-tab href="#2" @click="resetValidation">
              {{ $t('LOGIN.doctorLogin') }}
            </v-tab>
          </v-tabs>
          <v-form ref="form" v-model="valid" class="mt-4" @submit.prevent="submit">
            <v-text-field
              v-if="loginType === '1'"
              autofocus
              :label="$t('LOGIN.account')"
              v-model="account"
              :rules="[v => !!v || $t('LOGIN.inputAccount')]"
              required
              dense
              outlined
              class="mt-2"
            ></v-text-field>
            <v-text-field
              v-else
              autofocus
              :label="$t('LOGIN.phone')"
              v-model="account"
              :rules="[v => !!v || $t('LOGIN.inputPhone')]"
              required
              dense
              outlined
              class="mt-2"
            ></v-text-field>
            <v-text-field
              :label="$t('LOGIN.password')"
              v-model="password"
              required
              :rules="[v => !!v || $t('LOGIN.inputPassword')]"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPassword ? 'text' : 'password'"
              dense
              outlined
              class="mt-2"
              @click:append="showPassword = !showPassword"
            ></v-text-field>
            <v-btn type="submit" :disabled="!valid" block color="primary" class="mt-2">{{ $t('LOGIN.login') }}</v-btn>
          </v-form>
          <v-row dense class="mt-2 justify-space-between flex-nowrap">
            <v-col cols="auto">
              <v-btn small text color="grey-7" @click="clickRegisterDoctor">{{ $t('LOGIN.registerDoctor') }}</v-btn>
            </v-col>
            <v-col v-if="loginType === '2'" cols="auto" class="text-right">
              <v-btn small text color="grey-7" @click="forgetPassword = true">{{ $t('LOGIN.forgetPassword') }}</v-btn>
            </v-col>
          </v-row>
          <v-divider class="my-6"/>
          <v-btn block outlined color="primary" href="https://lin.ee/mWs8BoE" target="_blank">
            {{ $t('LOGIN.CS') }}
          </v-btn>
          <v-dialog v-if="forgetPassword" v-model="forgetPassword" max-width="420" scrollable>
            <forget-password @close="forgetPassword = false"/>
          </v-dialog>
          <v-dialog v-if="registerDialog" v-model="registerDialog" max-width="420" scrollable>
            <register-doctor-form @close="registerDialog = false"/>
          </v-dialog>
        </v-container>
      </v-card-text>
    </v-card>
    <v-img height="280" position="center 0" src="https://dentco.s3.us-east-2.amazonaws.com/asset/green_background.png" style="position: absolute; bottom: 0;"
           width="100%"/>
  </v-main>
</template>

<script>
import ForgetPassword from "../components/ForgetPassword";
import RegisterDoctorForm from "../components/login/RegisterDoctorForm";
import { loginAPI } from "../api";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

export default {
  name: "Login",
  components: {RegisterDoctorForm, ForgetPassword},
  data() {
    return {
      valid: true,
      account: '',
      password: '',
      showPassword: false,
      loginType: null,
      registerDialog: false,
      forgetPassword: false,
    }
  },
  computed: {
    ...mapGetters([
      'isSpecialClinic',
    ])
  },
  methods: {
    ...mapMutations({
      setUserData: 'setUserData'
    }),
    ...mapActions({
      getDoctorEmail: 'getDoctorEmail'
    }),
    resetValidation () {
      this.$refs.form.resetValidation()
    },
    submit() {
      if (!this.valid) return
      loginAPI({
        account: this.account,
        password: this.password,
        type: this.loginType,
      }).then(res => {
        this.setUserData(res.data)
        const nextPage = this.$router.currentRoute.query.next
        if (nextPage && nextPage !== '/login') this.$router.push(nextPage)
        else if (this.isSpecialClinic) this.$router.push({name: 'onlineReserve'})
        else this.$router.push('/')
      }).catch(e => {
        if (e.response && e.response.data && e.response.data.messages) {
          alert(e.response.data.messages)
        } else {
          alert(this.$t('GENERAL.oops'))
          console.error(e)
        }
      })
    },
    clickRegisterDoctor() {
      this.registerDialog = true
      this.$gtm.trackEvent({
        event: 'crm_register_doctor'
      });
    }
  },
  mounted() {
    this.loginType = this.$route.query.type || '1'
  },
}
</script>

<style scoped>

</style>