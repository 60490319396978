<template>
  <v-main>
    <navigation/>
    <v-overlay :value="loading" z-index="203">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
    <v-container v-if="doctor" style="max-width: 700px" class="mb-8 info-container">
      <v-row>
        <v-col cols="12">
          <v-row no-gutters dense class="flex-nowrap">
            <v-col cols="auto" class="flex-grow-1 text-h6">{{ $t('DoctorSettings.avatar') }}</v-col>
            <v-col cols="auto" class="flex-shrink-1">
              <v-menu bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    text
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ $t('GENERAL.edit') }}
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item @click="showCrop = true">
                    <v-list-item-title>{{ $t('DoctorSettings.uploadNewAvatar') }}</v-list-item-title>
                  </v-list-item>
                  <!--                    <v-list-item @click="deleteAvatarDialog = true">-->
                  <!--                      <v-list-item-title>刪除大頭貼</v-list-item-title>-->
                  <!--                    </v-list-item>-->
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-img
            v-if="doctor.profile"
            :src="doctor.profile"
            aspect-ratio="1"
            class="grey lighten-2 ma-auto"
            max-width="168"
          >
            <template v-slot:placeholder>
              <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
              >
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-col>
      </v-row>
      <v-divider class="my-6"/>
      <v-row dense>
        <v-col cols="12">
          <v-row no-gutters dense class="flex-nowrap">
            <v-col cols="auto" class="flex-grow-1 text-h6">{{ $t('DoctorSettings.name') }}</v-col>
<!--            <v-col cols="auto" class="flex-shrink-1">-->
<!--              <template v-if="editName">-->
<!--                <v-btn text color="primary" @click="save('name')">{{ $t('GENERAL.save') }}</v-btn>-->
<!--                <v-btn text @click="editName = false">{{ $t('GENERAL.cancel') }}</v-btn>-->
<!--              </template>-->
<!--              <v-btn v-else color="primary" text @click="editName = true; doctorName = doctor.doctor_name">-->
<!--                {{ $t('GENERAL.edit') }}-->
<!--              </v-btn>-->
<!--            </v-col>-->
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-text-field v-if="editName" v-model="doctorName" autofocus></v-text-field>
          <v-subheader v-else class="text-subtitle-1 font-weight-medium unset-height">{{
              doctor.doctor_name
            }}
          </v-subheader>
        </v-col>
      </v-row>
      <v-divider class="my-6"/>
      <v-row dense id="email">
        <v-col cols="12">
          <v-row no-gutters dense class="flex-nowrap">
            <v-col cols="auto" class="flex-grow-1 text-h6">Email</v-col>
            <v-col cols="auto" class="flex-shrink-1">
              <template v-if="editEmail">
                <v-btn text color="primary" @click="save('email')">{{ $t('GENERAL.save') }}</v-btn>
                <v-btn text @click="editEmail = false">{{ $t('GENERAL.cancel') }}</v-btn>
              </template>
              <v-btn v-else color="primary" text @click="editEmail = true; doctorEmail = doctor.email">
                {{ $t('GENERAL.edit') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-text-field v-if="editEmail" v-model="doctorEmail" ref="emailField" autofocus placeholder="email 將不會顯示在醫師小幫手頁面上，僅供醫師小幫手訊息通知使用" :rules="emailRule"/>
          <v-subheader v-else class="text-subtitle-1 font-weight-medium unset-height">{{ doctor.email }}</v-subheader>
        </v-col>
      </v-row>
      <v-divider class="my-6"/>
<!--      <v-row dense>-->
<!--        <v-col cols="12">-->
<!--          <v-row no-gutters dense class="flex-nowrap">-->
<!--            <v-col cols="auto" class="flex-grow-1 text-h6">{{ $t('DoctorSettings.birthday') }}</v-col>-->
<!--            <v-col cols="auto" class="flex-shrink-1">-->
<!--              <template v-if="editBirth">-->
<!--                <v-btn text color="primary" @click="save('birthday')">{{ $t('GENERAL.save') }}</v-btn>-->
<!--                <v-btn text @click="editBirth = false">{{ $t('GENERAL.cancel') }}</v-btn>-->
<!--              </template>-->
<!--              <v-btn v-else color="primary" text @click="editBirth = true; defaultBirth()">{{-->
<!--                  $t('GENERAL.edit')-->
<!--                }}-->
<!--              </v-btn>-->
<!--            </v-col>-->
<!--          </v-row>-->
<!--        </v-col>-->
<!--        <v-col cols="12">-->
<!--          <template v-if="editBirth">-->
<!--            <v-row no-gutters dense class="justify-space-between flex-nowrap birth-input" style="width: 225px">-->
<!--              <v-col class="px-1">-->
<!--                <v-text-field-->
<!--                  v-model="birthday[0]"-->
<!--                  dense-->
<!--                  :suffix="$t('DoctorSettings.year')"-->
<!--                  class="text-center"-->
<!--                />-->
<!--              </v-col>-->
<!--              <v-col class="px-1">-->
<!--                <v-text-field-->
<!--                  v-model="birthday[1]"-->
<!--                  dense-->
<!--                  type="numeric"-->
<!--                  :suffix="$t('DoctorSettings.month')"-->
<!--                />-->
<!--              </v-col>-->
<!--              <v-col class="px-1">-->
<!--                <v-text-field-->
<!--                  v-model="birthday[2]"-->
<!--                  dense-->
<!--                  type="numeric"-->
<!--                  :suffix="$t('DoctorSettings.day')"-->
<!--                />-->
<!--              </v-col>-->
<!--            </v-row>-->
<!--          </template>-->
<!--          <v-subheader v-else class="text-subtitle-1 font-weight-medium unset-height">{{-->
<!--              doctor.birthday-->
<!--            }}-->
<!--          </v-subheader>-->
<!--        </v-col>-->
<!--      </v-row>-->
<!--      <v-divider class="my-6"/>-->
      <v-row dense>
        <v-col cols="12">
          <v-row no-gutters dense class="flex-nowrap">
            <v-col cols="auto" class="flex-grow-1 text-h6">{{ $t('DoctorSettings.philosophy') }}</v-col>
            <v-col cols="auto" class="flex-shrink-1">
              <template v-if="editPrinciple">
                <v-btn text color="primary" @click="save('principle')">{{ $t('GENERAL.save') }}</v-btn>
                <v-btn text @click="editPrinciple = false">{{ $t('GENERAL.cancel') }}</v-btn>
              </template>
              <v-btn v-else color="primary" text @click="editPrinciple = true; principle = doctor.principle">
                {{ $t('GENERAL.edit') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-textarea v-if="editPrinciple" v-model="principle" no-resize/>
          <v-subheader v-else class="text-subtitle-1 font-weight-medium unset-height text-pre-line">{{
              doctor.principle
            }}
          </v-subheader>
        </v-col>
      </v-row>
      <v-divider class="my-6"/>
      <doctor-opening :clinics="doctor.clinics" @update="getDoctor" @load="setLoading"/>
      <v-divider class="my-6"/>
      <v-row dense>
        <v-col cols="12">
          <v-row no-gutters dense class="flex-nowrap">
            <v-col cols="auto" class="flex-grow-1 text-h6">{{ $t('DoctorSettings.education') }}</v-col>
            <v-col cols="auto" class="flex-shrink-1">
              <template v-if="editEducation">
                <v-btn text color="primary" @click="save('education')">{{ $t('GENERAL.save') }}</v-btn>
                <v-btn text @click="editEducation = false">{{ $t('GENERAL.cancel') }}</v-btn>
              </template>
              <v-btn v-else color="primary" text @click="editEducation = true; defaultEdu()">{{ $t('GENERAL.edit') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" class="edu pl-4">
          <template v-if="editEducation">
            <v-row dense class="flex-nowrap">
              <v-col cols="auto">
                <v-btn color="primary" text @click="addEdu">
                  <v-icon>mdi-plus</v-icon>
                  {{ $t('DoctorSettings.add') }}
                </v-btn>
              </v-col>
            </v-row>
            <template v-for="(e, i) in education">
              <education v-if="!e.delete" :data="e" :key="i" @delete="removeEdu(i)"/>
            </template>
          </template>
          <template v-else>
            <v-row v-for="(e, i) in doctor.education" :key="i" dense>
              <template v-if="e.school !== '無'">
                <v-col cols="3" sm="2" class="align-self-center">
                  <v-avatar size="80">
                    <v-img :src="showEduLogo(e.school)" :alt="e.school">
                      <template v-slot:placeholder>
                        <v-row class="fill-height ma-0" align="center" justify="center">
                          <v-progress-circular indeterminate color="grey lighten-5"/>
                        </v-row>
                      </template>
                    </v-img>
                  </v-avatar>
                </v-col>
                <v-col cols="9" sm="10">
                  <v-row dense>
                    <v-col cols="12" class="text-h6">{{ e.name || e.school }}</v-col>
                    <v-col cols="12">{{ e.partment }}</v-col>
                    <v-col cols="12">{{ getYear(e.year) }}</v-col>
                  </v-row>
                </v-col>
              </template>
            </v-row>
          </template>
        </v-col>
      </v-row>
      <v-divider class="my-6"/>
      <v-row dense>
        <v-col cols="12">
          <v-row no-gutters dense class="flex-nowrap">
            <v-col cols="auto" class="flex-grow-1 text-h6">{{ $t('DoctorSettings.experience') }}</v-col>
            <v-col cols="auto" class="flex-shrink-1">
              <template v-if="editExperience">
                <v-btn text color="primary" @click="save('experience')">{{ $t('GENERAL.save') }}</v-btn>
                <v-btn text @click="editExperience = false">{{ $t('GENERAL.cancel') }}</v-btn>
              </template>
              <v-btn v-else color="primary" text @click="editExperience = true; defaultExperience()">
                {{ $t('GENERAL.edit') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <template v-if="editExperience">
            <v-row dense class="flex-nowrap">
              <v-col cols="auto" class="flex-grow-1 flex-sm-grow-0">
                <v-text-field v-model="newExperience" hide-details outlined dense/>
              </v-col>
              <v-col cols="auto" class="flex-shrink-1 flex-sm-shrink-0">
                <v-btn color="primary" @click="addExperience">{{ $t('DoctorSettings.addExperience') }}</v-btn>
              </v-col>
            </v-row>
            <draggable
              class="list-group"
              tag="ul"
              v-model="experience"
              handle=".drag-content"
              @start="drag = true"
              @end="drag = false"
            >
              <v-list-item v-for="(e, i) in experience" :key="i" style="height: 40px">
                <v-list-item-title>
                  <v-btn icon class="px-0 handle drag-content" small>
                    <v-icon size="20">
                      mdi-cursor-move
                    </v-icon>
                  </v-btn>
                  {{ e }}
                </v-list-item-title>
                <v-list-item-action>
                  <v-btn icon @click="experience.splice(i, 1)">
                    <v-icon color="grey lighten-1">mdi-trash-can-outline</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </draggable>
          </template>
          <v-list v-else subheader>
            <v-list-item v-for="(e, i) in doctor.qualification" :key="i" style="height: 40px">
              <v-list-item-title v-text="e"/>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
      <v-divider class="my-6"/>
      <v-row dense>
        <v-col cols="12">
          <v-row no-gutters dense class="flex-nowrap">
            <v-col cols="auto" class="flex-grow-1 text-h6">
              LINE官方帳號
              <v-btn
                v-if="!hasGuidePermission"
                dark
                color="tiffany-10"
                depressed
                :block="$vuetify.breakpoint.smAndDown"
                class="px-md-12 rounded-lg"
                href="https://lin.ee/mWs8BoE"
                target="_blank"
              >
                欲顯示完整社群，請點我升級為進階方案
              </v-btn>
            </v-col>
            <v-col cols="auto" class="flex-shrink-1">
              <template v-if="editLinePage">
                <v-btn text color="primary" @click="save('linePage')">{{ $t('GENERAL.save') }}</v-btn>
                <v-btn text @click="cancelEditLine">{{ $t('GENERAL.cancel') }}</v-btn>
              </template>
              <v-btn v-else color="primary" text :disabled="!hasGuidePermission" @click="editLinePage = true; linePage = doctor.line_page">{{
                  $t('GENERAL.edit')
                }}
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-row class="mx-0">
            <v-radio-group
              v-model="linePageStatus"
              :disabled="!editLinePage"
              style="width: 100%;"
              class="px-2"
            >
              <v-radio
                label="不開放"
                value="0"
              ></v-radio>
              <v-radio
                label="使用串接帳號（建議選項）"
                value="1"
                @click="checkChannelExist"
              ></v-radio>
              <v-radio
                label="自行輸入連結"
                value="2"
              ></v-radio>
              <v-text-field
                v-model="linePage"
                :disabled="!(editLinePage && linePageStatus === '2')"
                class="setting-input pl-6"
                filled
                rounded
                single-line
                placeholder="請輸入連結（注意：自行輸入將無法從小幫手系統追蹤來源）"
                hide-details="auto"
              />
            </v-radio-group>
            <v-dialog v-model="lineAlertDialog" max-width="400">
              <v-card>
                <v-card-title>
                  <div>
                    您沒有串接的LINE官方賬號，請輸入其他連結，或是詢問 <a :href="$enum.DENTCO_CS_LINE" target="_blank">客服人員</a>
                  </div>
                </v-card-title>
              </v-card>
            </v-dialog>
          </v-row>
        </v-col>
      </v-row>
      <v-divider class="my-6"/>
      <v-row dense>
        <v-col cols="12">
          <v-row no-gutters dense class="flex-nowrap">
            <v-col cols="auto" class="flex-grow-1 text-h6">{{ $t('DoctorSettings.fbLink') }}</v-col>
            <v-col cols="auto" class="flex-shrink-1">
              <template v-if="editFb">
                <v-btn text color="primary" @click="save('fb')">{{ $t('GENERAL.save') }}</v-btn>
                <v-btn text @click="editFb = false">{{ $t('GENERAL.cancel') }}</v-btn>
              </template>
              <v-btn v-else color="primary" text @click="editFb = true; fb = doctor.fb_page">{{
                  $t('GENERAL.edit')
                }}
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-text-field v-if="editFb" v-model="fb"/>
          <v-subheader v-else class="text-subtitle-1 font-weight-medium unset-height">
            {{ fbPage }}
          </v-subheader>
        </v-col>
      </v-row>
      <v-divider class="my-6"/>
      <v-row dense>
        <v-col cols="12">
          <v-row no-gutters dense class="flex-nowrap">
            <v-col cols="auto" class="flex-grow-1 text-h6">{{ $t('DoctorSettings.webLink') }}</v-col>
            <v-col cols="auto" class="flex-shrink-1">
              <template v-if="editWebsite">
                <v-btn text color="primary" @click="save('website')">{{ $t('GENERAL.save') }}</v-btn>
                <v-btn text @click="editWebsite = false">{{ $t('GENERAL.cancel') }}</v-btn>
              </template>
              <v-btn v-else color="primary" text @click="editWebsite = true; website = doctor.website">
                {{ $t('GENERAL.edit') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-text-field v-if="editWebsite" v-model="website"/>
          <v-subheader v-else class="text-subtitle-1 font-weight-medium unset-height">{{
              decodeURI(doctor.website)
            }}
          </v-subheader>
        </v-col>
      </v-row>
      <v-divider class="my-6"/>
      <v-row dense>
        <v-col cols="12">
          <v-row no-gutters dense class="flex-nowrap">
            <v-col cols="auto" class="flex-grow-1 text-h6">{{ $t('DoctorSettings.treatments') }}</v-col>
            <v-col cols="auto" class="flex-shrink-1">
              <template v-if="editTreatments">
                <v-btn text color="primary" @click="save('treatments')">{{ $t('GENERAL.save') }}</v-btn>
                <v-btn text @click="editTreatments = false">{{ $t('GENERAL.cancel') }}</v-btn>
              </template>
              <v-btn v-else color="primary" text @click="editTreatments = true; defaultTreatment()">
                {{ $t('GENERAL.edit') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <template v-if="editTreatments">
            <v-row dense class="flex-nowrap">
              <v-col cols="auto" class="flex-grow-1 flex-sm-grow-0">
                <v-text-field v-model="newTreatment" hide-details outlined dense/>
              </v-col>
              <v-col cols="auto" class="flex-shrink-1 flex-sm-shrink-0">
                <v-btn color="primary" @click="addTreatment">{{ $t('DoctorSettings.addTreatments') }}</v-btn>
              </v-col>
            </v-row>
            <draggable
              class="list-group"
              tag="ul"
              v-model="treatments"
              handle=".drag-content"
              @start="drag = true"
              @end="drag = false"
            >
              <v-list-item v-for="(e, i) in treatments" :key="i" style="height: 40px">
                <v-list-item-title class="drag-content" style="cursor: pointer" v-text="e.description"/>
                <v-list-item-action>
                  <v-btn icon @click="treatments.splice(i, 1)">
                    <v-icon color="grey lighten-1">mdi-trash-can-outline</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </draggable>
          </template>
          <v-list v-else subheader>
            <v-list-item v-for="(e, i) in doctor.treatment" :key="i" style="height: 40px">
              <v-list-item-title v-text="e.description"/>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
      <v-divider class="my-6"/>
      <case-album :data="doctor.case_photo" @update="getDoctor" @load="setLoading"/>


      <v-dialog v-if="showCrop" v-model="showCrop" max-width="500">
        <crop-image @close="showCrop = false" @upload="uploadAvatar"/>
      </v-dialog>
    </v-container>
  </v-main>
</template>

<script>
import Navigation from "../../components/Navigation";
import CropImage from "../../components/settings/CropImage";
import draggable from "vuedraggable";
import Education from "../../components/settings/doctor/Education";
import { mapGetters, mapMutations, mapState } from "vuex";
import CaseAlbum from "../../components/settings/CaseAlbum";
import { getDoctorInfoAPI, getInfoAPI, getMessageSettingAPI, updateDoctorInfoAPI } from "../../api";
import DoctorOpening from "../../components/settings/doctor/doctorOpening";
import { checkPermissions } from "../../utils/tool";

export default {
  name: "DoctorSettings",
  components: {DoctorOpening, CaseAlbum, Education, CropImage, Navigation, draggable},
  data() {
    return {
      loading: true,

      info: null,

      doctor: null,
      showCrop: false,
      editName: false,
      doctorName: null,

      editEmail: false,
      doctorEmail: null,
      emailRule: [
        v => !!v || this.$t('RegisterDoctorForm.inputEmail'),
        v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || this.$t('RegisterDoctorForm.inputEmailFormat')
      ],

      editBirth: false,
      birthday: ['', '', ''],

      editPrinciple: false,
      principle: null,

      editEducation: false,
      education: null,

      drag: false,
      editExperience: false,
      experience: null,
      newExperience: null,

      editLinePage: false,
      linePage: null,
      linePageStatus: null,
      lineAlertDialog: false,

      editFb: false,
      fb: null,

      editWebsite: false,
      website: null,

      editTreatments: false,
      treatments: null,
      newTreatment: null,
    }
  },
  computed: {
    ...mapState({
      user: state => state.user,
      educationItems: state => state.educationItems,
    }),
    ...mapGetters([
      'axiosParamData',
    ]),
    fbPage: {
      get () {
        let data = this.doctor.fb_page;

        try {
          data = decodeURI(this.doctor.fb_page);
        } catch (e) {

        } finally {

        }

        return data;
      },
      set (val) {

      }
    },
    hasGuidePermission() {
      return checkPermissions({obj: this.info, type: 'doctor', permissions: ['guide_to_line']})
    }
  },
  methods: {
    ...mapMutations({
      setDoctorName: 'setDoctorName',
      setDoctorEmail: 'setDoctorEmail',
    }),
    uploadAvatar(image) {
      this.setLoading()
      const formData = new FormData()
      for (const [key, value] of Object.entries(this.axiosParamData)) {
        formData.append(key, value)
      }
      formData.append('profile', image)
      updateDoctorInfoAPI(formData).then(() => {
        this.showCrop = false
      }).catch(() => {
        alert(this.$t('DoctorSettings.uploadFail'))
      }).finally(() => {
        this.getDoctor()
      })
    },
    save(key) {
      this.setLoading()
      const data = {
        ...this.axiosParamData
      }
      if (key === 'name') {
        data.doctor_name = this.doctorName
        this.editName = false
      } else if (key === 'birthday') {
        data.birthday = this.birthday.join('-')
        this.editBirth = false
      } else if (key === 'principle') {
        data.principle = this.principle
        this.editPrinciple = false
      } else if (key === 'education') {
        let _ = JSON.parse(JSON.stringify(this.education))
        _ = _.filter(e => !e.delete)
        _.map(e => {
          e.year = e.year.join(',')
          delete e.delete
        })
        data.education = _
        this.editEducation = false
      } else if (key === 'experience') {
        data.qualification = this.experience.filter(item => !!item).join(',')
        this.editExperience = false
      } else if (key === 'linePage') {
        data.line_page_status = this.linePageStatus
        data.line_page = this.linePage
        this.editLinePage = false
      } else if (key === 'fb') {
        data.fb_page = this.fb
        this.editFb = false
      } else if (key === 'website') {
        data.website = this.website
        this.editWebsite = false
      } else if (key === 'treatments') {
        this.treatments = this.treatments.map((v, index) => {
          return {...v, sort: index};
        })
        data.treatment = this.treatments
        this.editTreatments = false
      } else if (key === 'email') {
        if (this.$refs.emailField.hasError) {
          this.loading = false
          return
        }
        data.email = this.doctorEmail
        this.editEmail = false
      }
      updateDoctorInfoAPI(data).catch(() => {
        alert(this.$t('DoctorSettings.updateFail'))
      }).finally(() => {
        this.getDoctor()
      })
    },
    defaultBirth() {
      const birthday = this.doctor.birthday.split('-')
      if (birthday.length === 3) this.birthday = birthday
    },
    defaultExperience() {
      this.experience = JSON.parse(JSON.stringify(this.doctor.qualification))
    },
    addExperience() {
      this.experience.push(this.newExperience)
      this.newExperience = null
    },
    defaultTreatment() {
      this.treatments = JSON.parse(JSON.stringify(this.doctor.treatment))
    },
    addTreatment() {
      this.treatments.push({description: this.newTreatment, sort: 99})
      this.newTreatment = null
    },
    showEduLogo(name) {
      const edu = this.educationItems.find(e => e.name === name)
      if (edu) return edu.logo
      return this.educationItems[this.educationItems.length - 1].logo
    },
    defaultEdu() {
      let edu = JSON.parse(JSON.stringify(this.doctor.education)) || []
      edu = edu.filter(function (obj) {
        return obj.school !== '無'
      });
      edu.map(e => {
        e.delete = false
        const y = e.year.split(',')
        if (y.length === 2) e.year = y
        else e.year = ['', '']
      })
      this.education = edu
    },
    removeEdu(i) {
      this.education.splice(i, 1)
    },
    addEdu() {
      this.education.push({delete: false, year: ['', '']})
    },
    getYear(y) {
      if (y) {
        const _ = y.split(',')
        if (_.length === 2) return `${_[0]}${this.$t('DoctorSettings.year')} - ${_[1] === this.$t('DoctorSettings.present') ? _[1] : _[1] + this.$t('DoctorSettings.year')}`
      }
    },
    getDoctor() {
      getDoctorInfoAPI(this.axiosParamData).then(res => {
        const _ = res.data
        _.qualification = _.qualification.split(',') || []
        _.qualification = _.qualification.filter(e => !!e)
        this.doctor = _
        this.cancelEditLine()
        if (this.doctor.doctor_name !== this.user.doctor_name) {
          this.setDoctorName(this.doctor.doctor_name)
        }
        this.setDoctorEmail(this.doctor.email)
        this.loading = false
      })
    },
    getInfo() {
      getInfoAPI(this.axiosParamData).then(res => {
        this.info = res.data
      })
    },
    setLoading() {
      this.loading = true
    },
    cancelEditLine() {
      this.editLinePage = false
      this.linePage = this.doctor.line_page
      this.linePageStatus = this.doctor.line_page_status
    },
    async checkChannelExist() {
      const res = await getMessageSettingAPI(this.axiosParamData)
      const hasChannel = !!res.data.own_channel
      if (!hasChannel) {
        this.lineAlertDialog = true
        this.linePageStatus = this.doctor.line_page_status
      }
    }
  },
  mounted() {
    this.getDoctor()
    this.getInfo()
  }
}
</script>

<style scoped lang="scss">
.info-container .v-subheader {
  word-break: break-all;
}

.edu {
  .row + .row--dense {
    margin-top: 8px;
  }
}

.unset-height {
  height: unset !important;
}
</style>
