<template>
  <div>
    <div class="mt-12 px-4 d-flex">
      <v-spacer/>
      <v-btn color="grey-4" @click="cancel">取消修改</v-btn>
      <v-btn color="primary ml-5" @click="save">確認儲存</v-btn>
    </div>
    <v-card outlined flat height="calc(100vh - 350px)" class="mt-1 px-4 py-10 overflow-y-auto">
      <v-item-group v-model="selectAiDataPack" multiple class="overflow-y-auto">
        <v-container>
          <v-row style="row-gap: 12px;">
            <v-col v-for="ai in aiDataPacks" :key="ai.id" cols="12" sm="6" md="3" class="text-center">
              <v-item :value="ai.id" v-slot="{ active, toggle }">
                <div style="cursor: pointer;" @click="toggle">
                  <div style="width: 75px; height: 75px;" class="mx-auto">
                    <v-img max-height="75" max-width="75" class="mx-auto" :src="assetURL(`ai/${ai.image_url}`)"
                           style="position: absolute"/>
                  </div>
                  <v-checkbox
                    :input-value="active"
                    :true-value="ai.id"
                    :label="ai.name"
                    style="width: fit-content"
                    class="mx-auto"
                    hide-details
                  />
                </div>
              </v-item>
              <div class="mt-2 grey-7--text" v-html="ai.description"/>
            </v-col>
          </v-row>
        </v-container>
      </v-item-group>

      <v-snackbar
        v-model="snackbar"
        centered
        timeout="2000"
        color="tiffany-10"
      >
        更新成功
      </v-snackbar>
    </v-card>
  </div>
</template>

<script>
import { changeUseAIDataPacksAPI, getAIDataPacksAPI, getUseAIDataPacksAPI } from '../../api';
import { assetURL } from '../../utils/filters';

export default {
  name: "AIDataPack",

  props: {
    medicalCode: {
      type: String
    }
  },

  data() {
    return {
      assetURL,
      aiDataPacks: [],
      selectAiDataPack: [],
      originalSelectAiDataPack: [],
      snackbar: false
    }
  },

  methods: {
    getAiPacks() {
      getAIDataPacksAPI().then(res => {
        this.aiDataPacks = res.data.data.packages
      })
    },
    getCurrentSet() {
      getUseAIDataPacksAPI(this.medicalCode).then(res => {
        const selectPacks = res.data.data.packages
        this.originalSelectAiDataPack = selectPacks.map(e => e.id)
        this.selectAiDataPack = JSON.parse(JSON.stringify(this.originalSelectAiDataPack))
      })
    },
    cancel() {
      this.selectAiDataPack = JSON.parse(JSON.stringify(this.originalSelectAiDataPack))
    },
    save() {
      changeUseAIDataPacksAPI(this.medicalCode, {package_ids: this.selectAiDataPack}).then(() => {
        this.getCurrentSet()
        this.snackbar = true
      })
    },
    getData() {
      this.getAiPacks()
      this.getCurrentSet()
    }
  },

  mounted() {
    this.getData()
  }
}
</script>

<style scoped>

</style>