<template>
  <div>
    <div class="d-flex align-center mt-2">
      <v-sheet color="grey-8" height="60" rounded width="100%" class="d-flex align-center px-2">
        <div class="d-flex mr-2" style="width: 350px; max-width: 70%;">
          <v-text-field
            v-model="search"
            v-on:keyup.enter="searchPreteeth()"
            single-line
            hide-details
            outlined
            dense
            clearable
            :placeholder="'輸入姓名/手機/民國生日(例801231)'"
          />
        </div>
        <v-dialog ref="dialog" v-model="modal" persistent width="290px">
          <template v-slot:activator="{ on, attrs }">
            <div style="width: 350px;">
              <v-text-field
                :value="showSelectDays"
                label="體驗日期篩選"
                prepend-icon="mdi-calendar"
                readonly
                single-line
                hide-details
                outlined
                dense
                clearable
                v-bind="attrs"
                v-on="on"
                @click:clear="confirmDays=[];confirmDays=[]"
              />
            </div>
          </template>
          <v-date-picker
            v-model="confirmDays"
            :selected-items-text="confirmDays.join('~')"
            :allowed-dates="allowedDays"
            locale="zh-TW"
            scrollable
            range
          >
            <v-spacer/>
            <v-btn text color="primary" @click="modal = false">{{ $t('GENERAL.cancel') }}</v-btn>
            <v-btn text color="primary" @click="confirm">{{ $t('OnlineReserveAnalysis.confirm') }}</v-btn>
          </v-date-picker>
        </v-dialog>
        <v-btn color="primary" class="ml-2" @click="searchPreteeth()">
          <v-icon>mdi-magnify</v-icon> 搜尋
        </v-btn>
        <v-spacer/>
        <v-btn :icon="$vuetify.breakpoint.xsOnly" outlined @click="exportPreteeth">
          <v-icon>mdi-file-export</v-icon>
          <template v-if="$vuetify.breakpoint.smAndUp">匯出</template>
        </v-btn>
      </v-sheet>
    </div>

    <v-data-table
      :headers="headers"
      :items="preteethLists"
      :options.sync="options"
      :server-items-length="total"
      :footer-props="{itemsPerPageOptions: [10, 20, 50]}"
      :loading="loading"
      fixed-header
      height="calc(100vh - 380px)"
    >
      <template v-slot:item.original_photo_path="{ item }">
        <v-img
          :src="item.original_photo_path"
          max-height="100"
          width="100"
          contain
          @click="toggleFullscreen(item.original_photo_path)"
        >
          <template v-slot:placeholder>
            <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
            >
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </template>
      <template v-slot:item.photo_path="{ item }">
        <v-img
          :src="item.photo_path"
          max-height="100"
          width="100"
          contain
          @click="toggleFullscreen(item.photo_path)"
        >
          <template v-slot:placeholder>
            <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
            >
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </template>
    </v-data-table>
    <v-overlay
      :value="selectedImage"
      @click="selectedImage = null"
    >
      <v-card flat width="fit-content" color="transparent">
        <img
          :src="selectedImage"
          alt=""
          style="height: auto; width: auto; max-width: 80vw; max-height: 75vh;"
        >
        <v-btn large block color="primary" target="_blank" :href="selectedImage">點我下載</v-btn>
      </v-card>
    </v-overlay>
  </div>
</template>

<script>
import { exportPreteethListsAPI, getPreteethListsAPI } from "../../api";
import { mapGetters } from "vuex";
import dayjs from "dayjs";

export default {
  name: "PreteethAIList",

  data() {
    const yearsAgo = dayjs().subtract(11, 'month').format('YYYY-MM')
    const currentMonth = dayjs().format('YYYY-MM')

    return {
      loading: false,
      search: null,
      options: {},
      preteethLists: [],
      total: 0,

      selectedImage: null,

      modal: false,
      confirmDays: [],
      confirmDays: []
    }
  },

  computed: {
    ...mapGetters([
      'axiosParamData',
    ]),
    headers() {
      return [
        {text: '姓名', align: 'center', value: 'name', sortable: false, width: 120},
        {text: '手機', align: 'center', value: 'phone', sortable: false, width: 120},
        {text: '性別', align: 'center', value: 'gender', sortable: false, width: 120},
        {text: '問卷類型', align: 'center', value: 'questionnaire_type', sortable: false, width: 120},
        {text: '年齡範圍', align: 'center', value: 'age_range', sortable: true, width: 120},
        {text: '原始圖片', align: 'center', value: 'original_photo_path', sortable: true, width: 120},
        {text: '矯正圖片', align: 'center', value: 'photo_path', sortable: true, width: 120},
        {
          text: '請問此體驗是否讓您更有意願預約矯正諮詢',
          align: 'center',
          value: 'accept_q1',
          sortable: true,
          width: 120
        },
        {
          text: '若您近日即將前往牙醫診所看診，是否願意花十分鐘了解矯正療程跟方案',
          align: 'center',
          value: 'accept_q2',
          sortable: true,
          width: 120
        },
        {
          text: '再次謝謝您參與本次體驗，請為矯正小助手打個分數',
          align: 'center',
          value: 'accept_q3',
          sortable: true,
          width: 120
        },
        {text: '備註', align: 'center', value: 'note', sortable: true, width: 120},
        {text: '請問您不願意體驗的原因為何', align: 'center', value: 'reject_q1', sortable: true, width: 120},
        {text: '有其他的建議想要提供給我們嗎', align: 'center', value: 'reject_q1_note', sortable: true, width: 120},
        {text: '填寫日期', align: 'center', value: 'created_at', sortable: true, width: 120},
      ]
    },
    showSelectDays() {
      return this.confirmDays.join(' ~ ')
    },
    getParams() {
      return {
        ...this.axiosParamData,
        search: this.search,
        start: this.confirmDays[0],
        end: this.confirmDays[1],
      }
    }
  },

  watch: {
    options: {
      handler() {
        this.getPreteethLists()
      },
      deep: true
    },
  },

  methods: {
    getPreteethLists() {
      const {page, itemsPerPage} = this.options
      const params = {
        ...this.getParams,
        page,
        limit: itemsPerPage,
      }
      getPreteethListsAPI(params).then(res => {
        this.preteethLists = res.data.data
        this.total = parseInt(res.data.paginate.total)
      })
    },
    searchPreteeth() {
      if (this.options.page === 1)
        return this.getPreteethLists()
      this.options.page = 1
    },
    toggleFullscreen(elem) {
      this.selectedImage = elem;
    },
    exportPreteeth() {
      exportPreteethListsAPI(this.getParams).then(res => {
        const blob = new Blob([res.data], {type: 'text/csv;charset=utf-8;'});
        const link = document.createElement("a");
        const url = URL.createObjectURL(blob);
        const fileName = res.headers['content-disposition'].split("filename=")[1] || '會員列表'
        link.setAttribute("href", url);
        link.setAttribute("download", fileName);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
    },
    allowedDays(v) {
      return dayjs(v) < dayjs().endOf('day')
    },
    confirm() {
      this.confirmDays = JSON.parse(JSON.stringify(this.confirmDays.sort()))
      this.modal = false
    },
  },

  mounted() {
    this.getPreteethLists()
  }
}
</script>

<style scoped>

</style>
