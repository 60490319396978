<template>
  <div class="d-flex">
    <div style="width: 78px;">
      <v-menu
        ref="menu1"
        v-model="menu1"
        :close-on-content-click="false"
        :nudge-right="40"
        :return-value.sync="time1"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
        :disabled="disabled"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="time1"
            single-line
            dense
            :readonly="!disabled"
            :disabled="disabled"
            hide-details
            filled
            rounded
            class="setting-input"
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-time-picker
          v-if="menu1"
          v-model="time1"
          format="24hr"
          full-width
          :allowed-minutes="m => m % 30 === 0"
          @click:minute="save('menu1', time1)"
        ></v-time-picker>
      </v-menu>
    </div>
    <div class="text-center px-2 align-self-center">
      ~
    </div>
    <div style="width: 78px;">
      <v-menu
        ref="menu2"
        v-model="menu2"
        :close-on-content-click="false"
        :nudge-right="40"
        :return-value.sync="time2"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
        :disabled="disabled"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="time2"
            single-line
            dense
            :readonly="!disabled"
            :disabled="disabled"
            hide-details
            filled
            rounded
            class="setting-input"
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-time-picker
          v-if="menu2"
          v-model="time2"
          format="24hr"
          full-width
          :allowed-minutes="m => m % 30 === 0"
          @click:minute="save('menu2', time2)"
        ></v-time-picker>
      </v-menu>
    </div>
  </div>
</template>

<script>
export default {
  name: "EditTimePlus",
  props: ['times', 'disabled'],
  data() {
    return {
      menu1: false,
      menu2: false,
      time1: null,
      time2: null
    }
  },
  methods: {
    save(name, time) {
      this.$refs[name].save(time)
      if (name === 'menu1') this.times[0] = time
      else this.times[1] = time
    }
  },
  mounted() {
    this.time1 = this.times[0]
    this.time2 = this.times[1]
  }
}
</script>

<style scoped>

</style>