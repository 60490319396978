const bindStatusConfig = {
  common: {
    '-1': {
      icon: '/asset/crm/locked.png',
      text: '封鎖'
    },
    '0': {
      icon: '/asset/crm/unbinded.png',
      text: '未綁定'
    },
    '1': {
      icon: '/asset/crm/binded.png',
      text: '已綁定'
    }
  },
  line: {
    '-1': {
      icon: '/asset/crm/line_locked.png',
      text: '已封鎖'
    },
    '0': {
      icon: '/asset/crm/unbinded.png',
      text: '未綁定'
    },
    '1': {
      icon: '/asset/crm/line_binded.png',
      text: '已綁定'
    }
  },
  messenger: {
    '-1': {
      icon: '/asset/crm/messenger_locked.png',
      text: '無法觸及'
    },
    '0': {
      icon: '/asset/crm/unbinded.png',
      text: '未綁定'
    },
    '1': {
      icon: '/asset/crm/messenger_binded.png',
      text: '已綁定'
    }
  }
}

export const bindStatus = ({key = 'common', status = null, name = null}) => {
  switch (key) {
    case 'common':
      return bindStatusConfig.common[status]
    case 'line':
      return {
        ...bindStatusConfig.line[status],
        text: `${bindStatusConfig.line[status].text} <span class="text-decoration-underline">${name}</span> 的LINE官方帳號`
      }
    case 'messenger':
      return {
        ...bindStatusConfig.messenger[status],
        text: `${bindStatusConfig.messenger[status].text} <span class="text-decoration-underline">${name}</span> 的Messenger`
      }
  }
}