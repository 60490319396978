<template>
  <div>
    <template v-if="editMode">
      <!-- 編輯模式 -->
      <v-sheet outlined style="border-color: var(--v-primary-base);">
        <v-textarea
          v-model.trim="responseText"
          no-resize
          solo
          flat
          hide-details
          dense
          autofocus
          rows="3"
          class="rounded-0"
        />
        <template v-if="generateLoading">
          <!-- AI 生成中 -->
          <v-sheet outlined color="red-6" style="position: relative;">
            <v-overlay absolute z-index="2" opacity="0.8">
              <div>AI 正在努力生成中...</div>
            </v-overlay>
            <v-skeleton-loader type="list-item-two-line, actions"></v-skeleton-loader>
          </v-sheet>
        </template>
        <v-sheet v-else v-show="aiResponse" class="pa-2" style="background-color: rgba(249, 100, 100, 0.15);">
          <div class="d-flex align-center">
            <div class="font-weight-medium text-body-2">AI 建議回覆：</div>
            <v-btn small outlined dark color="red-6" @click="setEditResponse(aiResponse)">複製到回覆欄中 ↑</v-btn>
          </div>
          <div class="mt-2">
            {{ aiResponse }}
          </div>
        </v-sheet>
        <v-sheet
          color="grey-2"
          class="d-flex pa-4 align-center"
          style="gap: 10px;"
        >
          <v-spacer />
          <v-btn outlined color="grey-5" @click="$emit('cancelEdit')">
            {{ $t('GENERAL.cancel') }}
          </v-btn>
          <template>
            <v-btn v-if="!response && !aiResponse" outlined color="blue-5" @click="handleNewAiResponse">
              生成AI回覆建議
            </v-btn>
            <v-btn v-else outlined color="blue-5" @click="handleNewAiResponse">
              生成新的回覆建議
            </v-btn>
          </template>
          <v-btn
            color="tiffany-7"
            :dark="!!responseText"
            :disabled="!responseText"
            @click="$emit('submitResponse', responseText)"
          >
            送出回覆
            <v-icon small>mdi-send</v-icon>
          </v-btn>
        </v-sheet>
      </v-sheet>
    </template>
    <template v-else>
      <template v-if="response">
        <!-- 已有回覆 -->
        <v-sheet outlined>
          <v-textarea
            v-model.trim="response"
            no-resize
            solo
            flat
            hide-details
            dense
            rows="3"
            readonly
            class="rounded-0"
          />
          <v-sheet
            color="grey-2"
            class="d-flex pa-4 align-center grey-5--text"
            style="gap: 10px;"
          >
            <v-spacer />
            <div>最後編輯時間：{{ updated_time | convertToTimezone(undefined, 'YYYY-MM-DD HH:mm') }}</div>
            <v-btn outlined color="grey-5" @click="setEditResponse(response)">
              修改內容
            </v-btn>
          </v-sheet>
        </v-sheet>
      </template>
      <template v-else-if="aiGenerating || generateLoading">
        <!-- AI 生成中 -->
        <v-sheet outlined color="red-6" style="position: relative;">
          <v-overlay absolute z-index="2" opacity="0.8">
            <div>AI 正在努力生成中...</div>
          </v-overlay>
          <v-skeleton-loader type="list-item-two-line, actions"></v-skeleton-loader>
        </v-sheet>
      </template>
      <template v-else-if="aiResponse">
        <!-- AI 建議回覆 -->
        <v-sheet outlined color="red-6">
          <v-textarea
            v-model.trim="aiResponse"
            no-resize
            solo
            flat
            hide-details
            dense
            rows="3"
            readonly
            class="rounded-0"
          />
          <v-sheet
            color="grey-2"
            class="d-flex pa-4 align-center"
            :class="{'flex-wrap justify-end': $vuetify.breakpoint.xsOnly}"
            style="gap: 10px;"
          >
            <v-spacer v-if="$vuetify.breakpoint.smAndUp" />
            <v-btn outlined color="blue-5" @click="handleNewAiResponse">
              生成新的回覆建議
            </v-btn>
            <v-btn
              outlined
              color="grey-5"
              @click="setEditResponse(aiResponse)"
            >
              我想修改內容
            </v-btn>
            <v-btn color="red-6" dark @click="$emit('submitResponse', aiResponse)">
              是，送出AI建議內容
              <v-icon small>mdi-send</v-icon>
            </v-btn>
          </v-sheet>
        </v-sheet>
      </template>
      <template v-else>
        <!-- 尚無回覆 -->
        <v-btn color="primary" outlined small @click="$emit('editResponse', '')">
          {{ $t('ReviewDetailCard.reply') }}
          <v-icon small>mdi-reply</v-icon>
        </v-btn>
      </template>
    </template>

    <plan-addon-alert-dialog v-model="alertDialog"/>
  </div>
</template>

<script>
import PlanAddonAlertDialog from "@/components/utils/PlanAddonAlertDialog.vue";
import { checkPermissions } from "@/utils/tool";
import { mapGetters } from "vuex";

export default {
  components: {PlanAddonAlertDialog},

  props: {
    editMode: Boolean,
    response: String,
    aiResponse: String,
    aiGenerating: Boolean,
    updated_time: String,
    editingResponse: String,
    userInfo: Object,
    negativeReview: Boolean
  },

  data() {
    return {
      responseText: this.editingResponse || '',
      alertDialog: false,
      generateLoading: false
    };
  },

  computed: {
    ...mapGetters([
      'clinicUser',
    ]),
    hasPermission() {
      const userType = this.clinicUser ? 'clinic' : 'doctor'
      return checkPermissions({obj: this.userInfo, type: userType, permissions: ['adv_review_with_ai']})
    }
  },

  watch: {
    editingResponse(newVal) {
      this.responseText = newVal;
    },
  },

  methods: {
    handleNewAiResponse() {
      if (this.negativeReview) {
        this.generateLoading = true
        return this.$emit('generateResponse')
      }

      if (!this.hasPermission) {
        this.alertDialog = true;
      } else {
        this.generateLoading = true
        this.$emit('generateResponse')
      }
    },
    setEditResponse(value) {
      this.responseText = value;
      this.$emit('editResponse', value);
    }
  }
};
</script>
