<template>
  <div>
    <v-overlay :value="pageLoading" absolute>
      <v-progress-circular indeterminate size="64"/>
    </v-overlay>

    <v-data-table
      :headers="headers"
      :items="appointments"
      :page.sync="page"
      :server-items-length="totalAppointments"
      fixed-header
      height="calc(100vh - 256px)"
      :items-per-page.sync="limit"
      item-key="id"
      :loading="pageLoading"
      :footer-props="{itemsPerPageOptions: [10, 20, 50]}"
      :no-data-text="status? $t('ReserveTable.noContact') : $t('ReserveTable.noUnContact')"
      :item-class="setCancelRed"
    >
      <template v-slot:item.birthday="{ item }">
        <template v-if="item.birthday">{{ parseInt(item.birthday) * 1000 | formatROCDate }}</template>
      </template>
      <template v-slot:item.prefer_time="{ item }">
        {{ showPreferTime(item) }}
      </template>
      <template v-slot:item.complete_status="{ item }">
        <div v-if="item.complete_status">{{ item.complete_status }}</div>
        <div v-if="Number(item.completed) === 4">病患自行取消</div>
      </template>
      <template v-slot:item.crm_note="{ item }">
        <span>
          {{ (item.crm_note && item.crm_note.length > 20) ? item.crm_note.substr(0, 20) + '...' : item.crm_note }}
        </span>
        <v-btn icon color="primary" @click="editCrmNote(item)">
          <v-icon dark x-small>
            mdi-pencil
          </v-icon>
        </v-btn>
      </template>
      <template v-slot:item.action="{ item }">
        <template v-if="Number(item.appointment_status) === 0">
          <template v-if="Number(item.completed) === 4">
            病患要求取消
            <v-btn outlined small color="primary" style="margin: 5px 0;" @click="handleChangeStatus(item, 'know')">
              知道了，移動到已聯繫
            </v-btn>
          </template>
          <template v-else-if="item.book_time">
            <v-btn outlined small color="primary" style="margin: 5px 0;" @click="handleChangeStatus(item, 'allow')">
              {{ $t('ReserveTable.canAppointment') }}
            </v-btn>
            <br/>
            <v-btn outlined small color="primary" style="margin: 5px 0;" @click="handleChangeStatus(item, 'reject')">
              {{ $t('ReserveTable.canNotAppointment') }}
            </v-btn>
            <br/>
            <v-btn outlined small color="primary" style="margin: 5px 0;" @click="handleChangeStatus(item, 'extra')">
              {{ $t('ReserveTable.additionalArrange') }}
            </v-btn>
          </template>
          <v-btn v-else outlined small color="primary" style="margin: 5px 0;"
                 @click="handleChangeStatus(item, 'concat')">
            {{ $t('ReserveTable.completeConcat') }}
          </v-btn>
        </template>
        <v-btn v-else outlined small color="primary" style="margin: 5px 0;"
               @click="editAppointment = item; changeStatus()">
          {{ $t('ReserveTable.withdraw') }}
        </v-btn>
      </template>
    </v-data-table>
    <v-dialog v-if="crmNoteDialog" max-width="500" v-model="crmNoteDialog" persistent>
      <v-card>
        <v-card-title>{{ $t('ReserveTable.editCrmNote') }}</v-card-title>
        <v-card-text>
          <v-textarea v-model="editAppointment.crm_note" hide-details outlined no-resize/>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue" text @click="update">
            {{ $t('ReserveTable.ok') }}
          </v-btn>
          <v-btn text @click="crmNoteDialog=false">
            {{ $t('GENERAL.cancel') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-if="confirmDialog" max-width="500" v-model="confirmDialog">
      <v-card class="px-8 pt-5 pb-6">
        <v-card-title>{{ confirmTitle }}</v-card-title>
        <v-card-text v-html="confirmText"/>
        <v-card-actions class="justify-space-around">
          <v-btn color="primary" @click="changeStatus">{{ $t('ReserveTable.confirm') }}</v-btn>
          <template v-if="showInsertSystemBtn(editAppointment) & actionType === 'allow'">
            <v-btn
              color="blue-6"
              dark
              @click="handleChangeStatus(editAppointment, 'allowInsert')"
            >
              點擊寫入健保系統
            </v-btn>
          </template>
          <v-btn v-if="rejectItem" class="white--text" color="blue-6" @click="rejectReasonDialog = true">{{
              $t('ReserveTable.otherReason')
            }}
          </v-btn>
          <v-btn color="grey-5" class="white--text" @click="confirmDialog = false">{{ $t('GENERAL.cancel') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-if="messageDialog" max-width="500" v-model="messageDialog">
      <v-card>
        <v-card-title class="headline justify-center" v-text="messageTitle"/>
        <v-card-title v-if="messageText" class="headline justify-center">{{ messageText }}</v-card-title>
        <v-card-actions class="justify-center">
          <v-btn color="primary" @click="messageDialog = false" v-text="$t('ReserveTable.know')"/>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-if="rejectReasonDialog" :max-width="$vuetify.breakpoint.mdAndUp ? 650 : 500"
              v-model="rejectReasonDialog">
      <v-card>
        <v-card-title>{{ $t('ReserveTable.reasonTitle') }}</v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col v-for="(reject, i) in rejectItem.remind_3" :key="i" cols="12" sm="6" md="3" class="text-center py-2">
              <v-btn block text color="blue" class="text-subtitle-1"
                     @click="handleChangeStatus(rejectItem, 'reject', i)">
                {{ reject.reason }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-if="insertFailDialog" max-width="500" v-model="insertFailDialog">
      <v-card class="px-8 pt-5 pb-6">
        <v-card-title class="red-6--text">操作失敗</v-card-title>
        <v-card-text>
          <div>
            暫時遇到異常！請改為點擊「此時段可預約」以及回到健保系統輸入約診，最後請截圖或拍照此畫面，通知小幫手有遇到此問題，我們會盡速協助！
          </div>
          <div class="mt-2">
            <div>線上預約資料：</div>
            <div class="pl-4">
              <div>約診代號： {{ editAppointment.id }}</div>
              <div>健保系統： {{ editAppointment.system_name }}</div>
            </div>
          </div>
          <div class="mt-2">
            <div>健保系統回傳錯誤訊息：</div>
            <div class="pl-4">
              {{ insertFailMsg }}
            </div>
          </div>
        </v-card-text>
        <v-card-actions class="justify-space-around">
          <v-btn color="primary" class="px-4" @click="insertFailDialog = false">我知道了</v-btn>
          <v-btn
            dark
            color="grey-5"
            target="_blank"
            :href="$enum.DENTCO_CS_LINE"
            class="px-4"
          >聯繫客服
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { getAppointmentAPI, updateAppointmentAPI } from "../../api";

export default {
  name: "ReserveTable",
  props: ['status', 'completed'],
  data() {
    return {
      appointments: [],
      totalAppointments: 0,
      limit: 10,
      page: 1,
      pageLoading: false,
      crmNoteDialog: false,
      editAppointment: null,
      confirmDialog: false,
      messageDialog: false,
      confirmTitle: null,
      confirmText: null,
      actionType: null,
      messageTitle: null,
      messageText: null,
      rejectReasonDialog: false,
      rejectItem: null,
      rejectReasonIndex: null,
      getDataTimer: null,
      insertFailDialog: false,
      insertFailMsg: null
    }
  },
  computed: {
    ...mapGetters([
      'axiosParamData',
      'clinicUser',
      'doctorUser'
    ]),
    headers() {
      const headers = [
        {text: this.$t('ReserveTable.patientName'), align: 'center', value: 'name', sortable: false, width: 100},
        {text: this.$t('ReserveTable.patientPhone'), align: 'center', value: 'phone', sortable: false, width: 120},
        {text: this.$t('ReserveTable.patientBirth'), align: 'center', value: 'birthday', sortable: false, width: 132},
        {text: this.$t('ReserveTable.patientGender'), align: 'center', value: 'sex', sortable: false, width: 96},
        {
          text: this.$t('ReserveTable.reserveDoctor'),
          align: 'center',
          value: 'doctor_name',
          sortable: false,
          width: 100
        },
        {text: this.$t('ReserveTable.addOnItem'), align: 'center', value: 'add_on_item', sortable: false, width: 100},
        {text: this.$t('ReserveTable.bookTime'), align: 'center', value: 'prefer_time', sortable: false, width: 120},
        {text: this.$t('ReserveTable.treatment'), align: 'center', value: 'item', sortable: false, width: 100},
        {text: this.$t('ReserveTable.note'), align: 'center', value: 'note', sortable: false, width: 150},
        {text: this.$t('ReserveTable.source'), align: 'center', value: 'source', sortable: false, width: 140},
        {text: this.$t('ReserveTable.createTime'), align: 'center', value: 'create_time', sortable: false, width: 120},
        {text: this.$t('ReserveTable.crmNote'), align: 'center', value: 'crm_note', sortable: false, width: 150},
        {text: this.$t('ReserveTable.action'), align: 'center', value: 'action', sortable: false, width: 150},
      ]
      if (this.doctorUser)
        headers[4] = {
          text: this.$t('ReserveTable.reserveClinic'),
          align: 'center',
          value: 'clinic_name',
          sortable: false,
          width: 100
        }
      if (this.status) {
        headers.insert(11, {
          text: this.$t('ReserveTable.updateTime'),
          align: 'center',
          value: 'update_time',
          sortable: false,
          width: 120
        })
        headers.insert(12, {
          text: this.$t('ReserveTable.showResult'),
          align: 'center',
          value: 'complete_status',
          sortable: false,
          width: 130
        })
      }

      return headers
    },
  },
  watch: {
    page() {
      this.getDataFromApi()
    },
    limit() {
      this.getDataFromApi()
    },
  },
  mounted() {
    this.getDataFromApi()
  },
  methods: {
    ...mapActions({
      getUnreadNotice: 'getUnreadNotice',
    }),
    getDataFromApi() {
      clearTimeout(this.getDataTimer)
      this.pageLoading = true
      const params = {
        ...this.axiosParamData,
        limit: this.limit,
        page: this.page,
        status: this.status
      }
      if (this.completed) params.completed = this.completed
      getAppointmentAPI(params).then(res => {
        this.totalAppointments = parseInt(res.data.paginate.total)
        this.appointments = res.data.data.map(v => {
          let source
          if (v.is_np === '0') {
            source = `NP/${v.channel_name}`;
            source += (v.inviter_alias !== '') ? `/${v.inviter_alias}` : ``;
          } else {
            source = `${this.$t('ReserveTable.oldPatient')}/${v.channel_name}`
          }
          if (v.doctor_name === '') {
            v.doctor_name = '不指定'
          }
          return {
            ...v,
            source
          }
        })
        this.getDataTimer = setTimeout(() => {
          this.getDataFromApi()
        }, 1000 * 60 * 5)
        this.pageLoading = false
      }).catch(e => {
        alert(this.$t('GENERAL.oops'))
        console.error(e)
      })
    },
    editCrmNote(obj) {
      this.editAppointment = JSON.parse(JSON.stringify(obj))
      this.crmNoteDialog = true
    },
    update() {
      const data = {
        ...this.axiosParamData,
        crm_note: this.editAppointment.crm_note
      }
      updateAppointmentAPI(this.editAppointment.id, data).then(() => {
        this.editAppointment = null
        this.crmNoteDialog = false
        this.getDataFromApi()
      }).catch(e => {
        alert(this.$t('GENERAL.oops'))
        console.error(e)
      })
    },
    showPreferTime(appointment) {
      const weekText = [
        'ReserveTable.Sun', 'ReserveTable.Mon',
        'ReserveTable.Tues', 'ReserveTable.Wed',
        'ReserveTable.Thur', 'ReserveTable.Fri',
        'ReserveTable.Sat'
      ]
      if (appointment.book_time) {
        return `${this.$moment(appointment.book_time).format('YYYY/MM/DD')}
        (${this.$tc(weekText[this.$moment(appointment.book_time).day()], 1)})
        ${this.$moment(appointment.book_time).format('HH:mm')}`
      } else if (appointment.week) {
        const timeSlot = [this.$t('ReserveTable.morning'), this.$t('ReserveTable.afternoon'), this.$t('ReserveTable.night')]
        let _ = this.$tc(weekText[parseInt(appointment.week)], 2)
        if (appointment.time_slot) return `${_} ${timeSlot[parseInt(appointment.time_slot)]}`
        return _
      }
      return this.$t('ReserveTable.notSpecified')
    },
    handleChangeStatus(item, action, rejectReasonIndex = 3) {
      this.editAppointment = JSON.parse(JSON.stringify(item))
      this.actionType = action
      switch (action) {
        case 'allow':
          this.rejectItem = null;
          this.rejectReasonIndex = null;
          this.confirmTitle = this.$t('ReserveTable.sendAndComplete');
          this.confirmText = item.remind_1;
          break;

        case 'allowInsert':
          this.rejectItem = null;
          this.rejectReasonIndex = null;
          this.confirmTitle = this.$t('ReserveTable.sendAndComplete');
          this.confirmText = `<div>${item.remind_1}</div><div class="mt-2 red-6--text">請留意！此筆約診將直接匯入${item.system_name}！</div>`;
          break;

        case 'reject':
          this.rejectReasonIndex = rejectReasonIndex;
          this.rejectItem = item;
          this.confirmTitle = this.$t('ReserveTable.sendAndComplete');
          this.confirmText = `<span style="color: var(--v-red-6-base)">${item.remind_3[rejectReasonIndex].msg.split('，')[0]}</span>，${item.remind_3[rejectReasonIndex].msg.split('，')[1]}`;
          this.rejectReasonDialog = false;
          break;

        case 'extra':
          this.rejectItem = null;
          this.rejectReasonIndex = null;
          this.confirmTitle = this.$t('ReserveTable.completeWithoutSend');
          this.confirmText = null;
          break;

        case 'know':
          this.rejectItem = null;
          this.rejectReasonIndex = null;
          this.confirmTitle = '此筆線上預約將移動至已聯繫，系統將不另外傳送訊息通知，請致電與病人確認其他時間。';
          this.confirmText = null;
          break;

        default:
          this.rejectItem = null;
          this.rejectReasonIndex = null;
          this.confirmTitle = this.$t('ReserveTable.remind');
          this.confirmText = null;
          break;
      }

      this.confirmDialog = true
    },
    changeStatus() {
      let vm = this
      let completed, appointment_status, insert_to_his
      let completed_reason = null
      switch (this.actionType) {
        case 'allow':
          completed = 1;
          this.messageTitle = this.$t('ReserveTable.moveToComplete');
          this.messageText = this.$t('ReserveTable.pleaseCreateReserve');
          break;

        case 'allowInsert':
          completed = 1;
          insert_to_his = 1;
          this.messageTitle = this.$t('ReserveTable.moveToComplete');
          this.messageText = '此筆約診已移動至已聯繫，且已匯入健保系統';
          break;

        case 'reject':
          completed = 3;
          completed_reason = this.rejectItem.remind_3[this.rejectReasonIndex].reason;
          this.messageTitle = this.$t('ReserveTable.pleaseCall');
          this.messageText = null;
          break;

        case 'extra':
          completed = 2;
          this.messageTitle = this.$t('ReserveTable.moveToComplete');
          this.messageText = null;
          break;

        case 'concat':
          completed = 1;
          this.messageTitle = this.$t('ReserveTable.moveToComplete');
          this.messageText = this.$t('ReserveTable.pleaseCreateReserve');
          break;

        case 'know':
          appointment_status = 1;
          this.messageTitle = null;
          this.messageText = null;
          break;

        default:
          if (Number(this.editAppointment.completed) === 4) {
            appointment_status = 0;
            this.messageTitle = null;
            this.messageText = null;
          } else {
            completed = 0;
            this.messageTitle = null;
            this.messageText = null;
          }
          break;
      }

      const data = {
        ...this.axiosParamData,
        completed,
        completed_reason
      }

      if (completed === 1 && insert_to_his) data.insert_to_his = insert_to_his
      if (appointment_status !== undefined) data.appointment_status = appointment_status
      // if (completed !== undefined) data.completed = completed
      this.confirmDialog = false
      this.pageLoading = true

      updateAppointmentAPI(this.editAppointment.id, data).then(() => {
        if (completed) this.messageDialog = true
        this.actionType = null
        this.getDataFromApi()
        this.getUnreadNotice()

        this.pageLoading = false
      }).catch(e => {
        if (e.response?.data?.fail_code === '500') {
          this.insertFailMsg = e.response?.data?.messages || '無任何訊息'
          this.insertFailDialog = true
        } else if (e.response?.data?.messages) {
          alert(this.$t('ReserveTable.notFoundError'))
        } else {
          alert(this.$t('GENERAL.oops'))
          console.error(e)
        }

        this.pageLoading = false
      })
    },
    setCancelRed(item) {
      if (Number(item.appointment_status) === 0 && Number(item.completed) === 4)
        return 'red--text'
    },
    showInsertSystemBtn(item) {
      return item.system_code === 'leyan';
    }
  },

  beforeDestroy() {
    clearTimeout(this.getDataTimer)
  },
}
</script>

<style scoped>

</style>
