<template>
  <v-menu close-on-content-click>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        icon
        :disabled="disabled"
      >
        <v-icon v-if="!iconPath">mdi-emoticon-happy-outline</v-icon>
        <img
          v-else
          :src="assetURL(`/asset/dentco_icon/${iconPath}`)"
          alt=""
          height="36"
          width="36"
        />
      </v-btn>
    </template>
    <v-card max-width="340" max-height="166" class="overflow-y-auto">
      <v-container fluid>
        <v-row dense>
          <v-col v-for="(icon, index) in icons" :key="index" cols="4" md="3">
            <v-card width="60" height="60" flat class="ma-auto" @click="clickIcon(icon.id)">
              <img
                :src="assetURL(`/asset/dentco_icon/${icon.path}`)"
                :alt="icon.description"
                height="60"
                width="60"
              />
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-menu>
</template>

<script>
import { assetURL } from "../../utils/filters";

export default {
  name: "IconPicker",

  props: {
    icons: {
      type: Array,
      required: true
    },
    value: {
      type: [String, Number],
      required: false
    },
    disabled: {
      type: Boolean,
      required: true
    }
  },

  data() {
    return {
      assetURL,
      iconPath: null
    };
  },

  computed: {
    selectedIcon: {
      get: function () {
        this.setIconPath(this.value)
        return this.value
      },
      set: function (v) {
        this.$emit('update', v)
      }
    }
  },

  watch: {
    value() {
      this.setIconPath(this.value)
    },
    icons: {
      handler(v) {
        if (v && v.length) {
          this.setIconPath(this.value)
        }
      },
      deep: true,
      immediate: true
    }
  },

  methods: {
    clickIcon(v) {
      this.selectedIcon = v
    },
    setIconPath(v) {
      this.iconPath = v ? this.icons.find(e => e.id === Number(v)).path : null
    }
  }
}
</script>

<style scoped>

</style>
