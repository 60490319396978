<template>
  <setting-layout ref="layout" :title="title" :subtitle="subtitle" :hasPermission="hasPermission" @cancel="cancel"
                  @save="save">
    <div v-if="!hasPermission" class="mt-7">
      <v-btn
        dark
        color="tiffany-10"
        depressed
        :block="$vuetify.breakpoint.smAndDown"
        class="px-md-12 rounded-lg"
        href="https://lin.ee/mWs8BoE"
        target="_blank"
      >
        欲顯示醫療服務，請點我升級為進階方案
      </v-btn>
    </div>
    <div class="mt-7">
      <v-row>
        <template v-for="(serviceItem, n) in serviceItems">
          <v-col :key="`serviceItem-${n}`" cols="12" sm="12" class="mb-4">
            <v-row>
              <v-col cols="12">
                <v-checkbox
                  v-model="clinicServiceItemIds"
                  :value="serviceItem.id"
                  true-value="1"
                  false-value="0"
                  class="ma-0"
                  hide-details dense
                  :disabled="!canEdit"
                  @change="changeItems($event, serviceItem)"
                >
                  <template v-slot:label>
                    <div v-html="serviceItem.title"/>
                  </template>
                </v-checkbox>
              </v-col>
              <v-col v-show="clinicServiceItemIds.includes(serviceItem.id)" cols="12">
                <template v-if="parseInt(serviceItem.id) !== 99">
                  <v-textarea
                    :rows="$vuetify.breakpoint.smAndUp ? 2 : 4"
                    no-resize
                    counter
                    filled dense rounded
                    full-width hide-details="auto"
                    class="setting-input ml-8"
                    maxlength="60"
                    :value="clinicServiceItems[serviceItem.id] ? clinicServiceItems[serviceItem.id].intro : ''"
                    :placeholder="'例如: ' + serviceItem.intro"
                    :disabled="!canEdit"
                    @input="updateClinicService($event, serviceItem, serviceItem.id)"
                  />
                </template>

                <template v-else>
                  <div v-for="i in 3">
                    <div
                      style="position: relative; display: flex; width: 100%;"
                    >
                      <div class="ml-8">
                        項目{{ i }}
                      </div>
                      <div class="ml-4" style="flex-grow: 1;">
                        <v-row dense>
                          <v-col cols="auto">
                            <icon-picker
                              :icons="dentcoIcons"
                              :value="clinicServiceItems[`${serviceItem.id}_${i}`] ? clinicServiceItems[`${serviceItem.id}_${i}`].dental_icon_id : null"
                              :disabled="!canEdit"
                              @update="updateClinicService($event, serviceItem, `${serviceItem.id}_${i}`, 'dental_icon_id')"
                            />
                          </v-col>
                          <v-col cols="8" class="flex-grow-1">
                            <v-text-field
                              :key="`serviceItem-other-title-${i}`"
                              filled dense rounded
                              hide-details="auto"
                              class="setting-input mb-4"
                              style="width: 250px; max-width: 80%;"
                              :value="clinicServiceItems[`${serviceItem.id}_${i}`] ? clinicServiceItems[`${serviceItem.id}_${i}`].title : ''"
                              placeholder="請輸入其他項目標題"
                              :disabled="!canEdit"
                              @input="updateClinicService($event, serviceItem, `${serviceItem.id}_${i}`, 'title')"
                            />
                          </v-col>
                        </v-row>

                        <v-textarea
                          :rows="$vuetify.breakpoint.smAndUp ? 2 : 4"
                          no-resize
                          counter
                          :key="`serviceItem-other-intro-${i}`"
                          filled dense rounded
                          full-width hide-details="auto"
                          class="setting-input mb-4"
                          maxlength="60"
                          :value="clinicServiceItems[`${serviceItem.id}_${i}`] ? clinicServiceItems[`${serviceItem.id}_${i}`].intro : ''"
                          :placeholder="'請輸入其他項目內容說明（限制60字以內）'"
                          :disabled="!canEdit"
                          @input="updateClinicService($event, serviceItem, `${serviceItem.id}_${i}`, 'intro')"
                        />
                      </div>
                    </div>
                  </div>
                </template>
              </v-col>
            </v-row>
          </v-col>
        </template>
      </v-row>

    </div>
  </setting-layout>
</template>

<script>
import { mapGetters } from 'vuex';
import SettingLayout from './SettingLayout';
import {
  getMenuServiceItemAPI,
  getClinicServiceItemAPI,
  saveClinicServiceItemAPI,
  getDentcoIconsAPI
} from '../../../api';
import { checkPermissions } from '../../../utils/tool';
import IconPicker from "../../utils/IconPicker.vue";

export default {
  name: "OurServices",
  inject: ['getIsEdit', 'toggleEdit', 'toggleLoading', 'saveAlert'],
  components: {IconPicker, SettingLayout},
  props: {
    title: {
      required: true,
      type: String
    },
    subtitle: {
      required: true,
      type: String
    },
    plan: {
      required: true,
      type: String
    },
    userInfo: {
      required: true,
      type: Object,
    }
  },
  computed: {
    ...mapGetters(['axiosParamData']),
    canEdit() {
      return this.getIsEdit() && this.hasPermission
    },
    hasPermission() {
      return checkPermissions({obj: this.userInfo, type: 'clinic', permissions: this.pagePermission})
    }
  },
  data() {
    return {
      serviceItems: [],
      oClinicServiceItemIds: [],
      clinicServiceItemIds: [],
      oClinicServiceItems: {},
      clinicServiceItems: {},
      pagePermission: ['adv_page'],
      dentcoIcons: []
    }
  },
  mounted() {
    this.getMenuServiceItem();
    this.getClinicServiceItem();
    this.getDentcoIcons();
  },
  methods: {
    save() {
      const settingData = Object.values(this.clinicServiceItems).filter(item => this.clinicServiceItemIds.includes(item.id))

      const data = {
        ...this.axiosParamData,
        setting_data: settingData
      }
      saveClinicServiceItemAPI(data).then(() => {
        this.toggleEdit(false)
        this.getClinicServiceItem()
        this.saveAlert()
      }).catch(e => {
        console.error(e)
        this.toggleLoading(false)
      })
    },
    cancel() {
      this.resetData()
    },
    resetData() {
      this.clinicServiceItemIds = JSON.parse(JSON.stringify(this.oClinicServiceItemIds))
      this.clinicServiceItems = JSON.parse(JSON.stringify(this.oClinicServiceItems))
    },
    getMenuServiceItem() {
      getMenuServiceItemAPI(this.axiosParamData).then(res => {
        this.serviceItems = res.data;
      });
    },
    getClinicServiceItem() {
      this.toggleLoading(true)
      const vm = this;

      vm.oClinicServiceItemIds = [];
      vm.oClinicServiceItems = {};

      getClinicServiceItemAPI({...this.axiosParamData}).then(res => {
        if (res.data.length > 0) {
          let countOther = 0
          res.data.forEach(function (serviceItem) {
            if (!vm.oClinicServiceItemIds.includes(serviceItem.id)) {
              vm.oClinicServiceItemIds.push(serviceItem.id);
            }

            let fakeId
            if (serviceItem.id === '99') {
              countOther++
              fakeId = `99_${countOther}`
            }

            vm.oClinicServiceItems[fakeId || serviceItem.id] = JSON.parse(JSON.stringify(serviceItem))
          });
        }
        this.resetData()
        this.toggleLoading(false)
      })
    },
    updateClinicService(value, serviceItem, id, key = 'intro') {
      if (this.clinicServiceItems[id]) {
        this.clinicServiceItems[id][key] = value
      } else {
        const data = {
          id: serviceItem.id,
          title: null,
          intro: null
        }
        data[key] = value
        if (parseInt(serviceItem.id) !== 99) {
          data.title = serviceItem.title
        }
        if (parseInt(serviceItem.id) === 99) {
          data.dental_icon_id = this.clinicServiceItems[id] ? this.clinicServiceItems[id].dental_icon_id : null
        }
        this.clinicServiceItems[id] = data
      }
    },
    changeItems(event, serviceItem) {
      if (event.includes(serviceItem.id)) {
        const data = {
          id: serviceItem.id,
          title: parseInt(serviceItem.id) === 99 ? null : serviceItem.title,
          intro: null
        }
        if (serviceItem.id === '99') {
          data.dental_icon_id = null
          this.clinicServiceItems['99_1'] = data
        } else {
          this.clinicServiceItems[serviceItem.id] = data
        }
      }
    },
    getDentcoIcons() {
      getDentcoIconsAPI(this.axiosParamData).then(res => {
        this.dentcoIcons = res.data
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.col {
  padding: 6px;
}

.setting-input {
  .v-input__control .v-input__slot {
    min-height: 36px !important;
    border-radius: 10px;
    padding: 0 18px !important;
  }

  &.v-select .v-input__control .v-input__slot {
    padding: 0 8px 0 18px !important;
  }
}
</style>
