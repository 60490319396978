<template>
  <div>
    <v-card-text class="overflow-y-auto" style="height: calc(100vh - 350px);">
      <v-overlay :value="loading" absolute>
        <v-progress-circular
          indeterminate
          size="64"
        ></v-progress-circular>
      </v-overlay>

      <dentco-alert-bar
        :message="`${clinicUser ? '診所' : ''}可使用點數額度：${quota} 點`"
        :btn-link="$enum.DENTCO_CS_LINE"
        :loading="quotaLoading"
        btn-title="聯繫牙醫小幫手儲值"
        class="text-body-1"
      />

      <div class="d-flex flex-column mt-2" style="gap: 16px;">
        <div>
          <template v-if="clinicUser">
            <div>
              <div class="bullet text-subtitle-1 font-weight-medium">
                自動化評價邀請設定
              </div>
              <div class="d-flex pl-6 flex-wrap" style="column-gap: 16px; row-gap: 8px;">
                <template v-if="isEdit">
                  <v-radio-group
                    v-model="changeSettings.survey_type"
                    row
                    hide-details
                    dense
                    class="mt-0"
                  >
                    <v-radio :label="$t('Review.dentcoFirst')" value="1"/>
                    <v-radio :label="$t('Review.googleFirst')" value="2"/>
                  </v-radio-group>
                </template>
                <template v-else>
                  {{ surveyTypeText }}
                </template>
              </div>
            </div>
          </template>
        </div>
        <div>
          <div>
            <div class="bullet text-subtitle-1 font-weight-medium">
              自動化通知設定
            </div>
            <div v-if="settingsData" class="pl-6">
              <SettingCard
                v-if="settingsData.length"
                :is-edit="isEdit"
                :settings-data.sync="settingsData"
                :settings-meta="settingsMeta"
                style="max-height: 500px;"
                :convert-func="convertSmsSettingData"
                :restore-func="restoreSmsSettingData"
              />
              <div v-else>尚無資料可設定</div>
            </div>
          </div>
        </div>
      </div>
    </v-card-text>

    <v-card-actions>
      <v-spacer/>
      <v-btn v-if="!isEdit" color="primary" class="px-8" @click="isEdit = true;">
        <v-icon small>mdi-pencil</v-icon>
        編輯
      </v-btn>
      <template v-else>
        <v-btn color="grey-5" dark class="px-4 px-md-8" @click="cancel">取消修改</v-btn>
        <v-btn color="red-6" dark class="px-4 px-md-8" @click="save">確認修改</v-btn>
      </template>
    </v-card-actions>

    <save-alert
      ref="alertRef"
      :to="to"
      @cancel="cancel"
    />
  </div>
</template>

<script>
import SettingCard from "../../../components/message/setting/SettingCard.vue";
import { mapGetters } from "vuex";
import DentcoAlertBar from "../../../components/utils/DentcoAlertBar.vue";
import { convertSmsSettingData, restoreSmsSettingData } from "../../../utils/messageSetting";
import { getSmsQuotaAPI, getSmsSettingsAPI, updateSmsSettingsAPI } from "../../../api";
import SaveAlert from "../../../components/message/setting/SaveAlert.vue";

export default {
  name: "SmsSetting",
  components: {SaveAlert, DentcoAlertBar, SettingCard},

  data() {
    return {
      loading: false,
      quotaLoading: false,

      quota: 0,
      settings: {},
      changeSettings: {},
      isEdit: false,
      settingsData: null,
      to: null,

      convertSmsSettingData,
      restoreSmsSettingData
    }
  },

  computed: {
    ...mapGetters([
      'axiosParamData',
      'clinicUser',
      'doctorUser',
      'hasOwnChannel'
    ]),
    settingsMeta() {
      return [
        {
          "key": "reserveType",
          "type": "select",
          "title": "發送類別",
          "options": [
            {"text": "LINE綁定連結+預約提醒", "value": "1", showText: 'LINE綁定連結+預約提醒'},
            {"text": "確認是否到診+預約提醒", "value": "2"}
          ]
        },
        {
          "key": "day2Remind",
          "type": "select",
          "title": "兩天前",
          "options": [
            {"text": "10:00", "value": "10:00"},
            {"text": "12:00", "value": "12:00"},
            {"text": "17:00", "value": "17:00"},
            {"text": "不通知", "value": null}
          ]
        },
        {
          "key": "day1Remind",
          "type": "select",
          "title": "一天前",
          "options": [
            {"text": "09:00", "value": "09:00"},
            {"text": "12:00(已額滿)", "value": "12:00", disabled: true, showText: '12:00'},
            {"text": "13:00", "value": "13:00"},
            {"text": "16:00", "value": "16:00"},
            {"text": "不通知", "value": null}
          ]
        },
        {
          "key": "day0Remind",
          "type": "select",
          "title": "當天",
          "options": [
            {"text": "07:00", "value": "07:00"},
            {"text": "08:00", "value": "08:00"},
            {"text": "11:00", "value": "11:00"},
            {"text": "15:00", "value": "15:00"},
            {"text": "不通知", "value": null}
          ]
        },
        {
          "key": "mondayReminderOnFriday",
          "type": "select",
          "title": "週五",
          "options": [
            {"text": "10:30", "value": '10:30'},
            {"text": "15:00(已額滿)", "value": '15:00', disabled: true, showText: '15:00'},
            {"text": "不通知", "value": null}
          ],
          "tooltip": "週五提早發送下週一的提醒"
        },
        {
          "key": "care",
          "type": "select",
          "title": "術後關懷",
          "options": [
            {"text": "10:00", "value": true},
            {"text": "不開啟", "value": false}
          ],
          "tooltip": "系統將於看診後隔天早上十點傳送關懷訊息，邀請患者加入LINE與回報是否有術後不適"
        },
        {
          "key": "halfYearRemind",
          "type": "select",
          "title": "6個月定檢",
          "options": [
            {"text": "09:00", "value": true},
            {"text": "不通知", "value": false}
          ],
          "tooltip": "患者180天內沒有到診紀錄，會自動於第181天上午09:00提醒。"
        },
        {
          "key": "quarterYearRemind",
          "type": "select",
          "title": "3個月定檢",
          "options": [
            {"text": "09:00", "value": true},
            {"text": "不通知", "value": false}
          ],
          "tooltip": "患者90天內沒有到診紀錄，會自動於第91天上午09:00提醒。"
        },
        {
          "key": "threeSixNoteRemind",
          "type": "select",
          "title": "指定時間定檢通知",
          "options": [
            {"text": "09:00", "value": true},
            {"text": "不通知", "value": false}
          ],
          "tooltip": "於預定的日期提醒備註中，包含3m,6m的關鍵字，會自動於該日期09:00提醒。"
        },
        {
          "key": "survey",
          "type": "select",
          "title": "評價",
          "options": [
            {"text": "每次掛號傳送", "value": -1},
            {"text": "間隔30天", "value": 30},
            {"text": "間隔60天", "value": 60},
            {"text": "間隔90天", "value": 90},
            {"text": "間隔120天", "value": 120},
            {"text": "間隔150天", "value": 150},
            {"text": "間隔180天", "value": 180},
            {"text": "不傳送", "value": 0},
          ],
          "tooltip": "看診結束後一小時自動發送評價邀請給患者"
        },
        {
          "key": "createRemind",
          "type": "switch",
          "title": "約診產生"
        },
        {
          "key": "updateRemind",
          "type": "switch",
          "title": "約診異動"
        },
        {
          "key": "cancelRemind",
          "type": "switch",
          "title": "約診刪除"
        }
      ]
    },
    surveyTypeText() {
      if (this.settings.survey_type === '1') return this.$t('Review.dentcoFirst')
      if (this.settings.survey_type === '2') return this.$t('Review.googleFirst')
    }
  },

  mounted() {
    this.getQuota()
    this.getSmsSetting()
  },

  methods: {
    getQuota() {
      this.quotaLoading = true
      getSmsQuotaAPI(this.axiosParamData).then(res => {
        this.quota = res.data.quota
      }).finally(() => {
        this.quotaLoading = false
      })
    },
    getSmsSetting() {
      this.loading = true
      getSmsSettingsAPI(this.axiosParamData).then(res => {
        this.settings = JSON.parse(JSON.stringify(res.data))
        this.setSettingsData()
      }).finally(() => {
        this.loading = false
      })
    },
    save() {
      this.loading = true
      this.changeSettings.setting_data = JSON.parse(JSON.stringify(this.settingsData))
      const data = {
        ...this.axiosParamData,
        ...this.changeSettings
      }
      updateSmsSettingsAPI(data).then(() => {
        this.isEdit = false
        this.getSmsSetting()
        // this.loading = false
      }).catch(e => {
        console.error(e)
        this.loading = false
      })
    },
    cancel() {
      this.setSettingsData()
      this.isEdit = false
    },
    setSettingsData() {
      const settingsData = JSON.parse(JSON.stringify(this.settings.setting_data))
      const codeKey = this.clinicUser ? 'clinic_code' : 'doctor_code'
      const nameKey = this.clinicUser ? 'clinic_name' : 'doctor_name'
      settingsData.map(e => {
        delete e[codeKey]
        delete e[nameKey]
        return e
      })
      this.settingsData = settingsData
      this.changeSettings = JSON.parse(JSON.stringify(this.settings))
    },
    showNotSaveAlert() {
      this.$refs.alertRef.alertDialog = true
    }
  },

  beforeRouteLeave(to, from, next) {
    if (this.isEdit) {
      this.to = to
      this.showNotSaveAlert()
      next(false)
    } else {
      next()
    }
  }
}
</script>

<style scoped>

</style>
