<script>
import Navigation from "../../components/Navigation.vue";

export default {
  name: "MessageIndex",

  components: {Navigation}
}
</script>

<template>
  <v-main>
    <navigation/>

    <div class="d-flex" style="height: 100%;">
      <div>
        <v-tabs vertical background-color="grey-8" style="height: 100%;">
          <v-tab class="px-md-12" :to="{name: 'MessageSendIndex'}">
            訊息群發
          </v-tab>
          <v-tab class="px-md-12" :to="{name: 'smsRefillLog'}">
            點數儲值
          </v-tab>
          <v-tab class="px-md-12" :to="{name: 'messageSetting'}">
            訊息設定
          </v-tab>
          <v-tab class="px-md-12" :to="{name: 'messageLog'}">
            發送紀錄
          </v-tab>
          <v-tab class="px-md-12" :to="{name: 'closeNotificationSetting'}">
            關閉當日訊息
          </v-tab>
        </v-tabs>
      </div>

      <div style="width: 100%;" class="overflow-auto">
        <router-view></router-view>
      </div>
    </div>
  </v-main>
</template>

<style scoped>

</style>
