<template>
  <div>
    <v-tabs>
      <v-tab :to="{name: 'reviewSettingMode'}">
        評價機制設定
      </v-tab>
      <v-tab :to="{name: 'aiSettingMode'}">
        自動化AI評價回覆
      </v-tab>
    </v-tabs>
    <div
      style="max-height: calc(100vh - 120px);"
      :style="$vuetify.breakpoint.smAndUp ? 'width: 100%;' : 'width: 100%;' "
      class="overflow-y-auto"
    >
      <router-view></router-view>
    </div>
  </div>
</template>

<script>

export default {
  name: "ReviewSettings"
}
</script>

<style scoped>
</style>
