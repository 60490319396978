<template>
  <setting-layout ref="layout" :title="title" :subtitle="subtitle" @cancel="cancel" @save="save">
    <div class="mt-7">
      <div v-if="$vuetify.breakpoint.smAndUp" class="opening-table grey-2 rounded-lg grey-7--text">
        <v-row dense class="flex-nowrap table-row">
          <v-col :cols="12/8" class="d-flex align-center justify-center"/>
          <v-col v-for="(day, i) in weeks" :key="i" :cols="12/8" class="d-flex align-center justify-center">{{
              day
            }}
          </v-col>
        </v-row>
        <v-row v-for="(v, k, i) in daySegment" :key="i" dense class="flex-nowrap table-row">
          <v-col :cols="12/8" class="d-flex align-center justify-center">{{ v }}</v-col>
          <v-col
            v-if="clinicOpeningSegment.length"
            v-for="n in 7"
            :key="n"
            :cols="12/8"
            class="d-flex align-center justify-center"
            :class="{'opening-content': canEdit, 'open': clinicOpeningSegment[n-1][k]}"
            :style="canEdit ? 'cursor: pointer' : ''"
            @click="canEdit ? clinicOpeningSegment[n-1][k] = !clinicOpeningSegment[n-1][k] : null"
          >
            <v-icon v-if="clinicOpeningSegment[n-1][k]" color="red-6" size="32">mdi-check-circle</v-icon>
          </v-col>
        </v-row>
      </div>
      <div v-else class="opening-table grey-2 rounded-lg grey-7--text">
        <v-row dense class="table-row">
          <v-col cols="3"/>
          <v-col v-for="(v, idx) in daySegment" :key="idx" cols="3" class="d-flex align-center justify-center">{{ v }}</v-col>
        </v-row>
        <v-row v-for="n in 7" :key="n" dense class="table-row">
          <v-col cols="3" class="d-flex align-center justify-center">{{ weeks[n-1] }}</v-col>
          <v-col
            cols="3"
            class="d-flex align-center justify-center"
            :class="{'opening-content': canEdit, 'open': clinicOpeningSegment[n-1].up}"
            :style="canEdit ? 'cursor: pointer' : ''"
            @click="canEdit ? clinicOpeningSegment[n-1].up = !clinicOpeningSegment[n-1].up : null"
          >
            <v-icon v-if="clinicOpeningSegment[n-1].up" color="red-6" size="32">mdi-check-circle</v-icon>
          </v-col>
          <v-col
            cols="3"
            class="d-flex align-center justify-center"
            :class="{'opening-content': canEdit, 'open': clinicOpeningSegment[n-1].middle}"
            :style="canEdit ? 'cursor: pointer' : ''"
            @click="canEdit ? clinicOpeningSegment[n-1].middle = !clinicOpeningSegment[n-1].middle : null"
          >
            <v-icon v-if="clinicOpeningSegment[n-1].middle" color="red-6" size="32">mdi-check-circle</v-icon>
          </v-col>
          <v-col
            cols="3"
            class="d-flex align-center justify-center"
            :class="{'opening-content': canEdit, 'open': clinicOpeningSegment[n-1].down}"
            :style="canEdit ? 'cursor: pointer' : ''"
            @click="canEdit ? clinicOpeningSegment[n-1].down = !clinicOpeningSegment[n-1].down : null"
          >
            <v-icon v-if="clinicOpeningSegment[n-1].down" color="red-6" size="32">mdi-check-circle</v-icon>
          </v-col>
        </v-row>
      </div>
      <div v-if="Object.keys(clinicBusinessHours).length" class="mt-7">
        <v-row>
          <v-col cols="12" md="4">
            <div class="d-flex">
              <div class="bullet align-self-center">上午診</div>
              <edit-time-plus :times="clinicBusinessHours['morning']" :disabled="!canEdit" class="ml-2"/>
            </div>
          </v-col>
          <v-col cols="12" md="4">
            <div class="d-flex">
              <div class="bullet align-self-center">下午診</div>
              <edit-time-plus :times="clinicBusinessHours['afternoon']" :disabled="!canEdit" class="ml-2"/>
            </div>
          </v-col>
          <v-col cols="12" md="4">
            <div class="d-flex">
              <div class="bullet align-self-center">晚間診</div>
              <edit-time-plus :times="clinicBusinessHours['evening']" :disabled="!canEdit" class="ml-2"/>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
  </setting-layout>
</template>

<script>
import SettingLayout from './SettingLayout';
import { getClinicInfoAPI, updateClinicInfoAPI } from '../../../api';
import { mapGetters } from 'vuex';
import EditTimePlus from '../EditTimePlus';

export default {
  name: "BusinessHours",
  inject: ['getIsEdit', 'toggleEdit', 'toggleLoading', 'saveAlert'],
  components: {EditTimePlus, SettingLayout},
  props: {
    title: {
      required: true,
      type: String
    },
    subtitle: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      weeks: [
        this.$t('Opening.Mon'), this.$t('Opening.Tues'), this.$t('Opening.Wed'),
        this.$t('Opening.Thur'), this.$t('Opening.Fri'), this.$t('Opening.Sat'),
        this.$t('Opening.Sun'),
      ],
      daySegment: {
        up: this.$t('Opening.morning'),
        middle: this.$t('Opening.afternoon'),
        down: this.$t('Opening.night')
      },
      oClinicOpeningSegment: [],
      clinicOpeningSegment: [],
      oClinicBusinessHours: {},
      clinicBusinessHours: {},
    }
  },
  computed: {
    ...mapGetters([
      'axiosParamData',
    ]),
    canEdit() {
      return this.getIsEdit()
    },
  },
  methods: {
    save() {
      this.toggleLoading(true)
      const clinicOpeningSegment = JSON.parse(JSON.stringify(this.clinicOpeningSegment))
      clinicOpeningSegment.unshift(clinicOpeningSegment.splice(clinicOpeningSegment.length - 1, 1)[0])

      const data = {
        ...this.axiosParamData,
        clinic_opening: this.clinicBusinessHours,
        clinic_open_date: clinicOpeningSegment
      }
      updateClinicInfoAPI(data).then(() => {
        this.getData()
        this.toggleEdit(false)
        this.saveAlert()
      }).catch(e => {
        console.error(e)
        this.toggleLoading(false)
      })
    },
    cancel() {
      this.resetData()
    },
    resetData() {
      this.clinicOpeningSegment = JSON.parse(JSON.stringify(this.oClinicOpeningSegment))
      this.clinicBusinessHours = JSON.parse(JSON.stringify(this.oClinicBusinessHours))
    },
    getData() {
      this.toggleLoading(true)
      getClinicInfoAPI(this.axiosParamData).then(res => {
        const d = JSON.parse(JSON.stringify(res.data.clinic_open_date))
        d.push(d.splice(0, 1)[0])
        this.oClinicOpeningSegment = d
        this.oClinicBusinessHours = res.data.clinic_opening
        this.resetData()
      }).finally(() => {
        this.toggleLoading(false)
      })
    }
  },
  mounted() {
    this.getData()
  }
}
</script>

<style scoped lang="scss">
.table-row {
  height: 64px;
  margin: 0 !important;

  div {
    height: 100%;
    border-bottom: 1px solid var(--v-grey-3-base);
    padding: 0;

    &:not(:last-child) {
      border-right: 1px solid var(--v-grey-3-base);
    }
  }

  &:last-child {
    div {
      border-bottom: unset;
    }
  }
}

.bullet::before {
  content: "";
  background: #000000;
  padding-inline: 5px;
  height: 10px;
  margin-inline-end: 10px;
  display: inline-block;
}
</style>