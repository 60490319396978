<template>
  <v-card>
    <v-card-title>{{ $t('SyncGoogleCalendar.askTitle') }}</v-card-title>
    <v-card-text>
      <div class="text-h6 red--text text--accent-2">{{ $t('SyncGoogleCalendar.hint') }}</div>
    </v-card-text>
    <v-card-actions class="justify-space-between">
      <v-btn color="primary" @click="sync">{{ $t('SyncGoogleCalendar.turnOnSync') }}</v-btn>
      <v-btn color="red lighten-1" class="white--text" @click="cancel">{{ $t('SyncGoogleCalendar.turnOffSync') }}</v-btn>
      <v-btn outlined @click="close">{{ $t('SyncGoogleCalendar.ignore') }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { settingSyncGoogleAPI, settingUnSyncGoogleAPI, syncGoogleAPI } from "../../api";
import { mapGetters } from "vuex";

export default {
  name: "SyncGoogleCalendar",
  computed: {
    ...mapGetters([
      'axiosParamData',
    ])
  },
  methods: {
    sync() {
      this.$gapi.grantOfflineAccess().then(res => {
        settingSyncGoogleAPI({...this.axiosParamData, oauth_authenticate_code: res}).then(() => {
          syncGoogleAPI(this.axiosParamData)
          alert('同步中，請稍後...')
          this.close()
        }).catch(e => {
          if (e.response && e.response.data && e.response.data.messages)
            alert(e.response.data.messages)
          else
            alert(this.$t('GENERAL.oops'))
        })
      })
    },
    cancel() {
      settingUnSyncGoogleAPI(this.axiosParamData).then(() => {
        alert(this.$t('SyncGoogleCalendar.successTurnOff'))
        this.close()
      })
    },
    close() {
      this.$emit('close')
    }
  }
}
</script>

<style scoped>

</style>