<template>
  <v-card class="py-8 px-8" rounded="xl">
    <v-overlay :value="loading" absolute z-index="3">
      <v-progress-circular indeterminate size="64"/>
    </v-overlay>
    <v-row>
      <!-- 基本資料區 -->
      <v-col cols="12" class="py-0">
        <div class="d-flex justify-space-between mb-8">
          <h6 class="text-subtitle-1 font-weight-bold">
            看診理念
          </h6>
          <div class="d-flex justify-end" style="gap: 8px">
            <v-btn
              color="grey"
              outlined
              class="text-subtitle-2 rounded-lg"
              @click="onClose"
            >
              取消
            </v-btn>
            <v-btn
              dark
              color="teal lighten-2"
              class="text-subtitle-2 rounded-lg"
              @click="onConfirm"
            >
              儲存
            </v-btn>
          </div>
        </div>
        <div class="d-flex flex-column mb-6" style="gap:0.6rem">
          <v-textarea
            v-model="value"
            cols="5"
            placeholder="請輸入您的看診理念"
            dense
            hide-details
            outlined
          />
        </div>
      </v-col>
      <!-- 社群連結區 -->
    </v-row>
    <v-card-actions>
      <v-spacer />
      <v-btn
        color="grey"
        outlined
        class="text-subtitle-2 rounded-lg"
        @click="onClose"
      >
        取消
      </v-btn>
      <v-btn
        dark
        color="teal lighten-2"
        class="text-subtitle-2 rounded-lg"
        @click="onConfirm"
      >
        儲存
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'ClinicConceptForm',

  props: {
    show: {
      type: Boolean,
      default: false
    },
    doctor: {
      type: Object,
      default: () => {
        return {
          experience: []
        };
      }
    }
  },

  data () {
    return {
      loading: false,
      value: ''
    };
  },

  watch: {
    show: {
      handler (show) {
        if (show && this.doctor) {
          this.value = this.doctor.principle;
        }
      },
      immediate: true,
      deep: true
    }
  },

  methods: {
    onConfirm () {
      this.loading = true
      this.$emit('update', {
        principle: this.value,
        callback: (status) => {
          this.loading = false
          if (status === 'success') {
            this.onClose();
          } else {
            alert('更新失敗，請再試一次')
          }
        }
      });
    },
    onClose () {
      this.$emit('update:show', false);
    }
  }
};
</script>

<style scoped>
.flip-list-move {
  transition: transform 0.5s;
}
</style>
