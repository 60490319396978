<template>
  <div class="px-4 mt-4">
    <v-card outlined flat class="mb-6 mt-4 pt-4 pb-8 px-8">
      <v-overlay :value="loading" absolute>
        <v-progress-circular
          indeterminate
          size="64"
        ></v-progress-circular>
      </v-overlay>
      <guide-tour config-key="review.setting.mode"/>

      <v-row v-if="clinicUser" dense>
        <v-col cols="12" class="mt-4">
        <span class="text-md-h6 text-subtitle-1 font-weight-medium bullet">
          {{ $t('Review.reviewSetting') }}
        </span>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
            <span style="vertical-align: text-bottom;">
              <v-icon v-on="on">
                mdi-help-circle-outline
              </v-icon>
            </span>
            </template>
            在此設定患者看診完後，給予評價的方式。<br>
            Google 評價優先：會詢問是否願意推薦診所，引導願意給好評的患者到 Google map 上填寫評價<br>
            實名詳細評價優先：會發送提醒引導患者在醫師小幫手留下評價，若為好評，會再引導至 Google map 填寫評價
          </v-tooltip>
        </v-col>
        <v-radio-group v-model="surveyType" row hide-details dense class="mt-0 pl-8">
          <v-radio :label="$t('Review.googleFirst')" value="2"/>
          <v-radio :label="$t('Review.dentcoFirst')" value="1"/>
        </v-radio-group>
      </v-row>

      <v-row dense>
        <v-col cols="12" class="mt-4">
          <span class="text-md-h6 text-subtitle-1 font-weight-medium bullet">Google 評價爬取設定</span>
        </v-col>
        <v-col cols="12" class="pl-8">
          <v-select
            v-if="clinicUser"
            v-model="selectDic"
            :items="doctorList"
            item-value="id"
            item-text="doctor_name"
            hide-details
            outlined
            dense
            style="max-width: 320px;"
          />
          <v-select
            v-else
            v-model="selectDic"
            :items="clinicList"
            item-value="id"
            item-text="clinic_name"
            hide-details
            outlined
            dense
            style="max-width: 320px;"
          />
          <div v-if="selectDic">
            <v-checkbox
              v-model="selectedGoogleCrawlerMode"
              :value="1"
              hide-details
              dense
              style="width: fit-content;"
              @click="selectNewDic = false"
            >
              <template v-slot:label>
                <div>
                  抓取符合醫師姓氏的評論
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on">
                        mdi-help-circle-outline
                      </v-icon>
                    </template>
                    Google評論中出現醫師姓氏，即會抓取至該醫師頁面顯示
                    <br>
                    範例：醫師全名為王小明，評論中寫到”王醫師” 會抓取與顯示在醫師頁面
                  </v-tooltip>
                </div>
              </template>
            </v-checkbox>
            <v-checkbox
              v-model="selectedGoogleCrawlerMode"
              :value="2"
              hide-details
              dense
              style="width: fit-content;"
              @click="selectNewDic = false"
            >
              <template v-slot:label>
                <div>
                  抓取符合醫師全名的評論
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on">
                        mdi-help-circle-outline
                      </v-icon>
                    </template>
                    Google評論中出現醫師全名，才會抓取至該醫師頁面顯示
                    <br>
                    範例：醫師全名為王小明，評論中寫到”王小明” 會抓取與顯示在醫師頁面
                  </v-tooltip>
                </div>
              </template>
            </v-checkbox>
            <v-checkbox
              v-model="selectedGoogleCrawlerMode"
              :value="3"
              hide-details
              dense
              style="width: fit-content;"
              @click="selectNewDic = false"
            >
              <template v-slot:label>
                <div>
                  抓取符合醫師名字的評論
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on">
                        mdi-help-circle-outline
                      </v-icon>
                    </template>
                    Google評論中出現醫師名字，才會抓取至該醫師頁面顯示
                    <br>
                    範例：醫師全名為王小明，評論中寫到”小明” 會抓取與顯示在醫師頁面
                  </v-tooltip>
                </div>
              </template>
            </v-checkbox>
          </div>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" class="mt-4">
          <span class="text-md-h6 text-subtitle-1 font-weight-medium bullet">{{ $t('Review.publicSetting') }}</span>
        </v-col>
        <v-col cols="12" class="pl-8">
          <v-select
            v-if="clinicUser"
            v-model="selectDoctor"
            :items="doctorList"
            item-value="doctor_code"
            item-text="doctor_name"
            hide-details
            outlined
            dense
            style="max-width: 320px;"
          />
          <div v-if="(clinicUser && selectDoctor) || doctorUser">
            <v-radio-group v-model="reviewPublish" hide-details dense class="mt-0">
              <v-radio
                v-for="(setting, i) in publishItems"
                :key="i"
                :label="setting"
                :value="i"
                style="width: fit-content;"
                @click="selectNewDoctor = false"
              />
            </v-radio-group>
          </div>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import GuideTour from "@/components/utils/GuideTour.vue";
import {
  getClinicListAPI, getCrawlerGoogleReviewModeAPI,
  getDoctorListAPI,
  getReviewRuleAPI,
  getReviewTypeAPI, updateCrawlerGoogleReviewModeAPI,
  updateReviewRuleAPI,
  updateReviewTypeAPI
} from "@/api";
import { mapGetters } from "vuex";

export default {
  name: "ModeSetting",

  components: {GuideTour},

  data() {
    return {
      loading: false,
      surveyType: null,

      doctorList: [{id: null, doctor_code: null, doctor_name: this.$t('Review.chooseDoctor')}],
      clinicList: [{id: null, clinic_code: null, clinic_name: this.$t('Review.chooseClinic')}],
      selectDoctor: null,
      reviewPublish: null,
      publishItems: [this.$t('Review.showAll'), this.$t('Review.hiddenNegative'), this.$t('Review.hiddenAll')],
      selectNewDoctor: true,

      selectDic: null,
      selectNewDic: true,
      selectedGoogleCrawlerMode: []
    }
  },

  computed: {
    ...mapGetters([
      'axiosParamData',
      'clinicUser',
      'doctorUser',
    ])
  },

  watch: {
    surveyType(v, o) {
      if (o) {
        this.loading = true
        const data = {
          ...this.axiosParamData,
          survey_type: v,
        }
        updateReviewTypeAPI(this.axiosParamData.clinic_code, data).then(() => {
          this.getReviewType()
        }).finally(() => {
          this.loading = false
        })
      }
    },
    selectDoctor(v) {
      if (v) {
        this.selectNewDoctor = true
        this.getReviewRule(v)
      }
    },
    reviewPublish(v) {
      if (!this.selectNewDoctor) {
        this.loading = true
        const data = {
          ...this.axiosParamData,
          review_auth_status: v,
        }
        const doctor = this.selectDoctor || this.axiosParamData.doctor_code
        updateReviewRuleAPI(doctor, data).then(() => {
          this.getReviewRule(doctor)
        }).finally(() => {
          this.loading = false
        })
      }
    },
    selectDic(v) {
      if (v) {
        this.selectNewDic = true
        this.getCrawlerGoogleReviewMode()
      }
    },
    selectedGoogleCrawlerMode: {
      handler(newV, oldV) {
        if (!this.selectNewDic && JSON.stringify(newV) !== JSON.stringify(oldV)) {
          this.loading = true
          const data = {
            ...this.axiosParamData,
            google_crawling_mode: newV
          }
          updateCrawlerGoogleReviewModeAPI(this.selectDic, data).then(res => {
            this.getCrawlerGoogleReviewMode()
          }).finally(() => {
            this.loading = false
          })
        }
      },
      deep: true
    }
  },

  methods: {
    getReviewType() {
      getReviewTypeAPI(this.axiosParamData).then(res => {
        this.surveyType = res.data.survey_type
      }).finally(() => {
        this.loading = false
      })
    },
    getReviewRule(v = null) {
      const params = {
        ...this.axiosParamData
      }
      if (v) params.doctor_code = v
      getReviewRuleAPI(params).then(res => {
        this.reviewPublish = parseInt(res.data.review_auth_status)
      }).finally(() => {
        this.loading = false
      })
    },
    getCrawlerGoogleReviewMode() {
      getCrawlerGoogleReviewModeAPI(this.selectDic, this.axiosParamData).then(res => {
        this.selectedGoogleCrawlerMode = res.data.google_crawling_mode.map(e => Number(e))
      }).finally(() => {
        this.loading = false
      })
    },

  },

  mounted() {
    if (this.clinicUser) {
      getDoctorListAPI(this.axiosParamData).then(res => {
        this.doctorList.push(...res.data)
      })
      this.getReviewType()
    } else {
      getClinicListAPI(this.axiosParamData).then(res => {
        this.clinicList.push(...res.data)
      })
      this.getReviewRule()
    }
  }
}
</script>

<style scoped>
.bullet::before {
  content: "";
  background: #000000;
  padding-inline: 5px;
  height: 10px;
  margin-inline-end: 10px;
  display: inline-block;
}
</style>