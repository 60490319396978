<template>
  <v-sheet class="mb-2 pa-4" :id="`notice-${notice.id}`">
    <v-badge :value="!parseInt(notice.is_read)" color="red" dot offset-x="10" class="mb-0" style="width: 100%">
      <v-row class="ma-0">
        <v-col v-if="notice.file_name" cols="12" sm="4"class="align-self-center text-center">
          <img :src="notice.file_name" alt="" style="max-width: 100%; max-height: 100%">
        </v-col>
        <v-col cols="12" :sm="notice.file_name ? 8 : 12">
          <div class="d-flex flex-column" style="height: 100%">
            <div class="text--secondary text-caption mb-2">日期：{{ notice.created_at | shortDate }}</div>
            <div class="text-pre-line mb-auto" v-text="notice.content"/>
            <div class="text-end">
              <v-btn
                v-if="notice.link_1"
                :href="notice.link_1"
                target="_blank"
                text
                color="blue"
                class="text-decoration-underline">了解更多
              </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-badge>
  </v-sheet>
</template>

<script>
export default {
  name: "NoticeCard",
  props: ['notice'],
}
</script>

<style scoped>

</style>