<template>
  <div>
    <v-app-bar dark app fixed>
      <v-toolbar-title style="cursor: pointer" @click="toHomePage">{{ getName }}</v-toolbar-title>

      <template v-if="extension" v-slot:extension>
        <slot name="extension"/>
      </template>
      <v-spacer/>
      <template v-if="$vuetify.breakpoint.smAndUp" v-for="(item, i) in barItems">
        <v-badge
          color="red"
          content="New"
          :value="item.isNew"
          offset-x="30"
          offset-y="8"
        >
          <v-btn :key="i" text :to="item.path" exact @click="refreshPage(item.path)">{{ item.name }}</v-btn>
        </v-badge>
      </template>
      <v-menu
        v-model="menu"
        :close-on-content-click="false"
        min-width="300"
        max-width="90%"
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-badge
              color="red"
              :content="notification.count > 99 ? '99+' : notification.count"
              :value="notification.count"
            >
              <v-icon>mdi-bell-outline</v-icon>
            </v-badge>
          </v-btn>
        </template>

        <v-card width="400">
          <v-card-title class="pb-1">通知</v-card-title>
          <v-card-text style="max-height: 80vh" class="overflow-y-auto">
            <v-sheet>
              <div class="d-flex">
                <div class="text-subtitle-1 align-self-center">系統公告
                  <v-avatar v-if="notification.notices" color="red" size="18" style="vertical-align: baseline">
                    <span class="white--text text-caption" v-text="notification.notices"/>
                  </v-avatar>
                </div>
                <v-spacer/>
                <v-btn text color="grey darken-1" :to="{name: 'notice'}" class="px-1" active-class="no-active"
                       @click="refreshPage({name: 'notice'})">查看全部
                </v-btn>
              </div>
              <v-list v-show="notices.length">
                <v-list-item
                  v-for="notice in notices"
                  :key="notice.id"
                  class="mb-1 px-1 no-active"
                  :href="notice.link_1"
                  :target="notice.link_1 ? '_blank' : null"
                  @click="setRead(notice)"
                >
                  <v-list-item-content class="pa-0">
                    <v-badge :value="!parseInt(notice.is_read)" color="red" dot offset-x="9" offset-y="10" class="mb-0">
                      <div class="d-flex">
                        <v-img v-if="notice.file_name" :src="notice.file_name" max-width="60" max-height="60"
                               class="mr-2 align-self-center"/>
                        <v-img v-else src="https://dentco.s3.us-east-2.amazonaws.com/asset/crm/logo_square.webp"
                               max-width="60" max-height="60"
                               class="mr-2 align-self-center"/>
                        <div class="flex-fill">
                          <div class="text--secondary text-caption mb-1">{{ notice.created_at | shortDate }}</div>
                          <div class="text-pre-line overflow-hidden multiline-ellipsis" v-text="notice.content"
                               style="max-height: 47px; word-break: break-all"/>
                          <div class="text-end mt-1">
                            <v-btn
                              v-if="notice.link_1"
                              :href="notice.link_1"
                              target="_blank"
                              text
                              small
                              color="blue"
                              class="text-decoration-underline"
                            >了解更多
                            </v-btn>
                          </div>
                        </div>
                      </div>
                    </v-badge>

                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <div v-show="!notices.length" class="text-center grey--text text--darken-1 mt-2">
                目前沒有任何系統公告
              </div>
            </v-sheet>
            <v-divider/>
            <notification-card
              :items="appointments"
              no-data="沒有未處理的線上約診"
              title="線上約診"
              :count="notification.appointments"
              :to-path="{name: 'onlineReserve'}"
              :get-content="appointmentContent"
              @refresh="refreshPage"
            />
            <v-divider/>
            <notification-card
              :items="negativeReviewItems"
              no-data="沒有未讀的站內負面評論"
              title="即時負評通知"
              :count="notification.negative_review"
              :to-path="{name: 'reviewList'}"
              :get-content="negativeReviewContent"
              :get-date-time="negativeReviewDateTime"
              @refresh="refreshPage"
            />
            <v-divider/>
            <notification-card
              :items="rescheduleItems"
              no-data="沒有未處理的改約名單"
              title="改約名單"
              :count="notification.change_reserves"
              :to-path="{name: 'reschedule'}"
              :get-content="rescheduleContent"
              @refresh="refreshPage"
            />
            <notification-card
              :items="doctorCareItems"
              no-data="沒有未處理的術後關懷"
              title="術後關懷"
              :count="notification.care"
              :to-path="{name: 'operativeCare'}"
              :get-content="doctorCareContent"
              @refresh="refreshPage"
            />
          </v-card-text>
        </v-card>
      </v-menu>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" app temporary dark right height="100%">
      <v-list nav dense>
        <v-list-item-group v-model="group" dark mandatory>
          <v-list-item v-for="(item, index) in navItems" :to="item.path" exact :key="index"
            :style="{ display: item.hidden ? 'none' : '' }"             
            @click="refreshPage(item.path)"
          >
            <template >
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ item.name }}</v-list-item-title>
            </template>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <template v-slot:append>
        <div class="px-2 pt-2 pb-8">
          <v-btn block color="blue-6" class="mb-2" :to="{name: 'accountSettings'}">修改帳號密碼</v-btn>
          <v-btn block color="primary" @click="clearUserData">
            {{ $t('Navigation.logout') }}
          </v-btn>
          <v-btn v-if="isSuper" :to="{name: 'switchAccount'}" block class="mt-2" color="red-6">
            神秘按鈕
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>

  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { getAppointmentAPI, getCareAPI, getRescheduleAPI, getTopNoticeAPI, getUnreadNegativeReviewAPI } from "../api";
import { formatDate, shortDate } from "../utils/filters";
import NotificationCard from "./NotificationCard";

export default {
  name: "Navigation",
  components: {NotificationCard},
  props: {
    extension: Boolean,
  },
  data() {
    return {
      drawer: null,
      group: null,
      menu: false,
      notices: [],
      appointments: [],
      rescheduleItems: [],
      negativeReviewItems: [],
      doctorCareItems: [],
      checkNotificationTimer: null,
    }
  },
  computed: {
    ...mapState({
      user: state => state.user,
      notification: state => state.notification,
    }),
    ...mapGetters([
      'clinicUser',
      'doctorUser',
      'axiosParamData',
      'isSpecialClinic',
      'isSuper'
    ]),
    navItems() {
      let items = [
        {
          path: {name: 'home'},
          icon: 'mdi-calendar-multiselect',
          name: this.$t('Navigation.reserveList'),
          showTop: {
            show: true,
            sort: 0,
          },
          isNew: false,
          canSpecial: false
        },
        {
          path: {name: 'onlineReserve'},
          icon: 'mdi-calendar-plus',
          name: this.$t('Navigation.onlineReserveList'),
          showTop: {
            show: true,
            sort: 1,
          },
          isNew: false,
          canSpecial: true
        },
        {
          path: {name: 'reschedule'},
          icon: 'mdi-calendar-remove',
          name: this.$t('Navigation.reschedule'),
          showTop: {
            show: true,
            sort: 2,
          },
          isNew: false,
          canSpecial: true
        },
        {
          path: {name: 'patients'},
          icon: 'mdi-account-settings',
          name: this.$t('Navigation.patientList'),
          isNew: false,
          canSpecial: false
        },
        {
          path: {name: 'freeTime'},
          icon: 'mdi-calendar-question',
          name: this.$t('Navigation.freeTime'),
          showTop: {
            show: true,
            sort: 5,
          },
          isNew: false,
          canSpecial: true
        },
        {
          path: {name: 'review'},
          icon: 'mdi-note-text-outline',
          name: this.$t('Navigation.reviewList'),
          isNew: false,
          canSpecial: true
        },
        {
          path: {name: 'upload'},
          icon: 'mdi-file-upload-outline',
          name: this.$t('Navigation.upload'),
          isNew: false,
          canSpecial: true
        },
        {
          path: {name: 'settings'},
          icon: this.clinicUser ? 'mdi-home-edit-outline' : 'mdi-account-edit-outline',
          name: this.clinicUser ? this.$t('Navigation.clinicInfo') : this.$t('Navigation.doctorInfo'),
          isNew: false,
          canSpecial: true
        },
        {
          path: {name: 'analytics'},
          icon: 'mdi-chart-line-variant',
          name: this.$t('Navigation.analytics'),
          isNew: false,
          canSpecial: true
        },
        {
          path: {name: 'adminSettings'},
          icon: 'mdi-cogs',
          name: this.$t('Navigation.adminSetting'),
          isNew: false,
          canSpecial: true
        },
        {
          path: {name: 'message'},
          icon: 'mdi-send-circle-outline',
          name: this.$t('Navigation.message'),
          isNew: false,
          showTop: {
            show: true,
            sort: 4,
          },
          canSpecial: true
        },
        {
          ...(this.clinicUser ? {
            path: { name: 'question' },
            icon: 'mdi-comment-question-outline',
            name: this.$t('Navigation.questions'),
            isNew: false,
            canSpecial: true,
          } : {
            hidden: true
          })
        },
        {
          path: {name: 'notice'},
          icon: 'mdi-bullhorn',
          name: '系統公告',
          isNew: false,
          canSpecial: true
        },
        {
          path: {name: 'operativeCare'},
          icon: 'mdi-hand-heart',
          name: '術後關懷',
          showTop: {
            show: true,
            sort: 3,
          },
          isNew: this.$moment() < this.$moment('2022-11-01'),
          canSpecial: true
        },
        {
          path: {name: 'superLink'},
          icon: 'mdi-link-plus',
          name: '連結小工具',
          isNew: false,
          canSpecial: true
        },
        // {
        //   path: { name: 'openaiConversation' },
        //   icon: 'mdi-robot',
        //   name: 'AI智能助理詢問紀錄',
        //   isNew: false,
        //   canSpecial: true
        // },
        {
          path: { name: 'AICustomerService' },
          icon: 'mdi-robot',
          name: 'AI智能客服',
          isNew: false,
          canSpecial: true
        },
      ]
      if (this.isSpecialClinic)
        items = items.filter(e => e.canSpecial)
      return items
    },
    getName() {
      if (this.clinicUser) {
        return this.user.clinic_name
      } else if (this.doctorUser) {
        return this.user.doctor_name + this.$t('Navigation.doctor')
      }
    },
    barItems() {
      const showItems = this.navItems.filter(e => e.showTop && e.showTop.show)
      return showItems.sort((a, b) => {
        return a.showTop.sort - b.showTop.sort
      })
    }
  },
  watch: {
    menu(v) {
      if (v) this.handleClickBell()
    }
  },
  methods: {
    ...mapMutations({
      clearUserData: 'clearUserData'
    }),
    ...mapActions({
      getUnreadNotice: 'getUnreadNotice',
      setNoticeRead: 'setNoticeRead',
    }),
    toHomePage() {
      if (this.$router.currentRoute.name !== 'home')
        this.$router.push({name: 'home'})
    },
    refreshPage(path) {
      if (this.$router.currentRoute.name === path.name) this.$router.go(0)
    },
    getTopNotices() {
      this.getUnreadNotice()
      getTopNoticeAPI(this.axiosParamData).then(res => {
        this.notices = res.data.slice(0, 3)
      })
    },
    setRead(notice) {
      this.setNoticeRead([notice.id]).then(() => {
        this.getTopNotices()
        if (!notice.link_1) {
          this.$router.push({name: 'notice', hash: `#notice-${notice.id}`})
          this.refreshPage({name: 'notice'})
        }
      })
    },
    handleClickBell() {
      this.getTopNotices()
      getAppointmentAPI({...this.axiosParamData, limit: 3, page: 1, status: 0}).then(res => {
        this.appointments = res.data.data
      })
      getRescheduleAPI(this.axiosParamData).then(res => {
        this.rescheduleItems = res.data.data.slice(0, 3)
      })
      getCareAPI({...this.axiosParamData, limit: 3, page: 1, reply_status: 0}).then(res => {
        this.doctorCareItems = res.data.data
      })
      getUnreadNegativeReviewAPI(this.axiosParamData).then(res => {
        this.negativeReviewItems = res.data.data
      })
    },
    appointmentContent(item) {
      return `${item.is_np === '0' ? '新患者' : '舊患者'} ${item.name}
      與 ${item.doctor_name}醫師 預約時間 ${item.book_time} ${item.item} 於 ${item.clinic_name}`
    },
    rescheduleContent(item) {
      return `原訂 ${shortDate(item.start_time)} ${item.doctor_name}醫師的預約已被病患 ${item.name} 點擊改約，
      改約原因為 ${item.change_reason} ，${parseInt(item.call_clinic_status) === 0 ? '請與病人聯繫改約' : '取消不需致電聯繫'}。`
    },
    doctorCareContent(item) {
      return `${shortDate(item.start_time)}就診病人${item.name}反應治療後不適，點擊查看更多資訊`
    },
    checkNotification() {
      clearTimeout(this.checkNotificationTimer)
      this.getUnreadNotice().then(() => {
        this.checkNotificationTimer = setTimeout(() => {
          this.checkNotification()
        }, 1000 * 60 * 60)
      })
    },
    negativeReviewContent(item) {
      switch (Number(item.type)) {
        case 1:
          return `您收到一筆來自 ${item.patient_name} 對於 ${ formatDate(item.reserve_start_date_time)} 於 ${item.clinic_name} ${item.doctor_name}${item.doctor_title} 的站內負面評價。`
        case 3:
          return `您收到一筆來自 ${item.patient_name} 的Google負面評價`
        default:
          return `您收到一筆來自 ${item.patient_name} 的負面評價`
      }
    },
    negativeReviewDateTime(item) {
      return formatDate(item.created_at)
    }
  },
  mounted() {
    this.checkNotification()
  },
  beforeDestroy() {
    clearTimeout(this.checkNotificationTimer)
  },
}
</script>

<style scoped>
.multiline-ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.v-list-item--active.no-active::before {
  display: none
}
</style>
