<template>
  <v-row
    dense
    class="align-center"
    :style="hasPermission ? '' : 'opacity: 0.4;'"
  >
    <v-col cols="12" md="3" class="bullet py-2">
      {{ social.title }}
    </v-col>
    <v-col cols="12" md="9" class="py-2">
      <v-row>
        <v-radio-group
          :value="social.linePageStatus"
          :disabled="!canEdit"
          style="width: 100%;"
          class="px-2"
          @change="$emit('updateValue', $event, 'line_page', {valueKey: 'linePageStatus'})"
        >
          <v-radio
            label="不開放"
            value="0"
          ></v-radio>
          <v-radio
            label="使用串接帳號（建議選項）"
            value="1"
            @click="checkChannelExist"
          ></v-radio>
          <v-radio
            label="自行輸入連結"
            value="2"
          ></v-radio>
          <v-text-field
            :value="social.value"
            :disabled="!(canEdit && social.linePageStatus === '2')"
            class="setting-input pl-6"
            filled
            rounded
            single-line
            placeholder="請輸入連結（注意：自行輸入將無法從小幫手系統追蹤來源）"
            hide-details="auto"
            @input="$emit('updateValue', $event, 'line_page', {valueKey: 'value'})"
          />
        </v-radio-group>
        <v-dialog v-model="lineAlertDialog" max-width="400">
          <v-card>
            <v-card-title>
              <div>
                您沒有串接的LINE官方賬號，請輸入其他連結，或是詢問 <a :href="$enum.DENTCO_CS_LINE" target="_blank">客服人員</a>
              </div>
            </v-card-title>
          </v-card>
        </v-dialog>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { getMessageSettingAPI } from "../../../api";
import { mapGetters } from "vuex";

export default {
  name: "GuideLineRow",

  props: {
    social: {
      type: Object,
      required: true
    },
    hasPermission: {
      type: Boolean,
      default: false
    },
    canEdit: {
      default: false,
      type: Boolean
    },
  },

  data() {
    return {
      lineAlertDialog: false,
    }
  },

  computed: {
    ...mapGetters([
      'axiosParamData',
    ]),
  },

  methods: {
    async checkChannelExist() {
      const res = await getMessageSettingAPI(this.axiosParamData)
      const hasChannel = !!res.data.own_channel
      if (!hasChannel) {
        this.lineAlertDialog = true
        this.$emit('updateValue', '0', 'line_page', {valueKey: 'linePageStatus'})
      }
    }
  }
}
</script>

<style scoped>
.bullet::before {
  content: "";
  background: #000000;
  padding-inline: 5px;
  height: 10px;
  margin-inline-end: 10px;
  display: inline-block;
}
</style>
