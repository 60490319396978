<template>
  <v-main>
    <navigation v-if="!liffUser"/>

    <div class="d-flex overflow-hidden" :class="{'flex-column': $vuetify.breakpoint.smAndDown}" style="height: calc(100vh - 64px)">
      <div>
        <v-tabs :vertical="$vuetify.breakpoint.mdAndUp" background-color="grey-8" :style="{height: $vuetify.breakpoint.mdAndUp ? '100%' : 'unset'}">
          <v-tab class="px-md-12" :to="{name: 'reviewList'}">
            站內評價列表
          </v-tab>
          <v-tab class="px-md-12" :to="{name: 'googleReviewList'}">
            Google評價列表
          </v-tab>
          <v-tab class="px-md-12" :to="{name: 'reviewSettings'}">
            評價設定
          </v-tab>
        </v-tabs>
      </div>
      <v-sheet width="100%" max-height="100%" class="overflow-y-auto">
        <router-view></router-view>
      </v-sheet>
    </div>
  </v-main>
</template>

<script>
import { mapGetters } from "vuex";
import Navigation from "@/components/Navigation.vue";

export default {
  name: "ReviewIndex",

  components: {
    Navigation
  },

  computed: {
    ...mapGetters([
      'liffUser'
    ]),
  }
}
</script>

<style scoped>

</style>