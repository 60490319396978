<template>
  <div>
    <guide-tour config-key="message.send.sms"/>

    <v-stepper v-model="step" vertical class="elevation-0">
      <v-stepper-step :complete="step > 1" :step="1" editable>
        {{ $t('SendStep.filterTitle') }}
        <small v-show="step === 1" class="mt-2 grey-5--text">{{ $t('SendStep.filterDesc') }}</small>
      </v-stepper-step>
      <v-stepper-content :step="1">
        <patient-filter
          ref="patientFilter"
          :value.sync="filterPatients"
          :count.sync="filterPatientCount"
          @nextStep="nextStep"
        />
      </v-stepper-content>

      <v-stepper-step v-show="!useCondition" :complete="step > 2" :step="2" :editable="step > 1" class="mt-2">
        {{ $t('SendStep.countSelect', {count: selectPatients.length}) }}
      </v-stepper-step>
      <v-stepper-content v-show="!useCondition" :step="2">
        <patient-selection
          ref="patientSelection"
          :value.sync="selectPatients"
          :patients="patients"
          :max-select="maxSelect"
          @nextStep="nextStep"
        />
      </v-stepper-content>

      <v-stepper-step :complete="step > 3" :step="3" :editable="step > 2" class="mt-2">
        編輯訊息
        <small v-show="step === 3" class="mt-2 grey-5--text">只能輸入文字訊息，編輯後點擊「確定內容」</small>
      </v-stepper-step>
      <v-stepper-content :step="3">
        <message-editor
          :value.sync="messageContent"
          @nextStep="nextStep"
        />
      </v-stepper-content>

      <v-stepper-step :step="4">
        {{ $t('SendStep.confirmSend') }}
      </v-stepper-step>
      <v-stepper-content :step="4">
        <confirm-content :content="messageContent" :total="smsSendCount" @confirm="confirm"/>
      </v-stepper-content>
    </v-stepper>

    <confirm-dialog
      v-model="toggleConfirmDialog"
      :title="confirmTitle"
      :content="confirmContent"
      :content-class="contentClass"
      :confirm-btn-text="confirmText"
      :cancel-btn-text="cancelText"
      :confirm-btn-color="confirmColor"
      :cancel-btn-color="cancelColor"
      @confirm="confirmFunc"
    >
    </confirm-dialog>
    <v-dialog v-model="quotaDialog" max-width="fit-content">
      <v-card class="py-md-10 px-md-8">
        <v-card-title class="justify-center" style="font-size: 24px;">
          訊息餘額
        </v-card-title>
        <v-card-text class="mt-2">
          <div class="text-pre-line text-center">
            <div style="font-size: 16px;">
              目前剩餘簡訊額度: {{ smsQuota }} 點，<br>
              本次預計使用： {{ useQuota }} 點
            </div>
            <div class="mt-4">
              若不足但選擇發出，將只有部分患者收到唷！
            </div>
          </div>
        </v-card-text>
        <v-card-actions class="flex-wrap">
          <v-btn dark color="grey-5" block class="ma-0" target="_blank" :href="$enum.DENTCO_CS_LINE">聯繫客服</v-btn>
          <v-btn color="red-6" dark block class="ml-0 mt-2" :loading="sendLoading" @click="confirmSend">確定送出</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import GuideTour from "@/components/utils/GuideTour.vue";
import PatientFilter from "@/components/message/send/PatientFilter.vue";
import PatientSelection from "@/components/message/send/PatientSelection.vue";
import { get_age, getGender } from "@/utils/filters";
import MessageEditor from "@/components/message/send/MessageEditor.vue";
import ConfirmContent from "@/components/message/send/ConfirmContent.vue";
import ConfirmDialog from "@/components/utils/ConfirmDialog.vue";
import { mapGetters } from "vuex";
import { getSmsQuotaAPI, sendMessageAPI, sendSMSMessageAPI } from "@/api";

export default {
  name: 'SmsSendStep',

  components: {
    ConfirmDialog,
    ConfirmContent,
    MessageEditor,
    PatientSelection,
    PatientFilter,
    GuideTour
  },

  data() {
    return {
      sendLoading: false,
      step: 1,
      filterPatientCount: 0,
      filterPatients: [],
      selectPatients: [],
      messageContent: null,

      SmsCharLimit: 70,  // 簡訊每 70 字，算一封錢
      maxSelect: 500,
      splitSize: 100,
      useCondition: false,

      toggleConfirmDialog: false,
      confirmTitle: '',
      confirmContent: '',
      contentClass: 'mt-2 red-6--text justify-center d-flex',
      confirmText: '',
      cancelText: '',
      confirmColor: null,
      cancelColor: null,
      confirmFunc: (payload) => {
        const {callback} = payload;
        callback && callback()
      },

      smsQuota: 0,
      quotaDialog: false
    }
  },

  computed: {
    ...mapGetters([
      'axiosParamData',
      'clinicUser',
    ]),
    patients() {
      return this.filterPatients.map(e => {
        let _ = `${e.patient_code}-${e.name}-${e.phone} ${e.name}`
        if (e.birthday) _ += ` ${get_age(parseInt(e.birthday) * 1000)}${this.$t('SendStep.age')}`
        if (getGender(e.sex)) _ += ` ${getGender(e.sex)}${this.$t('SendStep.genderUnit')}`
        return _
      })
    },
    smsSendCount() {
      return this.useCondition ? this.filterPatientCount : this.selectPatients.length
    },
    useQuota() {
     return Math.ceil(this.messageContent?.length / this.SmsCharLimit) * this.smsSendCount
    }
  },

  methods: {
    nextStep() {
      switch (this.step) {
        case 1:
          if (this.filterPatientCount > this.maxSelect) {
            this.confirmTitle = `篩選病人數大於 ${this.maxSelect} 人`
            this.confirmContent = `無法進一步篩選病人，\n將直接選取所有符合條件的對象 (共 ${this.filterPatientCount} 人)`
            this.contentClass = 'mt-2 red-6--text justify-center d-flex text-body-1'
            this.confirmText = this.$t('SendStep.accept')
            this.cancelText = this.$t('SendStep.refuse')
            this.confirmColor = 'primary'
            this.cancelColor = 'grey-4'
            this.confirmFunc = (payload) => {
              const {callback} = payload;
              this.useCondition = true
              this.step = 3
              callback && callback()
            }
            this.toggleConfirmDialog = true
          } else {
            this.useCondition = false
            this.step = 2
          }
          break
        case 2:
          if (!this.useCondition && !this.selectPatients.length) {
            this.confirmTitle = `請至少選擇一位發送對象`
            this.confirmContent = ``
            this.contentClass = 'mt-2 red-6--text justify-center d-flex text-body-1'
            this.confirmText = '我知道了，關閉視窗'
            this.cancelText = ''
            this.confirmColor = 'grey-4'
            this.confirmFunc = (payload) => {
              const {callback} = payload;
              callback && callback()
            }
            this.toggleConfirmDialog = true
          } else {
            this.step = 3
          }
          break
        case 3:
          if (!this.messageContent?.trim()) {
            this.confirmTitle = `請輸入發送內容`
            this.confirmContent = ``
            this.contentClass = 'mt-2 red-6--text justify-center d-flex text-body-1'
            this.confirmText = '我知道了，關閉視窗'
            this.cancelText = ''
            this.confirmColor = 'grey-4'
            this.confirmFunc = (payload) => {
              const {callback} = payload;
              callback && callback()
            }
            this.toggleConfirmDialog = true
          } else {
            this.step = 4
          }
          break
      }
    },
    async confirm() {
      await this.getQuota()

      if (this.smsQuota <= 0) {
        this.confirmTitle = `點數餘額不足，請聯繫客服`
        this.confirmContent = ``
        this.contentClass = 'mt-2 red-6--text justify-center d-flex text-body-1'
        this.confirmText = '我知道了，關閉視窗'
        this.cancelText = ''
        this.confirmColor = 'grey-4'
        this.confirmFunc = (payload) => {
          const {callback} = payload;
          callback && callback()
        }
        this.toggleConfirmDialog = true
      } else {
        this.quotaDialog = true
      }
    },
    confirmSend() {
      this.sendLoading = true
      const data = {
        ...this.axiosParamData,
        content: this.messageContent
      }
      if (this.useCondition) {
        data.filter_clinic = this.$refs.patientFilter.selectClinic.join(',') || null
        data.filter_doctor = this.$refs.patientFilter.selectDoctors.join(',') || null
        data.name = this.$refs.patientFilter.inputPatientName || null
        data.line_bot_is_verified = this.$refs.patientFilter.selectBindStatus || null
        data.start_time = this.$refs.patientFilter.dates.slice().sort((a, b) => new Date(a) - new Date(b))[0] || null
        data.end_time = this.$refs.patientFilter.dates.slice().sort((a, b) => new Date(a) - new Date(b))[1] || null

        sendSMSMessageAPI(data).then(res => {
          this.quotaDialog = false
          this.confirmTitle = `發送成功`
          this.confirmContent = `預計發送完畢時間： 10 分鐘後 \n 10 分鐘後可至發送紀錄瀏覽`
          this.contentClass = 'mt-2 red-6--text justify-center d-flex text-body-1'
          this.confirmText = '我知道了，關閉視窗'
          this.cancelText = ''
          this.confirmColor = 'grey-4'
          this.confirmFunc = (payload) => {
            const {callback} = payload;
            callback && callback()
          }
          this.toggleConfirmDialog = true
          this.sendLoading = false

          this.clearData()
          this.step = 1
        })
      } else {
        const promises = []
        while (this.selectPatients.length) {
          data.patient_code = this.selectPatients.splice(0, this.splitSize).map(e => e.split('-')[0]).join(',')
          promises.push(sendSMSMessageAPI(data))
          Promise.all(promises).then(() => {
            this.quotaDialog = false
            this.confirmTitle = `發送成功`
            this.confirmContent = `預計發送完畢時間： 10 分鐘後 \n 10 分鐘後可至發送紀錄瀏覽`
            this.contentClass = 'mt-2 red-6--text justify-center d-flex text-body-1'
            this.confirmText = '我知道了，關閉視窗'
            this.cancelText = ''
            this.confirmColor = 'grey-4'
            this.confirmFunc = (payload) => {
              const {callback} = payload;
              callback && callback()
            }
            this.toggleConfirmDialog = true
            this.sendLoading = false

            this.clearData()
            this.step = 1
          })
        }
      }
    },
    async getQuota() {
      const res = await getSmsQuotaAPI(this.axiosParamData)
      this.smsQuota = res.data.quota
    },
    clearData() {
      this.$refs.patientFilter?.clearFilter()
      this.$refs.patientSelection?.clearData()
      this.filterPatientCount = this.$options.data().filterPatientCount
      this.filterPatients = this.$options.data().filterPatients
      this.selectPatients = this.$options.data().selectPatients
      this.messageContent = this.$options.data().messageContent
    }
  }
};
</script>

<style scoped>
/* Your styles here */
</style>
