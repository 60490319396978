<template>
  <v-card>
    <v-card-title>{{ $t('CropImage.title') }}</v-card-title>
    <v-card-text>
      <v-file-input
        v-model="file"
        accept="image/png, image/jpeg, image/bmp"
        :placeholder="$t('CropImage.chooseImg')"
        prepend-icon="mdi-camera"
        @change="setImage"
      />
      <v-img
        v-if="cropSrc"
        :aspect-ratio="aspectRatio"
        :src="cropSrc"
        max-width="300"
        style="cursor: pointer"
        class="mx-auto"
        @click="dialog = true"
      />
      <div v-if="hint" class="red--text text-subtitle-1 ml-8">{{ hint }}</div>
      <v-dialog
        v-model="dialog"
        :max-width="dialogMaxWidth"
        :max-height="dialogMaxHeight"
        persistent
        scrollable
      >
        <v-card>
          <v-card-title>{{ $t('CropImage.cropTool') }}</v-card-title>
          <v-card-text style="max-height: 84%">
            <vue-cropper
              ref="cropper"
              :aspect-ratio="aspectRatio"
              :src="imgSrc"
              :view-mode="1"
              drag-mode="move"
              :toggle-drag-mode-on-dblclick="false"
            />
          </v-card-text>
          <v-card-actions>
            <v-btn icon>
              <v-icon color="primary" @click="cropImage">mdi-check</v-icon>
            </v-btn>
            <v-btn icon>
              <v-icon color="primary" dark @click="rotate('r');">mdi-rotate-right</v-icon>
            </v-btn>
            <v-btn icon>
              <v-icon color="primary" dark @click="rotate('l');">mdi-rotate-left</v-icon>
            </v-btn>
            <v-spacer/>
            <v-btn v-if="cropImg" color="primary" @click="dialog = false;" text>{{ $t('GENERAL.cancel') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn color="primary" :disabled="!file" @click="returnFile">{{ $t('CropImage.confirmUpload') }}</v-btn>
      <v-btn outlined @click="$emit('close')">{{ $t('GENERAL.cancel') }}</v-btn>
      <v-btn v-if="CS" outlined color="blue" href="https://lin.ee/mWs8BoE" target="_blank">{{ $t('CropImage.concatCS') }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import VueCropper from "vue-cropperjs";
import 'cropperjs/dist/cropper.css';

export default {
  name: "CropImage",
  props: {
    dialogMaxWidth: { default: "600px" },
    dialogMaxHeight: { default: "0.8vh" },
    maxWidth: { default: 1960 },
    maxHeight: { default: 1960 },
    aspectRatio: {default: 1},
    hint: {default: ''},
    CS: Boolean
  },
  components: {
    VueCropper
  },
  data() {
    return {
      imgSrc: "",
      cropImg: null,
      dialog: false,
      file: null,
      filename: null,
      cropBlob: null,
    };
  },
  computed: {
    cropSrc() {
      return this.cropImg
    }
  },
  methods: {
    setImage(e) {
      const file = e
      if (file) {
        this.filename = file.name

        if (!file.type.includes("image/")) {
          alert(this.$t('CropImage.chooseImgFile'))
          return
        }

        if (typeof FileReader === "function") {
          const reader = new FileReader()

          reader.onload = event => {
            this.imgSrc = event.target.result
            // rebuild cropperjs with the updated source
            this.$refs.cropper.replace(event.target.result)
            this.$emit("update:dataUrl", this.imgSrc)
          };

          reader.readAsDataURL(file)
          this.dialog = true
        } else {
          alert(this.$t('CropImage.alertError'))
        }
      } else {
        this.cropImg = null
      }
    },
    cropImage() {
      // get image data for post processing, e.g. upload or setting image src
      // this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL()
      this.$refs.cropper
        .getCroppedCanvas({
          maxWidth: this.maxWidth,
          maxHeight: this.maxHeight,
        })
        .toBlob(
          blob => {
            this.cropImg = URL.createObjectURL(blob)
            this.$emit("update:objectUrl", this.cropImg)
          },
          "image/jpeg",
          0.8
        )
      this.dialog = false
    },
    rotate(dir) {
      if (dir === "r") {
        this.$refs.cropper.rotate(90)
      } else {
        this.$refs.cropper.rotate(-90)
      }
    },
    async returnFile() {
      if (this.cropImg) {
        const blob = await fetch(this.cropImg).then(r => r.blob())
        this.$emit('upload', new File([blob], this.file.name, {type: this.file.type}))
      } else
        this.$emit('upload', this.file)
    }
  }
};
</script>

<style lang="scss" scoped>
.v-icon.v-icon.v-icon--link {
  padding: 0 10px;
}
</style>
