<template>
  <v-dialog v-model="dialog" max-width="600px">
    <template #activator="{ on, attrs }">
      <v-img
        height="150"
        max-width="150"
        v-bind="attrs"
        v-on="on"
      >
        <slot/>
      </v-img>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h5 font-weight-bold">新增圖片</span>
        <v-spacer/>
        <v-btn text class="px-0" small @click="onClose">
          <v-icon size="15">
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-divider/>
      <v-card-text class="pt-4">
        <v-file-input
          ref="file"
          v-model="files"
          class="d-none"
          accept="image/*"
          multiple
          @change="onFileChange"
        />
        <v-sheet
          v-if="!files.length"
          class="d-flex flex-column align-center justify-center"
          height="350"
          :color="dragOver ? 'blue-2' : 'grey-8'"
          style="cursor: pointer;"
          @dragover.prevent="dragOver = true"
          @dragleave.prevent="dragOver = false"
          @drop.prevent="onDrop"
          @click.stop="onFileClick"
        >
          <v-icon size="30" color="grey lighten-1">
            mdi-image-area
          </v-icon>
          <p class="text-subtitle-2 grey-5--text mt-2 text-center">
            將圖片拖拉到這裡或選擇檔案
            <br>
            (可一次上傳多張)
            <br>
            <span v-if="maxFileSize" class="red-6--text">檔案最大不可超過{{ readableFileSize(maxFileSize) }}</span>
          </p>
        </v-sheet>
        <v-sheet
          v-else
          class="d-flex flex-wrap ma-auto overflow-y-auto justify-space-between px-4"
          height="350"
          style="row-gap: 8px;"
        >
          <div v-for="(file, idx) in files" :key="idx" style="position: relative;">
            <v-btn
              fab
              color="white"
              absolute
              x-small
              style="top: 0; right: 0; overflow: hidden;"
              @click.stop="removeFile(idx)"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-img
              :src="previewImgUrl(file)"
              max-width="250"
              max-height="250"
            />
          </div>
        </v-sheet>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn color="grey" outlined class="text-subtitle-2 rounded-lg" @click="onClose">
          取消
        </v-btn>
        <v-btn dark color="teal lighten-2" class="text-subtitle-2 rounded-lg" @click="onConfirm">
          儲存
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ImageUploadTool',

  props: {
    maxLimit: {
      type: Number,
      required: false
    },
    maxFileSize: {
      type: Number,
      required: false
    },
  },

  data() {
    return {
      dialog: false,
      files: [],
      dragOver: false
    }
  },

  methods: {
    readableFileSize(size) {
      const i = Math.floor(Math.log(size) / Math.log(1024));
      return (size / Math.pow(1024, i)).toFixed(0) + ['B', 'KB', 'MB', 'GB', 'TB'][i];
    },
    onFileChange(files) {
      if (files.length > this.maxLimit) {
        this.files = []
        return alert(`最多只能上傳${this.maxLimit}張照片`)
      }
      if (files.some(file => !this.validateFileSize(file))) {
        this.files = []
        return alert(this.$t('Upload.fileLargeError'));
      }
    },
    onFileClick() {
      this.$refs.file?.$refs?.input.click();
    },
    onDrop(event) {
      const files = event.dataTransfer.files;
      if (files.length > this.maxLimit) {
        this.dragOver = false
        return alert(`最多只能上傳${this.maxLimit}張照片`)
        // Array.from(files).forEach(file => this.handleFile(file));
      }
      this.files = files
    },
    onClose() {
      this.files = [];
      this.dialog = false;
    },
    onConfirm() {
      this.$emit('submit', this.files);
      this.onClose();
    },
    previewImgUrl(file) {
      return URL.createObjectURL(new Blob([file]))
    },
    removeFile(idx) {
      this.files.splice(idx, 1);
    },
    validateFileSize (file) {
      if (this.maxFileSize > 0) {
        return file.size <= this.maxFileSize;
      }
      return true;
    },
  }
};
</script>

<style scoped>
/* Your styles here */
</style>
