<template>
  <v-card class="py-8 px-8" rounded="xl">
    <v-overlay :value="loading" absolute z-index="3">
      <v-progress-circular indeterminate size="64"/>
    </v-overlay>
    <v-row>
      <!-- 基本資料區 -->
      <v-col cols="12" class="py-2">
        <div class="d-flex justify-space-between mb-8">
          <h6 class="text-subtitle-1 font-weight-bold">
            教育背景
          </h6>
          <div class="d-flex justify-end" style="gap: 8px">
            <v-btn
              color="grey"
              outlined
              class="text-subtitle-2 rounded-lg"
              @click="onClose"
            >
              取消
            </v-btn>
            <v-btn
              color="teal lighten-2"
              class="text-subtitle-2 rounded-lg white--text"
              @click="onConfirm"
            >
              儲存
            </v-btn>
          </div>
        </div>

        <v-form ref="form" v-model="valid">
          <draggable
            v-model="education"
            tag="ul"
            class="px-0"
            handle=".draggable"
            animation="200"
            @start="drag = true"
            @end="drag = false"
          >
            <v-slide-y-transition v-for="(e, i) in education" :key="e.id">
              <v-list-item class="grey lighten-4 rounded-lg mb-5" dense>
                <v-btn text class="px-0" small style="min-width: fit-content">
                  <v-icon size="20" class="draggable">
                    mdi-cursor-move
                  </v-icon>
                </v-btn>
                <v-list-item-title class="flex-grow-1 px-2">
                  <div
                    class="d-flex flex-column justify-center py-6"
                    style="gap: 0.6rem"
                  >
                    <div class="d-flex" style="gap: 0.5rem">
                      <v-avatar size="40">
                        <v-img
                          :src="getEducationLogo(e.school)"
                          alt="education image"
                          height="40"
                          width="40"
                          contain
                        />
                      </v-avatar>
                      <v-select
                        v-model="e.school"
                        :items="educationItems"
                        filled
                        dense
                        hide-details
                        outlined
                        item-value="name"
                        item-text="name"
                        required
                        background-color="white"
                        aria-required="true"
                      />
                    </div>
                    <v-text-field
                      v-if="e.school === '其他'"
                      v-model="e.name"
                      background-color="white"
                      placeholder="請輸入學校名稱（限制15字內）"
                      dense
                      hide-details="auto"
                      :rules="[rules.required]"
                      outlined
                      required
                      aria-required="true"
                      maxlength="15"
                    />
                    <v-text-field
                      v-model="e.partment"
                      placeholder="請輸入系所/課程（限制15字內）"
                      background-color="white"
                      hide-details="auto"
                      :rules="[rules.required]"
                      dense
                      outlined
                      required
                      class="custom-input"
                      aria-required="true"
                      maxlength="15"
                    />
                  </div>
                </v-list-item-title>
                <delete-dialog @confirm="education.splice(i, 1)"/>
              </v-list-item>
            </v-slide-y-transition>
          </draggable>
        </v-form>
        <v-btn class="rounded-lg mb-7" depressed block @click="addEducation">
          <v-icon color="grey" size="15">
            mdi-plus-circle
          </v-icon>
          點我新增教育背景
        </v-btn>
      </v-col>
      <!-- 社群連結區 -->
    </v-row>
    <v-card-actions>
      <v-spacer />
      <v-btn
        color="grey"
        outlined
        class="text-subtitle-2 rounded-lg"
        @click="onClose"
      >
        取消
      </v-btn>
      <v-btn
        color="teal lighten-2"
        class="text-subtitle-2 rounded-lg white--text"
        @click="onConfirm"
      >
        儲存
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import draggable from 'vuedraggable';
import UploadImageDialog from "@/components/utils/UploadImageDialog.vue";
import DeleteDialog from './DeleteDialog.vue';
import { educationItems } from "@/utils/sharedData";

export default {
  name: 'EducationalBackgroundForm',
  components: { DeleteDialog, UploadImageDialog, draggable },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    doctor: {
      type: Object,
      default: () => {
        return {
          education: []
        };
      }
    }
  },
  data () {
    const OtherImg = ''
    return {
      loading: false,
      valid: true,
      drag: true,
      value: '',
      OtherImg,
      educationItems,
      education: [],
      rules: {
        required: value => !!value || '*請輸入有效文字，不可輸入空白內容'
      }
    };
  },
  watch: {
    show: {
      handler (show) {
        if (show && this.doctor) {
          this.education = JSON.parse(JSON.stringify(this.doctor.education)) || [];
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    getEducationLogo(edu) {
      const education = this.educationItems.find(item => item.name === edu)
      return education?.logo || 'https://dentco.tw/images/school/其他.png'
    },
    onConfirm () {
      this.$refs.form.validate()
      if (!this.valid) return
      this.loading = true
      this.$emit('update', {
        education: this.education.map(edu => {
          if (edu.school !== '其他') {
            delete edu.name
          }
          return edu
        }),
        callback: (status) => {
          this.loading = false
          if (status === 'success') {
            this.onClose();
          } else {
            alert('更新失敗，請再試一次')
          }
        }
      });
    },
    onClose () {
      this.$emit('update:show', false);
    },
    addEducation () {
      this.education.push({
        school: null,
        name: '',
        partment: ''
      });
      this.value = '';
    }
  }
};
</script>

<style scoped>
.flip-list-move {
  transition: transform 0.5s;
}
.custom-input {
  ::v-deep &.success--text {
    color: var(--v-primary-base) !important; /* 自訂成功文字顏色 */
  }
}
</style>
