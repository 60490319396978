<template>
  <div>
    <div class="d-flex align-center mt-2">
      <v-sheet color="grey-8" height="60" rounded width="100%" class="d-flex align-center px-2">
        <div class="d-flex" style="width: 400px; max-width: 70%;">
          <v-text-field
            v-model="search"
            v-on:keyup.enter="searchPatients()"
            single-line
            hide-details
            outlined
            dense
            clearable
            :placeholder="$t('Patients.searchPlaceholder')"
          />
        </div>
        <v-btn icon color="primary" class="ml-2" @click="searchPatients()">
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
        <v-spacer/>
        <v-btn :icon="$vuetify.breakpoint.xsOnly" outlined @click="exportPatients">
          <v-icon>mdi-file-export</v-icon>
          <template v-if="$vuetify.breakpoint.smAndUp">匯出</template>
        </v-btn>
      </v-sheet>
    </div>

    <v-data-table
      :headers="headers"
      :items="patients"
      :options.sync="options"
      :server-items-length="totalPatients"
      :footer-props="{itemsPerPageOptions: [10, 20, 50]}"
      :loading="loading"
      fixed-header
      height="calc(100vh - 380px)"
    >
      <template v-slot:item.name="{ item }">
        <div class="d-flex align-center" :class="{'ma-auto': $vuetify.breakpoint.smAndUp}" style="width: 122px;">
          <v-spacer v-if="$vuetify.breakpoint.xsOnly"/>
          <v-avatar size="36">
            <img
              :src="item.file_name_photo"
              :alt="item.name"
              @error="item.file_name_photo = 'https://dentco.s3.us-east-2.amazonaws.com/asset/crm/avatar-unknow.png'"
            >
          </v-avatar>
          <div class="ml-2">
            {{ item.name }}
          </div>
        </div>
      </template>
      <template v-slot:item.sex="{ item }">
        {{ item.sex | getGender }}
      </template>
      <template v-slot:item.birthday="{ item }">
        <template v-if="item.birthday">{{ parseInt(item.birthday) * 1000 | formatROCDate }}</template>
      </template>
      <template v-slot:item.bind_status="{ item }">
        <v-tooltip right max-width="500">
          <template v-slot:activator="{ on, attrs }">
            <v-img
              :src="showBindStatus({status: item.bind_status}).icon"
              max-width="24"
              width="24"
              class="ma-auto"
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <span>{{ showBindStatus({status: item.bind_status}).text }}</span>
        </v-tooltip>
      </template>
      <template v-slot:item.action="{ item }">
        <v-btn icon small @click="selectPatientCode = item.patient_code; patientCardDialog = true">
          <v-icon>mdi-card-account-details</v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <v-dialog v-if="patientCardDialog" v-model="patientCardDialog" max-width="960" scrollable>
      <patient-card
        :patient-code="selectPatientCode"
        @close="patientCardDialog = false"
        @refresh="getPatients"
      />
    </v-dialog>
  </div>

</template>

<script>
import { exportPatientsAPI, getPatientsAPI } from '../../api';
import { mapGetters } from 'vuex';
import { bindStatus } from '../../config/bindConfig';
import { assetURL } from '../../utils/filters';
import PatientCard from './PatientCard';

export default {
  name: "PatientList",

  components: {PatientCard},

  data() {
    return {
      loading: false,
      search: null,
      patients: [],
      options: {},
      totalPatients: 0,

      selectPatientCode: null,
      patientCardDialog: false,
    }
  },

  computed: {
    ...mapGetters([
      'axiosParamData',
    ]),
    headers() {
      return [
        {text: this.$t('Patients.patientName'), align: 'center', value: 'name', sortable: false, width: 120},
        {text: this.$t('Patients.gender'), align: 'center', value: 'sex', sortable: false, width: 60},
        {text: this.$t('Patients.birthday'), align: 'center', value: 'birthday', sortable: false, width: 120},
        {text: this.$t('Patients.patientPhone'), align: 'center', value: 'phone', sortable: false, width: 120},
        {text: this.$t('Patients.bindStatus'), align: 'center', value: 'bind_status', sortable: false, width: 80},
        {text: this.$t('Patients.personalInfo'), align: 'center', value: 'action', sortable: false, width: 120},
      ]
    },
  },

  watch: {
    options: {
      handler() {
        this.getPatients()
      },
      deep: true,
    },
    patientCardDialog(v) {
      if (!v)
        this.getPatients()
    }
  },

  methods: {
    searchPatients(){
      if (this.page === 1)
        return this.getPatients()
      this.page = 1
    },
    getPatients() {
      this.loading = true
      const {page, itemsPerPage} = this.options
      const params = {
        ...this.axiosParamData,
        page,
        limit: itemsPerPage,
        name: this.search || null
      }
      getPatientsAPI(params).then(res => {
        this.totalPatients = parseInt(res.data.paginate.total)
        this.patients = res.data.data
      }).catch(e => {
        alert(this.$t('GENERAL.oops'))
        console.error(e)
      }).finally(() => {
        this.loading = false
      })
    },
    showBindStatus(config) {
      const assetConfig = bindStatus(config)
      return {
        icon: assetURL(assetConfig.icon),
        text: assetConfig.text
      }
    },
    exportPatients() {
      const params = {
        ...this.axiosParamData,
        name: this.search || null
      }
      exportPatientsAPI(params).then(res => {
        const blob = new Blob([res.data], {type: 'text/csv;charset=utf-8;'});
        const link = document.createElement("a");
        const url = URL.createObjectURL(blob);
        const fileName = res.headers['content-disposition'].split("filename=")[1] || '病人列表'
        link.setAttribute("href", url);
        link.setAttribute("download", fileName);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
    }
  }
}
</script>

<style scoped>

</style>
