<template>
  <v-card flat>
    <v-tabs v-model="tab" grow>
      <v-tab @click="value.type = 1">
        <v-badge :value="checkHasNew(0)" dot color="red">
          衛教套版
        </v-badge>
      </v-tab>
      <v-tab @click="value.type = 1">
        <v-badge :value="checkHasNew(1)" dot color="red">
          節慶套版
        </v-badge>
      </v-tab>
      <v-tab @click="value.type = 1">
        <v-badge :value="checkHasNew(2)" dot color="red">
          功能套版
        </v-badge>
      </v-tab>
      <v-tab @click="value.type = 2">自定義圖片/文字</v-tab>
    </v-tabs>
    <template v-if="value.type === 1">
      <v-subheader>{{ $t('EditCard.stepOne') }}</v-subheader>
      <v-item-group v-model="value.activity" style="max-height: 350px" class="overflow-y-auto">
        <v-container>
          <v-row>
            <v-col v-for="act in filterActivities" :key="act.id" cols="6" sm="4" class="text-center">
              <v-item :value="act.id" v-slot="{ active, toggle }">
                <div style="cursor: pointer" @click="toggle">
                  <div style="width: 100px; height: 100px;" class="mx-auto">
                    <v-img max-height="100" max-width="100" class="mx-auto" :src="act.image_url" style="position: absolute"/>
                    <v-img v-if="isNew(act.start_date)" max-height="100" max-width="100" class="mx-auto" :src="mask" style="position: absolute"/>
                  </div>
                  <v-checkbox :value="active" hide-details style="width: fit-content" class="mx-auto">
                    <template v-slot:label>
                      <template v-if="isExpired(act.end_date)">
                        {{ act.title }}<span class="red--text">（活動已結束）</span>
                      </template>
                      <v-badge v-else-if="isNew(act.start_date)" color="red" dot>
                        (New){{ act.title }}
                      </v-badge>
                      <template v-else>
                        {{ act.title }}
                      </template>
                    </template>
                  </v-checkbox>
                  <v-dialog v-if="act.id === panoId && panoDialog" v-model="panoDialog" max-width="600" persistent>
                    <v-card>
                      <v-overlay :value="loading" absolute>
                        <v-progress-circular indeterminate size="64"/>
                      </v-overlay>
                      <v-card-title>{{ $t('EditCard.uploadPano') }}</v-card-title>
                      <v-card-text>
                        <div v-if="uploadPanoStatus" class="text-h6 font-weight-medium">
                          {{ $t('EditCard.previewPano') }}
                          <a :href="`https://pano.dentco.tw/pano/${value.panoImgId}`" target="_blank">
                            https://pano.dentco.tw/pano/{{ value.panoImgId }}
                          </a>
                        </div>
                        <template v-else>
                          <v-form v-model="valid" ref="form">
                            <v-text-field v-model.trim="patientName" :label="$t('EditCard.patientName')"
                                          :rules="rules"/>
                            <v-text-field v-model.trim="patientAge" :label="$t('EditCard.patientAge')" :rules="rules"/>
                            <v-text-field v-model.trim="patientPhone" :label="$t('EditCard.patientPhone')"
                                          :rules="rules"/>
                            <v-file-input v-model="panoImg" solo :label="$t('EditCard.panoPic')" :rules="rules"
                                          accept="image/png, image/jpeg, image/jpg"
                                          @click:clear="panoImg = null"/>
                          </v-form>
                        </template>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer/>
                        <v-btn v-if="uploadPanoStatus" width="125" color="primary" @click="savePano(active, toggle)">
                          {{ $t('EditCard.savePano') }}
                        </v-btn>
                        <v-btn v-else color="primary" width="125" @click="uploadPano">{{
                            $t('EditCard.upload')
                          }}
                        </v-btn>
                        <v-btn v-if="uploadPanoStatus" width="125" text @click="resetPano">{{
                            $t('EditCard.reUpload')
                          }}
                        </v-btn>
                        <v-btn v-else width="125" text @click="panoDialog = false">{{ $t('GENERAL.cancel') }}</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </div>
              </v-item>
              <v-btn v-if="act.id === panoId" color="primary" small @click="panoDialog = true">
                {{ $t('EditCard.openUpload') }}
              </v-btn>
              <template v-else>
                <v-btn v-if="act.intro_url" text :href="act.intro_url" target="_blank" color="primary">
                  {{ $t('EditCard.seeIntro') }}
                  <v-icon small>mdi-arrow-top-right</v-icon>
                </v-btn>
              </template>
            </v-col>
          </v-row>
        </v-container>
      </v-item-group>

      <v-subheader>{{ $t('EditCard.stepTwo') }}</v-subheader>
      <v-textarea v-model="value.activityContent" no-resize outlined height="120"/>
    </template>
    <template v-if="value.type === 2">
      <v-container>
        <v-row>
          <v-col cols="12" sm="4">
            <v-subheader class="pl-0">
              <v-icon color="blue" small>mdi-send</v-icon>
              {{ $t('EditCard.uploadPic') }}
            </v-subheader>
            <upload-img v-model="value.customImg" :aspect-ratio="1" class="mx-auto" style="max-width: 150px"/>
          </v-col>
          <v-col cols="12" sm="8">
            <v-subheader class="pl-0">
              <v-icon color="blue" small>mdi-send</v-icon>
              {{ $t('EditCard.content') }}
            </v-subheader>
            <v-textarea v-model="value.customContent" no-resize outlined/>
          </v-col>
        </v-row>
      </v-container>

    </template>
  </v-card>
</template>

<script>
import UploadImg from "../UploadImg";
import { uploadPanoImgAPI } from "../../api";
import { mapGetters, mapState } from "vuex";
import { isExpired } from '../../utils/tool';

export default {
  name: "EditCard",
  components: {UploadImg},
  props: {
    value: Object,
    activities: {
      default: [],
      type: Array
    },
    is3mClient: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tab: null,
      panoDialog: false,
      uploadPanoStatus: false,
      patientName: null,
      patientAge: null,
      patientPhone: null,
      panoImg: null,
      loading: false,
      valid: true,
      rules: [
        v => !!v || this.$t('EditCard.fieldRequired')
      ],
      filterActivities: [],
      mask: 'https://dentco.s3.us-east-2.amazonaws.com/asset/crm/activity_new_mask.png',
      isExpired
    }
  },
  computed: {
    ...mapState({
      panoId: state => state.panoId,
    }),
    ...mapGetters([
      'axiosParamData',
    ]),
    typeItems() {
      return [
        {value: 1, text: this.$t('EditCard.useActivity')},
        {value: 2, text: this.$t('EditCard.useCustom')},
      ]
    },
  },
  watch: {
    'value.type': {
      handler: function (v) {
        if (v === 2) {
          this.value.activity = null
          this.value.activityContent = null
        } else {
          this.value.customImg = null
          this.value.customContent = null
        }
      },
    },
    tab: {
      handler: function (v) {
        /**
         * tab: 0衛教 1節慶 2功能
         * db type: 1:衛教型 2:節日型 3:功能型
         */
        switch (v) {
          case 0:
            this.filterActivities = this.activities.filter(e => {
              return e.type === 1
            })
            break
          case 1:
            this.filterActivities = this.activities.filter(e => {
              return e.type === 2
            })
            break
          case 2:
            this.filterActivities = this.activities.filter(e => {
              return e.type === 3
            })
            break
        }
      }
    },
    'value.activity': {
      handler: function (v) {
        if (!this.is3mClient && [41, 42].includes(v)) {
          this.$emit('alert3m')
          this.$nextTick(() => {
            this.value.activity = null
          })
        }
      },
    },
  },
  methods: {
    uploadPano() {
      this.$refs.form[0].validate()
      if (!this.valid) return
      this.loading = true
      const data = {
        ...this.axiosParamData,
        name: this.patientName,
        age: this.patientAge,
        cellphone: this.patientPhone,
        image: this.panoImg,
      }
      const formData = new FormData()
      for (const [key, value] of Object.entries(data)) {
        formData.append(key, value)
      }
      uploadPanoImgAPI(formData).then(res => {
        this.value.panoImgId = res.data.pano_id
        this.uploadPanoStatus = true
      }).finally(() => {
        this.loading = false
      })
    },
    resetPano() {
      this.panoImg = null
      this.patientName = null
      this.patientAge = null
      this.patientPhone = null
      this.uploadPanoStatus = false
    },
    savePano(active, toggle) {
      if (!active) toggle()
      this.panoDialog = false
    },
    isNew(startDay) {
      return this.$moment().diff(this.$moment(startDay), 'days') <= 7
    },
    checkHasNew(tab) {
      let child = []
      switch (tab) {
        case 0:
          child = this.activities.filter(e => {
            return e.type === 1
          })
          break
        case 1:
          child = this.activities.filter(e => {
            return e.type === 2
          })
          break
        case 2:
          child = this.activities.filter(e => {
            return e.type === 3
          })
          break
      }

      return child.some(e => this.isNew(e.start_date))
    }
  },
}
</script>

<style scoped>

</style>
