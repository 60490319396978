<template>
  <v-autocomplete
    v-model="patientObj"
    item-text="show"
    :label="$t('SearchPatient.searchLabel')"
    clearable
    :hide-no-data="!noDataText"
    return-object
    outlined
    dense
    :rules="[v => !!v || $t('SearchPatient.mustInsert')]"
    :items="patientItems"
    :filter="customFilter"
    :search-input.sync="search"
    :no-data-text="noDataText"
    :loading="loading"
    append-outer-icon="mdi-magnify"
    @click:append-outer="searchPatient(search)"
    @change="$emit('update:patient', patientObj)"
    @keyup.enter.prevent="searchPatient(search)"
  >
    <template v-slot:no-data>
      <v-list-item v-if="search">
        <v-list-item-content>
          <v-list-item-title>
            <span class="grey-4--text">找不到此病患，請使用 <a @click="$emit('no-data')">NP約診</a></span>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>

<script>
import { searchPatientsAPI } from "../../api";
import { mapGetters } from "vuex";
import { formatROCDate } from "../../utils/filters";

export default {
  name: "SearchPatient",
  props: ['patient'],
  data() {
    return {
      patientObj: null,
      patientItems: [],
      search: null,
      loading: false,
      isSearch: false
    }
  },
  computed: {
    ...mapGetters([
      'axiosParamData',
    ]),
    noDataText() {
      if (this.isSearch) return '找不到此病患，請使用NP約診'
      else return ''
    }
  },
  methods: {
    searchPatient(v) {
      if (!v) return
      this.loading = true
      searchPatientsAPI({name: v, ...this.axiosParamData}).then(res => {
        const patientItems = res.data
        this.patientItems = patientItems.map(v => {
          const _ = [v.name]
          if (v.birthday) {
            _.push(formatROCDate(parseInt(v.birthday) * 1000))
          }
          if (v.phone) {
            _.push(v.phone)
          }
          return {...v, show: _.join(' -- ')}
        })
      }).finally(() => {
        this.loading = false
        this.isSearch = true
      })
    },
    customFilter(item, queryText) {
      const textOne = item.name
      const textTwo = item.birthday
      const searchText = queryText

      return textOne.includes(searchText) > -1 || textTwo.includes(searchText) > -1
    },

  },
  watch: {
    search() {
      this.isSearch = false
      this.patientItems = []
    },

  },
  mounted() {
    this.patientObj = this.patient
  }
}
</script>

<style scoped>

</style>
