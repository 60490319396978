<script>
export default {
  name: "PlanAddonAlertDialog",

  props: {
    value: {
      default: false,
      type: Boolean
    }
  },

  data() {
    return {

    }
  },

  computed: {
    dialog: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>

<template>
  <v-dialog v-model="dialog" max-width="fit-content">
    <v-card width="fit-content" class="pa-4">
      <v-card-title  class="text-center d-block">
        請聯繫客服升級方案
      </v-card-title>

      <v-card-actions class="mt-2">
        <div style="width: 100%;">
          <v-btn
            class="mx-auto"
            color="primary"
            :href="$enum.DENTCO_CS_LINE"
            target="_blank"
            block
            @click="dialog = false"
          >聯繫客服
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>

</style>