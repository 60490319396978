<template>
  <div>
    <v-card flat>
      <v-card-text>
        <v-card flat>
          <guide-tour config-key="patient"/>
          <v-card-text>
            <v-sheet
              :outlined="$vuetify.breakpoint.smAndUp"
              rounded
              :class="{ 'px-4': $vuetify.breakpoint.smAndUp }"
            >
              <v-tabs v-model="tab">
                <v-tab href="#patientList">
                  約診患者
                  <div class="grey-8 px-1 ml-1" v-text="total.patient"/>
                </v-tab>
                <v-tab href="#lineMember">
                  LINE 會員
                  <div class="grey-8 px-1 ml-1" v-text="total.line"/>
                </v-tab>
                <v-tab href="#messengerMember">
                  Messenger 會員
                  <div class="grey-8 px-1 ml-1" v-text="total.messenger"/>
                </v-tab>
                <v-tab href="#blacklist">
                  黑名單
                  <div class="grey-8 px-1 ml-1" v-text="total.blacklist"/>
                </v-tab>
                <v-tab href="#preteeth">
                  Preteeth AI 矯正模擬
                </v-tab>
                <v-tab href="#analysis">
                  病患分析統計
                </v-tab>
              </v-tabs>
              <div>
                <patient-list v-if="tab === 'patientList'"/>
                <member-list v-if="tab === 'lineMember'" member-type="line" key="line"/>
                <member-list v-if="tab === 'messengerMember'" member-type="messenger" key="messenger"/>
                <black-list v-if="tab === 'blacklist'"/>
                <preteeth-a-i-list v-if="tab === 'preteeth'"/>
                <patient-analysis v-if="tab === 'analysis'"/>
              </div>
            </v-sheet>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { countBlackListsAPI, countLineMembersAPI, countMessengerMembersAPI, countPatientsAPI } from '../../api';
import Navigation from '../Navigation';
import BlackList from './BlackList';
import MemberList from './MemberList';
import PatientList from './PatientList';
import PatientAnalysis from "../analytics/PatientAnalysis.vue";
import PreteethAIList from "./PreteethAIList.vue";
import GuideTour from "../utils/GuideTour.vue";

export default {
  name: "Mypatient",

  components: {GuideTour, PreteethAIList, PatientAnalysis, MemberList, PatientList, BlackList, Navigation },

  data() {
    return {
      tab: null,
      total: {
        patient: null,
        line: null,
        blacklist: null,
        messenger: null
      }
    }
  },

  computed: {
    ...mapGetters([
      'axiosParamData',
    ])
  },

  mounted() {
    countPatientsAPI(this.axiosParamData).then(res => {
      this.total.patient = res.data.total
    })
    countLineMembersAPI(this.axiosParamData).then(res => {
      this.total.line = res.data.total
    })
    countMessengerMembersAPI(this.axiosParamData).then(res => {
      this.total.messenger = res.data.total
    })
    countBlackListsAPI(this.axiosParamData).then(res => {
      this.total.blacklist = res.data.total
    })
  }
}
</script>

<style scoped></style>
