<template>
  <v-main>
    <navigation/>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"/>
    </v-overlay>
    <v-container fluid>
      <guide-tour config-key="administrator"/>
      <v-row>
        <v-col v-for="(role, i) in roles" :key="i" cols="12" :sm="12/roles.length">
          <admin-card
            :data="data[role.key]"
            :label="role.label"
            :tooltip="role.tooltip"
            :editable="role.editable"
            @refresh="getData"
            @new-role="role.new"
            @delete="deleteRole"
          />
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-if="newDialog" v-model="newDialog" max-width="400">
      <v-card>
        <v-card-title v-text="$t('Administrator.addMember') + label"/>
        <v-card-text>
          <v-text-field v-model.trim="newPhone" :label="$t('Administrator.phone')" autofocus/>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn color="primary" :disabled="!newPhone || (newPhone && newPhone.length!==10)"
                 @click="addRole">{{ $t('Administrator.confirm') }}
          </v-btn>
          <v-btn text @click="newDialog = false">{{ $t('GENERAL.cancel') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-if="bindLineDialog" v-model="bindLineDialog" max-width="415">
      <v-card>
        <v-card-title>{{ $t('Administrator.pleaseRegister') }}</v-card-title>
        <v-card-text class="text-pre-line">
          <div v-html="$t('Administrator.pleaseRegisterText')"/>
          <a :href="assistant.url" target="_blank">{{ assistant.url }}</a>，
          <div v-html="$t('Administrator.pleaseRegisterText2')"/>
          <v-img :src="assistant.image" contain height="160" class="mx-auto">
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular indeterminate color="grey lighten-1"/>
              </v-row>
            </template>
          </v-img>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn color="primary" @click="bindLineDialog = false">{{ $t('Administrator.IKnow') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-main>
</template>

<script>
import Navigation from "../components/Navigation";
import AdminCard from "../components/administrator/AdminCard";
import { createAdminAPI, deleteAdminAPI, getAdminAPI } from "../api";
import { mapGetters, mapState } from "vuex";
import GuideTour from "../components/utils/GuideTour.vue";

export default {
  name: "Administrator",
  components: {GuideTour, AdminCard, Navigation},
  data() {
    return {
      data: [],
      loading: true,
      newDialog: false,
      newPhone: null,
      label: null,
      key: null,
      bindLineDialog: false,
    }
  },
  computed: {
    ...mapState({
      assistant: state => state.assistant,
    }),
    ...mapGetters([
      'axiosParamData',
      'clinicUser'
    ]),
    roles() {
      if (this.clinicUser)
        return [
          {
            label: this.$t('Administrator.clinicPermission'), key: 'clinic', editable: true,
            tooltip: this.$t('Administrator.clinicPermissionTooltip'),
            new: (label) => {
              this.label = label
              this.newDialog = true
              this.key = 'clinic'
            }
          },
          {
            label: this.$t('Administrator.assistantPermission'), key: 'assistant', editable: true,
            tooltip: this.$t('Administrator.assistantPermissionTooltip'),
            new: (label) => {
              this.label = label
              this.newDialog = true
              this.key = 'assistant'
            }
          },
          {
            label: this.$t('Administrator.doctorPermission'), key: 'doctor',
            tooltip: this.$t('Administrator.clinicDoctorPermissionTooltip'),
            new: ''
          }
        ]
      else
        return [
          {
            label: this.$t('Administrator.doctorPermission'), key: 'doctor', editable: true,
            tooltip: this.$t('Administrator.doctorPermissionTooltip'),
            new: (label) => {
              this.label = label
              this.newDialog = true
              this.key = 'doctor'
            }
          },
          {
            label: this.$t('Administrator.assistantPermission'), key: 'assistant', editable: true,
            tooltip: this.$t('Administrator.doctorAssistantTooltip'),
            new: (label) => {
              this.label = label
              this.newDialog = true
              this.key = 'assistant'
            }
          }
        ]
    },
  },
  watch: {
    newDialog(v) {
      if (!v) this.newPhone = null
    },
  },
  methods: {
    getData() {
      this.loading = true
      getAdminAPI(this.axiosParamData).then(res => {
        this.data = res.data
      }).finally(() => {
        this.loading = false
      })
    },
    addRole() {
      const assistantExist = this.data.assistant.some(e => e.input_phone === this.newPhone)
      if (assistantExist) {
        if (this.key === 'assistant') return alert(this.$t('Administrator.alreadyExistAssistant'))
        // else alert(this.$t('Administrator.duplicateAssistant', { label: this.label }))
      }
      if (this.clinicUser) {
        const clinicExist = this.data.clinic.some(e => e.input_phone === this.newPhone)
        if (clinicExist) {
          if (this.key === 'clinic') return alert(this.$t('Administrator.alreadyExistClinic'))
          // else alert(this.$t('Administrator.duplicateClinic', { label: this.label }))
        }
      } else {
        const doctorExist = this.data.doctor.some(e => e.input_phone === this.newPhone)
        if (doctorExist) {
          if (this.key === 'doctor') return alert(this.$t('Administrator.alreadyExistDoctor'))
          // else alert(this.$t('Administrator.duplicateDoctor', { label: this.label }))
        }
      }
      this.loading = true
      const data = {
        ...this.axiosParamData,
        phone: this.newPhone,
        auth: this.key === 'assistant' ? 2 : 1
      }
      createAdminAPI(data).then(res => {
        if (res.data.data) {
          this.newDialog = false
          this.getData()
        } else {
          this.bindLineDialog = true
          this.loading = false
        }
      })
    },
    deleteRole(id, lineBot) {
      this.loading = true
      const params = {
        ...this.axiosParamData,
        line_bot_id: lineBot,
      }
      deleteAdminAPI(id, params).catch(e => {
        if (e.response && e.response.data && e.response.data.messages) {
          alert(e.response.data.messages)
        } else {
          alert(this.$t('GENERAL.oops'))
          console.error(e)
        }
      }).finally(() => {
        this.getData()
      })
    }
  },
  mounted() {
    this.getData()
  }
}
</script>

<style scoped>

</style>
