<template>
  <v-overlay :value="!hasPermission" absolute z-index="2">
    <div>
      <v-btn
        color="error"
        class="d-block ma-auto py-2"
        :href="$enum.DENTCO_CS_LINE"
        target="_blank"
      >
        此為付費功能，聯繫客服升級付費方案
      </v-btn>
    </div>
  </v-overlay>
</template>

<script>
export default {
  name: "PaidFeatureNotice",

  props: {
    hasPermission: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>

</style>