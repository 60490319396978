<template>
  <v-card elevation="0">
    <v-card-title>
      {{ label }}
      <v-tooltip v-if="tooltip" bottom max-width="500">
        <template v-slot:activator="{ on, attrs }">
          <v-icon color="grey lighten-1" v-bind="attrs" v-on="on">mdi-help-circle-outline</v-icon>
        </template>
        <span>{{ tooltip }}</span>
      </v-tooltip>

      <v-btn v-if="editable" small color="primary" class="ml-auto" @click="$emit('new-role', label)">
        <v-icon>mdi-plus</v-icon>{{ $t('AdminCard.newRole') }}
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-card outlined>
        <v-list >
          <template v-for="(item, i) in data">
            <v-list-item :key="item.no">
              <v-list-item-content>
                <v-list-item-title v-text="item.name"></v-list-item-title>
              </v-list-item-content>

              <v-list-item-action v-if="editable">
                <v-btn icon @click="remove(item)">
                  <v-icon color="red lighten-1">mdi-minus-circle-outline</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <v-divider v-if="i !== data.length-1"/>
          </template>
        </v-list>
      </v-card>
    </v-card-text>
    <v-dialog v-if="removeDialog" v-model="removeDialog" max-width="372">
      <v-card>
        <v-card-title v-text="title"/>
        <v-card-actions>
          <v-spacer/>
          <v-btn color="primary" @click="confirm">{{ $t('AdminCard.ok') }}</v-btn>
          <v-btn text @click="removeDialog = false">{{ $t('GENERAL.cancel') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
export default {
  name: "AdminCard",
  props: {
    data: Array,
    label: String,
    tooltip: String,
    editable: Boolean,
  },
  data() {
    return {
      removeDialog: false,
      title: null,
      removeItem: null,
    }
  },
  methods: {
    remove(item) {
      this.title = this.$t('AdminCard.removeTitle', { name: item.name, label: this.label })
      this.removeItem = item
      this.removeDialog = true
    },
    confirm() {
      this.$emit('delete', this.removeItem.no, this.removeItem.line_bot_id)
      this.removeDialog = false
    }
  }
}
</script>

<style scoped>

</style>