<template>
  <v-sheet>
    <v-sheet>
      <v-row class="no-gutters align-center">
        <v-col cols="5">治療分類</v-col>
        <v-col cols="5">治療名稱</v-col>
        <v-col cols="2" class="text-right">
          <v-btn v-if="!isEdit" color="tiffany-7" dark :disabled="loading"  @click="edit">編輯</v-btn>
        </v-col>
      </v-row>
      <v-sheet height="calc(100vh - 480px)" class="overflow-y-auto mt-2" style="position: relative;">
        <v-overlay :value="loading" absolute>
          <v-progress-circular
            indeterminate
            size="32"
          ></v-progress-circular>
        </v-overlay>
        <v-form ref="form" v-model="valid">

          <draggable
            v-model="selectedTreatments"
            :disabled="!isEdit"
            tag="div"
            handle=".handle"
            @start="drag = true"
            @end="drag = false"
          >

            <v-row
              v-for="(selection, index) in selectedTreatments"
              :key="index"
              class="mb-4 no-gutters"
            >
              <v-col cols="5">
                <div class="d-flex">
                  <div v-if="isEdit" class="pt-1">
                    <v-btn icon class="px-0 handle" small>
                      <v-icon size="20">
                        mdi-cursor-move
                      </v-icon>
                    </v-btn>
                  </div>

                  <multi-layer-select
                    :value-list.sync="selection.search_engine"
                    :treatment-list="treatmentList"
                    :is-edit="isEdit"
                    style="width: 100%;"
                  />
                </div>
              </v-col>
              <v-col cols="5">
                <div class="px-2">
                  <v-text-field
                    v-model="selection.treatment"
                    placeholder="請輸入治療名稱"
                    outlined
                    dense
                    :disabled="!isEdit"
                    hide-details="auto"
                    :rules="[v => !!v || '*請輸入「治療名稱」才能儲存']"
                  />
                </div>
              </v-col>
              <v-col v-if="isEdit" cols="2">
                <v-btn icon @click="deleteOneTreatment(index)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </draggable>
        </v-form>

        <v-btn
          dark
          color="tiffany-7"
          :disabled="!isEdit"
          @click="addOneTreatment"
        >
          新增服務項目
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-sheet>
    </v-sheet>
    <div v-if="isEdit" class="d-flex mt-2">
      <v-spacer/>
      <v-btn outlined @click="cancel">取消</v-btn>
      <v-btn color="red-6" dark class="ml-2" @click="save">儲存</v-btn>
    </div>
    <v-dialog v-model="onlineTreatmentAlertDialog" max-width="500" persistent>
      <v-card rounded="lg">
        <v-card-title class="text-center justify-center pt-12 text-h5 font-weight-medium red-6--text">
          儲存失敗
        </v-card-title>
        <v-card-text class="pt-4 text-center">
          <div class="text-body-1 font-weight-medium text--grey text--darken-4">
            {{ onlineTreatmentAlertText }}
          </div>
        </v-card-text>
        <v-card-actions class="justify-center pb-12">
          <v-sheet max-width="300" width="300">
            <v-btn color="grey-4" block dark class="mt-4" @click="onlineTreatmentAlertDialog = false">我知道了，關閉視窗</v-btn>
          </v-sheet>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-sheet>
</template>

<script>
import MultiLayerSelect from "@/components/OnlineAppointmentsSetting/MultiLayerSelect.vue";
import { getSearchEngineOnlineTreatmentAPI, updateSearchEngineOnlineTreatmentAPI } from "@/api";
import { mapGetters } from "vuex";
import draggable from "vuedraggable";

export default {
  name: "FuckStupidTreatmentSetting",

  components: {draggable, MultiLayerSelect},

  props: {
    treatmentList: {
      type: Array,
      required: true
    },
    selectCode: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      valid: true,
      loading: false,
      isEdit: false,
      drag: false,
      selectedTreatments: [],
      originalSelectedTreatments: [],
      onlineTreatmentAlertDialog: false,
      onlineTreatmentAlertText: ''
    }
  },

  computed: {
    ...mapGetters([
      'axiosParamData',
      'clinicUser',
    ]),
  },

  watch: {
    selectCode: {
      immediate: true,
      handler() {
        this.cancel();
        this.getSearchEngineOnlineTreatment();
      }
    },
  },

  methods: {
    setSelectedTreatments() {
      this.selectedTreatments = JSON.parse(JSON.stringify(this.originalSelectedTreatments))
    },
    edit() {
      this.isEdit = true
    },
    cancel() {
      this.setSelectedTreatments()
      this.isEdit = false
    },
    save() {
      if (!this.selectedTreatments.length) {
        this.onlineTreatmentAlertText = '您必須建立一筆有效的治療項目，才能進行儲存。'
        this.onlineTreatmentAlertDialog = true
        return
      }

      this.$refs.form.validate()
      if (!this.valid) {
        this.onlineTreatmentAlertText = '請確認所有欄位皆設定完成。'
        this.onlineTreatmentAlertDialog = true
        return
      }

      this.loading = true

      const data = {
        ...this.axiosParamData,
        item_options_structure: this.selectedTreatments
      }
      if (this.clinicUser) {
        data.doctor_code = this.selectCode
      } else {
        data.clinic_code = this.selectCode
      }

      updateSearchEngineOnlineTreatmentAPI(data).then(() => {
        this.isEdit = false
        this.getSearchEngineOnlineTreatment()
      }).catch(e => {
        console.error(e)
        alert('儲存失敗')
        this.loading = false
      })
    },
    addOneTreatment() {
      this.selectedTreatments.push({search_engine: [], treatment: ""});
    },
    deleteOneTreatment(index) {
      this.selectedTreatments.splice(index, 1);
    },
    getSearchEngineOnlineTreatment() {
      this.loading = true
      const params = {
        ...this.axiosParamData
      }

      if (this.clinicUser) {
        params.doctor_code = this.selectCode
      } else {
        params.clinic_code = this.selectCode
      }
      getSearchEngineOnlineTreatmentAPI(params).then(res => {
        this.originalSelectedTreatments = res.data
        this.setSelectedTreatments()

        this.loading = false
      })
    }
  }
}
</script>

<style scoped>

</style>