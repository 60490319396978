<template>
  <v-card flat :disabled="loading" :loading="loading" @click.native="onFocus">
    <v-btn
      v-if="isPersistent"
      fab
      icon
      absolute
      width="48"
      height="48"
      style="top: 0; right: 0; overflow: hidden;"
      @click="$emit('update:reserveFormDialog', false)"
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <v-card-title v-if="mode === 'create'">
      <v-radio-group v-model="createMod" row>
        <v-radio :label="$t('ReserveForm.normal')" value="normal"/>
        <v-radio :label="$t('ReserveForm.np')" value="np"/>
        <v-radio :label="$t('ReserveForm.notAvailable')" value="rest"/>
        <v-radio :label="$t('ReserveForm.noteReserve')" value="note"/>
      </v-radio-group>
    </v-card-title>
    <v-card-text style="max-height: 400px" class="overflow-y-auto" :class="{'px-0': mode === 'update'}">
      <v-form ref="form" v-model="valid">
        <v-container fluid class="pa-0">
          <v-row dense class="ma-0">
            <template v-if="mode === 'create'">
              <v-col v-if="createMod === 'normal'" cols="12">
                <search-patient :patient.sync="patient" @no-data="useNP"/>
              </v-col>
              <template v-if="createMod === 'np'">
                <v-col cols="12" sm="6">
                  <v-text-field v-model.trim="patientName" :label="$t('ReserveForm.newPatientName')" outlined
                                :rules="[v => !!v.trim() || $t('ReserveForm.mustInsert')]"/>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field v-model.trim="patientPhone" :label="$t('ReserveForm.phone')" outlined/>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model.trim="patientBirthday" :label="$t('ReserveForm.ROCBirthday')" outlined/>
                </v-col>
              </template>
            </template>
            <v-col cols="12">
              <div class="d-flex align-center" style="gap: 6px;">
                <v-menu
                  v-model="dateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="selectDate"
                      label="看診日期"
                      readonly
                      outlined
                      dense
                      hide-details="auto"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="selectDate"
                    locale="zh"
                    @input="dateMenu = false"
                  ></v-date-picker>
                </v-menu>
                <vue-timepicker
                  class="reserve-time-picker"
                  v-model="selectTime"
                  format="HH:mm"
                  hour-label="時"
                  minute-label="分"
                  :minute-interval="5"
                  hide-clear-button
                  :hour-range="[7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21]"
                  hide-disabled-hours
                  style="height: 40px; border-radius: 4px;"
                  input-class="input-class"
                />
              </div>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                v-model="duration"
                :items="durationItems"
                outlined
                hide-details="auto"
                :label="$t('ReserveForm.duration')"
                :rules="[v => !!v || $t('ReserveForm.mustInsert')]"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                v-if="clinicUser"
                v-model="doctor"
                :items="doctorItems"
                item-text="doctor_name"
                item-value="doctor_code"
                outlined
                :label="$t('ReserveForm.chooseDoctor')"
                :rules="[v => !!v || $t('ReserveForm.mustInsert')]"
                hide-details="auto"
              />
              <v-select
                v-else
                v-model="clinic"
                :items="clinicItems"
                item-text="clinic_name"
                item-value="clinic_code"
                outlined
                :label="$t('ReserveForm.chooseClinic')"
                :rules="[v => !!v || $t('ReserveForm.mustInsert')]"
                hide-details="auto"
              />
            </v-col>
            <v-col v-if="mode === 'create' && (createMod === 'rest' || createMod === 'note')" cols="12" sm="6">
              <template>
                <v-select v-model="cycleSelect" label="週期設定" :items="cycleOptions" outlined hide-details="auto"
                          hint="周期設定只會在同月份內生效"/>
              </template>
            </v-col>
            <template v-if="createMod === 'normal' || createMod === 'np'">
              <v-col cols="12" sm="6">
                <v-textarea v-model.trim="remindOthers" :label="$t('ReserveForm.remindOther')" rows="3" no-resize
                            outlined hide-details/>
              </v-col>
            </template>
            <v-col cols="12" sm="6">
              <v-textarea v-model.trim="note" :label="$t('ReserveForm.note')" rows="3" outlined no-resize hide-details/>
            </v-col>
            <v-col v-if="createMod === 'normal' || createMod === 'np'" cols="12">
              <v-sheet outlined class="px-3 rounded" style="border-color: rgba(0,0,0,.38);">
                <div class="py-4" style="position: relative;">
                  <div class="label">
                    約診標籤
                  </div>
                  <div class="d-flex flex-wrap" style="row-gap: 4px; column-gap: 16px;">
                    <template v-if="reserveTagItems.length">
                      <v-checkbox
                        v-for="tag in reserveTagItems"
                        :key="tag.tag_id"
                        v-model="reserveTags"
                        :label="tag.content"
                        :value="tag.tag_id"
                        hide-details="auto"
                        class="mt-0"
                      >
                        <template v-slot:label>
                          <v-chip small class="ml-1">#{{ tag.content }}</v-chip>
                        </template>
                      </v-checkbox>
                    </template>
                    <div v-else>
                      <span class="grey-4--text">您尚未設定標籤群組，請先 <a
                        @click="editReserveTagName">點我設定</a></span>
                      <reserve-tag-editor
                        v-if="editFilterReserveTagsItems.length"
                        :editReserveTagNameDialog.sync="editReserveTagNameDialog"
                        :items="editFilterReserveTagsItems"
                        @getData="getReserveTags"
                      />
                    </div>
                  </div>
                </div>
              </v-sheet>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <v-card-actions :class="{'px-0': mode === 'update'}">
      <v-btn block color="primary" :disabled="!(valid && reserveTime)" @click="submit">
        {{ mode === 'update' ? $t('ReserveForm.confirmChange') : $t('ReserveForm.newReserve') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import SearchPatient from './SearchPatient';
import { formatDate } from '../../utils/filters';
import { mapGetters, mapState } from "vuex";
import {
  createBatchReserveAPI,
  createReserveAPI,
  getClinicListAPI,
  getDoctorListAPI,
  getReserveTagAPI,
  updateReserveAPI
} from "../../api";
import { handleFixLengthObjArray } from '../../utils/tool';
import ReserveTagEditor from './ReserveTagEditor';
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker'

export default {
  name: "ReserveForm",
  components: {ReserveTagEditor, SearchPatient, VueTimepicker},
  props: {
    reserve: Object,
    reserveFormDialog: Boolean,
    mode: String,
    edit: Boolean,
    reserveTagItemsData: Array,
    isPersistent: Boolean
  },
  data() {
    return {
      valid: true,
      patient: null,
      reserveDateDialog: false,
      dateMenu: false,
      selectDate: null,
      selectTime: null,
      duration: 30,
      doctor: null,
      remindOthers: null,
      remindCash: null,
      note: null,
      doctorItems: [],
      createMod: 'normal',
      clinic: null,
      clinicItems: [],
      patientName: '',
      patientBirthday: null,
      patientPhone: null,
      reserveTagItems: [],
      reserveTags: [],
      editReserveTagNameDialog: false,
      editFilterReserveTagsItems: [],
      cycleOptions: [
        {text: '不重複', value: 'single'},
        {text: '每日同一時段', value: 'everyDay'},
        {text: '每週同一時段', value: 'everyWeek'},
        {text: '平日(周一到周五)', value: 'weekday'}
      ],
      cycleSelect: 'single',
      loading: false,
    }
  },
  computed: {
    ...mapState({
      specialPatient: state => state.specialPatient,
    }),
    ...mapGetters([
      'axiosParamData',
      'clinicUser'
    ]),
    durationItems() {
      return [10, 15, 20, 30, 40, 45, 60, 75, 90, 120, 150]
      // return [...Array(31).keys()].splice(2).map(x => x * 5)
    },
    reserveTime() {
      return `${this.selectDate} ${this.selectTime}`
    }
  },
  methods: {
    useNP() {
      this.createMod = 'np'
    },
    roundTime(time, down = true) {
      const roundTo = 5 // minutes
      const roundDownTime = roundTo * 60 * 1000

      return down
        ? time - time % roundDownTime
        : time + (roundDownTime - (time % roundDownTime))
    },
    submit() {
      if (this.mode === 'create' || this.mode === 'bookNext') {
        this.createReserve()
      } else {
        this.updateReserve()
      }
    },
    createReserve() {
      if (!this.valid) return
      this.loading = true
      this.handleCycleReserve()
      const data = {
        ...this.axiosParamData,
        start_time: this.cycleSelect === 'single' ? this.reserveTime : this.handleCycleReserve(),
        work_time: this.duration,
        remind_others: this.remindOthers,
        remind_cash: this.remindCash,
        note: this.note,
        reserve_tag: this.reserveTags
      }
      if (this.clinicUser) data.doctor_code = this.doctor
      else data.clinic_code = this.clinic
      if (this.createMod === 'normal') {
        data.patient_code = this.patient.patient_code
      } else if (this.createMod === 'np') {
        data.name = this.patientName
        data.phone = this.patientPhone
        data.birthday = this.patientBirthday
      } else if (this.createMod === 'rest') {
        data.patient_code = this.specialPatient.rest
      } else if (this.createMod === 'note') {
        data.patient_code = this.specialPatient.note
      }
      if (this.cycleSelect === 'single')
        createReserveAPI(data).then(() => {
          this.$emit("refresh")
          this.$emit("showAlert", this.selectDate)
          this.$emit('update:reserveFormDialog', false)
        }).catch(e => {
          console.error(e)
          alert('新增約診失敗')
        }).finally(() => {
          this.loading = false
        })
      else {
        createBatchReserveAPI(data).then(() => {
          this.$emit("refresh")
          this.$emit('update:reserveFormDialog', false)
        }).catch(e => {
          console.error(e)
          alert('批次新增約診失敗')
        }).finally(() => {
          this.loading = false
        })
      }
    },
    handleCycleReserve() {
      const reserveTimes = []
      const reserveTime = this.$moment(this.reserveTime).format("HH:mm")
      const oReserveDay = this.$moment(this.reserveTime).format('YYYY-MM-DD')
      let endDayOfMonth = this.$moment(this.reserveTime).endOf('month').format('YYYY-MM-DD');

      switch (this.cycleSelect) {
        case 'everyDay':
          do {
            reserveTimes.push(`${endDayOfMonth} ${reserveTime}`)
            endDayOfMonth = this.$moment(endDayOfMonth).subtract(1, 'days').format('YYYY-MM-DD');
          }
          while (endDayOfMonth >= oReserveDay);
          break
        case 'everyWeek':
          do {
            if (parseInt(this.$moment(endDayOfMonth).weekday()) === parseInt(this.$moment(this.reserveTime).weekday())) {
              reserveTimes.push(`${endDayOfMonth} ${reserveTime}`)
            }
            endDayOfMonth = this.$moment(endDayOfMonth).subtract(1, 'days').format('YYYY-MM-DD');
          }
          while (endDayOfMonth >= oReserveDay);
          break
        case 'weekday':
          do {
            if (
              parseInt(this.$moment(endDayOfMonth).weekday()) !== 6 &&
              parseInt(this.$moment(endDayOfMonth).weekday()) !== 0
            ) {
              reserveTimes.push(`${endDayOfMonth} ${reserveTime}`)
            }
            endDayOfMonth = this.$moment(endDayOfMonth).subtract(1, 'days').format('YYYY-MM-DD');
          }
          while (endDayOfMonth >= oReserveDay);
          break
      }
      return reserveTimes
    },
    updateReserve() {
      const data = {
        ...this.axiosParamData,
        start_time: this.reserveTime,
        work_time: this.duration,
        remind_others: this.remindOthers,
        remind_cash: this.remindCash,
        note: this.note,
        reserve_tag: this.reserveTags
      }
      if (this.clinicUser) data.doctor_code = this.doctor
      else data.clinic_code = this.clinic
      updateReserveAPI(this.reserve.reserve_no, data).then(() => {
        this.$emit("refresh")
        this.$emit('update:edit', false)
      })
    },
    editReserveTagName() {
      this.editReserveTagNameDialog = true
      this.editFilterReserveTagsItems = handleFixLengthObjArray(this.reserveTagItems, 10, {tag_id: null, content: null})
    },
    getReserveTags() {
      getReserveTagAPI(this.axiosParamData).then(res => {
        this.reserveTagItems = res.data
      })
    },
    onFocus() {
      this.$emit('update:isPersistent', true)
    },
  },
  mounted() {
    // this.handleCycleReserve();

    if (this.reserve) {
      this.selectDate = this.reserve.start ? formatDate(this.reserve.start, 'YYYY-MM-DD') : formatDate(this.roundTime(new Date()), 'YYYY-MM-DD')
      this.selectTime = this.reserve.start ? formatDate(this.reserve.start, 'HH:mm') : formatDate(this.roundTime(new Date()), 'HH:mm')
      if (this.reserve.patient) this.patient = this.reserve.patient
    }
    if (this.mode === 'update') {
      this.selectDate = formatDate(parseInt(this.reserve.start_time) * 1000, 'YYYY-MM-DD')
      this.selectTime = formatDate(parseInt(this.reserve.start_time) * 1000, 'HH:mm')
      this.duration = this.$moment.duration(this.$moment(parseInt(this.reserve.end_time) * 1000).diff(this.$moment(parseInt(this.reserve.start_time) * 1000))).asMinutes()
      this.doctor = this.reserve.doctor_code
      this.clinic = this.reserve.clinic_code
      this.remindOthers = this.reserve.remind_others
      this.remindCash = this.reserve.remind_cash
      this.note = this.reserve.note
      this.reserveTags = this.reserve.reserve_tag.map(e => e.tag_id)
    }
    if (this.clinicUser) {
      getDoctorListAPI(this.axiosParamData).then(res => {
        this.doctorItems = res.data
      })
    } else {
      getClinicListAPI(this.axiosParamData).then(res => {
        this.clinicItems = res.data
      })
    }
    if (!(this.reserveTagItemsData && this.reserveTagItemsData.length)) {
      this.getReserveTags()
    } else {
      this.reserveTagItems = this.reserveTagItemsData
    }
  }
}
</script>

<style lang="scss">
.label {
  transform: translateY(-24px) scale(.75);
  font-size: 16px;
  transform-origin: top left;
  left: -4px;
  right: auto;
  position: absolute;
  top: 18px;
  color: rgba(0, 0, 0, .6);
  height: 20px;
  line-height: 20px;
  letter-spacing: normal;
  background: #ffffff;
  padding: 0 4px;
}

.reserve-time-picker {
  &:focus-visible {
    border-color: var(--v-primary-base);
    outline-color: var(--v-primary-base) !important;
  }

  .input-class {
    height: 40px !important;
    border-color: rgb(169 168 168) !important;
    border-radius: 4px;
    outline-color: var(--v-primary-base) !important;
  }
}


.vue__time-picker .dropdown ul li:not([disabled]).active {
  background: var(--v-primary-base);
}
</style>
