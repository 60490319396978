<template>
  <div>
    <div class="d-flex">
      <div>
        <div class="bullet text-subtitle-1 black--text">預約頁服務項目</div>
        <div style="color: var(--v-grey-5-base);">
          患者在「線上預約頁」的診療項目內容，醫師可在不同診所提供不同的項目。<br>
          因Google政策，若需要自定義「其他」內容，請先選擇小幫手提供的合適項目，再新增其他服務項目。
        </div>
      </div>
      <div class="ml-auto">
        <v-btn v-if="!isEdit" color="tiffany-7" dark :disabled="loading"  @click="edit">編輯</v-btn>
      </div>
    </div>

    <v-sheet class="pa-5 mt-2" max-width="860" style="position: relative;">
      <v-overlay :value="loading" absolute>
        <v-progress-circular
          indeterminate
          size="32"
        ></v-progress-circular>
      </v-overlay>
      <v-row class="mb-4 align-center no-gutters">
        <v-col v-if="!isEdit" cols="5">
          <v-text-field
            ref="firstTreatmentSelect"
            :value="firstSelectedTreatment"
            :error="firstSelectError"
            :disabled="!isEdit"
            :rules="[v => !!v || '*請先選擇小幫手提供的服務項目']"
            placeholder="請選擇您的看診項目"
            outlined
            dense
            hide-details="auto"
          />
        </v-col>
        <v-col v-else cols="5">
          <v-select
            ref="firstTreatmentSelect"
            v-model="firstSelectedTreatment"
            :items="onlineAppointmentTreatmentList"
            :rules="[v => !!v || '*請先選擇小幫手提供的服務項目']"
            :error="firstSelectError"
            :disabled="!isEdit"
            outlined
            dense
            placeholder="請選擇您的看診項目"
            hide-details="auto"
            class="mr-4"
          />
        </v-col>
      </v-row>
      <v-row v-for="(selection, index) in selectedTreatments" :key="index" class="mb-4 align-center no-gutters">
        <v-col v-if="!isEdit && selection.treatment !== '其他'" cols="5">
          <v-text-field
            :value="selection.treatment"
            :disabled="!isEdit"
            outlined
            dense
            hide-details
          />
        </v-col>

        <v-col v-if="isEdit" cols="5">
          <v-select
            v-model="selection.treatment"
            :items="onlineAppointmentTreatmentWithOtherItemList"
            :disabled="!isEdit"
            outlined
            dense
            hide-details
            placeholder="請選擇您的看診項目"
            class="mr-4"
            @change="handleTreatmentChange(index)"
          />
        </v-col>

        <!-- 當選擇的是「其他」時顯示 v-text-field -->
        <v-col v-if="selection.treatment === '其他'" cols="5">
          <v-text-field
            v-model="selection.customTreatment"
            :ref="'customInput' + index"
            :disabled="!isEdit"
            placeholder="請輸入自定義內容"
            outlined
            dense
            hide-details
          />
        </v-col>

        <v-col v-if="isEdit" cols="2">
          <v-btn icon @click="deleteOneTreatment(index)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-btn
        dark
        color="tiffany-7"
        :disabled="!isEdit"
        @click="addOneTreatment"
      >
        新增服務項目
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-sheet>

    <div v-if="isEdit" class="d-flex mt-2">
      <v-spacer/>
      <v-btn outlined @click="cancel">取消</v-btn>
      <v-btn color="red-6" dark class="ml-2" @click="save">儲存</v-btn>
    </div>

    <v-dialog v-model="saveErrorDialog" max-width="500">
      <v-card class="text-pre-line">
        <v-card-title class="text-center justify-center red-6--text pt-12 text-h5">儲存失敗</v-card-title>
        <v-card-text class="text-body-1 pt-4 text-center">
          請確認所有欄位皆設定完成。<br>
          請注意，預約頁服務項目的第一欄位必須選擇系統提供的看診項目。
        </v-card-text>
        <v-card-actions class="justify-center pb-12">
          <v-sheet max-width="300" width="300">
            <v-btn color="grey-4" block dark @click="saveErrorDialog = false">我知道了，關閉視窗</v-btn>
          </v-sheet>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { getSelectTreatmentAPI, updateSelectTreatmentAPI } from "@/api";
import { mapGetters } from "vuex";

export default {
  name: "OnlineAppointmentTreatmentSetting",

  props: {
    treatmentList: {
      type: Array,
      required: true
    },
    selectCode: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      loading: false,
      isEdit: false,
      selectTreatment: [],
      selectedTreatments: [],
      firstSelectedTreatment: null,
      firstSelectError: false,
      saveErrorDialog: false
    }
  },

  computed: {
    ...mapGetters([
      'axiosParamData',
      'clinicUser',
    ]),
    onlineAppointmentTreatmentList() {
      return this.treatmentList.map(treatment => treatment.name);
    },
    onlineAppointmentTreatmentWithOtherItemList() {
      const treatmentNames = this.onlineAppointmentTreatmentList.slice();
      treatmentNames.push('其他');
      return treatmentNames;
    },
    newSelectedTreatments() {
      const selectedTreatments = this.selectedTreatments.map(item => item.treatment === "其他" ? item.customTreatment : item.treatment)
        .filter(treatment => treatment !== "");
      if (this.firstSelectedTreatment) {
        selectedTreatments.unshift(this.firstSelectedTreatment)
      }
      return selectedTreatments
    }
  },

  watch: {
    selectCode: {
      immediate: true,
      handler() {
        this.cancel();
        this.getSelectTreatment();
      }
    },
    selectTreatment: {
      immediate: true,
      handler(newVal) {
        this.setSelectedTreatments(newVal);
      }
    },
    firstSelectedTreatment(value) {
      if (value) {
        this.firstSelectError = false;
      }
    }
  },

  methods: {
    setSelectedTreatments(treatments) {
      // 如果 treatments[0] 是 onlineAppointmentTreatmentList 內的項目 就拿出來放入 firstSelectedTreatment
      if (treatments[0] && this.onlineAppointmentTreatmentList.includes(treatments[0])) {
        this.firstSelectedTreatment = treatments[0];

        this.selectedTreatments = treatments.slice(1).map(item => {
          if (this.onlineAppointmentTreatmentList.includes(item)) {
            return {treatment: item, customTreatment: ""};
          } else {
            return {treatment: "其他", customTreatment: item};
          }
        });
      } else {
        this.firstSelectedTreatment = null;

        this.selectedTreatments = treatments.map(item => {
          if (this.onlineAppointmentTreatmentList.includes(item)) {
            return {treatment: item, customTreatment: ""};
          } else {
            return {treatment: "其他", customTreatment: item};
          }
        });
      }

      this.validFirst()
    },
    edit() {
      this.isEdit = true
    },
    cancel() {
      this.setSelectedTreatments(this.selectTreatment)
      this.isEdit = false
    },
    save() {
      if (this.firstSelectError) {
        return this.saveErrorDialog = true
      }
      this.loading = true
      const data = {
        ...this.axiosParamData,
        arry_item_option: this.newSelectedTreatments
      }
      if (this.clinicUser) {
        data.doctor_code = this.selectCode
      } else {
        data.clinic_code = this.selectCode
      }
      updateSelectTreatmentAPI(data).then(() => {
        this.isEdit = false
        this.getSelectTreatment()
      }).catch(e => {
        console.error(e)
        alert('儲存失敗')
        this.loading = false
      })
    },
    handleTreatmentChange(index) {
      // 當使用者改變選項為非「其他」時，清除 customTreatment 的值
      if (this.selectedTreatments[index].treatment !== "其他") {
        this.selectedTreatments[index].customTreatment = "";
      }

      if (this.selectedTreatments[index].treatment === "其他") {
        // 使用 $nextTick 確保 DOM 已更新，再自動聚焦到 v-text-field
        this.$nextTick(() => {
          const customInputRef = this.$refs['customInput' + index];
          if (customInputRef) {
            customInputRef[0]?.$refs?.input?.focus();
          }
        });
      }
    },
    addOneTreatment() {
      this.selectedTreatments.push({treatment: "", customTreatment: ""});
    },
    deleteOneTreatment(index) {
      this.selectedTreatments.splice(index, 1);
    },
    validFirst() {
      this.$nextTick(() => {
        if (this.$refs.firstTreatmentSelect) {
          const isValid = this.$refs.firstTreatmentSelect.validate();
          this.firstSelectError = !isValid;
        }
      });
    },
    async getSelectTreatment() {
      this.loading = true
      const params = {
        ...this.axiosParamData
      }

      if (this.clinicUser) {
        params.doctor_code = this.selectCode
      } else {
        params.clinic_code = this.selectCode
      }
      try {
        const res = await getSelectTreatmentAPI(params);
        this.selectTreatment = res.data?.arry_item_option || [];
        this.loading = false
      } catch (e) {
        console.error(e)
        alert('取得設定失敗')
      }
    }
  }
}
</script>

<style scoped>

</style>