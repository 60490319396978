<template>
  <v-container fluid class="pb-6">
    <div v-if="mode === 'create'" class="bullet mb-12">
      自行輸入QA的欄位，請用正常口語的方式描述您希望AI知道的問題和對應的回覆
    </div>
    <div v-if="mode === 'edit'" class="text-h5 font-weight-bold pt-4 pb-6">個性化QA調整</div>
    <v-row>
      <v-col cols="12" :md="mode === 'create' ? 6 : 12">
        <v-textarea
          v-model="formData.question"
          label="問題內容 (請勿超過200字)"
          outlined
          rows="6"
          no-resize
        />
      </v-col>
      <v-col cols="12" :md="mode === 'create' ? 6 : 12">
        <v-textarea
          v-model="formData.answer"
          label="回答內容 (請勿超過200字)"
          outlined
          rows="6"
          no-resize
        />
      </v-col>
      <v-col v-if="mode === 'create'" cols="12" :class="{'text-right': $vuetify.breakpoint.mdAndUp}">
        <v-btn
          color="primary"
          :block="$vuetify.breakpoint.smAndDown"
          @click="submit"
        >確認送出
        </v-btn>
      </v-col>
      <v-col v-if="mode === 'edit'" cols="12">
        <v-row no-gutters>
          <v-btn
            color="primary"
            block
            @click="submit"
          >確認調整
          </v-btn>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-btn block color="grey-7" dark @click="$emit('delete', $event, formData)">
              刪除問答
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn block color="grey-4" dark @click="$emit('cancel')">
              取消調整
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "EditQuestionAnswerCard",

  props: {
    mode: {
      type: String,
      default: 'create'
    },
    value: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      formData: {
        question: null,
        answer: null
      }
    }
  },

  watch: {
    value: {
      handler(v) {
        if (v) {
          this.formData = JSON.parse(JSON.stringify(v))
        }
      },
      deep: true,
      immediate: true
    },
  },

  methods: {
    submit() {
      if (this.mode === 'create') {
        this.$emit('submit', this.formData)
      }
      if (this.mode === 'edit') {
        this.$emit('submit', this.formData)
      }
    },
  }
}
</script>

<style scoped>
.bullet::before {
  content: "";
  background: #00A099;
  padding-inline: 5px;
  height: 10px;
  margin-inline-end: 6px;
  display: inline-block;
  border-radius: 50%;
}
</style>