<template>
  <v-row dense align="center" :class="{'flex-nowrap': $vuetify.breakpoint.smAndDown}">
    <v-col cols="10" sm="11" md="12"
           :class="[{ 'tab-col': $vuetify.breakpoint.mdAndUp }, 'overflow-y-auto flex-grow-1 flex-shrink-0']"
           style="max-width: 100%"
    >
      <v-tabs
        v-model="tab"
        :vertical="$vuetify.breakpoint.mdAndUp"
        :center-active="$vuetify.breakpoint.smAndDown"
        :show-arrows="$vuetify.breakpoint.smAndDown"
      >
        <v-tab href="#all" class="text-left justify-start">
          <v-sheet v-if="$vuetify.breakpoint.mdAndUp" height="20" width="20" class="mr-2"/>
          {{ $t('ReserveTabs.allClinic') }}
        </v-tab>
        <v-tab v-for="item in doctorClinicItems" :key="item.clinic_code" :href="`#${item.clinic_code}`"
               class="text-left justify-start">
          <v-sheet :color="item.color_code" height="20" width="20" class="mr-2"/>
          {{ item.clinic_name }}
        </v-tab>
      </v-tabs>
    </v-col>
    <v-col cols="auto" sm="1" md="12" class="flex-shrink-1 flex-grow-0">
      <v-btn v-if="$vuetify.breakpoint.mdAndUp" block outlined @click="dialog = true">
        {{ $t('ReserveTabs.manageClinic') }}
      </v-btn>
      <v-tooltip v-else v-model="show" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" @click="dialog = true">
            <v-icon color="primary" dark>mdi-cog-outline</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('ReserveTabs.manageClinic') }}</span>
      </v-tooltip>
    </v-col>
    <v-dialog v-model="dialog" max-width="500" persistent scrollable>
      <v-card>
        <v-btn
          fab
          icon
          absolute
          width="48"
          height="48"
          style="top: 0; right: 0; overflow: hidden;"
          @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-card-title>{{ $t('ReserveTabs.manageClinic') }}</v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col cols="8">
              <v-autocomplete
                v-model="clinic"
                :items="clinicItems"
                :loading="loading"
                return-object
                item-text="show"
                hide-details="auto"
                hide-no-data
                :placeholder="$t('ReserveTabs.searchClinic')"
                :search-input.sync="search"
                clearable
                dense
                outlined
              />
            </v-col>
            <v-col cols="4">
              <v-btn color="primary" block :loading="addLoading" :disabled="!clinic" @click="addClinic">{{ $t('ReserveTabs.confirmAdd') }}</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text style="max-height: 350px">
          <v-list>
            <v-list-item v-for="item in doctorClinicItems" :key="item.clinic_code">
              <v-list-item-title class="drag-content" v-text="item.clinic_name"/>
              <v-list-item-action class="my-0 flex-row align-center">
                <div style="width: 76px;">
                  <v-select
                    v-model="item.clinic_color"
                    :items="colors"
                    item-value="id"
                    single-line
                    outlined
                    hide-details
                    dense
                  >
                    <template v-slot:item="{ item }">
                      <v-sheet :color="item.color_code" width="20" height="20"/>
                    </template>
                    <template v-slot:selection="{ item }">
                      <v-sheet :color="item.color_code" width="20" height="20"/>
                    </template>
                  </v-select>
                </div>

                <v-btn icon  @click="handleDeleteClinic(item)">
                  <v-icon color="grey lighten-1">mdi-trash-can-outline</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>

        <v-card-actions class="justify-center">
          <v-btn color="primary" class="px-8" @click="save">儲存</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="confirmDialog" max-width="340">
      <v-card>
        <v-card-title class="justify-center">
          是否確認刪除{{ currentEdit.clinic_name }}
        </v-card-title>
        <v-card-actions>
          <v-spacer/>
          <v-btn :disabled="confirmBtnDisabled" color="primary" @click="removeClinic(currentEdit.clinic_code)">確認</v-btn>
          <v-btn :disabled="confirmBtnDisabled" dark color="grey-5" @click="confirmDialog = false">取消</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import {
  createDoctorClinicAPI,
  deleteDoctorClinicAPI,
  getColorAPI,
  searchClinicAPI,
  updateClinicColorAPI,
} from "../../api";
import { mapGetters } from "vuex";

export default {
  name: "ReserveTabs",
  props: ['value', 'tabItems'],
  data() {
    return {
      dialog: false,
      show: false,
      clinic: null,
      clinicItems: [],
      search: null,
      loading: false,
      addLoading: false,
      colors: [],
      currentEdit: {},
      confirmDialog: false,
      confirmBtnDisabled: false
    }
  },
  computed: {
    ...mapGetters([
      'axiosParamData',
    ]),
    tab: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('update:value', v)
      },
    },
    doctorClinicItems: {
      get() {
        return this.tabItems
      },
      set(v) {
        this.$emit('update:tab-items', v)
      }
    }
  },
  watch: {
    search(v) {
      if (v) this.searchClinic(v)
    },
  },
  methods: {
    addClinic() {
      this.addLoading = true
      createDoctorClinicAPI({...this.axiosParamData, clinic_code: this.clinic.clinic_code}).then(() => {
        this.tab = this.clinic.clinic_code
        this.clinic = null
        this.search = null
        this.clinicItems = []
        this.$emit('refresh')
      }).catch(e => {
        if (e.response && e.response.data && e.response.data.messages) {
          alert(e.response.data.messages)
        } else {
          alert(this.$t('GENERAL.oops'))
          console.error(e)
        }
      }).finally(() => {
        this.addLoading = false
      })
    },
    handleDeleteClinic(clinic) {
      this.currentEdit = clinic
      this.confirmDialog = true
    },
    removeClinic(code) {
      this.confirmBtnDisabled = true
      deleteDoctorClinicAPI(code, this.axiosParamData).finally(() => {
        this.$emit('refresh')
        this.confirmDialog = false
      }).finally(() => {
        this.confirmBtnDisabled = false
      })
    },
    searchClinic(v) {
      this.loading = true
      searchClinicAPI({clinic_name: v}).then(res => {
        const clinicItems = res.data
        this.clinicItems = clinicItems.map(v => {
          return {...v, show: `${v.clinic_name}(${v.address})`}
        })
      }).finally(() => {
        this.loading = false
      })
    },
    getColors() {
      getColorAPI(this.axiosParamData).then(res => {
        this.colors = res.data
      })
    },
    save() {
      const setData = this.doctorClinicItems.map(e => {
        return {
          id: e.id,
          color_id: e.clinic_color
        }
      })
      const data = {
        ...this.axiosParamData,
        data: setData
      }
      updateClinicColorAPI(data).then(() => {
        this.$emit('refresh')
        this.dialog = false
      })
    }
  },
  mounted() {
    this.getColors()
  }
}
</script>

<style scoped lang="scss">
.tab-col {
  height: calc(100vh - 130px);
}
</style>