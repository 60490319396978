<template>
  <div>
    <v-card-text class="overflow-y-auto" style="height: calc(100vh - 350px);">
      <v-overlay :value="loading" absolute>
        <v-progress-circular
          indeterminate
          size="64"
        ></v-progress-circular>
      </v-overlay>

      <dentco-alert-bar
        :message="`${clinicUser ? '診所' : ''}可使用點數額度：${quota} 點`"
        :btn-link="$enum.DENTCO_CS_LINE"
        :loading="quotaLoading"
        btn-title="聯繫牙醫小幫手儲值"
        class="text-body-1"
      />

      <div class="d-flex flex-column mt-2" style="gap: 16px;">
        <div>
          <div>
            <div class="bullet text-subtitle-1 font-weight-medium">
              自動化通知設定
            </div>
            <div v-if="settingsData" class="pl-6">
              <SettingCard
                v-if="settingsData.length"
                :is-edit="isEdit"
                :settings-data.sync="settingsData"
                :settings-meta="settingsMeta"
                style="max-height: 500px;"
                :convert-func="convertVoiceSettingData"
                :restore-func="restoreVoiceSettingData"
              />
              <div v-else>尚無資料可設定</div>
            </div>
          </div>
        </div>
      </div>
    </v-card-text>

    <v-card-actions>
      <v-spacer/>
      <v-btn v-if="!isEdit" color="primary" class="px-8" @click="isEdit = true;">
        <v-icon small>mdi-pencil</v-icon>
        編輯
      </v-btn>
      <template v-else>
        <v-btn color="grey-5" dark class="px-4 px-md-8" @click="cancel">取消修改</v-btn>
        <v-btn color="red-6" dark class="px-4 px-md-8" @click="save">確認修改</v-btn>
      </template>
    </v-card-actions>

    <save-alert
      ref="alertRef"
      :to="to"
      @cancel="cancel"
    />
  </div>
</template>

<script>
import SettingCard from "../../../components/message/setting/SettingCard.vue";
import { mapGetters } from "vuex";
import DentcoAlertBar from "../../../components/utils/DentcoAlertBar.vue";
import { convertVoiceSettingData, restoreVoiceSettingData } from "../../../utils/messageSetting";
import { getSmsQuotaAPI, getVoiceSettingsAPI, updateVoiceSettingsAPI } from "../../../api";
import SaveAlert from "../../../components/message/setting/SaveAlert.vue";

export default {
  name: "VoiceSetting",

  components: {SaveAlert, DentcoAlertBar, SettingCard},

  data() {
    return {
      loading: false,
      quotaLoading: false,

      quota: 0,
      settings: {},
      isEdit: false,
      settingsData: null,
      to: null,

      convertVoiceSettingData,
      restoreVoiceSettingData
    }
  },

  computed: {
    ...mapGetters([
      'axiosParamData',
      'clinicUser',
      'doctorUser',
      'hasOwnChannel'
    ]),
    settingsMeta() {
      return [
        {
          "key": "day2Remind",
          "type": "select",
          "title": "兩天前",
          "options": [
            {"text": "10:00", "value": "10:00"},
            {"text": "12:00", "value": "12:00"},
            {"text": "17:00", "value": "17:00"},
            {"text": "不通知", "value": null}
          ]
        },
        {
          "key": "day1Remind",
          "type": "select",
          "title": "一天前",
          "options": [
            {"text": "09:00", "value": "09:00"},
            {"text": "11:00", "value": "12:00"},
            {"text": "13:00", "value": "13:00"},
            {"text": "16:00", "value": "16:00"},
            {"text": "不通知", "value": null}
          ]
        },
        {
          "key": "day0Remind",
          "type": "select",
          "title": "當天",
          "options": [
            {"text": "07:00", "value": "07:00"},
            {"text": "08:00", "value": "08:00"},
            {"text": "11:00", "value": "11:00"},
            {"text": "15:00", "value": "15:00"},
            {"text": "不通知", "value": null}
          ]
        },
        {
          "key": "halfYearRemind",
          "type": "select",
          "title": "6個月定檢",
          "options": [
            {"text": "10:30", "value": true},
            {"text": "不通知", "value": false}
          ],
          "tooltip": "患者180天內沒有到診紀錄，會自動於第181天上午10:30提醒。"
        }
      ]
    }
  },

  mounted() {
    this.getQuota()
    this.getVoiceSetting()
  },

  methods: {
    getQuota() {
      this.quotaLoading = true
      getSmsQuotaAPI(this.axiosParamData).then(res => {
        this.quota = res.data.quota
      }).finally(() => {
        this.quotaLoading = false
      })
    },
    getVoiceSetting() {
      this.loading = true
      getVoiceSettingsAPI(this.axiosParamData).then(res => {
        this.settings = JSON.parse(JSON.stringify(res.data))
        this.setSettingsData()
      }).finally(() => {
        this.loading = false
      })
    },
    save() {
      this.loading = true
      const data = {
        ...this.axiosParamData,
        setting_data: this.settingsData
      }
      updateVoiceSettingsAPI(data).then(() => {
        this.isEdit = false
        this.getVoiceSetting()
        // this.loading = false
      }).catch(e => {
        console.error(e)
        this.loading = false
      })
    },
    cancel() {
      this.setSettingsData()
      this.isEdit = false
    },
    setSettingsData() {
      const settingsData = JSON.parse(JSON.stringify(this.settings))
      const codeKey = this.clinicUser ? 'clinic_code' : 'doctor_code'
      const nameKey = this.clinicUser ? 'clinic_name' : 'doctor_name'
      settingsData.map(e => {
        delete e[codeKey]
        delete e[nameKey]
        return e
      })
      this.settingsData = settingsData
    },
    showNotSaveAlert() {
      this.$refs.alertRef.alertDialog = true
    }
  },

  beforeRouteLeave(to, from, next) {
    if (this.isEdit) {
      this.to = to
      this.showNotSaveAlert()
      next(false)
    } else {
      next()
    }
  }
}
</script>

<style scoped>

</style>
