<template>
  <v-menu
    v-model="menu"
    z-index="2"
    :nudge-width="200"
    offset-x
  >
    <template #activator="{ on, attrs }">
      <v-btn
        icon
        class="px-0"
        style="min-width: fit-content"
        v-bind="attrs"
        v-on="on"
      >
        <slot />
      </v-btn>
    </template>
    <v-card class="px-5 py-2">
      <div class="grid">
        <img
          v-for="icon in dentcoIcons"
          :key="icon.id"
          :src="icon.full_path"
          alt=""
          class="grid-img"
          @click="onClick(icon)"
        >
      </div>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: 'TreatmentImages',
  props: {
    iconId: {
      type: Number,
      default: 1
    },
    dentcoIcons: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data () {
    return {
      menu: false
    };
  },
  methods: {
    onClick (icon) {
      this.$emit('update:iconId', icon.id);
    }
  }
};
</script>
<style scoped lang="css">
.grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
  max-height: 175px;
  overflow-y: auto;
}

.grid-img {
  width: 60px;
  height: 60px;
  align-self: start;
  cursor: pointer;
}
</style>
