<template>
  <div>
    <v-tabs>
      <v-tab :to="{name: 'messageSend'}">LINE 群發</v-tab>
      <v-tab :to="{name: 'smsMessageSend'}">簡訊群發</v-tab>
    </v-tabs>
    <div>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MessageSendIndex'
};
</script>

<style scoped>
/* Your styles here */
</style>
