<template>
  <v-card class="py-8 px-8" rounded="xl">
    <v-overlay :value="loading" absolute z-index="3">
      <v-progress-circular indeterminate size="64"/>
    </v-overlay>
    <v-row>
      <!-- 基本資料區 -->
      <v-col cols="12" class="py-2">
        <div class="d-flex justify-space-between mb-8">
          <h6 class="text-subtitle-1 font-weight-bold">
            醫師資歷
          </h6>
          <div class="d-flex justify-end" style="gap: 8px">
            <v-btn
              color="grey"
              outlined
              class="text-subtitle-2 rounded-lg"
              @click="onClose"
            >
              取消
            </v-btn>
            <v-btn
              color="teal lighten-2"
              class="text-subtitle-2 rounded-lg white--text"
              @click="onConfirm"
            >
              儲存
            </v-btn>
          </div>
        </div>
        <v-form ref="form" v-model="valid">
          <draggable
            v-model="experience"
            tag="ul"
            class="px-0"
            handle=".draggable"
            animation="200"
            @start="drag = true"
            @end="drag = false"
          >

            <v-slide-y-transition
              v-for="(e, i) in experience"
              :key="i"
            >
              <v-list-item class="grey lighten-4 rounded-lg mb-5" dense>
                <v-btn text class="px-0" small style="min-width: fit-content">
                  <v-icon size="20" class="draggable">
                    mdi-cursor-move
                  </v-icon>
                </v-btn>
                <v-list-item-title class="flex-grow-1 px-2">
                  <div
                    class="d-flex flex-column justify-center py-2"
                    style="gap: 0.6rem"
                  >
                    <v-text-field
                      :value="e"
                      placeholder="請輸入醫師資歷"
                      background-color="white"
                      dense
                      hide-details="auto"
                      outlined
                      required
                      aria-required="true"
                      :rules="[rules.required]"
                      @keyup="updateExperience(i, $event)"
                    />
                  </div>
                </v-list-item-title>
                <delete-dialog @confirm="experience.splice(i, 1)"/>
              </v-list-item>
            </v-slide-y-transition>
          </draggable>
        </v-form>
        <v-btn class="rounded-lg mb-7" depressed block @click="addExperience">
          <v-icon color="grey" size="15">
            mdi-plus-circle
          </v-icon>
          點我新增醫師資歷
        </v-btn>
      </v-col>
      <!-- 社群連結區 -->
    </v-row>
    <v-card-actions>
      <v-spacer />
      <v-btn
        color="grey"
        outlined
        class="text-subtitle-2 rounded-lg"
        @click="onClose"
      >
        取消
      </v-btn>
      <v-btn
        color="teal lighten-2"
        class="text-subtitle-2 rounded-lg white--text"
        @click="onConfirm"
      >
        儲存
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import draggable from 'vuedraggable';
import DeleteDialog from './DeleteDialog.vue';

export default {
  name: 'MedicalQualificationsForm',

  components: { DeleteDialog, draggable },

  props: {
    show: {
      type: Boolean,
      default: false
    },
    doctor: {
      type: Object,
      default: () => {
        return {
          experience: []
        };
      }
    }
  },

  data () {
    return {
      loading: false,
      valid: true,
      drag: true,
      experience: [],
      rules: {
        required: value => !!value || '*請輸入有效文字，不可輸入空白內容'
      }
    };
  },

  watch: {
    show: {
      handler (show) {
        if (show && this.doctor) {
          this.experience = this.doctor?.qualification?.split(',') || [];
        }
      },
      immediate: true,
      deep: true
    }
  },

  methods: {
    onConfirm () {
      this.$refs.form.validate()
      if (!this.valid) return
      this.loading = true
      this.$emit('update', {
        qualification: this.experience.filter(item => !!item).join(','),
        callback: (status) => {
          this.loading = false
          if (status === 'success') {
            this.onClose();
          } else {
            alert('更新失敗，請再試一次')
          }
        }
      });
    },
    onClose () {
      this.$emit('update:show', false);
    },
    addExperience() {
      this.experience.push('');
    },
    updateExperience (index, value) {
      this.experience.splice(index, 1, value.target.value);
    }
  }
};
</script>

<style scoped lang="scss">
.flip-list-move {
  transition: transform 0.5s;
}
.custom-input {
  ::v-deep &.success--text {
    color: var(--v-primary-base) !important; /* 自訂成功文字顏色 */
  }
}
</style>
