<template>
  <v-card v-if="event" class="mx-auto reserve-card" outlined>
    <v-btn
      fab
      icon
      absolute
      width="48"
      height="48"
      style="top: 0; right: 0; overflow: hidden;"
      @click="$emit('update:reserveDialogWidth', '600'); $emit('update:selectedOpen', false)"
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <v-card-title style="max-height: 180px; overflow-x: auto">
      <v-list-item three-line class="px-1" style="max-width: 100%;">
        <v-list-item-avatar :size="$vuetify.breakpoint.xsOnly ? 60 : 80" color="grey">
          <v-img :src="event.file_name_photo"/>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title
            class="text-h5 mb-1 d-flex align-center"
            :class="{'flex-wrap': $vuetify.breakpoint.smAndDown}"
          >
            <div class="align-bottom">
              {{ event.name }}
              <img
                v-show="event.is_block == '1'"
                src="https://i.imgur.com/2wD2hbm.png" width="25" height="auto"
              >
            </div>
            <div
              class="text-overline align-bottom ml-2 mr-1"
              :class="{'ml-auto': $vuetify.breakpoint.smAndDown}"
            >
              <v-btn v-if="!isSpecialPatient"
                     outlined
                     small
                     color="grey-5"
                     @click="editPatientDialog = true">
                {{ $t('ReserveDetailCard.editInfo') }}
              </v-btn>
              <v-dialog v-if="editPatientDialog" v-model="editPatientDialog" max-width="960" scrollable>
                <patient-card
                  :patient-code="event.patient_code"
                  :show-tab="showPatientTab"
                  @close="editPatientDialog = false; showPatientTab = null;"
                  @successPb="getReserveDetail"
                />
              </v-dialog>
            </div>
            <v-menu v-if="!isSpecialPatient" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  v-bind="attrs"
                  v-on="on"
                  class="primary mr-1 ml-auto"
                >
                  傳送訊息給患者
                </v-btn>
              </template>
              <v-list dense outlined class="text-body-2">
                <v-menu offset-x left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item
                      class="justify-center"
                      v-bind="attrs"
                      v-on="on"
                      :disabled="!earlierThanReserve"
                    >
                      預約提醒
                    </v-list-item>
                  </template>
                  <v-list dense outlined class="text-body-2">
                    <v-list-item
                      class="justify-center"
                      @click="clickRemind('line')"
                    >
                      LINE訊息
                    </v-list-item>
                    <v-list-item
                      class="justify-center"
                      @click="clickRemind('sms')"
                    >
                      簡訊
                    </v-list-item>
                    <v-list-item
                      class="justify-center"
                      @click="clickRemind('call')"
                    >
                      電話語音
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-menu offset-x left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item class="justify-center" v-bind="attrs" v-on="on">
                      定檢通知
                    </v-list-item>
                  </template>
                  <v-list dense outlined class="text-body-2">
                    <v-list-item
                      class="justify-center"
                      @click="clickHalfYearRemind('line')"
                    >
                      LINE訊息
                    </v-list-item>
                    <v-list-item
                      class="justify-center"
                      @click="clickHalfYearRemind('sms')"
                    >
                      簡訊
                    </v-list-item>
                    <v-list-item
                      class="justify-center"
                      @click="clickHalfYearRemind('call')"
                    >
                      電話語音
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-menu offset-x left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item
                      class="justify-center"
                      v-bind="attrs"
                      v-on="on"
                      :disabled="earlierThanReserve"
                    >
                      評價邀請
                    </v-list-item>
                  </template>
                  <v-list dense outlined class="text-body-2">
                    <v-list-item
                      class="justify-center"
                      @click="clickSurvey('line')"
                    >
                      LINE訊息
                    </v-list-item>
                    <v-list-item
                      class="justify-center"
                      @click="clickSurvey('sms')"
                    >
                      簡訊
                    </v-list-item>
                  </v-list>
                </v-menu>
                <!--                <v-list-item-->
                <!--                  class="justify-center"-->
                <!--                  :disabled="earlierThanReserve"-->
                <!--                  @click="clickSurvey"-->
                <!--                >-->
                <!--                  評價邀請-->
                <!--                </v-list-item>-->
                <v-menu offset-x left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item
                      class="justify-center"
                      v-bind="attrs"
                      v-on="on"
                      :disabled="earlierThanReserve"
                    >
                      術後關懷
                    </v-list-item>
                  </template>
                  <v-list dense outlined class="text-body-2">
                    <v-list-item
                      class="justify-center"
                      @click="clickCare('line')"
                    >
                      LINE訊息
                    </v-list-item>
                    <v-list-item
                      class="justify-center"
                      @click="clickCare('sms')"
                    >
                      簡訊
                    </v-list-item>
                  </v-list>
                </v-menu>
                <!--                <v-list-item-->
                <!--                  class="justify-center"-->
                <!--                  :disabled="earlierThanReserve"-->
                <!--                  @click="clickCare"-->
                <!--                >-->
                <!--                  術後關懷-->
                <!--                </v-list-item>-->
                <v-menu offset-x left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item class="justify-center" v-bind="attrs" v-on="on">
                      衛教/同意書訊息
                    </v-list-item>
                  </template>

                  <v-list
                    :class="[
                      (confirmMsgDialog) ? 'element-hide' : ''
                    ]"
                  >
                    <v-menu v-if="dentcoHealthEducations.length" offset-x left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-list-item class="justify-center" v-bind="attrs" v-on="on">
                          小幫手衛教套版
                        </v-list-item>
                      </template>
                      <v-list>
                        <v-list>
                          <v-list-item
                            v-for="eduItem in dentcoHealthEducations"
                            :key="eduItem.id"
                            @click="clickHealthEdu(eduItem)"
                          >
                            <v-list-item-title>{{ eduItem.keyword }}</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-list>
                    </v-menu>
                    <v-menu offset-x left max-height="70vh">
                      <template v-slot:activator="{ on, attrs }">
                        <v-list-item class="justify-center" v-bind="attrs" v-on="on">
                          自定義衛教/同意書
                        </v-list-item>
                      </template>
                      <v-list>
                        <v-list-item
                          v-for="eduItem in ownHealthEducations"
                          :key="eduItem.id"
                          @click="clickHealthEdu(eduItem)"
                        >
                          <v-list-item-title>{{ eduItem.keyword }}</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="editHealthEducationDialog = true">
                          <v-list-item-title>
                            <span style="color: #4525F2;" class="text-decoration-underline">設定更多衛教資訊</span>
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-list>
                </v-menu>
                <v-list-item class="justify-center" @click="handleTelemedicine">
                  {{ event.google_meet ? '查看視訊連結' : '建立遠端視訊' }}
                </v-list-item>
                <!--                <v-list-item class="justify-center" @click="$refs.sendMessage.sendMessageDialog = true">-->
                <!--                  自行輸入內容-->
                <!--                </v-list-item>-->
                <v-menu offset-x left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item
                      class="justify-center"
                      v-bind="attrs"
                      v-on="on"
                    >
                      自行輸入內容
                    </v-list-item>
                  </template>
                  <v-list dense outlined class="text-body-2">
                    <v-list-item
                      class="justify-center"
                      @click="$refs.sendMessage.sendMessageDialog = true"
                    >
                      LINE訊息
                    </v-list-item>
                    <v-list-item
                      class="justify-center"
                      @click="$refs.smsMessage.sendMessageDialog = true"
                    >
                      簡訊
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-list>
            </v-menu>
          </v-list-item-title>
          <v-list-item-subtitle class="d-flex">
            <div v-for="(item, i) in info" :key="i" class="d-flex align-center mr-2">
              <v-icon class="mr-1" size="16">
                {{ item.icon }}
              </v-icon>
              <div>
                {{ item.value }}
              </div>
            </div>
            <div class="d-flex align-center">
              <v-img
                :src="showBindStatus({status: event.bind_status}).icon"
                max-width="16"
                width="16"
                class="mr-1"
              />
              <div>
                {{ showBindStatus({status: event.bind_status}).text }}
              </div>
              <v-dialog
                v-model="bindStatusDialog"
                width="fit-content"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-help-circle-outline</v-icon>
                  </v-btn>
                </template>

                <v-card>
                  <v-card-title class="grey-7--text font-weight-bold">
                    患者綁定狀態
                  </v-card-title>

                  <v-card-text>
                    <div v-if="event.bind_status === '0'">
                      <div class="d-flex align-center">
                        <v-img
                          :src="showBindStatus({status: event.bind_status}).icon"
                          max-width="20"
                          width="20"
                          class="mr-1"
                        />
                        <div class="text-body-2">沒有任何綁定的紀錄</div>
                      </div>
                    </div>
                    <template v-else>
                      <div v-for="(status, idx) in bindStatusDetail" :key="idx" class="d-flex align-center mb-2">
                        <v-img
                          :src="status.icon"
                          max-width="20"
                          width="20"
                          class="mr-1"
                        />
                        <div class="text-body-2" v-html="status.text"/>
                      </div>
                    </template>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </div>
          </v-list-item-subtitle>
          <v-list-item-subtitle class="d-flex flex-nowrap align-center">
            <div style="text-overflow: ellipsis; overflow:hidden; white-space: nowrap; width: 70%;">
              患者備註 {{ event.patient_note }}
            </div>
            <v-dialog max-width="500">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  color="grey-5"
                  outlined
                  small
                >查看完整備註內容</v-btn>
              </template>
              <v-card>
                <v-card-title>
                  患者備註 <v-btn icon @click="showPatientTab = 'note'; editPatientDialog = true;"><v-icon>mdi-pencil</v-icon></v-btn>
                </v-card-title>
                <v-card-text class="text-pre-line" v-text="event.patient_note"/>
              </v-card>
            </v-dialog>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-card-title>
    <v-divider/>
    <v-card-text style="height: fit-content;">
      <v-row no-gutters dense>
        <v-col cols="12" :md="showRecord && $vuetify.breakpoint.mdAndUp ? 7 : 12">
          <v-card-title class="justify-space-between">
            {{ $t('ReserveDetailCard.reserveInfo') }}
            <div>
              <v-btn class="white--text" :color="edit ? 'red-6' : 'primary'" @click="showRecord = false; edit = !edit">
                <v-icon left>
                  {{ edit ? 'mdi-pencil-off' : 'mdi-pencil' }}
                </v-icon>
                {{ edit ? $t('ReserveDetailCard.cancelEdit') : $t('ReserveDetailCard.editReserve') }}
              </v-btn>
            </div>
          </v-card-title>
          <v-card-text class="pt-0 reserve-info">
            <v-row v-if="!edit" dense>
              <v-col cols="12" sm="6">
                <div>&#8231; {{ $t('ReserveDetailCard.attendStatus') }}
                  <span class="black--text" v-text="attendStatusMap[event.attend_status].text"/>
                  <v-menu v-if="earlierThanReserve" offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="red-6"
                        class="white--text ml-1"
                        outlined
                        small
                        v-bind="attrs"
                        v-on="on"
                      >
                        再次提醒
                      </v-btn>
                    </template>
                    <v-list dense outlined class="text-body-2">
                      <v-list-item
                        class="justify-center"
                        @click="clickRemind('line')"
                      >
                        LINE訊息
                      </v-list-item>
                      <v-list-item
                        class="justify-center"
                        @click="clickRemind('sms')"
                      >
                        簡訊
                      </v-list-item>
                      <v-list-item
                        class="justify-center"
                        @click="clickRemind('call')"
                      >
                        電話語音
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
                <div v-if="clinicUser">&#8231; {{ $t('ReserveDetailCard.doctor') }}
                  <span style="color: #000000">{{ event.doctor_name }}</span>
                </div>
                <div v-else>&#8231; {{ $t('ReserveDetailCard.clinic') }}
                  <span style="color: #000000">{{ event.clinic_name }}</span>
                </div>
                <div>&#8231; {{ $t('ReserveDetailCard.reserveTime') }}
                  <span style="color: #000000">{{ parseInt(event.start_time) * 1000 | formatDate }}</span>
                </div>
                <div>&#8231; {{ $t('ReserveDetailCard.reserveEndTime') }}
                  <span style="color: #000000">{{ parseInt(event.end_time) * 1000 | formatDate }}</span>
                </div>
              </v-col>
              <v-col cols="12" sm="6">
                <div>&#8231; {{ $t('ReserveDetailCard.remindOther') }}
                  <span style="color: #000000">{{ event.remind_others || $t('ReserveDetailCard.null') }}</span>
                </div>
                <div>&#8231; {{ $t('ReserveDetailCard.note') }}
                  <span style="color: #000000">{{ event.note || $t('ReserveDetailCard.null') }}</span>
                </div>
              </v-col>
              <v-col cols="12">
                <div>&#8231; 本次約診標籤：
                  <v-chip v-for="tag in event.reserve_tag" :key="tag.tag_id" small class="mr-1">#{{
                      tag.content
                    }}
                  </v-chip>
                </div>
              </v-col>
            </v-row>
            <reserve-form
              v-else
              :reserve="event"
              mode="update"
              :edit.sync="edit"
              @refresh="refresh"
            />
          </v-card-text>
          <template v-if="!isSpecialPatient && !edit">
            <v-card-title class="justify-space-between">
              {{ $t('ReserveDetailCard.otherInfo') }}
              <v-btn class="white--text" :color="showRecord ? 'red-6' : 'primary'"
                     @click="edit = false; showRecord = !showRecord">
                <v-icon left>
                  {{ showRecord ? 'mdi-eye-off-outline' : 'mdi-eye-outline' }}
                </v-icon>
                {{
                  showRecord ? $t('ReserveDetailCard.closeReserveRecord') : $t('ReserveDetailCard.openReserveRecord')
                }}
              </v-btn>
            </v-card-title>
            <v-card-text class="pt-0 reserve-other-info">
              <v-row dense>
                <v-col cols="auto">
                  &#8231; {{ $t('ReserveDetailCard.keepAppointment') }}: {{ event.appointment_count }}
                </v-col>
                <v-col cols="auto">
                  &#8231; {{ $t('ReserveDetailCard.alterAppointment') }}: {{ event.change_count }}
                </v-col>
                <v-col cols="auto">
                  &#8231; {{ $t('ReserveDetailCard.standUp') }}: {{ event.stood_up_count }}
                </v-col>
              </v-row>
            </v-card-text>
          </template>
        </v-col>
        <v-col v-if="showRecord && $vuetify.breakpoint.mdAndUp" md="5" class="overflow-y-auto"
               style="max-height: 334px">
          <reserve-record
            :patient-code="event.patient_code"
          />
        </v-col>
        <v-dialog v-if="showRecord && $vuetify.breakpoint.smAndDown" v-model="showRecord" scrollable max-width="495">
          <v-card>
            <v-btn
              fab
              icon
              absolute
              width="48"
              height="48"
              style="top: 0; right: 0; overflow: hidden;"
              @click="showRecord = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-card-title>{{ $t('ReserveDetailCard.reserveRecord') }}</v-card-title>
            <reserve-record
              :patientCode="event.patient_code"
              style="max-height: fit-content"
              class="overflow-y-auto"
            />
          </v-card>
        </v-dialog>
      </v-row>
    </v-card-text>
    <v-divider/>
    <v-card-actions v-if="!edit">
      <v-btn color="red-6" dark @click="changeReserveStatus(6)">
        {{ $t('ReserveDetailCard.deleteReserve') }}
      </v-btn>
      <v-spacer/>
      <v-btn
        v-if="statusBtn.length < 3 || $vuetify.breakpoint.smAndUp" color="blue-6" dark @click="bookNextDialog = true">
        {{ $t('ReserveDetailCard.bookNext') }}
      </v-btn>
      <v-btn
        v-if="!isSpecialPatient"
        v-for="(item, i) in statusBtn" :key="i" @click="changeReserveStatus(item.status)" :color="item.color" dark
      >
        {{ item.text }}
      </v-btn>
    </v-card-actions>
    <v-card-actions v-if="statusBtn.length >= 3 && $vuetify.breakpoint.xsOnly">
      <v-btn block color="blue" dark @click="bookNextDialog = true">{{ $t('ReserveDetailCard.bookNext') }}</v-btn>
    </v-card-actions>


    <v-dialog v-if="bookNextDialog" v-model="bookNextDialog" max-width="600" persistent>
      <v-card class="pa-2">
        <v-card-title>{{ $t('ReserveDetailCard.bookNextTitle') }} -- {{ event.name }}</v-card-title>
        <v-btn
          fab
          icon
          absolute
          width="48"
          height="48"
          style="top: 0; right: 0; overflow: hidden;"
          @click="bookNextDialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <reserve-form
          :reserve="{patient: {patient_code: event.patient_code}}"
          :reserveFormDialog.sync="bookNextDialog"
          mode="bookNext"
          @refresh="refresh"
          @showAlert="$emit('showAlert', $event)"
        />
      </v-card>
    </v-dialog>
    <send-message
      ref="sendMessage"
      :patient-name="event.name"
      :reserve-no="event.reserve_no"
      :btnProps="{text: true, small: true}"
      style="display: none;"
    />
    <send-sms-message
      v-show="false"
      ref="smsMessage"
      :patient-name="event.name"
      :reserve-no="event.reserve_no"
      :btnProps="{text: true}"
    />
    <v-dialog v-model="telemedicineDialog" max-width="400">
      <v-card v-if="telemedicineLoading" color="primary" dark>
        <v-card-text>
          視訊連結建立中...
          <v-progress-linear indeterminate color="white" class="mb-0"/>
        </v-card-text>
      </v-card>
      <v-card v-else-if="notBind">
        <v-card-title>患者尚未綁定Line，無法建立視訊連結</v-card-title>
        <v-card-actions class="justify-center">
          <v-btn color="primary" @click="telemedicineDialog = false">我知道了</v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-else>
        <v-card-title>遠端視訊看診</v-card-title>
        <v-card-text>
          系統已生成遠端視訊連結，請點擊發送通知，系統將透過LINE通知病人於預約時間加入遠端視訊。
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" :href="event.google_meet" target="_blank">加入遠端視訊</v-btn>
          <v-spacer/>
          <v-btn color="blue-6" class="white--text" @click="sendTelemedicineDialog = true">發送通知</v-btn>
          <v-dialog v-if="sendTelemedicineDialog" v-model="sendTelemedicineDialog" max-width="350">
            <v-card>
              <v-card-title>系統將發送遠端視訊連結給病人，請確認後傳送。</v-card-title>
              <v-card-actions class="justify-center">
                <v-btn color="primary" @click="sendTelemedicine">確認發送</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-btn v-if="reOpen" color="red-6" class="white--text" @click="generationTelemedicine">重新生成視訊連結
          </v-btn>
          <v-btn v-else color="grey-5" class="white--text" @click="telemedicineDialog = false">暫不發送</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="editHealthEducationDialog" max-width="600" scrollable>
      <custom-education/>
    </v-dialog>
    <v-dialog v-if="confirmMsgDialog" v-model="confirmMsgDialog" max-width="fit-content">
      <v-card v-if="confirmMsgResult" width="fit-content" class="px-4 py-2">
        <v-card-title class="justify-center" v-text="confirmMsgResult"/>
        <v-card-actions>
          <v-btn
            v-if="showCsBtn"
            color="primary"
            class="mx-auto"
            :href="$enum.DENTCO_CS_LINE"
            target="_blank"
            @click="confirmMsgDialog = false"
          >聯繫客服
          </v-btn>
          <v-btn
            v-else
            color="primary"
            class="mx-auto"
            @click="closeConfirmMsg"
          >確定
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-else width="fit-content" class="pa-4">
        <v-card-title class="justify-center text-center" v-html="confirmMsgTitle"/>
        <v-card-actions class="mt-2">
          <v-btn
            :loading="confirmMsgLoading"
            color="grey-4"
            dark
            width="45%"
            @click="confirmMsgDialog = false"
          >取消傳送</v-btn>
          <v-spacer/>
          <v-btn
            :loading="confirmMsgLoading"
            color="primary"
            width="45%"
            @click="sendMsg"
          >確認傳送</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import {
  createTelemedicineAPI,
  getCallStatus,
  getDentcoHealthEducationAPI,
  getHealthEducationAPI,
  getReserveDetailAPI,
  getSmsQuotaAPI,
  getSmsStatus,
  remindTelemedicineAPI, sendCallHalfYearRemindAPI,
  sendCallReserveRemindAPI,
  sendLineHalfYearRemindAPI,
  sendReserveCareAPI,
  sendReserveHealthEducationAPI,
  sendReserveRemindAPI,
  sendReserveSurveyAPI,
  sendSmsHalfYearRemindAPI, sendSmsReserveCareAPI,
  sendSmsReserveRemindAPI, sendSmsReserveSurveyAPI,
  updateReserveStatusAPI,
  updateTelemedicineAPI
} from '@/api';
import { bindStatus } from '@/config/bindConfig';
import { assetURL, formatROCDate } from '@/utils/filters';
import UploadImg from "../UploadImg";
import PatientCard from "../patients/PatientCard";
import SendMessage from "../utils/SendMessage";
import CustomEducation from './CustomEducation';
import ReserveForm from "./ReserveForm";
import ReserveRecord from "./ReserveRecord";
import SendSmsMessage from "../utils/SendSmsMessage.vue";

export default {
  name: "ReserveDetailCard",
  props: ['reserveId', 'selectedOpen', 'reserveDialogWidth'],
  components: {
    SendSmsMessage,
    CustomEducation,
    SendMessage,
    UploadImg,
    PatientCard,
    ReserveForm,
    ReserveRecord
  },
  data() {
    return {
      event: null,
      edit: false,
      bookNextDialog: false,
      showRecord: false,
      editPatientDialog: false,
      confirmType: null,
      confirmMsgDialog: false,
      confirmMsgTitle: null,
      confirmMsgResult: null,
      confirmMsgLoading: false,
      telemedicineDialog: false,
      telemedicineLoading: false,
      reOpen: true,
      sendTelemedicineDialog: false,
      sendTelemedicineLoading: false,
      notBind: false,
      bindStatusDialog: false,
      editHealthEducationDialog: false,
      ownHealthEducations: [],
      sendHealthEdu: null,
      dentcoHealthEducations: [],
      showCsBtn: false,
      actionType: null,

      showPatientTab: null
    }
  },
  computed: {
    ...mapState({
      user: state => state.user,
      specialPatient: state => state.specialPatient,
      attendStatusMap: state => state.attendStatusMap,
    }),
    ...mapGetters([
      'axiosParamData',
      'clinicUser',
      'doctorUser',
      'smsStatus',
      'callStatus'
    ]),
    info() {
      const info = []
      if (parseInt(this.event.sex) === 0) {
        info.push({icon: 'mdi-gender-female', value: '女'})
      } else if (parseInt(this.event.sex) === 1) {
        info.push({icon: 'mdi-gender-male', value: '男'})
      }

      if (this.event.birthday) {
        info.push({icon: 'mdi-cake-variant', value: formatROCDate(parseInt(this.event.birthday) * 1000)})
      }

      if (this.event.phone) {
        info.push({icon: 'mdi-cellphone-iphone', value: this.event.phone})
      }

      return info
    },
    statusBtn() {
      const btns = []
      const arrive = this.$t('ReserveDetailCard.setArrive')
      const standOut = this.$t('ReserveDetailCard.setStandOut')
      const alter = this.$t('ReserveDetailCard.setAlter')
      if (parseInt(this.event.start_time) < this.$moment().unix()) {
        if (this.event.attend_status === '-1') {
          btns.push(
            {text: arrive, color: 'primary', status: 1},
          )
        } else if (this.event.attend_status === '1') {
          btns.push(
            {text: standOut, color: 'red-6', status: -1},
          )
        } else {
          btns.push(
            {text: standOut, color: 'red-6', status: -1}
          )
        }
      }
      if (this.event.attend_status === '0') {
        btns.push(
          {text: alter, color: 'orange-6', status: 4},
          {text: arrive, color: 'primary', status: 1}
        )
      } else if (['-1', '1'].includes(this.event.attend_status)) {
        btns.push(
          {text: alter, color: 'orange-6', status: 4}
        )
      } else if (['4', '5'].includes(this.event.attend_status)) {
        btns.push(
          {text: arrive, color: 'primary', status: 1}
        )
      }
      return btns
    },
    isSpecialPatient() {
      return Object.values(this.specialPatient).includes(this.event.patient_code)
    },
    earlierThanReserve() {
      return this.$moment().unix() < parseInt(this.event.start_time)
    },
    bindStatusDetail() {
      const bindStatus = []
      const configs = []

      if (this.clinicUser) {
        if (this.event.clinic_oa_status) {
          configs.push(
            {bindStatusKey: 'line', eventStatusKey: 'clinic_oa_status', name: this.event.clinic_name, hiddenNull: true},
            {bindStatusKey: 'messenger', eventStatusKey: 'clinic_fb_status', name: this.event.clinic_name, hiddenNull: true},
            {bindStatusKey: 'line', eventStatusKey: 'doctor_oa_status', name: `${this.event.doctor_name}醫師`, hiddenNull: true},
            {bindStatusKey: 'messenger', eventStatusKey: 'doctor_fb_status', name: `${this.event.doctor_name}醫師`, hiddenNull: true}
          )
        } else {
          configs.push(
            {bindStatusKey: 'line', eventStatusKey: 'dentco_oa_status', name: '小幫手公用', hiddenNull: false},
            {bindStatusKey: 'messenger', eventStatusKey: 'clinic_fb_status', name: this.event.clinic_name, hiddenNull: true},
            {bindStatusKey: 'line', eventStatusKey: 'doctor_oa_status', name: `${this.event.doctor_name}醫師`, hiddenNull: true},
            {bindStatusKey: 'messenger', eventStatusKey: 'doctor_fb_status', name: `${this.event.doctor_name}醫師`, hiddenNull: true}
          )
        }
      } else {
        if (this.event.doctor_oa_status) {
          configs.push(
            {bindStatusKey: 'line', eventStatusKey: 'clinic_oa_status', name: this.event.clinic_name, hiddenNull: true},
            {bindStatusKey: 'messenger', eventStatusKey: 'clinic_fb_status', name: this.event.clinic_name, hiddenNull: true},
            {bindStatusKey: 'line', eventStatusKey: 'doctor_oa_status', name: `${this.event.doctor_name}醫師`, hiddenNull: true},
            {bindStatusKey: 'messenger', eventStatusKey: 'doctor_fb_status', name: `${this.event.doctor_name}醫師`, hiddenNull: true}
          )
        } else {
          configs.push(
            {bindStatusKey: 'line', eventStatusKey: 'dentco_oa_status', name: '小幫手公用', hiddenNull: false},
            {bindStatusKey: 'line', eventStatusKey: 'clinic_oa_status', name: this.event.clinic_name, hiddenNull: true},
            {bindStatusKey: 'messenger', eventStatusKey: 'clinic_fb_status', name: this.event.clinic_name, hiddenNull: true},
            {bindStatusKey: 'messenger', eventStatusKey: 'doctor_fb_status', name: `${this.event.doctor_name}醫師`, hiddenNull: true}
          )
        }
      }

      configs.forEach(config => {
        if (!config.hiddenNull || this.event[config.eventStatusKey])
          bindStatus.push(
            this.showBindStatus({
              key: config.bindStatusKey,
              status: this.event[config.eventStatusKey],
              name: config.name
            })
          )
      })

      return bindStatus
    }
  },
  methods: {
    ...mapMutations({
      setSmsStatus: 'setSmsStatus',
      setCallStatus: 'setCallStatus',
    }),
    changeReserveStatus(status) {
      updateReserveStatusAPI(this.reserveId, {
        ...this.axiosParamData,
        attend_status: status,
      }).then(res => {
        this.$emit('refresh')
        this.$emit('update:selectedOpen', false)
      })
    },
    refresh() {
      this.getReserveDetail()
      this.$emit('refresh')
    },
    getReserveDetail() {
      this.$emit('update:reserveDialogWidth', '600')
      getReserveDetailAPI(this.reserveId, this.axiosParamData).then(res => {
        this.event = res.data
      })
    },
    clickRemind(action) {
      let canSend = false
      switch (action) {
        case 'line':
          this.actionType = 'line'
          canSend = true
          break
        case 'sms':
          this.actionType = 'sms'
          canSend = this.checkBeforeSend('sms')
          break
        case 'call':
          this.actionType = 'call'
          canSend = this.checkBeforeSend('call')
          break
      }
      if (canSend) {
        this.confirmType = 'remind'
        this.confirmMsgTitle = '系統將立即傳送「預約通知」給此位患者'
        this.confirmMsgDialog = true
      }
    },
    async checkBeforeSend(action) {
      switch (action) {
        case 'sms':
          if (!this.event.phone) {
            this.confirmMsgResult = '此病人無電話號碼，無法傳送簡訊'
            this.showCsBtn = false
            return
          }

          if (this.smsStatus) {
            const quota = await this.checkSmsQuota()
            if (quota > 0) {
              return true
            } else {
              this.confirmMsgResult = '儲值點數不足，請聯繫客服'
              this.showCsBtn = true
            }
          } else {
            this.confirmMsgResult = '您尚未開啟此功能，請聯繫客服'
            this.showCsBtn = true
          }
          break
        case 'call':
          if (!this.event.phone) {
            this.confirmMsgResult = '此病人無電話號碼，無法致電'
            this.showCsBtn = false
            return
          }

          if (this.callStatus) {
            const quota = await this.checkSmsQuota()
            if (quota > 0) {
              return true
            } else {
              this.confirmMsgResult = '儲值點數不足，請聯繫客服'
              this.showCsBtn = true
            }
          } else {
            this.confirmMsgResult = '您尚未開啟此功能，請聯繫客服'
            this.showCsBtn = true
          }
          break
      }
    },
    async checkSmsQuota() {
      const res = await getSmsQuotaAPI(this.axiosParamData)
      return Number(res.data.quota)
    },
    clickHalfYearRemind(action) {
      let canSend = false
      switch (action) {
        case 'line':
          this.actionType = 'line'
          canSend = true
          break
        case 'sms':
          this.actionType = 'sms'
          canSend = this.checkBeforeSend('sms')
          break
        case 'call':
          this.actionType = 'call'
          canSend = this.checkBeforeSend('call')
          break
      }
      if (canSend) {
        this.confirmType = 'halfYearRemind'
        this.confirmMsgTitle = '系統將立即傳送「定檢通知」給此位患者'
        this.confirmMsgDialog = true
      }
    },
    // clickCare() {
    //   this.confirmType = 'care'
    //   this.confirmMsgDialog = true
    //   this.confirmMsgTitle = '系統將立即傳送術後關懷訊息給此位患者'
    // },
    clickCare(action) {
      let canSend = false
      switch (action) {
        case 'line':
          this.actionType = 'line'
          canSend = true
          break
        case 'sms':
          this.actionType = 'sms'
          canSend = this.checkBeforeSend('sms')
          break
      }
      if (canSend) {
        this.confirmType = 'care'
        this.confirmMsgDialog = true
        this.confirmMsgTitle = '系統將立即傳送術後關懷訊息給此位患者'
      }
    },
    // clickSurvey() {
    //   this.confirmType = 'survey'
    //   this.confirmMsgDialog = true
    //   this.confirmMsgTitle = '系統將立即傳送術評價邀請給此位患者'
    // },
    clickSurvey(action) {
      let canSend = false
      switch (action) {
        case 'line':
          this.actionType = 'line'
          canSend = true
          break
        case 'sms':
          this.actionType = 'sms'
          canSend = this.checkBeforeSend('sms')
          break
      }
      if (canSend) {
        this.confirmType = 'survey'
        this.confirmMsgTitle = '系統將立即傳送術評價邀請給此位患者'
        this.confirmMsgDialog = true
      }
    },
    clickHealthEdu(eduItem) {
      this.sendHealthEdu = eduItem
      this.confirmType = 'healthEdu'
      this.confirmMsgDialog = true
      this.confirmMsgTitle = `您是否要傳送衛教訊息：<br>${eduItem.keyword}`
    },
    sendMsg() {
      this.confirmMsgLoading = true
      const data = {
        ...this.axiosParamData,
        reserve_no: this.event.reserve_no
      }
      let api
      switch (this.confirmType) {
        case 'remind':
          switch (this.actionType) {
            case 'line':
              api = sendReserveRemindAPI
              break
            case 'sms':
              api = sendSmsReserveRemindAPI
              break
            case 'call':
              api = sendCallReserveRemindAPI
              break
          }
          break
        case 'halfYearRemind':
          switch (this.actionType) {
            case 'line':
              api = sendLineHalfYearRemindAPI
              break
            case 'sms':
              api = sendSmsHalfYearRemindAPI
              break
            case 'call':
              api = sendCallHalfYearRemindAPI
              break
          }
          break
        case 'care':
          switch (this.actionType) {
            case 'line':
              api = sendReserveCareAPI
              break
            case 'sms':
              api = sendSmsReserveCareAPI
              break
          }
          break
        case 'survey':
          switch (this.actionType) {
            case 'line':
              api = sendReserveSurveyAPI
              break
            case 'sms':
              api = sendSmsReserveSurveyAPI
              break
          }
          break
        case 'healthEdu':
          api = sendReserveHealthEducationAPI
          data.health_id = this.sendHealthEdu.id
          break
      }
      api(data).then(() => {
        this.confirmMsgResult = '成功發送'
      }).catch(e => {
        if (e.response && e.response.data && e.response.data.messages)
          this.confirmMsgResult = e.response.data.messages
        else
          alert(this.$t('GENERAL.oops'))
      }).finally(() => {
        this.confirmMsgLoading = false
      })
    },
    closeConfirmMsg() {
      this.confirmMsgDialog = false
    },
    handleTelemedicine() {
      if (parseInt(this.event.doctor_oa_status) || parseInt(this.event.clinic_oa_status) || parseInt(this.event.dentco_oa_status)) {
        if (!this.event.google_meet) {
          this.telemedicineLoading = true
          this.telemedicineDialog = true
          this.generationTelemedicine()
        } else {
          this.reOpen = true
          this.telemedicineDialog = true
        }
      } else {
        this.notBind = true
        this.telemedicineDialog = true
      }
    },
    generationTelemedicine() {
      this.telemedicineLoading = true
      this.$gapi.grantOfflineAccess().then(res => {
        const api = this.event.google_meet ? updateTelemedicineAPI : createTelemedicineAPI
        const data = {
          oauth_authenticate_code: res,
          reserve_no: this.event.reserve_no,
          ...this.axiosParamData,
        }
        api(data).then(() => {
          this.getReserveDetail()
          this.reOpen = false
        }).finally(() => {
          this.telemedicineLoading = false
        })
      }).catch(() => {
        this.telemedicineDialog = false
        this.telemedicineLoading = false
      })
    },
    sendTelemedicine() {
      this.sendTelemedicineLoading = true
      remindTelemedicineAPI({reserve_no: this.event.reserve_no, ...this.axiosParamData}).then(() => {
        this.sendTelemedicineDialog = false
      }).finally(() => {
        this.sendTelemedicineLoading = false
      })
    },
    showBindStatus(config) {
      const assetConfig = bindStatus(config)
      return {
        icon: assetURL(assetConfig.icon),
        text: assetConfig.text
      }
    },
    getHealthEducationList() {
      getHealthEducationAPI(this.axiosParamData).then(res => {
        this.ownHealthEducations = res.data
      })
    },
    getDentcoHealthEducationList() {
      getDentcoHealthEducationAPI(this.axiosParamData).then(res => {
        this.dentcoHealthEducations = res.data
      })
    },
  },
  watch: {
    showRecord(v) {
      if (v && this.$vuetify.breakpoint.mdAndUp) this.$emit('update:reserveDialogWidth', '860')
      else this.$emit('update:reserveDialogWidth', '600')
    },
    editHealthEducationDialog(v) {
      if (!v) this.getHealthEducationList()
    },
    confirmMsgDialog(v) {
      if (!v) {
        this.confirmMsgResult = null
        this.showCsBtn = false
      }
    }
  },
  mounted() {
    this.getReserveDetail()
    this.getHealthEducationList()
    this.getDentcoHealthEducationList()

    if (this.smsStatus === null) {
      getSmsStatus(this.axiosParamData).then(res => {
        this.setSmsStatus(res.data.sms_status)
      })
    }
    if (this.callStatus === null) {
      getCallStatus(this.axiosParamData).then(res => {
        this.setCallStatus(res.data.cht_voice_status)
      })
    }
  }
}
</script>

<style scoped>
.reserve-card .v-list-item__subtitle {
  -webkit-line-clamp: unset;
}

.element-hide {
  display: none !important;
}
</style>
