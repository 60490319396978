<template>
  <v-container fluid class="pa-0" style="height: 100%; max-height: 100%;">
    <v-overlay :value="loading" absolute>
      <v-progress-circular
        indeterminate
        size="32"
      ></v-progress-circular>
    </v-overlay>
    <v-row no-gutters style="height: 100%;">
      <v-col
        cols="12"
        md="2"
        xl="1"
        class="overflow-y-auto pa-2"
        :style="$vuetify.breakpoint.mdAndUp ? 'height: calc(100vh - 112px);' : ''"
      >
        <v-tabs
          v-model="selectedItem"
          :vertical="$vuetify.breakpoint.mdAndUp"
          :show-arrows="$vuetify.breakpoint.smAndDown"
        >
          <v-tab
            v-for="tab in tabItems"
            :key="tab.code"
            :href="`#${tab.code}`"
            class="px-md-12 justify-start"
          >
            {{ tab.name }}
          </v-tab>
        </v-tabs>
      </v-col>

      <v-col
        cols="12"
        md="10"
        xl="11"
        class="pt-1"
      >
        <v-card
          flat
          class="overflow-y-auto px-md-6"
          :height="$vuetify.breakpoint.mdAndUp ? 'calc(100vh - 116px)' : 'calc(100vh - 180px)'"
          :max-height="$vuetify.breakpoint.mdAndUp ? 'calc(100vh - 116px)' : 'calc(100vh - 180px)'"
          color="grey-8"
        >
          <v-card
            flat
            max-width="1400"
            class="px-md-6"
            color="grey-8"
          >
            <div
              :class="{'mt-3': $vuetify.breakpoint.mdAndUp}"
            >
              <div class="d-flex flex-wrap">
                <guide-tour config-key="treatmentSetting"/>
              </div>
            </div>
            <v-card-text>
              <v-container fluid>
                <v-row>
                  <v-col cols="12">
                    <v-card color="white">
                      <v-card-text class="py-8">
                        <fuck-stupid-treatment-setting
                          v-if="treatmentList.length && selectedItem"
                          :select-code="selectedItem"
                          :treatment-list="treatmentList"
                        />
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import GuideTour from "@/components/utils/GuideTour.vue";
import SearchTreatmentSetting from "@/components/OnlineAppointmentsSetting/SearchTreatmentSetting.vue";
import OnlineAppointmentTreatmentSetting
  from "@/components/OnlineAppointmentsSetting/OnlineAppointmentTreatmentSetting.vue";
import { getClinicListAPI, getDoctorListAPI, getTreatmentListAPI } from "@/api";
import { mapGetters } from "vuex";
import FuckStupidTreatmentSetting from "@/components/OnlineAppointmentsSetting/FuckStupidTreatmentSetting.vue";

export default {
  name: "TreatmentSetting",

  components: {FuckStupidTreatmentSetting, OnlineAppointmentTreatmentSetting, SearchTreatmentSetting, GuideTour},

  data() {
    return {
      selectedItem: null,
      tabItems: [],
      treatmentList: [],
      loading: false
    }
  },

  computed: {
    ...mapGetters([
      'clinicUser',
      'axiosParamData'
    ]),
  },

  async mounted() {
    this.loading = true
    if (this.clinicUser) {
      getDoctorListAPI(this.axiosParamData).then(res => {
        this.tabItems = res.data.map(e => {
          return {
            name: e.doctor_name,
            code: e.doctor_code
          }
        })
        this.loading = false
      }).catch(e => {
        console.error(e)
        alert('取得醫師列表失敗')
      })
    } else {
      getClinicListAPI(this.axiosParamData).then(res => {
        this.tabItems = res.data.map(e => {
          return {
            name: e.clinic_name,
            code: e.clinic_code
          }
        })
        this.loading = false
      }).catch(e => {
        console.error(e)
        alert('取得診所列表失敗')
      })
    }
    await this.getTreatmentList()
  },

  methods: {
    async getTreatmentList() {
      try {
        const res = await getTreatmentListAPI();
        this.treatmentList = res.data;
      } catch (error) {
        console.error("Failed to fetch treatment list:", error);
        // 顯示錯誤訊息給使用者
        alert("無法獲取治療清單，請稍後再試。");
      }
    }
  }
}
</script>

<style scoped>

</style>