<template>
  <patient-pie-analysis
    title="Messenger會員語系圓餅圖"
    :data="sourceData"
  />
</template>

<script>
import { getAnalysisMessengerLangAPI } from "../../api";
import { mapGetters } from "vuex";
import PatientPieAnalysis from "./PatientPieAnalysis.vue";
import { generateRandomColor } from "../../utils/tool";

export default {
  name: "MessengerLangAnalysis",

  components: {PatientPieAnalysis},

  data() {
    return {
      sourceData: null,
      colorSetting: {
        zh_TW: '#838181',
        en_EN: '#23bfda',
        ja_JP: '#efcf13',
        th_TH: '#f45d64'
      }
    }
  },

  computed: {
    ...mapGetters([
      'axiosParamData',
    ]),
  },

  methods: {
    getAnalysisData() {
      getAnalysisMessengerLangAPI(this.axiosParamData).then(res => {
        const data = res.data
        data.datasets[0].backgroundColor = this.colorTheme(data.labels)
        this.sourceData = res.data
      })
    },
    colorTheme(labels) {
      return labels?.map(label => this.colorSetting[label] || generateRandomColor())
    }
  },

  mounted() {
    this.getAnalysisData()
  }
}
</script>

<style scoped>

</style>
