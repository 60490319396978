<template>
  <div>
    <div class="d-flex align-center mt-2">
      <v-sheet color="grey-8" height="60" rounded width="100%" class="d-flex align-center px-2">
        <div class="d-flex" style="width: 400px; max-width: 70%;">
          <v-text-field
            v-model="search"
            v-on:keyup.enter="searchMembers()"
            single-line
            hide-details
            outlined
            dense
            clearable
            placeholder="輸入姓名/電話號碼"
          />
        </div>
        <v-btn icon color="primary" class="ml-2" @click="searchMembers()">
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
        <v-spacer/>
        <v-btn :icon="$vuetify.breakpoint.xsOnly" outlined @click="exportMembers">
          <v-icon>mdi-file-export</v-icon>
          <template v-if="$vuetify.breakpoint.smAndUp">匯出</template>
        </v-btn>
      </v-sheet>
    </div>

    <v-data-table
      :headers="headers"
      :items="members"
      :options.sync="options"
      :server-items-length="totalMembers"
      :footer-props="{itemsPerPageOptions: [10, 20, 50]}"
      :loading="loading"
      fixed-header
      height="calc(100vh - 380px)"
    >
      <template v-slot:item.name="{ item }">
        <div class="d-flex align-center" :class="{'ma-auto': $vuetify.breakpoint.smAndUp}" style="width: 122px;">
          <v-spacer v-if="$vuetify.breakpoint.xsOnly"/>
          <v-avatar size="36">
            <img
              :src="item.photo"
              :alt="item.name"
              @error="item.photo = 'https://dentco.s3.us-east-2.amazonaws.com/asset/crm/avatar-unknow.png'"
            >
          </v-avatar>
          <div class="ml-2">
            {{ item.name }}
          </div>
        </div>
      </template>
      <template v-slot:item.sex="{ item }">
        {{ item.sex | getGender }}
      </template>
      <template v-slot:item.birthday="{ item }">
        <template v-if="item.birthday">{{ parseInt(item.birthday) * 1000 | formatROCDate }}</template>
      </template>
      <template v-slot:item.bind_status="{ item }">
        <v-tooltip right max-width="500">
          <template v-slot:activator="{ on, attrs }">
            <v-img
              :src="showBindStatus({key: memberType, status: item.bind_status}).icon"
              max-width="24"
              width="24"
              class="ma-auto"
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <span v-html="showBindStatus({key: memberType, status: item.bind_status, name: getName}).text"/>
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import {
  exportLineMembersAPI,
  exportMessengerMembersAPI,
  getLineMembersAPI,
  getMessengerMembersAPI
} from '../../api';
import { mapGetters, mapState } from 'vuex';
import { bindStatus } from '../../config/bindConfig';
import { assetURL } from '../../utils/filters';

export default {
  name: "MemberList",

  props: {
    memberType: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      loading: false,
      search: null,
      members: [],
      options: {},
      totalMembers: 0,
    }
  },

  computed: {
    ...mapState({
      user: state => state.user
    }),
    ...mapGetters([
      'axiosParamData',
      'clinicUser',
      'doctorUser'
    ]),
    headers() {
      return [
        {text: this.$t('Patients.patientName'), align: 'center', value: 'name', sortable: false, width: 120},
        {text: this.$t('Patients.patientPhone'), align: 'center', value: 'phone', sortable: true, width: 120},
        {text: 'Email', align: 'center', value: 'email', sortable: true, width: 120},
        {text: 'UID', align: 'center', value: 'uid', sortable: false, width: 120},
        {text: '語系', align: 'center', value: 'lang', sortable: true, width: 120},
        {text: this.$t('Patients.bindStatus'), align: 'center', value: 'bind_status', sortable: true, width: 80},
        {text: '加入來源', align: 'center', value: 'inviter', sortable: false, width: 80},
        {text: '加入時間', align: 'center', value: 'create_time', sortable: true, width: 80},
      ]
    },
    getName() {
      if (this.clinicUser) {
        return this.user.clinic_name
      } else if (this.doctorUser) {
        return this.user.doctor_name + this.$t('Navigation.doctor')
      }
    },
  },

  watch: {
    options: {
      handler() {
        this.getMembers()
      },
      deep: true
    }
  },

  methods: {
    searchMembers(){
      if (this.page === 1)
        return this.getMembers()
      this.page = 1
    },
    getMembers() {
      this.loading = true
      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      const params = {
        ...this.axiosParamData,
        page,
        limit: itemsPerPage,
        name: this.search || null
      }

      if (sortBy.length) {
        params.sort = sortBy[0]
        params.sort_type = sortDesc[0] ? 'desc' : 'asc'
      }

      let api

      if (this.memberType === 'line')
        api = getLineMembersAPI
      else if (this.memberType === 'messenger')
        api = getMessengerMembersAPI

      api(params).then(res => {
        this.totalMembers = parseInt(res.data.paginate.total)
        this.members = res.data.data
      }).catch(e => {
        alert(this.$t('GENERAL.oops'))
        console.error(e)
      }).finally(() => {
        this.loading = false
      })
    },
    showBindStatus(config) {
      const assetConfig = bindStatus(config)
      return {
        icon: assetURL(assetConfig.icon),
        text: assetConfig.text
      }
    },
    exportMembers() {
      let api

      if (this.memberType === 'line')
        api = exportLineMembersAPI
      else if (this.memberType === 'messenger')
        api = exportMessengerMembersAPI

      api(this.axiosParamData).then(res => {
        const blob = new Blob([res.data], {type: 'text/csv;charset=utf-8;'});
        const link = document.createElement("a");
        const url = URL.createObjectURL(blob);
        const fileName = res.headers['content-disposition'].split("filename=")[1] || '會員列表'
        link.setAttribute("href", url);
        link.setAttribute("download", fileName);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
    }
  }
}
</script>

<style scoped>

</style>
