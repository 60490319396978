<template>
  <div>
    <v-card outlined class="pa-2">
      <v-container fluid>
        <v-row>
          <v-col cols="12" sm="6">
            <v-card>
              <v-card-subtitle class="d-flex">{{ $t('SendStep.patientList') }}
                <v-btn small color="primary" class="ml-auto" @click="toggleSelectPatient">
                  {{ selectAllPatients ? $t('SendStep.cancelSelect') : $t('SendStep.selectAll') }}
                </v-btn>
              </v-card-subtitle>
              <v-card-text>
                <v-text-field
                  v-model.trim="searchPatientName"
                  :label="$t('SendStep.searchName')"
                  outlined
                  dense
                  hide-details
                  style="width: calc(100% - 15px)"
                />
                <v-sheet height="200" class="overflow-y-auto mt-2">
                  <v-checkbox
                    v-for="patient in filterPatients"
                    :key="patient"
                    :input-value="value.includes(patient)"
                    color="blue"
                    hide-details
                    dense
                    style="padding-top: 8px"
                    :disabled="value.length >= maxSelect && !value.includes(patient)"
                    @click="selectPatient(patient)"
                  >
                    <template v-slot:label>
                      {{ patient.split('-')[2] }}
                    </template>
                  </v-checkbox>
                </v-sheet>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6">
            <v-card>
              <v-card-subtitle class="d-flex">{{ $t('SendStep.showSelect') }}
                <v-btn
                  small
                  color="primary"
                  :disabled="!value.length"
                  class="ml-auto"
                  @click="$emit('update:value', [])"
                >
                  {{ $t('SendStep.clearAll') }}
                </v-btn>
              </v-card-subtitle>
              <v-card-text style="height: 264px;" class="overflow-y-auto">
                <v-chip
                  v-for="(patient, i) in value"
                  :key="patient"
                  class="ma-2"
                  close
                  color="primary"
                  outlined
                  @click:close="remove(i)"
                >
                  {{ patient.split('-')[1] }}
                </v-chip>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <div class="mt-12">
      <v-btn color="primary" @click="submit">{{ $t('SendStep.confirmSelect') }}</v-btn>
    </div>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  name: 'PatientSelection',

  props: {
    value: {
      type: Array,
      required: true
    },
    patients: {
      type: Array,
      required: true
    },
    maxSelect: {
      type: Number,
      required: true
    }
  },

  data() {
    return {
      searchPatientName: null,
    }
  },

  computed: {
    selectAllPatients() {  // 是否已經將篩選出來的病患全部選擇了，只要有一個沒選擇，就不是
      return this.patients.every(v => this.value.includes(v))
    },
    filterPatients() {
      if (!this.searchPatientName) return this.patients
      return this.patients.filter(a => a.includes(this.searchPatientName));
    }
  },

  methods: {
    toggleSelectPatient() {
      this.$nextTick(() => {
        if (this.selectAllPatients) {
          this.$emit('update:value', _.difference(this.value, this.patients))
        } else {
          const selected = _.union(this.value, this.patients)
          if (selected.length > this.maxSelect) {
            return alert(`最多可選擇 ${this.maxSelect} 位患者`)
          }
          this.$emit('update:value', selected)
        }
      })
    },
    selectPatient(patient) {
      const index = this.value.indexOf(patient)
      if (index === -1) {
        if (this.value.length >= this.maxSelect) {
          alert(`最多可選擇 ${this.maxSelect} 位患者`)
        } else {
          this.$emit('update:value', [...this.value, patient])
        }
      } else {
        this.$emit('update:value', this.value.filter(v => v !== patient))
      }
    },
    remove(index) {
      this.$emit('update:value', this.value.filter((_, idx) => idx !== index))
    },
    submit() {
      this.$emit('nextStep')
    },
    clearData() {
      this.searchPatientName = this.$options.data().searchPatientName
    }
  }
};
</script>

<style scoped>
/* Your styles here */
</style>
