<template>
  <v-card flat max-width="1280" class="pl-md-6">
    <guide-tour config-key="message.setting"/>

    <div class="px-4 mt-4">
      <div v-if="clinicUser || hasOwnChannel">
        <v-card outlined flat class="mb-6 mt-4">
          <v-tabs grow>
            <v-tab :to="{name: 'lineSetting'}">
              LINE 訊息設定
            </v-tab>
            <v-tab :to="{name: 'smsSetting'}">
              簡訊發送設定
            </v-tab>
            <v-tab :to="{name: 'messengerSetting'}">
              Messenger 訊息設定
            </v-tab>
            <v-tab :to="{name: 'voiceSetting'}">
              語音訊息設定
            </v-tab>
          </v-tabs>

          <div style="width: 100%;">
            <router-view></router-view>
          </div>
        </v-card>
      </div>

      <v-sheet v-else outlined rounded color="red-6">
        <v-card class="d-flex" :class="{'flex-wrap': $vuetify.breakpoint.xsOnly, 'pl-2': $vuetify.breakpoint.smAndUp}"
                flat>
          <div
            class="align-self-center red-6--text pa-2"
            :class="{'text-center': $vuetify.breakpoint.xsOnly}"
            :style="{'width: 100%': $vuetify.breakpoint.xsOnly}"
          >
            <v-icon color="red-6" :class="{'d-block': $vuetify.breakpoint.xsOnly}">mdi-bullhorn</v-icon>
            此功能僅開放自有LINE使用，請聯繫牙醫小幫手客服詢問切換
          </div>
          <div :class="{'ml-auto': $vuetify.breakpoint.smAndUp, 'mt-2': $vuetify.breakpoint.xsOnly}"
               :style="$vuetify.breakpoint.xsOnly ? 'width: 100%' : 'unset'">
            <v-btn
              block
              tile
              :height="$vuetify.breakpoint.xsOnly ? 'unset' : '100%'"
              min-height="36"
              class="elevation-0 white--text"
              color="red-6"
              :href="$enum.DENTCO_CS_LINE"
              target="_blank"
            >點我聯繫醫師小幫手升級
            </v-btn>
          </div>
        </v-card>
      </v-sheet>
    </div>
  </v-card>
</template>

<script>
import GuideTour from "../../../components/utils/GuideTour.vue";
import { mapGetters, mapMutations } from "vuex";
import { getMessageSettingAPI } from "../../../api";

export default {
  name: "MessageSetting",

  components: {GuideTour},

  computed: {
    ...mapGetters([
      'axiosParamData',
      'clinicUser',
      'hasOwnChannel'
    ])
  },

  mounted() {
    this.getChannelInfo()
  },

  methods: {
    ...mapMutations({
      setOwnChannel: 'setOwnChannel'
    }),
    getChannelInfo() {
      getMessageSettingAPI(this.axiosParamData).then(res => {
        this.setOwnChannel(res.data.own_channel)
      })
    }
  }
}
</script>

<style scoped>

</style>
