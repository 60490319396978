<template>
  <v-card>
    <v-card-title class="text-h6 justify-center">{{ $t('RegisterDoctorForm.doctorRegister') }}</v-card-title>
    <v-card-text>
      <v-form ref="form" v-model="valid">
        <v-container fluid class="pa-1">
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                :label="$t('RegisterDoctorForm.doctorName')"
                v-model.trim="registerData.name"
                hide-details="auto"
                :rules="[v => !!v || $t('RegisterDoctorForm.inputDoctorName')]"
                required
                dense
                outlined
              />
            </v-col>
            <v-col cols="12">
              <v-select
                :label="$t('RegisterDoctorForm.gender')"
                v-model.trim="registerData.sex"
                hide-details="auto"
                :rules="[v => v !== null || $t('RegisterDoctorForm.inputGender')]"
                :items="gender"
                required
                dense
                outlined
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="Email"
                v-model.trim="registerData.email"
                hide-details="auto"
                ref="emailField"
                :rules="emailRule"
                required
                dense
                outlined
              />
            </v-col>
            <v-col cols="7" sm="8">
              <v-text-field
                :label="$t('RegisterDoctorForm.phone')"
                v-model.trim="registerData.phone"
                hide-details="auto"
                ref="phoneField"
                :rules="phoneRule"
                required
                dense
                outlined
              />
            </v-col>
            <v-col cols="5" sm="4">
              <v-btn block color="primary" class="px-1" :disabled="disabledSendOTP" @click="sendOtp">
                {{ timerCount > 0 ? timerCount : $t('RegisterDoctorForm.sendOtp') }}
              </v-btn>
            </v-col>
            <v-col v-if="showOtp" cols="12">
              <v-text-field
                :label="$t('RegisterDoctorForm.otp')"
                v-model.trim="registerData.otp_code"
                hide-details="auto"
                :rules="[v => !!v || $t('RegisterDoctorForm.inputOtp')]"
                :prefix="prefix"
                required
                dense
                outlined
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                :label="$t('RegisterDoctorForm.password')"
                v-model.trim="registerData.password"
                required
                :rules="[v => !!v || $t('RegisterDoctorForm.inputPassword')]"
                hide-details="auto"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                dense
                outlined
                @click:append="showPassword = !showPassword"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                :label="$t('RegisterDoctorForm.confirmPassword')"
                v-model.trim="registerData.password2"
                required
                :rules="[v => !!v || $t('RegisterDoctorForm.inputConfirmPassword'), v => v === registerData.password || $t('RegisterDoctorForm.inputConfirmPassword2')]"
                hide-details="auto"
                :append-icon="showPassword2 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword2 ? 'text' : 'password'"
                dense
                outlined
                validate-on-blur
                @click:append="showPassword2 = !showPassword2"
              />
            </v-col>
            <v-col cols="12">
              <v-select
                v-model="registerData.kind"
                :label="$t('RegisterDoctorForm.medicalCategory')"
                :items="medicalCategories"
                item-value="id"
                item-text="name"
                outlined
                dense
                hide-details="auto"
                :rules="[v => !!v || $t('RegisterDoctorForm.needSelectMedical')]"
              />
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                v-model="clinic"
                :label="$t('RegisterDoctorForm.clinicDoctor')"
                :items="clinicItems"
                :loading="loading"
                return-object
                item-text="show"
                hide-details="auto"
                hide-no-data
                :placeholder="$t('RegisterDoctorForm.clinicDoctorPH')"
                :search-input.sync="search"
                :hint="$t('RegisterDoctorForm.clinicDoctorHint')"
                clearable
                dense
                outlined
              />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn block :disabled="!valid || !registerData.otp_code" color="primary" @click="submit">
        {{ $t('RegisterDoctorForm.confirm') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { getMedicalCategoryAPI, registerDoctorAPI, searchClinicAPI, sendOtpAPI } from "../../api";

export default {
  name: "RegisterDoctorForm",
  data() {
    return {
      valid: true,
      showPassword: false,
      showPassword2: false,
      showOtp: false,
      timerCount: 0,
      disabledSendOTP: true,
      prefix: null,
      clinicItems: [],
      search: null,
      loading: false,
      gender: [
        {text: this.$t('RegisterDoctorForm.male'), value: 1},
        {text: this.$t('RegisterDoctorForm.female'), value: 0}
      ],
      registerData: {
        name: '',
        phone: '',
        password: '',
        password2: '',
        otp_code: '',
        sex: null,
        kind: null
      },
      clinic: null,
      phoneRule: [
        v => !!v || this.$t('RegisterDoctorForm.inputPhone'),
        v => (v.startsWith('09') && v.trim().length === 10) || this.$t('RegisterDoctorForm.inputPhoneFormat')
      ],
      emailRule: [
        v => !!v || this.$t('RegisterDoctorForm.inputEmail'),
        v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || this.$t('RegisterDoctorForm.inputEmailFormat')
      ],
      medicalCategories: []
    }
  },
  watch: {
    timerCount: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.timerCount--;
          }, 1000);
        } else {
          this.disabledSendOTP = false
        }
      },
      immediate: true
    },
    search(v) {
      if (v) this.searchClinic(v)
    },
    'registerData.phone': {
      handler() {
        this.disabledSendOTP = !(this.$refs.phoneField.validate() && this.timerCount <= 0);
      }
    }
  },
  methods: {
    submit() {
      this.$refs.form.validate()
      if (!this.valid) return
      if (this.registerData.password2 !== this.registerData.password) {
        return
      }
      if (this.clinic)
        this.registerData.clinic_code = this.clinic.clinic_code
      registerDoctorAPI(this.registerData).then(() => {
        alert(this.$t('RegisterDoctorForm.success'))
        this.$emit('close')
      }).catch(e => {
        if (e.response && e.response.data && e.response.data.messages) {
          alert(e.response.data.messages)
        } else {
          alert(this.$t('GENERAL.oops'))
          console.error(e)
        }
      })
    },
    sendOtp() {
      const data = {
        phone: this.registerData.phone,
        suffix_message: this.$t('RegisterDoctorForm.suffixMessage')
      }
      this.disabledSendOTP = true
      this.timerCount = 30
      sendOtpAPI(data).then(res => {
        if (res.data.status === -1) {
          alert(res.data.message)
          this.timerCount = 0
          this.disabledSendOTP = false
          return
        }
        this.showOtp = true
        this.prefix = `${res.data.prefix} -`
      }).catch((e) => {
        if (e.response && e.response.data && e.response.data.status === -1) {
          alert(e.response.data.message || this.$t('GENERAL.oops'))
        } else {
          alert(this.$t('GENERAL.oops'))
        }
        this.disabledSendOTP = false
        this.timerCount = 0
      })
    },
    searchClinic(v) {
      this.loading = true
      searchClinicAPI({clinic_name: v}).then(res => {
        const clinicItems = res.data
        this.clinicItems = clinicItems.map(v => {
          return {...v, show: `${v.clinic_name}(${v.address})`}
        })
      }).finally(() => {
        this.loading = false
      })
    },
    getMedicalList() {
      getMedicalCategoryAPI().then(res => {
        this.medicalCategories = res.data
      })
    }
  },
  mounted() {
    this.getMedicalList()
  }
}
</script>

<style scoped>

</style>