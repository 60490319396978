<template>
  <v-container>
    <guide-tour config-key="analytics.patient"/>
    <v-row class="mt-4">
      <v-col cols="12" md="6">
        <line-bind-analysis/>
      </v-col>
      <v-col cols="12" md="6">
        <messenger-bind-analysis/>
      </v-col>
      <v-col cols="12" md="6">
        <line-lang-analysis/>
      </v-col>
      <v-col cols="12" md="6">
        <messenger-lang-analysis/>
      </v-col>
      <v-col cols="12">
        <registration-analysis/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LineBindAnalysis from "./LineBindAnalysis.vue";
import MessengerBindAnalysis from "./MessengerBindAnalysis.vue";
import LineLangAnalysis from "./LineLangAnalysis.vue";
import MessengerLangAnalysis from "./MessengerLangAnalysis.vue";
import RegistrationAnalysis from "./RegistrationAnalysis.vue";
import GuideTour from "../utils/GuideTour.vue";

export default {
  name: "PatientAnalysis",

  components: {
    GuideTour,
    RegistrationAnalysis,
    MessengerLangAnalysis,
    LineLangAnalysis,
    MessengerBindAnalysis,
    LineBindAnalysis
  }
}
</script>

<style scoped>

</style>
