<template>
  <v-main>
    <navigation extension>
      <template v-slot:extension>
        <v-sheet width="calc(100% + 32px)" color="grey-8" class="mx-n4">
          <v-tabs v-model="tab" light background-color="grey-8" class="ma-auto" style="max-width: 924px;" @change="onTabChange">
            <template v-for="item in settingItems">
              <v-tab v-if="item.show" :key="item.id" :href="`#${item.id}`">
                {{ item.name }}
                <v-icon v-if="item.plus" small>mdi-crown</v-icon>
              </v-tab>
            </template>

            <div v-if="isEdit" class="action d-flex align-center ml-auto">
              <v-btn
                elevation="0"
                outlined
                class="rounded-lg"
                style="color: var(--v-grey-7-base); border-color: var(--v-grey-4-base); background-color: var(--v-grey-3-base);"
                @click="cancel"
              >
                取消
              </v-btn>
              <v-btn elevation="0" color="red-6" dark class="ml-2 rounded-lg" @click="save">確認儲存</v-btn>
            </div>
          </v-tabs>
          <v-progress-linear
            :active="loading"
            :indeterminate="loading"
            absolute
            bottom
            color="primary"
          ></v-progress-linear>
        </v-sheet>
      </template>
    </navigation>
    <v-tabs-items v-model="currentTab" touchless>
      <template v-for="item in settingItems">
        <v-tab-item v-if="item.show" :key="item.id" :value="item.id" transition="fade-transition">
          <component
            v-if="currentTab === item.id"
            :is="item.component"
            :ref="item.id"
            :title="item.title"
            :subtitle="item.subtitle"
            :is-edit.sync="isEdit"
            :plan="clinicPlan"
            :user-info="info"
          />
        </v-tab-item>
      </template>
    </v-tabs-items>
    <v-dialog v-model="alertDialog" width="368" max-width="80%">
      <v-card class="px-2 py-5 rounded-lg">
        <div class="text-center">
          <v-icon size="100" :color="alertData.color" v-text="alertData.icon"/>
        </div>
        <v-card-title class="text-center justify-center" v-text="alertData.content"/>
      </v-card>
    </v-dialog>
  </v-main>
</template>

<script>
import Navigation from '../../components/Navigation';
import BasicInfo from '../../components/settings/clinic/BasicInfo';
import ClinicIntro from '../../components/settings/clinic/ClinicIntro';
import BusinessHours from '../../components/settings/clinic/BusinessHours';
import ClinicSocials from '../../components/settings/clinic/ClinicSocials';
import OurServices from '../../components/settings/clinic/OurServices';
import OurEnvironment from '../../components/settings/clinic/OurEnvironment';
import OurEquipment from '../../components/settings/clinic/OurEquipment';
import { getClinicBaseInfoAPI, getInfoAPI } from '../../api';
import { mapGetters } from 'vuex';

export default {
  name: "PlusClinicSettings",
  components: {
    OurEquipment,
    OurEnvironment,
    OurServices,
    ClinicSocials,
    BusinessHours,
    ClinicIntro,
    BasicInfo,
    Navigation
  },
  data() {
    return {
      tab: 'basicInfo',
      currentTab: 'basicInfo',
      isEdit: false,
      loading: true,
      alertDialog: false,
      alertData: {
        color: 'red-6',
        icon: 'mdi-alert-circle',
        content: '請確認您是否已儲存編輯',
      },
      clinicPlan: '1',
      info: null
    }
  },
  computed: {
    ...mapGetters(['axiosParamData', 'isDental']),
    settingItems() {
      const items = [
        {
          id: 'basicInfo',
          name: '基本資訊',
          plus: false,
          component: 'BasicInfo',
          title: '診所基本資訊',
          subtitle: '此為診所的地址與聯絡電話資訊，公開顯示於牙醫小幫手網站上，可點擊欄位進行編輯與調整。',
          show: true
        },
        {
          id: 'clinicIntro',
          name: '診所介紹',
          plus: false,
          component: 'ClinicIntro',
          title: '診所介紹',
          subtitle: '此處可編輯診所簡介與環境照片，將公開顯示於醫師小幫手網站上。建議填寫完整的介紹或經營理念，以增加曝光度且讓患者更有意願預約！',
          show: true
        },
        {
          id: 'businessHours',
          name: '看診時間',
          plus: false,
          component: 'BusinessHours',
          title: '看診時間',
          subtitle: '請於時間表上點擊診所開放看診的時段，可以讓患者瀏覽到營業時間。並於下方設定各診次的起迄時間，可預約的時段將會依此顯示。',
          show: true
        },
        {
          id: 'clinicSocials',
          name: '診所社群',
          plus: true,
          component: 'ClinicSocials',
          title: '診所社群',
          subtitle: '診所可放置社群網站連結於下方對應欄位，將公開顯示於醫師小幫手網站上，讓患者對診所更加認識並加增互動。若不編輯則不顯示該項目資訊。',
          show: true
        },
        {
          id: 'ourServices',
          name: '服務項目',
          plus: true,
          component: 'OurServices',
          title: '醫療服務項目',
          subtitle: '請勾選診所開放預約的治療項目，並可自由編輯該治療的詳細介紹，讓患者對於該治療更了解。每項字數上限為60字，若不編輯即顯示預設文字。',
          show: this.isDental
        },
        {
          id: 'ourEnvironment',
          name: '醫療環境',
          plus: true,
          component: 'OurEnvironment',
          title: '醫療環境',
          subtitle: '請勾選診所有設置的區域，並可自由編輯該區域的詳細介紹。每項字數上限為20字，若不編輯即顯示預設文字。',
          show: this.isDental
        },
        {
          id: 'ourEquipment',
          name: '醫療設備',
          plus: true,
          component: 'OurEquipment',
          title: '醫療設備',
          subtitle: '請勾選診所擁有的設備項目，並可自由編輯該設備的詳細介紹。每項字數上限為20字，若不編輯即顯示預設文字。',
          show: this.isDental
        }
      ]
      return items
    }
  },
  methods: {
    onTabChange() {
      if (this.isEdit) {
        this.$nextTick(() => {
          this.tab = this.currentTab
        })
        this.showNotSaveAlert()
      } else {
        this.currentTab = this.tab
      }
    },
    showNotSaveAlert() {
      this.alertData = this.$options.data().alertData
      this.showAlert()
    },
    save() {
      this.$refs[this.currentTab][0].save()
    },
    cancel() {
      this.$refs[this.currentTab][0].cancel()
      this.toggleEdit(false)
    },
    toggleEdit(v) {
      this.isEdit = v
    },
    getIsEdit() {
      return this.isEdit
    },
    toggleLoading(v) {
      this.loading = v
    },
    showAlert() {
      this.alertDialog = true
    },
    setAlertData({icon='mdi-alert-circle', color='red-6', content}) {
      this.alertData.icon = icon
      this.alertData.color = color
      this.alertData.content = content
    },
    saveAlert() {
      this.setAlertData({
        icon: 'mdi-check-circle',
        color: 'primary',
        content: '確認完成編輯'
      })
      this.showAlert()
    },
    getClinicBasicInfo() {
      getClinicBaseInfoAPI(this.axiosParamData).then(res => {
        this.clinicPlan = res.data.plan
      })
      getInfoAPI(this.axiosParamData).then(res => {
        this.info = res.data
      })
    }
  },
  provide: function () {
    return {
      getIsEdit: this.getIsEdit,
      toggleEdit: this.toggleEdit,
      toggleLoading: this.toggleLoading,
      showAlert: this.showAlert,
      setAlertData: this.setAlertData,
      saveAlert: this.saveAlert
    }
  },
  mounted() {
    this.getClinicBasicInfo()
  }
}
</script>

<style lang="scss">
.v-tabs:not(.v-tabs--vertical):not(.v-tabs--right)>.v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes) .v-slide-group__prev {
  display: none !important;
}
</style>
