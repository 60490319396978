<template>
  <setting-layout ref="layout" :title="title" :subtitle="subtitle" @cancel="cancel" @save="save">
    <div class="mt-7">
      <div>
        <div class="bullet">診所環境照片</div>
        <div class="grey-2 pa-5 rounded-lg mt-4">
          <div style="white-space: pre-line;">請上傳貴診所的診所環境照（最多12張），可拖曳滑動調整照片順序。
            <template v-if="clinicPlan === '1'">未升級進階方案僅上傳一張照片，如需上傳多張照片請洽 <a target="_blank" href="https://lin.ee/mWs8BoE">醫師小幫手客服專員</a> 升級為進階方案用戶。</template>
          </div>
          <div class="mt-5">
            <draggable
              v-model="clinicPhotos"
              tag="v-row"
              handle=".handle"
              class="list-group ma-0 d-flex flex-wrap"
              :disabled="!canEdit"
              :move="onMove"
              @start="drag = true"
              @end="drag = false"
            >
              <v-col
                v-for="banner in clinicPhotos"
                :key="banner.id"
                cols="6"
                sm="auto"
                class="pa-sm-3 pa-1"
              >
                <v-img
                  :src="banner.banner"
                  :aspect-ratio="15/8"
                  width="150"
                  class="handle"
                  style="overflow: unset;"
                  :style="canEdit ? 'cursor: pointer' : ''"
                  :gradient="banner.delete ? 'rgba(0,0,0,.3), rgba(0,0,0,.3)' : null"
                >
                  <template v-if="canEdit" v-slot>
                    <v-btn
                      fab
                      dark
                      x-small
                      right
                      absolute
                      depressed
                      style="right: -5px; top: -5px;"
                      width="20"
                      height="20"
                      :color="banner.delete ? 'red' : 'grey-4'"
                      @click.prevent="banner.delete = !banner.delete"
                    >
                      <v-icon dark>{{ banner.delete ? 'mdi-restore' : 'mdi-close' }}</v-icon>
                    </v-btn>
                  </template>
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-col>
              <v-col v-if="canEdit && clinicPhotos.length < 12" id="newPhoto" class="d-flex pa-sm-3 pa-1" cols="6" sm="auto">
                <v-card hover width="150" @click="handleClickAddPhoto">
                  <v-responsive :aspect-ratio="15/8" class="align-center text-center">
                    <v-icon>mdi-image-plus</v-icon>
                  </v-responsive>
                </v-card>
              </v-col>
            </draggable>
            <v-dialog v-if="showCrop" v-model="showCrop" max-width="500">
              <crop-image :aspect-ratio="15/8" @close="showCrop = false" @upload="addPhoto"/>
            </v-dialog>
          </div>
        </div>
      </div>
      <div class="mt-7">
        <div class="bullet">診所簡介</div>
        <v-textarea
          v-model="goal"
          :disabled="!canEdit"
          filled rounded no-resize
          background-color="grey-2"
          class="rounded-lg mt-4"
          hide-details="auto"
          placeholder="請輸入30-100字的診所介紹，例如：醫師小幫手診所，是坐落於台北市信義區的第一間科技互動牙科診所，結合LINE通訊軟體與民眾零距離醫病互動，隨時在身邊照護您的醫師小幫手！"
        />
      </div>
    </div>
  </setting-layout>
</template>

<script>
import SettingLayout from './SettingLayout';
import draggable from "vuedraggable";
import { deleteClinicBannerBatchAPI, getClinicInfoAPI, updateClinicInfoAPI, uploadClinicBannerAPI } from '../../../api';
import { mapGetters } from 'vuex';
import CropImage from '../CropImage';

export default {
  name: "ClinicIntro",
  inject: ['getIsEdit', 'toggleEdit', 'saveAlert', 'setAlertData', 'showAlert', 'toggleLoading'],
  components: {CropImage, SettingLayout, draggable},
  props: {
    title: {
      required: true,
      type: String
    },
    subtitle: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      oClinicPhotos: [],
      clinicPhotos: [],
      drag: false,
      showCrop: false,
      tmpId: 0,
      oGoal: null,
      goal: null,
      clinicPlan: null
    }
  },
  computed: {
    ...mapGetters([
      'axiosParamData',
    ]),
    canEdit() {
      return this.getIsEdit()
    },
  },
  watch: {
    'clinicPhotos': {
      handler() {
        this.autoSortPhoto()
      },
      deep: true,
    },
  },
  methods: {
    cancel() {
      this.resetData()
    },
    save() {
      this.toggleLoading(true)
      const data = {
        ...this.axiosParamData,
        goal: this.goal
      }

      const promises = [
        updateClinicInfoAPI(data),
        ...this.handleSavePhotos()
      ]

      Promise.all(promises).then(() => {
        this.saveAlert()
        this.getData()
        this.toggleEdit(false)
      }).finally(() => {
        this.toggleLoading(false)
      })
    },
    resetData() {
      const tmp = JSON.parse(JSON.stringify(this.oClinicPhotos))
      tmp.map(e => e.delete = false)
      this.clinicPhotos = tmp
      this.tmpId = 0
      this.goal = this.oGoal
    },
    getData() {
      getClinicInfoAPI(this.axiosParamData).then(res => {
        this.oClinicPhotos = res.data.banner
        this.oGoal = res.data.goal
        this.clinicPlan = res.data.plan
        this.resetData()
      })
    },
    autoSortPhoto() {
      this.clinicPhotos.forEach((item, index) => {
        item.sort = index + 1
      })
    },
    setDelete(banner) {
      banner.delete = !banner.delete
    },
    onMove(e, originalEvent) {
      try {
        return e.draggedContext.element.id !== 'newPhoto' && e.relatedContext.element.id !== 'newPhoto'
      }
      catch(err) {
        return false
      }
    },
    addPhoto(image) {
      const newImg = {
        file: image,
        id: `tmp-${this.tmpId + 1}`,
        banner: URL.createObjectURL(image),
        delete: false,
        sort: this.clinicPhotos.length + 1,
        new: true
      }
      this.clinicPhotos.push(newImg)
      this.tmpId += 1
      this.showCrop = false
    },
    handleSavePhotos() {
      const promises = []
      const data = {
        ...this.axiosParamData,
        files: [],
        ids: []
      }
      this.clinicPhotos.forEach(e => {
        if (!e.delete) {
          data.files.push(e.file || new File([], ''))
          data.ids.push(e.new ? '' : e.id)
        }
      })
      const formData = new FormData()
      for (const key in data) {
        if (Array.isArray(data[key])) {
          data[key].forEach(e => {
            formData.append(`${key}[]`, e)
          })
        } else {
          formData.append(key, data[key])
        }
      }
      promises.push(uploadClinicBannerAPI(formData))

      const deleteId = this.clinicPhotos.filter(e => e.delete).map(e => e.id)
      if (deleteId.length)
        promises.push(deleteClinicBannerBatchAPI({...this.axiosParamData, ids: deleteId}))
      return promises
    },
    handleClickAddPhoto() {
      if (this.clinicPlan === '1' && this.clinicPhotos.length > 0) {
        this.setAlertData({content: '欲上傳多張照片，請洽客服升級進階方案'})
        this.showAlert()
      } else {
        this.showCrop = true
      }
    }
  },
  mounted() {
    this.getData()
  }
}
</script>

<style scoped>
.bullet::before {
  content: "";
  background: #000000;
  padding-inline: 5px;
  height: 10px;
  margin-inline-end: 10px;
  display: inline-block;
}
</style>