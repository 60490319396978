<template>
  <v-main>
    <navigation v-if="!liffUser"/>

    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"/>
    </v-overlay>

    <v-card flat max-width="1185" class="mx-auto">
      <guide-tour config-key="superLink">
        <div>
          連結設定可幫助您快速追蹤患者加入的流量來源與轉換成效
          <router-link to="/analytics" class="link_blue--text" style="text-decoration: none;">
            <span style="text-decoration: none;">（統計數據請點我）</span>
          </router-link>
          ，進而優化行銷策略，提高患者轉換率。
        </div>
      </guide-tour>

      <v-container fluid>
        <v-row>
          <v-col cols="12" sm="6">
            <v-card flat class="mx-auto" outlined>
              <v-tabs v-model="tabs">
                <v-tab @click="setMode('')">加入 LINE 官方帳號</v-tab>
                <v-tab v-show="jsonState['add_messenger']" @click="setMode('add_messenger')">加入 Facebook Messenger</v-tab>
                <v-tab v-show="jsonState['appointment']" @click="setMode('appointment')">線上預約連結</v-tab>
              </v-tabs>

              <v-tabs-items v-model="tabs" touchless>
                <v-tab-item>
                  <v-container class="px-6 py-6">
                    <div class="relative mt-4">
                      <v-icon color="primary" size="14" class="mdi mdi-circle mb-1"></v-icon>
                      <span>選擇模式（必選2選1）</span>
                      <v-tooltip top max-width="500">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon color="grey lighten-1" v-bind="attrs" v-on="on">mdi-help-circle-outline</v-icon>
                        </template>
                        <span v-text="''"/>
                      </v-tooltip>
                    </div>
                    <div class="relative ml-4">
                      <v-select
                        return-object filled dense rounded full-width
                        item-text="mode"
                        hide-details="auto"
                        class="setting-input mt-4"
                        label="選擇模式"
                        :items="Object.keys(jsonMode)"
                        v-model="mode"
                      ></v-select>
                    </div>
                    <div class="relative mt-4">
                      <v-icon color="primary" size="14" class="mdi mdi-circle mb-1"></v-icon>
                      <span>選擇來源</span>
                      <v-tooltip top max-width="500">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon color="grey lighten-1" v-bind="attrs" v-on="on">mdi-help-circle-outline</v-icon>
                        </template>
                        <span v-text="''"/>
                      </v-tooltip>
                    </div>
                    <div class="relative ml-4">
                      <v-select
                        return-object filled dense rounded full-width
                        item-text="source"
                        hide-details="auto"
                        class="setting-input mt-4"
                        label="選擇來源"
                        :items="Object.keys(jsonSource[mode])"
                        v-model="source"
                      ></v-select>
                    </div>
                  </v-container>
                </v-tab-item>

                <v-tab-item v-show="jsonState['add_messenger']">
                  <v-container class="px-6 py-6">
                    <div class="relative mt-4">
                      <v-icon color="primary" size="14" class="mdi mdi-circle mb-1"></v-icon>
                      <span>選擇來源</span>
                    </div>
                    <div class="relative ml-4">
                      <v-select
                        return-object filled dense rounded full-width
                        item-text="source"
                        hide-details="auto"
                        class="setting-input mt-4"
                        label="選擇來源"
                        :items="Object.keys(jsonSource[mode])"
                        v-model="source"
                      ></v-select>
                    </div>
                  </v-container>
                </v-tab-item>

                <v-tab-item v-show="jsonState['appointment']">
                  <v-container class="px-6 py-6">
                    <div class="relative mt-4">
                      <v-icon color="primary" size="14" class="mdi mdi-circle mb-1"></v-icon>
                      <span>選擇來源</span>
                    </div>
                    <div class="relative ml-4">
                      <v-select
                        return-object filled dense rounded full-width
                        item-text="source"
                        hide-details="auto"
                        class="setting-input mt-4"
                        label="選擇來源"
                        :items="Object.keys(jsonSource['appointment'][crmUser])"
                        v-model="source"
                      ></v-select>
                    </div>
                    <div
                      v-show="source === '自定義'"
                      class="relative ml-4"
                    >
                        <v-text-field
                          filled rounded full-width hide-details="auto"
                          :placeholder="'輸入自定義來源'"
                          class="setting-input mt-4"
                          v-model="customizeSource"
                        />
                    </div>
                  </v-container>
                </v-tab-item>
              </v-tabs-items>
              <v-container fluid class="d-flex justify-end">
                <!-- <v-btn
                  class="mr-2" color="default"
                  @click="reset()"
                >重選</v-btn> -->

                <v-btn
                  v-if="mode !=='' && source !==''"
                  class="rounded-lg" color="primary"
                  @click="generate()"
                >產生邀請連結</v-btn>
              </v-container>
            </v-card>
          </v-col>

          <v-col cols="12" sm="6">
            <v-card
              flat class="mx-auto" style="background: #F5F5F5;"
              v-if="jsonSuperLink.original !==undefined"
            >
              <div class="d-flex flex-column justify-center align-center pt-6 pb-0">
                  <vue-qr
                    :logoSrc="logo"
                    :text="jsonSuperLink.original"
                    :size="500"
                    :logo-scale="0.225"
                    ref="qrCode"
                    class="my-10"
                    style="width: 250px; height: 250px;"
                  >
                  </vue-qr>
                <v-btn outlined small color="primary" class="mt-2" @click="download()">
                  下載QRcode
                </v-btn>
              </div>
              <div class="relative my-2 mt-6">
                <v-container fluid>
                  <v-row>
                    <v-col cols="12" sm="3">
                      <div class="relative mt-2 pl-4">
                        <v-icon color="primary" size="14" class="mdi mdi-circle mb-1"></v-icon>
                        <span>完整網址</span>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="jsonSuperLink.original"
                        readonly filled rounded full-width hide-details="auto"
                        class="setting-input"
                      />
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-btn
                        class="rounded-lg ml-4" color="primary"
                        @click="copyUrl(jsonSuperLink.original)"
                      >複製網址</v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="3">
                      <div class="relative mt-2 pl-4">
                        <v-icon color="primary" size="14" class="mdi mdi-circle mb-1"></v-icon>
                        <span>短網址</span>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="jsonSuperLink.short"
                        readonly filled rounded full-width hide-details="auto"
                        class="setting-input"
                      />
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-btn
                        class="rounded-lg ml-4" color="primary"
                        @click="copyUrl(jsonSuperLink.short)"
                      >複製網址</v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </div>
            </v-card>

            <v-card
              flat class="mx-auto" style="background: #F5F5F5;"
              v-else
            ></v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-main>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { getSuperLinksAPI } from "../api";
import Navigation from "../components/Navigation";
import GuideTour from "../components/utils/GuideTour.vue";

export default {
  name: "SuperLink",
  components: {GuideTour, Navigation},
  data() {
    return {
      loading: true,
      tabs: 0,
      mode: '',
      source: '',
      customizeSource: '',
      logo: '',
      jsonState: {
        'bind_line': true,
        'add_line': true,
        'add_messenger': true,
        'appointment': true
      },
      jsonLogo: {
        'bind_line': '/images/line.svg',
        'add_line': '/images/line.svg',
        'add_messenger': 'https://i.imgur.com/H3wh8V1.png',
        'appointment': 'https://i.imgur.com/UBpCo78.png'
      },
      jsonMode: {
        '先綁定再加好友': 'bind_line',
        '先加好友再綁定': 'add_line',
      },
      jsonSource: {
        '': {
          '立牌': 'TVmx25bb',
          '官網': 'vZP461HM',
          'Google Map': 'vZP571HV',
          'FB': '3RuFZnoE',
          'IG': 'LgKZARfr',
          'Youtube': 'hDC7gkaB',
          'Tiktok': 'gLlaMkaB',
          '廣告': 'Kl8a61HM',
          '名片': 'Agf2QRfr',
          '好友推薦': 'SVHxJ24C',
          '部落格文章': 'cY1De5Zq',
          '海報': 'DDsQDsIF',
          'NFC立牌': 'mUK1xrvB',
          'NFC卡片': 'AHpex68i',
          'NFC桌貼': 'V7ZMr8ag',
          '簡訊': 'z2XrYtS2',
        },
        '先綁定再加好友': {
          '立牌': 'TVmx25bb',
          '官網': 'vZP461HM',
          'Google Map': 'vZP571HV',
          'FB': '3RuFZnoE',
          'IG': 'LgKZARfr',
          'Youtube': 'hDC7gkaB',
          'Tiktok': 'gLlaMkaB',
          '廣告': 'Kl8a61HM',
          '名片': 'Agf2QRfr',
          '好友推薦': 'SVHxJ24C',
          '部落格文章': 'cY1De5Zq',
          '海報': 'DDsQDsIF',
          'NFC立牌': 'mUK1xrvB',
          'NFC卡片': 'AHpex68i',
          'NFC桌貼': 'V7ZMr8ag',
          '簡訊': 'z2XrYtS2',
        },
        '先加好友再綁定': {
          '立牌': 'TVmx25bb',
          '官網': 'vZP461HM',
          'Google Map': 'vZP571HV',
          'FB': '3RuFZnoE',
          'IG': 'LgKZARfr',
          'Youtube': 'hDC7gkaB',
          'Tiktok': 'gLlaMkaB',
          '廣告': 'Kl8a61HM',
          '名片': 'Agf2QRfr',
          '好友推薦': 'SVHxJ24C',
          '部落格文章': 'cY1De5Zq',
          '海報': 'DDsQDsIF',
          'NFC立牌': 'mUK1xrvB',
          'NFC卡片': 'AHpex68i',
          'NFC桌貼': 'V7ZMr8ag',
          '簡訊': 'z2XrYtS2',
        },
        'add_messenger': {
          '立牌': 'TVmx25bb',
          '官網': 'vZP461HM',
          'Google Map': 'vZP571HV',
          'FB': '3RuFZnoE',
          'IG': 'LgKZARfr',
          'Youtube': 'hDC7gkaB',
          'Tiktok': 'gLlaMkaB',
          '廣告': 'Kl8a61HM',
          '名片': 'Agf2QRfr',
          '好友推薦': 'SVHxJ24C',
          '部落格文章': 'cY1De5Zq',
          '海報': 'DDsQDsIF',
          'NFC立牌': 'mUK1xrvB',
          'NFC卡片': 'AHpex68i',
          'NFC桌貼': 'V7ZMr8ag',
          '簡訊': 'z2XrYtS2',
        },
        'appointment': {
          'doctorUser': {
            '醫師官網': 'DoctorWebsite',
            '醫師部落格': 'DoctorBlog',
            'FB': 'DoctorFB',
            'IG': 'DoctorIG',
            'Youtube': 'DoctorYoutube',
            'Tiktok': 'DoctorTiktok',
            '廣告': 'DoctorAds',
            '名片': 'BusinessCard',
            '自定義': 'Customize',
          },
          'clinicUser': {
            '診所官網': 'ClinicWebsite',
            '診所部落格': 'ClinicBlog',
            'Google Map': 'GoogleMap',
            'FB': 'ClinicFB',
            'IG': 'ClinicIG',
            'Youtube': 'ClinicYoutube',
            'Tiktok': 'ClinicTiktok',
            '廣告': 'ClinicAds',
            '名片': 'BusinessCard',
            '自定義': 'Customize',
          }
        }
      },
      // jsonSuperLink: { "original": "https://liff.line.me/1622774606-my6kd0On?c=3731181091&d=&p=IDCGniPB", "short": "https://link.dentco.tw/62d08368" }
      jsonSuperLink: {}
    }
  },
  computed: {
    ...mapGetters([
      'axiosParamData',
      'liffUser',
      'doctorUser',
      'clinicUser'
    ]),
    crmUser: {
      get() {
        if (this.doctorUser) {
          return 'doctorUser';
        } else {
          return 'clinicUser';
        }
      },
      set(val) {

      }
    }
  },
  methods: {
    ...mapActions({

    }),
    getSuperLinks(source) {
      const vm = this;
      const mode = (vm.jsonMode[vm.mode] !== undefined) ? vm.jsonMode[vm.mode] : vm.mode;
      vm.loading = true;

      getSuperLinksAPI({...vm.axiosParamData, patient_code: source}).then(res => {
        vm.jsonSuperLink = res.data.data[mode];
        vm.logo = vm.jsonLogo[mode];
        vm.loading = false;
      })
    },
    generate () {
      let source;

      if (this.mode === 'appointment') {
        if (this.jsonSource[this.mode][this.crmUser][this.source] === 'Customize') {
          source = this.customizeSource;
        } else {
          source = this.jsonSource[this.mode][this.crmUser][this.source];
        }
      } else {
        source = this.jsonSource[this.mode][this.source];
      }

      this.getSuperLinks(source);
    },
    setMode (mode) {
      if (mode !== '') {
        if (this.jsonMode[mode] !== undefined) {
          this.mode = this.jsonMode[mode];
        } else {
          this.mode = mode;
        }
      } else {
        this.mode = '';
      }

      this.source = '';
    },
    copyUrl (url) {
      const input = document.createElement('input');
      input.setAttribute('value', url);
      document.body.appendChild(input);
      input.select();
      document.execCommand('copy');
      document.body.removeChild(input);
      alert('已複製網址！')
    },
    download () {
      function download(result, filename) {
        const url = result;
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        // the filename you want
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      }
      const qrElem = this.$refs.qrCode.$el;
      download(qrElem.src, 'qr-code.png');

      // html2canvas(qrElem).then(canvas => {
      //   canvas.toBlob(blob => {
      //     download(qrElem.src, 'qr-code.png');
      //   });
      // });
    },
    reset () {
      this.mode = '';
      this.source = '';
    }
  },
  mounted() {
    const vm = this;
    getSuperLinksAPI({...this.axiosParamData, patient_code: ''}).then(res => {
      const data = res.data.data;
      // console.log(data);
      Object.keys(data).forEach(function(mode, index) {
        if (vm.jsonState[mode] !== undefined) {
          if (data[mode]['original'] === null) {
            vm.jsonState[mode] = false;
          }
        }
        vm.loading = false;
      });
    })
  }
}
</script>

<style lang="scss">
.setting-input {
  .v-input__control .v-input__slot {
    min-height: 36px !important;
    border-radius: 10px;
    padding: 0 18px !important;
  }

  &.v-select .v-input__control .v-input__slot {
    padding: 0 8px 0 18px !important;
  }
}
</style>
