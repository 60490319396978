<template>
  <setting-layout ref="layout" :title="title" :subtitle="subtitle" @cancel="cancel" @save="save">
    <div class="d-flex mt-7" :class="{'flex-wrap': $vuetify.breakpoint.xsOnly}">
      <div class="avatar-container align-self-center">
        <v-menu
          v-model="showMenu"
          absolute
          offset-y
          :disabled="!canEdit"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-img
              :src="clinicAvatar"
              aspect-ratio="1"
              class="grey lighten-2 ma-auto"
              width="166"
              height="166"
              max-width="100%"
              max-height="100%"
              v-bind="attrs"
              v-on="on"
              :style="canEdit ? 'cursor: pointer': ''"
            >
              <template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </template>

          <v-list>
            <v-list-item @click="showCrop = true">
              <v-list-item-title>上傳新頭貼</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-dialog v-if="showCrop" v-model="showCrop" max-width="500">
          <crop-image @close="showCrop = false" @upload="uploadAvatar"/>
        </v-dialog>
      </div>
      <div
        class="d-flex flex-wrap"
        :class="{'pl-7': $vuetify.breakpoint.smAndUp, 'mt-5': $vuetify.breakpoint.xsOnly}"
        style="width: 100%; gap: 10px;"
      >
        <div class="d-flex" style="gap: 10px;" :style="$vuetify.breakpoint.xsOnly ? 'width: 100%' : ''">
          <div style="line-height: 2.4; word-break: keep-all;">診所名稱</div>
          <div style="width: 100%;">
            <v-text-field
              v-model="clinic.clinic_name"
              filled dense rounded disabled
              full-width hide-details="auto"
              class="setting-input"
            />
          </div>
        </div>
        <v-spacer v-if="$vuetify.breakpoint.smAndUp"/>
        <div class="d-flex" style="gap: 10px;" :style="$vuetify.breakpoint.xsOnly ? 'order: 1' : ''">
          <div style="line-height: 2.4; word-break: keep-all;">診所電話</div>
          <div class="d-flex" style="gap: 10px;">
            <div style="width: 64px;">
              <v-text-field
                v-model="telCode"
                :disabled="!canEdit"
                filled dense rounded
                hide-details="auto"
                class="setting-input"
              />
            </div>
            <div style="width: 112px;">
              <v-text-field
                v-model="tel"
                :disabled="!canEdit"
                filled dense rounded
                hide-details="auto"
                class="setting-input"
              />
            </div>
          </div>
        </div>
        <div class="d-flex" style="gap: 10px;">
          <div style="line-height: 2.4; word-break: keep-all;">診所地址</div>
          <div class="d-flex flex-wrap" style="gap: 10px;">
            <div
              class="d-flex"
              style="column-gap: 10px;"
              :style="$vuetify.breakpoint.xsOnly ? 'width: 100%' : 'width: 240px'"
            >
              <div style="width: 50%;">
                <v-select
                  v-model="selectCity"
                  :disabled="!canEdit"
                  :items="cities"
                  item-text="name"
                  return-object
                  filled dense rounded
                  full-width
                  hide-details="auto"
                  class="setting-input"
                />
              </div>
              <div style="width: 50%;">
                <v-select v-model="selectArea" :disabled="!canEdit" :items="cityAreas" item-text="name" return-object
                          filled dense rounded full-width hide-details="auto" class="setting-input"/>
              </div>
            </div>
            <div :style="$vuetify.breakpoint.xsOnly ? 'width: 100%' : 'width: 256px'">
              <v-text-field v-model="address" :disabled="!canEdit" filled dense rounded full-width hide-details="auto"
                            class="setting-input"/>
            </div>
          </div>
        </div>
        <div class="d-flex" style="gap: 10px;" :style="$vuetify.breakpoint.xsOnly ? 'width: 100%' : 'width: 65%'">
          <div style="line-height: 2.4; word-break: keep-all;">診所Email</div>
          <div style="width: 100%;">
            <v-text-field
              v-model="clinic.email"
              :disabled="!canEdit"
              filled dense rounded
              full-width hide-details="auto"
              class="setting-input"
            />
          </div>
        </div>
      </div>
    </div>
  </setting-layout>
</template>

<script>
import SettingLayout from './SettingLayout';
import { getCitiesAPI, getClinicInfoAPI, updateClinicInfoAPI } from '../../../api';
import { mapGetters, mapState } from 'vuex';
import CropImage from '../CropImage';

export default {
  name: "BasicInfo",
  inject: ['getIsEdit', 'toggleEdit', 'toggleLoading', 'saveAlert'],
  components: {CropImage, SettingLayout},
  props: {
    title: {
      required: true,
      type: String
    },
    subtitle: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      clinic: {},
      cities: [],
      selectCity: null,
      selectArea: null,
      address: null,
      telCode: null,
      tel: null,
      showMenu: false,
      showCrop: false,
      clinicAvatar: null,
      newClinicAvatar: null,
    }
  },
  computed: {
    ...mapGetters([
      'axiosParamData',
    ]),
    ...mapState({
      user: state => state.user,
    }),
    canEdit() {
      return this.getIsEdit()
    },
    cityAreas() {
      return this.selectCity ? this.selectCity.areas : []
    },
  },
  methods: {
    defaultAddress() {
      const city = this.cities.find(e => e.name.replace('台', '臺') === this.clinic.county.replace('台', '臺'))
      this.selectCity = city || null
      this.selectArea = this.selectCity ? this.selectCity.areas.find(e => e.name === this.clinic.area) || null : null
      this.address = this.clinic.address
    },
    defaultTel() {
      this.telCode = this.clinic.tel.substring(this.clinic.tel.lastIndexOf('(') + 1, this.clinic.tel.lastIndexOf(')'))
      this.tel = this.clinic.tel.split(')')[1]
    },
    defaultAvatar() {
      this.clinicAvatar = this.clinic.profile
    },
    async getClinic() {
      this.toggleLoading(true)
      const res = await getClinicInfoAPI(this.axiosParamData)
      this.clinic = res.data
      if (this.clinic.clinic_name !== this.user.clinic_name) {
        this.setClinicName(this.clinic.clinic_name)
      }
      this.defaultAvatar()
      this.defaultTel()
      this.toggleLoading(false)
    },
    async getData() {
      await this.getClinic()

      getCitiesAPI(this.axiosParamData).then(res => {
        this.cities = res.data
        this.defaultAddress()
      })
    },
    uploadAvatar(image) {
      this.newClinicAvatar = image
      this.clinicAvatar = URL.createObjectURL(image)
      this.showCrop = false
    },
    cancel() {
      this.newClinicAvatar = null
      this.defaultAddress()
      this.defaultAvatar()
      this.defaultTel()
    },
    save() {
      this.toggleLoading(true)
      const data = {
        ...this.axiosParamData,
        tel: `(${this.telCode})${this.tel}`,
        county: this.selectCity.name,
        area: this.selectArea.name,
        address: this.address,
        email: this.clinic.email
      }
      if (this.newClinicAvatar)
        data.profile = this.newClinicAvatar

      const formData = new FormData()
      for (const [key, value] of Object.entries(data)) {
        formData.append(key, value)
      }
      updateClinicInfoAPI(formData).then(() => {
        this.newClinicAvatar = null
        this.getClinic()
        this.toggleEdit(false)
        this.saveAlert()
      }).catch(e => {
        console.error(e)
        this.toggleLoading(false)
      }).finally(() => {
      })
    }
  },
  mounted() {
    this.getData()
  }
}
</script>

<style lang="scss">
.avatar-container {
  width: 100px;
  height: 100px;

  @media only screen and (max-width: 599px) {
    width: 100%;
    height: 166px;
  }
}

.setting-input {
  .v-input__control .v-input__slot {
    min-height: 36px !important;
    border-radius: 10px;
    padding: 0 18px !important;
  }

  &.v-select .v-input__control .v-input__slot {
    padding: 0 8px 0 18px !important;
  }
}
</style>
