import axios from 'axios';
import Vue from 'vue';
import Vuex from 'vuex';
import {
  countUnreadAPI,
  getDoctorInfoAPI,
  getFreeTimeDetailAPI,
  getFreeTimeModeAPI, getManualSettingAPI, getOnlineTreatmentAlertAPI,
  getPopupNoticeAPI,
  readNoticeAPI
} from "../api";
import i18n from "../i18n";
import { checkUserExist, flashTitle } from "../utils/tool";

Vue.use(Vuex);

const state = {
  user: {},
  liffUser: false,
  specialPatient: {
    rest: 'resttime',
    note: 'memo'
  },
  attendStatusMap: {
    '-1': {text: i18n.t('store.standUp'), color: 'red-6'},
    '0': {text: i18n.t('store.undefined'), color: '#463636'},
    '1': {text: i18n.t('store.keepAppointment'), color: 'primary'},
    '4': {text: i18n.t('store.alterAppointment'), color: 'orange-6'},
    '5': {text: i18n.t('store.alterAppointment'), color: 'orange-6'},
  },
  educationItems: [
    {name: '臺灣大學', logo: 'https://dentco.tw/images/school/臺灣大學.png'},
    {name: '陽明大學', logo: 'https://dentco.tw/images/school/陽明大學.png'},
    {name: '臺北醫學大學', logo: 'https://dentco.tw/images/school/臺北醫學大學.png'},
    {name: '國防醫學院', logo: 'https://dentco.tw/images/school/國防醫學院.png'},
    {name: '中國醫藥大學', logo: 'https://dentco.tw/images/school/中國醫藥大學.png'},
    {name: '中山醫學大學', logo: 'https://dentco.tw/images/school/中山醫學大學.png'},
    {name: '高雄醫學大學', logo: 'https://dentco.tw/images/school/高雄醫學大學.png'},
    {name: '美國賓夕法尼亞大學', logo: 'https://dentco.tw/images/school/美國賓夕法尼亞大學.png'},
    {name: '其他', logo: 'https://dentco.tw/images/school/其他.png'},  // 必須放最後
  ],
  assistant: {  // 智能助理
    url: 'https://lin.ee/pmm0aRc',
    image: 'https://dentco.s3.us-east-2.amazonaws.com/asset/base/dentco_line_assistant.png'
  },
  panoId: 7,
  doctorEmail: null,
  globalAlert: null,
  popUpNotice: null,
  notification: 0,
  excludeMessageActivities: {
    'doctor': [24],
    'clinic': [],
  },
  specialClinic: [],
  smsStatus: null,
  callStatus: null,
  modeSetting: {},
  detailSetting: {},
  manualSetting: {},  // API 來的
  manualWeekly: [],
  manualSingle: {},
  manualClosed: [],
  selectRecord: null,
  ownChannel: false,

  defaultAiSetting: {
    style_prompt: '親切謙和',
    use_emoji_prompt: 0,
    seo_prompt: '',
    use_seo: 0,
    ai_generate_mode: 1,
    ai_generate_mode_good: 0,
    ai_generate_mode_bad: 1
  }
}

const mutations = {
  setUserData(state, userData) {
    state.user = userData
    localStorage.setItem('user', JSON.stringify(userData))
    axios.defaults.headers.common.Authorization = `Bearer ${userData.token}`
  },
  setClinicName(state, name) {
    state.user.clinic_name = name
    localStorage.setItem('user', JSON.stringify(state.user))
  },
  setDoctorName(state, name) {
    state.user.doctor_name = name
    localStorage.setItem('user', JSON.stringify(state.user))
  },
  clearUserData() {
    localStorage.removeItem('user')
    location.reload()
  },
  setLiffUSer(state) {
    state.liffUser = true
  },
  setDoctorEmail(state, email) {
    state.doctorEmail = email
  },
  setGlobalAlert(state, data) {
    state.globalAlert = data
  },
  setPopUpNotice(state, data) {
    state.popUpNotice = {
      dialog: true,
      ...data
    }
  },
  setNotification(state, data) {
    state.notification = data
  },
  setSmsStatus(state, data) {
    state.smsStatus = data
  },
  setCallStatus(state, data) {
    state.callStatus = data
  },
  setModeSetting(state, data) {
    state.modeSetting = data
  },
  setDetailSetting(state, data) {
    state.detailSetting = data
  },
  setManualSetting(state, data) {
    state.manualSetting = data
  },
  setManualWeekly(state, data) {
    state.manualWeekly = data
  },
  setManualSingle(state, data) {
    state.manualSingle = data
  },
  setManualClosed(state, data) {
    state.manualClosed = data
  },
  setSelectRecord(state, data) {
    state.selectRecord = data
  },
  setOwnChannel(state, data) {
    state.ownChannel = data
  },
}

const getters = {
  axiosParamData: state => {
    const auth = {type: state.user.type}
    if (state.user.type === '1') auth['clinic_code'] = state.user.clinic_code
    else auth['doctor_code'] = state.user.doctor_code
    if (state.user.line_bot_id) auth['line_bot_id'] = state.user.line_bot_id
    if (Number(state.user.plan) === 99) auth.plan = 99
    return auth
  },
  isSuper: state => {
    return Number(state.user.plan) === 99;
  },
  clinicUser: state => {
    return state.user.type === '1';
  },
  doctorUser: state => {
    return state.user.type === '2';
  },
  liffUser: state => {
    return state.liffUser
  },
  isSpecialClinic: state => {
    return state.specialClinic.includes(String(state.user.clinic_code))
  },
  medicalKind: state => {
    return state.user.kind
  },
  isDental: state => {
    return state.user.kind === '1'
  },
  smsStatus: state => {
    if (state.smsStatus === null)
      return null
    return Boolean(Number(state.smsStatus))
  },
  callStatus: state => {
    if (state.callStatus === null)
      return null
    return Boolean(Number(state.callStatus))
  },
  modeSetting: state => {
    return state.modeSetting
  },
  detailSetting: state => {
    return state.detailSetting
  },
  manualSetting: state => {
    return state.manualSetting
  },
  manualWeekly: state => {
    return state.manualWeekly
  },
  manualSingle: state => {
    return state.manualSingle
  },
  manualClosed: state => {
    return state.manualClosed
  },
  hasOwnChannel: state => {
    return state.ownChannel
  },
  defaultAiSetting: state => {
    return state.defaultAiSetting
  }
}

const actions = {
  async getDoctorEmail(context) {
    const res = await getDoctorInfoAPI(context.getters.axiosParamData)
    context.commit('setDoctorEmail', res.data.email)
    return res.data.email
  },
  async getPopUpNotice(context) {
    const res = await getPopupNoticeAPI(context.getters.axiosParamData)
    if (Array.isArray(res.data) && !res.data.length) {
    } else
      context.commit('setPopUpNotice', res.data)
  },
  async afterLogin(context) {
    const email = await context.dispatch('getDoctorEmail')
    if (context.getters.doctorUser && !context.getters.liffUser && !email)
      context.commit('setGlobalAlert', {
        message: '為了提供醫師更多個人品牌行銷知識\n提醒醫師需填寫您的email，\n才能接收更多最新資訊喔!',
        to: {name: 'settings'},
        dialog: true,
      })

    await context.dispatch('getPopUpNotice')
  },
  async getUnreadNotice(context) {
    const res = await countUnreadAPI(context.getters.axiosParamData)
    const data = res.data
    if (!checkUserExist() && data.count) {
      const count = data.count > 99 ? '99+' : data.count
      flashTitle(count, '醫師小幫手CRM系統')
    }
    context.commit('setNotification', data)
  },
  async setNoticeRead(context, ids) {
    readNoticeAPI({...context.getters.axiosParamData, id: ids.join()}).then(() => {
      context.dispatch('getUnreadNotice')
    })
  },
  async getFreeTimeMode(context, params) {
    const res = await getFreeTimeModeAPI(params)
    const data = res.data
    context.commit('setModeSetting', data)
  },
  async getFreeTimeDetail(context, params) {
    context.commit('setDetailSetting', {})
    const res = await getFreeTimeDetailAPI(params)
    const data = res.data
    // data.appointment_completed_line_page_kind = 1
    // data.appointment_completed_line_page_link = '0000134mfknvjds'
    context.commit('setDetailSetting', data)
  },
  async getManualSetting(context, params) {
    context.commit('setManualSetting', {})
    const res = await getManualSettingAPI(params)
    const data = res.data
    await context.dispatch('processManualSetting', data)
    await context.dispatch('processManualWeekly', data.weekly)
    await context.dispatch('processManualSingle', data.single)
    await context.dispatch('processManualClosed', data.closed)
  },
  async processManualSetting(context, data) {
    context.commit('setManualSetting', data)
  },
  async processManualWeekly(context, data) {
    const manualWeekly = data.length ? JSON.parse(JSON.stringify(data)) : [[], [], [], [], [], [], []]
    context.commit('setManualWeekly', manualWeekly)
  },
  async processManualSingle(context, data) {
    const manualSingle = Array.isArray(data) ? {} : JSON.parse(JSON.stringify(data))
    context.commit('setManualSingle', manualSingle)
  },
  async processManualClosed(context, data) {
    const manualClosed = data.length ? JSON.parse(JSON.stringify(data)) : []
    context.commit('setManualClosed', manualClosed)
  }
}

export default new Vuex.Store({
  state,
  mutations,
  getters,
  actions
});
