<template>
  <v-card flat class="pa-2" color="transparent">
    <v-timeline dense>
      <v-timeline-item
        v-for="(item, index) in reserveRecordItems"
        :key="index"
        :color="attendStatusMap[item.attend_status].color"
      >
        <v-card
          class="elevation-2"
          @click="onClick(item)"
        >
          <v-card-text>
            {{ item.start_time }} {{ clinicUser ? `${item.doctor_name}醫師` : item.clinic_name }}，
            {{ attendStatusMap[item.attend_status].text }}。
          </v-card-text>
        </v-card>
      </v-timeline-item>
    </v-timeline>
  </v-card>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import { getPatientReserveRecordAPI } from "../../api";

export default {
  name: "ReserveRecord",
  props: ['patientCode'],
  data() {
    return {
      reserveRecordItems: []
    }
  },
  computed: {
    ...mapState({
      attendStatusMap: state => state.attendStatusMap,
    }),
    ...mapGetters([
      'axiosParamData',
      'clinicUser'
    ]),
  },
  methods: {
    ...mapMutations({
      setSelectRecord: 'setSelectRecord'
    }),
    onClick(item) {
      this.setSelectRecord(item)
      if (this.$router.currentRoute.name !== 'home')
        this.$router.push({name: 'home'})
    }
  },
  mounted() {
    getPatientReserveRecordAPI({
      ...this.axiosParamData,
      patient_code: this.patientCode
    }).then(res => {
      this.reserveRecordItems = res.data
    })
  }
}
</script>

<style scoped>

</style>
