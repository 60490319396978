<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="8" sm="10" class="grey--text text--darken-3">
        <div class="my-1">姓名：{{ question.name }}</div>
        <div class="my-1">電話：{{ question.phone }}</div>
        <div class="my-1">留言時間：{{ question.time }}</div>
        <div v-if="clinicUser" class="my-1">詢問對象：{{ question.answer_to }}</div>
        <div class="mt-4">
          <v-chip label class="mb-2" text-color="grey darken-2">
            留言內容
          </v-chip>
          <div class="ml-4 grey--text text--darken-2">
            {{ question.content }}
          </div>
        </div>
      </v-col>
      <v-col cols="4" sm="2">
        <v-chip v-if="question.answer" color="primary" label class="px-6">
          已回復
        </v-chip>
        <v-chip v-else color="red accent-2" label text-color="white" class="px-6">
          未回復
        </v-chip>
      </v-col>
      <v-col cols="12" class="mt-2">
        <div>
          <v-chip label class="mb-2" text-color="grey darken-2">
            醫師回覆
          </v-chip>
          <span v-if="question.answer && question.update_name" class="grey--text text--darken-1">(最後回覆: {{ question.update_name }})</span>
          <div v-if="editDoctorResponse" style="position: relative" class="pa-1">
            <v-overlay :value="loading" absolute>
              <v-progress-circular indeterminate size="64"/>
            </v-overlay>
            <v-textarea v-model.trim="doctorResponse" no-resize outlined hide-details dense autofocus height="100"/>
            <v-btn color="primary" class="mt-2" :disabled="!doctorResponse" @click="submit">送出</v-btn>
            <v-btn color="#6a6a6a" class="mt-2 white--text" @click="editDoctorResponse = false">取消</v-btn>
          </div>
          <div v-else>
            <template v-if="question.answer">
              <div class="ml-4 grey--text text--darken-2">
                {{ question.answer }}
              </div>
              <v-btn color="primary" outlined small @click="editDoctorResponse = true">
                修改回覆內容
              </v-btn>
            </template>
            <v-btn v-else color="primary" outlined small @click="editDoctorResponse = true">
              點我回覆民眾問題
              <v-icon small>mdi-reply</v-icon>
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { replyQuestionsAPI } from "../../api";
import { mapGetters } from "vuex";

export default {
  name: "QuestionDetailCard",
  props: ['question'],
  data() {
    return {
      doctorResponse: null,
      editDoctorResponse: false,
      loading: false,
    }
  },
  computed: {
    ...mapGetters([
      'axiosParamData',
      'clinicUser',
    ]),
  },
  methods: {
    submit() {
      this.loading = true
      const data = {
        ...this.axiosParamData,
        id: this.question.id,
        text: this.doctorResponse
      }
      replyQuestionsAPI(data).then(() => {
        this.editDoctorResponse = false
        this.$emit('refresh')
      }).finally(() => {
        this.loading = false
      })
    }
  },
  mounted() {
    this.doctorResponse = this.question.answer
  }
}
</script>

<style scoped>

</style>