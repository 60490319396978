<template>
  <v-main>
    <navigation/>
    <v-card max-width="500" flat class="mx-auto mt-12">
      <v-snackbar
        v-model="snackbar"
        color="red-6"
        top
        absolute
        content-class="white--text font-weight-bold"
      >
        {{ snackbarDetail }}
      </v-snackbar>
      <v-card-title>重設您的帳號密碼</v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-text-field v-model="formData.account" label="帳號" :rules="rules.account" autofocus autocomplete="off"/>
          <v-text-field v-model="formData.password" label="密碼" type="password" :rules="rules.password"
                        autocomplete="new-password"/>
          <v-text-field v-model="formData.password2" label="確認密碼" type="password"
                        :rules="rules.password2.concat(validatePassword2)" autocomplete="new-password"/>
          <v-text-field v-model="formData.email" label="email" :rules="rules.email" autocomplete="email"/>
        </v-form>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn color="primary" :loading="loading" @click="submit">確認送出</v-btn>
      </v-card-actions>
    </v-card>
  </v-main>
</template>

<script>
import Navigation from "../components/Navigation";
import { mapGetters, mapMutations } from "vuex";
import { accountSettingAPI } from "../api";

export default {
  name: "AccountSettings",
  components: {Navigation},
  data() {
    return {
      valid: true,
      formData: {
        account: null,
        password: null,
        password2: null,
        email: null,
      },
      rules: {
        account: [
          v => !!v || '請輸入帳號',
          v => (/^[a-zA-Z0-9]{6,16}$/).test(v) || '帳號需6碼至16碼英文或數字組合，不得含有特殊符號'
        ],
        email: [
          v => !!v || '請輸入信箱',
          v => (/^\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/).test(v) || 'Email格式不正確',
        ],
        password: [
          v => !!v || '請輸入密碼',
          v => (/^(?=.*[0-9])(?=.*?[a-zA-Z])[a-zA-Z0-9!@#$%^&*]{6,20}$/).test(v) || '密碼至少需包含英文、數字共6碼至20碼'
        ],
        password2: [
          v => !!v || '請輸入確認密碼'
        ],
      },
      snackbar: false,
      snackbarDetail: null,
      loading: false,
    }
  },
  computed: {
    ...mapGetters([
      'axiosParamData',
    ]),
    validatePassword2() {
      return this.formData.password2 === this.formData.password || "確認密碼與密碼不一致"
    },
  },
  methods: {
    ...mapMutations({
      clearUserData: 'clearUserData'
    }),
    submit() {
      this.$refs.form.validate()
      if (!this.valid) return
      this.loading = true
      const data = {
        ...this.axiosParamData,
        account: this.formData.account,
        password: this.formData.password,
        email: this.formData.email,
      }
      accountSettingAPI(data).then(() => {
        this.$refs.form.reset()
        this.clearUserData()
      }).catch(e => {
        if (e.response.data.messages)
          this.snackbarDetail = e.response.data.messages
        else
          this.snackbarDetail = this.$t('GENERAL.oops')
        this.snackbar = true
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>

</style>