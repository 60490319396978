<template>
  <v-dialog v-model="dialog" max-width="450">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" :disabled="!hasPermission" :outlined="hasPermission" color="primary">
        「生成」過去評價的AI回覆
      </v-btn>
    </template>

    <v-card class="pa-4" :loading="loading" :disabled="loading">
      <v-btn
        fab
        icon
        absolute
        width="48"
        height="48"
        style="top: 0; right: 0; overflow: hidden;"
        @click="dialog = false;"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-card-title class="text-center d-block">
        {{ title }}
      </v-card-title>
      <v-card-subtitle class="red-6--text text-center">
        {{ errorMsg }}
      </v-card-subtitle>
      <v-card-text v-show="step === 1">
        <v-checkbox
          v-model="selectType"
          label="站內評價"
          :value="1"
          hide-details="auto"
        />
        <v-checkbox
          v-model="selectType"
          label="Google商家評價"
          :value="2"
          hide-details="auto"
        />
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn color="primary" class="px-8" @click="confirm">{{ step === 1 ? '確定選擇' : '確定生成' }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { generatePastAiResponseAPI } from "@/api";
import { mapGetters } from "vuex";

export default {
  name: 'GenerateAiResponseDialog',

  props: {
    hasPermission: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      dialog: false,
      selectType: [],
      title: '請選擇欲生成AI回覆的評價類型',

      step: 1,
      loading: false,

      errorMsg: ''
    };
  },

  computed: {
    ...mapGetters([
      'axiosParamData'
    ]),
  },

  watch: {
    dialog(v) {
      if (!v) {
        this.resetData()
      }
    }
  },

  methods: {
    async confirm() {
      switch (this.step) {
        case 1:
          if (!this.selectType.length) {
            return this.errorMsg = '請至少選擇一項'
          } else {
            this.errorMsg = ''
          }
          this.loading = true
          try {
            const count = await this.generatePastAiResponse();
            this.title = `您即將生成 ${count} 筆AI回覆做瀏覽，請確認AI回覆設定，生成後若要修改，會需要每筆手動操作，生成後不會自動送出回覆！`;
            this.step = 2;
          } catch (error) {
            console.error('生成失敗：', error);
            alert(error);
          } finally {
            this.loading = false
          }
          break;
        case 2:
          this.loading = true
          try {
            await this.generatePastAiResponse('prod');
            alert('AI努力生成中，請稍後');
            this.dialog = false
            this.resetData()
          } catch (error) {
            console.error('操作失敗：', error);
            alert(error);
          } finally {
            this.loading = false
          }
          break;
      }
    },
    async generatePastAiResponse(mode = 'dev') {
      const data = {
        mode,
        ...this.axiosParamData,
        generate_type: this.selectType
      };
      try {
        if (mode === 'dev') {
          const res = await generatePastAiResponseAPI(data);
          return res.data?.count;
        } else {
          generatePastAiResponseAPI(data).catch(error => {
            console.error('生成API請求失敗：', error);
          });
        }
      } catch (error) {
        console.error('生成API請求失敗：', error);
        const errorMsg = error?.response?.data?.messages || this.$t('GENERAL.oops');
        throw new Error(errorMsg);
      }
    },
    resetData() {
      this.step = this.$options.data().step
      this.title = this.$options.data().title
      this.selectType = this.$options.data().selectType
      this.errorMsg = this.$options.data().errorMsg
    }
  }
};
</script>

<style scoped>
/* Your styles here */
</style>
