<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="4" md="2" class="text-center outlined">{{ $t('FreeTimeSetting.week') }}</v-col>
      <v-col cols="8" md="10" class="text-center outlined">{{ $t('FreeTimeSetting.freeTime') }}</v-col>
    </v-row>
    <v-row v-for="freeTimeData in freeTimeView" dense :key="freeTimeData.date.date">
      <v-col cols="4" md="2" class="outlined d-flex text-center align-center justify-center">
        {{ freeTimeData.date.date }}<br>{{ weekdays[freeTimeData.date.weekday] }}
      </v-col>
      <v-col cols="8" md="10" class="outlined d-flex align-center flex-wrap">
        <v-chip
          v-for="(time, index) in freeTimeData.openingTime"
          :key="index"
          class="mr-2 my-1"
          :small="$vuetify.breakpoint.width < 1040"
          v-text="time"
          color="primary"
          text-color="black"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ManualView",

  props: {
    selectSetting: {
      type: String,
      required: false
    },
    dateList: {
      type: Array,
      required: true
    },
    weekdays: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      freeTimeView: []
    }
  },

  computed: {
    ...mapGetters([
      'manualWeekly',
      'manualSingle',
      'manualClosed'
    ]),
  },

  watch: {
    selectSetting: {
      handler(v) {
        if (v === 'view') this.processView()
      },
      immediate: true
    }
  },

  methods: {
    processView() {
      this.freeTimeView = this.dateList.map(date => {
        let openingTime = this.manualSingle[date.date] ? this.manualSingle[date.date].slice(0) : []
        if (!this.manualClosed.includes(date.date)) {
          openingTime = openingTime.concat(this.manualWeekly[date.weekday])
        }
        return {
          date,
          openingTime: new Set(openingTime.sort())
        }
      })
    }
  }
}
</script>

<style scoped>
.outlined {
  border: 1px solid #707070;
}
</style>
