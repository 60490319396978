<template>
  <v-card color="white pb-6">
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"/>
    </v-overlay>
    <v-card-title class="justify-center" style="background-color: #5E676E;">
      <div class="white--text">
        <div class="text-center">手動空檔時段設定</div>
        <div class="text-center text-body-2 text-decoration-underline">（下滑設定更多內容）</div>
      </div>
    </v-card-title>

    <v-card-text>
      <v-tabs v-model="selectSetting" show-arrows>
        <v-tab href="#weekly">設定週期時段</v-tab>
        <v-tab href="#single">設定單次時段</v-tab>
        <v-tab href="#view">檢視全部時段</v-tab>
      </v-tabs>

      <v-sheet max-height="500" class="overflow-y-auto">
        <v-tabs-items v-model="selectSetting" touchless>
          <v-tab-item value="weekly">
            <manual-weekly :time-options="timeOptions" :weekdays="weekdays"/>
          </v-tab-item>
          <v-tab-item value="single">
            <manual-single :date-list="dateList" :time-options="timeOptions" :weekdays="weekdays"/>
          </v-tab-item>
          <v-tab-item value="view">
            <manual-view ref="viewRef" :date-list="dateList" :select-setting="selectSetting" :weekdays="weekdays"/>
          </v-tab-item>
        </v-tabs-items>
      </v-sheet>
    </v-card-text>

    <v-card-actions class="px-6">
      <v-spacer/>
      <v-btn color="red-6" small dark @click="save">儲存</v-btn>
      <v-btn color="grey-3" small @click="cancel">取消</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { getDatesUntil } from "../../utils/tool";
import ManualWeekly from "./ManualWeekly.vue";
import ManualSingle from "./ManualSingle.vue";
import ManualView from "./ManualView.vue";
import { getLastDate, updateManualSettingAPI } from "../../api";

export default {
  name: "ManualSetting",

  components: {ManualView, ManualSingle, ManualWeekly},

  props: {
    settingCode: {
      type: String,
      required: true
    },
  },

  data() {
    return {
      selectSetting: 'weekly',
      getDatesUntil,
      loading: false,
      dateList: []
    }
  },

  computed: {
    ...mapGetters([
      'clinicUser',
      'axiosParamData',
      'detailSetting',
      'manualSetting',
      'manualWeekly',
      'manualSingle',
      'manualClosed',
    ]),
    weekdays() {
      this.$moment.locale("zh_TW")
      return this.$moment.weekdaysShort()
    },
    // async dateList() {
    //   const params = {
    //     ...this.axiosParamData,
    //   }
    //   if (this.clinicUser)
    //     params.doctor_code = this.settingCode
    //   else
    //     params.clinic_code = this.settingCode
    //
    //   try {
    //     const res = await getLastDate(params)
    //     const lastDate = res.data.specify_end_date
    //     console.log(lastDate)
    //     return this.getDatesUntil(lastDate);
    //   } catch (e) {
    //     console.error(e)
    //     alert('手動空檔時間生成錯誤')
    //   } finally {
    //
    //   }
    // },
    timeOptions() {
      const items = []
      new Array(24).fill().forEach((acc, index) => {
        items.push(this.$moment({hour: index}).format('HH:mm'))
        items.push(this.$moment({hour: index, minute: 30}).format('HH:mm'))
      })
      return items.splice(16, 29)
    }
  },

  watch: {
    settingCode: {
      async handler(v) {
        this.selectSetting = this.$options.data().selectSetting

        const params = {
          ...this.axiosParamData
        }
        if (this.clinicUser)
          params.doctor_code = v
        else
          params.clinic_code = v
        this.loading = true
        await this.getManualSetting(params)
        this.loading = false
      },
      immediate: true,
    },
    'detailSetting.months_count': {
      async handler(v) {
        if (v) await this.fetchDateList()
      },
      immediate: true,
    }
  },

  methods: {
    ...mapActions({
      getManualSetting: 'getManualSetting',
      processManualSetting: 'processManualSetting'
    }),
    async fetchDateList() {
      const params = {
        ...this.axiosParamData,
      }
      if (this.clinicUser)
        params.doctor_code = this.settingCode
      else
        params.clinic_code = this.settingCode

      try {
        const res = await getLastDate(params)
        const lastDate = res.data.specify_end_date
        this.dateList = this.getDatesUntil(lastDate);
      } catch (e) {
        console.error(e)
        alert('手動空檔時間生成錯誤')
      } finally {

      }
    },
    save() {
      const data = {
        ...this.axiosParamData,
        weekly: this.manualWeekly,
        single: this.manualSingle,
        closed: this.manualClosed,
      }
      if (this.clinicUser)
        data.doctor_code = this.settingCode
      else
        data.clinic_code = this.settingCode
      this.loading = true
      updateManualSettingAPI(data).then(async () => {
        const params = {
          ...this.axiosParamData
        }
        if (this.clinicUser)
          params.doctor_code = this.settingCode
        else
          params.clinic_code = this.settingCode
        await this.getManualSetting(params)
      }).finally(() => {
        this.loading = false
      })
    },
    async cancel() {
      await this.processManualSetting(JSON.parse(JSON.stringify(this.manualSetting)))
      if (this.selectSetting === 'view') {
        this.$refs.viewRef.processView()
      }
    }
  }
}
</script>

<style scoped>

</style>
