<template>
  <v-main>
    <navigation/>

    <v-tabs vertical background-color="grey-8" style="height: 100%;">
      <v-tab
        class="px-md-12"
        @click="$refs.serviceVersion ? $refs.serviceVersion.getData() : null"
      >
        AI版本選擇
      </v-tab>
      <v-tab
        class="px-md-12"
        @click="$refs.dataPack ? $refs.dataPack.getData() : null"
      >
        匯入資料包
      </v-tab>
      <v-tab
        class="px-md-12"
        @click="$refs.qaList ? $refs.qaList.resetPage() : null"
      >
        個性化QA
      </v-tab>
      <v-tab
        class="px-md-12"
        @click="$refs.history ? $refs.history.getData() : null"
      >
        互動記錄
      </v-tab>

      <v-tab-item>
        <v-card flat max-width="1280" class="px-md-6 px-2">
          <guide-tour config-key="AICustomerService.version">
            小幫手 AI 為透過 Open AI 的 ChatGPT 訓練而成的診所客服 AI 模型，
            診所/醫師可透過匯入資料包、個人化內容，讓聊天機器人成為您最佳的虛擬助理。
          </guide-tour>

          <a-i-service-version ref="serviceVersion" :medical-code.sync="medicalCode"/>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat max-width="1280" class="px-md-6 px-2">
          <guide-tour config-key="AICustomerService.dataPack">
            請選擇您想匯入的資料內容，資料可以選擇一個或多個以上的內容。如有設定問題請洽
          </guide-tour>

          <a-i-data-pack ref="dataPack" :medical-code="medicalCode"/>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat max-width="1280" class="px-md-6 px-2">
          <guide-tour config-key="AICustomerService.qa"/>

          <a-i-question-answer ref="qaList" :medical-code="medicalCode"/>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat max-width="1280" class="px-md-6 px-2">
          <guide-tour config-key="AICustomerService.interactiveHistory"/>

          <a-i-cs-interactive-his ref="history"/>
        </v-card>
      </v-tab-item>
    </v-tabs>
  </v-main>
</template>

<script>
import Navigation from '../components/Navigation';
import AIServiceVersion from '../components/AICustomerService/AIServiceVersion';
import AIDataPack from '../components/AICustomerService/AIDataPack';
import AIQuestionAnswer from '../components/AICustomerService/AIQuestionAnswer';
import AICsInteractiveHis from '../components/AICustomerService/AICsInteractiveHis';
import GuideTour from "../components/utils/GuideTour.vue";

export default {
  name: "AICustomerService",

  components: {GuideTour, AICsInteractiveHis, AIQuestionAnswer, AIDataPack, AIServiceVersion, Navigation},

  data() {
    return {
      medicalCode: null
    }
  }
}
</script>

<style scoped>

</style>
