import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import LineIcon from '@/components/utils/icon/LineIcon.vue';
import TiktokIcon from '@/components/utils/icon/TiktokIcon.vue';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    // iconfont: 'mdi',
    values: {
      line: {
        component: LineIcon
      },
      tiktok: {
        component: TiktokIcon
      }
    }
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#0ec8bf',
        light_blue: '#02fff4',
        secondary: '#68ffdd',
        blue: '#48b6e0',
        link_blue: '#4525F2',

        'tiffany-1': '#F3FCFC',
        'tiffany-2': '#CFF4F2',
        'tiffany-3': '#9FE9E5',
        'tiffany-4': '#6EDED9',
        'tiffany-5': '#3ED3CC',
        'tiffany-6': '#0EC8BF',
        'tiffany-7': '#0BA099',
        'tiffany-8': '#087873',
        'tiffany-9': '#06504C',
        'tiffany-10': '#00A099',

        'blue-1': '#F2FAFE',
        'blue-2': '#CCECFB',
        'blue-3': '#99D8F7',
        'blue-4': '#66C5F3',
        'blue-5': '#33B1EF',
        'blue-6': '#009EEB',
        'blue-7': '#007EBC',
        'blue-8': '#005F8D',
        'blue-9': '#003F5E',

        'green-1': '#F4FDF6',
        'green-2': '#D5F5DD',
        'green-3': '#ABEBBB',
        'green-4': '#82E298',
        'green-5': '#58D876',
        'green-6': '#2ECE54',
        'green-7': '#25A543',
        'green-8': '#1C7C32',
        'green-9': '#1C7C32',

        'orange-1': '#fde9cb',
        'orange-2': '#fdd598',
        'orange-3': '#fcc572',
        'orange-4': '#fcb64d',
        'orange-5': '#fba626',
        'orange-6': '#fc9800',

        'red-1': '#FFF7F7',
        'red-2': '#FEE0E0',
        'red-3': '#FDC1C1',
        'red-4': '#FBA2A2',
        'red-5': '#FA8383',
        'red-6': '#F96464',

        'yellow-1': '#FFFDF2',
        'yellow-2': '#FEF6CC',
        'yellow-3': '#FDEE99',
        'yellow-4': '#FBE567',
        'yellow-5': '#FADD34',
        'yellow-6': '#F9D401',

        'grey-1': '#FFFFFF',
        'grey-2': '#F9F9F9',
        'grey-3': '#E0E0E0',
        'grey-4': '#C1C1C1',
        'grey-5': '#6A6A6A',
        'grey-6': '#000000',
        'grey-7': '#757575',
        'grey-8': '#F5F5F5'
      },
    },
  },
});
