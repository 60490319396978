<template>
  <v-main>
    <navigation v-if="!liffUser"/>
    <v-container fluid>
      <v-row dense>
        <v-col v-if="clinicUser" cols="12" md="2" xl="1"
               :class="[{ 'tab-col': $vuetify.breakpoint.mdAndUp }, 'overflow-y-auto']">
          <clinic-reserve-tabs :value.sync="currentTab" :tab-items.sync="tabItems" @refresh="getDoctor"/>
        </v-col>
        <v-col v-else cols="12" md="2" xl="1">
          <reserve-tabs :value.sync="currentTab" :tab-items.sync="tabItems" @refresh="getClinic"/>
        </v-col>
        <v-col cols="12" md="10" xl="11">
          <reserve-calendar v-if="tabItems" :tabItems="tabItems" :currentTab="currentTab"/>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import Navigation from '../components/Navigation';
import ReserveCalendar from "../components/reserve/ReserveCalendar";
import { mapGetters, mapState } from "vuex";
import { getClinicListAPI, getDoctorListAPI } from "../api";
import ReserveTabs from "../components/reserve/ReserveTabs";
import ClinicReserveTabs from "../components/reserve/ClinicReserveTabs";

export default {
  components: {
    ClinicReserveTabs,
    ReserveTabs,
    Navigation,
    ReserveCalendar
  },
  data() {
    return {
      currentTab: 'all',
      tabItems: []
    }
  },
  computed: {
    ...mapGetters([
      'axiosParamData',
      'clinicUser',
      'liffUser'
    ]),
  },
  methods: {
    getClinic() {
      getClinicListAPI(this.axiosParamData).then(res => {
        this.tabItems = res.data
      })
    },
    getDoctor() {
      getDoctorListAPI(this.axiosParamData).then(res => {
        this.tabItems = res.data
      })
    }
  },
  mounted() {
    if (this.clinicUser) {
      this.getDoctor()
    } else {
      this.getClinic()
    }
  }
}
</script>

<style scoped lang="scss">
.tab-col {
  height: calc(100vh - 80px);
}
</style>
