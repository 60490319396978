<template>
  <v-card outlined flat height="calc(100vh - 300px)" class="mt-12">
    <v-tabs v-model="tab" grow>
      <v-tab class="px-md-12" @click="resetEdit">
        自行輸入QA
      </v-tab>
      <v-tab class="px-md-12" @click="getAllQuestions">
        輸入記錄
      </v-tab>
    </v-tabs>

    <div style="height: calc(100% - 48px);" class="overflow-y-auto">
      <v-tabs-items v-model="tab" touchless>
        <v-tab-item class="pa-4">
          <edit-question-answer-card
            :value="currentEdit"
            @submit="createQuestionAnswer"
          />
        </v-tab-item>
        <v-tab-item>
          <v-dialog v-model="editDialog" max-width="714">
            <v-card class="px-4">
              <edit-question-answer-card
                :value="currentEdit"
                mode="edit"
                @submit="updateQuestionAnswer"
                @delete="handleDelete"
                @cancel="editDialog = false"
              />
            </v-card>
          </v-dialog>

          <v-container fluid>
            <v-row>
              <v-col cols="12" md="6" class="d-flex align-center">
                <v-text-field
                  v-model="inputSearch"
                  hide-details
                  outlined
                  prepend-inner-icon="mdi-magnify"
                  placeholder="ex:植牙"
                  dense
                />
                <v-btn color="primary" class="ml-2" @click="handleSearch">確認篩選</v-btn>
              </v-col>
              <v-col cols="12" md="6">
                <v-data-footer
                  :options.sync="options"
                  :pagination="pagination"
                  :items-per-page-options="[10, 20, 50]"
                />
              </v-col>
            </v-row>
          </v-container>

          <v-progress-linear :active="loading" indeterminate/>
          <v-list>
            <v-list-item
              v-for="(qa, idx) in questions"
              :key="qa.id"
              :class="{'grey-8': idx % 2 === 0}"
            >
              <v-list-item-content>
                <v-row dense>
                  <v-col cols="10">
                    <div class="py-1">問題：{{ qa.question }}</div>
                    <div class="py-1">回答：{{ qa.answer }}</div>
                    <div class="py-1">最後更新時間：{{ qa.updated_at }}</div>
                  </v-col>
                  <v-col cols="2" class="align-center justify-center text-center d-flex flex-wrap" style="column-gap: 12px; row-gap: 6px;">
                    <v-btn icon @click="handleEdit(qa)">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn icon @click="handleDelete($event, qa)">
                      <v-icon>mdi-trash-can</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-tab-item>
      </v-tabs-items>
    </div>

    <v-dialog v-model="deleteDialog" max-width="fit-content">
      <v-card width="fit-content" class="pa-4">
        <v-card-title class="justify-center text-center">
          <div>
            <div>請再次確認您是否要刪除此筆問答</div>
            <div class="red-6--text">（刪除後無法再恢復，請再次確認）</div>
          </div>
        </v-card-title>
        <v-card-actions class="mt-2">
          <v-btn
            color="grey-4"
            dark
            width="45%"
            @click="deleteDialog = false"
          >取消刪除
          </v-btn>
          <v-spacer/>
          <v-btn
            color="primary"
            width="45%"
            @click="deleteQa"
          >確認刪除
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar"
      centered
      timeout="2000"
      color="tiffany-10"
    >
      {{ snackbarTitle }}
    </v-snackbar>
  </v-card>
</template>

<script>
import EditQuestionAnswerCard from './EditQuestionAnswerCard';
import {
  createAIQuestionAnswerAPI,
  deleteAIQuestionAnswerAPI,
  listAIQuestionAnswerAPI,
  updateAIQuestionAnswerAPI
} from '../../api';

export default {
  name: "AIQuestionAnswer",

  props: {
    medicalCode: {
      type: String
    }
  },

  components: {EditQuestionAnswerCard},

  data() {
    return {
      tab: null,
      editDialog: false,
      deleteDialog: false,

      loading:false,
      questions: [],
      options: {
        page: 1,
        itemsPerPage: 10
      },
      totalLength: 0,

      inputSearch: null,
      searchName: null,

      currentEdit: {
        question: null,
        answer: null
      },

      snackbar: false,
      snackbarTitle: null
    }
  },

  computed: {
    pagination() {
      const pageStart = (this.options.page - 1) * this.options.itemsPerPage
      const pageStop = Math.min(this.options.page * this.options.itemsPerPage, this.totalLength)

      return {
        itemsLength: this.totalLength,
        page: this.options.page,
        pageStart: pageStart,
        pageStop: pageStop,
        itemsPerPage: this.options.itemsPerPage
      }
    }
  },

  watch: {
    options: {
      handler() {
        this.getAllQuestions()
      },
      deep: true,
    },
  },

  methods: {
    handleSearch() {
      this.searchName = this.inputSearch
      if (this.options.page === 1)
        this.getAllQuestions()
      else
        this.options.page = 1
    },
    getAllQuestions() {
      this.loading = true
      const {page, itemsPerPage} = this.options
      const options = {
        page: page,
        query: this.searchName,
        per_page: itemsPerPage
      }

      listAIQuestionAnswerAPI(this.medicalCode, options).then(res => {
        this.questions = res.data.data.data
        this.totalLength = res.data.data.meta.total
      }).finally(() => {
        this.loading = false
      })
    },
    createQuestionAnswer(data) {
      createAIQuestionAnswerAPI(this.medicalCode, data).then(() => {
        this.currentEdit = this.$options.data().currentEdit
        this.snackbarTitle = '新增成功'
        this.snackbar = true
      })
    },
    updateQuestionAnswer(data) {
      updateAIQuestionAnswerAPI(this.currentEdit.id, data).then(() => {
        this.getAllQuestions()
        this.currentEdit = this.$options.data().currentEdit
        this.editDialog = false

        this.snackbarTitle = '更新成功'
        this.snackbar = true
      })
    },
    handleEdit(qa) {
      this.currentEdit = JSON.parse(JSON.stringify(qa))
      this.editDialog = true
    },
    handleDelete(event, qa) {
      this.currentEdit = JSON.parse(JSON.stringify(qa))
      this.deleteDialog = true
    },
    deleteQa() {
      deleteAIQuestionAnswerAPI(this.currentEdit.id).then(() => {
        this.getAllQuestions()
        this.deleteDialog = false
        this.editDialog = false

        this.snackbarTitle = '刪除成功'
        this.snackbar = true
      })
    },
    resetEdit() {
      this.currentEdit = this.$options.data().currentEdit
    },
    resetPage() {
      this.tab = null
      this.options = this.$options.data().options
      this.searchName = this.inputSearch = null
    }
  }
}
</script>

<style scoped>

</style>