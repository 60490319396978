export default {
  GENERAL: {
    oops: 'Oops..伺服器出現錯誤，請稍後再試一次',
    save: '儲存',
    cancel: '取消',
    edit: '編輯',
    fieldRequired: '此欄位必填',
    to: '至',
  },
  LOGIN: {
    title: '智慧管理後台',
    description: '最完善的CRM系統，最人性化的LINE互動機器人',
    doctorLogin: '醫師登入',
    clinicLogin: '診所登入',
    login: '登入',
    account: '帳號',
    password: '密碼',
    inputAccount: '請輸入帳號',
    phone: '手機號碼',
    inputPhone: '請輸入手機號碼',
    inputPassword: '請輸入密碼',
    forgetPassword: '忘記密碼？我來幫你找！',
    registerDoctor: '新朋友加入註冊',
    CS: '我想找客服',
  },
  ClinicReserveTabs: {
    allDoctor: '全部醫師',
    removeDoctor: '移除醫師',
    manageDoctor: '醫師管理'
  },
  ReserveTabs: {
    allClinic: '全部診所',
    addRemoveClinic: '新增/移除診所',
    searchClinic: '搜尋診所',
    confirmAdd: '確認新增',
    manageClinic: '診所管理'
  },
  RegisterDoctorForm: {
    doctorRegister: '醫師註冊',
    doctorName: '醫師姓名',
    inputDoctorName: '請輸入醫師姓名',
    inputEmail: '請輸入Email',
    inputEmailFormat: '請輸入正確的Email格式',
    phone: '手機號碼',
    inputPhone: '請輸入手機號碼',
    inputPhoneFormat: '請輸入正確的手機格式',
    sendOtp: '發送驗證碼',
    otp: '驗證碼',
    inputOtp: '請輸入驗證碼',
    password: '設定密碼',
    inputPassword: '請設定密碼',
    confirmPassword: '確認密碼',
    inputConfirmPassword: '請再次輸入密碼',
    inputConfirmPassword2: '密碼不一致',
    clinicDoctor: '工作診所',
    clinicDoctorPH: '若搜尋不到可略過，可後續新增',
    clinicDoctorHint: '輸入診所名稱搜尋',
    gender: '性別',
    inputGender: '請選擇性別',
    confirm: '確認送出',
    female: '女',
    male: '男',
    success: '註冊成功!',
    suffixMessage: ' 為您的註冊驗證碼，請於驗證碼欄位輸入。',
    medicalCategory: '您的醫療類別',
    needSelectMedical: '請選擇您的醫療類別'
  },
  ForgetPassword: {
    forgetPassword: '忘記密碼',
    phone: '手機號碼',
    inputPhone: '請輸入手機號碼',
    inputPhoneFormat: '請輸入正確的手機格式',
    sendOtp: '發送驗證碼',
    otp: '驗證碼',
    inputOtp: '請輸入驗證碼',
    password: '設定密碼',
    inputPassword: '請設定密碼',
    confirmPassword: '確認密碼',
    inputConfirmPassword: '請再次輸入密碼',
    inputConfirmPassword2: '密碼不一致',
    confirm: '確認送出',
    success: '重設密碼成功！',
    suffixMessage: ' 為您重設密碼的驗證碼，請於驗證碼欄位輸入。',
  },
  ReserveCalendar: {
    showReschedule: '顯示改約',
    print: '列印',
    googleCalendar: '同步Google行事曆',
    modifyReserveTime: '確認修改約診時間？',
    ORIGReserveTime: '原看診時間:',
    newReserveTime: '新看診時間:',
    confirm: '確認',
    sendPatientRemind: '是否需要傳送以下改約訊息給病人？',
    sendRemind: '是，請傳送提醒',
    cancelSendRemind: '否，不需傳送',
    clinicChoosePrint: '選擇醫師、日期',
    doctorChoosePrint: '選擇診所、日期',
    chooseDoctor: '選擇醫師',
    chooseClinic: '選擇診所',
    date: '日期',
    allDoctor: '全部醫師',
    allClinic: '全部診所',
  },
  PrintReserve: {
    print: '列印',
    download: '下載',
    reserveList: '預約一覽表',
    printDate: '列印日期',
    printContent: '列印內容',
    reserveDate: '預約日期',
    reserveTime: '時間',
    duration: '需時(分)',
    doctor: '看診醫師',
    clinic: '看診診所',
    patientName: '病患姓名',
    gender: '性別',
    birthday: '生日(民國)',
    phone: '電話',
    attendStatus: '到診狀態',
    note: '備註',
    fileTitle: '約診列表',
  },
  ReserveForm: {
    normal: '一般約診',
    np: 'NP 約診',
    notAvailable: '設定「勿約」',
    noteReserve: '設定「備註用」',
    newPatientName: '請輸入新病人姓名',
    phone: '請輸入手機',
    ROCBirthday: '請輸入民國生日(ex: 650101 或 1010101)',
    reserveTime: '看診時間',
    duration: '需要時間(分鐘)',
    mustInsert: '此欄位必填',
    chooseDoctor: '選擇醫師',
    chooseClinic: '選擇診所',
    remindOther: '額外提醒',
    remindCash: '提醒攜帶金額',
    note: '診所內部備註',
    confirmChange: '確認修改',
    newReserve: '新增約診',
  },
  SearchPatient: {
    searchLabel: '搜尋病人姓名/手機/民國生日',
    mustInsert: '此欄位必填',
  },
  ReserveDetailCard: {
    editInfo: '查看完整患者資料',
    reserveInfo: '約診資訊',
    cancelEdit: '取消編輯',
    editReserve: '編輯約診',
    attendStatus: '到診狀態：',
    doctor: '看診醫師：',
    clinic: '看診診所：',
    reserveTime: '約診時間：',
    reserveEndTime: '預計結束時間：',
    remindOther: '額外提醒內容：',
    remindCash: '攜帶金額：',
    note: '診所內部備註：',
    null: '無',
    otherInfo: '其他資訊',
    closeReserveRecord: '關閉就診記錄',
    openReserveRecord: '查看就診記錄',
    keepAppointment: '赴約',
    alterAppointment: '改約',
    standUp: '爽約',
    reserveRecord: '就診記錄',
    deleteReserve: '刪除約診',
    bookNext: '預約下次',
    bookNextTitle: '預約回診',
    bind: '已綁定',
    setArrive: '設為到診',
    setStandOut: '設為爽約',
    setAlter: '設為改約',
  },
  Navigation: {
    reserveList: '約診狀態表',
    onlineReserveList: '線上約診',
    reschedule: '改約名單',
    patientList: '我的病人',
    freeTime: '線上預約設定',
    reviewList: '評價總覽',
    upload: '上傳專區',
    clinicInfo: '診所資訊設定',
    doctorInfo: '醫師資訊設定',
    analytics: '統計數據',
    adminSetting: 'LINE智能助理權限設定',
    message: '訊息發送',
    questions: '詢問留言板',
    doctor: '醫師',
    logout: '登出',
  },
  OnlineReserve: {
    unContact: '未聯繫',
    contact: '已聯繫',
    effect: '成效',
  },
  OnlineReserveAnalysis: {
    filterTitle: '線上預約數據月份篩選',
    confirm: '確認',
    reserveSource: '預約來源',
    NPProportion: 'NP病人比例',
    completeProportion: '完成預約比例',
    onlineReserveTrend: '線上預約數量趨勢圖',
    total: '總數量',
    website: '小幫手線上預約平台',
    otherWebsite: '醫師/診所自有官網',
    pageView: '瀏覽量',
    webPageView: '網頁瀏覽量',
    webPageViewTooltip: '網頁瀏覽量為您在小幫手線上預約平台生成的診所/醫師網頁之瀏覽數',
  },
  ReserveTable: {
    noUnContact: '沒有未聯繫的約診',
    noContact: '沒有已聯繫的約診',
    canAppointment: '此時段可預約',
    canNotAppointment: '此時段無法預約',
    additionalArrange: '已額外安排時間',
    completeConcat: '已完成病人聯繫',
    withdraw: '移回尚未處理',
    editCrmNote: '修改院內備註',
    ok: '確定',
    confirm: '確認',
    otherReason: '其他訊息內容',
    know: '我知道了',
    reasonTitle: '請選擇此時段無法預約的原因，並於下一步確認傳訊內容',
    patientName: '病患姓名',
    patientPhone: '病患手機',
    reserveDoctor: '預約醫師',
    bookTime: '偏好時間',
    treatment: '看診項目',
    addOnItem: '加購項目',
    note: '病患備註',
    showResult: '處理結果',
    source: '患者來源',
    createTime: '建立時間',
    crmNote: '院內備註',
    action: '功能',
    reserveClinic: '預約診所',
    updateTime: '處理時間',
    oldPatient: '舊病人',
    Sun: '日 | 星期日',
    Mon: '一 | 星期一',
    Tues: '二 | 星期二',
    Wed: '三 | 星期三',
    Thur: '四 | 星期四',
    Fri: '五 | 星期五',
    Sat: '六 | 星期六',
    morning: '上午',
    afternoon: '下午',
    night: '晚上',
    notSpecified: '不指定',
    sendAndComplete: '點擊確認，病人將收到以下簡訊或是LINE訊息，並將此筆約診移至已聯繫',
    completeWithoutSend: '此筆線上預約將移動至已聯繫，系統將不另外傳送訊息通知，請致電與病人確認其他時間。',
    remind: '記得與病人聯繫確認時間喔！',
    moveToComplete: '此筆約診已移動至已聯繫',
    pleaseCreateReserve: '請協助將病人約診訊息放上診所約診系統',
    pleaseCall: '已告知病人致電診所，建議主動致電接洽',
    notFoundError: '噢！找不到此筆線上預約',
    patientBirth: '出生日期',
    patientGender: '性別',
  },
  RescheduleDataTable: {
    noData: '目前沒有需要改約的約診',
    alterTime: '已更換時間',
    withdraw: '病人點擊錯誤',
    editNote: '修改改約備註',
    confirm: '確定',
    pleaseCallPatient: '請與病人聯繫改約',
    cancelWithoutConcat: '取消不需致電聯繫',
    sortByText: '排序方式',
    patientName: '病患姓名',
    patientPhone: '病患手機',
    reserveTime: '約診時間',
    reserveDoctor: '約診醫師',
    note: '備註內容',
    alterNote: '改約備註',
    updateTime: '點擊更改時間',
    alterReason: '改約原因',
    whetherCall: '是否聯繫',
    action: '功能',
    reserveClinic: '約診診所',
    confirmReschedule: '<div>此筆改約通知將刪除，記得跟病人聯繫，並<span class="red-6--text">建立新約診</span>喔！</div>',
    cancelReschedule: '系統將會提醒病人在原訂時間回診喔！',
  },
  Patients: {
    fastSearch: '快速搜尋',
    searchPlaceholder: '輸入（姓名 或 手機號碼 或 民國生日 ex.650101）',
    check: '查看',
    avatar: '大頭貼',
    patientName: '姓名',
    patientPhone: '手機',
    gender: '性別',
    birthday: '生日',
    bindStatus: '綁定狀態',
    personalInfo: '詳細資訊',
    search: '搜尋'
  },
  PatientCard: {
    patientPersonalInfo: '病患基本資料卡',
    personalInfo: '基本資料',
    lineBind: 'Line 小幫手綁定狀態',
    bind: '已綁定',
    notBind: '未綁定',
    name: '姓名',
    gender: '性別',
    phone: '手機',
    birthday: '民國生日',
    year: '年',
    month: '月',
    day: '日',
    reserveRecord: '就診記錄',
    bookReserve: '預約門診',
    female: '女',
    male: '男',
    dataError: '此病患不存在,或已被刪除',
  },
  store: {
    keepAppointment: '確認到診',
    alterAppointment: '改約',
    standUp: '爽約',
    undefined: '尚未確認',
  },
  FreeTime: {
    whetherSave: '是否需要儲存修改結果？',
    yes: '是',
    no: '否',
  },
  FreeTimeSetting: {
    settingOnlineReserve: '線上預約設定（請擇一）：',
    whetherOpenOnline: `是否要開放 <span
                  class="font-weight-bold text-decoration-underline">當日</span>/<span
                  class="font-weight-bold text-decoration-underline">隔日</span> 線上預約：`,
    openCurrentDay: `是，我要開放
                      <span class="font-weight-bold text-decoration-underline">當日</span>
                     線上預約`,
    openTomorrow: `是，我要開放
                    <span class="font-weight-bold text-decoration-underline">隔日</span>
                   線上預約`,
    reservationMode: '預約模式',
    smartMode: '排除已約診的時段',
    smartTooltip: '系統會綜合您開放的時段和已經存在的預約，將尚未預約的時段供民眾預約',
    freeTimeGapMode: '預約單位時間',
    freeTimeGapTooltip: '系統將顯示相對應的空檔時間，若為看診速度較快的醫師可選擇15分鐘為單位',
    thirtyMinutes: '30分鐘',
    fifteenMinutes: '15分鐘',
    week: '星期',
    freeTime: '空檔時段',
    date: '日期',
    everyWeek: '週期空檔',
    single: '單次空檔',
    settingWeek: '設定週期時段',
    settingSingle: '設定單次時段',
    viewSetting: '檢視全部時段',
    manualFreeTime: '手動空檔時段',
    manualSubTitle: '(自行設定開診的時間)',
    manualTrueBtn: '是，我要使用手動開啟空檔',
    manualFalseBtn: '否，我要繼續使用手動空檔時段',
    systemToManual: '原本狀態為 連動健保系統時間，您的線上預約已跟健保系統連動，\n系統已設定自動抓取沒有預約的時段顯示給民眾選取，\n確定要改為手動開啟空檔？',
    registerToManual: '原本狀態為 登記預約 ，您即將開啟手動空檔設定，NP將會從Google搜尋時與您預約，請確實開啟時段，並注意線上預約通知，如需增加助理接收通知，請洽醫師小幫手客服。',
    disableToManual: '原本狀態為 不開放線上預約 ，您即將開啟手動空檔設定，NP將會從Google搜尋時與您預約，請確實開啟時段，並注意線上預約通知，如需增加助理接收通知，請洽醫師小幫手客服。',
    systemFreeTime: '連動健保系統時間',
    systemSubTitle: '(請確認貴診所是否有合作串接，在健保系統若有約診，即會開放該時段給患者預約)',
    systemTrueBtn: '是，我已確認診所有合作串接',
    systemFalseBtn: '否，我要繼續聯動健保系統時間',
    toSystem: '您即將開啟 健保系統連動 設定，\n請確認診所與醫師小幫手有合作串接，\n若沒有將無法顯示任何可預約時段。',
    registerFreeTime: '開放登記',
    registerSubTitle: '(患者可看見您的資訊並登記預約)',
    registerTrueBtn: '是，我確認要預約登記',
    registerFalseBtn: '否，我要繼續使用登記預約',
    toRegister: '您即將開啟 線上預約登記，\n根據數據顯示，有時間供病人選擇時預約數量可增加3倍，\n請確認是否僅供登記。',
    disableFreeTime: '不開放線上預約',
    disableTrueBtn: '是，我確認不開放線上預約',
    disableFalseBtn: '否，我要維持不開放線上預約',
    manualToDisable: '您即將關閉 手動空檔時段\n醫師小幫手平台每月有數十萬位民眾在搜尋醫師，\n您將會錯失許多NP與您預約的機會，請確認是否關閉線上預約。',
    systemToDisable: '您即將關閉 連動健保系統時間\n醫師小幫手平台每月有數十萬位民眾在搜尋醫師，\n您將會錯失許多NP與您預約的機會，請確認是否關閉線上預約。',
    registerToDisable: '您即將關閉 登記預約\n醫師小幫手平台每月有數十萬位民眾在搜尋醫師，\n您將會錯失許多NP與您預約的機會，請確認是否關閉線上預約。',
  },
  Review: {
    reviewSetting: '評價機制設定：',
    googleFirst: 'Google 評價優先',
    dentcoFirst: '實名詳細評價優先',
    publicSetting: '評價公開設定：',
    reviewFilter: '評價篩選',
    doctor: '醫師',
    clinic: '診所',
    treatmentItem: '治療項目',
    order: '排序',
    reviewCount: '評論',
    allReview: '全部評價',
    reviewWithMessage: '附上評論',
    negativeReview: '負面評價',
    all: '全部',
    latest: '最新',
    gradDESC: '評分最高',
    gradASC: '評分最低',
    chooseDoctor: '-- 請選擇一位醫師 --',
    chooseClinic: '-- 請選擇診所 --',
    showAll: '全部顯示',
    hiddenNegative: '負評不公開',
    hiddenAll: '評價不公開',
    allDoctor: '所有醫師',
    allClinic: '所有診所',
  },
  ReviewDetailCard: {
    doctor: '看診醫師：',
    clinic: '看診診所：',
    treatment: '就診項目：',
    reviewMessage: '評論內容：',
    reply: '回覆',
    doctorReply: '醫師回覆：',
    alterReply: '修改回覆',
    submit: '送出',
    replyFromLine: '您的回覆內容將傳送LINE通知給病人，點擊確認送出。',
    confirm: '確認',
    pleaseInput: '請確實填寫回覆內容',
  },
  Upload: {
    upload: '上傳',
    chooseClinic: '-- 請選擇診所 --',
    success: '上傳成功',
    fileLargeError: '檔案錯誤或太大',
    chooseFile: '請選擇一個檔案',
  },
  ClinicSettings: {
    avatar: '診所頭像',
    uploadNewAvatar: '上傳新大頭貼',
    name: '診所名稱',
    address: '診所地址',
    city: '縣/市',
    area: '鄉/鎮/區',
    streetAddress: '街道地址',
    tel: '診所電話',
    telCode: '區碼',
    telNumber: '號碼',
    philosophy: '看診理念',
    fbLink: 'FB粉專連結',
    webLink: '診所網站鏈接',
    uploadFail: '上傳失敗',
    updateFail: '更新失敗',
  },
  ClinicOpening: {
    businessHours: '看診時間',
    updateFail: '更新失敗',
  },
  Opening: {
    morningBH: '上午診:',
    afternoonBH: '下午診:',
    nightBH: '晚診:',
    Mon: '週一',
    Tues: '週二',
    Wed: '週三',
    Thur: '週四',
    Fri: '週五',
    Sat: '週六',
    Sun: '週日',
    morning: '上午',
    afternoon: '下午',
    night: '晚上',
  },
  EditTime: {
    from: '從',
    to: '至',
  },
  CaseAlbum: {
    clinicCase: '診所環境',
    doctorCase: '衛教分享',
    hint: '*請注意上傳之照片若為案例，請確認是否經過病人書面同意，有心人士會利用衛生局的檢舉機制騷擾，如需了解更多請與客服聯繫。',
    uploadFail: '上傳失敗',
  },
  CropImage: {
    title: '上傳圖片並裁切',
    chooseImg: '選擇一張圖片',
    cropTool: '裁切工具',
    confirmUpload: '確認上傳',
    concatCS: '與客服聯繫',
    chooseImgFile: '請選擇圖片檔',
    alertError: '很抱歉，您的瀏覽器不支援裁切圖片，請點選確認直接上傳',
  },
  ClinicBanner: {
    banner: '診所封面',
    uploadFail: '上傳失敗',
  },
  DoctorSettings: {
    avatar: '醫師頭像',
    uploadNewAvatar: '上傳新大頭貼',
    name: '醫師姓名',
    birthday: '生日',
    year: '年',
    month: '月',
    day: '日',
    philosophy: '看診理念',
    education: '教育背景',
    add: '新增一筆',
    experience: '醫師資歷',
    addExperience: '新增資歷',
    fbLink: 'FB粉專連結',
    webLink: '醫師網站鏈接',
    treatments: '醫師治療項目',
    addTreatments: '新增治療項目',
    uploadFail: '上傳失敗',
    updateFail: '更新失敗',
    present: '至今',
  },
  Education: {
    schoolName: '校名',
    department: '科系',
    year: '年',
    present: '至今',
  },
  Analytics: {
    patientRecommend: '患者推薦',
    efficient: '看診效率',
    onlineReserveResult: '線上約診成效',
  },
  OnTimeAnalysis: {
    allDoctor: '所有醫師',
    latestMonthOnTimeRatio: '近一個月內患者準時比率',
    latestSixMonthLateRation: '近六個月內整體遲到比例',
    average: '地區平均',
    topTenLate: '近一個月內前十名遲到病人名單',
    noLateData: '太棒了！近一個月沒有遲到名單',
    clinicNoData: '此診所尚無資料',
    doctorNoData: '此醫師尚無資料',
    lateRation: '遲到比率',
    patientName: '患者姓名',
    patientPhone: '患者電話',
    lateCount: '遲到次數',
    totalLateTime: '遲到總分鐘數',
    latestMonthLate: '近一個月每人平均遲到分鐘數',
    minutes: '分鐘',
    latestMonthLateRatio: '近一個月遲到比率',
    onTime: '準時',
    standUp: '未到',
    late: '遲到',
  },
  lineInviteAnalysis: {
    bindRatio: '指定區間綁定率',
    bindRatioTooltip: '指定區間綁定率 = 日期區間內全院綁定人數 / (全院患者-沒有手機的患者)',
    bindRatio30: '近30日綁定率',
    bindRatio30Tooltip: '近30日綁定率 = 近30日內全院綁定人數 / (近30日內全院患者-沒有手機的患者)',
    inviteGrow: '推薦成長',
    inviteRanking: '推薦排名',
    noInvite: '暫無病患推薦',
    totalInvite: '累積推薦數',
    ranking: '排名',
    patientName: '患者姓名',
    recommendDoctor: '推薦醫師',
    recommendClinic: '推薦診所',
    recommendCount: '推薦人數',
  },
  Administrator: {
    addMember: '新增人員：',
    phone: '請輸入手機號碼',
    confirm: '確定',
    pleaseRegister: '請先加入並綁定',
    pleaseRegisterText: `此使用者的手機門號 <span class="text-decoration-underline font-weight-bold">尚未加入</span> 或 <span class="text-decoration-underline font-weight-bold">尚未綁定</span>。
          請傳送以下連結邀請該位使用者加入：`,
    pleaseRegisterText2: `或掃描 QR Code 加入LINE小幫手。

          加入後請 <span class="text-decoration-underline font-weight-bold">依照流程進行綁定</span>，綁定後再於此操作新增權限。`,
    IKnow: '我知道了',
    clinicPermission: '全院權限',
    clinicPermissionTooltip: '全院權限：可於LINE智能助理觀看全院的約診/評價/數據，並於每週日10:00接收全院當週的病人數據報表',
    assistantPermission: '助理權限',
    assistantPermissionTooltip: '助理權限：可於LINE智能助理觀看全院之約診，以及收到每位醫師的線上預約通知、改約通知、負評通知、術後關懷回報。若診所的 LINE 訊息量不足或簡訊點數不足，也會收到警示訊息。',
    doctorPermission: '醫師權限',
    clinicDoctorPermissionTooltip: '醫師權限：可於LINE智能助理觀看自己的約診/評價/數據，以及收到自己的線上預約通知、改約通知、負評通知、術後關懷回報。並於每週日10:00接收自己的當週病人數據報表。如該位醫師有特助協助處理，請登入醫師帳號額外設定。',
    doctorPermissionTooltip: '醫師權限：可於LINE智能助理觀看所有跑點診所之病人的約診/評價/數據，以及收到自己的線上預約通知、改約通知、負評通知、術後關懷回報。並於每週日10:00接收該位醫師的當週病人數據報表。',
    doctorAssistantTooltip: '助理權限：可於LINE智能助理觀看所有跑點診所約診，以及收到醫師的線上預約通知、改約通知、負評通知、術後關懷回報。若醫師的 LINE 訊息量不足或簡訊點數不足，也會收到警示訊息。',
    alreadyExistAssistant: '手機號碼已存在於助理權限的人員列表中',
    duplicateAssistant: '手機號碼和助理人員重複, 請先移除後, 再新增{label}的人員',
    alreadyExistClinic: '手機號碼已存在於全院權限中',
    duplicateClinic: '手機號碼已存在於全院權限中, 請先移除後, 再新增{label}的人員',
    alreadyExistDoctor: '手機號碼已存在於醫師權限中',
    duplicateDoctor: '手機號碼已存在於醫師權限中, 請先移除後, 再新增{label}的人員',
  },
  AdminCard: {
    newRole: '新增',
    ok: '確定',
    removeTitle: '您確定要將{name}移出{label}嗎？',
  },
  Voice: {
    twoDaysAgo: '兩天前 ',
    oneDayAgo: '前一天 ',
    currentDay: '當天早上 ',
  },
  Message: {
    autoTeachSetting: '自動化衛教諮詢設定',
    autoTeachLabel: '我要開啟就診前15分鐘自動化衛教諮詢',
    autoRemindSetting: '自動化提醒設定',
    twoDaysAgo: '兩天前 12:00',
    oneDayAgo: '前一天 12:00',
    currentDay: '當天早上 08:00',
    permissionDeny: '此功能僅開放自有LINE使用，請聯繫醫師小幫手客服詢問切換～',
    IKnow: '我知道了',
    remindRemind: '請注意，如全部未勾選，民眾將不會收到約診提醒，建議還是至少要勾選一個時間哦',
    startSend: '開始發送訊息',
    sendLog: '群發歷史記錄',
    remindLog: '約診提醒、評價邀請、手動發送紀錄',
    selectDayPrefix: '顯示',
    selectDaySuffix: '天內資料',
  },
  LogCard: {
    to: '傳送對象：',
  },
  SendStep: {
    filterTitle: '篩選發送對象',
    filterDesc: '粗略篩選(可略過)',
    filterDate: '看診日期(可選區間)',
    filterSatisfaction: '曾給過好評的病患',
    filterDoctor: '約診醫師',
    selectAll: '全選',
    filterClinic: '看診診所',
    filterLatent: '潛在治療',
    filterName: '病患姓名',
    confirmFilter: '確認篩選',
    clearFilter: '清除篩選',
    countSelect: '選擇發送對象(已選擇： {count}人)',
    patientList: '病人名單',
    cancelSelect: '取消全選',
    searchName: '搜尋病人姓名..',
    showSelect: '已選清單',
    clearAll: '清除全部',
    confirmSelect: '確認選擇',
    editSelectContent: '編輯內容/選擇套板',
    confirmContent: '確定內容',
    confirmSend: '確認發送',
    countSend: '發送人數： {count}人',
    sendContent: '發送內容：',
    useActivity: '使用套版',
    otherContent: '額外傳送訊息：',
    picture: '圖片：',
    content: '想說的話：',
    confirm: '確定，我要送出訊息',
    alertMax: '篩選病人數大於500人，無法進一步篩選病人，將直接選取所有符合條件的對象(共{count}人)。',
    accept: '可以，我能接受',
    refuse: '不，我要重新篩選',
    alertOwnChannel: '由於LINE的政策調整，不再支援大型LINE帳號的群發，若要使用群發功能，請申請自有LINE帳號，醫師小幫手會協助將相關功能及已經加入醫師小幫手的病人轉移過去。',
    howApply: '如何申請自有LINE帳號',
    concatCS: '聯繫客服',
    success: '發送成功',
    IKnow: '我知道了',
    age: '歲',
    genderUnit: '性',
    pleaseChooseActivity: '請選擇想使用的套版',
    pleaseEditContent: '請上傳圖片或輸入想說的話',
    pleaseChoosePatient: '請選擇至少一位發送對象',
  },
  EditCard: {
    chooseMod: '選擇群發方式',
    stepOne: '1. 選擇套版',
    uploadPano: 'Pano 報告上傳',
    previewPano: 'pano報告預覽:',
    patientName: '病患名稱',
    patientAge: '病患年齡',
    patientPhone: '病患手機',
    panoPic: '上傳pano圖片',
    savePano: '儲存並準備發送',
    upload: '上傳報告',
    reUpload: '我要重新上傳報告',
    openUpload: '開啟上傳界面',
    seeIntro: '點我看介紹',
    stepTwo: '2. 額外想說的話(可省略)',
    uploadPic: '上傳想發送的圖片',
    content: '輸入想說的話',
    fieldRequired: '此欄位必填',
    useActivity: '使用套版群發',
    useCustom: '使用自定義群發',
  },
  SyncGoogleCalendar: {
    askTitle: '是否開啟同步將約診資料同步至 google 日曆？',
    hint: '開啟後將於60分鐘後開始生效。',
    turnOnSync: '開啟同步',
    turnOffSync: '關閉同步',
    ignore: '暫不設定',
    successTurnOff: '成功取消同步',
  },
  PostOperativeCare: {
    patientName: '病患名稱',
    patientPhone: '病患手機',
    reserveTime: '看診時間',
    reserveDoctor: '約診醫師',
    reserveClinic: '預約診所',
    reserveNote: '預約備註內容',
    reportBackTime: '病人回報時間',
    reportBack: '治療後症狀回報',
    needContact: '是否需要診所聯繫',
    reportBackNote: '關懷備註',
    processed: '已處理',
    unprocessed: '未處理',
    action: '功能',
  },
  CalendarList: {
    status: '狀態',
    reserveDate: '約診日期',
    reserveTime: '約診時間',
    reserveDoctor: '約診醫師',
    reserveClinic: '預約診所',
    patientName: '病患名稱',
    patientPhone: '病患手機',
    note: '診所內部備註',
    remindOther: '額外提醒',
    reserveTag: '約診標籤'
  }
}
