<template>
  <div>
    <v-data-table 
      :headers="headers" :items="items" item-key="id" :height="'100vh'"
      :no-data-text="''" :header-props="headerProps" :options.sync="options"
      :server-items-length="total" :footer-props="{ itemsPerPageOptions: [10, 20, 50] }"
    >
      <template v-slot:item.profile="{ item }">
        <div style="margin: 10px 0;">
          <img :src="item.profile" width="50" @error="defaultProfile">
        </div>
      </template>

      <template v-slot:item.created_at="{ item }">
        {{ $moment(item.created_at).format('YYYY-MM-DD HH:mm') }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { getOpenaiConversationAPI } from '../../api';

export default {
  name: "OpenaiConversationDataTable",
  components: {},
  props: {

  },
  data() {
    return {
      pageLoading: false,
      items: [],
      headerProps: {},
      options: {},
      total: 0,
    }
  },
  computed: {
    ...mapState({
      user: state => state.user,
    }),
    ...mapGetters([
      'axiosParamData',
      'doctorUser',
      'liffUser'
    ]),
    headers() {
      const headers = [
        { text: '大頭貼', align: 'center', value: 'profile', sortable: false, width: 100 },
        { text: '名稱', align: 'center', value: 'name', sortable: false, width: 100 },
        { text: '詢問內容', align: 'center', value: 'user_message', sortable: false, width: 200 },
        { text: '回覆內容', align: 'center', value: 'openai_message', sortable: false },
        { text: '時間', align: 'center', value: 'created_at', sortable: false, width: 150 },
      ]

      return headers;
    },
  },
  watch: {
    options: {
      handler() {
        this.getOpenaiConversationList()
      },
      deep: true,
    },
  },
  methods: {
    defaultProfile(e) {
      e.target.src = 'https://i.imgur.com/x1VX5zU.jpg';
    },
    getOpenaiConversationList() {
      this.pageLoading = true
      const { page, itemsPerPage } = this.options
      getOpenaiConversationAPI({ ...this.axiosParamData, limit: itemsPerPage, page }).then(res => {
        this.items = res.data.data
        this.total = parseInt(res.data.total)
        this.pageLoading = false
      })
    },
  }
}
</script>

<style scoped></style>