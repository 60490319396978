<template>
  <v-main>
    <navigation/>

    <v-container v-if="doctor && userInfo" class="my-8">
      <div class="grid">
        <!-- Doctor Info -->
        <div class="grid-column-1-2">
          <template v-if="hasAdvPermission">
            <v-card v-if="!isEditorDoctor" class="p-0 overflow-hidden" rounded="xl">
              <upload-image-dialog
                size="300"
                :aspect-ratio="1480 / 600"
                :max-file-size="bannerSize"
                color="white"
                placeholder="將圖片拖拉到這裡或選擇檔案，檔案大小限制2MB內，圖片尺寸為1480×600px"
                hide-detail
                img-class="rounded-t-xl"
                img-style="border-radius: 0px;width: 100%"
                :disabled="bannerLoading"
                @submit="uploadDoctorBanner"
              >
                <v-overlay :value="bannerLoading" absolute z-index="2">
                  <v-progress-circular indeterminate size="64"/>
                </v-overlay>
                <v-btn
                  class="text-subtitle-2 rounded-lg"
                  dark
                  style="
                  width: 150px;
                  height: 40px;
                  position: absolute;
                  top: 10px;
                  right: 30px;
                  z-index: 1;
                  background: #2727277e;
                "
                >
                  <v-icon dark size="20" class="mr-2">
                    mdi-camera
                  </v-icon>
                  上傳圖片
                </v-btn>
                <v-img height="300" :src="doctor.banner"/>
              </upload-image-dialog>
              <div style="padding: 20px;">
                <div class="d-flex justify-space-between align-center">
                  <div class="d-flex">
                    <div
                      class="doctor-image text-center px-5 py-5"
                      style="position: relative; width: 200px;"
                    >
                      <v-avatar
                        size="200"
                        style="position: absolute; bottom: 0; left: -2px;"
                      >
                        <img
                          :src="doctor.profile"
                          alt=""
                        >
                      </v-avatar>
                    </div>

                    <v-btn
                      v-if="!!doctor.line_page"
                      class="px-10 ml-4"
                      color="green-6"
                      dark
                      :href="doctor.line_page"
                      target="_blank"
                    >
                      <v-icon class="px-1" color="white" size="20">
                        $line
                      </v-icon>
                      加入LINE好友
                    </v-btn>
                  </div>
                  <v-btn
                    color="teal lighten-2"
                    class="text-subtitle-2 rounded-lg"
                    dark
                    style="width: 100px; height: 40px"
                    @click="isEditorDoctor = true"
                  >
                    <v-icon dark size="15" class="mr-2">
                      mdi-pencil
                    </v-icon>
                    編輯
                  </v-btn>
                </div>
              </div>
              <v-row no-gutters :class="{'flex-nowrap': $vuetify.breakpoint.lgAndUp}" style="padding: 10px 30px 50px 30px; row-gap: 16px;">
                <v-col cols="12" md="8">
                  <div class="d-flex justify-space-between align-center mb-5">
                    <div
                      class="d-flex align-center flex-wrap"
                      style="gap: 0.5rem"
                    >
                      <div class="d-flex align-center">
                        <v-icon color="teal lighten-2" size="30">
                          mdi-check-circle
                        </v-icon>
                        <h4 class="text-h4 font-weight-bold text-no-wrap">
                          {{ doctor.alias || doctor.doctor_name }}
                        </h4>
                      </div>
                    </div>
                  </div>
                  <p class="subtitle-1" style="color: #3d3d3d">
                    <v-icon size="16">
                      mdi-map-marker-outline
                    </v-icon>
                    服務區域： {{ doctor.service_area?.join(",") }}
                  </p>
                  <div class="d-flex flex-wrap" style="gap: 1rem">
                    <div
                      v-for="clinic in doctor.clinics"
                      :key="'clinic_' + clinic.clinic_name"
                      class="text-no-wrap"
                    >
                      <v-icon :color="clinic.color" size="10" class="mx-1">
                        mdi-circle
                      </v-icon>
                      <a
                        class="subtitle-1 text-decoration-underline"
                        style="
                        color: #3d3d3d;
                        vertical-align: top;
                        text-underline-offset: 0.2em;
                      "
                      >
                        {{ clinic.clinic_name }}
                      </a>
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" md="auto" class="ml-lg-auto" :style="{'min-width': $vuetify.breakpoint.lgAndUp ? '240px' : 'unset'}">
                  <v-list
                    dense
                    min-height="20"
                  >
                    <v-list-item
                      v-for="(link, idx) in doctor.social_item_structure"
                      v-if="link.url && link.label !== 'LINE'"
                      :key="idx"
                      class="d-flex align-center mb-2"
                      style="min-height: fit-content; gap: 0.3rem"
                    >
                      <v-icon color="black" size="16">
                        {{ getSocialIcon(link.label) }}
                      </v-icon>
                      <v-list-item-content class="py-0">
                        <a
                          :href="link.url"
                          class="black--text text-body-2 text-decoration-underline px-0 text-truncate"
                          target="_blank"
                        >
                          {{ link.name || link.label }}
                        </a>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </v-card>
          </template>
          <template v-else>
            <v-card v-if="!isEditorDoctor" class="p-0" rounded="xl">
              <div class="d-flex" style="padding: 30px;">
                <div class="doctor-image text-center" style="width: 150px">
                  <v-avatar size="100">
                    <img
                      :src="doctor.profile"
                      alt=""
                    >
                  </v-avatar>
                </div>
                <div class="flex-grow-1">
                  <div class="d-flex justify-space-between mb-2" :class="{'flex-wrap': $vuetify.breakpoint.xsOnly}">
                    <div class="text-wrap d-flex flex-wrap" style="gap: 1rem">
                      <h4 class="text-h4 font-weight-bold text-no-wrap">
                        {{ doctor.alias || doctor.doctor_name }}
                      </h4>
                    </div>
                    <v-btn
                      color="teal lighten-2"
                      class="text-subtitle-2 rounded-lg ml-auto"
                      dark
                      style="width: 100px; height: 40px;"
                      :style="{'order': $vuetify.breakpoint.xsOnly ? '-1' : 'unset'}"
                      @click="isEditorDoctor = true"
                    >
                      <v-icon dark size="15" class="mr-2">
                        mdi-pencil
                      </v-icon>
                      編輯
                    </v-btn>
                  </div>
                  <p class="subtitle-1" style="color: #3d3d3d">
                    <v-icon size="16">
                      mdi-map-marker-outline
                    </v-icon>
                    服務區域： {{ doctor.service_area?.join(",") }}
                  </p>
                  <div class="d-flex flex-wrap" style="gap: 1rem">
                    <div
                      v-for="clinic in doctor.clinics"
                      :key="'clinic_' + clinic.clinic_name"
                      class="text-no-wrap"
                    >
                      <v-icon :color="clinic.color" size="10" class="mx-1">
                        mdi-circle
                      </v-icon>
                      <a
                        class="subtitle-1 text-decoration-underline"
                        style="
                        color: #3d3d3d;
                        vertical-align: top;
                        text-underline-offset: 0.2em;
                      "
                      >
                        {{ clinic.clinic_name }}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </v-card>
          </template>
          <doctor-form
            v-if="isEditorDoctor && userInfo"
            :show.sync="isEditorDoctor"
            :doctor="doctor"
            :hasAdvPermission="hasAdvPermission"
            :hasGuideLinePermission="hasGuideLinePermission"
            :avatarLoading="avatarLoading"
            @update="updateDoctorInfo"
            @uploadDoctorAvatar="uploadDoctorAvatar"
          />
        </div>

        <!-- Doctor Schedule -->
        <div class="grid-column-2-3 row-span-3">
          <doctor-schedule :doctor="doctor" @update="updateSchedule" />
        </div>

        <!-- Doctor Awards -->
        <div v-if="hasAdvPermission" class="grid-column-1-2">
          <doctor-honor-badge :hasPermission="hasAdvPermission"/>
        </div>

        <!-- Treatment Section -->
        <div class="grid-column-1-2">
          <!-- Treatment Section -->
          <hash-tag-card
            v-if="!isTreatmentSection"
            :hashtagItems="doctor.hashtag_item_structure"
            :has-permission="hasAdvPermission"
            :dentcoIcons="dentcoIcons"
            @edit="isTreatmentSection = true"
          />
          <treatment-section-adv-form
            v-if="hasAdvPermission && isTreatmentSection"
            :show.sync="isTreatmentSection"
            :doctor="doctor"
            :dentcoIcons="dentcoIcons"
            @update="updateHashTag"
          />
          <treatment-section-free-form
            v-if="!hasAdvPermission && isTreatmentSection"
            :show.sync="isTreatmentSection"
            :doctor="doctor"
            @update="updateHashTag"
          />
        </div>

        <!-- Medical Qualifications Section -->
        <div class="grid-column-1-2">
          <v-row>
            <v-col cols="12" sm="6">
              <!-- Medical Qualifications Section -->
              <v-card v-if="!isMedicalQualifications" class="p-0" rounded="xl">
                <div style="padding: 20px 30px">
                  <div class="d-flex justify-space-between align-center mb-2">
                    <h6 class="text-subtitle-1 font-weight-bold">
                      醫師資歷
                    </h6>
                    <v-btn
                      color="teal lighten-2"
                      class="text-subtitle-2 rounded-lg"
                      dark
                      style="width: 100px; height: 40px"
                      @click="isMedicalQualifications = true"
                    >
                      <v-icon dark size="15" class="mr-2">
                        mdi-pencil
                      </v-icon>
                      編輯
                    </v-btn>
                  </div>
                  <v-list dense class="overflow-auto" style="height: 160px">
                    <v-list-item
                      v-for="(experience, index) in doctor.qualification?.split(',')"
                      :key="index"
                      class="pa-0"
                    >
                      <v-list-item-content>
                        <v-list-item-title
                          class="text-body-1 grey--text text--darken-2 text-wrap"
                          style="line-height: 1.2rem;"
                        >
                          {{ experience }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </div>
              </v-card>
              <medical-qualifications-form
                v-if="isMedicalQualifications"
                :show.sync="isMedicalQualifications"
                :doctor="doctor"
                @update="updateQualifications"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <!-- Educational Background Section -->
              <v-card v-if="!isEducationalBackground" class="p-0" rounded="xl">
                <div style="padding: 20px 30px">
                  <div class="d-flex justify-space-between align-center mb-2">
                    <h6 class="text-subtitle-1 font-weight-bold align-center">
                      教育背景
                    </h6>
                    <v-btn
                      color="teal lighten-2"
                      class="text-subtitle-2 rounded-lg"
                      dark
                      style="width: 100px; height: 40px"
                      @click="isEducationalBackground = true"
                    >
                      <v-icon dark size="15" class="mr-2">
                        mdi-pencil
                      </v-icon>
                      編輯
                    </v-btn>
                  </div>
                  <v-list dense class="overflow-auto" style="height: 160px">
                    <v-list-item
                      v-for="(education, index) in doctor.education"
                      :key="index"
                      class="pa-0"
                    >
                      <v-list-item-avatar size="60">
                        <v-img
                          :src="getEducationLogo(education.school)"
                          alt="education image"
                          class="rounded-full"
                          contain
                        />
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title
                          class="text-body-1 grey--text text--darken-2"
                        >
                          {{ education.name || education.school }} {{ education.partment }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </div>
              </v-card>
              <educational-background-form
                v-if="isEducationalBackground"
                :show.sync="isEducationalBackground"
                :doctor="doctor"
                @update="updateEducation"
              />
            </v-col>
          </v-row>
        </div>

        <div v-if="!hasAdvPermission" class="grid-column-1-2">
          <doctor-honor-badge/>
        </div>

        <!-- Clinic Concept Section -->
        <div class="grid-column-1-2">
          <v-card v-if="!isClinicConcept" class="p-0" rounded="xl">
            <paid-feature-notice :has-permission="hasAdvPermission"/>
            <div style="padding: 20px 30px 0px 30px">
              <div class="d-flex justify-space-between align-center mb-2">
                <h6
                  class="text-subtitle-1 font-weight-bold"
                  style="height: 40px"
                >
                  看診理念
                </h6>
                <v-btn
                  v-if="hasAdvPermission"
                  color="teal lighten-2"
                  class="text-subtitle-2 rounded-lg"
                  dark
                  style="width: 100px; height: 40px"
                  @click="isClinicConcept = true"
                >
                  <v-icon dark size="15" class="mr-2">
                    mdi-pencil
                  </v-icon>
                  編輯
                </v-btn>
              </div>
            </div>
            <div style="padding: 0px 30px 20px 30px; position: relative">
              <div
                class="d-flex justify-center align-center text-center text-h5 font-weight-bold grey--text text--darken-2 text-pre-line"
                style="min-height: 150px"
                v-text="doctor.principle"
              />
            </div>
          </v-card>
          <clinic-concept-form
            v-if="isClinicConcept"
            :show.sync="isClinicConcept"
            :doctor="doctor"
            @update="updatePrinciple"
          />
        </div>

        <!-- Health Education Sharing Section -->
        <div class="grid-column-1-2">
          <health-education-sharing :has-permission="hasAdvPermission"/>
        </div>
      </div>
    </v-container>
  </v-main>
</template>

<script>
import Navigation from "@/components/Navigation.vue";
import UploadImageDialog from "@/components/utils/UploadImageDialog.vue";
import DoctorForm from "@/components/settings/doctor/v2/DoctorForm.vue";
import DoctorSchedule from "@/components/settings/doctor/v2/DoctorSchedule.vue";
import HealthCategoryDialog from "@/components/settings/doctor/v2/HealthCategoryDialog.vue";
import EditorHealthImageDialog from "@/components/settings/doctor/v2/EditorHealthImageDialog.vue";
import TreatmentSectionAdvForm from "@/components/settings/doctor/v2/TreatmentSectionAdvForm.vue";
import MedicalQualificationsForm from "@/components/settings/doctor/v2/MedicalQualificationsForm.vue";
import EducationalBackgroundForm from "@/components/settings/doctor/v2/EducationalBackgroundForm.vue";
import ClinicConceptForm from "@/components/settings/doctor/v2/ClinicConceptForm.vue";
import HashTagCard from "@/components/settings/doctor/v2/adv/HashTagCard.vue";
import TreatmentSectionFreeForm from "@/components/settings/doctor/v2/TreatmentSectionFreeForm.vue";
import HealthEducationSharing from "@/components/settings/doctor/v2/HealthEducationSharing.vue";
import DoctorHonorBadge from "@/components/settings/doctor/v2/DoctorHonorBadge.vue";
import PaidFeatureNotice from "@/components/settings/doctor/v2/PaidFeatureNotice.vue";
import { mapGetters } from "vuex";
import {
  getDentcoIconsAPI,
  getDoctorInfoAPI, getInfoAPI,
  updateDoctorClinicAPI,
  updateDoctorInfoAPI
} from "@/api";
import { checkPermissions, getSocialIcon, transformSchedule } from "@/utils/tool";
import { educationItems } from "@/utils/sharedData";

export default {
  name: "DoctorSettingV2",

  components: {
    PaidFeatureNotice,
    DoctorHonorBadge,
    HealthEducationSharing,
    TreatmentSectionFreeForm,
    HashTagCard,
    ClinicConceptForm,
    EducationalBackgroundForm,
    MedicalQualificationsForm,
    TreatmentSectionAdvForm,
    EditorHealthImageDialog,
    HealthCategoryDialog,
    DoctorSchedule,
    DoctorForm,
    UploadImageDialog,
    Navigation
  },

  data() {
    return {
      userInfo: null,
      bannerSize: 2 * 1024 * 1024,
      doctor: {},
      bannerLoading: false,
      avatarLoading: false,
      dentcoIcons: [],
      educationItems,

      isEditorDoctor: false,
      isMedicalQualifications: false,
      isEducationalBackground: false,
      isClinicConcept: false,
      isTreatmentSection: false,
      healthImages: [],
    };
  },

  computed: {
    ...mapGetters([
      'axiosParamData',
    ]),
    hasAdvPermission() {
      return checkPermissions({obj: this.userInfo, type: 'doctor', permissions: ['adv_page']})
    },
    hasGuideLinePermission() {
      return checkPermissions({obj: this.userInfo, type: 'doctor', permissions: ['guide_to_line']})
    }
  },

  mounted() {
    this.getPermissionsInfo()
    this.getDoctor()
    this.getDentcoIcons()
  },

  methods: {
    getSocialIcon,
    transformSchedule,
    getEducationLogo(edu) {
      const education = this.educationItems.find(item => item.name === edu)
      return education?.logo || 'https://dentco.tw/images/school/其他.png'
    },
    async getDoctor() {
      try {
        const response = await getDoctorInfoAPI(this.axiosParamData);
        const formattedClinics = response.data.clinics.map(clinic => ({
          ...clinic,
          schedule: this.transformSchedule(clinic.doctor_open_date)
        }));
        this.doctor = { ...response.data, clinics: formattedClinics };
      } catch (error) {
        console.error('Error fetching doctor data:', error);
      }
    },
    uploadDoctorBanner(file) {
      this.bannerLoading = true;
      const data = {
        ...this.axiosParamData
      }
      const formData = new FormData()
      for (const [key, value] of Object.entries(data)) {
        formData.append(key, value)
      }
      formData.append('banner', file)
      updateDoctorInfoAPI(formData).then(res => {
        console.log(res)
      }).catch(() => {
        alert('上傳失敗')
      }).finally(() => {
        this.getDoctor()
        this.bannerLoading = false;
      })
    },
    async uploadDoctorAvatar(file) {
      try {
        this.avatarLoading = true;
        const formData = new FormData();
        for (const [key, value] of Object.entries(this.axiosParamData)) {
          formData.append(key, value);
        }
        formData.append('profile', file);
        await updateDoctorInfoAPI(formData);
        await this.getDoctor();
      } catch (error) {
        alert('Upload failed');
      } finally {
        setTimeout(() => {
          this.avatarLoading = false;
        }, 500);
      }
    },
    async updateDoctorInfo(payload) {
      const { data, callback } = payload;
      const { alias, email, social_item_structure, clinics } = data;

      try {
        const [res1, res2] = await Promise.all([
          updateDoctorInfoAPI({
            alias,
            email,
            social_item_structure,
            ...this.axiosParamData
          }),
          updateDoctorClinicAPI({
            clinics,
            ...this.axiosParamData
          }),
        ]);

        if (callback && typeof callback === 'function') {
          callback('success');
        }
      } catch (error) {
        if (callback && typeof callback === 'function') {
          callback('error');
        }
        console.error(error);
      } finally {
        this.getDoctor();
      }
    },

    async handleUpdateAPI({ data, apiMethod, callback }) {
      try {
        await apiMethod({
          ...data,
          ...this.axiosParamData
        });
        if (callback && typeof callback === 'function') {
          callback('success');
        }
      } catch (error) {
        if (callback && typeof callback === 'function') {
          callback('error');
        }
        console.error(error);
      } finally {
        this.getDoctor();
      }
    },
    async updateHashTag(payload) {
      const { hashtags, callback } = payload;
      await this.handleUpdateAPI({
        data: {hashtag_item_structure: hashtags},
        apiMethod: updateDoctorInfoAPI,
        callback,
      });
    },
    async updateSchedule(payload) {
      const { clinics, callback } = payload;
      await this.handleUpdateAPI({
        data: {clinics},
        apiMethod: updateDoctorInfoAPI,
        callback,
      });
    },
    async updateQualifications(payload) {
      const { qualification, callback } = payload;
      await this.handleUpdateAPI({
        data: {qualification},
        apiMethod: updateDoctorInfoAPI,
        callback,
      });
    },
    async updateEducation(payload) {
      const { education, callback } = payload;
      await this.handleUpdateAPI({
        data: {education},
        apiMethod: updateDoctorInfoAPI,
        callback,
      })
    },
    async updatePrinciple(payload) {
      const { principle, callback } = payload;
      await this.handleUpdateAPI({
        data: {principle},
        apiMethod: updateDoctorInfoAPI,
        callback,
      })
    },

    getDentcoIcons() {
      getDentcoIconsAPI(this.axiosParamData).then(res => {
        this.dentcoIcons = res.data
      })
    },
    getPermissionsInfo() {
      getInfoAPI(this.axiosParamData).then(res => {
        this.userInfo = res.data
      })
    },
  }
}
</script>

<style scoped lang="scss">
.grid {
  display: grid;
  gap: 2rem;
  grid-template-columns: 100%;
}

.grid-column-1-2,
.grid-column-2-3 {
  grid-column: 1 / -1;
}
.row-span-3 {
  grid-row: span 3;
}

@media (min-width: 768px) {
  .grid {
    grid-template-columns: calc(100% - 382px) 350px;
  }
  .grid-column-1-2 {
    grid-column: 1 / 2; /* 等價於 md:col-start-1 md:col-end-2 */
  }
  .grid-column-2-3 {
    grid-column: 2 / 3; /* 等價於 md:col-start-2 md:col-end-3 */
  }
}
</style>