<template>
  <v-main>
    <navigation/>

    <v-tabs class="elevation-2">
      <v-tab class="px-md-12" :to="{name: 'freeTimeSetting'}">
        線上預約設定
      </v-tab>
      <v-tab class="px-md-12" :to="{name: 'treatmentSetting'}">
        治療項目設定
      </v-tab>
    </v-tabs>

    <div style="width: 100%;" class="overflow-auto">
      <router-view></router-view>
    </div>
  </v-main>
</template>

<script>
import Navigation from "@/components/Navigation.vue";

export default {
  name: "OnlineAppointmentsSettingIndex",

  components: {Navigation}
}
</script>

<style scoped>

</style>