<template>
  <v-card flat max-width="1280" class="pl-md-6">
    <guide-tour config-key="message.store">
      <div>
        ∙ 以下為現有點數額度和儲值紀錄，NT$1等同1點，可同時用於簡訊發送和語音發送
      </div>
      <div>
        ∙ 簡訊發送一則為 NT$1 (1點)，語音發送一通為 NT$3 (3點)
      </div>
      <div>
        ∙ 語音訊息撥出後，患者有接通才會扣除點數
      </div>
      <div>
        ∙ 若要新增額度，歡迎點擊按鈕聯繫客服儲值
      </div>
    </guide-tour>

    <div class="px-4 mt-4">
      <div>
        <dentco-alert-bar
          :message="ownChannel ? `診所可使用點數額度：${quota} 點` : '此功能僅開放自有LINE使用，請聯繫牙醫小幫手客服詢問切換'"
          :btn-link="$enum.DENTCO_CS_LINE"
          btn-title="聯繫牙醫小幫手儲值"
        />
      </div>
      <div v-if="ownChannel" class="mt-8 pb-4">
        <div class="mt-2">
          <v-data-table
            :headers="headers"
            :items="logData"
            :footer-props="{itemsPerPageOptions: [10, 20, 50]}"
            fixed-header
            height="calc(100vh - 320px)"
          >
            <template v-slot:item.quota="{ item }">
              {{ item.quota }} 點
            </template>
          </v-data-table>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import { getMessageSettingAPI, getSmsQuotaAPI, getSmsStoreLogsAPI } from '../../api';
import { mapGetters } from 'vuex';
import DentcoAlertBar from "../../components/utils/DentcoAlertBar.vue";
import GuideTour from "../../components/utils/GuideTour.vue";

export default {
  name: "SmsRefillLog",

  components: {GuideTour, DentcoAlertBar},

  data() {
    return {
      logData: [],
      quota: 0,
      ownChannel: false
    }
  },

  computed: {
    ...mapGetters([
      'axiosParamData',
    ]),
    headers() {
      return [
        {text: '儲值日期', align: 'center', value: 'create_time', sortable: true},
        {text: '儲值點數', align: 'center', value: 'quota', sortable: false},
        {text: '儲值類別', align: 'center', value: 'type', sortable: false},
      ]
    }
  },

  methods: {
    getLog() {
      getSmsStoreLogsAPI(this.axiosParamData).then(res => {
        this.logData = res.data
      })
    },

    getQuota() {
      getSmsQuotaAPI(this.axiosParamData).then(res => {
        this.quota = res.data.quota
      })
    }
  },

  mounted() {
    this.getLog()
    this.getQuota()

    getMessageSettingAPI(this.axiosParamData).then(res => {
      this.ownChannel = res.data.own_channel
    })
  }
}
</script>

<style scoped>

</style>
