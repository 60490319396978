<template>
  <plus-clinic-settings v-if="clinicUser" ref="clinicSetting"/>
  <doctor-setting-v2 v-else/>
<!--  <doctor-settings v-else/>-->
</template>

<script>
import { mapGetters } from "vuex";
import DoctorSettings from "./DoctorSettings";
import PlusClinicSettings from './PlusClinicSettings';
import DoctorSettingV2 from "@/pages/settings/DoctorSettingV2.vue";

export default {
  name: "index",
  components: {DoctorSettingV2, PlusClinicSettings, DoctorSettings},
  computed: {
    ...mapGetters([
      'clinicUser'
    ]),
  },
  beforeRouteLeave (to, from, next) {
    if (this.$refs.clinicSetting && this.$refs.clinicSetting.isEdit) {
      this.$refs.clinicSetting.showNotSaveAlert()
      next(false)
    } else {
      next()
    }
  },
}
</script>

<style scoped>

</style>