import liff from "@line/liff";
import moment from 'moment';
import { bindStatus } from '../config/bindConfig';
import i18n from "../i18n";
import store from "../store";

export function generateArrayOfYears(n, pre = null) {
  const currentYear = (new Date()).getFullYear();
  const range = (start, stop, step) => Array.from({length: (stop - start) / step + 1}, (_, i) => `${start + (i * step)}`);
  const res = range(currentYear, currentYear - n, -1)

  if (pre) {
    res.unshift(pre)
    return res
  }
  return res
}

export function generateDateArrayFromNow(days) {
  const taiwanTimeZone = 'Asia/Taipei';
  let now = new Date().toLocaleString('en-US', {timeZone: taiwanTimeZone});
  let taiwanTime = new Date(now);

  return Array.from({length: days}, (v, i) => {
    let date = new Date(taiwanTime);
    date.setDate(date.getDate() + i);

    let dateString = date.toLocaleString('en-US', {
      timeZone: taiwanTimeZone,
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    }).split(',')[0];
    let parts = dateString.split('/');
    dateString = `${parts[2]}-${parts[0].padStart(2, '0')}-${parts[1].padStart(2, '0')}`;

    let weekday = date.getDay().toString();
    return {"date": dateString, "weekday": weekday};
  });
}

export function getDatesUntil(targetDate) {
  const timeZoneOffset = 8 * 60 * 60 * 1000; // 台灣時間與UTC的時差（8小時）
  const target = new Date(Date.UTC(
    parseInt(targetDate.slice(0, 4)),
    parseInt(targetDate.slice(5, 7)) - 1,
    parseInt(targetDate.slice(8, 10))
  ));
  const currentUTC = new Date(Date.now());
  const current = new Date(currentUTC.getTime() + timeZoneOffset);

  const result = [];
  let currentDate = new Date(Date.UTC(current.getUTCFullYear(), current.getUTCMonth(), current.getUTCDate()));

  while (currentDate <= target) {
    const formattedDate = currentDate.toISOString().split('T')[0];
    const weekday = currentDate.getUTCDay();
    result.push({ date: formattedDate, weekday });

    currentDate.setUTCDate(currentDate.getUTCDate() + 1);
  }

  return result;
}


export function loginLiff(liffId, next) {
  liff.init({liffId}).then(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const clinicCode = urlParams.get('clinic_code') || null
    const doctorCode = urlParams.get('doctor_code') || null
    const type = urlParams.get('type')
    if (type && (clinicCode || doctorCode))
      liff.getProfile().then(profile => {
        const userId = profile.userId
        store.commit('setUserData', {clinic_code: clinicCode, doctor_code: doctorCode, line_bot_id: userId, type})
        next()
      }).catch(() => {
        alert('無法取得您的 Line 帳號')
        liff.closeWindow()
      })
    else
      alert('網址參數錯誤')
  })
}

const hiddenProperty = 'hidden' in document ? 'hidden' :
  'webkitHidden' in document ? 'webkitHidden' :
    'mozHidden' in document ? 'mozHidden' :
      null
const visibilityChangeEvent = hiddenProperty.replace(/hidden/i, 'visibilitychange')

const onVisibilityChange = function () {
  if (!document[hiddenProperty]) {
    clearFlashTitle('醫師小幫手CRM系統')
    return true
  } else {
    return false
  }
}

export function checkUserExist() {

  document.removeEventListener(visibilityChangeEvent, onVisibilityChange, true)
  document.addEventListener(visibilityChangeEvent, onVisibilityChange, true)
  return onVisibilityChange()
}

let flashTitleTimer = null
let step = 0

export function flashTitle(flashContent, title) {
  clearTimeout(flashTitleTimer)
  step++
  if (step === 3) {
    step = 1
  }
  if (step === 1) {
    document.title = `(${flashContent})${title}`
  }
  if (step === 2) {
    document.title = title
  }
  flashTitleTimer = setTimeout(() => {
    flashTitle(flashContent, title)
  }, 380)
}

export function clearFlashTitle(title) {
  document.title = title
  clearTimeout(flashTitleTimer)
}

export function getBlockListIcon(reserve) {
  if (reserve.is_block == '1') {
    return `<img src="https://i.imgur.com/2wD2hbm.png" width="20" class="">`;
  } else {
    return ``;
  }
}

export function getReserveIcon(reserve) {
  switch (reserve.attend_status) {
    case '0':
      if (reserve.bind_status === '1')
        return '/asset/crm/question.png'
      else
        return bindStatus({status: reserve.bind_status}).icon
    case '-1':
      return '/asset/crm/deny.png'
    case '1':
      return '/asset/crm/check.png'
    default:
      return '/asset/crm/alert.png'
  }
}

export function showReserveTime(reserve) {
  const startTime = moment(parseInt(reserve.start_time) * 1000).format('HH:mm')
  const endTime = moment(parseInt(reserve.end_time) * 1000).format('HH:mm')
  return `${startTime}<br>${i18n.t('GENERAL.to')}<br>${endTime}`
}

export function handleFixLengthObjArray(oObject, fixLength = 3, defaultItem = null) {
  const copyObject = JSON.parse(JSON.stringify(oObject))
  const tmpArr = Array.apply(null, Array(fixLength)).map(() => {
    return JSON.parse(JSON.stringify(defaultItem))
  })
  if (copyObject.length < fixLength) {
    return copyObject.concat(tmpArr.slice(0, fixLength - copyObject.length))
  }
  return copyObject
}

export function isExpired(date) {
  const today = new Date()
  date = new Date(date)
  return today > date
}

export const checker = (needPermissions, allPermissions) => needPermissions.every(v => allPermissions.includes(v));

export const checkPermissions = ({obj, type, permissions, targetCode}) => {
  if (!obj) return

  let allPermissions = []
  // const checker = (needPermissions, allPermissions) => needPermissions.every(v => allPermissions.includes(v));
  switch (type) {
    case 'clinic': {
      if (targetCode === 'all') {
        allPermissions = [...obj.doctors.flatMap(doctor => doctor.permission_name), ...obj.permission_name]
      } else if (targetCode) {
        const targetDoctor = obj.doctors.find(doctor => doctor.code === targetCode)
        allPermissions = [...targetDoctor.permission_name, ...obj.permission_name]
      } else {
        allPermissions = obj.permission_name
      }
      break
    }
    case 'doctor': {
      if (targetCode === 'all') {
        allPermissions = [...obj.clinics.flatMap(clinic => clinic.permission_name), ...obj.permission_name]
      } else if (targetCode) {
        const targetClinic = obj.clinics.find(clinic => clinic.code === targetCode)
        allPermissions = [...targetClinic.permission_name, ...obj.permission_name]
      } else {
        allPermissions = obj.permission_name
      }
      break
    }
  }

  return checker(permissions, allPermissions)
}

export function generateRandomColor() {
  return `#${Math.floor(Math.random() * 16777215).toString(16)}`
}

export function canShow(condition, clientPermissions, modeSetting) {
  const permissionStatusMapping = {
    "line_appointment": "line_appointment_status",
    "web_appointment": "web_appointment_status",
    "google_map_appointment": "google_map_appointment_status",
  };

  const guideToLineCondition = [-100.1, -100.2, -100.9]
  const hasGuideToLine = clientPermissions.includes('guide_to_line')

  // 根據當前的權限和 guideToLine 條件，過濾並轉換對應的狀態值為數字陣列
  let condition2 = Object.entries(permissionStatusMapping)
    .filter(([permission, statusKey]) =>
      // 如果擁有相應的權限，或者在沒有 line_appointment 或 web_appointment 權限的情況下，
      // 但是 modeSetting 中相應狀態的值屬於 guideToLineCondition 指定的值，則該狀態被納入考慮
      clientPermissions.includes(permission) ||
      (['line_appointment', 'web_appointment'].includes(permission) && guideToLineCondition.includes(Number(modeSetting[statusKey])))
    )
    .map(([_, statusKey]) => Number(modeSetting[statusKey]));

  // 如果沒有 guide_to_line 權限，則進一步過濾掉 modeSetting 中值小於等於 -100 的狀態
  if (!hasGuideToLine) {
    condition2 = condition2.filter(mode => !guideToLineCondition.includes(mode));
  }

  // 檢查傳入的條件（condition）是否至少有一個存在於 condition2 中，如果是，則表示可以顯示相關設定
  return condition.some(element => condition2.includes(element))
}

export function getSocialIcon(label) {
  switch (label) {
    case 'LINE':
      return '$line';
    case 'Instagram':
      return 'mdi-instagram';
    case 'Facebook':
      return 'mdi-facebook';
    case 'Tiktok':
      return '$tiktok';
    case 'YouTube':
      return 'mdi-youtube';
    default:
      return 'mdi-web';
  }
}

export function transformSchedule(data) {
  const result = {
    morning: [],
    afternoon: [],
    evening: []
  };

  // 重新排列索引，使得星期一開始
  const adjustedData = [...data.slice(1), data[0]];

  // 遍歷數據，根據索引添加到對應的時間段
  adjustedData.forEach(day => {
    result.morning.push(day.up);
    result.afternoon.push(day.middle);
    result.evening.push(day.down);
  });

  return result;
}

export function reverseTransformSchedule(data) {
  const result = [];

  // 遍歷 0-6 的索引，將數據轉換回原結構
  for (let i = 0; i < 7; i++) {
    result.push({
      up: data.morning[i],
      middle: data.afternoon[i],
      down: data.evening[i]
    });
  }

  // 恢復星期日為索引 0 的排序
  return [result[6], ...result.slice(0, 6)];
}

export function ceilToPoint(n) {
  return Math.ceil(parseFloat(n) * 2) / 2
}
