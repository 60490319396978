import Vue from 'vue'
import VueI18n from 'vue-i18n'
import zh_TW from './zh_TW.js'
import th_TH from './th_TH'

Vue.use(VueI18n)

const locale = 'zh-TW'

const messages = {
  'zh-TW': zh_TW,
  'th-TH': th_TH,
}

const i18n = new VueI18n({
  locale,
  messages,
  silentTranslationWarn: true
})

export default i18n