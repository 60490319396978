<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <template #activator="{ on, attrs }">
      <v-btn class="rounded-lg" depressed block v-bind="attrs" v-on="on">
        <v-icon color="grey" size="15">
          mdi-plus-circle
        </v-icon>
        點我新增服務診所
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h5 font-weight-bold">新增服務診所</span>
        <v-spacer />
        <v-btn text class="px-0" small @click="onClose">
          <v-icon size="15">
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text class="pt-4" style="position: relative">
        <div class="d-flex flex-column mb-6" style="gap:0.6rem">
          <div class="text-subtitle-1 grey--text text--darken-2">
            服務診所
          </div>
          <v-autocomplete
            v-model="clinic"
            :items="clinics"
            :loading="loading"
            return-object
            placeholder="請輸入您的服務診所（輸入即搜尋）"
            hide-details="auto"
            required
            dense
            outlined
            :item-text="getItemText"
            :search-input.sync="search"
            clearable
          >
            <template #append>
              <v-icon class="px-1" color="teal lighten-2">
                mdi-magnify
              </v-icon>
            </template>
          </v-autocomplete>
        </div>
        <p class="text-body-2 grey--text text--darken-3 mb-3">
          1. 若找不到服務診所，可能是診所尚未與牙醫小幫手合作，請洽<router-link class="link_blue--text" to="https://www.dentco.tw/clinic/0101090517" style="text-underline-offset:2px">客服</router-link>。
        </p>
      </v-card-text>
      <v-card-actions class="pb-7">
        <v-spacer />
        <v-btn color="grey" outlined class="text-subtitle-2 rounded-lg" @click="onClose">
          取消
        </v-btn>
        <v-btn dark color="teal lighten-2" class="text-subtitle-2 rounded-lg" @click="onConfirm">
          新增
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>

import { searchClinicAPI } from "@/api";

export default {
  name: 'AddClinicDialog',

  data () {
    return {
      dialog: false,
      loading: false,
      search: null,
      clinic: '',
      clinics: []
    };
  },

  watch: {
    search (v) {
      if (v) this.searchClinic(v)
    }
  },

  methods: {
    searchClinic (v) {
      this.loading = true
      searchClinicAPI({clinic_name: v}).then(res => {
        // const clinicItems = res.data
        this.clinics = res.data
      }).finally(() => {
        this.loading = false
      })
    },
    getItemText (item) {
      return `${item.clinic_name}(${item.full_address})`;
    },
    onConfirm () {
      if (this.clinic) {
        this.$emit('change', this.clinic);
        this.onClose();
      }
    },
    onClose () {
      this.dialog = false;
    }
  }
};
</script>
