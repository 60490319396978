<template>
  <v-main>
    <navigation/>
    <v-card flat max-width="900" class="mx-auto">
      <v-card-title class="justify-center">
        <div class="px-2" style="width: fit-content; border-bottom: 2px solid #03c8bf">
          系統公告
        </div>
      </v-card-title>
      <v-card-subtitle class="text-end mx-auto" style="max-width: 800px">
        <v-btn v-show="notices.length" color="primary" small outlined @click="readAllNotices">全部已讀</v-btn>
      </v-card-subtitle>
      <v-container class="pb-5" fluid style="max-width: 800px">
        <notice-card v-for="notice in notices" :key="notice.id" :notice="notice" @click.native="setRead([notice.id])"/>
        <v-pagination
          v-model="page"
          class="my-4"
          :length="maxPage"
          total-visible="10"
        />
      </v-container>
    </v-card>
  </v-main>
</template>

<script>
import NoticeCard from "../components/notices/NoticeCard";
import Navigation from "../components/Navigation";
import { getNoticesAPI, readAllNoticeAPI } from "../api";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Notices",
  components: {Navigation, NoticeCard},
  data() {
    return {
      notices: [],
      page: 1,
      maxPage: 1,
      limit: 20,
    }
  },
  computed: {
    ...mapGetters([
      'axiosParamData',
    ]),
  },
  watch: {
    page() {
      this.getNotices()
    }
  },
  methods: {
    ...mapActions({
      setNoticeRead: 'setNoticeRead',
      getUnreadNotice: 'getUnreadNotice',
    }),
    getNotices() {
      getNoticesAPI({...this.axiosParamData, limit: this.limit, page: this.page}).then(res => {
        this.page = parseInt(res.data.paginate.page)
        this.maxPage = parseInt(res.data.paginate.page_total)
        this.notices = res.data.data
      })
    },
    setRead(ids) {
      this.setNoticeRead(ids).then(() => {
        this.getNotices()
      })
    },
    readAllNotices() {
      readAllNoticeAPI(this.axiosParamData).then(res => {
        this.getUnreadNotice()
      })
    }
  },
  mounted() {
    this.getNotices()
  }
}
</script>

<style scoped>

</style>