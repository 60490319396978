<template>
  <v-sheet color="grey-2" class="pa-4">
    <div class="bullet">
      目前AI評價回覆設置
      <router-link :to="{name: 'aiSettingMode'}" class="link_blue--text">
        <span class="text-decoration-underline">點我設定</span>
      </router-link>
    </div>
    <div class="d-flex flex-wrap align-center mt-4" style="column-gap: 18px; row-gap: 10px;">
      <div>AI回覆風格： <v-chip label outlined small color="primary darken-1">{{ aiSetting.style_prompt }}</v-chip></div>
      <div>插入Emoji表情： <v-chip label outlined small color="primary darken-1">{{ useEmoji }}</v-chip></div>
      <div>AI生成模式：
        <div class="d-inline-flex flex-wrap" style="gap: 2px;">
          <v-chip label outlined small color="primary darken-1">好評：{{ aiGenerateModeGood }}</v-chip>
          <v-chip label outlined small color="primary darken-1">負評：{{ aiGenerateModeBad }}</v-chip>
        </div>
      </div>
      <div class="d-flex">
        <div style="width: fit-content;" class="text-no-wrap">加入必要內容及SEO關鍵字：</div>
        <div class="d-flex flex-wrap" style="gap: 8px;">
          <v-chip v-for="(seo, idx) in seoPrompts" :key="idx" label outlined small color="primary darken-1" class="text-wrap" style="min-height: 24px; height: unset;">{{ seo }}</v-chip>
        </div>
      </div>
    </div>
  </v-sheet>
</template>

<script>
import { getExtensionAIPromptSettingAPI } from "@/api";
import { checkPermissions } from "@/utils/tool";
import { mapGetters } from "vuex";

export default {
  name: "ShowAIResponseSetting",

  props: {
    userInfo: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      aiSetting: {},
      loading: false
    }
  },

  computed: {
    ...mapGetters([
      'axiosParamData',
      'defaultAiSetting',
      'clinicUser'
    ]),
    hasPermission() {
      const userType = this.clinicUser ? 'clinic' : 'doctor'
      return checkPermissions({obj: this.userInfo, type: userType, permissions: ['adv_review_with_ai']})
    },
    useEmoji() {
      return !!Number(this.aiSetting.use_emoji_prompt) ? '是' : '否'
    },
    aiGenerateMode() {
      return Number(this.aiSetting.ai_generate_mode) === 1 ? 'AI生成回覆，人工確認後送出' : 'AI生成回覆，並且自動送出'
    },
    aiGenerateModeGood() {
      switch (Number(this.aiSetting.ai_generate_mode_good)) {
        case 1:
          return 'AI生成回覆，人工確認後送出'
        case 2:
          return 'AI生成回覆，並且自動送出'
        default:
          return '不生成AI回覆'
      }
    },
    aiGenerateModeBad() {      
      switch (Number(this.aiSetting.ai_generate_mode_bad)) {
        case 1:
          return 'AI生成回覆，人工確認後送出'
        case 2:
          return 'AI生成回覆，並且自動送出'
        default:
          return '不生成AI回覆'
      }
    },
    seoPrompts() {
      return !!Number(this.aiSetting.use_seo) ? this.aiSetting.seo_prompt.split(',') : []
    }
  },


  mounted() {
    this.getExtensionAIPromptSetting()
  },

  methods: {
    getExtensionAIPromptSetting() {
      this.loading = true

      getExtensionAIPromptSettingAPI(this.axiosParamData).then(res => {
        let data = res.data

        if (Object.keys(data).length === 0 && !this.hasPermission) {
          data = this.defaultAiSetting
        }

        this.aiSetting = data

        this.loading = false
      })
    }
  }
}
</script>

<style scoped>

</style>