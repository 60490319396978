<template>
  <v-container class="pt-7 setting-container">
    <div>
      <div class="card-title pl-2 font-weight-medium d-flex">
        {{ title }}
        <div
          v-if="hasPermission"
          class="ml-auto text-decoration-underline align-self-center blue-7--text"
          style="cursor: pointer;"
          @click="clickToEdit"
        >
          編輯內容
        </div>
      </div>
      <div class="card-sub-title pl-5 grey-7--text text-body-2 mt-1">
        {{ subtitle }}
        <br>
        若有相關問題可參考
        <a :href="`${$enum.DENTCO_NOTION_HOST}/1-216deb39b5da41f3afd4523b28240aff`" target="_blank" class="link_blue--text">操作說明</a>
        或
        <a :href="$enum.DENTCO_CS_LINE" target="_blank" class="link_blue--text">聯繫客服</a>
      </div>
    </div>
    <div class="content">
      <slot></slot>
    </div>
    <hr class="my-7">
    <div v-if="this.getIsEdit()" class="action d-flex align-center mb-7">
      <v-spacer/>
      <v-btn elevation="0" outlined class="rounded-lg grey-7--text"
             style="border-color: var(--v-grey-4-base); background-color: var(--v-grey-3-base);" @click="cancel">取消
      </v-btn>
      <v-btn elevation="0" color="red-6" dark class="ml-2 rounded-lg" @click="save">確認儲存</v-btn>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "SettingLayout",
  inject: ['getIsEdit','toggleEdit'],
  props: {
    title: {
      required: true,
      type: String
    },
    subtitle: {
      required: true,
      type: String
    },
    hasPermission: {
      required: false,
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
    }
  },
  methods: {
    clickToEdit() {
      this.toggleEdit(true)
    },
    save() {
      this.$emit('save')
    },
    cancel() {
      this.toggleEdit(false)
      this.$emit('cancel')
    }
  }
}
</script>

<style scoped lang="scss">
.setting-container {
  max-width: 1200px;

  .card-title {
    border-left: 10px solid var(--v-tiffany-10-base);
    color: var(--v-tiffany-10-base);
    font-size: 18px;
  }
}
</style>
