import { Doughnut, mixins } from 'vue-chartjs'

export default {
  extends: Doughnut,
  props: ['chartData', 'options'],
  mixins: [mixins.reactiveProp],
  mounted() {
    this.renderChart(this.chartData, {
      borderWidth: '10px',
      hoverBackgroundColor: 'red',
      hoverBorderWidth: '10px',
    })
  },
}
