import { render, staticRenderFns } from "./AiSetting.vue?vue&type=template&id=6a89c408&scoped=true"
import script from "./AiSetting.vue?vue&type=script&lang=js"
export * from "./AiSetting.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a89c408",
  null
  
)

export default component.exports