<template>
  <table class="bordered-table">
    <thead>
      <tr>
        <th />
        <th>一</th>
        <th>二</th>
        <th>三</th>
        <th>四</th>
        <th>五</th>
        <th>六</th>
        <th>日</th>
      </tr>
    </thead>
    <tbody v-if="!editable">
      <tr>
        <td>
          <div class="text-body-1">
            上午
          </div>
        </td>
        <td>
          <template v-for="item in items">
            <v-icon
              v-if="item.schedule.morning && item.schedule.morning[0]"
              :key="JSON.stringify(item) + '_0'"
              :color="item.color"
              size="10"
            >
              mdi-circle
            </v-icon>
          </template>
        </td>
        <td>
          <template v-for="item in items">
            <v-icon
              v-if="item.schedule.morning && item.schedule.morning[1]"
              :key="JSON.stringify(item) + '_1'"
              :color="item.color"
              size="10"
            >
              mdi-circle
            </v-icon>
          </template>
        </td>
        <td>
          <template v-for="item in items">
            <v-icon
              v-if="item.schedule.morning && item.schedule.morning[2]"
              :key="JSON.stringify(item) + '_2'"
              :color="item.color"
              size="10"
            >
              mdi-circle
            </v-icon>
          </template>
        </td>
        <td>
          <template v-for="item in items">
            <v-icon
              v-if="item.schedule.morning && item.schedule.morning[3]"
              :key="JSON.stringify(item) + '_3'"
              :color="item.color"
              size="10"
            >
              mdi-circle
            </v-icon>
          </template>
        </td>
        <td>
          <template v-for="item in items">
            <v-icon
              v-if="item.schedule.morning && item.schedule.morning[4]"
              :key="JSON.stringify(item) + '_4'"
              :color="item.color"
              size="10"
            >
              mdi-circle
            </v-icon>
          </template>
        </td>
        <td>
          <template v-for="item in items">
            <v-icon
              v-if="item.schedule.morning && item.schedule.morning[5]"
              :key="JSON.stringify(item) + '_5'"
              :color="item.color"
              size="10"
            >
              mdi-circle
            </v-icon>
          </template>
        </td>
        <td>
          <template v-for="item in items">
            <v-icon
              v-if="item.schedule.morning && item.schedule.morning[6]"
              :key="JSON.stringify(item) + '_6'"
              :color="item.color"
              size="10"
            >
              mdi-circle
            </v-icon>
          </template>
        </td>
      </tr>
      <tr>
        <td>
          <div class="text-body-1">
            下午
          </div>
        </td>
        <td>
          <template v-for="item in items">
            <v-icon
              v-if="item.schedule.afternoon && item.schedule.afternoon[0]"
              :key="JSON.stringify(item) + '_0'"
              :color="item.color"
              size="10"
            >
              mdi-circle
            </v-icon>
          </template>
        </td>
        <td>
          <template v-for="item in items">
            <v-icon
              v-if="item.schedule.afternoon && item.schedule.afternoon[1]"
              :key="JSON.stringify(item) + '_1'"
              :color="item.color"
              size="10"
            >
              mdi-circle
            </v-icon>
          </template>
        </td>
        <td>
          <template v-for="item in items">
            <v-icon
              v-if="item.schedule.afternoon && item.schedule.afternoon[2]"
              :key="JSON.stringify(item) + '_2'"
              :color="item.color"
              size="10"
            >
              mdi-circle
            </v-icon>
          </template>
        </td>
        <td>
          <template v-for="item in items">
            <v-icon
              v-if="item.schedule.afternoon && item.schedule.afternoon[3]"
              :key="JSON.stringify(item) + '_3'"
              :color="item.color"
              size="10"
            >
              mdi-circle
            </v-icon>
          </template>
        </td>
        <td>
          <template v-for="item in items">
            <v-icon
              v-if="item.schedule.afternoon && item.schedule.afternoon[4]"
              :key="JSON.stringify(item) + '_4'"
              :color="item.color"
              size="10"
            >
              mdi-circle
            </v-icon>
          </template>
        </td>
        <td>
          <template v-for="item in items">
            <v-icon
              v-if="item.schedule.afternoon && item.schedule.afternoon[5]"
              :key="JSON.stringify(item) + '_5'"
              :color="item.color"
              size="10"
            >
              mdi-circle
            </v-icon>
          </template>
        </td>
        <td>
          <template v-for="item in items">
            <v-icon
              v-if="item.schedule.afternoon && item.schedule.afternoon[6]"
              :key="JSON.stringify(item) + '_6'"
              :color="item.color"
              size="10"
            >
              mdi-circle
            </v-icon>
          </template>
        </td>
      </tr>
      <tr>
        <td>
          <div class="text-body-1">
            晚上
          </div>
        </td>
        <td>
          <template v-for="item in items">
            <v-icon
              v-if="item.schedule.evening && item.schedule.evening[0]"
              :key="JSON.stringify(item) + '_0'"
              :color="item.color"
              size="10"
            >
              mdi-circle
            </v-icon>
          </template>
        </td>
        <td>
          <template v-for="item in items">
            <v-icon
              v-if="item.schedule.evening && item.schedule.evening[1]"
              :key="JSON.stringify(item) + '_1'"
              :color="item.color"
              size="10"
            >
              mdi-circle
            </v-icon>
          </template>
        </td>
        <td>
          <template v-for="item in items">
            <v-icon
              v-if="item.schedule.evening && item.schedule.evening[2]"
              :key="JSON.stringify(item) + '_2'"
              :color="item.color"
              size="10"
            >
              mdi-circle
            </v-icon>
          </template>
        </td>
        <td>
          <template v-for="item in items">
            <v-icon
              v-if="item.schedule.evening && item.schedule.evening[3]"
              :key="JSON.stringify(item) + '_3'"
              :color="item.color"
              size="10"
            >
              mdi-circle
            </v-icon>
          </template>
        </td>
        <td>
          <template v-for="item in items">
            <v-icon
              v-if="item.schedule.evening && item.schedule.evening[4]"
              :key="JSON.stringify(item) + '_4'"
              :color="item.color"
              size="10"
            >
              mdi-circle
            </v-icon>
          </template>
        </td>
        <td>
          <template v-for="item in items">
            <v-icon
              v-if="item.schedule.evening && item.schedule.evening[5]"
              :key="JSON.stringify(item) + '_5'"
              :color="item.color"
              size="10"
            >
              mdi-circle
            </v-icon>
          </template>
        </td>
        <td>
          <template v-for="item in items">
            <v-icon
              v-if="item.schedule.evening && item.schedule.evening[6]"
              :key="JSON.stringify(item) + '_6'"
              :color="item.color"
              size="10"
            >
              mdi-circle
            </v-icon>
          </template>
        </td>
      </tr>
    </tbody>
    <tbody v-if="editable">
      <tr>
        <td>
          <div class="text-body-1">
            上午
          </div>
        </td>
        <td
          v-for="(_, index) in defaultValue"
          :key="'morning_' + index"
          :class="item.schedule.morning[index] ? item.color : ''"
          style="border: 1px solid #6a6a6a !important;"
          role="button"
          @click="item.schedule.morning[index] = !item.schedule.morning[index];onUpdate()"
        >
          <v-icon :color="item.schedule.morning[index] ? item.color : 'white'" size="10">
            mdi-circle
          </v-icon>
        </td>
      </tr>
      <tr>
        <td>
          <div class="text-body-1">
            下午
          </div>
        </td>
        <td
          v-for="(_, index) in defaultValue"
          :key="'afternoon_' + index"
          :class="item.schedule.afternoon[index] ? item.color : ''"
          role="button"
          style="border: 1px solid #6a6a6a !important;"
          @click="item.schedule.afternoon[index] = !item.schedule.afternoon[index];onUpdate()"
        >
          <v-icon
            :color="item.schedule.afternoon[index] ? item.color : 'white'"
            size="10"
          >
            mdi-circle
          </v-icon>
        </td>
      </tr>
      <tr>
        <td>
          <div class="text-body-1">
            晚上
          </div>
        </td>
        <td
          v-for="(_, index) in defaultValue"
          :key="'evening_' + index"
          :class="item.schedule.evening[index] ? item.color : ''"
          role="button"
          style="border: 1px solid #6a6a6a !important;"
          @click="item.schedule.evening[index] = !item.schedule.evening[index];onUpdate()"
        >
          <v-icon :color="item.schedule.evening[index] ? item.color : 'white'" size="10">
            mdi-circle
          </v-icon>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
export default {
  name: 'ScheduleTable',
  props: {
    editable: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      item: {
        color: '',
        name: '',
        // visit_clinic_note: null,
        url: '',
        schedule: {
          afternoon: [],
          morning: [],
          evening: []
        }
      }
    };
  },
  computed: {
    defaultValue () {
      return Array.from({ length: 7 }).map(_ => false);
    }
  },
  mounted () {
    if (this.editable && this.items.length > 0) {
      this.item = { ...this.items[0] };
      this.item.schedule.morning = this.defaultValue.map(
        (_, index) => this.item.schedule.morning[index] ?? false
      );
      this.item.schedule.afternoon = this.defaultValue.map(
        (_, index) => this.item.schedule.afternoon[index] ?? false
      );
      this.item.schedule.evening = this.defaultValue.map(
        (_, index) => this.item.schedule.evening[index] ?? false
      );
    }
  },
  methods: {
    onUpdate () {
      this.item = { ...this.item };
      this.$emit('update', this.item);
    }
  }
};
</script>
<style lang="css" scoped>
.bordered-table {
  border-style: hidden;
  border-collapse: collapse;
  border-spacing: 0;
  border-radius: 5px;
  box-shadow: 0 0 0 1px #6a6a6a; /* this draws the table border  */
}

td,
th {
  font-size: 14px;
  color: #6a6a6a !important;
  border: 1px solid #6a6a6a !important;
  text-align: center !important;
  width: 12.5%;
  padding: 8px !important;
}

.bordered-table thead tr th:first-child {
  border-top-left-radius: 5px;
}

.bordered-table thead tr th:last-child {
  border-top-right-radius: 5px;
}

.bordered-table tbody tr:last-child td:first-child {
  border-bottom-left-radius: 5px;
}

.bordered-table tbody tr:last-child td:last-child {
  border-bottom-right-radius: 5px;
}
</style>
