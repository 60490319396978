<script>
import { getRescheduleSettingsAPI, updateRescheduleSettingsAPI } from "../../api";
import { mapGetters } from "vuex";

export default {
  name: "RescheduleSetting",

  data() {
    return {
      loading: false,
      settings: {}
    }
  },

  computed: {
    ...mapGetters([
      'axiosParamData',
    ]),
    computedMyData() {
      return Object.keys(this.settings).length ? JSON.stringify(this.settings) : undefined
    }
  },

  watch: {
    computedMyData: {
      handler(newV, oldV) {
        console.log('oldV', oldV)
        console.log('newV', newV)
        if (oldV && oldV !== newV)
          this.updateSettings()

      },
      deep: true
    },
  },

  mounted() {
    this.getSettings()
  },

  methods: {
    getSettings() {
      this.loading = true
      getRescheduleSettingsAPI(this.axiosParamData).then(res => {
        this.settings = res.data
      }).finally(() => {
        this.loading = false
      })
    },
    updateSettings() {
      this.loading = true
      const data = {
        ...this.axiosParamData,
        ...this.settings
      }
      updateRescheduleSettingsAPI(data).then(() => {
        this.getSettings()
      }).catch(e => {
        alert('更新失敗')
        console.error(e)
        this.loading = false
      })
    }
  }
}
</script>

<template>
<div class="py-4">
  <v-overlay :value="loading" absolute>
    <v-progress-circular indeterminate size="64"/>
  </v-overlay>
  <div>
    <div class="bullet">改約限制設定</div>
    <div class="pl-6">
      <v-radio-group
        v-model="settings.cancellation_period"
        row
        hide-details
        dense
        class="mt-0"
      >
        <v-radio label="24小時內不可改約" value="24"/>
        <v-radio label="8小時內不可改約" value="8"/>
        <v-radio label="隨時可改約" value="0"/>
      </v-radio-group>
    </div>
  </div>
</div>
</template>

<style scoped lang="scss">
.bullet::before {
  content: "";
  background: var(--v-tiffany-10-base);
  padding-inline: 5px;
  height: 10px;
  margin-inline-end: 10px;
  display: inline-block;
}
</style>
