<template>
  <v-row dense align="center" :class="{'flex-nowrap': $vuetify.breakpoint.smAndDown}">
    <v-col cols="10" sm="11" md="12"
           :class="[{ 'tab-col': $vuetify.breakpoint.mdAndUp }, 'overflow-y-auto flex-grow-1 flex-shrink-0']"
           style="max-width: 100%"
    >
      <v-tabs
        v-model="tab"
        :vertical="$vuetify.breakpoint.mdAndUp"
        :center-active="$vuetify.breakpoint.smAndDown"
        :show-arrows="$vuetify.breakpoint.smAndDown"
      >
        <v-tab href="#all" class="text-left justify-start">
          <v-sheet v-if="$vuetify.breakpoint.mdAndUp" height="20" width="20" class="mr-2"/>
          {{ $t('ClinicReserveTabs.allDoctor') }}
        </v-tab>
        <v-tab v-for="item in doctorClinicItems" :key="item.doctor_code" :href="`#${item.doctor_code}`"
               class="text-left justify-start">
          <v-sheet :color="item.color_code" height="20" width="20" class="mr-2"/>
          {{ item.doctor_name }}
        </v-tab>
      </v-tabs>
    </v-col>
    <v-col cols="auto" sm="1" md="12" class="flex-shrink-1 flex-grow-0">
      <v-btn v-if="$vuetify.breakpoint.mdAndUp" block outlined @click="dialog = true">
        {{ $t('ClinicReserveTabs.manageDoctor') }}
      </v-btn>
      <v-tooltip v-else v-model="show" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" @click="dialog = true">
            <v-icon color="primary" dark>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('ClinicReserveTabs.manageDoctor') }}</span>
      </v-tooltip>
    </v-col>
    <v-dialog v-model="dialog" max-width="500" persistent scrollable>
      <v-card>
        <v-btn
          fab
          icon
          absolute
          width="48"
          height="48"
          style="top: 0; right: 0; overflow: hidden;"
          @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-card-title>{{ $t('ClinicReserveTabs.manageDoctor') }}</v-card-title>
        <v-card-text style="max-height: 350px">
          <v-list>
            <draggable
              v-model="doctorClinicItems"
              tag="div"
              handle=".drag-content"
              @start="drag = true"
              @end="drag = false"
            >
              <v-list-item v-for="item in doctorClinicItems" :key="item.doctor_code">
                <v-list-item-title>
                  <v-btn icon class="px-0 handle drag-content" small>
                    <v-icon size="20">
                      mdi-cursor-move
                    </v-icon>
                  </v-btn>
                  {{ item.doctor_name }}
                </v-list-item-title>
                <v-list-item-action class="my-0 flex-row align-center">
                  <div style="width: 76px;">
                    <v-select
                      v-model="item.doctor_color"
                      :items="colors"
                      item-value="id"
                      single-line
                      outlined
                      hide-details
                      dense
                    >
                      <template v-slot:item="{ item }">
                        <v-sheet :color="item.color_code" width="20" height="20"/>
                      </template>
                      <template v-slot:selection="{ item }">
                        <v-sheet :color="item.color_code" width="20" height="20"/>
                      </template>
                    </v-select>
                  </div>
                  <v-btn icon @click="handleDeleteDoctor(item)">
                    <v-icon color="grey lighten-1">mdi-trash-can-outline</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </draggable>
          </v-list>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn color="primary" class="px-8" @click="save">儲存</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="confirmDialog" max-width="340">
      <v-card>
        <v-card-title class="justify-center">
          是否確認刪除{{ currentEdit.doctor_name }}醫師
        </v-card-title>
        <v-card-actions>
          <v-spacer/>
          <v-btn :disabled="confirmBtnDisabled" color="primary" @click="removeDoctor(currentEdit.doctor_code)">確認
          </v-btn>
          <v-btn :disabled="confirmBtnDisabled" dark color="grey-5" @click="confirmDialog = false">取消</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import draggable from "vuedraggable";
import { deleteDoctorClinicAPI, getColorAPI, updateDoctorColorAPI } from "@/api";

export default {
  name: "ClinicReserveTabs",

  components: {draggable},

  props: ['value', 'tabItems'],

  data() {
    return {
      dialog: false,
      show: false,
      loading: false,
      colors: [],
      currentEdit: {},
      confirmDialog: false,
      drag: false,
      confirmBtnDisabled: false
    }
  },
  computed: {
    ...mapGetters([
      'axiosParamData',
    ]),
    tab: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('update:value', v)
      },
    },
    doctorClinicItems: {
      get() {
        return this.tabItems
      },
      set(v) {
        this.$emit('update:tab-items', v)
      }
    }
  },
  methods: {
    handleDeleteDoctor(doctor) {
      this.currentEdit = doctor
      this.confirmDialog = true
    },
    removeDoctor(code) {
      this.confirmBtnDisabled = true
      deleteDoctorClinicAPI(this.axiosParamData.clinic_code, {
        ...this.axiosParamData,
        doctor_code: code
      }).finally(() => {
        this.$emit('refresh')
        this.confirmDialog = false
      }).finally(() => {
        this.confirmBtnDisabled = false
      })
    },
    getColors() {
      getColorAPI(this.axiosParamData).then(res => {
        this.colors = res.data
      })
    },
    save() {
      const setData = this.doctorClinicItems.map(e => {
        return {
          id: e.id,
          color_id: e.doctor_color
        }
      })
      const data = {
        ...this.axiosParamData,
        data: setData
      }
      updateDoctorColorAPI(data).then(() => {
        this.$emit('refresh')
        this.dialog = false
      })
    }
  },
  mounted() {
    this.getColors()
  }
}
</script>

<style scoped lang="scss">
.tab-col {
  height: calc(100vh - 130px);
}
</style>