import moment from "moment-timezone";


export function formatROCDate(timestamp) {
  if (isNaN(timestamp)) return null
  const year = moment(parseInt(timestamp)).format("YYYY")
  return year - 1911 + '年' + moment(parseInt(timestamp)).format('MM月DD日')
}

export function formatDate(timestamp, format='YYYY-MM-DD HH:mm') {
  return moment(timestamp).format(format)
}

export function shortDate(value) {
  return moment.utc(`${String(value)}`).local().format('YYYY/MM/DD HH:mm')
}

export function formatToDate(timestamp) {
  return moment(timestamp).format('YYYY-MM-DD')
}

export function formatTimestampToTime(timestamp) {
  // 將時間戳從秒轉換為毫秒
  const date = new Date(Number(timestamp) * 1000);

  // 使用 Intl.DateTimeFormat 來格式化時間，指定時區為台灣時區
  const formatter = new Intl.DateTimeFormat('zh-TW', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
    timeZone: 'Asia/Taipei'
  });

  return formatter.format(date);
}

export function convertToTimezone(utcString, timezone = "Asia/Taipei", format = "YYYY-MM-DD HH:mm:ss") {
  return moment.utc(utcString) // 解析 UTC 時間
    .tz(timezone)            // 轉換到指定時區
    .format(format);         // 格式化輸出
}

export function getGender(n) {
  n = parseInt(n)
  if (n === 1) return '男'
  else if (n === 0) return '女'
  else return null
}

export function get_age(timestamp) {
  const now = Date.now()
  return moment.duration(now - timestamp).years()
}

export function assetURL(path) {

  return new URL(path, 'https://cdn.dentco.tw').href
}
