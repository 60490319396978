<template>
  <v-card class="p-0" rounded="xl">
    <paid-feature-notice :has-permission="hasPermission"/>
    <div style="padding: 20px 30px 0px 30px">
      <div class="d-flex justify-space-between align-center mb-2">
        <h6
          class="text-subtitle-1 font-weight-bold"
          style="height: 40px"
        >
          衛教/案例分享
        </h6>
        <div v-if="hasPermission" class="d-flex" style="gap: 0.8rem">
          <health-category-dialog
            :categories="categories"
            @update="updateDoctorHealthEduCategory"
          >
            <v-btn
              color="teal lighten-2"
              class="text-subtitle-2 rounded-lg"
              outlined
              style="width: 150px; height: 40px"
            >
              <v-icon dark size="15" class="mr-2">
                mdi-pencil
              </v-icon>
              編輯分類
            </v-btn>
          </health-category-dialog>
          <editor-health-image-dialog
            :categories.sync="categories"
            @update="createDoctorHealthEduPost"
            @updateCategories="updateDoctorHealthEduCategory"
          >
            <template #custom>
              <v-btn
                color="teal lighten-2"
                class="text-subtitle-2 rounded-lg"
                dark
                style="width: 150px; height: 40px"
              >
                <v-icon dark size="15" class="mr-2">
                  mdi-plus-circle
                </v-icon>
                新增照片
              </v-btn>
            </template>
          </editor-health-image-dialog>
        </div>
      </div>
    </div>
    <div style="padding: 0px 30px 20px 30px; position: relative">
      <v-tabs
        ref="tabs"
        v-model="currentTab"
        show-arrows
        slider-color="teal lighten-2"
      >
        <v-tab
          v-for="(tab, idx) in tabs"
          :key="tab.id"
          fixed-tabs
          class="px-0 text-subtitle-1"
          :tab-value="tab.id"
        >
          {{ tab.name }}
        </v-tab>
      </v-tabs>

      <v-progress-linear :active="loading" indeterminate></v-progress-linear>
      <v-divider class="mt-0 mb-3" />
      <div>
        <div class="grid">
          <template v-if="posts.length">
            <editor-health-image-dialog
              v-for="post in posts"
              :key="post.id"
              :value="post"
              :categories.sync="categories"
              :img-style="{
                gridColumn: `span ${post.span || 1} / span ${post.span || 1}`,
                gridRow: `span ${post.span || 1} / span ${post.span || 1}`,
              }"
              :disabled="loading"
              @update="updateDoctorHealthEduPost(post.id, $event)"
              @delete="deleteDoctorHealthEduPost(post.id, $event)"
              @updateCategories="updateDoctorHealthEduCategory"
            >
              <img :src="post.gallery[0]" :alt="post.title" class="grid-img">
            </editor-health-image-dialog>
          </template>

          <editor-health-image-dialog
            v-else
            :categories.sync="categories"
            @update="createDoctorHealthEduPost"
          >
            <template #custom>
              <v-avatar
                :style="{
                  gridColumn: 'span 1 / span 1',
                  gridRow: 'span 1 / span 1',
                }"
                style="position: relative; border-radius: 10px"
                class="grid-img"
                size="100"
              >
                <div class="d-flex justify-center align-center rounded-circle mx-auto">
                  <v-icon color="white" sm>
                    mdi-plus
                  </v-icon>
                </div>
              </v-avatar>
            </template>
          </editor-health-image-dialog>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import HealthCategoryDialog from "@/components/settings/doctor/v2/HealthCategoryDialog.vue";
import EditorHealthImageDialog from "@/components/settings/doctor/v2/EditorHealthImageDialog.vue";
import {
  createDoctorHealthEduCategoryAPI,
  createDoctorHealthEduPostAPI, deleteDoctorHealthEduPostAPI,
  getDoctorHealthEduCategoryAPI,
  getDoctorHealthEduPostsAPI, updateDoctorHealthEduPostAPI
} from "@/api";
import { mapGetters } from "vuex";
import PaidFeatureNotice from "@/components/settings/doctor/v2/PaidFeatureNotice.vue";

export default {
  name: "HealthEducationSharing",

  props: {
    hasPermission: {
      type: Boolean
    }
  },

  components: {PaidFeatureNotice, EditorHealthImageDialog, HealthCategoryDialog},

  data() {
    return {
      loading: false,
      categories: [],
      tabs: [],
      currentTab: null,
      items: [],
      posts: []
    }
  },

  computed: {
    ...mapGetters([
      'axiosParamData',
    ])
  },

  watch: {
    currentTab: {
      handler(v) {
        console.log(v)
        this.getPosts(v)
      },
      immediate: true,
      deep: true
    },
    categories: {
      handler(v) {
        const all = {
          name: '全部',
          id: 0
        }
        const unCategorized = {
          name: '未分類',
          id: -1
        }

        if (!this.hasPermission && !v?.length) {
          v = [
            {"id": 1, "name": "一般牙科"},
            {"id": 2, "name": "美白牙齒"},
            {"id": 3, "name": "矯正療程"},
            {"id": 4, "name": "植牙療程"}
          ]
        }
        this.tabs = [all, ...v, unCategorized];
        this.$refs?.tabs?.onResize();
      },
      immediate: true,
      deep: true
    }
  },

  mounted() {
    this.getDoctorHealthEduCategory()
  },

  methods: {
    getDoctorHealthEduCategory() {
      getDoctorHealthEduCategoryAPI(this.axiosParamData).then(res => {
        this.categories = res.data
      })
    },
    async updateDoctorHealthEduCategory(payload) {
      const {categories, callback} = payload;

      try {
        const res = await createDoctorHealthEduCategoryAPI({
          ...this.axiosParamData,
          categories
        })

        if (callback && typeof callback === 'function') {
          callback('success');
        }
      } catch (error) {
        if (callback && typeof callback === 'function') {
          callback('error');
        }
        console.error(error)
      } finally {
        this.getDoctorHealthEduCategory()
      }
    },
    async createDoctorHealthEduPost(payload) {
      const {post, callback} = payload;

      try {
        const data = {
          ...this.axiosParamData,
          ...post
        }
        const formData = new FormData()
        for (const [key, value] of Object.entries(data)) {
          // if value is array
          if (Array.isArray(value)) {
            value.forEach(item => {
              formData.append(`${key}[]`, item)
            })
          } else {
            formData.append(key, value)
          }
        }
        const res = await createDoctorHealthEduPostAPI(formData)

        if (callback && typeof callback === 'function') {
          callback('success');
        }
      } catch (error) {
        if (callback && typeof callback === 'function') {
          callback('error');
        }
        console.error(error)
      } finally {
        this.getPosts(this.currentTab)
      }
    },
    async updateDoctorHealthEduPost(postId, payload) {
      const {post, callback} = payload;

      delete post.categories;

      try {
        const data = {
          ...this.axiosParamData,
          ...post,
          _method: 'put'
        }
        const formData = new FormData()
        for (const [key, value] of Object.entries(data)) {
          if (Array.isArray(value)) {
            value.forEach((item, index) => {
              if (key === 'gallery') {
                formData.append(`gallery[${index}]`, item);
              } else {
                // 處理其他一般數組
                formData.append(`${key}[]`, item);
              }
            });
          } else {
            // 處理非數組的普通 key-value
            formData.append(key, value);
          }
        }

        const res = await updateDoctorHealthEduPostAPI(postId, formData)

        if (callback && typeof callback === 'function') {
          callback('success');
        }
      } catch (error) {
        if (callback && typeof callback === 'function') {
          callback('error');
        }
        console.error(error)
      } finally {
        this.getPosts(this.currentTab)
      }
    },
    async deleteDoctorHealthEduPost(postId, payload) {
      const {callback} = payload;

      try {
        const res = await deleteDoctorHealthEduPostAPI(postId, this.axiosParamData)

        if (callback && typeof callback === 'function') {
          callback('success');
        }
      } catch (error) {
        if (callback && typeof callback === 'function') {
          callback('error');
        }
        console.error(error)
      } finally {
        this.getPosts(this.currentTab)
      }
    },
    getPosts(categoryId) {
      this.loading = true

      const params = {
        ...this.axiosParamData,
        category_id: categoryId
      }
      getDoctorHealthEduPostsAPI(params).then(res => {
        this.posts = []  // hint: 不知道為啥這樣才會更新別刪
        this.$nextTick(() => {  // hint: 不知道為啥這樣才會更新別刪
          this.posts = res.data
          if (!this.hasPermission && !res.data?.length) {
            this.posts = [
              {
                "id": 1,
                "title": "什麼是牙冠增長術？術後會有後遺症嗎？",
                "gallery": [
                  "https://cdn.dentco.tw/blog-dev/images/JiaoZhengPianShenMeSh.2e16d0ba.fill-951x580.format-webp.webp"
                ]
              },
              {
                "id": 2,
                "title": "牙齒矯正原理是什麼？矯正後一定要戴維持器嗎？",
                "gallery": [
                  "https://cdn.dentco.tw/blog-dev/images/JiaoZhengPianJiaoZhen.2e16d0ba.fill-951x580.format-webp_qoeCfmj.webp"
                ]
              },
              {
                "id": 3,
                "title": "牙齒矯正完整介紹，不管幾歲都能做牙齒矯正嗎？",
                "gallery": [
                  "https://cdn.dentco.tw/blog-dev/images/JiaoZhengPianYaChiJia.2e16d0ba.fill-951x580.format-webp_bwGNkmv.webp"
                ]
              },

            ]
          }
        })
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style scoped lang="scss">
.grid {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr)); /* grid-cols-2 */
  gap: 1rem;
  max-height: 400px;
  overflow-y: auto;
}

.grid-img {
  position: relative;
  aspect-ratio: 1 / 1;
  background-color: #e5e7eb;
  border-radius: 0.5rem;
  overflow: hidden;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

@media (min-width: 768px) {
  .grid {
    grid-template-columns: repeat(3, minmax(0, 1fr)); /* md:grid-cols-3 */
  }
}

@media (min-width: 1024px) {
  .grid {
    grid-template-columns: repeat(4, minmax(0, 1fr)); /* lg:grid-cols-4 */
  }
}
</style>