<template>
  <div class="overflow-auto pr-2 pb-2">
    <table>
      <thead>
      <tr :class="{'read-height': !isEdit, 'edit-height': isEdit}">
        <th></th>
        <th v-if="isEdit" class="edit-all">全體設定</th>
        <th v-for="data in convertData" :key="data.code">
          {{ data.name }}
        </th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="setting in settingsMeta" :key="setting.key" :class="{'read-height': !isEdit, 'edit-height': isEdit}">
        <td class="text-center white--text">
          {{ setting.title }}
          <v-tooltip v-if="setting.tooltip" bottom>
            <template v-slot:activator="{ on }">
            <span style="vertical-align: text-bottom;">
              <v-icon v-on="on" size="20" color="white">
                mdi-help-circle-outline
              </v-icon>
            </span>
            </template>
            <span>{{ setting.tooltip }}</span>
          </v-tooltip>
        </td>
        <td v-if="isEdit" class="edit-all">
          <div>
            <v-select
              v-if="setting.type === 'select'"
              :items="setting.options"
              dense
              hide-details
              outlined
              class="text-body-2"
              @change="updateAllValue($event, setting.key)"
            />
            <v-switch
              v-if="setting.type === 'switch'"
              hide-details
              class="mt-0 mx-auto"
              style="width: fit-content"
              @change="updateAllValue($event || false, setting.key)"
            />
          </div>
        </td>
        <td v-for="(data, idx) in convertData" :key="data.code">
          <div v-if="isEdit">
            <v-select
              v-if="setting.type === 'select'"
              :items="setting.options"
              :value="data[setting.key]"
              dense
              hide-details
              outlined
              class="text-body-2"
              @change="updateValue($event, idx, setting.key)"
            />
            <v-switch
              v-if="setting.type === 'switch'"
              :input-value="data[setting.key]"
              :value="data[setting.key]"
              hide-details
              class="mt-0 mx-auto"
              style="width: fit-content"
              @change="updateValue($event || false, idx, setting.key)"
            />
          </div>
          <div v-else class="text-center">
            <div v-if="showValue(setting, data[setting.key]).type === 'text'">
              {{ showValue(setting, data[setting.key]).value }}
            </div>
            <v-icon
              v-else
              color="primary"
            >
              {{ showValue(setting, data[setting.key]).value }}
            </v-icon>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "SettingCard",

  props: {
    isEdit: {
      type: Boolean,
      default: false
    },
    settingsData: {
      type: Array
    },
    settingsMeta: {
      type: Array,
      required: true
    },
    convertFunc: {
      type: Function,
      required: true
    },
    restoreFunc: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      convertData: []
    }
  },

  computed: {
    ...mapGetters([
      'clinicUser'
    ]),
  },

  watch: {
    settingsData: {
      handler() {
        console.log(1)
        const convertData = this.convertFunc(this.settingsData, this.clinicUser ? 1 : 2)
        if(JSON.stringify(this.convertData) !== JSON.stringify(convertData)) {
          this.convertData = convertData
        }
      },
      immediate: true,
      deep: true
    },
    convertData: {
      handler() {
        console.log(2)
        const originalData = this.restoreFunc(this.convertData, this.clinicUser ? 1 : 2)
        this.$emit('update:settingsData', originalData)
        // if (JSON.stringify(originalData) !== JSON.stringify(this.settingsData)) {
        //   this.$emit('update:settingsData', originalData)
        // }
      },
      deep: true
    }
  },

  methods: {
    updateValue(event, idx, key) {
      this.convertData[idx][key] = event
    },
    updateAllValue(event, key) {
      this.convertData.map(e => e[key] = event)
    },
    showValue(setting, value) {
      const res = {
        type: 'text',
        value: '-'
      }
      if (setting.type === 'select') {
        const option = setting.options.find(e => e.value === value)
        if (value) {
          res.value = option ? option.showText || option.text : value
        }
      } else {
        if (value) {
          res.type = 'v-icon'
          res.value = 'mdi-checkbox-marked-circle'
        }
      }
      return res
    }
  }
}
</script>

<style scoped>
td,
th {
  border-bottom: 1px solid #000;
}

thead th {
  border-top: 1px solid #000;
}

th:last-child,
td:last-child {
  border-right: 1px solid #000;
}

th:first-child,
td:first-child {
  border-left: 1px solid #000;
}

table {
  border-collapse: separate;
  border-spacing: 0;
}

td,
th {
  width: 120px;
  padding: 4px 8px;
}

th {
  z-index: 1;
  background-color: #ffffff;
}

table {
  table-layout: fixed;
  width: 100%;
}

td:first-child, th:first-child {
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: grey;
}

thead tr th {
  position: sticky;
  top: 0;
}

th:first-child {
  z-index: 2;
}

.read-height {
  height: 56px;
}

.edit-height {
  height: 56px;
}

.edit-all {
  background-color: #C1C1C1;
}
</style>
