<template>
  <setting-layout ref="layout" :title="title" :subtitle="subtitle" @cancel="cancel" @save="save">
    <template v-for="(social, idx) in canEditSocials">
      <component
        v-if="social.component"
        :key="idx"
        :is="social.component"
        :social="social"
        :has-permission="true"
        :can-edit="canEdit(social.permissions)"
        @updateValue="updateValue"
      />
      <v-row v-else dense class="align-center">
        <v-col cols="12" md="3" class="bullet py-2">
          {{ social.title }}
        </v-col>
        <v-col cols="12" md="9" class="py-2">
          <v-text-field
            v-model="social.value"
            class="setting-input"
            filled
            rounded
            single-line
            placeholder="請輸入網址"
            hide-details="auto"
            :disabled="!canEdit(social.permissions)"
          />
        </v-col>
      </v-row>
    </template>
    <div v-if="disabledSocials.length" class="my-7">
      <v-btn
        dark
        color="tiffany-10"
        depressed
        :block="$vuetify.breakpoint.smAndDown"
        class="px-md-12 rounded-lg"
        href="https://lin.ee/mWs8BoE"
        target="_blank"
      >
        欲顯示完整診所社群，請點我升級為進階方案
      </v-btn>
    </div>
    <template v-for="(social, idx) in disabledSocials">
      <component
        v-if="social.component"
        :key="idx"
        :is="social.component"
        :social="social"
        :has-permission="false"
        :can-edit="canEdit(social.permissions)"
        @updateValue="updateValue"
      />
    </template>
  </setting-layout>
</template>

<script>
import SettingLayout from "./SettingLayout.vue";
import MultiSocialRow from "./MultiSocialRow.vue";
import { checkPermissions, handleFixLengthObjArray } from "../../../utils/tool";
import { getClinicInfoAPI, updateClinicInfoAPI } from "../../../api";
import { mapGetters } from "vuex";
import GuideLineRow from "./GuideLineRow.vue";

export default {
  name: "ClinicSocials",

  inject: ['getIsEdit', 'toggleEdit', 'toggleLoading', 'saveAlert'],

  components: {GuideLineRow, MultiSocialRow, SettingLayout},

  props: {
    title: {
      required: true,
      type: String
    },
    subtitle: {
      required: true,
      type: String
    },
    userInfo: {
      required: true,
      type: Object,
    }
  },

  data() {
    return {
      checkPermissions,
      socialLinks: [
        {title: '官方網站', value: {}, key: 'website', permissions: []},
        {title: 'LINE官方帳號', value: null, key: 'line_page', permissions: ['guide_to_line'], component: 'GuideLineRow'},
        {title: 'Facebook 粉絲團', value: null, key: 'fb_page', permissions: ['adv_page']},
        {title: 'Instagram 貼文', value: [], key: 'ig_page', permissions: ['adv_page'], component: 'MultiSocialRow', multi: true, length: 3},
        {title: 'YouTube 影片', value: [], key: 'youtube_page', permissions: ['adv_page'], component: 'MultiSocialRow', multi: true, length: 3},
      ],
      data: null
    }
  },

  computed: {
    ...mapGetters([
      'axiosParamData',
    ]),
    canEditSocials() {
      return this.socialLinks.filter(social => {
        return this.checkPermissions({obj: this.userInfo, type: 'clinic', permissions: social.permissions})
      })
    },
    disabledSocials() {
      return this.socialLinks.filter(social => {
        return !this.checkPermissions({obj: this.userInfo, type: 'clinic', permissions: social.permissions})
      })
    },
  },

  methods: {
    canEdit(socialPermission) {
      return this.getIsEdit() &&
        this.checkPermissions({obj: this.userInfo, type: 'clinic', permissions: socialPermission})
    },
    cancel() {
      this.getData()
    },
    save() {
      this.toggleLoading(true)
      const data = {
        ...this.axiosParamData
      }
      this.socialLinks.forEach(e => {
        if (e.key === 'line_page') {
          data.line_page = e.value
          data.line_page_status = e.linePageStatus
        } else {
          data[e.key] = e.value
        }
      })
      updateClinicInfoAPI(data).then(() => {
        this.getData()
        this.toggleEdit(false)
        this.saveAlert()
      }).catch(e => {
        alert('error')
        console.error(e)
        this.toggleLoading(false)
      })
    },
    getData() {
      this.toggleLoading(true)
      getClinicInfoAPI(this.axiosParamData).then(res => {
        this.data = res.data
        this.setData()
      }).finally(() => {
        this.toggleLoading(false)
      })
    },
    setData() {
      this.socialLinks = this.$options.data().socialLinks.map(social => {
        switch (social.key) {
          case 'website':
          case 'fb_page':
          case 'ig_page':
          case 'youtube_page':
            if (social.multi)
              social.value = handleFixLengthObjArray(JSON.parse(JSON.stringify(this.data[social.key])), social.length)
            else
              social.value = this.data[social.key]
            break
          case 'line_page':
            social.value = JSON.parse(JSON.stringify(this.data[social.key]))
            social.linePageStatus = this.data.line_page_status
        }
        return social
      })
    },
    updateValue(event, socialKey, options = {}) {
      if (socialKey === 'line_page') {
        const {valueKey} = options
        const guideLine = this.socialLinks.find(social => social.key === 'line_page')
        guideLine[valueKey] = event
      } else {
        const {idx} = options
        const social = this.socialLinks.find(social => social.key === socialKey)
        social.value[idx] = event
      }
    }
  },

  mounted() {
    this.getData()
  }
}
</script>

<style scoped>
.bullet::before {
  content: "";
  background: #000000;
  padding-inline: 5px;
  height: 10px;
  margin-inline-end: 10px;
  display: inline-block;
}
</style>
