<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="4" md="2" class="text-center outlined">{{ $t('FreeTimeSetting.week') }}</v-col>
      <v-col cols="8" md="10" class="text-center outlined">{{ $t('FreeTimeSetting.freeTime') }}</v-col>
    </v-row>
    <v-row dense v-for="(weekday, i) in weekdays" :key="i">
      <v-col cols="4" md="2" class="outlined d-flex text-center align-center justify-center">
        {{ weekday }}
      </v-col>

      <v-col cols="8" md="10" class="outlined">
        <v-select
          v-model="freeTimeWeekly[i]"
          :items="timeOptions"
          :small-chips="$vuetify.breakpoint.width < 1040"
          :clearable="!$vuetify.breakpoint.xsOnly"
          multiple
          flat
          hide-details
          class="select-single py-2 mt-0"
        >
          <template v-slot:selection="{ attrs, item, select, selected }">
            <v-chip
              v-bind="attrs"
              :input-value="selected"
              :small="$vuetify.breakpoint.width < 1040"
              @click="select"
              color="primary"
              text-color="black"
            >
              {{ item }}
            </v-chip>
          </template>
        </v-select>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ManualWeekly",

  props: {
    timeOptions: {
      type: Array,
      required: true
    },
    weekdays: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      freeTimeWeekly: []
    }
  },

  computed: {
    ...mapGetters([
      'manualSetting',
      'manualWeekly'
    ]),
  },

  watch: {
    manualSetting: {
      handler(v) {
        if (v && v.weekly)
          this.freeTimeWeekly = JSON.parse(JSON.stringify(v.weekly))
      },
      deep: true,
      immediate: true
    },
    freeTimeWeekly: {
      handler(v) {
        if (v.length)
          this.processManualWeekly(v)
      },
      deep: true
    }
  },

  methods: {
    ...mapActions({
      processManualWeekly: 'processManualWeekly'
    })
  }
}
</script>

<style scoped>
.outlined {
  border: 1px solid #707070;
}
</style>
