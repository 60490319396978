function getValue(data, dayIndex) {
  const conditionKey = `reserve_day${dayIndex}`;
  const timeKey = `reserve_day${dayIndex}_time`;

  return data[conditionKey] === "1" ? data[timeKey] : null;
}

export function convertSettingData(data = [], type = 1) {
  const settingsData = []
  const codeKey = type === 1 ? 'doctor_code' : 'clinic_code'
  const nameKey = type === 1 ? 'doctor_name' : 'clinic_name'
  data.forEach(setting => {
    settingsData.push({
      id: setting.remind_setting_id,
      code: setting[codeKey],
      name: setting[nameKey],
      day2Remind: getValue(setting, 2),
      day2RemindTime: setting.reserve_day2_time,
      day1Remind: getValue(setting, 1),
      day1RemindTime: setting.reserve_day1_time,
      day0Remind: getValue(setting, 0),
      day0RemindTime: setting.reserve_day0_time,
      createRemind: Boolean(Number(setting.reserve_create)),
      updateRemind: Boolean(Number(setting.reserve_update)),
      cancelRemind: Boolean(Number(setting.reserve_cancel)),
      mondayReminderOnFriday: setting.reserve_fri_send_mon === "1" ? setting.reserve_fri_send_mon_time : null,
      mondayReminderOnFridayTime: setting.reserve_fri_send_mon_time,
      treatment: Boolean(Number(setting.treatment)),
      care: Boolean(Number(setting.care)),
      survey: Number(setting.survey) === 1 ? Number(setting.survey_time_gap) === 0 ? -1 : Number(setting.survey_time_gap) : 0,
      surveyTimeGap: Number(setting.survey_time_gap),
      halfYearRemind: Boolean(Number(setting.half_year)),
      quarterYearRemind: Boolean(Number(setting.quarter_year)),
      threeSixNoteRemind: Boolean(Number(setting.three_six_month))
    })
  })
  return settingsData
}

export function restoreSettingData(newData = [], type = 1) {
  const originalData = [];
  const codeKey = type === 1 ? 'doctor_code' : 'clinic_code';
  const nameKey = type === 1 ? 'doctor_name' : 'clinic_name';

  newData.forEach(setting => {
    const originalSetting = {
      remind_setting_id: setting.id,
      [codeKey]: setting.code,
      [nameKey]: setting.name,
      reserve_create: setting.createRemind ? "1" : "0",
      reserve_update: setting.updateRemind ? "1" : "0",
      reserve_cancel: setting.cancelRemind ? "1" : "0",
      reserve_day0: setting.day0Remind ? "1" : "0",
      reserve_day1: setting.day1Remind ? "1" : "0",
      reserve_day2: setting.day2Remind ? "1" : "0",
      reserve_fri_send_mon: setting.mondayReminderOnFriday ? "1" : "0",
      reserve_fri_send_mon_time: setting.mondayReminderOnFriday || setting.mondayReminderOnFridayTime,
      reserve_day0_time: setting.day0Remind || setting.day0RemindTime,
      reserve_day1_time: setting.day1Remind || setting.day1RemindTime,
      reserve_day2_time: setting.day2Remind || setting.day2RemindTime,
      treatment: setting.treatment ? "1" : "0",
      care: setting.care ? "1" : "0",
      survey: setting.survey === 0 ? "0" : "1",
      survey_time_gap: setting.survey === 0 ? setting.surveyTimeGap : setting.survey === -1 ? 0 : setting.survey,
      half_year: setting.halfYearRemind ? "1" : "0",
      quarter_year: setting.quarterYearRemind ? "1" : "0",
      three_six_month: setting.threeSixNoteRemind ? "1" : "0"
    };

    originalData.push(originalSetting);
  });

  return originalData;
}

export function convertSmsSettingData(data = [], type = 1) {
  const settingsData = []
  const codeKey = type === 1 ? 'doctor_code' : 'clinic_code'
  const nameKey = type === 1 ? 'doctor_name' : 'clinic_name'
  data.forEach(setting => {
    settingsData.push({
      id: setting.sms_setting_id,
      doctorClinicId: setting.doctor_in_clinic_id,
      code: setting[codeKey],
      name: setting[nameKey],
      reserveType: setting.reserve_type,
      day2Remind: getValue(setting, 2),
      day2RemindTime: setting.reserve_day2_time,
      day1Remind: getValue(setting, 1),
      day1RemindTime: setting.reserve_day1_time,
      day0Remind: getValue(setting, 0),
      day0RemindTime: setting.reserve_day0_time,
      halfYearRemind: Boolean(Number(setting.half_year)),
      quarterYearRemind: Boolean(Number(setting.quarter_year)),
      threeSixNoteRemind: Boolean(Number(setting.three_six_month)),
      createRemind: Boolean(Number(setting.reserve_create)),
      updateRemind: Boolean(Number(setting.reserve_update)),
      cancelRemind: Boolean(Number(setting.reserve_cancel)),
      mondayReminderOnFriday: setting.reserve_fri_send_mon === "1" ? setting.reserve_fri_send_mon_time : null,
      mondayReminderOnFridayTime: setting.reserve_fri_send_mon_time,
      care: Boolean(Number(setting.care)),
      // survey: Boolean(Number(setting.survey)),
      survey: Number(setting.survey) === 1 ? Number(setting.survey_time_gap) === 0 ? -1 : Number(setting.survey_time_gap) : 0,
      surveyTimeGap: Number(setting.survey_time_gap),
    })
  })
  return settingsData
}

export function restoreSmsSettingData(newData = [], type = 1) {
  const originalData = [];
  const codeKey = type === 1 ? 'doctor_code' : 'clinic_code';
  const nameKey = type === 1 ? 'doctor_name' : 'clinic_name';

  newData.forEach(setting => {
    const originalSetting = {
      sms_setting_id: setting.id,
      doctor_in_clinic_id: setting.doctorClinicId,
      [codeKey]: setting.code,
      [nameKey]: setting.name,
      reserve_create: setting.createRemind ? "1" : "0",
      reserve_update: setting.updateRemind ? "1" : "0",
      reserve_cancel: setting.cancelRemind ? "1" : "0",
      reserve_type: setting.reserveType,
      reserve_day0: setting.day0Remind ? "1" : "0",
      reserve_day1: setting.day1Remind ? "1" : "0",
      reserve_day2: setting.day2Remind ? "1" : "0",
      reserve_fri_send_mon: setting.mondayReminderOnFriday ? "1" : "0",
      reserve_fri_send_mon_time: setting.mondayReminderOnFriday || setting.mondayReminderOnFridayTime,
      reserve_day0_time: setting.day0Remind || setting.day0RemindTime,
      reserve_day1_time: setting.day1Remind || setting.day1RemindTime,
      reserve_day2_time: setting.day2Remind || setting.day2RemindTime,
      care: setting.care ? "1" : "0",
      // survey: setting.survey ? "1" : "0",
      survey: setting.survey === 0 ? "0" : "1",
      survey_time_gap: setting.survey === 0 ? setting.surveyTimeGap : setting.survey === -1 ? 0 : setting.survey,
      half_year: setting.halfYearRemind ? "1" : "0",
      quarter_year: setting.quarterYearRemind ? "1" : "0",
      three_six_month: setting.threeSixNoteRemind ? "1" : "0"
    };

    originalData.push(originalSetting);
  });

  return originalData;
}

export function convertVoiceSettingData(data = [], type = 1) {
  const settingsData = []
  const codeKey = type === 1 ? 'doctor_code' : 'clinic_code'
  const nameKey = type === 1 ? 'doctor_name' : 'clinic_name'
  data.forEach(setting => {
    settingsData.push({
      id: setting.remind_cht_voice_setting_id,
      doctorClinicId: setting.doctor_in_clinic_id,
      code: setting[codeKey],
      name: setting[nameKey],
      day2Remind: getValue(setting, 2),
      day2RemindTime: setting.reserve_day2_time,
      day1Remind: getValue(setting, 1),
      day1RemindTime: setting.reserve_day1_time,
      day0Remind: getValue(setting, 0),
      day0RemindTime: setting.reserve_day0_time,
      halfYearRemind: Boolean(Number(setting.half_year))
    })
  })
  return settingsData
}

export function restoreVoiceSettingData(newData = [], type = 1) {
  const originalData = [];
  const codeKey = type === 1 ? 'doctor_code' : 'clinic_code';
  const nameKey = type === 1 ? 'doctor_name' : 'clinic_name';

  newData.forEach(setting => {
    const originalSetting = {
      remind_cht_voice_setting_id: setting.id,
      doctor_in_clinic_id: setting.doctorClinicId,
      [codeKey]: setting.code,
      [nameKey]: setting.name,
      reserve_day0: setting.day0Remind ? "1" : "0",
      reserve_day1: setting.day1Remind ? "1" : "0",
      reserve_day2: setting.day2Remind ? "1" : "0",
      reserve_day0_time: setting.day0Remind || setting.day0RemindTime,
      reserve_day1_time: setting.day1Remind || setting.day1RemindTime,
      reserve_day2_time: setting.day2Remind || setting.day2RemindTime,
      half_year: setting.halfYearRemind ? "1" : "0"
    };

    originalData.push(originalSetting);
  });

  return originalData;
}
