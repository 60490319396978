<template>
  <v-dialog v-model="dialog" persistent max-width="550px">
    <template #activator="{ on, attrs }">
      <v-btn
        text
        class="px-0"
        small
        style="min-width: fit-content"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon color="black">
          mdi-trash-can-outline
        </v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <v-spacer />
        <v-btn text class="px-0" small @click="onClose">
          <v-icon size="15">
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>
      <div class="text-h5 font-weight-bold text-center">
        即將刪除分類
      </div>
      <v-card-text class="pt-4 px-8 text-center" style="position: relative">
        <div class="text-h5 font-weight-bold primary--text">
          {{ name }}
        </div>
        <p class="text-body-2 grey--text text--darken-3 my-4">
          請再次確認是否要刪除此衛教分類，若刪除則該分類底下的衛教內容。
        </p>
      </v-card-text>
      <v-card-actions class="pb-10 px-10" style="gap:1rem;">
        <v-btn color="grey" outlined class="text-subtitle-2 rounded-lg flex-grow-1" @click="onClose">
          取消
        </v-btn>
        <v-btn dark color="teal lighten-2" class="text-subtitle-2 rounded-lg flex-grow-1" @click="onConfirm">
          確認
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>

export default {
  name: 'DeleteHealthCategoryDialog',
  props: {
    name: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      dialog: false
    };
  },
  methods: {
    onConfirm () {
      this.$emit('confirm');
      this.onClose();
    },
    onClose () {
      this.dialog = false;
    }
  }
};
</script>
