<template>
  <v-card class="p-0" rounded="xl">
    <paid-feature-notice :hasPermission="hasPermission"/>
    <div style="padding: 20px 30px 0px 30px">
      <div class="d-flex justify-space-between align-center mb-2">
        <h6
          class="text-subtitle-1 font-weight-bold"
          style="height: 40px"
        >
          醫師榮譽認證
        </h6>
      </div>
    </div>
    <div style="padding: 0px 30px 20px 30px; position: relative">
      <div
        class="d-flex justify-center align-center text-center text-h2 font-weight-bold grey--text text--lighten-1"
        style="height: 150px"
      >
        COMING SOON
      </div>
    </div>
  </v-card>
</template>

<script>
import PaidFeatureNotice from "@/components/settings/doctor/v2/PaidFeatureNotice.vue";

export default {
  name: "DoctorHonorBadge",
  components: {PaidFeatureNotice},

  props: {
    hasPermission: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>

</style>