<template>
  <div>
    <v-card outlined class="pa-2">
      <v-container fluid>
        <v-row>
          <v-col cols="12" class="grey-7--text">
            <div>
              <div>發送人數：{{ total }} 人</div>
            </div>
            <div>
              <div>發送內容：</div>
              <div v-text="content" class="text-pre-line"/>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <div class="mt-12">
      <v-btn color="primary" @click="submit">{{ $t('SendStep.confirm') }}</v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ConfirmContent',

  props: {
    total: {
      type: Number,
      required: true
    },
    content: {
      type: String,
      required: false
    }
  },

  methods: {
    submit() {
      this.$emit('confirm')
    }
  }
};
</script>

<style scoped>
/* Your styles here */
</style>
