<template>
  <div>
    <v-card outlined class="pa-2">
      <v-container fluid>
        <v-row>
          <v-col cols="12">
            <v-textarea
              placeholder="請輸入文字訊息"
              :value="value"
              outlined
              rows="5"
              no-resize
              hide-details="auto"
              persistent-hint
              hint="內文1～70字為1點，71～140字為2點，141～210字為3點⋯依此類推。"
              :messages="countText"
              @input="$emit('update:value', $event?.trim())"
            />
            <div class="grey-7--text text-body-2 mt-4">
              <div>
                ▲備註一：輸入的字數與實際發送的字數可能有些微差異，將會依照實際發送的字數來扣點。
              </div>
              <div>
                ▲備註二：近期政府推動反詐騙宣導，若內文涉及敏感字眼，可能會有被阻擋發送之風險。 若有遇到發送不成功的情況，請再洽詢
                <a :href="$enum.DENTCO_CS_LINE" target="_blank" class="link_blue--text">牙醫小幫手客服</a>。
              </div>
              <div>
                ▲備註三：承上則，每封客製化簡訊將會經由電信企業審核，無詐騙疑慮才能送出。審核時間長度不一，無法立即送達，敬請耐心等候。
              </div>
              <div>
                ▲備註四：請勿在訊息內放入網址連結，否則可能會被判定為詐騙簡訊而導致發送失敗。
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <div class="mt-12">
      <v-btn color="primary" @click="submit">{{ $t('SendStep.confirmContent') }}</v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MessageEditor',

  props: {
    value: {
      type: String,
      required: null
    },
  },

  computed: {
    countText() {
      if (this.value)
        return `已編輯 ${this.value.length} 字`
    }
  },

  methods: {
    submit() {
      this.$emit('nextStep')
    }
  }
};
</script>

<style scoped>
/* Your styles here */
</style>
