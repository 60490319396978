<template>
  <v-row v-if="education" dense class="flex-nowrap edit">
    <v-col cols="3" sm="2" class="align-self-center">
      <v-avatar size="80">
        <v-img v-if="selectSchool" :src="selectSchool.logo" :alt="selectSchool.name">
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular indeterminate color="grey lighten-5"/>
            </v-row>
          </template>
        </v-img>
      </v-avatar>
    </v-col>
    <v-col cols="auto" sm="8" class="flex-shrink-1">
      <v-row dense>
        <v-col cols="12" sm="8">
          <v-select v-model="selectSchool" :items="educationItems" item-text="name" return-object dense hide-details
                    :style="selectSchool.name === '其他' ? 'max-width: 65px' : 'max-width: 200px'" class="d-inline-block"/>
          <v-text-field v-if="selectSchool.name === '其他'" v-model="education.name" :placeholder="$t('Education.schoolName')" dense hide-details
                        style="max-width: 150px" class="d-inline-block"/>
        </v-col>
        <v-col cols="12" sm="4">
          <v-text-field v-model="education.partment" :placeholder="$t('Education.department')" dense hide-details style="max-width: 200px"/>
        </v-col>
        <v-col cols="12">
          <v-row dense no-gutters>
            <v-col cols="auto">
              <v-select v-model="education.year[0]" :items="startYearItems" :suffix="$t('Education.year')" dense hide-details style="max-width: 100px"/>
            </v-col>
            <v-col cols="1" class="align-self-center text-center">~</v-col>
            <v-col cols="auto">
              <v-select v-model="education.year[1]" :items="endYearItems" :suffix="$t('Education.year')" dense hide-details style="max-width: 100px"/>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="auto" class="align-self-center ml-auto">
      <v-btn icon color="primary" @click="education.delete = true">
        <v-icon>mdi-trash-can-outline</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
import { generateArrayOfYears } from "../../../utils/tool";

export default {
  name: "Education",
  props: ['data'],
  data() {
    return {
      edit: false,
      education: null,
      selectSchool: {},
      startYear: null,
      endYear: null,
      startYearItems: generateArrayOfYears(80),
      endYearItems: generateArrayOfYears(80, this.$t('Education.present')),
    }
  },
  computed: {
    ...mapState({
      educationItems: state => state.educationItems,
    }),
  },
  watch: {
    selectSchool: {
      handler: function (val) {
        if (val.name !== '其他') {
          delete this.education.name
        }
        this.education.school = this.selectSchool.name
      },
      deep: true
    },
  },
  methods: {
    reset() {
      this.education = this.data
      const select = this.educationItems.find(e => e.name === this.data.school)
      if (select) this.selectSchool = select
      else if (this.data.school) this.selectSchool = this.educationItems[this.educationItems.length - 1]
    }
  },
  mounted() {
    // if (this.data.school === '無') this.$emit('delete')
    this.reset()
  }
}
</script>

<style scoped lang="scss">
.edu {
  .edit.row + .row--dense {
    margin-top: 24px;
  }
}
</style>