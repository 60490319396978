<template>
  <v-dialog v-model="dialog" persistent max-width="1080px" :disabled="disabled">
    <template #activator="{ on, attrs }">
      <v-hover v-if="!hasCustomSlot" v-slot="{ hover }">
        <v-avatar
          ref="avatar"
          :height="height"
          :rounded="false"
          color="grey"
          :style="imgStyle"
          v-bind="attrs"
          style="position: relative; width: 100%; border-radius: 10px"
          v-on="on"
        >
          <slot/>
          <div
            v-if="hover"
            class="d-flex justify-center align-center mx-auto"
            style="
              position: absolute;
              background-color: rgba(0, 0, 0, 0.2);
              top: 0px;
              left: 0px;
              width: 100%;
              height: 100%;
              border-radius: 10px;
            "
          >
            <v-icon color="white">
              mdi-pencil-outline
            </v-icon>
          </div>
        </v-avatar>
      </v-hover>
      <div v-else v-bind="attrs" v-on="on">
        <slot name="custom"/>
      </div>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h5 font-weight-bold">{{ value.id ? "編輯" : "新增" }}衛教/案例圖片</span>
        <v-spacer/>
        <v-btn text class="px-0" small @click="onClose">
          <v-icon size="15">
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-divider/>
      <v-card-text class="pt-4" style="position: relative">
        <v-row>
          <v-col cols="12" md="6">
            <h3 class="text-subtitle-1 grey--text text--darken-2 mb-3">
              圖片（最多 {{ postImgLimit }} 張）
            </h3>
            <div class="text-body-2 grey--text text--darken-2 mb-6">
              注意事項：
              <ul style="list-style: -moz-ethiopic-numeric">
                <li>圖片可自由調整順序，首張圖片為封面。</li>
                <li>圖片檔案大小2MB內。</li>
                <li>
                  若遇到任何問題可洽<a class="link_blue--text text-decoration-underline"
                                       style="text-underline-offset:2px">客服</a>協助您。
                </li>
              </ul>
            </div>

            <v-sheet max-height="315" class="overflow-y-auto">
              <draggable
                v-model="post.gallery"
                tag="div"
                class="d-flex flex-wrap"
                handle=".draggable"
                animation="200"
                style="gap: 0.8rem"
                @start="drag = true"
                @end="drag = false"
              >
                <v-slide-y-transition
                  v-for="(thumbnail, index) in post.gallery"
                  :key="index"
                >
                  <div class="thumbnail-img draggable" style="position: relative">
                    <v-btn
                      color="white"
                      fab
                      x-small
                      light
                      style="
                      position: absolute;
                      top: 0px;
                      right: 0px;
                      width: 20px;
                      height: 20px;
                    "
                      @click="post.gallery.splice(index, 1)"
                    >
                      <v-icon size="20">
                        mdi-close
                      </v-icon>
                    </v-btn>
                    <img :src="getSrc(thumbnail)" alt="">
                  </div>
                </v-slide-y-transition>
                <image-upload-tool
                  v-if="post.gallery.length < postImgLimit"
                  :max-limit="postImgLimit - post.gallery.length"
                  :max-file-size="gallerySize"
                  @submit="post.gallery.push(...$event)"
                >
                  <v-hover v-slot="{ hover }">
                    <div
                      class="d-flex flex-column align-center justify-center thumbnail-img"
                      role='button'
                      style="gap: 1rem; position: relative"
                    >
                      <v-overlay :value="hover" absolute opacity="0.3">
                        <v-icon color="white" sm>
                          mdi-camera
                        </v-icon>
                      </v-overlay>
                      <v-icon size="30" color="grey lighten-1" style="flex:0 0 0">
                        mdi-image-area
                      </v-icon>
                      <p class="text-subtitle-2">
                        上傳圖片
                      </p>
                    </div>
                  </v-hover>
                </image-upload-tool>
              </draggable>
            </v-sheet>
          </v-col>
          <v-col cols="12" md="6">
            <div class="d-flex flex-column mb-6" style="gap: 0.6rem">
              <div class="text-subtitle-1 grey--text text--darken-2">
                標題
              </div>
              <v-text-field
                v-model="post.title"
                placeholder="請輸入標題"
                dense
                hide-details
                outlined
                required
                aria-required="true"
              />
            </div>
            <div class="d-flex flex-column mb-6" style="gap: 0.6rem">
              <div class="text-subtitle-1 grey--text text--darken-2">
                分類
              </div>
              <v-select
                v-model="post.category_id"
                multiple
                :items="categories"
                item-text="name"
                item-value="id"
                dense
                hide-details
                outlined
                required
                aria-required="true"
              >
                <template #append-item>
                  <health-category-dialog
                    :categories="categories"
                    @update="$emit('updateCategories', $event)"
                  />
                </template>
              </v-select>
            </div>
            <div class="d-flex flex-column mb-6" style="gap: 0.6rem">
              <div class="text-subtitle-1 grey--text text--darken-2">
                內容
              </div>
              <v-textarea
                v-model="post.content"
                placeholder="請輸入內容"
                rows="5"
                dense
                hide-details
                outlined
                required
                aria-required="true"
                no-resize
              />
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="pb-7">
        <v-btn v-if="post.id" @click="onDelete" text color="red">刪除</v-btn>
        <v-spacer/>
        <v-btn
          color="grey"
          outlined
          class="text-subtitle-2 rounded-lg"
          @click="onClose"
        >
          取消
        </v-btn>
        <v-btn
          color="teal lighten-2"
          class="text-subtitle-2 rounded-lg white--text"
          @click="onConfirm"
        >
          儲存
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import draggable from 'vuedraggable';
import HealthCategoryDialog from '@/components/settings/doctor/v2/HealthCategoryDialog.vue';
import UploadImageDialog from "@/components/utils/UploadImageDialog.vue";
import ImageUploadTool from "@/components/utils/ImageUploadTool.vue";

export default {
  name: 'EditorHealthImageDialog',
  components: {ImageUploadTool, UploadImageDialog, HealthCategoryDialog, draggable},
  props: {
    value: {
      type: Object,
      default: () => {
        return {
          id: 0,
          category_id: [],
          title: '',
          content: '',
          alt: '',
          gallery: []
        };
      }
    },
    imgStyle: {
      type: [Object, String],
      default: ''
    },
    categories: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      postImgLimit: 10,
      gallerySize: 2 * 1024 * 1024,
      drag: false,
      height: 100,
      dialog: false,
      file: null,
      post: {
        category_id: [],
        title: '',
        content: '',
        alt: '',
        gallery: []
      },
      newGallery: []
    };
  },
  computed: {
    hasCustomSlot() {
      return !!this.$slots.custom;
    }
  },
  watch: {
    dialog(dialog) {
      if (dialog) {
        if (this.value) {
          this.post = JSON.parse(JSON.stringify(this.value));
        } else {
          this.post = {
            category_id: [],
            title: '',
            src: '',
            alt: '',
            thumbnails: []
          };
        }
      }
    },
    value: {
      handler() {
        this.$nextTick(this.onImageResize);
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    window.addEventListener('resize', this.onImageResize);
    this.$nextTick(this.onImageResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onImageResize);
  },
  methods: {
    getSrc(value) {
      if (typeof value === 'string') {
        return value;
      } else {
        return URL.createObjectURL(value);
      }
    },
    onImageResize() {
      if (this.$refs.avatar && this.$refs.avatar.$el) {
        this.height = this.$refs.avatar.$el.clientWidth || 100;
      }
    },
    onConfirm() {
      this.$emit('update', {
        post: this.post,
        callback: (status) => {
          if (status === 'success') {
            this.onClose();
          } else {
            if (this.post.id)
              alert('更新失敗，請再試一次')
            else
              alert('新增失敗，請再試一次')
          }
        }
      });
      this.onImageResize();
    },
    addCategories(value) {
      this.$emit('update:categories', value);
    },
    onClose() {
      this.file = null;
      this.dialog = false;
    },
    onDelete() {
      this.$emit('delete', {
        callback: (status) => {
          if (status === 'success') {
            this.onClose();
          } else {
            alert('刪除失敗，請再試一次')
          }
        }
      });
    }
  }
};
</script>
<style scoped>
.thumbnail-img {
  width: 150px;
  height: 150px;
  flex: 0 0 150px;
  min-width: 150px;
  background: #f5f5f5;
  text-align: center;
  border-radius: 10px;
  overflow: hidden;
}

.thumbnail-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
</style>
