export default {
  home: '/2-1-2-2-CRM-6856311a57974ea99a4f5af03fa11d85',
  onlineAppointment: {
    title: '線上約診',
    subTitle: '可瀏覽和處理來自各管道的線上預約，',
    docLink: '/2-3-CRM-e90185e684f44a0fabf40877e5cfd8ae'
  },
  reschedule: {
    title: '改約名單',
    subTitle: '可瀏覽和處理患者改約，',
    docLink: '/2-3-CRM-e90185e684f44a0fabf40877e5cfd8ae'
  },
  care: {
    title: '術後關懷',
    subTitle: '可瀏覽患者收到術後關懷訊息的回覆，',
    docLink: '/2-5-CRM-483ff757dd044ff29816e8ca27fea68f'
  },
  message: {
    send: {
      line: {
        title: 'Line訊息群發',
        subTitle: '可彈性篩選患者和發送文字與圖片訊息，也可針對您的需求，自由選擇各式套版發送。',
        docLink: '/2-4-CRM-65a810e340904843bb9e8f92a47148de'
      },
      sms: {
        title: '簡訊群發',
        subTitle: '可彈性篩選患者和發送文字，'
      }
    },
    store: {
      title: '點數儲值',
      subTitle: '∙ ',
      docLink: '/7-35be2313b71a4de9ac184fbc8b8f0bcd'
    },
    setting: {
      title: '訊息設定',
      subTitle: '獨立設定不同管道的訊息發送通知，包含看診提醒、評價邀請、術後關懷、衛教諮詢、半年定檢。',
      docLink: '/2-4-CRM-65a810e340904843bb9e8f92a47148de'
    },
    log: {
      title: '發送紀錄',
      subTitle: '保留90天內的紀錄可以查詢 (設定至少選擇1天，至多30天)，再選擇要瀏覽的訊息種類，即可取得發送紀錄與下載檔案。',
      docLink: '/2-4-CRM-65a810e340904843bb9e8f92a47148de'
    },
    closeNotification: {
      title: '關閉當日訊息',
      subTitle: '勾選日的當日所有訊息都不會發送。'
    }
  },
  onlineAppointmentSetting: {
    title: '線上預約管道與模式設定',
    subTitle: '可設定醫師指定的預約模式，',
    docLink: '/2-3-CRM-e90185e684f44a0fabf40877e5cfd8ae'
  },
  treatmentSetting: {
    title: '治療項目',
    subTitle: '設定線上預約時讓民眾預約的項目，您的項目將連動搜尋分類，請認真設置，',
    suffix: '*因Google政策，若希望增強Google商家的搜尋，建議您先選擇小幫手提供的分類，再新增您的治療名稱， 若都使用其他分類，會讓Google演算法無法正確識別。'
  },
  patient: {
    title: '我的病人',
    subTitle: '可瀏覽各管道的會員資料和編輯黑名單，',
    docLink: '/2-7-CRM-07dbdf7f1ae147df9a047a653a567a33'
  },
  review: {
    overview: {
      title: '評價列表',
      subTitle: '可瀏覽每筆站內評價內容與分數，',
      docLink: '/2-6-CRM-c081e1d133714d889e86f7e1e0464db6'
    },
    googleReviewList: {
      title: 'Google評價列表',
      subTitle: '可瀏覽每筆Google評價內容與分數，'
    },
    setting: {
      mode: {
        title: '評價設定',
        subTitle: '可設定診所與醫師指定的評價方式、是否公開顯示，',
        docLink: '/2-6-CRM-c081e1d133714d889e86f7e1e0464db6'
      },
      ai: {
        title: '自動化 AI 評價回覆',
        subTitle: '可設定醫師/診所指定的回覆風格、加入Emoji及SEO關鍵字，並可選擇手動或自動發送回覆，',
      }
    }
  },
  upload: {
    title: '上傳專區',
    subTitle: '可在此上傳約診資料至約診狀態表 (限小天使、牙谷、DOLPHIN、優仕系統)，'
  },
  analytics: {
    invite: {
      title: '患者推薦',
      subTitle: '可瀏覽綁定率與推薦成長，'
    },
    onlineAppointment: {
      title: '線上約診成效',
      subTitle: '可查看網頁瀏覽量和預約趨勢圖，'
    },
    patient: {
      title: '病患分析',
      subTitle: '可瀏覽會員綁定狀況和語系分佈，'
    }
  },
  administrator: {
    title: 'LINE 智能助理權限',
    subTitle: '可瀏覽和編輯不同權限的人員名單，'
  },
  question: {
    title: '詢問留言板',
    subTitle: '可設定是否要開放留言板、回覆民眾提問，',
    docLink: '/2-8-CRM-d139776b1cdb4f69828333eae72bd21e'
  },
  superLink: {
    title: '連結小工具',
    subTitle: '',
    docLink: '/2-9-CRM-fb19e42962ea4caf93e997e42015d8fe'
  },
  AICustomerService: {
    version: {
      title: 'AI智能客服',
      subTitle: '',
      docLink: '/2-10-CRM-AI-affc135cbc5240ac877396a395115af2'
    },
    dataPack: {
      title: '匯入資料包',
      subTitle: '',
      docLink: '/2-10-CRM-AI-affc135cbc5240ac877396a395115af2'
    },
    qa: {
      title: '個性化QA',
      subTitle: '',
      docLink: '/2-10-CRM-AI-affc135cbc5240ac877396a395115af2'
    },
    interactiveHistory: {
      title: '互動紀錄',
      subTitle: '',
      docLink: '/2-10-CRM-AI-affc135cbc5240ac877396a395115af2'
    }

  }
}
