<template>
  <v-main>
    <navigation v-if="!liffUser"/>
    <guide-tour config-key="question"/>
    <v-card flat class="mx-auto">
      <v-container fluid>
        <v-checkbox
          v-model="questionActive"
          :true-value="1"
          :false-value="0"
          label="啟用線上詢問留言板"
          style="width: fit-content;"
          @change="handleSwitchQuestionActive"
        />
        <v-dialog v-model="alertActiveDialog" max-width="266" persistent>
          <v-card>
            <v-card-title>關閉留言板將會流失 NP 諮詢與預約的機會唷！</v-card-title>
            <v-card-actions>
              <v-btn dark color="red-6" @click="switchQuestionActive">確定關閉</v-btn>
              <v-spacer/>
              <v-btn color="primary" @click="cancelSwitchActive">維持開啟</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-container>
      <v-container class="pb-5" fluid>
        <v-tabs v-model="tabs">
          <v-tab>全部留言</v-tab>
          <v-tab>已回復</v-tab>
          <v-tab>未回復</v-tab>
        </v-tabs>
        <div>
          <template v-for="question in questions">
            <question-detail-card :question="question" :key="question.id" @refresh="getQuestions"/>
            <v-divider/>
          </template>
        </div>
        <v-pagination
          v-model="page"
          class="my-4"
          :length="maxPage"
          total-visible="10"
        ></v-pagination>
      </v-container>
    </v-card>
  </v-main>
</template>

<script>
import Navigation from "../components/Navigation";
import QuestionDetailCard from "../components/question/QuestionDetailCard";
import { mapGetters } from "vuex";
import {
  getClinicQuestionActiveAPI, getDoctorQuestionActiveAPI,
  getQuestionsAPI,
  updateClinicQuestionActiveAPI,
  updateDoctorQuestionActiveAPI
} from "../api";
import GuideTour from "../components/utils/GuideTour.vue";

export default {
  name: "Question",
  components: {GuideTour, QuestionDetailCard, Navigation},
  data() {
    return {
      tabs: 0,
      questions: [],
      page: 1,
      limit: 10,
      maxPage: 1,
      questionActive: null,
      alertActiveDialog: false
    }
  },
  computed: {
    ...mapGetters([
      'axiosParamData',
      'liffUser',
      'clinicUser',
      'doctorUser'
    ]),
  },
  watch: {
    page() {
      window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
      this.getQuestions()
    },
    tabs() {
      if (this.page === 1) {
        this.getQuestions()
      }
      this.page = 1
    },
  },
  methods: {
    getQuestions() {
      const params = {
        ...this.axiosParamData,
        page: this.page,
        limit: this.limit,
        kind: this.tabs,
      }
      getQuestionsAPI(params).then(res => {
        this.questions = res.data.data
        this.maxPage = parseInt(res.data.paginate.page_total)
      })
    },
    handleSwitchQuestionActive(newV) {
      if (newV) {
        this.switchQuestionActive()
      } else {
        this.alertActiveDialog = true
      }
    },
    switchQuestionActive() {
      let api
      if (this.clinicUser) {
        api = updateClinicQuestionActiveAPI
      }
      if (this.doctorUser) {
        api = updateDoctorQuestionActiveAPI
      }
      api({...this.axiosParamData, question_active: this.questionActive}).then(() => {
        this.alertActiveDialog = false
        this.getQuestionActive()
      })
    },
    cancelSwitchActive() {
      this.questionActive = 1
      this.alertActiveDialog = false
    },
    getQuestionActive() {
      let api
      if (this.clinicUser) {
        api = getClinicQuestionActiveAPI
      }
      if (this.doctorUser) {
        api = getDoctorQuestionActiveAPI
      }
      api(this.axiosParamData).then(res => {
        this.questionActive = res.data.question_active
      })
    }
  },
  mounted() {
    this.getQuestions()
    this.getQuestionActive()
  }
}
</script>

<style scoped>

</style>
