<template>
  <v-row dense no-gutters class="flex-nowrap">
    <v-col cols="6">
      <v-menu
        ref="menu1"
        v-model="menu1"
        :close-on-content-click="false"
        :nudge-right="40"
        :return-value.sync="time1"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="time1"
            :label="$t('EditTime.from')"
            dense
            prepend-icon="mdi-clock-outline"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-time-picker
          v-if="menu1"
          v-model="time1"
          format="24hr"
          full-width
          :allowed-minutes="m => m % 30 === 0"
          @click:minute="save('menu1', time1)"
        ></v-time-picker>
      </v-menu>
    </v-col>
    <v-col cols="6">
      <v-menu
        ref="menu2"
        v-model="menu2"
        :close-on-content-click="false"
        :nudge-right="40"
        :return-value.sync="time2"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="time2"
            :label="$t('EditTime.to')"
            dense
            prepend-icon="mdi-clock-outline"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-time-picker
          v-if="menu2"
          v-model="time2"
          format="24hr"
          full-width
          :allowed-minutes="m => m % 30 === 0"
          @click:minute="save('menu2', time2)"
        ></v-time-picker>
      </v-menu>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "EditTime",
  props: ['times'],
  data() {
    return {
      menu1: false,
      menu2: false,
      time1: null,
      time2: null
    }
  },
  methods:{
    save(name, time) {
      this.$refs[name].save(time)
      if (name === 'menu1') this.times[0] = time
      else this.times[1] = time
    }
  },
  mounted() {
    this.time1 = this.times[0]
    this.time2 = this.times[1]
  }
}
</script>

<style scoped>

</style>