<template>
  <div>
    <v-file-input
      v-model="image"
      :accept="accept"
      ref="file"
      style="display:none;"
    ></v-file-input>
    <v-card v-if="!imageUrl" hover @click="$refs.file.$refs.input.click()">
      <v-responsive :aspect-ratio="aspectRatio" class="align-center text-center">
        <v-icon>mdi-image-plus</v-icon>
      </v-responsive>
    </v-card>
    <v-img
      v-else
      :aspect-ratio="fixRatio ? aspectRatio : null" :src="imageUrl" @click="$refs.file.$refs.input.click()"
      contain
      style="cursor: pointer; border: 1px dotted #33b6b0;"
    />
  </div>
</template>

<script>
export default {
  name: "UploadImg",
  props: {
    value: File,
    aspectRatio: {default: 16 / 9},
    fixRatio: Boolean,
    accept: {default: 'image/png, image/jpeg, image/bmp'},
    oImgUrl: String
  },
  data() {
    return {
      image: null,
      imageUrl: null
    }
  },
  watch: {
    image(v) {
      this.$emit('input', this.image)
      if (v) {
        this.imageUrl = URL.createObjectURL(this.image)
      }
      else this.imageUrl = null
    },
  },
  mounted() {
    if (this.oImgUrl) this.imageUrl = this.oImgUrl
  }
  // methods: {
  //   previewImage() {
  //     if (this.image) this.imageUrl = URL.createObjectURL(this.image)
  //   },
  // }
}
</script>

<style scoped>

</style>