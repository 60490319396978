<template>
  <v-sheet>
    <div class="d-flex">
      <div class="text-subtitle-1 align-self-center">{{ title }}
        <v-avatar v-if="count" color="red" size="18" style="vertical-align: baseline">
          <span class="white--text text-caption" v-text="count"/>
        </v-avatar>
      </div>
      <v-spacer/>
      <v-btn text color="grey darken-1" :to="toPath" class="px-1" active-class="no-active" @click="$emit('refresh', toPath)">查看全部
      </v-btn>
    </div>
    <v-list v-show="items.length" max-height="50vh" class="overflow-y-auto">
      <v-list-item
        v-for="item in items"
        :key="item.id"
        class="mb-1 px-1 no-active"
        :to="toPath"
        @click="$emit('refresh', toPath)"
      >
        <v-list-item-content class="pa-0">
          <div class="d-flex">
            <v-img
              :src="assetURL('/asset/crm/logo_square.webp')"
              max-width="60"
              max-height="60"
              class="mr-2 align-self-center"
            />
            <div class="flex-fill">
              <div v-if="getDateTime" class="text--secondary text-caption mb-1">
                {{ getDateTime(item) }}
              </div>
              <div
                class="text-pre-line overflow-hidden multiline-ellipsis"
                v-text="getContent(item)"
                style="max-height: 47px"
              />
            </div>
          </div>

        </v-list-item-content>
      </v-list-item>
    </v-list>
    <div v-show="!items.length" class="text-center grey--text text--darken-1 mt-2" v-text="noData"/>
  </v-sheet>
</template>

<script>
import { assetURL } from "@/utils/filters";

export default {
  name: "NotificationCard",
  props: {
    items: Array,
    title: String,
    count: Number,
    toPath: Object,
    noData: String,
    getContent: Function,
    getDateTime: Function
  },
  data() {
    return {
      assetURL
    }
  }
}
</script>

<style scoped>
.multiline-ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.v-list-item--active.no-active::before {
  display: none
}
</style>