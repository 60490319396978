<template>
  <v-main>
    <navigation/>
    <guide-tour config-key="upload"/>
    <v-tabs
      v-model="tab"
      background-color="transparent"
      color="basil"
      grow
    >
      <v-tab v-for="(item, i) in uploadItems" :key="i">
        {{ item.name }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item v-for="(item, i) in uploadItems" :key="i">
        <v-card
          color="basil"
          flat
        >
          <v-container style="max-width: 800px; width: 80%">
            <v-row>
              <v-col v-if="doctorUser" cols="12">
                <v-select
                  v-model="selectClinic"
                  :items="clinicItems"
                  item-value="clinic_code"
                  item-text="clinic_name"
                  hide-details
                  outlined
                  dense
                />
              </v-col>
              <v-col cols="12">
                <v-file-input
                  v-model="file"
                  show-size
                  :label="item.inputLabel"
                  :disabled="isLoading"
                  prepend-icon="mdi-folder-upload"
                  :accept="item.accept"
                ></v-file-input>
              </v-col>
              <v-col class="text-center">
                <v-btn @click="handleFileUpload(item.api)" :loading="isLoading" :disabled="doctorUser ? (!selectClinic || !file) : !file">
                  {{ $t('Upload.upload') }}</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-main>
</template>

<script>
import { mapGetters } from "vuex";
import { getClinicListAPI, uploadAngelAPI, uploadDolphinAPI, uploadFiadigitAPI, uploadDrcooperAPI } from "@/api";
import Navigation from "../components/Navigation";
import GuideTour from "../components/utils/GuideTour.vue";

export default {
  name: "Upload",
  components: {GuideTour, Navigation},
  data() {
    return {
      file: null,
      isLoading: false,
      tab: null,
      uploadItems: [
        {name: '小天使專區', inputLabel: 'Excel 檔案', accept: '.xlsx, .xls', api: 'angel'},
        {name: 'Dolphin、牙谷專區', inputLabel: 'csv 檔案', accept: '.csv', api: 'dolphin'},
        {name: '優仕專區', inputLabel: 'Excel 檔案', accept: '.xlsx, .xls, .csv', api: 'fiadigit'},
        {name: '北昕專區', inputLabel: 'csv 檔案', accept: '.csv', api: 'drcooper'}
      ],
      clinicItems: [{clinic_code: null, clinic_name: this.$t('Upload.chooseClinic')}],
      selectClinic: null,
    }
  },
  computed: {
    ...mapGetters([
      'axiosParamData',
      'doctorUser'
    ]),
    mapFunc() {
      return {
        angel: uploadAngelAPI,
        dolphin: uploadDolphinAPI,
        fiadigit: uploadFiadigitAPI,
        drcooper: uploadDrcooperAPI
      }
    }
  },
  methods: {
    handleFileUpload(apiPath) {
      if (this.file) {
        this.isLoading = true
        const data = {
          ...this.axiosParamData,
          file: this.file,
        }
        if (this.doctorUser) data.clinic_code = this.selectClinic
        this.mapFunc[apiPath](data).then((res) => {
          if (res.data.count) {
            alert(`已成功上傳${res.data.count}筆約診資料，如有數量落差請重新匯出上傳，同步時間為10分鐘請稍等`)
          } else {
            alert(this.$t('Upload.success'))
          }
        }).catch(e => {
          if (e.response && e.response.data && e.response.data.messages) {
            alert(e.response.data.messages)
          } else {
            alert(this.$t('Upload.fileLargeError'))
          }
        }).finally(() => {
          this.isLoading = false
        })
        this.file = null
      } else {
        alert(this.$t('Upload.chooseFile'))
      }
    },
  },
  mounted() {
    if (this.doctorUser) {
      getClinicListAPI(this.axiosParamData).then(res => {
        this.clinicItems.push(...res.data)
      })
    }
  }
}
</script>

<style scoped>

</style>
