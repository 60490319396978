<template>
  <v-main>
    <navigation/>
    <v-card max-width="600" flat class="mx-auto mt-16 px-6">
      <v-form @submit.prevent="confirmSwitch">
        <v-select
          v-model="formData.selectType"
          :items="typeOptions"
          label="請選擇欲切換的身份"
        />
        <v-autocomplete
          v-model="formData.selectUser"
          :items="userList"
          label="選擇要切換的帳號(可文字搜尋)"
          item-text="name"
          item-value="code"
        />
        <v-card-actions class="justify-center pt-8">
          <v-btn type="submit" width="200" color="primary">確認</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-main>
</template>

<script>
import Navigation from "../components/Navigation.vue";
import { listClinicsAPI, listDoctorsAPI, switchAccountAPI } from "../api";
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "SwitchAccount",

  components: {Navigation},

  data() {
    return {
      clinicList: [],
      doctorList: [],
      typeOptions: [
        {text: '診所', value: 1},
        {text: '醫師', value: 2},
      ],

      formData: {
        selectType: 1,
        selectUser: null
      }
    }
  },

  computed: {
    ...mapGetters([
      'axiosParamData',
      'isSuper'
    ]),
    userList() {
      switch (this.formData.selectType) {
        case 1:
          return this.clinicList.map(e => ({name: `${e.name} ${e.code} (${e.county}${e.area})`, code: e.code}))
        case 2:
          return this.doctorList.map(e => ({name: `${e.name}(${e.code})`, code: e.code}))
      }

    }
  },

  methods: {
    ...mapMutations({
      setUserData: 'setUserData'
    }),
    confirmSwitch() {
      if (this.isSuper) {
        const data = {
          ...this.axiosParamData,
          plan: 99,
          switch_code: this.formData.selectUser,
          switch_type: String(this.formData.selectType)
        }
        switchAccountAPI(data).then(res => {
          this.setUserData(res.data)
          this.$router.push({name: 'home'})
        })
      } else {
        this.$router.push({name: 'home'})
      }
    }
  },

  mounted() {
    if (!this.isSuper) {
      return this.$router.push({name: 'home'})
    }
    listClinicsAPI(this.axiosParamData).then(res => {
      this.clinicList = res.data
    })
    listDoctorsAPI(this.axiosParamData).then(res => {
      this.doctorList = res.data
    })
  }
}
</script>

<style scoped>

</style>
