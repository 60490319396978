<template>
  <v-card class="p-0" rounded="xl">
    <div style="padding: 20px 30px">
      <div class="d-flex justify-space-between align-center">
        <h6 class="text-subtitle-1 font-weight-bold">
          擅長治療
        </h6>
        <v-btn
          color="teal lighten-2"
          class="text-subtitle-2 rounded-lg"
          dark
          style="width: 100px; height: 40px"
          @click="$emit('edit')"
        >
          <v-icon dark size="15" class="mr-2">
            mdi-pencil
          </v-icon>
          編輯
        </v-btn>
      </div>
      <v-row
        v-if="hasPermission"
        class="my-2 overflow-y-auto overflow-x-hidden"
        style="max-height: 300px"
      >
        <v-col
          v-for="t in hashtagItems"
          :key="JSON.stringify(t)"
          cols="12"
          md="6"
          class="d-flex align-center"
        >
          <v-avatar size="60" class="mr-2">
            <v-img
              :src="getIconSrc(t.dental_icon_id)"
              alt=""
            />
          </v-avatar>
          <div class="overflow-x-hidden flex-grow-1">
            <h3
              class="text-subtitle-1 font-weight-bold black--text mb-1 text-truncate"
            >
              {{ t.title }}
            </h3>
            <p class="mb-0 text-body-2 grey--text lighten-4">
              {{ t.description }}
            </p>
          </div>
        </v-col>
      </v-row>
      <v-row v-else class="my-2">
        <v-chip
          v-for="t in hashtagItems"
          :key="JSON.stringify(t)"
          class="ma-2 rounded-xl"
          label
          outlined
          color="teal"
        >
          {{ t.title }}
        </v-chip>
      </v-row>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "HashTagCard",

  props: {
    hashtagItems: {
      type: Array,
      default: () => []
    },
    hasPermission: {
      type: Boolean,
      default: false
    },
    dentcoIcons: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },

  methods: {
    getIconSrc(id) {
      const icon = this.dentcoIcons.find(icon => icon.id === id)
      return icon?.full_path
    },
  }
}
</script>

<style scoped>

</style>