<template>
  <v-app translate="no">
    <template v-if="hasAccess()">
      <router-view></router-view>
    </template>
    <template v-else>
      <navigation/>
      <v-main>
        <v-card max-width="500" height="60vh" flat class="mx-auto d-flex justify-center align-center text-h5">
          您沒有權限閱讀這個頁面
        </v-card>
      </v-main>
    </template>
    <v-dialog v-if="globalAlert" v-model="globalAlert.dialog" persistent max-width="400">
      <v-card class="text-pre-line">
        <v-card-title class="text-center justify-center">{{ globalAlert.message }}</v-card-title>
        <v-card-actions class="justify-center">
          <v-btn color="primary" :to="globalAlert.to" @click="confirmGlobalAlert">確認</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-if="popUpNotice" v-model="popUpNotice.dialog" persistent width="fit-content" max-width="600" scrollable>
      <v-sheet class="text-pre-line pa-4 overflow-y-auto" width="fit-content" style="position: relative;">
        <v-btn
          fab
          icon
          absolute
          width="36"
          height="36"
          style="top: 0; right: 0; overflow: hidden;"
          @click="popUpNotice.dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-sheet v-if="popUpNotice.file_name" color="transparent" class="text-center mx-auto">
          <img :src="popUpNotice.file_name" alt="" style="vertical-align: bottom; max-height: 400px; max-width: 100%">
        </v-sheet>
        <v-sheet v-if="popUpNotice.content" class="text-center mx-auto">
          {{ popUpNotice.content }}
        </v-sheet>
        <v-btn
          v-if="popUpNotice.link_1"
          block
          target="_blank"
          color="#00A199"
          :href="popUpNotice.link_1"
          class="white--text mt-4"
          @click="popUpNotice.dialog = false"
        >點我了解更多
          <v-icon small dark>mdi-chevron-right-circle</v-icon>
        </v-btn>
        <v-btn v-else block color="#00A199" class="white--text mt-4" @click="popUpNotice.dialog = false">確認</v-btn>
      </v-sheet>
    </v-dialog>
  </v-app>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import Navigation from "@/components/Navigation";

export default {
  components: {Navigation},
  data() {
    return {
      globalAlertDialog: false
    }
  },
  computed: {
    ...mapGetters([
      'clinicUser',
      'doctorUser',
      'axiosParamData',
      'isSpecialClinic',
    ]),
    ...mapState([
      'globalAlert',
      'popUpNotice',
    ])
  },
  mounted() {

  },
  methods: {
    confirmGlobalAlert() {
      this.globalAlert.dialog = false
      if (this.globalAlert.click)
        this.globalAlert.click()
    },
    hasAccess() {
      if (this.isSpecialClinic) {
        if (!this.$router.currentRoute.meta.canSpecial) return false
      }
      return true
    }
  }
};
</script>

<style lang="scss">
.v-btn--active.no-active::before {
  display: none
}

.embedded-video-16-9 {
  width:  90vw;
  height: 50.625vw;
  margin: 32px auto;
  max-width: 960px;
  max-height: 540px;
}

.bullet::before {
  content: "";
  background: var(--v-tiffany-10-base);
  padding-inline: 5px;
  height: 10px;
  margin-inline-end: 10px;
  display: inline-block;
  border-radius: 50% 50%;
}
</style>
