<template>
  <v-row dense class="align-center" :style="hasPermission ? '' : 'opacity: 0.4;'">
    <template v-for="(v, idx) in social.value">
      <v-col cols="12" md="3" class="bullet py-2" :key="idx">
        {{ `${social.title}${idx + 1}` }}
      </v-col>
      <v-col cols="12" md="9" class="py-2">
        <v-text-field
          :value="v"
          class="setting-input"
          filled
          rounded
          single-line
          placeholder="請輸入網址"
          hide-details="auto"
          :disabled="!canEdit"
          @input="$emit('updateValue', $event, social.key, {idx})"
        />
      </v-col>
    </template>
  </v-row>
</template>

<script>
export default {
  name: "MultiSocialRow",

  props: {
    social: {
      type: Object,
      required: true
    },
    hasPermission: {
      default: false,
      type: Boolean
    },
    canEdit: {
      default: false,
      type: Boolean
    },
  },

  methods: {
  }
}
</script>

<style scoped>
.bullet::before {
  content: "";
  background: #000000;
  padding-inline: 5px;
  height: 10px;
  margin-inline-end: 10px;
  display: inline-block;
}
</style>
