<template>
  <div>
    <div v-if="parseInt(kind) === 2" class="d-flex flex-nowrap align-center pa-1">
      <div class="ml-auto" style="width: 320px;">
        <v-text-field v-model="searchQ" placeholder="輸入姓名或手機或民國生日(例801231)" outlined hide-details dense clearable/>
      </div>
      <v-btn class="ml-2" color="primary" @click="handleSearch">搜尋</v-btn>
    </div>
    <v-data-table
      :headers="headers"
      :items="reserveItems"
      item-key="reserve_no"
      :height="parseInt(kind) === 1 ? 'calc(100vh - 256px)' : 'calc(100vh - 304px)'"
      :no-data-text="$t('RescheduleDataTable.noData')"
      :header-props="headerProps"
      :options.sync="options"
      :server-items-length="total"
      :footer-props="{itemsPerPageOptions: [10, 20, 50]}"
    >
      <template v-slot:item.start_time="{ item }">
        {{ $moment(item.start_time).add(8, 'hours').format('YYYY-MM-DD HH:mm') }}
      </template>
      <template v-slot:item.updated_time="{ item }">
        {{ $moment(item.updated_time).add(8, 'hours').format('YYYY-MM-DD HH:mm') }}
      </template>
      <template v-slot:item.reserve_change_note="{ item }">
        <span>
          {{
            (item.reserve_change_note && item.reserve_change_note.length > 20) ? item.reserve_change_note.substr(0, 20) + '...' : item.reserve_change_note
          }}
        </span>
        <v-btn icon color="primary" @click="editChangeNote(item)">
          <v-icon dark x-small>
            mdi-pencil
          </v-icon>
        </v-btn>
      </template>
      <template v-slot:item.call_clinic_status="{ item }">
        {{ callClinicMap[parseInt(item.call_clinic_status)] }}
      </template>
      <template v-slot:item.action="{ item }">
        <v-btn outlined small block color="primary" class="my-1" @click="confirmReschedule(item.reserve_no)">
          {{ $t('RescheduleDataTable.alterTime') }}
        </v-btn>
        <v-btn outlined small block color="primary" class="my-1" @click="cancelReschedule(item.reserve_no)">
          {{ $t('RescheduleDataTable.withdraw') }}
        </v-btn>
        <send-message class="my-1" :reserve-no="item.reserve_no" :patient-name="item.name"/>
      </template>
      <template v-slot:item.handle_time="{ item }">
        {{ $moment(item.handle_time).add(8, 'hours').format('YYYY-MM-DD HH:mm') }}
      </template>
    </v-data-table>
    <v-dialog v-if="changeNoteDialog" max-width="500" v-model="changeNoteDialog" persistent>
      <v-card>
        <v-card-title>{{ $t('RescheduleDataTable.editNote') }}</v-card-title>
        <v-card-text>
          <v-textarea v-model="editReserve.reserve_change_note" hide-details outlined no-resize/>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue" text @click="updateChangeNote">
            {{ $t('RescheduleDataTable.confirm') }}
          </v-btn>
          <v-btn text @click="changeNoteDialog=false">
            {{ $t('GENERAL.cancel') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-if="confirmDialog" max-width="414" v-model="confirmDialog">
      <v-card>
        <v-card-title v-html="confirmTitle"/>
        <v-card-actions class="justify-space-around">
          <v-btn text color="primary" @click="confirm">{{ $t('RescheduleDataTable.confirm') }}</v-btn>
          <v-btn text @click="reset">{{ $t('GENERAL.cancel') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { getRescheduleAPI, updateRescheduleAPI, updateReserveAPI } from '../../api';
import SendMessage from '../utils/SendMessage';

export default {
  name: "RescheduleDataTable",
  components: {SendMessage},
  props: {
    kind: {
      type: [Number, String],
      default: 1,
      required: false
    }
  },
  data() {
    return {
      reserveItems: [],
      pageLoading: false,
      callClinicMap: [this.$t('RescheduleDataTable.pleaseCallPatient'), this.$t('RescheduleDataTable.cancelWithoutConcat')],
      confirmDialog: false,
      confirmTitle: null,
      changeStatus: null,
      selectReserveId: null,
      headerProps: {
        sortByText: this.$t('RescheduleDataTable.sortByText')
      },
      changeNoteDialog: false,
      editReserve: null,
      options: {},
      total: 0,
      searchQ: null
    }
  },
  computed: {
    ...mapState({
      user: state => state.user,
    }),
    ...mapGetters([
      'axiosParamData',
      'doctorUser',
      'liffUser'
    ]),
    headers() {
      const headers = [
        {text: this.$t('RescheduleDataTable.patientName'), align: 'center', value: 'name', sortable: false, width: 100},
        {text: this.$t('RescheduleDataTable.patientPhone'), align: 'center', value: 'phone', sortable: false, width: 120},
        {text: this.$t('RescheduleDataTable.reserveTime'), align: 'center', value: 'start_time', width: 120},
        {text: this.$t('RescheduleDataTable.reserveDoctor'), align: 'center', value: 'doctor_name', sortable: false, width: 100},
        {text: this.$t('RescheduleDataTable.note'), align: 'center', value: 'note', sortable: false, width: 150},
        {text: this.$t('RescheduleDataTable.alterNote'), align: 'center', value: 'reserve_change_note', sortable: false, width: 150},
        {text: this.$t('RescheduleDataTable.updateTime'), align: 'center', value: 'updated_time', width: 120},
        {text: this.$t('RescheduleDataTable.alterReason'), align: 'center', value: 'change_reason', sortable: false, width: 150},
        {text: this.$t('RescheduleDataTable.whetherCall'), align: 'center', value: 'call_clinic_status', sortable: false, width: 150},
      ]
      if (this.doctorUser)
        headers[3] = {
          text: this.$t('RescheduleDataTable.reserveClinic'),
          align: 'center',
          value: 'clinic_name',
          sortable: false,
          width: 100
        }
      if (parseInt(this.kind) === 1)
        headers.push({text: this.$t('RescheduleDataTable.action'), align: 'center', value: 'action', sortable: false, width: 150})
      else
        headers.push({text: '處理時間', align: 'center', value: 'handle_time', sortable: false, width: 150})
      return headers
    },
  },
  watch: {
    options: {
      handler() {
        this.getRescheduleList()
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      getUnreadNotice: 'getUnreadNotice',
    }),
    handleSearch() {
      if (this.options.page === 1) this.getRescheduleList()
      else this.options.page = 1
    },
    getRescheduleList() {
      this.pageLoading = true
      const {page, itemsPerPage} = this.options
      getRescheduleAPI({...this.axiosParamData, limit: itemsPerPage, page, kind: this.kind, name: this.searchQ}).then(res => {
        this.reserveItems = res.data.data
        this.total = parseInt(res.data.paginate.total)
        this.pageLoading = false
      })
    },
    confirmReschedule(id) {
      this.confirmTitle = this.$t('RescheduleDataTable.confirmReschedule')
      this.selectReserveId = id
      this.changeStatus = 6
      this.confirmDialog = true
    },
    cancelReschedule(id) {
      this.confirmTitle = this.$t('RescheduleDataTable.cancelReschedule')
      this.selectReserveId = id
      this.changeStatus = 1
      this.confirmDialog = true
    },
    confirm() {
      const data = {
        ...this.axiosParamData,
        attend_status: this.changeStatus
      }
      updateRescheduleAPI(this.selectReserveId, data).then(() => {
        this.getRescheduleList()
        const data = {
          ...this.axiosParamData,
          reserve_change_note: null
        }
        updateReserveAPI(this.selectReserveId, data)
        this.reset()
        this.getUnreadNotice()
      })
    },
    reset() {
      this.confirmDialog = false
      this.confirmTitle = null
      this.selectReserveId = null
      this.changeStatus = null
    },
    editChangeNote(obj) {
      this.editReserve = JSON.parse(JSON.stringify(obj))
      this.changeNoteDialog = true
    },
    updateChangeNote() {
      const data = {
        ...this.axiosParamData,
        reserve_change_note: this.editReserve.reserve_change_note
      }
      updateReserveAPI(this.editReserve.reserve_no, data).then(() => {
        this.editReserve = null
        this.changeNoteDialog = false
        this.getRescheduleList()
      })
    }
  }
}
</script>

<style scoped>

</style>
