<template>
  <div>
    <v-container>
      <v-row dense>
        <v-col cols="12" class="text-right">
          <v-btn color="primary" @click="print">{{ $t('PrintReserve.print') }}</v-btn>
          <v-btn color="blue" class="white--text" @click="downloadExcel">{{ $t('PrintReserve.download') }}</v-btn>
          <v-btn outlined @click="$emit('close-print')">{{ $t('GENERAL.cancel') }}</v-btn>
        </v-col>
        <v-col cols="12">
          <div style="width: fit-content;" class="ml-auto">
            <v-checkbox v-model="showSpecialPatient" label="顯示備註/勿約" dense hide-details/>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <v-container id="printMe">
      <v-card-title class="justify-center" style="font-size: 200%; font-weight: 900;">
        {{ $t('PrintReserve.reserveList') }}
      </v-card-title>

      <v-spacer></v-spacer>

      <p style="font-size: 150%; font-weight: 900;">預約日期 ： {{$moment(printTime).format('YYYY/MM/DD ddd') }}</p>
      <p style="font-size: 150%; font-weight: 900;">{{ $t('PrintReserve.printContent') }} ：
        {{ clinicUser ? printObj.doctor_name : printObj.clinic_name }}</p>

      <v-spacer></v-spacer>
      <table class="table table-bordered" style="border: 1px solid #dee2e6; border-collapse: collapse; width: 100%;">
        <thead>
        <tr>
          <th style="border: 1px solid #dee2e6; border-bottom-width: 2px; vertical-align: bottom; padding: 12px;">
            {{ $t('PrintReserve.reserveDate') }}
          </th>
          <th style="border: 1px solid #dee2e6; border-bottom-width: 2px; vertical-align: bottom; padding: 12px; width: 85px;">
            約診時間
          </th>
          <th style="border: 1px solid #dee2e6; border-bottom-width: 2px; vertical-align: bottom; padding: 12px; width: 85px;">
            預計結束時間
          </th>
          <th
            style="border: 1px solid #dee2e6; border-bottom-width: 2px; vertical-align: bottom; padding: 12px; width: 90px;">
            {{ $t('PrintReserve.duration') }}
          </th>
          <th
            style="border: 1px solid #dee2e6; border-bottom-width: 2px; vertical-align: bottom; padding: 12px; width: 110px;">
            {{ clinicUser ? $t('PrintReserve.doctor') : $t('PrintReserve.clinic') }}
          </th>
          <th
            style="border: 1px solid #dee2e6; border-bottom-width: 2px; vertical-align: bottom; padding: 12px; width: 95px;">
            {{ $t('PrintReserve.patientName') }}
          </th>
          <th
            style="border: 1px solid #dee2e6; border-bottom-width: 2px; vertical-align: bottom; padding: 12px; width: 60px;">
            {{ $t('PrintReserve.gender') }}
          </th>
          <th
            style="border: 1px solid #dee2e6; border-bottom-width: 2px; vertical-align: bottom; padding: 12px; width: 160px;">
            {{ $t('PrintReserve.birthday') }}
          </th>
          <th style="border: 1px solid #dee2e6; border-bottom-width: 2px; vertical-align: bottom; padding: 12px;">
            {{ $t('PrintReserve.phone') }}
          </th>
          <th
            style="border: 1px solid #dee2e6; border-bottom-width: 2px; vertical-align: bottom; padding: 12px; width: 100px;">
            {{ $t('PrintReserve.attendStatus') }}
          </th>
          <th style="border: 1px solid #dee2e6; border-bottom-width: 2px; vertical-align: bottom; padding: 12px;">
            {{ $t('PrintReserve.note') }}
          </th>
          <th style="border: 1px solid #dee2e6; border-bottom-width: 2px; vertical-align: bottom; padding: 12px;">
            額外提醒內容
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, index) in printData" :key="index">
          <template v-if="showSpecialPatient || !Object.values(specialPatient).includes(item.patient_code)">
            <td style="border: 1px solid #dee2e6; padding: 12px;">{{
                $moment.unix(item.start_time).format('YYYY/MM/DD ddd')
              }}
            </td>
            <td style="border: 1px solid #dee2e6; padding: 12px;">{{
                $moment.unix(item.start_time).format('HH : mm')
              }}
            </td>
            <td style="border: 1px solid #dee2e6; padding: 12px;">{{
                $moment.unix(item.end_time).format('HH : mm')
              }}
            </td>
            <td style="border: 1px solid #dee2e6; padding: 12px;">{{ (item.end_time - item.start_time) / 60 }}</td>
            <td style="border: 1px solid #dee2e6; padding: 12px;">{{ clinicUser ? item.doctor_name : item.clinic_name }}
              {{ clinicUser ? '醫師' : null }}
            </td>
            <td style="border: 1px solid #dee2e6; padding: 12px;">{{ item.name }}</td>
            <td style="border: 1px solid #dee2e6; padding: 12px;">{{ item.sex | getGender }}</td>
            <td style="border: 1px solid #dee2e6; padding: 12px;">{{
                parseInt(item.birthday) * 1000 | formatROCDate
              }}
            </td>
            <td style="border: 1px solid #dee2e6; padding: 12px;">{{ item.phone }}</td>
            <td style="border: 1px solid #dee2e6; padding: 12px;">{{ attendStatusMap[item.attend_status].text }}</td>
            <td style="border: 1px solid #dee2e6; padding: 12px;">{{ item.note }}</td>
            <td style="border: 1px solid #dee2e6; padding: 12px;">{{ item.remind_others }}</td>
          </template>
        </tr>
        </tbody>
      </table>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { printReserveExcel } from "../../api";

export default {
  name: "PrintReserve",
  props: ['printData', 'printTime', 'printObj'],
  data() {
    return {
      showSpecialPatient: true
    }
  },
  computed: {
    ...mapState({
      specialPatient: state => state.specialPatient,
      attendStatusMap: state => state.attendStatusMap,
    }),
    ...mapGetters([
      'axiosParamData',
      'clinicUser'
    ]),
  },
  methods: {
    print() {
      this.$htmlToPaper('printMe', {}, () => {
        this.$emit('close-print')
      })
    },
    downloadExcel() {
      const params = {start: this.printTime, end: this.printTime, ...this.axiosParamData}
      if (this.clinicUser) params.doctor_code = this.printObj.doctor_code
      else params.clinic_code = this.printObj.clinic_code
      if (!this.showSpecialPatient) params.exclude = 1
      printReserveExcel(params).then(res => {
        const blob = new Blob([res.data], {type: 'text/csv;charset=utf-8;'});
        const link = document.createElement("a");
        const url = URL.createObjectURL(blob);
        const fileName = res.headers['content-disposition'].split("filename=")[1] || this.$t('PrintReserve.fileTitle')
        link.setAttribute("href", url);
        link.setAttribute("download", fileName);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
    }
  }
}
</script>

<style scoped>

</style>
