<template>
  <patient-pie-analysis
    title="LINE綁定狀況圓餅圖"
    :data="sourceData"
  />
</template>

<script>
import { getAnalysisLineAccountAPI } from "../../api";
import { mapGetters } from "vuex";
import PatientPieAnalysis from "./PatientPieAnalysis.vue";

export default {
  name: "LineBindAnalysis",

  components: {PatientPieAnalysis},

  data() {
    return {
      sourceData: null
    }
  },

  computed: {
    ...mapGetters([
      'axiosParamData',
    ]),
  },

  methods: {
    getAnalysisData() {
      getAnalysisLineAccountAPI(this.axiosParamData).then(res => {
        const data = res.data
        data.datasets[0].backgroundColor = [
          '#23bfda',
          '#efcf13',
          '#f45d64'
        ]
        this.sourceData = res.data
      })
    }
  },

  mounted() {
    this.getAnalysisData()
  }
}
</script>

<style scoped>

</style>
