<template>
  <div>
    <guide-tour config-key="message.send.line"/>
    <v-stepper v-model="e6" vertical class="elevation-0 mx-auto">
      <v-overlay :value="loading" absolute>
        <v-progress-circular indeterminate size="64"/>
      </v-overlay>
      <v-stepper-step :complete="e6 > 1" step="1" editable>
        {{ $t('SendStep.filterTitle') }}
        <small>{{ $t('SendStep.filterDesc') }}</small>
      </v-stepper-step>
      <v-stepper-content step="1">
        <v-card outlined class="mb-12 pa-2">
          <v-row>
            <v-col cols="12" sm="6">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="dates"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateRangeText"
                    :label="$t('SendStep.filterDate')"
                    prepend-icon="mdi-calendar"
                    readonly
                    clearable
                    hide-details
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="dates" no-title scrollable range locale="zh">
                  <v-spacer/>
                  <v-btn text color="primary" @click="menu = false">
                    取消
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.menu.save(dates)">
                    確認
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="6">
              <v-checkbox v-model="satisfaction" hide-details :label="$t('SendStep.filterSatisfaction')"/>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select v-if="clinicUser" v-model="selectDoctors" multiple chips hide-details clearable
                        :items="doctorItems" item-text="doctor_name" item-value="doctor_code" :label="$t('SendStep.filterDoctor')">
                <template v-slot:prepend-item>
                  <v-list-item ripple @click="toggle">
                    <v-list-item-action>
                      <v-icon :color="selectDoctors.length > 0 ? 'indigo darken-4' : ''">
                        {{ icon }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ $t('SendStep.selectAll') }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
              </v-select>
              <v-select v-else v-model="selectClinic" multiple chips hide-details clearable
                        :items="clinicItems" item-text="clinic_name" item-value="clinic_code" :label="$t('SendStep.filterClinic')">
                <template v-slot:prepend-item>
                  <v-list-item ripple @click="toggle">
                    <v-list-item-action>
                      <v-icon :color="selectDoctors.length > 0 ? 'indigo darken-4' : ''">
                        {{ icon }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ $t('SendStep.selectAll') }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select v-model="selectHealth" multiple chips hide-details
                        clearable :items="healthItems" item-value="id" item-text="category_name" :label="$t('SendStep.filterLatent')"/>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field v-model="patientName" hide-details :label="$t('SendStep.filterName')" clearable/>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                v-model="selectReserveTags"
                hide-details
                label="約診標籤"
                clearable
                multiple
                :items="reserveTags"
                item-value="tag_id"
                item-text="content"
              />
            </v-col>
          </v-row>
        </v-card>
        <v-btn color="primary" @click="filterPatient">{{ $t('SendStep.confirmFilter') }}</v-btn>
        <v-btn text @click="clearFilter">{{ $t('SendStep.clearFilter') }}</v-btn>
      </v-stepper-content>

      <v-stepper-step v-show="!useCondition" :complete="e6 > 2" step="2" :editable="e6 > 1" class="mt-2">
        {{ $t('SendStep.countSelect', { count: selectPatients.length }) }}
      </v-stepper-step>
      <v-stepper-content v-show="!useCondition" step="2">
        <v-card outlined class="mb-12 pa-2">
          <v-row>
            <v-col cols="12" sm="6">
              <v-card>
                <v-card-subtitle class="d-flex">{{ $t('SendStep.patientList') }}
                  <v-btn small color="primary" class="ml-auto" @click="toggleSelectPatient">
                    {{ selectAllPatients ? $t('SendStep.cancelSelect') : $t('SendStep.selectAll') }}
                  </v-btn>
                </v-card-subtitle>
                <v-card-text>
                  <v-text-field v-model.trim="searchPatientName" :label="$t('SendStep.searchName')" outlined dense hide-details
                                style="width: calc(100% - 15px)"/>
                  <v-card flat height="200" class="overflow-y-auto">
                    <v-card v-for="patient in patients" :key="patient" flat link height="40"
                            @click="selectPatient(patient)">
                      <v-checkbox
                        :input-value="selectPatients.includes(patient)"
                        color="blue"
                        :label="patient.split('-')[2]"
                        hide-details dense
                        style="padding-top: 8px"
                        :disabled="selectPatients.length >= maxSelect && !selectPatients.includes(patient)"
                      />
                    </v-card>
                  </v-card>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" sm="6">
              <v-card>
                <v-card-subtitle class="d-flex">{{ $t('SendStep.showSelect') }}
                  <v-btn small color="primary" :disabled="!selectPatients.length" class="ml-auto"
                         @click="selectPatients = []">{{ $t('SendStep.clearAll') }}
                  </v-btn>
                </v-card-subtitle>
                <v-card-text class="overflow-y-auto" style="height: 256px;">
                  <v-chip
                    v-for="(patient, i) in selectPatients"
                    :key="patient"
                    class="ma-2"
                    close
                    color="primary"
                    outlined
                    @click:close="remove(i)"
                  >
                    {{ patient.split('-')[1] }}
                  </v-chip>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
        <v-btn color="primary" @click="e6 = 3">{{ $t('SendStep.confirmSelect') }}</v-btn>
      </v-stepper-content>

      <v-stepper-step :complete="e6 > 3" step="3" :editable="e6 > 2" class="mt-2">
        {{ $t('SendStep.editSelectContent') }}
      </v-stepper-step>
      <v-stepper-content step="3">
        <v-card outlined class="mb-12 pa-2">
          <edit-card
            v-if="mountedActivities"
            ref="editCard"
            v-model="sendContent"
            :activities="activities"
            :is3mClient="Boolean(Number(settings.mmm_verify))"
            @alert3m="alertThreeM = true"
          />
        </v-card>
        <v-btn color="primary" @click="confirmStep(3)">{{ $t('SendStep.confirmContent') }}</v-btn>
      </v-stepper-content>

      <v-stepper-step step="4">
        {{ $t('SendStep.confirmSend') }}
      </v-stepper-step>
      <v-stepper-content step="4">
        <v-card outlined class="mb-12 pa-2">
          <v-container>
            <v-row dense>
              <v-col cols="12">
                {{ $t('SendStep.countSend', { count: countSendPatients }) }}
              </v-col>
              <v-col cols="12">
                {{ $t('SendStep.sendContent') }}
                <div v-if="sendContent.type === 1" class="ml-4">
                  {{ $t('SendStep.useActivity') }} - {{ showActivity().title }}
                  <template v-if="sendContent.activityContent">
                    <br/>{{ $t('SendStep.otherContent') }} {{ sendContent.activityContent }}
                  </template>
                </div>
                <div v-else class="ml-4">
                  <template v-if="sendContent.customImg">
                    {{ $t('SendStep.picture') }}
                    <v-img max-height="200" max-width="200" contain :src="showCustomImg()"/>
                  </template>
                  <template v-if="sendContent.customContent">
                    <br v-if="sendContent.customImg"/>{{ $t('SendStep.content') }} {{ sendContent.customContent }}
                  </template>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
        <v-btn color="primary" @click="confirmStep(4)">
          {{ $t('SendStep.confirm') }}
        </v-btn>
      </v-stepper-content>
      <v-dialog v-model="maxAlertDialog" max-width="500">
        <v-card>
          <v-card-title>
            {{ $t('SendStep.alertMax', { count: totalPatientCount }) }}
          </v-card-title>
          <v-card-actions class="flex-wrap justify-center justify-space-around">
            <v-btn color="primary" class="my-1" width="150" @click="confirmUseCondition">{{ $t('SendStep.accept') }}</v-btn>
            <v-btn color="red" class="white--text ml-0 my-1" width="150" @click="denyUseCondition">{{ $t('SendStep.refuse') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialog" max-width="500">
        <v-card>
          <v-card-title>
            {{ $t('SendStep.alertOwnChannel') }}
          </v-card-title>
          <v-card-actions>
            <v-spacer/>
            <v-btn
              href="https://docs.google.com/presentation/d/1g8jvKgFMh7uILzP11fNEOnELCvm88SBwSsSXeN5LSYE/edit?usp=sharing"
              target="_blank" color="primary">{{ $t('SendStep.howApply') }}
            </v-btn>
            <v-btn href="https://lin.ee/mWs8BoE" target="_blank" color="red">{{ $t('SendStep.concatCS') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-if="alertDialog" v-model="alertDialog" max-width="280">
        <v-card>
          <v-card-title class="justify-center">
            {{ $t('SendStep.success') }}
          </v-card-title>
          <v-card-text>
            <div class="text-center">
              預計發送完畢時間： <span class="text-decoration-underline">10</span> 分鐘後<br>
              <span class="text-decoration-underline">10</span> 分鐘後可至發送紀錄瀏覽
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn color="blue" class="white--text" @click="alertDialog = false">{{ $t('SendStep.IKnow') }}</v-btn>
            <v-spacer/>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="activityExpiredDialog" max-width="fit-content" persistent>
        <v-card>
          <v-card-title>活動時間已結束，請問您是否還要繼續發送群發訊息？</v-card-title>
          <v-card-actions>
            <v-spacer/>
            <v-btn color="primary" @click="sendMessage">是</v-btn>
            <v-btn dark color="grey-5" @click="cancelSendMessage">否</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="quotaDialog" max-width="fit-content">
        <v-card class="py-md-10 px-md-8">
          <v-card-title class="justify-center" style="font-size: 24px;">
            訊息餘額
          </v-card-title>
          <v-card-text class="mt-2">
            <div class="text-pre-line text-center">
              <div style="font-size: 16px;">
                目前剩餘LINE訊息約：<span class="text-decoration-underline">{{ quota }}</span> 則<br>
                本次預計使用：<span class="text-decoration-underline">{{ countSendPatients }}</span> 則<br>
              </div>
              <div class="mt-4">
                建議您前往LINE後台，確認訊息量是否足夠與加購<br>
                若不足但選擇發出，將只有部分患者收到唷！<br>
              </div>
            </div>
          </v-card-text>
          <v-card-actions class="flex-wrap">
            <v-btn dark color="grey-5" block class="ma-0" target="_blank" :href="$enum.DENTCO_CS_LINE">聯繫客服</v-btn>
            <v-btn color="red-6" dark block class="ml-0 mt-2" @click="sendMessage">確定送出</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="alertThreeM" width="fit-content" max-width="380">
        <v-card>
          <v-card-title>此功能需要為3M氟漆客戶，如需開啟請洽客服</v-card-title>
          <v-card-actions>
            <v-btn
              class="mx-auto"
              color="primary"
              :href="$enum.DENTCO_CS_LINE"
              target="_blank"
              @click="alertThreeM = false"
            >聯繫客服
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-stepper>
  </div>
</template>

<script>
import {
  getChannelQuotaAPI,
  getClinicListAPI,
  getDoctorListAPI,
  getHealthEduAPI,
  getMessageActivitiesAPI,
  getMessagePatientAPI,
  getMessagePermissionAPI, getMessageSettingAPI, getReserveTagAPI,
  sendMessageAPI
} from "@/api";
import { mapGetters, mapMutations, mapState } from "vuex";
import { get_age, getGender } from "@/utils/filters";
import _ from "lodash";
import EditCard from "@/components/message/EditCard";
import { isExpired } from '@/utils/tool';
import GuideTour from "@/components/utils/GuideTour.vue";

export default {
  name: "SendStep",

  components: {GuideTour, EditCard},

  data() {
    return {
      loading: false,
      e6: 1,
      dates: [],
      menu: false,
      satisfaction: false,
      doctorItems: [],
      selectDoctors: [],
      clinicItems: [],
      selectClinic: [],
      healthItems: [],
      selectHealth: [],
      patientName: null,
      selectReserveTags: [],
      reserveTags: [],
      patientItems: [],
      originalPatients: [],
      patients: [],
      searchPatientName: null,
      selectPatients: [],
      sendContent: {
        type: 1
      },
      activities: [],
      restrict: false,
      dialog: false,
      alertDialog: false,
      maxAlertDialog: false,
      useCondition: false,
      totalPatientCount: 0,
      mountedActivities: false,
      waitTimeout: null,
      canSendMessage: true,
      activityExpiredDialog: false,
      isExpired,
      alertThreeM: false,
      settings: false,
      maxSelect: 500,
      quota: null,
      total: null,
      quotaDialog: false
    }
  },
  computed: {
    ...mapState({
      panoId: state => state.panoId,
      excludeMessageActivities: state => state.excludeMessageActivities,
    }),
    ...mapGetters([
      'axiosParamData',
      'clinicUser',
      'doctorUser',
      'medicalKind'
    ]),
    dateRangeText: {
      get: function () {
        return this.dates.join(' ~ ')
      },
      set: function () {
        this.dates = []
      }
    },
    icon() {
      let selectAll
      if (this.clinicUser)
        selectAll = this.selectDoctors.length === this.doctorItems.length
      else
        selectAll = this.selectClinic.length === this.clinicItems.length
      if (selectAll) return 'mdi-close-box'
      return 'mdi-checkbox-blank-outline'
    },
    selectAllPatients() {  // 是否已經將篩選出來的病患全部選擇了，只要有一個沒選擇，就不是
      return this.patients.every(v => this.selectPatients.includes(v))
    },
    countSendPatients() {
      return this.totalPatientCount > this.maxSelect ? this.totalPatientCount : this.selectPatients.length
    }
  },
  watch: {
    searchPatientName(v) {
      if (v)
        this.patients = this.originalPatients.filter(a => a.includes(v));
      else
        this.patients = this.originalPatients
    }
  },
  methods: {
    ...mapMutations({
      setGlobalAlert: 'setGlobalAlert'
    }),
    toggle() {
      if (this.clinicUser) {
        const selectAll = this.selectDoctors.length === this.doctorItems.length
        this.$nextTick(() => {
          if (selectAll) {
            this.selectDoctors = []
          } else {
            this.selectDoctors = this.doctorItems.map(e => e.doctor_code)
          }
        })
      } else {
        const selectAll = this.selectClinic.length === this.clinicItems.length
        this.$nextTick(() => {
          if (selectAll) {
            this.selectClinic = []
          } else {
            this.selectClinic = this.clinicItems.map(e => e.clinic_code)
          }
        })
      }
    },
    filterPatient() {
      this.loading = true
      this.searchPatientName = null
      const params = {
        ...this.axiosParamData,
        satisfaction: Number(this.satisfaction) || null,
        health_education_id: this.selectHealth.join(',') || null,
        filter_clinic: this.selectClinic.join(',') || null,
        filter_doctor: this.selectDoctors.join(',') || null,
        name: this.patientName || null,
        tag_ids: this.selectReserveTags
      }
      if (this.dates.length) {
        if (this.dates.length === 1) params.start_time = params.end_time = this.dates[0]
        else {
          params.start_time = this.dates[0]
          params.end_time = this.dates[1]
        }
      }

      getMessagePatientAPI(params).then(res => {
        this.totalPatientCount = res.data.count
        if (res.data.count > this.maxSelect) {
          this.maxAlertDialog = true
          return
        }
        const r = res.data.data
        this.patients = this.originalPatients = r.map(e => {
          let _ = `${e.patient_code}-${e.name}-${e.phone} ${e.name}`
          if (e.birthday) _ += ` ${get_age(parseInt(e.birthday) * 1000)}${this.$t('SendStep.age')}`
          if (getGender(e.sex)) _ += ` ${getGender(e.sex)}${this.$t('SendStep.genderUnit')}`
          return _
        })
        this.useCondition = false
        this.e6 = 2
      }).finally(() => {
        this.loading = false
      })
    },
    clearFilter() {
      this.satisfaction = this.$options.data().satisfaction
      this.selectHealth = this.$options.data().selectHealth
      this.selectDoctors = this.$options.data().selectDoctors
      this.selectClinic = this.$options.data().selectClinic
      this.patientName = this.$options.data().patientName
      this.selectReserveTags = this.$options.data().selectReserveTags
      this.dates = this.$options.data().dates
    },
    confirmUseCondition() {
      this.useCondition = true
      this.e6 = 3
      this.maxAlertDialog = false
    },
    denyUseCondition() {
      this.useCondition = false
      this.maxAlertDialog = false
    },
    remove(index) {
      this.selectPatients.splice(index, 1)
    },
    toggleSelectPatient() {
      this.$nextTick(() => {
        if (this.selectAllPatients) {
          this.selectPatients = _.difference(this.selectPatients, this.patients)
        } else {
          const tmp = _.union(this.selectPatients, this.patients)
          if (tmp.length > this.maxSelect) {
            return alert(`最多可選擇 ${this.maxSelect} 位患者`)
          }
          this.selectPatients = _.union(this.selectPatients, this.patients)
        }
      })
    },
    selectPatient(patient) {
      const index = this.selectPatients.indexOf(patient)
      if (index === -1) {
        this.selectPatients.length >= this.maxSelect ? alert(`最多可選擇 ${this.maxSelect} 位患者`) : this.selectPatients.push(patient)
      } else {
        this.selectPatients.splice(index, 1)
      }
    },
    showActivity() {
      return this.activities.find(e => e.id === this.sendContent.activity) || {}
    },
    showCustomImg() {
      if (this.sendContent.customImg)
        return URL.createObjectURL(this.sendContent.customImg)
    },
    async confirmStep(n) {
      switch (n) {
        case 3:
          if (this.sendContent.type === 1) {
            if (this.sendContent.activity === this.panoId && !this.sendContent.panoImgId) alert('請先上傳 pano報告再繼續')
            else if (this.sendContent.activity) this.e6 = 4
            else alert(this.$t('SendStep.pleaseChooseActivity'))
          } else {
            if (this.sendContent.customImg || this.sendContent.customContent) this.e6 = 4
            else alert(this.$t('SendStep.pleaseEditContent'))
          }
          break;
        case 4:
          if (this.restrict && (this.totalPatientCount > this.maxSelect || this.selectPatients.length > 1)) {
            this.dialog = true
            return
          }
          if (this.isExpired(this.showActivity().end_date))
            return this.activityExpiredDialog = true


          if (this.totalPatientCount <= this.maxSelect && !this.selectPatients.length) {
            alert(this.$t('SendStep.pleaseChoosePatient'))
            this.e6 = 2
            return
          }

          if (this.selectPatients.length > this.maxSelect) {
            alert(`最多可選擇 ${this.maxSelect} 位患者，請重新選擇`)
            this.e6 = 2
            return
          }

          if (!this.canSendMessage) {
            this.setGlobalAlert({
              message: '群發訊息需間隔一分鐘發送避免塞車，請稍候再試一次',
              dialog: true,
            })
            return
          }

          await this.getChannelQuota()
          this.quotaDialog = true

          // this.loading = true
          // if (this.isExpired(this.showActivity().end_date))
          //   return this.activityExpiredDialog = true
          //
          // this.sendMessage()
          break;
      }
    },
    sendMessage() {
      this.loading = true
      if (this.selectPatients.length > this.maxSelect) {
        this.loading = false
        return alert(`最多可選擇 ${this.maxSelect} 位患者，請重新選擇`)
      }
      const allPatientCodes = JSON.parse(JSON.stringify(this.selectPatients))
      let data = {
        ...this.axiosParamData,
        message_type: this.sendContent.activity === this.panoId ? 3 : this.sendContent.type,
        activity: this.sendContent.activity || 0,
        pano_id: this.sendContent.panoImgId || 0,
        image: this.sendContent.customImg,
        content: this.sendContent.type === 2 ? this.sendContent.customContent : this.sendContent.activityContent,
      }

      // 發送 篩選的時候大於500人的
      if (this.totalPatientCount > this.maxSelect) {
        data = {
          ...data,
          satisfaction: Number(this.satisfaction) || null,
          health_education_id: this.selectHealth.join(',') || null,
          filter_clinic: this.selectClinic.join(',') || null,
          filter_doctor: this.selectDoctors.join(',') || null,
          name: this.patientName || null,
        }

        if (this.sendContent.customImg) {
          const formData = new FormData()
          for (const [key, value] of Object.entries(data)) {
            formData.append(key, value)
          }
          data = formData
        }
        sendMessageAPI(data)
        this.canSendMessage = false
        this.waitTimeout = setTimeout(() => {
          this.canSendMessage = true
        }, 1000 * 60)
        this.quotaDialog = false
        this.alertDialog = true
        this.resetAll()
        this.e6 = 1
        this.loading = false
        return
      }

      // 發送自己選擇的人
      if (allPatientCodes.length) {
        if (this.sendContent.type === 2) {
          // 自定義就要切片
          const promises = []
          while (allPatientCodes.length) {
            data.patient_code = allPatientCodes.splice(0, 100).map(e => e.split('-')[0]).join(',')
            if (this.sendContent.customImg) {
              data = {
                ...data,
                ...this.axiosParamData,
                message_type: this.sendContent.activity === this.panoId ? 3 : this.sendContent.type,
                activity: this.sendContent.activity || 0,
                pano_id: this.sendContent.panoImgId || 0,
                image: this.sendContent.customImg,
                content: this.sendContent.type === 2 ? this.sendContent.customContent : this.sendContent.activityContent
              }
              const formData = new FormData()
              for (const [key, value] of Object.entries(data)) {
                formData.append(key, value)
              }
              data = formData
            }
            promises.push(sendMessageAPI(data))
          }
          Promise.all(promises).then(() => {
            this.quotaDialog = false
            this.alertDialog = true
            this.resetAll()
            this.e6 = 1
            this.canSendMessage = false
            this.waitTimeout = setTimeout(() => {
              this.canSendMessage = true
            }, 1000 * 60)
          }).finally(() => {
            this.loading = false
          })
        } else {
          data.patient_code = allPatientCodes.map(e => e.split('-')[0]).join(',')
          if (this.sendContent.customImg) {
            data = {
              ...data,
              ...this.axiosParamData,
              message_type: this.sendContent.activity === this.panoId ? 3 : this.sendContent.type,
              activity: this.sendContent.activity || 0,
              pano_id: this.sendContent.panoImgId || 0,
              image: this.sendContent.customImg,
              content: this.sendContent.type === 2 ? this.sendContent.customContent : this.sendContent.activityContent
            }
            const formData = new FormData()
            for (const [key, value] of Object.entries(data)) {
              formData.append(key, value)
            }
            data = formData
          }
          sendMessageAPI(data).then(() => {
            this.quotaDialog = false
            this.alertDialog = true
            this.resetAll()
            this.e6 = 1
            this.canSendMessage = false
            this.waitTimeout = setTimeout(() => {
              this.canSendMessage = true
            }, 1000 * 60)
          }).finally(() => {
            this.loading = false
          })
        }
      }
    },
    cancelSendMessage() {
      this.loading = false
      this.activityExpiredDialog = false
    },
    resetAll() {
      this.sendContent = {
        type: 1
      }
      this.$refs.editCard.resetPano()
      this.selectPatients = []
      this.satisfaction = false
      this.dates = []
      this.selectClinic = []
      this.selectDoctors = []
      this.selectHealth = []
      this.patientName = null
      this.searchPatientName = null
      this.totalPatientCount = 0
    },
    getMsgSetting() {
      getMessageSettingAPI(this.axiosParamData).then(res => {
        this.settings = res.data
      })
    },
    getChannelQuota() {
      getChannelQuotaAPI(this.axiosParamData).then(res => {
        this.quota = Number(res.data.quota) - Number(res.data.total_usage)
      })
    }
  },
  mounted() {
    this.getMsgSetting()
    if (this.clinicUser)
      getDoctorListAPI(this.axiosParamData).then(res => {
        this.doctorItems = res.data
      })
    else
      getClinicListAPI(this.axiosParamData).then(res => {
        this.clinicItems = res.data
      })
    getHealthEduAPI(this.axiosParamData).then(res => {
      this.healthItems = res.data
    })
    getMessageActivitiesAPI({...this.axiosParamData, kind: this.medicalKind}).then(res => {
      let self = this;
      let excludeMessageActivities
      this.mountedActivities = true

      if (this.doctorUser)
        excludeMessageActivities = this.excludeMessageActivities.doctor

      if (this.clinicUser)
        excludeMessageActivities = this.excludeMessageActivities.clinic

      this.activities = res.data.data.filter(function(e) {
        return !excludeMessageActivities.includes(e.id)
      })
    })
    getMessagePermissionAPI(this.axiosParamData).then(res => {
      this.restrict = Boolean(Number(res.data.send_restriction))
    })
    getReserveTagAPI(this.axiosParamData).then(res => {
      this.reserveTags = res.data.filter(tag => !!tag.content)
    })
  }
}
</script>

<style scoped>

</style>
