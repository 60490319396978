<template>
  <v-container>
    <v-sheet max-width="1280">
      <guide-tour config-key="review.setting.ai"/>
      <div class="px-4" style="position: relative;">

        <v-skeleton-loader
          v-if="pageLoading"
          type="list-item-three-line, list-item-three-line, list-item-three-line"
        ></v-skeleton-loader>

        <template v-else>
          <dentco-alert-bar
            v-if="!hasPermission"
            message="歡迎聯繫客服開啟AI評價設定，可以瞭解如何給予患者正向的回饋，以及體驗不同風格的回覆互動，最重要的是還能幫助增加SEO流量唷！"
            :btn-link="$enum.DENTCO_CS_LINE"
            btn-title="聯繫客服開啟AI評價功能"
            class="mt-7"
          />
          <div class="d-flex flex-column mt-4" style="gap: 10px;">
            <div class="bullet" :class="{'text--disabled': !hasPermission}">
              一鍵生成過去評價回覆
            </div>
            <div class="d-flex" style="gap: 12px;">
              <generate-ai-response-dialog :has-permission="hasPermission"/>
              <send-ai-response-dialog :has-permission="hasPermission"/>
            </div>
          </div>
          <v-sheet
            outlined
            :max-height="$vuetify.breakpoint.smAndUp ? 546 : ''"
            class="pa-7 mt-4 d-flex font-weight-medium overflow-y-auto"
            :class="{'flex-wrap': !$vuetify.breakpoint.mdAndUp}"
            style="column-gap: 32px; position: relative;"
          >
            <v-overlay :value="loading" absolute>
              <v-progress-circular
                indeterminate
                size="64"
              ></v-progress-circular>
            </v-overlay>
            <div
              v-if="!isEdit && !$vuetify.breakpoint.mdAndUp"
              class="text-right"
              style="width: 100%;"
            >
              <v-btn color="primary" @click="isEdit = true">編輯</v-btn>
            </div>
            <div
              class="d-flex flex-column"
              :style="{
                gap: '28px',
                height: '100%',
                width: $vuetify.breakpoint.mdAndUp ? 'calc(100% - 172px)' : '100%',
                'padding-bottom': isEdit ? '60px' : null
              }"
            >
              <div class="d-flex flex-column" style="gap: 10px;">
                <div class="bullet">設定AI回覆風格</div>
                <div v-if="isEdit" style="width: 400px;">
                  <v-select
                    v-model="selectStyle"
                    outlined
                    dense
                    hide-details
                    :items="styleOptions"
                    :readonly="!hasPermission"
                  >
                    <template v-slot:selection="{ item }">
                      <span>{{ item }}</span>
                      <span v-if="!hasPermission">（欲修改請 <a :href="$enum.DENTCO_CS_LINE" target="_blank"
                                                               style="color: #0044FF; text-underline-offset: 3px;"
                                                               @click.stop>聯繫客服</a> 開啟AI評價功能）</span>
                    </template>
                  </v-select>
                </div>
                <div v-else class="grey-5--text">
                  {{ selectStyleText }}
                  <span v-if="!hasPermission">（欲修改請 <a :href="$enum.DENTCO_CS_LINE" target="_blank"
                                                           style="color: #0044FF; text-underline-offset: 3px;"
                                                           @click.stop>聯繫客服</a> 開啟AI評價功能）</span>
                </div>
              </div>

              <div class="d-flex flex-column" style="gap: 10px;">
                <div class="bullet">選擇AI生成模式</div>
                <div v-if="isEdit" style="width: fit-content;">
                  <div>
                    <div>好評（每個項目皆為4～5分，即為好評）</div>
                    <v-radio-group v-model="selectAiGenerateModeGood" dense hide-details class="mt-0 pl-2">
                      <v-radio
                        v-for="(option, idx) in aiGenerateModeOptions"
                        :key="option.value"
                        :value="option.value"
                        style="width: fit-content;"
                        :disabled="!checkIsAble('good', option.value, hasPermission)"
                      >
                        <template v-slot:label>
                          <div>
                            <span v-html="option.text"/>
                            <span v-if="!checkIsAble('good', option.value, hasPermission)">（欲修改請 <a
                              :href="$enum.DENTCO_CS_LINE"
                              target="_blank"
                              style="color: #0044FF; text-underline-offset: 3px;"
                              @click.stop>聯繫客服</a> 開啟AI評價功能）</span>
                          </div>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </div>
                  <div>
                    <div>負評（任一項目為1～3分，即為負評）</div>
                    <v-radio-group v-model="selectAiGenerateModeBad" dense hide-details class="mt-0 pl-2">
                      <v-radio
                        v-for="(option, idx) in aiGenerateModeOptions"
                        :key="option.value"
                        :value="option.value"
                        style="width: fit-content;"
                        :disabled="!checkIsAble('bad', option.value, hasPermission)"
                      >
                        <template v-slot:label>
                          <div>
                            <span v-html="option.text"/>
                            <span v-if="!checkIsAble('bad', option.value, hasPermission)">（欲修改請 <a
                              :href="$enum.DENTCO_CS_LINE"
                              target="_blank"
                              style="color: #0044FF; text-underline-offset: 3px;"
                              @click.stop>聯繫客服</a> 開啟AI評價功能）</span>
                          </div>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </div>
                </div>
                <div v-else class="grey-5--text">
                  <div>
                    <div>好評（每個項目皆為4～5分，即為好評）：
                      <div class="pl-2">
                        <span v-html="selectAiGenerateModeGoodText"></span>
                        <span v-if="!hasPermission">（欲修改請 <a :href="$enum.DENTCO_CS_LINE" target="_blank"
                                                                 style="color: #0044FF; text-underline-offset: 3px;"
                                                                 @click.stop>聯繫客服</a> 開啟AI評價功能）</span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div>負評（任一項目為1～3分，即為負評）：
                      <div class="pl-2">
                        <span v-html="selectAiGenerateModeBadText"></span>
                        <span v-if="!hasPermission">（欲修改請 <a :href="$enum.DENTCO_CS_LINE" target="_blank"
                                                                 style="color: #0044FF; text-underline-offset: 3px;"
                                                                 @click.stop>聯繫客服</a> 開啟AI評價功能）</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="d-flex flex-column" style="gap: 10px;">
                <div class="bullet" :class="{'text--disabled': !hasPermission}">回覆中是否插入Emoji表情</div>
                <div v-if="isEdit">
                  <v-radio-group v-model="selectEmoji" row dense hide-details class="mt-0" :disabled="!hasPermission">
                    <v-radio label="是" :value="1"/>
                    <v-radio label="否" :value="0"/>
                  </v-radio-group>
                </div>
                <div v-else class="grey-5--text" :class="{'text--disabled': !hasPermission}">
                  {{ selectEmojiText }}
                </div>
              </div>

              <div class="d-flex flex-column" style="gap: 10px;">
                <div class="bullet" :class="{'text--disabled': !hasPermission}">回覆中是否加入必要內容及SEO關鍵字</div>
                <div v-if="isEdit">
                  <v-radio-group v-model="selectSeoMode" row dense hide-details class="mt-0" :disabled="!hasPermission">
                    <v-radio label="是" :value="1"/>
                    <v-radio label="否" :value="0"/>
                  </v-radio-group>

                  <div v-if="selectSeoMode === 1" class="mt-2">
                    <div class="d-flex align-center" style="column-gap: 10px;">
                      <div style="width: 300px; max-width: 80%;">
                        <v-text-field
                          v-model="newSeoPrompt"
                          hide-details
                          outlined
                          dense
                          placeholder="請輸入SEO關鍵字"
                          :disabled="!hasPermission"
                        />
                      </div>
                      <div>
                        <v-btn color="primary" height="40" :disabled="!hasPermission" @click="addSeoPrompt">
                          新增關鍵字
                        </v-btn>
                      </div>
                    </div>
                    <div>
                      <v-list-item
                        v-for="(e, i) in seoPrompts"
                        :key="i"
                        style="height: 40px"
                        dense
                        class="my-2 grey-8"
                        :disabled="!hasPermission"
                      >
                        <v-list-item-title v-text="e"/>
                        <v-list-item-action>
                          <v-btn icon @click="seoPrompts.splice(i, 1)">
                            <v-icon color="grey lighten-1">mdi-trash-can-outline</v-icon>
                          </v-btn>
                        </v-list-item-action>
                      </v-list-item>
                    </div>
                  </div>
                </div>
                <div v-else class="grey-5--text" :class="{'text--disabled': !hasPermission}">
                  {{ selectSeoModeText }}
                </div>
              </div>
            </div>
            <div
              v-if="$vuetify.breakpoint.mdAndUp"
              style="position: sticky; top: 0; width: 140px;"
            >
              <div v-if="!isEdit" class="text-right" style="position: absolute; top: 0; right: 0;">
                <v-btn color="primary" @click="isEdit = true">編輯</v-btn>
              </div>
              <div v-if="isEdit" class="text-right" style="position: absolute; bottom: 0; right: 0;">
                <div class="d-flex" style="gap: 10px;">
                  <v-btn
                    color="grey-4"
                    outlined
                    @click="isEdit = false"
                  >取消
                  </v-btn>
                  <v-btn
                    color="red-6"
                    dark
                    @click="updateExtensionAIPromptSetting"
                  >儲存
                  </v-btn>
                </div>
              </div>
            </div>
            <div v-if="isEdit && !$vuetify.breakpoint.mdAndUp" style="width: 100%;">
              <div class="d-flex justify-end" style="gap: 10px;">
                <v-btn
                  color="grey-4"
                  outlined
                  @click="isEdit = false"
                >取消
                </v-btn>
                <v-btn
                  color="red-6"
                  dark
                  @click="updateExtensionAIPromptSetting"
                >儲存
                </v-btn>
              </div>
            </div>
          </v-sheet>
        </template>
      </div>
    </v-sheet>
    <v-dialog v-model="alertDialog" max-width="400">
      <v-card class="pa-4">
        <v-btn
          fab
          icon
          absolute
          width="48"
          height="48"
          style="top: 0; right: 0; overflow: hidden;"
          @click="alertDialog = false;"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-card-title class="text-center d-block">
          {{ alertTitle }}
        </v-card-title>
        <v-card-actions class="mt-2">
          <div style="width: 100%;">
            <v-btn color="primary" block :loading="alertLoading" @click="onClickSubmit">
              確認
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import {
  getExtensionAIPromptSettingAPI,
  getInfoAPI, submitPastAiResponseAPI,
  updateExtensionAIPromptSettingAPI
} from "@/api";
import DentcoAlertBar from "@/components/utils/DentcoAlertBar.vue";
import GuideTour from "@/components/utils/GuideTour.vue";
import { checkPermissions } from "@/utils/tool";
import { mapGetters } from "vuex";
import GenerateAiResponseDialog from "@/components/review/setting/ai/GenerateAiResponseDialog.vue";
import SendAiResponseDialog from "@/components/review/setting/ai/SendAiResponseDialog.vue";

export default {
  name: "AiSetting",

  components: {
    SendAiResponseDialog,
    GenerateAiResponseDialog,
    DentcoAlertBar,
    GuideTour
  },

  data() {
    return {
      userInfo: null,
      isEdit: false,

      styleOptions: ['親切謙和', '友善溫暖', '熱情開放', '專業嚴謹', '幽默風趣'],
      selectStyle: null,

      aiGenerateModeOptions: [
        {
          value: 0,
          text: '不生成AI回覆'
        },
        {
          value: 1,
          text: 'AI生成回覆， <span class="text-decoration-underline" style="text-underline-offset: 3px;">人工確認後</span> 送出'
        },
        {
          value: 2,
          text: 'AI生成回覆，並且 <span class="text-decoration-underline" style="text-underline-offset: 3px;">自動</span> 送出'
        }
      ],
      selectAiGenerateMode: 1,
      selectAiGenerateModeGood: 0,
      selectAiGenerateModeBad: 1,

      selectEmoji: 1,

      seoPrompts: [],
      newSeoPrompt: '',
      selectSeoMode: 1,

      pageLoading: false,
      loading: false,
      alertLoading: false,

      alertDialog: false,
      alertTitle: '',
      alertType: 'generate'
    }
  },

  computed: {
    ...mapGetters([
      'clinicUser',
      'axiosParamData',
      'defaultAiSetting'
    ]),
    selectStyleText() {
      return this.selectStyle
    },
    selectAiGenerateModeText() {
      return this.aiGenerateModeOptions.find(e => e.value === this.selectAiGenerateMode)?.text
    },
    selectAiGenerateModeGoodText() {
      return this.aiGenerateModeOptions.find(e => e.value === this.selectAiGenerateModeGood)?.text
    },
    selectAiGenerateModeBadText() {
      return this.aiGenerateModeOptions.find(e => e.value === this.selectAiGenerateModeBad)?.text
    },
    selectEmojiText() {
      if (!this.hasPermission) return '否';  // 就算設定是，但只要沒有權限就顯示否
      return this.selectEmoji ? '是' : '否'
    },
    selectSeoModeText() {
      if (!this.hasPermission) return '否';  // 就算設定是，但只要沒有權限就顯示否

      if (!this.selectSeoMode) return '否';

      const strLength = 30
      let str = '是';
      if (this.seoPrompts.length) {
        const prompts = this.seoPrompts.length > 2
          ? this.seoPrompts.slice(0, 2).join(', ') + '...'
          : this.seoPrompts.join(', ');

        str += ` ${prompts}`;
      }

      // 控制文字長度
      return str.length > strLength ? str.slice(0, strLength) + '...' : str;
    },
    hasPermission() {
      const userType = this.clinicUser ? 'clinic' : 'doctor'
      return checkPermissions({obj: this.userInfo, type: userType, permissions: ['adv_review_with_ai']})
    }
  },

  async mounted() {
    await this.getPermissionsInfo()
    this.getExtensionAIPromptSetting()
  },

  methods: {
    checkIsAble(type, value, hasPermission) {
      if (hasPermission) return true
      if (type === 'good') {
        return value === 0
      }
      if (type === 'bad') {
        return [0, 1].includes(value)
      }
    },
    addSeoPrompt() {
      if (this.newSeoPrompt.trim()) {
        this.seoPrompts.push(this.newSeoPrompt)
        this.newSeoPrompt = ''
      }
    },
    async getPermissionsInfo() {
      this.pageLoading = true
      try {
        const res = await getInfoAPI(this.axiosParamData)
        this.userInfo = res.data
        this.pageLoading = false
      } catch (e) {
        alert(this.$t('GENERAL.oops'))
      }
    },
    getExtensionAIPromptSetting() {
      this.loading = true

      getExtensionAIPromptSettingAPI(this.axiosParamData).then(res => {
        let data = res.data
        if (!Object.keys(data).length) {
          data = this.defaultAiSetting
        }

        this.selectStyle = this.hasPermission ? data.style_prompt : this.defaultAiSetting.style_prompt
        this.selectEmoji = data.use_emoji_prompt
        this.seoPrompts = data.seo_prompt ? data.seo_prompt.split(',') : []
        this.selectSeoMode = data.use_seo
        this.selectAiGenerateMode = this.hasPermission ? data.ai_generate_mode : this.defaultAiSetting.ai_generate_mode
        this.selectAiGenerateModeGood = this.hasPermission ? data.ai_generate_mode_good : this.defaultAiSetting.ai_generate_mode_good

        // 判斷寫死ㄏㄏ
        this.selectAiGenerateModeBad = (this.hasPermission || data.ai_generate_mode_bad <= 1) 
          ? data.ai_generate_mode_bad 
          : this.defaultAiSetting.ai_generate_mode_bad
        
        this.loading = false
      })
    },
    updateExtensionAIPromptSetting() {
      const data = {
        style_prompt: this.selectStyle,
        use_emoji_prompt: this.selectEmoji,
        seo_prompt: this.seoPrompts.join(','),
        use_seo: this.selectSeoMode,
        ai_generate_mode: this.selectAiGenerateMode,
        ai_generate_mode_good: this.selectAiGenerateModeGood,
        ai_generate_mode_bad: this.selectAiGenerateModeBad,
        ...this.axiosParamData
      }

      this.loading = true

      updateExtensionAIPromptSettingAPI(data).then(() => {
        this.getExtensionAIPromptSetting()
        this.isEdit = false
      })
    },
    async onClickPreviewSubmit() {
      this.alertType = 'submit';
      try {
        const count = await this.submitPastAiResponse();
        this.alertTitle = `您即將送出 ${count} 筆AI回覆（不含Google商家上的評論回覆），送出後若要修改，會需要每筆手動操作。此操作不會通知患者評價的回覆內容。`;
        this.alertDialog = true;
      } catch (error) {
        console.error('送出失敗：', error);
        alert(error);
      }
    },
    async submitPastAiResponse(mode = 'dev') {
      const data = {
        mode,
        ...this.axiosParamData,
      };
      try {
        const res = await submitPastAiResponseAPI(data);
        if (mode === 'dev') {
          return res.data?.count;
        }
      } catch (error) {
        console.error('送出API請求失敗：', error);
        const errorMsg = error?.response?.data?.messages || this.$t('GENERAL.oops');
        throw new Error(errorMsg);
      }
    },
    async onClickSubmit() {
      this.alertLoading = true
      try {
        switch (this.alertType) {
          case 'submit':
            await this.submitPastAiResponse('prod');
            alert('送出成功');
            this.alertDialog = false;
            return;
        }
      } catch (error) {
        console.error('操作失敗：', error);
        alert(error);
      } finally {
        this.alertLoading = false
      }
    }
  }
}
</script>

<style scoped>

</style>