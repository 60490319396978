<template>
  <v-card class="p-0" rounded="xl">
    <v-overlay :value="loading" absolute z-index="3">
      <v-progress-circular indeterminate size="64"/>
    </v-overlay>
    <div style="padding: 20px">
      <div class="d-flex justify-space-between align-center">
        <h6 class="text-subtitle-1 font-weight-bold">
          看診時間
        </h6>
        <v-btn
          v-if="!isEdit"
          color="teal lighten-2"
          class="text-subtitle-2 rounded-lg"
          dark
          style="width: 100px; height: 40px"
          @click="isEdit = true"
        >
          <v-icon dark size="15" class="mr-2">
            mdi-pencil
          </v-icon>
          編輯
        </v-btn>
        <div v-if="isEdit" class="d-flex justify-end" style="gap: 8px">
          <v-btn color="grey" outlined class="text-subtitle-2 rounded-lg" @click="onClose">
            取消
          </v-btn>
          <v-btn dark color="teal lighten-2" class="text-subtitle-2 rounded-lg" @click="onConfirm">
            儲存
          </v-btn>
        </div>
      </div>
    </div>
    <div v-if="!isEdit" style="padding: 0px 20px 30px 20px">
      <schedule-table :items="clinicList" />
      <p class="text-end text-caption grey--text text--lighten-2 py-2">
        *詳細時間及國定假日請依網路預約時間為主
      </p>
      <div class="d-flex flex-column mt-2" style="gap: 0.8rem">
        <div
          v-for="clinic in clinicList"
          :key="'clinic_' + clinic.clinic_name"
          class="d-flex align-start"
        >
          <v-icon :color="clinic.color" size="10" class="mx-1 my-2">
            mdi-circle
          </v-icon>
          <div>
            <a
              class="subtitle-1 text-decoration-underline"
              style="
                color: #3d3d3d;
                vertical-align: top;
                text-underline-offset: 0.2em;
              "
            >
              {{ clinic.clinic_name }}
            </a>
            <p v-if="clinic.visit_clinic_note" class="text-caption mb-0">
              {{ clinic.visit_clinic_note }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div v-else style="padding: 0px 20px 30px 20px">
      <div v-for="(clinic , index) in clinicList" :key="'clinic_' + clinic.clinic_name">
        <div class="d-flex align-start">
          <v-icon :color="clinic.color" size="10" class="mx-1 my-2">
            mdi-circle
          </v-icon>
          <a
            class="subtitle-1"
            style="
              color: #3d3d3d;
              vertical-align: top;
            "
          >
            {{ clinic.clinic_name }}
          </a>
        </div>
        <schedule-table :items="[clinic]" class="my-3" editable @update="updateClinicSchedule(index, $event)" />
        <v-btn
          v-if="clinic.visit_clinic_note === null"
          class="rounded-lg mb-4"
          depressed
          block
          @click="clinic.visit_clinic_note = ''"
        >
          <v-icon color="grey" size="15" class="mt-1">
            mdi-plus-circle
          </v-icon>
          新增看診時間備註
        </v-btn>
        <v-text-field
          v-else
          v-model="clinic.visit_clinic_note"
          class="text-body-2 mb-4"
          dense
          hide-details
          placeholder="請輸入看診時間備註（限制15字內）"
          outlined
          maxlength="15"
        >
          <template #append>
            <v-icon class="px-1" color="black" @click="clinic.visit_clinic_note = null">
              mdi-trash-can-outline
            </v-icon>
          </template>
        </v-text-field>
      </div>
    </div>
    <v-card-actions v-if="isEdit" class="pb-7">
      <v-spacer />
      <v-btn color="grey" outlined class="text-subtitle-2 rounded-lg" @click="onClose">
        取消
      </v-btn>
      <v-btn dark color="teal lighten-2" class="text-subtitle-2 rounded-lg" @click="onConfirm">
        儲存
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import ScheduleTable from '@/components/settings/doctor/v2/ScheduleTable.vue';
import { reverseTransformSchedule } from "@/utils/tool";

export default {
  name: 'DoctorSchedule',

  components: { ScheduleTable },

  props: {
    doctor: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      isEdit: false,
      clinicList: []
    };
  },
  watch: {
    doctor: {
      handler () {
        this.clinicList = JSON.parse(JSON.stringify(this.doctor.clinics || []));
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    reverseTransformSchedule,
    updateClinicSchedule (index, data) {
      delete data.visit_clinic_note
      const tmpClinic = this.clinicList[index]
      const $event = { ...tmpClinic , ...data }
      this.clinicList.splice(index , 1 , $event)
    },
    onConfirm () {
      this.loading = true
      this.clinicList = this.clinicList.map(clinic => {
        clinic.doctor_open_date = this.reverseTransformSchedule(clinic.schedule)
        return clinic
      })

      this.$emit('update', {
        clinics: this.clinicList,
        callback: (status) => {
          this.loading = false
          if (status === 'success') {
            this.isEdit = false;
          } else {
            alert('更新失敗，請再試一次')
          }
        }
      });
    },
    onClose () {
      this.clinicList = JSON.parse(JSON.stringify(this.doctor.clinics));
      this.isEdit = false;
    }
  }
};
</script>

<style scoped>
.flip-list-move {
  transition: transform 0.5s;
}
</style>
