export default {
  GENERAL: {
    oops: 'Oops..伺服器出現錯誤，請稍後再試一次',
    save: 'บันทึก',
    cancel: 'ยกเลิก',
    edit: 'แก้ไข',
    fieldRequired: '此欄位必填',
    to: 'To',
  },
  LOGIN: {
    title: 'เบื้องหลังการจัดการ',
    description: 'ระบบ CRM ที่สมบูรณ์ที่สุด คือระบบในการโต้ตอบ Line ที่ใช้สะดวกที่สุด',
    doctorLogin: 'เข้าสู่ระบบแพทย์',
    clinicLogin: 'เข้าสู่ระบบคลินิก',
    login: 'เข้าสู่ระบบ',
    account: 'หมายเลขบัญชี',
    password: 'รหัสผ่าน',
    inputAccount: 'กรุณากรอกหมายเลขบัญชี',
    phone: '手機號碼',
    inputPhone: '請輸入手機號碼',
    inputPassword: 'กรุณากรอกรหัสผ่าน',
    forgetPassword: 'ลืมรหัสผ่าน? ให้ฉันช่วย!',
    registerDoctor: 'สมัครสมาชิก',
    CS: 'ฉันต้องการหาฝ่ายบริการลูกค้า',
  },
  ClinicReserveTabs: {
    allDoctor: 'แพทย์ทั้งหมด',
    removeDoctor: 'ลบแพทย์',
  },
  ReserveTabs: {
    allClinic: 'คลินิกทั้งหมด',
    addRemoveClinic: 'เพิ่ม/ลบคลินิก',
    searchClinic: '搜尋診所',
    confirmAdd: '確認新增',
    manageClinic: '診所管理'
  },
  RegisterDoctorForm: {
    doctorRegister: '醫師註冊',
    doctorName: 'ชื่อแพทย์',
    inputDoctorName: '請輸入醫師姓名',
    inputEmail: '請輸入Email',
    inputEmailFormat: '請輸入正確的Email格式',
    phone: '手機號碼',
    inputPhone: '請輸入手機號碼',
    inputPhoneFormat: '請輸入正確的手機格式',
    sendOtp: '發送驗證碼',
    otp: '驗證碼',
    inputOtp: '請輸入驗證碼',
    password: '設定密碼',
    inputPassword: '請設定密碼',
    confirmPassword: '確認密碼',
    inputConfirmPassword: '請再次輸入密碼',
    inputConfirmPassword2: '密碼不一致',
    clinicDoctor: '工作診所',
    clinicDoctorPH: '若搜尋不到可略過，可後續新增',
    clinicDoctorHint: '輸入診所名稱搜尋',
    gender: 'เพศ',
    inputGender: '請選擇性別',
    confirm: '確認送出',
    female: '女',
    male: '男',
    success: '註冊成功!',
    suffixMessage: ' 為您的註冊驗證碼，請於驗證碼欄位輸入。',
  },
  ForgetPassword: {
    forgetPassword: '忘記密碼',
    phone: '手機號碼',
    inputPhone: '請輸入手機號碼',
    inputPhoneFormat: '請輸入正確的手機格式',
    sendOtp: '發送驗證碼',
    otp: '驗證碼',
    inputOtp: '請輸入驗證碼',
    password: '設定密碼',
    inputPassword: '請設定密碼',
    confirmPassword: '確認密碼',
    inputConfirmPassword: '請再次輸入密碼',
    inputConfirmPassword2: '密碼不一致',
    confirm: '確認送出',
    success: '重設密碼成功！',
    suffixMessage: ' 為您重設密碼的驗證碼，請於驗證碼欄位輸入。',
  },
  ReserveCalendar: {
    showReschedule: 'แสดงการเปลี่ยนแปลงการนัดหมาย',
    print: 'ปริ้น',
    googleCalendar: 'google 日曆',
    modifyReserveTime: '確認修改約診時間？',
    ORIGReserveTime: '原看診時間:',
    newReserveTime: '新看診時間:',
    confirm: '確認',
    sendPatientRemind: '是否需要傳送以下改約訊息給病人？',
    sendRemind: '是，請傳送提醒',
    cancelSendRemind: '否，不需傳送',
    clinicChoosePrint: '選擇醫師、日期',
    doctorChoosePrint: '選擇診所、日期',
    chooseDoctor: 'เลือกแพทย์',
    chooseClinic: '選擇診所',
    date: '日期',
    allDoctor: 'แพทย์ทั้งหมด',
    allClinic: 'คลินิกทั้งหมด',
  },
  PrintReserve: {
    print: 'ปริ้น',
    download: '下載',
    reserveList: '預約一覽表',
    printDate: '列印日期',
    printContent: '列印內容',
    reserveDate: '預約日期',
    reserveTime: '時間',
    duration: '需時(分)',
    doctor: 'หมอผู้ดูแล',
    clinic: '看診診所',
    patientName: 'ชื่อผู้ป่วย',
    gender: 'เพศ',
    birthday: '生日(民國)',  // fixme：泰國應該不是用民國
    phone: '電話',
    attendStatus: '到診狀態',
    note: '備註',
    fileTitle: '約診列表',
  },
  ReserveForm: {
    normal: 'การนัดหมายทั่วไป',
    np: 'NP การการนัดหมาย',
    notAvailable: 'การตั้งค่า「ห้ามนัด」',
    noteReserve: 'การตั้งค่า「หมายเหตุการใช้」',
    newPatientName: '請輸入新病人姓名',
    phone: '請輸入手機',
    ROCBirthday: '請輸入民國生日(ex: 650101 或 1010101)',
    reserveTime: 'เวลาการนัดหมาย',
    duration: 'เวลาที่ต้องการ (นาที)',
    mustInsert: '此欄位必填',
    chooseDoctor: 'เลือกแพทย์',
    chooseClinic: '選擇診所',
    remindOther: 'เตือนเพิ่มติม',
    remindCash: 'แจ้งเตือนจำนวนเงินที่ต้องนำมาเพิ่มเติม',
    note: 'หมายเหตุภายในคลินิก',
    confirmChange: '確認修改',
    newReserve: 'เพิ่มเติมการนัดหมาย',
  },
  SearchPatient: {
    searchLabel: 'ค้นหาชื่อ/เบอร์โทรศัพท์วันเดือนปีเกิดผู้ป่วย',  //fixme： 泰文應該不需要民國生日
    mustInsert: '此欄位必填',
  },
  ReserveDetailCard: {
    editInfo: '編輯聯絡資訊',
    reserveInfo: '約診資訊',
    cancelEdit: '取消編輯',
    editReserve: '編輯約診',
    attendStatus: '到診狀態：',
    doctor: '看診醫師：',
    clinic: '看診診所：',
    reserveTime: '約診時間：',
    reserveEndTime: '預計結束時間：',
    remindOther: '額外提醒內容：',
    remindCash: '攜帶金額：',
    note: '診所內部備註：',
    null: '無',
    otherInfo: '其他資訊',
    closeReserveRecord: '關閉就診記錄',
    openReserveRecord: '查看就診記錄',
    keepAppointment: '赴約',
    alterAppointment: '改約',
    standUp: '爽約',
    reserveRecord: '就診記錄',
    deleteReserve: '刪除約診',
    bookNext: '預約下次',
    bookNextTitle: '預約回診',
    bind: '已綁定',
    setArrive: '設為到診',
    setStandOut: '設為爽約',
    setAlter: '設為改約',
  },
  Navigation: {
    reserveList: 'แบบฟอร์มสถานะการนัดหมาย',
    onlineReserveList: 'การจองออนไลน์',
    reschedule: 'รายชื่อที่เปลี่ยนการนัดหมาย',
    patientList: 'ผู้ป่วยของฉัน',
    freeTime: 'เวลาที่ต้องการ',
    reviewList: 'ภาพรวมการรีวิว',
    upload: 'อัปโหลด',
    clinicInfo: 'การตั้งค่าข้อมูลคลินิก',
    doctorInfo: 'การตั้งค่าข้อมูลแพทย์',
    analytics: 'ข้อมูลสถิติ',
    adminSetting: 'LINE智能助理權限設定',
    message: 'ส่งข้อความ',
    questions: '詢問留言板',
    doctor: 'แพทย์',
    logout: 'ลงชื่อออก',
  },
  OnlineReserve: {
    unContact: 'ยังไม่ติดต่อ',
    contact: 'ติดต่อแล้ว',
    effect: 'ประสิทธิผล',
  },
  OnlineReserveAnalysis: {
    filterTitle: 'ตัวกรองข้อมูลการจองออนไลน์ประจำเดือน',
    confirm: 'ยืนยัน',
    reserveSource: 'แหล่งที่มาของการจอง',
    NPProportion: 'สัดส่วนผู้ป่วย NP',
    completeProportion: 'อัตราส่วนการจองที่สำเร็จ',
    onlineReserveTrend: 'แผนภูมิแนวโน้มจำนวนการจองออนไลน์',
    total: 'จำนวนทั้งหมด',
    website: 'แพลตฟอร์มผู้ช่วยทันตแพทย์',
    otherWebsite: 'เว็บไซต์ของแพทย์/คลินิก',
    pageView: '瀏覽量',
    webPageView: '網頁瀏覽量',
    webPageViewTooltip: '網頁瀏覽量為您在牙醫小幫手平台生成的診所/醫師網頁之瀏覽數',
  },
  ReserveTable: {
    noUnContact: '沒有未聯繫的約診',
    noContact: '沒有已聯繫的約診',
    canAppointment: 'เวลานี้สามารถจองได้',
    canNotAppointment: 'เวลานี้ไม่สามารถจองได้',
    additionalArrange: 'ได้จัดการเวลาเพิ่มเติมแล้ว',
    completeConcat: 'ได้ติดต่อผู้ป่วยแล้ว',
    withdraw: 'ยังไม่ได้ดำเนินการย้อนกลับ',
    editCrmNote: 'แก้ไขหมายเหตุในโรงพยาบาล',
    ok: 'แพทย์ที่นัดหมาย',
    confirm: 'ยืนยัน',
    otherReason: 'ข้อมูลเนื้อหาอื่นๆ',
    know: '我知道了',
    reasonTitle: 'โปรดเลือกเหตุผลที่ไม่สามารถจองเวลานี้ได้ และยืนยันการส่งเนื้อหาในขั้นตอนต่อไป',
    patientName: 'ชื่อผู้ป่วย',
    patientPhone: 'เบอร์โทรศัพท์มือถือของผู้ป่วย',
    reserveDoctor: 'แพทย์ที่นัดหมาย',
    bookTime: 'เวลาที่ต้องการ',
    treatment: 'รายการการักษา',
    addOnItem: 'รายการเสริม',
    note: 'หมายเหตุผู้ป่วย',
    source: 'แหล่งที่มาของผู้ป่วย',
    createTime: 'เวลาที่เข้าใช้',
    crmNote: 'หมายเหตุในโรงพยาบาล',
    action: 'ฟังก์ชั่น',
    reserveClinic: '預約診所',
    updateTime: '處理時間',
    oldPatient: '舊病人',
    Sun: '日 | 星期日',
    Mon: '一 | 星期一',
    Tues: '二 | 星期二',
    Wed: '三 | 星期三',
    Thur: '四 | 星期四',
    Fri: '五 | 星期五',
    Sat: '六 | 星期六',
    morning: '上午',
    afternoon: '下午',
    night: '晚上',
    notSpecified: '不指定',
    sendAndComplete: 'คลิกเพื่อยืนยัน ผู้ป่วยจะได้รับข้อความ SMS หรือข้อความ LINE ต่อไปนี้ และจะย้ายการจองนี้ไปยัง“ติดต่อแล้ว"',
    completeWithoutSend: 'การจองออนไลน์นี้จะย้ายไปยัง"ติดต่อแล้ว",ระบบจะไม่ส่งข้อความแจ้งเตือนอีก,กรุณาโทรแจ้งผู้ป่วยเพื่อยืนยันเวลาอื่นๆ',
    remind: '記得與病人聯繫確認時間喔！',
    moveToComplete: 'การนัดหมายนี้ถูกย้ายไปยัง"ติดต่อแล้ว"',
    pleaseCreateReserve: 'กรุณาช่วยนำข้อมูลการการนัดหมายของผู้ป่วยเข้าสู่ระบบนัดหมายคลินิก',
    pleaseCall: 'ได้แจ้งให้ผู้ป่วยโทรไปยังคลินิกแล้ว,แนะนำให้โทรการติดต่อคลินิกเลย',
    notFoundError: '噢！找不到此筆線上預約',
    patientBirth: '出生日期',
    patientGender: '性別',
  },
  RescheduleDataTable: {
    noData: '目前沒有需要改約的約診',
    alterTime: 'เปลี่ยนเวลาแล้ว',
    withdraw: 'ผู้ป่วยกดผิด',
    editNote: '修改改約備註',
    confirm: 'แพทย์ที่นัดหมาย',
    pleaseCallPatient: '請與病人聯繫改約',
    cancelWithoutConcat: '取消不需致電聯繫',
    sortByText: '排序方式',
    patientName: 'ชื่อผู้ป่วย',
    patientPhone: 'เบอร์โทรศัพท์ผู้ป่วย',
    reserveTime: 'เวลาการจอง',
    reserveDoctor: 'แพทย์ที่่นัดหมาย',
    note: 'หมายเหตุเนื้อหา',
    alterNote: '改約備註',
    updateTime: 'คลิกเพื่อเปลี่ยนเวลา',
    alterReason: 'เหตุผลที่เปลี่ยนเวลา',
    whetherCall: 'ติดต่อรึยัง',
    action: 'ฟังก์ชั่น',
    reserveClinic: '約診診所',
    confirmReschedule: '<div>此筆改約通知將刪除，記得跟病人聯繫，並<span class="red-6--text">建立新約診</span>喔！</div>',
    cancelReschedule: '系統將會提醒病人在原訂時間回診喔！',
  },
  Patients: {
    fastSearch: 'ค้นหาโดยเร็ว',
    searchPlaceholder: 'กรอก(ชื่อ,เบอร์โทรศัพท์หรือวันเดือนปีเกิดex:650101)', //fixme： 泰文應該不需要民國生日
    check: 'ตรวจสอบ',
    avatar: 'รูปภาพLine',
    patientName: 'ชื่อผู้ป่วย',
    patientPhone: 'เบอร์โทรศัพท์ผู้ป่วย',
    gender: 'เพศ',
    birthday: 'วันเกิด',
    lineBind: 'ผูกมัดLine ',
    personalInfo: 'บัตรข้อมูลพื้นฐาน',
  },
  PatientCard: {
    patientPersonalInfo: '病患基本資料卡',
    personalInfo: '基本資料',
    lineBind: 'Line 小幫手綁定狀態',
    bind: '已綁定',
    notBind: '未綁定',
    name: '姓名',
    gender: 'เพศ',
    phone: '手機',
    birthday: '民國生日',  //fixme： 泰國不用民國
    year: 'ปี',
    month: '月',
    day: '日',
    reserveRecord: '就診記錄',
    bookReserve: '預約門診',
    female: '女',
    male: '男',
    dataError: '此病患不存在,或已被刪除',
  },
  store: {
    keepAppointment: '確認到診',
    alterAppointment: '改約',
    standUp: '爽約',
    undefined: '尚未確認',
  },
  FreeTime: {
    whetherSave: '是否需要儲存修改結果？',
    yes: '是',
    no: '否',
  },
  FreeTimeSetting: {
    settingOnlineReserve: 'การตั้งค่าการจองออนไลน์ (โปรดเลือกอย่างใดอย่างหนึ่ง):',
    whetherOpenOnline: `จะให้เปิดจองออนไลน์ใน <span
                  class="font-weight-bold text-decoration-underline">วันนี้</span>/<span
                  class="font-weight-bold text-decoration-underline">วันพรุ่งนี้</span> หรือไม่:`,
    openCurrentDay: `ใช่ ฉันเปิดให้สามารถจองออนไลน์
                      <span class="font-weight-bold text-decoration-underline">วันนี้</span>
                     ได้`,
    openTomorrow: `ใช่ ฉันเปิดให้สามารถจองออนไลน์
                    <span class="font-weight-bold text-decoration-underline">พรุ่งนี้</span>
                   ได้`,
    reservationMode: '預約模式',
    smartMode: '排除已約診的時段',
    smartTooltip: '系統會綜合您開放的時段和已經存在的預約，將尚未預約的時段供民眾預約',
    freeTimeGapMode: '預約單位時間',
    freeTimeGapTooltip: '系統將顯示相對應的空檔時間，若為看診速度較快的醫師可選擇15分鐘為單位',
    thirtyMinutes: '30分鐘',
    fifteenMinutes: '15分鐘',
    week: '星期',
    freeTime: '空檔時段',
    date: '日期',
    everyWeek: '週期空檔',
    single: '單次空檔',
    settingWeek: '設定週期時段',
    settingSingle: '設定單次時段',
    viewSetting: '檢視全部時段',
    manualFreeTime: 'เลือกเวลาที่ต้องการเอง',
    manualSubTitle: '(ตั้งเวลาเปิดคลินิกด้วยตนเอง)',
    manualTrueBtn: '是，我要使用手動開啟空檔',
    manualFalseBtn: '否，我要繼續使用手動空檔時段',
    systemToManual: '原本狀態為 連動健保系統時間，您的線上預約已跟健保系統連動，\n系統已設定自動抓取沒有預約的時段顯示給民眾選取，\n確定要改為手動開啟空檔？',
    registerToManual: '原本狀態為 登記預約 ，您即將開啟手動空檔設定，NP將會從Google搜尋時與您預約，請確實開啟時段，並注意線上預約通知，如需增加助理接收通知，請洽牙醫小幫手客服。',
    disableToManual: '原本狀態為 不開放線上預約 ，您即將開啟手動空檔設定，NP將會從Google搜尋時與您預約，請確實開啟時段，並注意線上預約通知，如需增加助理接收通知，請洽牙醫小幫手客服。',
    systemFreeTime: 'เชื่อมเวลากับระบบประกันสุขภาพ', //fixme： 泰國有健保？
    systemSubTitle: '(โปรดยืนยันว่าคลินิกของคุณมีการทำงานร่วมกันหรือไม่)', //fixme： 泰國有健保？
    systemTrueBtn: '是，我已確認診所有合作串接',
    systemFalseBtn: '否，我要繼續聯動健保系統時間',
    toSystem: '您即將開啟 健保系統連動 設定，\n請確認診所與牙醫小幫手有合作串接，\n若沒有將無法顯示任何可預約時段。',
    registerFreeTime: 'เปิดให้ลงทะเบียน',
    registerSubTitle: '(ผู้ป่วยสามารถดูข้อมูลและลงทะเบียนนัดหมายได้)',
    registerTrueBtn: '是，我確認要預約登記',
    registerFalseBtn: '否，我要繼續使用登記預約',
    toRegister: '您即將開啟 線上預約登記，\n根據數據顯示，有時間供病人選擇時預約數量可增加3倍，\n請確認是否僅供登記。',
    disableFreeTime: 'ไม่อนุญาตให้จองออนไลน์',
    disableTrueBtn: '是，我確認不開放線上預約',
    disableFalseBtn: '否，我要維持不開放線上預約',
    manualToDisable: '您即將關閉 手動空檔時段\n牙醫小幫手平台每月有數十萬位民眾在搜尋醫師，\n您將會錯失許多NP與您預約的機會，請確認是否關閉線上預約。',
    systemToDisable: '您即將關閉 連動健保系統時間\n牙醫小幫手平台每月有數十萬位民眾在搜尋醫師，\n您將會錯失許多NP與您預約的機會，請確認是否關閉線上預約。',
    registerToDisable: '您即將關閉 登記預約\n牙醫小幫手平台每月有數十萬位民眾在搜尋醫師，\n您將會錯失許多NP與您預約的機會，請確認是否關閉線上預約。',
  },
  Review: {
    reviewSetting: 'การตั้งการรีวิว：',
    googleFirst: 'ลำดับความสำคัญในการรีวิวของ Google',
    dentcoFirst: 'การรีวิวอย่างละเอียดโดยชื่อจริง',
    publicSetting: 'การตั้งค่าการรีวิวสาธารณะ：',
    reviewFilter: 'การคัดกรองการรีวิว',
    doctor: 'แพทย์',
    clinic: '診所',
    treatmentItem: 'รายการการรักษา',
    order: 'จัดอันดับ',
    reviewCount: 'ความคิดเห็น',
    allReview: 'การรีวิวทั้งหมด',
    reviewWithMessage: 'แนบความคิดเห็น',
    negativeReview: 'ความคิดเห็นเชิงลบ',
    all: 'ทั้งหมด',
    latest: '最新',
    gradDESC: 'คะแนนสูงสุด',
    gradASC: 'คะแนนต่ำสุด',
    chooseDoctor: '-- กรุณาเลือกแพทย์ --',
    showAll: '全部顯示',
    hiddenNegative: '負評不公開',
    hiddenAll: '評價不公開',
    allDoctor: 'แพทย์ทั้งหมด',
    allClinic: '所有診所',
  },
  ReviewDetailCard: {
    doctor: 'แพทย์ผู้ดูแล：',
    clinic: 'ผู้ช่วยติดตาม：',
    treatment: 'รายการที่พบหมอ：',
    reviewMessage: 'เนื้อหาความคิดเห็น：',
    reply: 'ตอบกลับ',
    doctorReply: '醫師回覆：',
    alterReply: '修改回覆',
    submit: '送出',
    replyFromLine: '您的回覆內容將傳送LINE通知給病人，點擊確認送出。',
    confirm: 'ยืนยัน',
    pleaseInput: '請確實填寫回覆內容',
  },
  Upload: {
    upload: '上傳',
    chooseClinic: '-- 請選擇診所 --',
    success: '上傳成功',
    fileLargeError: '檔案錯誤或太大',
    chooseFile: '請選擇一個檔案',
  },
  ClinicSettings: {
    avatar: 'สัญลักษณ์คลินิก',
    uploadNewAvatar: 'อัปโหลดรูปภาพประจำตัว',
    name: 'ชื่อคลินิก',
    address: 'ที่อยู่คลินิก',
    city: '縣/市',
    area: '鄉/鎮/區',
    streetAddress: '街道地址',
    tel: 'เบอร์โทรศัพท์คลินิก',
    telCode: '區碼',
    telNumber: '號碼',
    philosophy: 'แนวคิดการให้คำปรึกษา',
    fbLink: 'ลิงค์แฟนเพจ FB',
    webLink: 'ลิงค์เว็บไซต์คลินิก',
    uploadFail: '上傳失敗',
    updateFail: '更新失敗',
  },
  ClinicOpening: {
    businessHours: 'เวลาการนัดหมาย',
    updateFail: '更新失敗',
  },
  Opening: {
    morningBH: '上午診:',
    afternoonBH: '下午診:',
    nightBH: '晚診:',
    Mon: 'วันจันทร์',
    Tues: 'วันอังคาร',
    Wed: 'วันพุธ',
    Thur: 'วันพฤหัสบดี',
    Fri: 'วันศุกร์',
    Sat: 'วันเสาร์',
    Sun: 'วันอาทิตย์',
    morning: 'ตอนเช้า',
    afternoon: 'ตอนบ่าย',
    night: 'ตอนกลางคืน',
  },
  EditTime: {
    from: '從',
    to: '至',
  },
  CaseAlbum: {
    clinicCase: 'สภาพแวดล้อมคลินิก',
    doctorCase: 'แบ่งปันความรู้ด้านสุขอนามัย',
    hint: '*โปรดระวังรูปที่อัพเป็นตัวอย่าง จะต้องได้รับการยืนยันว่าได้ยินยอมเป็นลายลักษณ์อักษรจากผู้ป่วยแล้ว อาจมีผู้ไม่ประสงค์ดีใช้ช่องทางจากการร้องเรียนถึงกรมอนามัยเพื่อก่อความรบกวน สอบถามข้อมูลเพิ่มเติม กรุณาติดต่อฝ่ายบริการลูกค้า', //fixme： 台灣的衛生局會管道泰國去嗎
    uploadFail: '上傳失敗',
  },
  CropImage: {
    title: 'อัปโหลดรูปภาพ',
    chooseImg: 'เลือกรูปภาพ',
    cropTool: '裁切工具',
    confirmUpload: 'ยืนยันการอัปโหลด',
    concatCS: 'ติดต่อฝ่ายบริการลูกค้า',
    chooseImgFile: '請選擇圖片檔',
    alertError: '很抱歉，您的瀏覽器不支援裁切圖片，請點選確認直接上傳',
  },
  ClinicBanner: {
    banner: 'หน้าปกคลินิก',
    uploadFail: '上傳失敗',
  },
  DoctorSettings: {
    avatar: 'รูปภาพแพทย์',
    uploadNewAvatar: 'อัปโหลดรูปภาพประจำตัว',
    name: 'ชื่อแพทย์',
    birthday: 'วันเกิด',
    year: 'ปี',
    month: '月',
    day: '日',
    philosophy: 'แนวคิดการพบหมอ',
    education: 'ระดับการศึกษา',
    add: 'เพิ่มเดิม',
    experience: 'คุณสมบัติแพทย์',
    addExperience: 'เพิ่มคุณสมบัติ',
    fbLink: 'ลิงค์แฟนเพจ FB',
    webLink: 'ลิงค์เว็บไซต์แพทย์',
    treatments: 'รายการการรักษาของแพทย์',
    addTreatments: 'เพิ่มรายการการรักษา',
    uploadFail: '上傳失敗',
    updateFail: '更新失敗',
    present: '至今',
  },
  Education: {
    schoolName: '校名',
    department: 'แผนก',
    year: 'ปี',
    present: '至今',
  },
  Analytics: {
    patientRecommend: 'ผู้ป่วยแนะนำ',
    efficient: 'ประสิทธิภาพการพบหมอ',
    onlineReserveResult: 'ผลของการจองออนไลน์',
  },
  OnTimeAnalysis: {
    allDoctor: 'แพทย์ทั้งหมด',
    latestMonthOnTimeRatio: 'อัตราส่วนการตรงต่อเวลาของผู้ป่วยในเดือนที่ผ่านมา',
    latestSixMonthLateRation: 'อัตราการมาสายโดยรวมในช่วง 6 เดือนที่ผ่านมา',
    average: '地區平均',
    topTenLate: 'รายชื่อผู้ป่วยมาสาย 10 อันดับแรกในเดือนที่ผ่านมา',
    noLateData: '太棒了！近一個月沒有遲到名單',
    clinicNoData: '此診所尚無資料',
    doctorNoData: '此醫師尚無資料',
    lateRation: '遲到比率',
    patientName: 'ชื่อผู้ป่วย',
    patientPhone: 'เบอร์โทรศัพท์ผู้ป่วย',
    lateCount: 'มาสายกี่ครั้ง',
    totalLateTime: 'มาสายทั้งหมดกี่นาที',
    latestMonthLate: 'จำนวนนาทีที่มาสายของแต่ละคนในเดือนที่ผ่านมา',
    minutes: '分鐘',
    latestMonthLateRatio: 'อัตราการมาสายในเดือนที่ผ่านมา',
    onTime: 'ตรงเวลา',
    standUp: 'ไม่มา',
    late: 'มาสาย',
  },
  lineInviteAnalysis: {
    bindRatio: '指定區間綁定率',
    bindRatioTooltip: '指定區間綁定率 = 日期區間內全院綁定人數 / (全院患者-沒有手機的患者)',
    bindRatio30: '近30日綁定率',
    bindRatio30Tooltip: '近30日綁定率 = 近30日內全院綁定人數 / (近30日內全院患者-沒有手機的患者)',
    inviteGrow: 'แนะนำการเติบโต',
    inviteRanking: 'การแนะนำอันดับ',
    noInvite: '暫無病患推薦',
    totalInvite: '累積推薦數',
    ranking: 'อันดับ',
    patientName: 'ชื่อผู้ป่วย',
    recommendDoctor: '推薦醫師',
    recommendClinic: 'คลินิกแนะนำ',
    recommendCount: 'จำนวนผู้แนะนำ',
  },
  Administrator: {
    addMember: '新增人員：',
    phone: '請輸入手機號碼',
    confirm: 'แพทย์ที่นัดหมาย',
    pleaseRegister: '請先加入並綁定',
    pleaseRegisterText: `此使用者的手機門號 <span class="text-decoration-underline font-weight-bold">尚未加入</span> 或 <span class="text-decoration-underline font-weight-bold">尚未綁定</span>。
          請傳送以下連結邀請該位使用者加入：`,
    pleaseRegisterText2: `或掃描 QR Code 加入LINE小幫手。

          加入後請 <span class="text-decoration-underline font-weight-bold">依照流程進行綁定</span>，綁定後再於此操作新增權限。`,
    IKnow: 'ฉันเข้าใจแล้ว',
    clinicPermission: 'สิทธิ์ทั้งคลินิก',
    clinicPermissionTooltip: '全院權限：可於LINE智能助理觀看全院的約診/評價/數據，並於每週日10:00接收全院當週的病人數據報表，如需接收改約/線上預約即時通知，請另加入診所Line通知群組',
    assistantPermission: 'สิทธิ์ผู้ช่วย',
    assistantPermissionTooltip: '助理權限：此權限僅可於LINE智能助理，觀看全院之約診，無法瀏覽評價/數據和接收報表，如需接收改約/線上預約即時通知，請另加入診所Line通知群組',
    doctorPermission: 'สิทธิ์แพทย์',
    clinicDoctorPermissionTooltip: '醫師權限：此權限僅可於LINE智能助理，觀看該位醫師的約診/評價/數據，即時接收改約/線上預約通知，並於每週日10:00接收該為醫師當週的病人數據報表，如該位醫師有特助協助處理，請登入醫師帳號額外設定',
    doctorPermissionTooltip: '醫師權限：此權限可於LINE智能助理，觀看所有跑點診所之病人的約診/評價/數據，即時接收改約/線上預約通知，並於每週日10:00接收該位醫師的當週病人數據報表。',
    doctorAssistantTooltip: '助理權限：此權限僅可於LINE智能助理，觀看所有跑點診所約診，無法瀏覽評價/數據和接收報表，如需接收改約/線上預約即時通知，請另加入醫師LINE群組。',
    alreadyExistAssistant: '手機號碼已存在於助理權限的人員列表中',
    duplicateAssistant: '手機號碼和助理人員重複, 請先移除後, 再新增{label}的人員',
    alreadyExistClinic: '手機號碼已存在於全院權限中',
    duplicateClinic: '手機號碼已存在於全院權限中, 請先移除後, 再新增{label}的人員',
    alreadyExistDoctor: '手機號碼已存在於醫師權限中',
    duplicateDoctor: '手機號碼已存在於醫師權限中, 請先移除後, 再新增{label}的人員',
  },
  AdminCard: {
    newRole: 'เพิ่ม',
    ok: 'แพทย์ที่นัดหมาย',
    removeTitle: '您確定要將{name}移出{label}嗎？',
  },
  Voice: {
    twoDaysAgo: 'สองวันก่อนเที่ยง ',
    oneDayAgo: 'หนึ่งวันก่อนเที่ยง ',
    currentDay: 'วันนี้ตอนเช้า ',
  },
  Message: {
    autoTeachSetting: 'การตั้งค่าการให้คำปรึกษาด้านสุขอนามัยอัตโนมัติ',
    autoTeachLabel: 'ก่อนพบหมอ15นาที ฉันต้องการเปิดการให้คำปรึกษาสุขอนามัยของช่องปากแบบอัตโนมัติ',
    autoRemindSetting: '自動化提醒設定',
    twoDaysAgo: 'สองวันก่อนเที่ยง',
    oneDayAgo: 'หนึ่งวันก่อนเที่ยง',
    currentDay: 'วันนี้ตอนเช้า8:00',
    permissionDeny: '此功能僅開放自有LINE使用，請聯繫牙醫小幫手客服詢問切換～',
    IKnow: 'ฉันเข้าใจแล้ว',
    remindRemind: '請注意，如全部未勾選，民眾將不會收到約診提醒，建議還是至少要勾選一個時間哦',
    startSend: 'เริ่มส่งข้อความ',
    sendLog: 'ประวัติบรอดแคสต์',
    remindLog: 'ประวัติการแจ้งการนัดหมาย',
    selectDayPrefix: '顯示',
    selectDaySuffix: '天內資料',
  },
  LogCard: {
    to: '傳送對象：',
  },
  SendStep: {
    filterTitle: 'ตัวกรองเพื่อส่ง',
    filterDesc: 'ตัวคัดกรอง (ข้ามได้)',
    filterDate: 'วันที่พบหมอ(เลือกช่วงได้)',
    filterSatisfaction: 'ผู้ป่วยให้ความคิดเห็นในเชิงบวก',
    filterDoctor: 'แพทย์ที่นัดหมาย',
    selectAll: 'ทั้งหมด',
    filterClinic: '看診診所',
    filterLatent: 'ศักยภาพในการรักษา',
    filterName: 'ชื่อผู้ป่วย',
    confirmFilter: 'ยืนยันตัวกรอง',
    clearFilter: 'ล้างตัวคัดกรอง',
    countSelect: 'เลือกผู้ที่จะส่งให้ (เลือกแล้ว: {count}คน)',
    patientList: 'ชื่อผู้ป่วย',
    cancelSelect: '取消全選',
    searchName: 'ค้นหาชื่อผู้ป่วย',
    showSelect: 'รายการที่เลือกแล้ว',
    clearAll: 'ลบทั้งหมด',
    confirmSelect: '確認選擇',
    editSelectContent: 'แก้ไขเนื้อหา/เลือกเซ็ต',
    confirmContent: 'ยืนยันเนื้อหา',
    confirmSend: 'ยืนยันการส่ง',
    countSend: '發送人數： {count}人',
    sendContent: '發送內容：',
    useActivity: '使用活動套版',
    otherContent: '額外傳送訊息：',
    picture: '圖片：',
    content: '想說的話：',
    confirm: '確定，我要送出訊息',
    alertMax: '篩選病人數大於500人，無法進一步篩選病人，將直接選取所有符合條件的對象(共{count}人)。',
    accept: '可以，我能接受',
    refuse: '不，我要重新篩選',
    alertOwnChannel: '由於LINE的政策調整，不再支援大型LINE帳號的群發，若要使用群發功能，請申請自有LINE帳號，牙醫小幫手會協助將相關功能及已經加入牙醫小幫手的病人轉移過去。',
    howApply: '如何申請自有LINE帳號',
    concatCS: '聯繫客服',
    success: '發送成功',
    IKnow: 'ฉันเข้าใจแล้ว',
    age: '歲',
    genderUnit: '性',
    pleaseChooseActivity: '請選擇想使用的套版',
    pleaseEditContent: '請上傳圖片或輸入想說的話',
    pleaseChoosePatient: '請選擇至少一位發送對象',
  },
  EditCard: {
    chooseMod: 'เลือกวิธีบรอดแคสต์',
    stepOne: '1. เลือกเทมเพลต',
    uploadPano: 'Pano 報告上傳',
    previewPano: 'pano報告預覽:',
    patientName: '病患名稱',
    patientAge: '病患年齡',
    patientPhone: 'เบอร์โทรศัพท์ผู้ป่วย',
    panoPic: '上傳pano圖片',
    savePano: '儲存並準備發送',
    upload: '上傳報告',
    reUpload: '我要重新上傳報告',
    openUpload: '開啟上傳界面',
    seeIntro: 'คลิกเพื่อดูการแนะนำ',
    stepTwo: '2. คำที่อยากจะพูดเพิ่มเติม (สามารถละเว้นได้)',
    uploadPic: 'อัปโหลดรูปภาพที่ต้องการส่ง',
    content: 'กรอกสิ่งที่ต้องการจะพูด',
    fieldRequired: '此欄位必填',
    useActivity: 'ใช้เทมเพลตบรอดแคสต์',
    useCustom: 'ใช้บรอดแคสต์แบบกำหนดเอง',
  },
  SyncGoogleCalendar: {
    askTitle: '是否開啟同步將約診資料同步至 google 日曆？',
    hint: '開啟後將於30分鐘後開始生效。',
    turnOnSync: '開啟同步',
    turnOffSync: '關閉同步',
    ignore: '暫不設定',
    successTurnOff: '成功取消同步',
  },
  PostOperativeCare: {
    patientName: '病患名稱',
    patientPhone: '病患手機',
    reserveTime: '看診時間',
    reserveDoctor: '約診醫師',
    reserveClinic: '預約診所',
    reserveNote: '預約備註內容',
    reportBackTime: '病人回報時間',
    reportBack: '治療後症狀回報',
    needContact: '是否需要診所聯繫',
    reportBackNote: '關懷備註',
    processed: '已處理',
    unprocessed: '未處理',
    action: '功能',
  }
}
