import { render, staticRenderFns } from "./SaveAlert.vue?vue&type=template&id=2043224c&scoped=true"
import script from "./SaveAlert.vue?vue&type=script&lang=js"
export * from "./SaveAlert.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2043224c",
  null
  
)

export default component.exports