<template>
  <v-row>
    <v-col cols="12">
      <v-row no-gutters dense class="flex-nowrap">
        <v-col cols="auto" class="flex-grow-1 text-h6">{{ $t('ClinicOpening.businessHours') }}</v-col>
        <v-col cols="auto" class="flex-shrink-1">
          <template v-if="editOpening">
            <v-btn text color="primary" @click="save">{{ $t('GENERAL.save') }}</v-btn>
            <v-btn text @click="cancel">{{ $t('GENERAL.cancel') }}</v-btn>
          </template>
          <v-btn v-else color="primary" text @click="editOpening = true">{{ $t('GENERAL.edit') }}</v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <template v-for="(clinic, i) in clinics">
        <v-chip color="grey-5" text-color="white" class="ml-1" :class="{'mt-4': i > 0}" label>
          {{ clinic.clinic_name }}
        </v-chip>
        <opening :key="clinic.clinic_code" :ref="`opening-${i}`" :edit="editOpening"
                 :date="clinic.doctor_open_date"/>
      </template>
    </v-col>
  </v-row>
</template>

<script>
import Opening from "../Opening";
import { mapGetters } from "vuex";
import { updateDoctorInfoAPI } from "../../../api";

export default {
  name: "doctorOpening",
  props: ['clinics'],
  components: {Opening},
  data() {
    return {
      editOpening: false
    }
  },
  computed: {
    ...mapGetters([
      'axiosParamData',
    ]),
  },
  methods: {
    save() {
      this.$emit('load')

      for (let i = 0; i < this.clinics.length; i++) {
        const openingDate = JSON.parse(JSON.stringify(this.$refs[`opening-${i}`][0].openingDate))
        openingDate.unshift(openingDate.splice(openingDate.length - 1, 1)[0])
        this.clinics[i].doctor_open_date = openingDate
      }

      const data = {
        ...this.axiosParamData,
        clinics: this.clinics,
      }
      updateDoctorInfoAPI(data).catch(() => {
        alert(this.$t('ClinicOpening.updateFail'))
      }).finally(() => {
        this.$emit('update')
      })
      this.editOpening = false
    },
    cancel() {
      for (let i = 0; i < this.clinics.length; i++) {
        this.$refs[`opening-${i}`][0].reset()
      }
      this.editOpening = false
    }
  }
}
</script>

<style scoped>

</style>