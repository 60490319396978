<template>
  <v-card>
    <v-overlay :value="loading" absolute>
      <v-progress-circular indeterminate size="64"/>
    </v-overlay>

    <v-card-title>
      會員成長曲線圖
    </v-card-title>

    <v-card-text>
      <v-dialog ref="dialog" v-model="modal" persistent width="290px">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            :value="showSelectMonth"
            label="月份篩選"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          />
        </template>
        <v-date-picker
          v-model="selectMonths"
          type="month"
          :selected-items-text="selectMonths.join('~')"
          :allowed-dates="allowedMonths"
          locale="zh-TW"
          scrollable
          range
        >
          <v-spacer/>
          <v-btn text color="primary" @click="modal = false">{{ $t('GENERAL.cancel') }}</v-btn>
          <v-btn text color="primary" @click="confirm">{{ $t('OnlineReserveAnalysis.confirm') }}</v-btn>
        </v-date-picker>
      </v-dialog>

      <ve-line
        :data="chartData"
        :legend="{show: false}"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import dayjs from "dayjs";
import { mapGetters } from "vuex";
import { getAnalysisRegistrationAPI } from "../../api";

export default {
  name: "RegistrationAnalysis",

  data() {
    const yearsAgo = dayjs().subtract(11, 'month').format('YYYY-MM')
    const currentMonth = dayjs().format('YYYY-MM')

    return {
      loading: false,
      chartData: {
        columns: ['month', 'line', 'messenger'],
        rows: []
      },

      modal: false,
      selectMonths: [yearsAgo, currentMonth],
      confirmMonths: [yearsAgo, currentMonth]
    }
  },

  computed: {
    ...mapGetters([
      'axiosParamData'
    ]),
    showSelectMonth() {
      return this.confirmMonths.join(' ~ ')
    }
  },

  methods: {
    getAnalysisData() {
      const params = {
        ...this.axiosParamData,
        query_start: this.confirmMonths[0],
        query_end: this.confirmMonths[1] || this.confirmMonths[0]
      }

      getAnalysisRegistrationAPI(params).then(res => {
        this.chartData.rows = res.data
      })
    },
    confirm() {
      this.confirmMonths = JSON.parse(JSON.stringify(this.selectMonths.sort()))
      this.modal = false
      this.getAnalysisData()
    },
    allowedMonths(v) {
      return dayjs(v) < dayjs().endOf('month') && dayjs(v) > dayjs().subtract(24, 'month');
    }
  },

  mounted() {
    this.getAnalysisData()
  }
}
</script>

<style scoped>

</style>
