<template>
  <v-card>
    <v-btn
      fab
      icon
      absolute
      width="48"
      height="48"
      style="top: 0; right: 0; overflow: hidden;"
      @click="$emit('close')"
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <div
      class="px-4"
      :class="{'px-8': $vuetify.breakpoint.smAndUp}"
    >
      <v-sheet height="160" class="d-flex align-center">
        <div>
          <v-avatar :size="$vuetify.breakpoint.smAndUp ? 100 : 64" class="mx-5">
            <img
              :src="patient.file_name_photo"
              :alt="patient.name"
              @error="patient.file_name_photo = assetURL('/asset/crm/avatar-unknow.png')"
            >
          </v-avatar>
        </div>
        <div class="text-sm-h5 text-h6 font-weight-bold">
          <template v-if="!editName">
            {{ patient.name }}
            <img
              v-show="patient.is_block == '1'"
              src="https://i.imgur.com/2wD2hbm.png" width="25" height="auto"
            >
            <v-icon class="ml-auto" @click="editName = true">mdi-pencil</v-icon>
          </template>
          <v-text-field
            v-else
            ref="nameRef"
            v-model="patient.name"
            :rules="[v => !!v || this.$t('GENERAL.fieldRequired')]"
            dense
            autofocus
            append-outer-icon="mdi-check"
            @click:append-outer="savePatientInfo('editName')"
          />
        </div>
        <v-spacer/>
        <v-dialog
          v-model="bookNextDialog"
          max-width="600"
          persistent
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              dark
              color="red-6"
              class="py-6 mx-2"
              @click="addBlacklist()"
            >
              加入黑名單
            </v-btn>
            <v-btn
              v-bind="attrs"
              v-on="on"
              dark
              color="blue-6"
              class="py-6"
              @click="bookNextDialog = true"
            >
              預約看診建立
            </v-btn>
          </template>
          <v-card>
            <v-card-title>{{ $t('PatientCard.bookReserve') }} -- {{ patient.name }}</v-card-title>
            <v-btn
              fab
              icon
              absolute
              width="48"
              height="48"
              style="top: 0; right: 0; overflow: hidden;"
              @click="bookNextDialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <reserve-form
              :reserve="{patient: {patient_code: patient.patient_code}}"
              :reserveFormDialog.sync="bookNextDialog"
              mode="bookNext"
            />
          </v-card>
        </v-dialog>
      </v-sheet>
      <v-tabs v-model="tab">
        <v-tab href="#patientList">基本資料</v-tab>
        <v-tab href="#lineMember">會員綁定狀態</v-tab>
        <v-tab href="#messengerMember">就診紀錄</v-tab>
        <v-tab href="#note" @click="getPatientNote">備註</v-tab>
      </v-tabs>
    </div>
    <v-divider/>
    <v-tabs-items v-model="tab">
      <v-tab-item value="patientList">
        <v-sheet
          color="grey-8"
          class="px-4"
          :class="{'px-8': $vuetify.breakpoint.smAndUp}"
          height="360"
        >
          <v-card-title class="grey-7--text">
            基本資訊
          </v-card-title>
          <div>
            <!--        性別-->
            <div class="d-flex align-center">
              <v-sheet width="120" color="transparent">
                <v-subheader v-text="$t('PatientCard.gender')"/>
              </v-sheet>
              <div>
                <v-sheet v-if="!editGender" width="160" class="d-flex" color="transparent">
                  {{ patient.sex | getGender }}
                  <v-icon class="ml-auto" @click="editGender = true">mdi-pencil</v-icon>
                </v-sheet>
                <v-sheet v-else width="160" color="transparent">
                  <v-select
                    :items="sexItems"
                    v-model="patient.sex"
                    dense
                    outlined
                    hide-details
                    autofocus
                    append-outer-icon="mdi-content-save-outline"
                    @click:append-outer="savePatientInfo('editGender')"
                  />
                </v-sheet>
              </div>
            </div>

            <!--        生日-->
            <div class="d-flex align-center mt-2">
              <v-sheet width="120" color="transparent">
                <v-subheader>{{ $t('PatientCard.birthday') }}</v-subheader>
              </v-sheet>
              <div>
                <v-sheet v-if="!editBirthday" width="160" class="d-flex" color="transparent">
                  {{ parseInt(patient.birthday) * 1000 | formatROCDate }}
                  <v-icon class="ml-auto" @click="showBirthday">mdi-pencil</v-icon>
                </v-sheet>
                <v-sheet v-else class="d-flex birth-input" color="transparent">
                  <v-sheet width="60" color="transparent">
                    <v-text-field
                      v-model="year"
                      dense
                      :suffix="$t('PatientCard.year')"
                      hide-details
                    />
                  </v-sheet>

                  <v-sheet width="60" class="ml-2" color="transparent">
                    <v-text-field
                      v-model="month"
                      dense
                      type="numeric"
                      :suffix="$t('PatientCard.month')"
                      hide-details
                    />
                  </v-sheet>

                  <v-sheet width="60" class="ml-2" color="transparent">
                    <v-text-field
                      v-model="day"
                      dense
                      type="numeric"
                      :suffix="$t('PatientCard.day')"
                      hide-details
                    />
                  </v-sheet>

                  <v-icon style="margin: 4px 0 4px 9px" :disabled="validBirth" @click="savePatientInfo('editBirthday')">
                    mdi-content-save-outline
                  </v-icon>
                </v-sheet>
              </div>
            </div>
          </div>

          <!--        手機-->
          <div class="d-flex align-center mt-2">
            <v-sheet width="120" color="transparent">
              <v-subheader v-text="$t('PatientCard.phone')"/>
            </v-sheet>
            <div>
              <v-sheet v-if="!editPhone" width="160" class="d-flex" color="transparent">
                {{ patient.phone }}
                <v-icon class="ml-auto" @click="editPhone = true">mdi-pencil</v-icon>
              </v-sheet>
              <v-sheet v-else width="160" color="transparent">
                <v-text-field
                  v-model="patient.phone"
                  dense
                  autofocus
                  hide-details
                  outlined
                  append-outer-icon="mdi-content-save-outline"
                  @click:append-outer="savePatientInfo('editPhone')"
                />
              </v-sheet>
            </div>
          </div>

        </v-sheet>
      </v-tab-item>
      <v-tab-item value="lineMember">
        <v-sheet
          color="grey-8"
          class="px-4"
          :class="{'px-8': $vuetify.breakpoint.smAndUp}"
          height="360"
        >
          <v-card-title class="grey-7--text">
            會員綁定狀態
          </v-card-title>
          <div class="px-4 mt-4">
            <v-row>
              <v-col v-for="(status, idx) in bindStatusDetail" :key="idx" cols="12">
                <div class="d-flex align-center">
                  <v-img
                    :src="status.icon"
                    max-width="20"
                    width="20"
                    class="mr-1"
                  />
                  <div class="text-body-2" v-html="status.text"/>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-sheet>
      </v-tab-item>
      <v-tab-item value="messengerMember">
        <v-sheet
          color="grey-8"
          class="px-4"
          :class="{'px-8': $vuetify.breakpoint.smAndUp}"
          height="360"
        >
          <v-row style="height: 100%;">
            <v-col cols="12" md="3">
              <v-card-title class="grey-7--text">
                患者就診紀錄
              </v-card-title>
            </v-col>
            <v-col cols="12" md="9" class="overflow-y-auto" style="height: 100%;">
              <reserve-record :patient-code="patientCode"/>
            </v-col>
          </v-row>
        </v-sheet>
      </v-tab-item>
      <v-tab-item value="note">
        <v-sheet
          color="grey-8"
          class="px-4"
          :class="{'px-8': $vuetify.breakpoint.smAndUp}"
          height="360"
        >
          <v-card-title class="grey-7--text">
            備註
            <v-dialog v-model="patientNoteDialog" max-width="500">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  v-bind="attrs"
                  v-on="on"
                  color="primary"
                >編輯備註</v-btn>
              </template>
              <v-card>
                <v-card-title>病人備註</v-card-title>
                <v-card-text>
                  <v-textarea
                    v-if="patientNoteDialog"
                    ref="patientNoteRef"
                    :value="patientNote"
                    outlined
                    placeholder="請輸入病人備註內容"
                    rows="4"
                    no-resize
                    clearable
                    hide-details
                  />
                </v-card-text>
                <v-card-actions>
                  <v-spacer/>
                  <v-btn
                    color="primary"
                    @click="updatePatientNote($refs.patientNoteRef.internalValue)"
                  >確定修改</v-btn>
                  <v-btn dark color="grey-4" @click="patientNoteDialog = false">取消</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card-title>
          <div class="px-4 mt-4 text-pre-line" v-text="patientNote"/>
        </v-sheet>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import {
  addBlacklistAPI,
  getPatientInfoAPI,
  getPatientNoteAPI,
  updatePatientAPI,
  updatePatientNoteAPI
} from '../../api';
import { bindStatus } from '../../config/bindConfig';
import { assetURL } from '../../utils/filters';
import ReserveForm from '../reserve/ReserveForm';
import ReserveRecord from '../reserve/ReserveRecord';

export default {
  name: "PatientCard",

  components: {ReserveForm, ReserveRecord},

  props: {
    patientCode: {
      type: String,
      required: true
    },
    showTab: {
      type: String,
      required: false
    }
  },

  data() {
    return {
      patient: {},
      assetURL,
      bookNextDialog: false,
      tab: null,

      editName: false,

      editGender: false,
      sexItems: [
        {value: '0', text: this.$t('PatientCard.female')},
        {value: '1', text: this.$t('PatientCard.male')},
      ],

      editBirthday: false,
      year: null,
      month: null,
      day: null,

      editPhone: false,

      patientNoteDialog: false,
      patientNote: null
    }
  },

  computed: {
    ...mapState({
      user: state => state.user
    }),
    ...mapGetters([
      'axiosParamData',
      'clinicUser',
      'doctorUser'
    ]),
    validBirth() {
      return !(Number(this.year) * Number(this.month) * Number(this.day) > 0 ||
        Number(this.year) + Number(this.month) + Number(this.day) === 0)
    },
    getName() {
      if (this.clinicUser) {
        return this.user.clinic_name
      } else if (this.doctorUser) {
        return this.user.doctor_name + this.$t('Navigation.doctor')
      }
    },
    bindStatusDetail() {
      const bindStatus = []
      const configs = []

      if (this.clinicUser) {
        if (this.patient.clinic_oa_status) {
          configs.push(
            {bindStatusKey: 'line', eventStatusKey: 'clinic_oa_status', name: this.getName, hiddenNull: true},
            {bindStatusKey: 'messenger', eventStatusKey: 'clinic_fb_status', name: this.getName, hiddenNull: true}
          )
        } else {
          configs.push(
            {bindStatusKey: 'line', eventStatusKey: 'dentco_oa_status', name: '小幫手公用', hiddenNull: true},
            {bindStatusKey: 'messenger', eventStatusKey: 'clinic_fb_status', name: this.getName, hiddenNull: true}
          )
        }

      } else if (this.doctorUser) {
        if (this.patient.doctor_oa_status) {
          configs.push(
            {bindStatusKey: 'line', eventStatusKey: 'doctor_oa_status', name: this.getName, hiddenNull: true},
            {bindStatusKey: 'messenger', eventStatusKey: 'doctor_fb_status', name: this.getName, hiddenNull: true}
          )
        } else {
          configs.push(
            {bindStatusKey: 'line', eventStatusKey: 'dentco_oa_status', name: '小幫手公用', hiddenNull: true},
            {bindStatusKey: 'messenger', eventStatusKey: 'doctor_fb_status', name: this.getName, hiddenNull: true}
          )
        }
      }

      configs.forEach(config => {
        if (!config.hiddenNull || this.patient[config.eventStatusKey])
          bindStatus.push(
            this.showBindStatus({
              key: config.bindStatusKey,
              status: this.patient[config.eventStatusKey],
              name: config.name
            })
          )
      })

      return bindStatus
    }
  },

  watch: {
    showTab: {
      handler(v) {
        if (v) this.tab = v
        if (v === 'note') this.getPatientNote()
      },
      immediate: true
    }
  },

  methods: {
    getPatientInfo() {
      const params = {
        ...this.axiosParamData,
        patient_code: this.patientCode
      }
      getPatientInfoAPI(params).then(res => {
        this.patient = res.data
      }).catch(e => {
        if (e.response && e.response.data && e.response.data.messages) {
          alert(this.$t('PatientCard.dataError'))
        } else {
          alert(this.$t('GENERAL.oops'))
          console.error(e)
        }
        this.$emit('close')
      })
    },
    showBirthday() {
      if (this.patient.birthday) {
        const birth = this.$moment(parseInt(this.patient.birthday) * 1000)
        this.year = birth.format("YYYY") - 1911
        this.month = birth.format("MM")
        this.day = birth.format("DD")
      } else {
        this.year = this.month = this.day = null
      }
      this.editBirthday = true
    },
    savePatientInfo(key) {
      const data = {...this.axiosParamData}

      switch (key) {
        case 'editName':
          if (!this.$refs.nameRef.validate()) return
          data.name = this.patient.name
          break
        case 'editGender':
          data.sex = this.patient.sex
          break
        case 'editPhone':
          data.phone = this.patient.phone
          break
        case 'editBirthday':
          if (this.year && this.month && this.day)
            data.birthday = this.$moment(`${parseInt(this.year) + 1911}-${this.month.padStart(2, 0)}-${this.day.padStart(2, 0)}`).format('X')
          else
            data.birthday = ''
          break
      }
      updatePatientAPI(this.patient.patient_code, data).then(() => {
        this[key] = false
        this.getPatientInfo()
      })
    },
    showBindStatus(config) {
      const assetConfig = bindStatus(config)
      return {
        icon: assetURL(assetConfig.icon),
        text: assetConfig.text
      }
    },
    addBlacklist() {
      if (confirm('確定加入黑名單？')) {
        const vm = this;
        this.loading = true
        const data = {
          ...this.axiosParamData,
          phone: this.patient.phone
        };
        addBlacklistAPI(data).then(res => {
          alert('加入成功')
        }).catch(e => {
          const messages = e.response.data.messages || '';

          if (messages !== '') {
            alert(messages)
          } else {
            alert(this.$t('GENERAL.oops'))
          }
        }).finally(() => {

        })
      }
    },
    getPatientNote() {
      const params = {
        ...this.axiosParamData,
        patient_code: this.patientCode
      }
      getPatientNoteAPI(params).then(res => {
        this.patientNote = res.data.note
      }).catch(e => {
        alert('取得備註失敗')
        console.error(e)
      })
    },
    updatePatientNote(value) {
      const data = {
        ...this.axiosParamData,
        note: value,
        patient_code: this.patientCode
      }
      updatePatientNoteAPI(data).then(() => {
        this.getPatientNote()
        this.patientNoteDialog = false
        this.$emit('successPb')
      })
    }
  },

  mounted() {
    this.getPatientInfo()
  }
}
</script>

<style lang="scss">
.birth-input input {
  text-align: center;
}
</style>
