import { render, staticRenderFns } from "./SmsRefillLog.vue?vue&type=template&id=96118c72&scoped=true"
import script from "./SmsRefillLog.vue?vue&type=script&lang=js"
export * from "./SmsRefillLog.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "96118c72",
  null
  
)

export default component.exports