<template>
  <v-container>
    <guide-tour config-key="analytics.invite"/>
    <div class="mt-4">
      <v-dialog ref="dialog" v-model="modal" persistent width="290px">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="confirmDate"
            label="月份篩選"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          />
        </template>
        <v-date-picker
          v-model="sortDate"
          type="month"
          :allowed-dates="allowedMonths"
          :selected-items-text="sortDate.join('~')"
          locale="zh-TW"
          scrollable
          range
        >
          <v-spacer/>
          <v-btn text color="primary" @click="modal = false">{{ $t('GENERAL.cancel') }}</v-btn>
          <v-btn text color="primary" @click="confirm">{{ $t('OnlineReserveAnalysis.confirm') }}</v-btn>
        </v-date-picker>
      </v-dialog>
    </div>
    <v-row>
      <v-col cols="12" sm="6">
        <v-card class="">
          <v-overlay :value="loadBind" absolute>
            <v-progress-circular indeterminate size="64"/>
          </v-overlay>
          <v-card-title>
            {{ $t('lineInviteAnalysis.bindRatio30') }}
            <v-tooltip bottom max-width="500">
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="grey lighten-1" v-bind="attrs" v-on="on">mdi-help-circle-outline</v-icon>
              </template>
              <span v-text="$t('lineInviteAnalysis.bindRatio30Tooltip')"/>
            </v-tooltip>
          </v-card-title>
          <v-card-text>
            <div class="text-center text-h2 font-weight-bold primary--text">
              {{
                totalPatient30 - noPhonePatient30 ? (bindPatient30 / (totalPatient30 - noPhonePatient30) * 100).toFixed(1) : 0
              }}%
            </div>
            <div class="text-center text-h6 mt-3">
              [{{ bindPatient30 }} / ({{ totalPatient30 }} - {{ noPhonePatient30 }})]
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6">
        <v-card class="">
          <v-overlay :value="loadBind" absolute>
            <v-progress-circular indeterminate size="64"/>
          </v-overlay>
          <v-card-title>
            {{ $t('lineInviteAnalysis.bindRatio') }}
            <v-tooltip bottom max-width="500">
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="grey lighten-1" v-bind="attrs" v-on="on">mdi-help-circle-outline</v-icon>
              </template>
              <span v-text="$t('lineInviteAnalysis.bindRatioTooltip')"/>
            </v-tooltip>
          </v-card-title>
          <v-card-text>
            <div class="text-center text-h2 font-weight-bold primary--text">
              {{
                totalPatient - noPhonePatient ? (bindPatient / (totalPatient - noPhonePatient) * 100).toFixed(1) : 0
              }}%
            </div>
            <div class="text-center text-h6 mt-3">
              [{{ bindPatient }} / ({{ totalPatient }} - {{ noPhonePatient }})]
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6">
        <v-card class="invite-rate-card ">
          <v-overlay :value="loadLobby" absolute>
            <v-progress-circular indeterminate size="64"/>
          </v-overlay>
          <v-card-title>
            加 LINE 人數趨勢圖
            <v-tooltip bottom max-width="500">
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="grey lighten-1" v-bind="attrs" v-on="on">mdi-help-circle-outline</v-icon>
              </template>
              <span>此為各項管道在在此期間內邀請加入LINE人數</span>
            </v-tooltip>
          </v-card-title>
          <v-card-text>
            <ve-line :data="lobbyLinePeopleChartData" :settings="lobbyLinePeopleChartSettings"/>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6">
        <v-card class="invite-rate-card ">
          <v-overlay :value="loadLobby" absolute>
            <v-progress-circular indeterminate size="64"/>
          </v-overlay>
          <v-card-title>
            櫃檯現場加LINE成效
            <v-tooltip bottom max-width="500">
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="grey lighten-1" v-bind="attrs" v-on="on">mdi-help-circle-outline</v-icon>
              </template>
              <span>此為櫃檯在此期間內邀請有看診且尚未綁定的患者加入LINE的比例</span>
            </v-tooltip>
          </v-card-title>
          <v-card-text>
            <ve-line :data="lobbyLineEfficiencyChartData" :legend="{show: false}"
                     :settings="lobbyLineEfficiencyChartSettings"/>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6">
        <v-card class="invite-rate-card ">
          <v-overlay :value="loadInvite" absolute>
            <v-progress-circular indeterminate size="64"/>
          </v-overlay>
          <v-card-title>{{ $t('lineInviteAnalysis.inviteGrow') }}</v-card-title>
          <v-card-text>
            <ve-line :data="chartData" :legend="{show: false}" :settings="chartSettings"/>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6">
        <v-card>
          <v-overlay :value="loadInvite" absolute>
            <v-progress-circular indeterminate size="64"/>
          </v-overlay>
          <v-card-title>
            {{ $t('lineInviteAnalysis.inviteRanking') }}
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">
                  mdi-help-circle-outline
                </v-icon>
              </template>
              被推薦者需加入LINE好友才會出現在紀錄中，推薦者也才能獲得點數獎勵。
            </v-tooltip>
          </v-card-title>
          <v-card-text color="indigo darken-3">
            <v-data-table :headers="inviteHeader" :items="inviteList" :no-data-text="$t('lineInviteAnalysis.noInvite')"
                          mobile-breakpoint="350"/>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { getAnalysisLineBindAPI, getAnalysisLineInviteAPI, getAnalysisLineLobbyAPI } from "../../api";
import GuideTour from "../utils/GuideTour.vue";

export default {
  name: "lineInviteAnalysis",
  components: {GuideTour},
  data() {
    const currentMonth = this.$moment().format('YYYY-MM')
    return {
      loadBind: true,
      loadInvite: true,
      totalPatient: 0,
      bindPatient: 0,
      noPhonePatient: 0,
      totalPatient30: 0,
      bindPatient30: 0,
      noPhonePatient30: 0,
      chartData: {
        columns: ['date', 'total'],
        rows: []
      },
      chartSettings: {
        labelMap: {
          total: this.$t('lineInviteAnalysis.totalInvite')
        }
      },
      inviteList: [],
      modal: false,
      date: [currentMonth],
      confirmDate: [currentMonth],
      lobbyLinePeopleChartData: {
        columns: [],
        rows: []
      },
      lobbyLinePeopleChartSettings: {
        labelMap: {
          counter: '立牌',
          nfc: 'NFC',
          sms: '簡訊',
          friend_recommendation: '好友推薦',
          business_card: '名片',
          website: '官網',
          google_map: 'Google map',
          fb: 'FB',
          ig: 'IG',
          youtube: 'YouTube',
          tiktok: 'Tiktok',
          ad: '廣告',
          poster: '海報',
          helper_website: '小幫手網站',
        }
      },
      lobbyLineEfficiencyChartData: {
        columns: ['month', 'rate'],
        rows: []
      },
      lobbyLineEfficiencyChartSettings: {
        labelMap: {
          rate: '百分比'
        }
      },
      loadLobby: true,
    }
  },
  computed: {
    ...mapGetters([
      'axiosParamData'
    ]),
    inviteHeader() {
      if (this.axiosParamData.type === '1')
        return [
          {text: this.$t('lineInviteAnalysis.ranking'), value: 'ranking'},
          {text: this.$t('lineInviteAnalysis.patientName'), value: 'name'},
          {text: this.$t('lineInviteAnalysis.recommendDoctor'), value: 'invite_name'},
          {text: this.$t('lineInviteAnalysis.recommendCount'), value: 'invite_count'},
        ]
      else
        return [
          {text: this.$t('lineInviteAnalysis.ranking'), value: 'ranking'},
          {text: this.$t('lineInviteAnalysis.patientName'), value: 'name'},
          {text: this.$t('lineInviteAnalysis.recommendClinic'), value: 'invite_name'},
          {text: this.$t('lineInviteAnalysis.recommendCount'), value: 'invite_count'},
        ]
    },
    sortDate: {
      get() {
        return [...new Set(this.date)];
      },
      set(v) {
        this.date = v.sort((a, b) => {
          return new Date(a) - new Date(b)
        })
      },
    },
  },
  mounted() {
    this.getLineBindData()
    this.getInviteData()
    this.getLineLobby()
  },
  watch: {
    confirmDate: {
      handler: function (n, o) {
        this.getLineLobby()
        this.getLineBindData()
      },
      deep: true
    }
  },
  methods: {
    getLineBindData() {
      const params = {
        ...this.axiosParamData,
        query_start: this.sortDate[0],
        query_end: this.sortDate.length > 1 ? this.sortDate[1] : this.sortDate[0]
      }
      getAnalysisLineBindAPI(params).then(res => {
        this.totalPatient = res.data.total_patients
        this.bindPatient = res.data.bind_patients
        this.totalPatient30 = res.data.total_patients_30
        this.bindPatient30 = res.data.bind_patients_30
        this.noPhonePatient = res.data.no_patients
        this.noPhonePatient30 = res.data.no_patients_30
        this.loadBind = false
      })
    },
    getInviteData() {
      getAnalysisLineInviteAPI(this.axiosParamData).then(res => {
        this.chartData.rows = res.data.invite_rate
        this.inviteList = res.data.invite_list
        this.loadInvite = false
      })
    },
    allowedMonths(val) {
      return this.$moment(val).isSameOrBefore(this.$moment(), 'month')
    },
    getLineLobby() {
      const params = {
        ...this.axiosParamData,
        query_start: this.sortDate[0],
        query_end: this.sortDate.length > 1 ? this.sortDate[1] : this.sortDate[0]
      }
      getAnalysisLineLobbyAPI(params).then(res => {
        this.lobbyLinePeopleChartData.columns = Object.keys(res.data.people[0])
        this.lobbyLinePeopleChartData.columns.sort((a, b) => {
          return Object.keys(this.lobbyLinePeopleChartSettings.labelMap).indexOf(a) 
            - Object.keys(this.lobbyLinePeopleChartSettings.labelMap).indexOf(b)
          ;
        });
        this.lobbyLinePeopleChartData.rows = res.data.people
        this.lobbyLineEfficiencyChartData.rows = res.data.efficiency
        this.loadLobby = false
      })
    },
    confirm() {
      this.confirmDate = JSON.parse(JSON.stringify(this.sortDate))
      this.modal = false
    },
  }

}
</script>

<style scoped>

</style>
