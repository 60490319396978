<template>
  <div>
    <v-card-title class="tiffany-10--text text-md-h5 font-weight-bold">
      {{ getValueByPath(guideConfig, configKey).title }}
    </v-card-title>
    <v-card-subtitle class="py-0 grey-7--text">
      <slot></slot>
      <div v-if="getValueByPath(guideConfig, configKey).docLink">
        {{ getValueByPath(guideConfig, configKey).subTitle }}
        若有相關問題可參考
        <a :href="getDocLink(getValueByPath(guideConfig, configKey).docLink)" target="_blank" class="link_blue--text">操作說明</a>
        或
        <a :href="$enum.DENTCO_CS_LINE" target="_blank" class="link_blue--text">聯繫客服</a>
      </div>
      <div v-else>
        {{ getValueByPath(guideConfig, configKey).subTitle }}
        若有相關問題可
        <a :href="$enum.DENTCO_CS_LINE" target="_blank" class="link_blue--text">聯繫客服</a>
      </div>
      <div v-if="getValueByPath(guideConfig, configKey).suffix">{{ getValueByPath(guideConfig, configKey).suffix }}</div>
    </v-card-subtitle>
  </div>
</template>

<script>
import guideConfig from "../../guideConfig";
import { DENTCO_NOTION_HOST } from "../../constant/module/notion";

export default {
  name: "GuideTour",

  props: {
    configKey: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      guideConfig,
    }
  },

  methods: {
    getValueByPath(obj, path) {
      return path.split('.').reduce((acc, part) => acc && acc[part], obj);
    },
    getDocLink(path) {
      const sanitizedPath = path.startsWith('/') ? path.replace(/^\/+/, '') : path;
      return `${this.$enum.DENTCO_NOTION_HOST}/${sanitizedPath}`;
    }
  }
}
</script>

<style scoped>

</style>
