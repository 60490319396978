<template>
  <v-row>
    <v-col cols="12">
      <v-row no-gutters dense class="flex-nowrap">
        <v-col cols="auto" class="flex-grow-1 text-h6">{{ clinicUser ? $t('CaseAlbum.clinicCase') : $t('CaseAlbum.doctorCase') }}</v-col>
        <v-col cols="auto" class="flex-shrink-1">
          <template v-if="editCasePhoto">
            <v-btn text color="primary" @click="save">{{ $t('GENERAL.save') }}</v-btn>
            <v-btn text @click="cancel">{{ $t('GENERAL.cancel') }}</v-btn>
          </template>
          <v-btn v-else color="primary" text @click="editCasePhoto = true">{{ $t('GENERAL.edit') }}</v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-row>
        <v-col
          v-for="photo in casePhotos"
          :key="photo.id"
          class="d-flex child-flex"
          cols="6"
          sm="4"
        >
          <v-img
            :src="photo.addr"
            :aspect-ratio="2"
            class="grey lighten-2"
            contain
            @click="editCasePhoto ? null : selectedItem = photo.addr"
            :style="editCasePhoto ? null : 'cursor: pointer'"
            :gradient="photo.delete ? 'rgba(0,0,0,.3), rgba(0,0,0,.3)' : null"
          >
            <template v-if="editCasePhoto" v-slot>
              <v-btn
                fab
                dark
                x-small
                right
                absolute
                style="right: 0"
                :color="photo.delete ? 'red' : 'primary'"
                @click="photo.delete = !photo.delete"
              >
                <v-icon dark>{{ photo.delete ? 'mdi-restore' : 'mdi-trash-can-outline' }}</v-icon>
              </v-btn>
            </template>
            <template v-slot:placeholder>
              <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
              >
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-col>
        <v-col
          v-for="photo in newPhotos"
          :key="photo.id"
          class="d-flex child-flex"
          cols="6"
          sm="4"
        >
          <v-img
            :src="photo.addr"
            :aspect-ratio="2"
            class="grey lighten-2"
            contain
            @click="editCasePhoto ? null : selectedItem = photo.addr"
            :style="editCasePhoto ? null : 'cursor: pointer'"
            :gradient="photo.delete ? 'rgba(0,0,0,.3), rgba(0,0,0,.3)' : null"
          >
            <template v-if="editCasePhoto" v-slot>
              <v-btn
                fab
                dark
                x-small
                right
                absolute
                style="right: 0"
                :color="photo.delete ? 'red' : 'primary'"
                @click="photo.delete = !photo.delete"
              >
                <v-icon dark>{{ photo.delete ? 'mdi-restore' : 'mdi-trash-can-outline' }}</v-icon>
              </v-btn>
            </template>
            <template v-slot:placeholder>
              <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
              >
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-col>
        <v-col v-if="editCasePhoto" class="d-flex child-flex" cols="6" sm="4">
          <v-card hover @click="showCrop = true">
            <v-responsive :aspect-ratio="2" class="align-center text-center">
              <v-icon>mdi-image-plus</v-icon>
            </v-responsive>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
    <v-overlay v-if="selectedItem">
      <v-img :src="selectedItem" max-height="98vh" max-width="98vw">
        <template v-slot>
          <v-btn fab dark x-small right absolute style="right: 0" @click="selectedItem = null">
            <v-icon dark>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-img>
    </v-overlay>
    <v-dialog v-if="showCrop" v-model="showCrop" max-width="500">
      <crop-image :aspect-ratio="2" :hint="hint" CS @close="showCrop = false" @upload="addPhoto"/>
    </v-dialog>
  </v-row>
</template>

<script>
import CropImage from "./CropImage";
import { deleteClinicCaseAPI, deleteDoctorCaseAPI, uploadClinicCaseAPI, uploadDoctorCaseAPI } from "../../api";
import { mapGetters } from "vuex";
export default {
  name: "CaseAlbum",
  components: {CropImage},
  props: ['data'],
  data() {
    return {
      editCasePhoto: false,
      selectedItem: null,
      showCrop: false,
      newPhotos: [],
      newPhotosFile: [],
      casePhotos: null,
      hint: this.$t('CaseAlbum.hint'),
    }
  },
  computed: {
    ...mapGetters([
      'axiosParamData',
      'clinicUser'
    ]),
  },
  watch: {
    data: {
      handler: function (val, oldVal) { this.reset() },
      deep: true
    }
  },
  methods: {
    addPhoto(image) {
      this.newPhotosFile.push(image)
      this.newPhotos.push({
        id: this.newPhotosFile.length - 1,
        addr: URL.createObjectURL(image),
        delete: false,
        tmp: true
      })
      this.showCrop = false
    },
    cancel() {
      this.newPhotos = []
      this.newPhotosFile = []
      this.casePhotos.map(e => e.delete = false)
      this.editCasePhoto = false
    },
    save() {
      this.$emit('load')
      const updateId = this.newPhotos.filter(e => !e.delete).map(e => e.id)
      let updateAPI, deleteAPI
      if (this.clinicUser) {
        updateAPI = uploadClinicCaseAPI
        deleteAPI = deleteClinicCaseAPI
      } else {
        updateAPI = uploadDoctorCaseAPI
        deleteAPI = deleteDoctorCaseAPI
      }
      const formData = new FormData()
      for (const [key, value] of Object.entries(this.axiosParamData)) {
        formData.append(key, value)
      }

      const asyncAll = []
      if (updateId.length) {
        updateId.forEach(i => {
          formData.append("files[]", this.newPhotosFile[i]);
        })

        const uploadBanner = updateAPI(formData)
        asyncAll.push(uploadBanner)
      }

      const deleteId = this.casePhotos.filter(e => e.delete).map(e => e.id)

      deleteId.forEach(i => {
        asyncAll.push(deleteAPI(i, this.axiosParamData))
      })
      Promise.all(asyncAll).then(() => {
        this.editCasePhoto = false
        this.newPhotos = []
        this.newPhotosFile = []
      }).catch(() => {
        alert(this.$t('CaseAlbum.uploadFail'))
      }).finally(() => {
        this.$emit('update')
      })
    },
    reset() {
      this.data.map(e => e.delete = false)
      this.casePhotos = JSON.parse(JSON.stringify(this.data))
    }
  },
  mounted() {
    this.reset()
  }
}
</script>

<style scoped>

</style>