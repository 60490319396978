export const treatmentCategory = [
  {
    "id": 1,
    "name": "基礎治療",
    "en_name": "General treatment",
    "sort": 1,
    "icon_id": 5,
    "title": "基礎治療",
    "description": "檢查與基本治療，維護口腔健康"
  },
  {
    "id": 2,
    "name": "矯正治療",
    "en_name": "Orthodontics",
    "sort": 5,
    "icon_id": 33,
    "title": "矯正治療",
    "description": "為牙齒不整齊或咬合不正提供矯正"
  },
  {
    "id": 3,
    "name": "植牙治療",
    "en_name": "Dental Implants",
    "sort": 6,
    "icon_id": 17,
    "title": "植牙治療",
    "description": "針對缺牙提供人工牙根修復，恢復功能與美觀"
  },
  {
    "id": 4,
    "name": "美白/貼片",
    "en_name": "Anterior Aesthetic",
    "sort": 7,
    "icon_id": 34,
    "title": "美白/貼片",
    "description": "改善牙齒色澤與外觀，增添微笑自信"
  },
  {
    "id": 5,
    "name": "智齒拔除",
    "en_name": "Wisdom teeth extraction",
    "sort": 8,
    "icon_id": 15,
    "title": "智齒拔除",
    "description": "移除阻生或不適智齒，減少後續不適"
  },
  {
    "id": 6,
    "name": "兒童治療",
    "en_name": "Pediatric treatment",
    "sort": 9,
    "icon_id": 13,
    "title": "兒童治療",
    "description": "針對孩童牙齒成長照護，預防與治療"
  },
  {
    "id": 7,
    "name": "根管治療",
    "en_name": "Endodontic treatment (root canal tx)",
    "sort": 10,
    "icon_id": 16,
    "title": "根管治療",
    "description": "清除受感染神經組織，恢復健康功能"
  },
  {
    "id": 8,
    "name": "牙周治療",
    "en_name": "Periodontal treatment",
    "sort": 11,
    "icon_id": 20,
    "title": "牙周治療",
    "description": "針對牙齦與牙周組織問題，改善口腔健康"
  },
  {
    "id": 9,
    "name": "假牙",
    "en_name": "Prosthetics",
    "sort": 12,
    "icon_id": 19,
    "title": "假牙",
    "description": "以人工材料製作替代牙齒，恢復咀嚼功能"
  },
  {
    "id": 10,
    "name": "睡眠呼吸中止症諮詢",
    "en_name": "Anti-Snore Appliance",
    "sort": 99,
    "icon_id": 36,
    "title": "睡眠呼吸中止症諮詢",
    "description": "分析睡眠呼吸中止問題，提供改善建議"
  },
  {
    "id": 11,
    "name": "正顎手術",
    "en_name": "Orthognathic Surgery",
    "sort": 99,
    "icon_id": 18,
    "title": "正顎手術",
    "description": "透過手術矯正顎骨位置，改善臉部對稱"
  },
  {
    "id": 12,
    "name": "顳顎關節",
    "en_name": "Temporomandibular Joint Disorder",
    "sort": 99,
    "icon_id": 18,
    "title": "顳顎關節",
    "description": "緩解咬合不適與顳顎關節疼痛問題"
  },
  {
    "id": 15,
    "name": "定期檢查/塗氟",
    "en_name": "Regular Check/Fluoride Application",
    "sort": 2,
    "icon_id": 26,
    "title": "定期檢查/塗氟",
    "description": "定期檢查與塗氟保護，預防蛀牙問題"
  },
  {
    "id": 999,
    "name": "其他",
    "icon_id": 1,
    "title": "",
    "description": ""
  }
]
export const educationItems = [
  {name: '臺灣大學', logo: 'https://dentco.tw/images/school/臺灣大學.png'},
  {name: '陽明大學', logo: 'https://dentco.tw/images/school/陽明大學.png'},
  {name: '臺北醫學大學', logo: 'https://dentco.tw/images/school/臺北醫學大學.png'},
  {name: '國防醫學院', logo: 'https://dentco.tw/images/school/國防醫學院.png'},
  {name: '中國醫藥大學', logo: 'https://dentco.tw/images/school/中國醫藥大學.png'},
  {name: '中山醫學大學', logo: 'https://dentco.tw/images/school/中山醫學大學.png'},
  {name: '高雄醫學大學', logo: 'https://dentco.tw/images/school/高雄醫學大學.png'},
  {name: '美國賓夕法尼亞大學', logo: 'https://dentco.tw/images/school/美國賓夕法尼亞大學.png'},
  {name: '其他', logo: 'https://dentco.tw/images/school/其他.png'},  // 必須放最後
];