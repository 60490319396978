<template>
  <div>
    <v-card outlined class="pa-2">
      <v-overlay :value="loading" absolute>
        <v-progress-circular
          indeterminate
          size="64"
        ></v-progress-circular>
      </v-overlay>
      <v-container fluid>
        <v-row>
          <v-col cols="12" sm="6">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="dates"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateRangeText"
                  :label="$t('SendStep.filterDate')"
                  readonly
                  clearable
                  hide-details
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="dates" no-title scrollable range locale="zh">
                <v-spacer/>
                <v-btn text color="primary" @click="menu = false">
                  取消
                </v-btn>
                <v-btn text color="primary" @click="$refs.menu.save(dates)">
                  確認
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="6"/>
          <v-col cols="12" sm="6">
            <v-select
              v-if="clinicUser"
              v-model="selectDoctors"
              multiple
              chips
              small-chips
              hide-details
              clearable
              :items="doctorItems"
              item-text="doctor_name"
              item-value="doctor_code"
              :label="$t('SendStep.filterDoctor')"
            />
            <v-select
              v-else
              v-model="selectClinic"
              multiple
              chips
              small-chips
              hide-details
              clearable
              :items="clinicItems"
              item-text="clinic_name"
              item-value="clinic_code"
              :label="$t('SendStep.filterClinic')"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-select
              v-model="selectBindStatus"
              hide-details
              clearable
              :items="filterBindStatusOptions"
              label="綁定狀態"
            />
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="inputPatientName" hide-details :label="$t('SendStep.filterName')" clearable/>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <div class="mt-12">
      <v-btn color="primary" @click="submit">{{ $t('SendStep.confirmFilter') }}</v-btn>
      <v-btn text @click="clearFilter">{{ $t('SendStep.clearFilter') }}</v-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getClinicListAPI, getDoctorListAPI, getSMSMessagePatientAPI } from "@/api";

export default {
  name: 'PatientFilter',

  props: {
    value: {
      type: Array,
      required: true
    },
    count: {
      type: Number,
      required: true
    }
  },

  data() {
    return {
      loading: false,
      menu: false,
      dates: [],
      selectDoctors: [],
      doctorItems: [],
      selectClinic: [],
      clinicItems: [],
      selectBindStatus: null,
      filterBindStatusOptions: [
        {value: -1, text: '未綁定'},
        {value: 1, text: '已綁定'}
      ],
      inputPatientName: null
    };
  },

  computed: {
    ...mapGetters([
      'axiosParamData',
      'clinicUser',
    ]),
    dateRangeText: {
      get: function () {
        return this.dates.slice().sort((a, b) => new Date(a) - new Date(b)).join(' ~ ')
      },
      set: function () {
        this.dates = []
      }
    }
  },

  mounted() {
    if (this.clinicUser)
      getDoctorListAPI(this.axiosParamData).then(res => {
        this.doctorItems = res.data
      })
    else
      getClinicListAPI(this.axiosParamData).then(res => {
        this.clinicItems = res.data
      })
  },

  methods: {
    clearFilter() {
      this.selectDoctors = this.$options.data().selectDoctors
      this.selectClinic = this.$options.data().selectClinic
      this.selectBindStatus = this.$options.data().selectBindStatus
      this.inputPatientName = this.$options.data().inputPatientName
      this.dates = this.$options.data().dates
    },
    submit() {
      this.loading = true

      const params = {
        ...this.axiosParamData,
        filter_clinic: this.selectClinic.join(',') || null,
        filter_doctor: this.selectDoctors.join(',') || null,
        name: this.inputPatientName || null,
        line_bot_is_verified: this.selectBindStatus || null,
        start_time: this.dates.slice().sort((a, b) => new Date(a) - new Date(b))[0] || null,
        end_time: this.dates.slice().sort((a, b) => new Date(a) - new Date(b))[1] || null,
      }

      getSMSMessagePatientAPI(params).then(res => {
        this.$emit('update:value', res.data.data)
        this.$emit('update:count', res.data.count)
        this.loading = false
        this.$emit('nextStep')
      })
    }
  }
};
</script>

<style scoped>
/* Your styles here */
</style>
