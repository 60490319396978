<template>
  <v-dialog v-model="dialog" max-width="fit-content">
    <v-card style="padding: 18px 80px;">
      <v-card-title v-if="title" :class="titleClass">
        <slot name="title">
          {{ title }}
        </slot>
      </v-card-title>
      <v-card-text v-if="content" :class="contentClass">
        <slot name="content">
          <div v-text="content" class="text-pre-line"/>
        </slot>
      </v-card-text>
      <v-card-actions class="d-flex flex-column" style="row-gap: 20px;">
        <slot name="actions">
          <div style="width: 300px;">
            <slot name="confirm">
              <v-btn v-if="confirmBtnText" :color="confirmBtnColor" dark block @click="confirm">
                {{ confirmBtnText }}
              </v-btn>
            </slot>
          </div>
          <div style="width: 300px;">
            <slot name="cancel">
              <v-btn v-if="cancelBtnText" :color="cancelBtnColor" dark block @click="cancel">
                {{ cancelBtnText }}
              </v-btn>
            </slot>
          </div>
        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ConfirmDialog',

  props: {
    value: {
      type: Boolean,
      default: false
    },
    title: {
      type: [String, Boolean],
      default: false
    },
    content: {
      type: [String, Boolean],
      default: false
    },
    titleClass: {
      type: String,
      default: 'justify-center font-weight-bold text-h5'
    },
    contentClass: {
      type: String,
      default: 'mt-2 red-6--text justify-center d-flex'
    },
    confirmBtnText: {
      type: String,
      default: '確定'
    },
    cancelBtnText: {
      type: String,
      default: '取消'
    },
    confirmBtnColor: {
      type: String,
      default(value) {
        console.log(value)
        return value === null ? 'primary' : value;
      }
    },
    cancelBtnColor: {
      type: String,
      default: 'grey-7'
    }
  },

  data() {
    return {
    }
  },

  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },


  methods: {
    closeDialog() {
      this.dialog = false
    },
    confirm() {
      this.$emit('confirm', {
        callback: () => {
          this.closeDialog()
        }
      });
    },
    cancel() {
      if (this.$listeners?.cancel) {
        this.$emit('cancel', {
          callback: () => {
            this.closeDialog()
          }
        });
      } else {
        this.closeDialog()
      }
    }
  }
};
</script>

<style scoped>
/* Your styles here */
</style>
