<template>
  <v-card class="py-8 px-8" rounded="xl">
    <v-overlay :value="loading" absolute z-index="3">
      <v-progress-circular indeterminate size="64"/>
    </v-overlay>
    <v-row>
      <!-- 基本資料區 -->
      <v-col cols="12" class="py-2">
        <div class="d-flex justify-space-between mb-8">
          <h6 class="text-subtitle-1 font-weight-bold">
            擅長治療
          </h6>
          <div class="d-flex justify-end" style="gap: 8px">
            <v-btn
              color="grey"
              outlined
              class="text-subtitle-2 rounded-lg"
              @click="onClose"
            >
              取消
            </v-btn>
            <v-btn
              color="teal lighten-2"
              class="text-subtitle-2 rounded-lg white--text"
              @click="onConfirm"
            >
              儲存
            </v-btn>
          </div>
        </div>
        <v-form ref="form" v-model="valid">
          <draggable
            v-model="hashtagItems"
            tag="div"
            class="px-0 d-flex flex-wrap"
            handle=".draggable"
            animation="200"
            style="row-gap: 4px;"
            @start="drag = true"
            @end="drag = false"
          >
            <v-col v-for="(e, i) in hashtagItems" :key="i" cols="12" md="12" lg="6" class="px-4">
              <v-list-item
                class="grey lighten-4 rounded-lg mb-5"
                dense
              >
                <v-btn text class="px-0" small style="min-width: fit-content">
                  <v-icon size="20" class="draggable">
                    mdi-cursor-move
                  </v-icon>
                </v-btn>
                <v-list-item-title class="flex-grow-1 px-2">
                  <div
                    class="d-flex flex-column justify-center py-6"
                    style="gap: 0.6rem"
                  >
                    <div class="d-flex" style="gap: 0.5rem">
                      <treatment-images :icon-id.sync="e.dental_icon_id" :dentcoIcons="dentcoIcons">
                        <v-img
                          :src="getIconSrc(e.dental_icon_id)"
                          alt="treat image"
                          class="rounded-full"
                          height="40"
                          width="40"
                          contain
                        />
                      </treatment-images>
<!--                      <v-select-->
<!--                        v-model="e.category"-->
<!--                        :items="treatmentCategory"-->
<!--                        filled-->
<!--                        dense-->
<!--                        hide-details-->
<!--                        outlined-->
<!--                        item-value="id"-->
<!--                        item-text="name"-->
<!--                        required-->
<!--                        placeholder="選擇擅長治療"-->
<!--                        background-color="white"-->
<!--                        aria-required="true"-->
<!--                        @change="onChangeCategory(e, $event)"-->
<!--                      />-->

                      <v-text-field
                        v-model="e.title"
                        background-color="white"
                        placeholder="請輸入項目名稱"
                        dense
                        :rules="[rules.required]"
                        hide-details="auto"
                        outlined
                        required
                        aria-required="true"
                      />
                    </div>
                    <v-textarea
                      v-model="e.description"
                      background-color="white"
                      placeholder="請輸入細節內容"
                      rows="5"
                      dense
                      outlined
                      required
                      aria-required="true"
                      no-resize
                    />
                  </div>
                </v-list-item-title>
                <delete-dialog @confirm="hashtagItems.splice(i, 1)"/>
              </v-list-item>
            </v-col>
          </draggable>
        </v-form>
      </v-col>
      <!-- 社群連結區 -->
    </v-row>
    <v-card-actions class="px-0">
      <v-btn class="rounded-lg flex-grow-1" depressed style="text-transform: none !important;" @click="addTreatments">
        <v-icon color="grey" size="15">
          mdi-plus-circle
        </v-icon>
        點我新增擅長治療
      </v-btn>
      <v-btn
        color="grey"
        outlined
        class="text-subtitle-2 rounded-lg"
        @click="onClose"
      >
        取消
      </v-btn>
      <v-btn
        color="teal lighten-2"
        class="text-subtitle-2 rounded-lg white--text"
        @click="onConfirm"
      >
        儲存
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import draggable from 'vuedraggable';
import UploadImageDialog from "@/components/utils/UploadImageDialog.vue";
import TreatmentImages from './TreatmentImages.vue';
import DeleteDialog from './DeleteDialog.vue';
// import { treatmentCategory } from "@/utils/sharedData";

export default {
  name: 'TreatmentSectionAdvForm',
  components: { DeleteDialog, TreatmentImages, UploadImageDialog, draggable },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    doctor: {
      type: Object,
      default: () => {
        return {
          treatment: []
        };
      }
    },
    dentcoIcons: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data () {
    return {
      loading: false,
      drag: true,
      valid: true,
      hashtagItems: [],
      rules: {
        required: value => !!value || '*請輸入有效文字，不可輸入空白內容'
      }
    };
  },
  watch: {
    show: {
      handler (show) {
        if (show && this.doctor) {
          this.hashtagItems = JSON.parse(JSON.stringify(this.doctor.hashtag_item_structure));
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    getIconSrc(id) {
      const icon = this.dentcoIcons.find(icon => icon.id === id)
      return icon?.full_path
    },
    // onChangeCategory (e, event) {
    //   const category = this.treatmentCategory.find((s) => s.id === event)
    //   e.title = category.title;
    //   e.description = category.description;
    //   e.dental_icon_id = category.icon_id || 1;
    // },
    onConfirm () {
      this.$refs.form.validate()
      if (!this.valid) return
      this.loading = true
      this.$emit('update', {
        hashtags: this.hashtagItems,
        callback: (status) => {
          this.loading = false
          if (status === 'success') {
            this.onClose();
          } else {
            alert('更新失敗，請再試一次')
          }
        }
      });
    },
    onClose () {
      this.$emit('update:show', false);
    },
    addTreatments () {
      this.hashtagItems.push({
        title: '',
        category: 999,
        dental_icon_id: 7,
        description: ''
      });
    }
  }
};
</script>

<style scoped lang="scss">
.flip-list-move {
  transition: transform 0.5s;
}
.custom-input {
  ::v-deep &.success--text {
    color: var(--v-primary-base) !important; /* 自訂成功文字顏色 */
  }
}
</style>
