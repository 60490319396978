<template>
  <v-main>
    <navigation v-if="!liffUser"/>
    <guide-tour config-key="onlineAppointment"/>
    <v-tabs v-model="tab" right>
      <v-tab>待處理預約</v-tab>
<!--      <v-tab>取消請求處理</v-tab>-->
      <v-tab>{{ $t('OnlineReserve.contact') }}</v-tab>
      <v-tab>{{ $t('OnlineReserve.effect') }}</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab" touchless>
      <v-tab-item>
        <reserve-table ref="unConcat" :status="0"/>
      </v-tab-item>
<!--      <v-tab-item>-->
<!--        <reserve-table ref="patientCancel" :status="0" :completed="4"/>-->
<!--      </v-tab-item>-->
      <v-tab-item>
        <reserve-table ref="concat" :status="1"/>
      </v-tab-item>
      <v-tab-item>
        <online-reserve-analysis/>
      </v-tab-item>
    </v-tabs-items>
  </v-main>
</template>

<script>
import ReserveTable from "../components/onlineReserve/ReserveTable";
import Navigation from "../components/Navigation";
import { mapGetters } from "vuex";
import OnlineReserveAnalysis from "../components/analytics/OnlineReserveAnalysis";
import GuideTour from "../components/utils/GuideTour.vue";

export default {
  name: "OnlineReserve",
  components: {GuideTour, OnlineReserveAnalysis, Navigation, ReserveTable},
  data() {
    return {
      tab: null
    }
  },
  computed: {
    ...mapGetters([
      'liffUser'
    ]),
  },
  watch: {
    tab(v) {
      if (v === 0 && this.$refs.unConcat) {
        this.$refs.unConcat.getDataFromApi()
      } else if (v === 1 && this.$refs.concat) {
        this.$refs.patientCancel.getDataFromApi()
      } else if (v === 2 && this.$refs.concat) {
        this.$refs.concat.getDataFromApi()
      }
    }
  },
  // methods: {
  //   change() {
  //     this.$refs.unConcat.getDataFromApi()
  //   }
  // }
}
</script>

<style scoped>

</style>
