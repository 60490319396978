<template>
  <v-container class="pb-12">
    <guide-tour v-if="showGuide" config-key="analytics.onlineAppointment"/>
    <div class="mt-4">
      <v-dialog ref="dialog" v-model="modal" persistent width="290px">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="confirmDate"
            :label="$t('OnlineReserveAnalysis.filterTitle')"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          />
        </template>
        <v-date-picker
          v-model="sortDate"
          type="month"
          :allowed-dates="allowedMonths"
          :selected-items-text="sortDate.join('~')"
          locale="zh-TW"
          scrollable
          range
        >
          <v-spacer/>
          <v-btn text color="primary" @click="modal = false">{{ $t('GENERAL.cancel') }}</v-btn>
          <v-btn text color="primary" @click="confirm">{{ $t('OnlineReserveAnalysis.confirm') }}</v-btn>
        </v-date-picker>
      </v-dialog>
    </div>
    <v-row>
      <v-col cols="12">
        <v-subheader>
          {{ $t('OnlineReserveAnalysis.webPageView') }}
          <v-tooltip bottom max-width="500">
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="grey lighten-1" v-bind="attrs" v-on="on">mdi-help-circle-outline</v-icon>
            </template>
            <span v-text="$t('OnlineReserveAnalysis.webPageViewTooltip')"/>
          </v-tooltip>
        </v-subheader>
        <ve-line :data="chartData" :legend="{show: false}" :settings="pageViewChartSettings"/>
      </v-col>
      <v-col cols="12" sm="12">
        <v-subheader>{{ $t('OnlineReserveAnalysis.onlineReserveTrend') }}</v-subheader>
        <ve-line :data="onlineRateData" :settings="chartSettings"/>
      </v-col>
      <v-col cols="12" sm="6">
        <v-subheader>{{ $t('OnlineReserveAnalysis.reserveSource') }}</v-subheader>
        <pie-chart :chart-data="sourceData" class="mx-auto" style="max-width: 400px; max-height: 400px;"/>
      </v-col>
      <v-col cols="12" sm="6">
        <v-subheader>{{ $t('OnlineReserveAnalysis.NPProportion') }}</v-subheader>
        <pie-chart :chart-data="npData" class="mx-auto" style="max-width: 400px; max-height: 400px;"/>
      </v-col>
      <v-col cols="12" sm="6">
        <v-subheader>{{ $t('OnlineReserveAnalysis.completeProportion') }}</v-subheader>
        <pie-chart :chart-data="completeData" class="mx-auto" style="max-width: 400px; max-height: 400px;"/>
      </v-col>
      <v-col cols="12" sm="6">
        <v-subheader>預約項目比例</v-subheader>
        <pie-chart :chart-data="onlineTreatmentData" class="mx-auto" style="max-width: 400px; max-height: 400px;"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import {
    getAnalysisOnlineCompleteAPI,
    getAnalysisOnlineReserveAmountAPI,
    getAnalysisOnlineReserveNPAPI,
    getAnalysisOnlineReserveSourceAPI, getAnalysisOnlineTreatmentAPI, getAnalysisWebViewAPI
} from "../../api";
import PieChart from "../PieChart";
import BarChart from "../BarChart";
import GuideTour from "../utils/GuideTour.vue";

export default {
  name: "OnlineReserveAnalysis",

  props: {
    showGuide: {
      type: Boolean,
      default: false
    }
  },

  components: {GuideTour, PieChart, BarChart},

  data() {
    const currentMonth = this.$moment().format('YYYY-MM')
    return {
      date: [currentMonth],
      confirmDate: [currentMonth],
      menu: false,
      modal: false,
      sourceData: null,
      onlineTreatmentData: null,
      npData: null,
      completeData: null,
      onlineRateData: null,
      chartSettings: {
        labelMap: {
          total: this.$t('OnlineReserveAnalysis.total'),
          website: this.$t('OnlineReserveAnalysis.website'),
          otherWebsite: this.$t('OnlineReserveAnalysis.otherWebsite')
        }
      },
      chartData: {
        columns: ['month', 'total'],
        rows: []
      },
      pageViewChartSettings: {
        labelMap: {
          total: this.$t('OnlineReserveAnalysis.pageView'),
        }
      }
    }
  },
  computed: {
    ...mapGetters([
      'axiosParamData',
    ]),
    sortDate: {
      get() {
        return [...new Set(this.date)];
      },
      set(v) {
        this.date = v.sort((a, b) => {
          return new Date(a) - new Date(b)
        })
      },
    },
  },
  watch: {
    confirmDate: {
      handler: function (n, o) {
        this.getData()
      },
      deep: true
    }
  },
  methods: {
    confirm() {
      this.confirmDate = JSON.parse(JSON.stringify(this.sortDate))
      this.modal = false
    },
    allowedMonths(val) {
      return this.$moment(val).isBetween(this.$moment().subtract(24, 'months'), this.$moment(), 'month', '(]')
    },
    getData() {
      const params = {
        ...this.axiosParamData,
        query_start: this.sortDate[0],
        query_end: this.sortDate.length > 1 ? this.sortDate[1] : this.sortDate[0]
      }
      getAnalysisOnlineReserveSourceAPI(params).then(res => {
        const data = res.data

        data.datasets[0].backgroundColor = [
          '#f45d64', '#efcf13', '#0076b5', '#e5578b', '#f0723b', '#65c15e', '#976abd', '#c55eaa', '#589165', '#88b2d0', '#66404e',
          '#3e7d90', '#b15499', '#2ca3e6', '#d9a14d', '#8e6cb3', '#4cb36e', '#e67446', '#a3875d', '#5098a5', '#cc4d78', '#8db362',
          '#eb657d', '#6cbba8', '#d46c3a', '#7a539b', '#b4a14e', '#3a8cb2', '#c96b93', '#6e9450', '#d88f66', '#5e778a', '#af6542',
          '#4d6e85', '#c87e4a', '#637c9f', '#bb7f58', '#365e7c', '#d49d7b', '#427998', '#de8261', '#84778f', '#e56b40', '#5e7d90',
          '#b89a7d', '#3e6d82'
        ];

        this.sourceData = data
      })
      getAnalysisOnlineReserveNPAPI(params).then(res => {
        const data = res.data
        data.datasets[0].backgroundColor = ['#e5578b', '#65c15e']
        this.npData = res.data
      })
      getAnalysisOnlineCompleteAPI(params).then(res => {
        const data = res.data
        data.datasets[0].backgroundColor = ['#e5578b', '#65c15e', '#0076b5', '#976abd']
        this.completeData = res.data
      })
      getAnalysisOnlineReserveAmountAPI(params).then(res => {
        this.onlineRateData = res.data
      })
      getAnalysisWebViewAPI(params).then(res => {
        this.chartData.rows = res.data
      })
      getAnalysisOnlineTreatmentAPI(params).then(res => {
          const data = res.data
          data.datasets[0].backgroundColor = [
            '#f45d64', '#efcf13', '#0076b5', '#e5578b', '#f0723b', '#65c15e', '#976abd', '#c55eaa', '#589165', '#88b2d0', '#66404e',
            '#3e7d90', '#b15499', '#2ca3e6', '#d9a14d', '#8e6cb3', '#4cb36e', '#e67446', '#a3875d', '#5098a5', '#cc4d78', '#8db362',
            '#eb657d', '#6cbba8', '#d46c3a', '#7a539b', '#b4a14e', '#3a8cb2', '#c96b93', '#6e9450', '#d88f66', '#5e778a', '#af6542',
            '#4d6e85', '#c87e4a', '#637c9f', '#bb7f58', '#365e7c', '#d49d7b', '#427998', '#de8261', '#84778f', '#e56b40', '#5e7d90',
            '#b89a7d', '#3e6d82'
          ]
          this.onlineTreatmentData = res.data
      })
    }
  },
  mounted() {
    this.getData()
  }

}
</script>
<style scoped>

</style>
