<template>
  <v-card :loading="loading" height="70vh">
    <v-card-title>
      <template v-if="!isTyping">
        設定「衛教訊息」
      </template>
      <template v-else-if="newEducation">
        新增「衛教訊息」
      </template>
      <template v-else>
        編輯「衛教訊息」
      </template>
      <v-btn color="primary" text href="http://pse.is/58qfwl" target="_blank">
        點我看介紹↗
      </v-btn>
    </v-card-title>
    <v-card-text class="overflow-y-auto">
      <v-form v-model="valid" ref="form">
        <v-select
          v-if="!isTyping"
          v-model="selectEditEducation"
          :items="healthEducations"
          label="衛教訊息"
          item-text="keyword"
          outlined
          dense
          return-object
          no-data-text="沒有已設定的衛教訊息，請點擊下方按鈕進行新增"
        >
          <template v-slot:append-item>
            <v-list-item @click="newEducation = true">
              <v-list-item-content>
                <v-list-item-title>
                  <span style="color: #4525F2;" class="text-decoration-underline">新增衛教訊息</span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-select>
        <v-text-field
          v-else
          v-model="formData.keyword"
          label="衛教訊息名稱"
          outlined
          dense
          clearable
          validate-on-blur
          :rules="rules.keyword.concat(validateKeyword)"
        />

        <template v-if="isTyping">
          <v-sheet outlined rounded height="100%">
            <v-btn-toggle
              v-model="contentType"
              mandatory
              tile
              borderless
              shaped
              color="primary"
              active-class="font-weight-bold primary--text"
              style="width: 100%;"
            >
              <v-btn class="flex-grow-1" color="grey-8" value="text">
                自定義輸入衛教內容文字
              </v-btn>

              <v-btn color="grey-8" value="json">
                <v-icon :color="contentType === 'json' ? 'primary' : 'grey-4'">mdi-code-json</v-icon>
              </v-btn>
            </v-btn-toggle>

            <div class="px-4 py-2">
              <v-row v-if="contentType === 'text'">
                <v-col cols="12" md="auto">
                  <upload-img
                    v-model="formData.img_file"
                    :o-img-url="formData.img_url"
                    :aspect-ratio="1"
                    class="mx-auto"
                    style="width: 150px;"
                  />
                </v-col>
                <v-col cols="12" md="auto" class="flex-grow-1">
                  <v-textarea
                    v-model="formData.text_content"
                    rows="5"
                    hide-details="auto"
                    no-resize
                    solo
                    flat
                    placeholder="請輸入欲傳送給患者的衛教內容文字"
                  />
                </v-col>
              </v-row>
              <v-textarea
                v-if="contentType === 'json'"
                v-model="formData.json_content"
                rows="5"
                hide-details="auto"
                no-resize
                solo
                flat
                placeholder="此為程式語言Json格式輸入處，請洽客服操作。"
                :rules="rules.json_content"
              />
            </div>
          </v-sheet>
          <div class="py-6">
            <template v-if="newEducation">
              <v-btn color="primary" block @click="createHealthEducation">確認新增</v-btn>
              <v-btn color="grey-4" dark block class="mt-4" @click="cancel">取消新增</v-btn>
            </template>
            <template v-else>
              <v-btn color="primary" block @click="updateHealthEducation">確認調整</v-btn>
              <div class="d-flex mt-4">
                <v-btn color="grey-7" dark width="45%" @click="deleteHealthEducation">刪除衛教</v-btn>
                <v-spacer/>
                <v-btn color="grey-4" dark width="45%" @click="cancel">取消調整</v-btn>
              </div>
            </template>
          </div>
        </template>
      </v-form>
      <v-snackbar
        v-model="snackbar"
        centered
        timeout="2000"
        color="tiffany-10"
      >
        {{ snackbarText }}
      </v-snackbar>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import {
createHealthEducationAPI,
deleteHealthEducationAPI,
getHealthEducationAPI,
updateHealthEducationAPI
} from '../../api';
import UploadImg from '../UploadImg';

export default {
  name: "CustomEducation",
  components: {UploadImg},
  data() {
    return {
      loading: false,
      newEducation: false,
      selectEditEducation: null,
      healthEducations: [],
      currentEditType: null,

      valid: true,
      formData: {
        keyword: null,
        text_content: null,
        json_content: null,
        img_file: null
      },
      rules: {
        keyword: [
          v => !!v || '此欄位必填',
        ],
        json_content: [
          v => !!v || '此欄位必填',
        ],
      },

      snackbar: false,
      snackbarText: null
    }
  },

  computed: {
    ...mapGetters([
      'axiosParamData',
    ]),
    isTyping() {
      return this.newEducation || this.selectEditEducation
    },
    contentType: {
      get() {
        if (this.currentEditType)
          return this.currentEditType

        if (this.formData.json_content)
          return 'json'
        else
          return 'text'
      },
      set(v) {
        this.currentEditType = v
      },
    },
    validateKeyword() {
      if (this.formData.id) {
        return this.healthEducations.filter(e => e.keyword === this.formData.keyword).length <= 1 || '您輸入的衛教名稱已重複，請輸入其他名稱'
      } else {
        return !this.healthEducations.some(e => e.keyword === this.formData.keyword) || '您輸入的衛教名稱已重複，請輸入其他名稱'
      }
    }
  },

  watch: {
    selectEditEducation: {
      handler(v) {
        if (v)
          this.formData = JSON.parse(JSON.stringify(v))
      },
      deep: true,
    },
  },

  methods: {
    cancel() {
      this.selectEditEducation = null
      this.newEducation = false
      this.formData = this.$options.data().formData
    },
    getHealthEducationList() {
      getHealthEducationAPI(this.axiosParamData).then(res => {
        this.healthEducations = res.data
      })
    },
    cleanData() {
      const formData = new FormData()

      formData.append('keyword', this.formData.keyword)

      if (this.contentType === 'json') {
        this.formData.text_content = null
        formData.append('json_content', this.formData.json_content)
      }
      if (this.contentType === 'text') {
        this.formData.json_content = null
        if (this.formData.text_content) formData.append('text_content', this.formData.text_content)
      }
      if (this.formData.img_file) {
        formData.append('img', this.formData.img_file)
      }

      for (const [key, value] of Object.entries(this.axiosParamData)) {
        formData.append(key, value)
      }
      return formData
    },
    createHealthEducation() {
      this.$refs.form.validate()
      if (!this.valid) return
      this.loading = true

      createHealthEducationAPI(this.cleanData()).then(() => {
        this.getHealthEducationList()
        this.snackbarText = '新增成功'
        this.snackbar = true
        this.cancel()
      }).finally(() => {
        this.loading = false
      })
    },
    updateHealthEducation() {
      this.$refs.form.validate()
      if (!this.valid) return
      this.loading = true

      const data = this.cleanData()

      data.append('_method', 'put')

      updateHealthEducationAPI(this.formData.id, data).then(() => {
        this.getHealthEducationList()
        this.snackbarText = '修改成功'
        this.snackbar = true
      }).finally(() => {
        this.loading = false
      })
    },
    deleteHealthEducation() {
      this.loading = true
      deleteHealthEducationAPI(this.formData.id, this.axiosParamData).then(() => {
        this.getHealthEducationList()
        this.snackbarText = '刪除成功'
        this.snackbar = true
        this.cancel()
      }).finally(() => {
        this.loading = false
      })
    }
  },
  mounted() {
    this.getHealthEducationList()
  }
}
</script>

<style scoped>

</style>