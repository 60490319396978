import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import moment from './plugins/moment';
import router from './router';
import store from './store';
import VueQr from 'vue-qr';
import * as filters from './utils/filters';
import { arrayPrototypeInsert } from "./utils/arrays";
import VueHtmlToPaper from 'vue-html-to-paper';
import VCharts from 'v-charts'
import liff from "@line/liff";
import i18n from './i18n';
import VueGtm from "@gtm-support/vue2-gtm";
import VueGapi from 'vue-gapi';
// 引入常量
import './constant'
import axios from 'axios';

Vue.config.productionTip = false

axios.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    if (error.response && error.response.data) {
      if (error.response.data.messages === '-99999') {
        if (liff.isInClient()) {
          if (!sessionStorage.getItem('alerted')) {
            alert('您沒有權限')
            sessionStorage.setItem('alerted', 'yes')
          }
          sessionStorage.setItem('liffAuth', '0')
          store.commit('clearUserData')
        }
        else {
          if (router.currentRoute.name !== 'login') {
            const nextPage = router.currentRoute.path
            router.push({name: 'login', query: {next: nextPage}})
          }
        }
        return new Promise(() => {})
      } else if (error.response.data.messages === '-99998') {
        if (!sessionStorage.getItem('alerted')) {
          alert('您沒有完整權限')
          sessionStorage.setItem('alerted', 'yes')
        }
        sessionStorage.setItem('liffAuth', '0')
        store.commit('clearUserData')
        return new Promise(() => {})
      }
    }

    return Promise.reject(error)
  }
)
if (process.env.VUE_APP_API_URL)
  axios.defaults.baseURL = process.env.VUE_APP_API_URL

Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key])
})

arrayPrototypeInsert()

Vue.use(moment);
Vue.use(VCharts);
const NEW_ID = '76227274059-c2vfb15nq0i0o7thaisam5lvb3n3u6uj.apps.googleusercontent.com'
Vue.use(VueGapi, {
  clientId: NEW_ID,
  discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'],
  scope: 'https://www.googleapis.com/auth/calendar',
})

const options = {
  name: '_blank',
  specs: ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'],
  styles: [
    'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
  ],
}

Vue.use(VueQr)
Vue.use(VueHtmlToPaper, options)
Vue.use(VueGtm, {
  id: 'GTM-W6NQCFR',
  enabled: true,
  debug: false,
})

Vue._watchers = Vue.prototype._watchers = []  // 為了修復 v-charts 的官方 bug https://blog.csdn.net/m0_56915985/article/details/129964771

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount('#app')
