<template>
  <v-main>
    <navigation v-if="!liffUser"/>
    <guide-tour config-key="care"/>
    <v-tabs v-model="tab" right>
      <v-tab>{{ $t('PostOperativeCare.unprocessed') }}</v-tab>
      <v-tab>{{ $t('PostOperativeCare.processed') }}</v-tab>
    </v-tabs>
    <v-data-table
      :headers="headers"
      :items="careItems"
      height="calc(100vh - 256px)"
      :options.sync="options"
      :loading="loading"
      :server-items-length="totalCares"
    >
      <template v-slot:item.content="{ item }">
        <span v-if="item.content && item.content.length > 8">
          {{ item.content.substr(0, 8) }}
          <a @click="showMoreContent(item.content)">...more</a>
        </span>
        <span v-else>
          {{ item.content }}
        </span>
      </template>
      <template v-slot:item.clinic_call="{ item }">
        <span v-if="parseInt(item.clinic_call)" style="color: var(--v-red-6-base);">請聯繫患者</span>
        <span v-else style="color: var(--v-grey-5-base);">不需要聯繫</span>
      </template>
      <template v-slot:item.care_note="{ item }">
        <span>
          {{ (item.care_note && item.care_note.length > 20) ? item.care_note.substr(0, 20) + '...' : item.care_note }}
        </span>
        <v-btn icon color="primary" @click="handleEdit(item)">
          <v-icon dark x-small>
            mdi-pencil
          </v-icon>
        </v-btn>
      </template>
      <template v-slot:item.action="{ item }">
        <v-btn v-if="tab === 0" outlined small color="primary" style="margin: 5px 0;" @click="changeStatus(item, 1)">
          設為已處理
        </v-btn>
        <v-btn v-else outlined small color="primary" style="margin: 5px 0;"
               @click="changeStatus(item, 0)">
          設為未處理
        </v-btn>
      </template>
    </v-data-table>
    <v-dialog v-if="reportBackNoteDialog" max-width="500" v-model="reportBackNoteDialog" persistent>
      <v-card>
        <v-card-title>編輯術後備註</v-card-title>
        <v-card-text>
          <v-textarea v-model="currentEdit.care_note" hide-details outlined no-resize/>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue" text @click="editReportBackNote">
            ok
          </v-btn>
          <v-btn text @click="reportBackNoteDialog=false">
            {{ $t('GENERAL.cancel') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-if="showMoreDialog" v-model="showMoreDialog" width="fit-content">
      <v-card width="fit-content" max-width="350" max-height="50vh" class="pa-6 text-pre-line overflow-y-auto" v-text="moreContent"/>
    </v-dialog>
  </v-main>
</template>

<script>
import Navigation from "../components/Navigation";
import { mapActions, mapGetters } from "vuex";
import { getCareAPI, updateCareAPI } from "../api";
import GuideTour from "../components/utils/GuideTour.vue";

export default {
  name: "PostOperativeCare",
  components: {GuideTour, Navigation},
  data() {
    return {
      tab: null,
      careItems: [],
      options: {},
      loading: true,
      totalCares: 0,
      currentEdit: null,
      reportBackNoteDialog: false,
      showMoreDialog: false,
      moreContent: null
    }
  },
  computed: {
    ...mapGetters([
      'axiosParamData',
      'liffUser',
      'doctorUser',
    ]),
    headers() {
      const headers = [
        {text: this.$t('PostOperativeCare.patientName'), align: 'center', value: 'name', sortable: false, width: 100},
        {text: this.$t('PostOperativeCare.patientPhone'), align: 'center', value: 'phone', sortable: false, width: 120},
        {text: this.$t('PostOperativeCare.reserveTime'), align: 'center', value: 'start_time', sortable: false, width: 120},
        {
          text: this.$t('PostOperativeCare.reserveDoctor'),
          align: 'center',
          value: 'doctor_name',
          sortable: false,
          width: 100
        },
        {text: this.$t('PostOperativeCare.reserveNote'), align: 'center', value: 'note', sortable: false, width: 150},
        {
          text: this.$t('PostOperativeCare.reportBackTime'),
          align: 'center',
          value: 'create_time',
          sortable: false,
          width: 150
        },
        {text: this.$t('PostOperativeCare.reportBack'), align: 'center', value: 'content', sortable: false, width: 120},
        {
          text: this.$t('PostOperativeCare.needContact'),
          align: 'center',
          value: 'clinic_call',
          sortable: false,
          width: 150
        },
        {
          text: this.$t('PostOperativeCare.reportBackNote'),
          align: 'center',
          value: 'care_note',
          sortable: false,
          width: 150
        },
        {
          text: this.$t('PostOperativeCare.action'),
          align: 'center',
          value: 'action',
          sortable: false,
          width: 150
        },
      ]
      if (this.doctorUser)
        headers[3] = {
          text: this.$t('PostOperativeCare.reserveClinic'),
          align: 'center',
          value: 'clinic_name',
          sortable: false,
          width: 100
        }
      return headers
    },
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi()
      },
      deep: true,
    },
    tab() {
      if (this.options.page === 1)
        this.getDataFromApi()
      else
        this.options.page = 1
    }
  },
  methods: {
    ...mapActions({
      getUnreadNotice: 'getUnreadNotice',
    }),
    getDataFromApi() {
      this.loading = true
      const {page, itemsPerPage} = this.options
      getCareAPI({...this.axiosParamData, reply_status: this.tab, limit: itemsPerPage, page}).then(res => {
        this.careItems = res.data.data
        this.totalCares = parseInt(res.data.paginate.total)
        this.loading = false
      })
    },
    handleEdit(item) {
      this.currentEdit = JSON.parse(JSON.stringify(item))
      this.reportBackNoteDialog = true
    },
    editReportBackNote() {
      updateCareAPI(this.currentEdit.id, {care_note: this.currentEdit.care_note, ...this.axiosParamData}).then(() => {
        this.currentEdit = null
        this.reportBackNoteDialog = false
        this.getDataFromApi()
      })
    },
    showMoreContent(v) {
      this.moreContent = v
      this.showMoreDialog = true
    },
    changeStatus(item, v) {
      updateCareAPI(item.id, {reply_status: v, ...this.axiosParamData}).then(() => {
        this.getDataFromApi()
        this.getUnreadNotice()
      })
    }
  }
}
</script>

<style scoped>

</style>
