<template>
  <v-main>
    <navigation v-if="!liffUser" extension>
      <template v-slot:extension>
        <v-tabs v-model="tab" grow>
          <v-tab @click="scrollTop">{{ $t('Analytics.patientRecommend') }}</v-tab>
          <v-tab @click="scrollTop">{{ $t('Analytics.onlineReserveResult') }}</v-tab>
          <v-tab @click="scrollTop">病患分析</v-tab>
        </v-tabs>
      </template>
    </navigation>
    <v-tabs v-else v-model="tab" grow>
      <v-tab @click="scrollTop">{{ $t('Analytics.patientRecommend') }}</v-tab>
      <v-tab @click="scrollTop">{{ $t('Analytics.onlineReserveResult') }}</v-tab>
      <v-tab @click="scrollTop">病患分析</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab" touchless>
      <v-tab-item>
        <line-invite-analysis/>
      </v-tab-item>
      <v-tab-item>
        <online-reserve-analysis showGuide/>
      </v-tab-item>
      <v-tab-item>
        <patient-analysis/>
      </v-tab-item>
    </v-tabs-items>
  </v-main>
</template>

<script>
import Navigation from "../components/Navigation";
import LineInviteAnalysis from "../components/analytics/lineInviteAnalysis";
import { mapGetters } from "vuex";
import OnlineReserveAnalysis from "../components/analytics/OnlineReserveAnalysis";
import PatientAnalysis from "../components/analytics/PatientAnalysis.vue";

export default {
  name: "Analytics",
  components: {PatientAnalysis, OnlineReserveAnalysis, LineInviteAnalysis, Navigation},
  data() {
    return {
      tab: null,
    }
  },
  computed: {
    ...mapGetters([
      'liffUser'
    ]),
  },
  methods: {
    scrollTop() {
      window.scrollTo({top: 0, left: 0})
    }
  }
}
</script>

<style scoped>

</style>
