<template>
  <v-dialog v-model="dialog" max-width="530">
    <v-card>
      <v-card-title>調整標籤名稱</v-card-title>
      <v-card-text>
        <v-row v-for="(tag, idx) in editFilterReserveTagsItems" :key="idx" dense class="align-center">
          <v-col cols="auto" md="2" class="flex-shrink-1">
            <v-chip small>#標籤{{ idx + 1 }}</v-chip>
          </v-col>
          <v-col md="10" class="flex-grow-1">
            <v-text-field
              v-model="tag.content"
              placeholder="請輸入10字內的標籤名稱"
              outlined dense single-line
              hide-details
              class="tag-input"
              maxlength="10"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn small dark width="100" color="primary" @click="confirmReserveTagName">確定修改</v-btn>
        <v-btn small dark width="100" color="grey-4" @click="dialog = false">取消</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { updateReserveTagAPI } from '../../api';
import { mapGetters } from 'vuex';

export default {
  name: "ReserveTagEditor",
  props: {
    editReserveTagNameDialog: {
      type: Boolean,
      required: true
    },
    items: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      editFilterReserveTagsItems: [],
    }
  },
  computed: {
    ...mapGetters([
      'axiosParamData',
    ]),
    dialog: {
      get() {
        return this.editReserveTagNameDialog
      },
      set(value) {
        this.$emit('update:editReserveTagNameDialog', value)
      },
    },
  },
  methods: {
    confirmReserveTagName() {
      const setting_data = this.editFilterReserveTagsItems.filter(e => e.content !== null)
      const data = {
        ...this.axiosParamData,
        setting_data
      }
      updateReserveTagAPI(data).then(() => {
        this.$emit('getData')
        this.dialog = false
      })
    },
  },
  mounted() {
    this.editFilterReserveTagsItems = JSON.parse(JSON.stringify(this.items))
  }
}
</script>

<style scoped>

</style>