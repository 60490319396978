<template>
  <setting-layout ref="layout" :title="title" :subtitle="subtitle" :hasPermission="hasPermission" @cancel="cancel" @save="save">
    <div v-if="!hasPermission" class="mt-7">
      <v-btn
        dark
        color="tiffany-10"
        depressed
        :block="$vuetify.breakpoint.smAndDown"
        class="px-md-12 rounded-lg"
        href="https://lin.ee/mWs8BoE"
        target="_blank"
      >
        欲顯示醫療設備，請點我升級為進階方案
      </v-btn>
    </div>
    <div class="mt-7">
      <div
        v-for="(data, category) in equipments"
        class="grey-2 pa-5 rounded-lg mt-4"
      >
        <div class="grey-7--text text-body-2 mb-4">
          [ {{ category }} ]
        </div>
        <v-row>
          <template v-for="(equipment, n) in data">
            <v-col :key="`equipment-${n}`" cols="12" sm="6" class="mb-4">
              <v-row>
                <v-col cols="12">
                  <v-checkbox
                    v-model="clinicEquipmentIds"
                    :value="equipment.id"
                    true-value="1"
                    false-value="0"
                    class="ma-0"
                    hide-details dense
                    :disabled="!canEdit"
                    @change="changeItems($event, equipment)"
                  >
                    <template v-slot:label>
                      <div v-html="equipment.title"/>
                    </template>
                  </v-checkbox>
                </v-col>
                <v-col cols="1"></v-col>
                <v-col v-show="clinicEquipmentIds.includes(equipment.id)" cols="11">
                  <v-textarea
                    :rows="$vuetify.breakpoint.mdAndUp ? 1 : 2"
                    no-resize
                    counter
                    filled dense rounded
                    full-width hide-details="auto"
                    class="setting-input"
                    maxlength="20"
                    :value="(clinicEquipmentIds.includes(equipment.id)) ? clinicEquipmentItems[equipment.id] : ''"
                    :placeholder="'例如: ' + equipment.intro"
                    :disabled="!canEdit"
                    @input="updateEquipment($event, equipment)"
                  />
                </v-col>
              </v-row>
            </v-col>
          </template>
        </v-row>
      </div>

    </div>
  </setting-layout>
</template>

<script>
import { mapGetters } from 'vuex';
import SettingLayout from './SettingLayout';
import {
  getMenuEquipmentAPI,
  getClinicEquipmentAPI,
  saveClinicEquipmentAPI,
} from '../../../api';
import { checkPermissions } from '../../../utils/tool';

export default {
  name: "OurEquipment",
  inject: ['getIsEdit', 'toggleEdit', 'saveAlert', 'toggleLoading'],
  components: {SettingLayout},
  props: {
    title: {
      required: true,
      type: String
    },
    subtitle: {
      required: true,
      type: String
    },
    plan: {
      required: true,
      type: String
    },
    userInfo: {
      required: true,
      type: Object,
    }
  },
  computed: {
    ...mapGetters(['axiosParamData']),
    canEdit() {
      return this.getIsEdit() && this.hasPermission
    },
    hasPermission() {
      return checkPermissions({obj: this.userInfo, type: 'clinic', permissions:this.pagePermission})
    }
  },
  data() {
    const defaultItem = '基本項目'
    const advanceItem = '進階項目'
    const bonusItem = '加分項目'
    const equipments = {}
    equipments[defaultItem] = []
    equipments[advanceItem] = []
    equipments[bonusItem] = []
    return {
      equipments,
      defaultItem,
      advanceItem,
      bonusItem,
      oClinicEquipmentIds: [],
      clinicEquipmentIds: [],
      oClinicEquipmentItems: {},
      clinicEquipmentItems: {},
      pagePermission: ['adv_page']
    }
  },
  mounted() {
    this.getMenuEquipment();
    this.getClinicEquipment();
  },
  methods: {
    save() {
      this.toggleLoading(true)
      const vm = this
      const filtered = Object.keys(vm.clinicEquipmentItems)
        .filter(key => vm.clinicEquipmentIds.includes(key))
        .reduce((obj, key) => {
          obj[key] = vm.clinicEquipmentItems[key]
          return obj
        }, {})
      const settingData = []
      for (const [key, value] of Object.entries(filtered)) {
        settingData.push({
          id: key,
          intro: value
        })
      }
      const data = {
        ...this.axiosParamData,
        setting_data: settingData
      }
      saveClinicEquipmentAPI(data).then(() => {
        this.toggleEdit(false)
        this.getClinicEquipment()
        this.saveAlert()
      }).catch(e => {
        console.error(e)
        this.toggleLoading(false)
      })
    },
    cancel() {
      this.resetData()
    },
    resetData() {
      this.clinicEquipmentIds = JSON.parse(JSON.stringify(this.oClinicEquipmentIds))
      this.clinicEquipmentItems = JSON.parse(JSON.stringify(this.oClinicEquipmentItems))
    },
    getClinicEquipment () {
      this.toggleLoading(true)
      const vm = this;

      vm.oClinicEquipmentIds = [];
      vm.oClinicEquipmentItems = {};

      getClinicEquipmentAPI({ ...this.axiosParamData }).then(res => {
        const ClinicEquipments = res.data;

        if (ClinicEquipments.length > 0) {
          ClinicEquipments.forEach(function(equipment){
            vm.oClinicEquipmentIds.push(equipment.id);
            vm.oClinicEquipmentItems[equipment.id] = equipment.intro;
          });
        }
        this.resetData()
      }).finally(() => {
        this.toggleLoading(false)
      })
    },
    getMenuEquipment () {
      getMenuEquipmentAPI(this.axiosParamData).then(res => {
        const groupEquipments = res.data.reduce((group, product) => {
          const { category } = product;
          group[category] = group[category] || [];
          group[category].push(product);
          return group;
        }, {});

        this.equipments[this.defaultItem] = groupEquipments[this.defaultItem];
        this.equipments[this.advanceItem] = groupEquipments[this.advanceItem];
        this.equipments[this.bonusItem] = groupEquipments[this.bonusItem];
      });
    },
    changeItems(event, equipmentItem) {
      if (event.includes(equipmentItem.id) && !this.clinicEquipmentItems[equipmentItem.id]) {
        this.clinicEquipmentItems[equipmentItem.id] = null
      }
    },
    updateEquipment(value, equipmentItem) {
      this.clinicEquipmentItems[equipmentItem.id] = value
    },
  }
}
</script>

<style lang="scss" scoped>
.col {
  padding: 6px;
}
.setting-input {
  .v-input__control .v-input__slot {
    min-height: 36px !important;
    border-radius: 10px;
    padding: 0 18px !important;
  }

  &.v-select .v-input__control .v-input__slot {
    padding: 0 8px 0 18px !important;
  }
}
</style>
