<template>
  <v-main>
    <navigation v-if="!liffUser"/>
    <div>
      <openai-conversation-data-table />
    </div>
  </v-main>
</template>

<script>
import { mapGetters } from "vuex";
import Navigation from "../components/Navigation";
import OpenaiConversationDataTable from '../components/OpenaiConversation/OpenaiConversationDataTable';

export default {
  name: "OpenaiConversation",
  components: {OpenaiConversationDataTable, Navigation},
  data() {
    return {

    }
  },
  computed: {
    ...mapGetters([
      'liffUser'
    ])
  },
  watch: {

  }
}
</script>

<style scoped>

</style>
