<template>
  <v-card flat class="mt-2 py-2 px-4 overflow-y-auto">
    <v-sheet color="grey-8" class="pa-5">
      <v-row>
        <v-col cols="12" md="6">
          <v-menu
            ref="menu"
            v-model="dateMenu"
            :close-on-content-click="false"
            :return-value.sync="selectDate"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateRangeText"
                readonly
                clearable
                hide-details
                dense
                outlined
                label="請選擇開始日期與結束日期"
                v-bind="attrs"
                v-on="on"
                @keyup.enter="handleSearch"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="selectDate"
              no-title
              scrollable
              range
              locale="zh"
              :allowed-dates="allowedDates"
            >
              <v-spacer/>
              <v-btn text color="primary" @click="dateMenu = false">
                取消
              </v-btn>
              <v-btn text color="primary" @click="$refs.menu.save(selectDate)">
                確認
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="searchPhone"
            outlined
            dense
            placeholder="電話號碼(選填)"
            clearable
            hide-details
            @keyup.enter="handleSearch"
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="searchKeyword"
            outlined
            dense
            placeholder="ex:植牙"
            clearable
            hide-details
            @keyup.enter="handleSearch"
          />
        </v-col>
        <v-col cols="12" md="6" class="text-right">
          <v-btn
            color="primary"
            :block="$vuetify.breakpoint.smAndDown"
            @click="handleSearch"
          >確認篩選
          </v-btn>
          <v-btn
            color="grey-4"
            dark
            :block="$vuetify.breakpoint.smAndDown"
            :class="{'mt-2': $vuetify.breakpoint.smAndDown}"
            @click="clearSearch"
          >清除篩選
          </v-btn>
        </v-col>
      </v-row>
    </v-sheet>

    <div class="mt-12">
      <div class="d-flex align-center">
        <div class="bullet">{{ logTitle }}</div>

        <v-btn color="primary" outlined class="ml-auto" @click="downloadLog">
          <v-icon>mdi-file-download</v-icon>
          下載記錄
        </v-btn>
      </div>
      <div>
        <v-data-table
          :headers="headers"
          :items="logs"
          :options.sync="options"
          :server-items-length="total"
          :footer-props="{ itemsPerPageOptions: [10, 20, 50] }"
          :loading="loading"
        >
          <template v-slot:item.avatar="{ item }">
            <v-avatar size="36">
              <img
                v-if="item.user_profile"
                :src="item.user_profile.image_url"
                :alt="item.user_profile.name"
                @error="item.user_profile.image_url = assetURL('/asset/crm/avatar-unknow.png')"
              >
              <img
                v-else
                :src="assetURL('/asset/crm/avatar-unknow.png')"
                alt="unknown"
              >
            </v-avatar>
          </template>
          <template v-slot:item.name="{ item }">
            {{ item.user_profile ? item.user_profile.name : '未知' }}
          </template>
          <template v-slot:item.channel_type="{ item }">
            <v-img
              v-if="item.channel_type === 'messenger'"
              :src="assetURL('/asset/crm/messenger.png')"
              width="30"
              height="30"
              class="ma-auto"
            />
            <v-img
              v-if="item.channel_type === 'line'"
              :src="assetURL('/asset/crm/line_binded.png')"
              width="30"
              height="30"
              class="ma-auto"
            />
          </template>
        </v-data-table>
      </div>
    </div>
  </v-card>
</template>

<script>
import { downloadAIQuestionAnswerHistoryAPI, listAIQuestionAnswerHistoryAPI } from '../../api';
import { assetURL } from '../../utils/filters';
import { mapGetters } from 'vuex';

export default {
  name: "AICsInteractiveHis",

  data() {
    return {
      assetURL,
      dateMenu: false,
      selectDate: [],
      searchPhone: null,
      searchKeyword: null,

      currentSearchData: {
        selectDate: []
      },
      options: {},
      total: 0,
      logs: [],
      loading: false
    }
  },

  computed: {
    ...mapGetters([
      'clinicUser',
      'axiosParamData',
    ]),
    dateRangeText: {
      get: function () {
        return this.selectDate.sort().join(' ~ ')
      },
      set: function () {
        this.selectDate = []
      }
    },
    logTitle() {
      if (this.currentSearchData.selectDate && this.currentSearchData.selectDate.length) {
        return `發送紀錄 - ${this.currentSearchData.selectDate.sort().join(' ~ ')}`
      }

      return '發送紀錄'
    },
    headers() {
      return [
        { text: '患者頭貼', align: 'center', value: 'avatar', sortable: false, width: 90 },
        { text: '患者姓名', align: 'center', value: 'name', sortable: false, width: 90 },
        { text: '互動渠道', align: 'center', value: 'channel_type', sortable: false, width: 90 },
        { text: '患者提問', align: 'center', value: 'question', sortable: false, width: 90 },
        { text: 'AI回覆', align: 'center', value: 'answer', sortable: false, width: 90 },
        { text: '發送時間', align: 'center', value: 'created_at', sortable: false, width: 90 },
      ]
    }
  },

  watch: {
    options: {
      handler() {
        this.getLog()
      },
      deep: true,
    },
  },

  methods: {
    allowedDates(val) {
      return this.$moment(val).isBetween(this.$moment().subtract(90, 'days'), this.$moment(), 'day', '(]')
    },
    downloadLog() {
      downloadAIQuestionAnswerHistoryAPI(this.searchDownloadParams()).then(res => {
        const blob = new Blob([res.data], {type: 'text/csv;charset=utf-8;'});
        const link = document.createElement("a");
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", 'AI客服互動記錄.csv');
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
    },
    clearSearch() {
      this.selectDate = []
      this.searchPhone = this.searchKeyword = null
      this.currentSearchData = this.$options.data().currentSearchData

      if (this.options.page === 1) this.getLog()
      else this.options.page = 1
    },
    handleSearch() {
      this.currentSearchData = {
        selectDate: JSON.parse(JSON.stringify(this.selectDate)),
        searchPhone: this.searchPhone,
        searchKeyword: this.searchKeyword
      }
      if (this.options.page === 1) this.getLog()
      else this.options.page = 1
    },
    getLog() {
      this.logs = []
      this.loading = true
      const { page, itemsPerPage } = this.options
      const params = {
        ...this.searchDownloadParams(),
        page,
        per_page: itemsPerPage
      }
      listAIQuestionAnswerHistoryAPI(params).then(res => {
        this.logs = res.data.data.data
        this.total = res.data.data.meta.total
      }).finally(() => {
        this.loading = false
      })
    },
    searchDownloadParams() {
      const create_from = this.currentSearchData.selectDate.length ? this.currentSearchData.selectDate[0] : null
      const create_to = this.currentSearchData.selectDate.length === 2 ? this.currentSearchData.selectDate[1] : null

      return {
        medical_type: this.clinicUser ? 'clinic' : 'doctor',
        medical_id: this.clinicUser ? this.axiosParamData.clinic_code : this.axiosParamData.doctor_code,
        phone: this.currentSearchData.searchPhone,
        keyword: this.currentSearchData.searchKeyword,
        create_from: create_from,
        create_to: create_to || create_from
      }
    },
    getData() {
      this.clearSearch()
    }
  }
}
</script>

<style scoped>
.bullet::before {
  content: "";
  background: #00A099;
  padding-inline: 5px;
  height: 10px;
  margin-inline-end: 6px;
  display: inline-block;
  border-radius: 50%;
}
</style>
