<script>
export default {
  name: "SaveAlert",

  props: {
    to: {
      required: false
    }
  },

  data() {
    return {
      alertDialog: false
    }
  },

  methods: {
    confirm() {
      this.$emit('cancel')
      this.$router.push(this.to)
      this.alertDialog = false
    }
  }
}
</script>

<template>
  <v-dialog v-model="alertDialog" max-width="fit-content">
    <v-card class="px-4 px-md-12 py-5 py-md-8 rounded-lg">
      <div class="text-center">
        <div class="text-h5 font-weight-medium">
          尚未儲存，確定要離開嗎？
        </div>
        <div class="text-subtitle-1 red-6--text font-weight-medium">
          請確認內容是否編輯完成，並請按下「確認修改」
        </div>
      </div>
      <div class="d-flex flex-column justify-center mt-8">
        <v-btn
          color="primary"
          block
          @click="confirm"
        >
          確定不儲存
        </v-btn>
        <v-btn
          color="grey-4"
          dark
          class="mt-4"
          block
          @click="alertDialog = false"
        >
          繼續編輯
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<style scoped>

</style>
