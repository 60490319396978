<template>
  <v-card color="white">
    <v-card-title class="justify-center" style="background-color: #5E676E;">
      <div class="white--text">
        <div class="text-center">線上預約設定</div>
        <div
          class="text-center text-body-2 text-decoration-underline"
          style="cursor: pointer;"
          @click="guideDialog = true"
        >（點我觀看介紹）
        </div>
        <v-dialog v-model="guideDialog" max-width="700">
          <v-card class="pb-12">
            <v-btn
              fab
              icon
              absolute
              width="48"
              height="48"
              style="top: 0; right: 0; overflow: hidden;"
              @click="guideDialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-card-title class="justify-center pt-12 pb-4 font-weight-bold text-h5">線上預約設定說明</v-card-title>
            <v-card-text
              class="px-md-12"
              style="color: var(--v-grey-7-base); font-size: 16px; line-height: 32px;"
            >
              <ul>
                <li>系統自動篩選:請確認貴診所是否有合作串接，在健保系統若有約診，即會開放該時段給患者預約。</li>
                <li>手動空檔時段:可自行設定開放的時間。</li>
                <li>登記預約:患者可看見您的資訊並登記預約。</li>
                <li>不開放預約:選擇此預約模式，前台會顯示診所電話，請患者致電。</li>
                <li>私訊預約:患者可直接加入LINE進行預約。</li>
              </ul>
            </v-card-text>
            <v-card-actions class="justify-center">
              <v-btn dark color="grey-4" max-width="300" width="100%" @click="guideDialog = false">我知道了</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </v-card-title>

    <v-card-text class="py-8 overflow-y-auto d-flex flex-column" :style="cardTextStyle">
      <div>
        <div class="bullet text-subtitle-1 black--text">LINE預約</div>
        <v-select
          v-model="lineMode"
          :items="lineChoice"
          hide-details
          dense
          outlined
          placeholder="請選擇您想要的預約模式"
          @change="onSelect($event, 'line')"
        >
          <template v-slot:item="{ item, on: selectOn, attrs: selectAttrs }">
            <v-list-item v-if="item.disabled">
              <v-tooltip top content-class="tooltip--dialogue">
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on" style="width: 100%;">
                    <v-list-item disabled class="pa-0">
                      {{ item.text }}
                    </v-list-item>
                  </div>
                </template>
                <span>聯繫客服升級方案</span>
              </v-tooltip>
            </v-list-item>

            <v-list-item v-else v-bind="selectAttrs" v-on="selectOn">
              {{ item.text }}
            </v-list-item>
          </template>
        </v-select>
      </div>
      <div>
        <div class="bullet text-subtitle-1 black--text">跨網頁預約</div>
        <v-select
          v-model="webMode"
          :items="webChoice"
          hide-details
          dense
          outlined
          placeholder="請選擇您想要的預約模式"
          @change="onSelect($event, 'web')"
        >
          <template v-slot:item="{ item, on: selectOn, attrs: selectAttrs }">
            <v-list-item v-if="item.disabled">
              <v-tooltip top content-class="tooltip--dialogue">
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on" style="width: 100%;">
                    <v-list-item disabled class="pa-0">
                      {{ item.text }}
                    </v-list-item>
                  </div>
                </template>
                <span>聯繫客服升級方案</span>
              </v-tooltip>
            </v-list-item>

            <v-list-item v-else v-bind="selectAttrs" v-on="selectOn">
              {{ item.text }}
            </v-list-item>
          </template>
        </v-select>
      </div>
      <div>
        <div class="bullet text-subtitle-1 black--text">Google地圖預約</div>
        <v-select
          v-model="googleMode"
          :items="googleChoice"
          hide-details
          dense
          outlined
          placeholder="請選擇您想要的預約模式"
          @change="onSelect($event, 'google')"
        >
          <template v-slot:item="{ item, on: selectOn, attrs: selectAttrs }">
            <v-list-item v-if="item.disabled">
              <v-tooltip top content-class="tooltip--dialogue">
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on" style="width: 100%;">
                    <v-list-item disabled class="pa-0">
                      {{ item.text }}
                    </v-list-item>
                  </div>
                </template>
                <span>聯繫客服升級方案</span>
              </v-tooltip>
            </v-list-item>

            <v-list-item v-else v-bind="selectAttrs" v-on="selectOn">
              {{ item.text }}
            </v-list-item>
          </template>
        </v-select>
      </div>
    </v-card-text>

    <v-dialog v-model="dialog" max-width="450" persistent>
      <v-card class="px-2 px-md-6 py-8">
        <v-card-title class="justify-center" style="font-size: 24px;">
          {{ dialogTitle }}
        </v-card-title>
        <v-card-text class="text-center mt-2" style="color: #272727; font-size: 18px;" v-html="dialogContent"/>
        <v-card-actions class="flex-wrap justify-center">
          <v-sheet max-width="300" width="100%">
            <v-btn block dark color="red-6" @click="save">{{ dialogOkBtn }}</v-btn>
          </v-sheet>
          <v-sheet max-width="300" width="100%" class="mt-4">
            <v-btn block dark color="grey-5" @click="cancel">否，我再想想</v-btn>
          </v-sheet>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="directMessageChannelDialog" max-width="450" persistent>
      <v-card class="px-2 px-md-6 py-8">
        <v-card-title class="justify-center" style="font-size: 24px;">{{ dialogTitle }}</v-card-title>
        <v-card-text class="mt-2">
          <div class="text-center" style="color: #272727; font-size: 18px;">
            {{ dialogContent }}
          </div>

          <v-sheet max-width="300" width="100%" class="mx-auto mt-6">
            <v-btn block outlined color="primary" @click="handleGuideLineSetting($event, 1)">
              是，請患者加入「診所」LINE
            </v-btn>
            <v-btn class="mt-4" block outlined color="primary" @click="handleGuideLineSetting($event, 2)">
              是，請患者加入「醫師」LINE
            </v-btn>
            <v-btn class="mt-4" block :outlined="!showCustomField" color="primary" @click="showCustomField = true">
              自行輸入預約連結
            </v-btn>
            <div v-if="showCustomField" class="d-flex mt-4 align-center">
              <v-text-field
                ref="textReserveRef"
                :value="detailSetting.text_reserve_page"
                placeholder="輸入連結(後續可調整)"
                outlined
                hide-details
                dense
              />
              <v-btn
                dark
                color="red-6"
                class="ml-2"
                @click="save($event, 3, {textReservePage: $refs.textReserveRef.internalValue})"
              >送出
              </v-btn>
            </div>
            <v-btn class="mt-4" block dark color="grey-5" @click="cancel">否，我再想想</v-btn>
          </v-sheet>

          <div v-if="!!disabledAlert" class="red--text text-center mt-2" v-html="disabledAlert"/>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { updateFreeTimeModeAPI } from "../../api";
import { checker } from "../../utils/tool";

export default {
  name: "ModeChoice",

  props: {
    clientPermissions: {
      type: Array,
      required: true
    },
    settingCode: {
      type: String,
      required: true
    },
    cardTextStyle: {
      type: String,
      required: false
    }
  },

  data() {
    return {
      guideDialog: false,
      lineMode: null,
      webMode: null,
      googleMode: null,
      dialog: false,
      directMessageChannelDialog: false,
      disabledAlert: null,
      showCustomField: false,
      dialogTitle: null,
      dialogContent: null,
      dialogOkBtn: null,
      changeChannel: null,
      checker,
    }
  },

  computed: {
    ...mapGetters([
      'clinicUser',
      'axiosParamData',
      'modeSetting',
      'detailSetting'
    ]),
    lineChoice() {
      const hasCommonPermission = this.checker(['line_appointment'], this.clientPermissions)
      return [
        {value: 1, text: '系統自動篩選', disabled: !hasCommonPermission},  // aka 聯動健保係統
        {value: 2, text: '手動空檔時段', disabled: !hasCommonPermission},  // aka 手動設定的空檔
        {value: 3, text: '登記預約', disabled: !hasCommonPermission},  // aka 可以給你預約，但你不能選時間，時間由診所安排
        {value: 4, text: '私訊預約', disabled: !this.checker(['guide_to_line'], this.clientPermissions)},  // aka 加Line私訊、各種私訊(這樣還需要買小幫手嗎？)
        {value: 5, text: '不開放預約', disabled: false},  // aka 嫌客人太多啦操你妹
      ]
    },
    webChoice() {
      const hasCommonPermission = this.checker(['web_appointment'], this.clientPermissions)
      return [
        {value: 1, text: '系統自動篩選', disabled: !hasCommonPermission},  // aka 聯動健保係統
        {value: 2, text: '手動空檔時段', disabled: !hasCommonPermission},  // aka 你他媽的智障useManualReserve
        {value: 3, text: '登記預約', disabled: !hasCommonPermission},  // aka 可以給你預約，但你不能選時間，時間由診所安排
        {value: 4, text: '私訊預約', disabled: !this.checker(['guide_to_line'], this.clientPermissions)},  // aka 加Line私訊、各種私訊(這樣還需要買小幫手嗎？)
        {value: 5, text: '不開放預約', disabled: false},  // aka 嫌客人太多啦操你妹
      ]
    },
    googleChoice() {
      const hasCommonPermission = this.checker(['google_map_appointment'], this.clientPermissions)
      return [
        {value: 1, text: '系統自動篩選', disabled: !hasCommonPermission},  // aka 聯動健保係統
        {value: 2, text: '手動空檔時段', disabled: !hasCommonPermission},  // aka 你他媽的智障useManualReserve
        {value: 5, text: '不開放預約', disabled: false},  // aka 嫌客人太多啦操你妹
      ]
    },
    dialogMapping() {
      return {
        1: {
          title: '您將開啟「系統自動篩選」',
          content: '請確認診所預約系統與牙醫小幫手有進行串接，若沒有將無法顯示任何可預約時段。',
          btn: '是，我已確認診所有合作串接'
        },
        2: {
          title: '您將開啟「手動空檔時段」',
          content: `您即將開啟手動空檔時段設定，請確實開啟時段，並注意線上預約通知，如需增加助理接收通知，請洽 <a href="${this.$enum.DENTCO_CS_LINE}" target="_blank">醫師小幫手客服</a> 。`,
          btn: '是，我要開啟手動空檔時段模式'
        },
        3: {
          title: '您將開啟「登記預約」',
          content: '您即將開啟登記預約設定，根據數據顯示，有時間供病人選擇時預約數量可增加3倍，請確認是否僅供登記。',
          btn: '是，我要開啟登記預約模式'
        },
        4: {
          title: '您將開啟「私訊預約」',
          content: '系統將引導患者加入LINE詢問預約細節，請確認有助理處理回覆，若您尚未串接，請至診所/醫師資訊輸入LINE官方帳號連結。'
        },
        5: {
          title: '您將開啟「不開放預約」',
          content: '您即將關閉任何預約模式，請再次確認是否要開啟「不開放預約」設定。',
          btn: '是，我要選擇不開放預約'
        },
      }
    }
  },

  watch: {
    modeSetting: {
      handler(v) {
        this.initData(v)
      },
      immediate: true,
      deep: true
    },
    settingCode: {
      handler(v) {
        const params = {
          ...this.axiosParamData
        }
        if (this.clinicUser)
          params.doctor_code = v
        else
          params.clinic_code = v
        this.getFreeTimeMode(params)
      },
      immediate: true,
    }
  },

  methods: {
    ...mapActions({
      getFreeTimeMode: 'getFreeTimeMode',
      getFreeTimeDetail: 'getFreeTimeDetail',
    }),
    initData(v) {
      this.lineMode = null
      this.webMode = null
      this.googleMode = null

      switch (Number(v.line_appointment_status)) {
        case -100.1:  // 私訊診所line預約
        case -100.2:  // 私訊醫師line預約
        case -100.9:  // 私訊自定連結預約
          this.lineMode = 4
          break
        case 0:  // 不開放預約
          this.lineMode = 5
          break
        case 1:  // 登記預約
          this.lineMode = 3
          break
        case 2:  // 系統自動篩選 aka 聯動健保係統
          this.lineMode = 1
          break
        case 3:  // 手動空檔
          this.lineMode = 2
          break
      }

      switch (Number(v.web_appointment_status)) {
        case -100.1:  // 私訊診所line預約
        case -100.2:  // 私訊醫師line預約
        case -100.9:  // 私訊自定連結預約
          this.webMode = 4
          break
        case 0:  // 不開放預約
          this.webMode = 5
          break
        case 1:  // 登記預約
          this.webMode = 3
          break
        case 2:  // 系統自動篩選 aka 聯動健保係統
          this.webMode = 1
          break
        case 3:  // 手動空檔
          this.webMode = 2
          break
      }

      switch (Number(v.google_map_appointment_status)) {
        case 0:  // 不開放預約
          this.googleMode = 5
          break
        case 2:  // 系統自動篩選 aka 聯動健保係統
          this.googleMode = 1
          break
        case 3:  // 手動空檔
          this.googleMode = 2
          break
      }
    },
    decodeChoice(mode, directMessageChannel = null) {
      switch (mode) {
        case 1:  // 前端聯動健保代號
          return 2  // 實際DB聯動健保代號
        case 2:  // 前端手動代號
          return 3  // 實際DB手動代號
        case 3:  // 前端登記預約代號
          return 1  // 實際DB登記預約代號
        case 4:  // 前端私訊預約代號
          switch (directMessageChannel) {
            case 1:
              return -100.1  // 實際DB診所私訊代號
            case 2:
              return -100.2  // 實際DB醫師私訊代號
            case 3:
              return -100.9  // 實際DB自定私訊代號
          }
          break
        case 5:  // 前端不開放代號
          return 0  // 實際DB不開放代號
      }
    },
    onSelect(event, channel) {
      this.changeChannel = channel
      switch (event) {
        case 1:  // 系統自動篩選 aka 聯動健保係統
        case 2:  // 手動空檔時段
        case 3:  // 登記預約
        case 5:  // 不開放
          this.dialogTitle = this.dialogMapping[event].title
          this.dialogContent = this.dialogMapping[event].content
          this.dialogOkBtn = this.dialogMapping[event].btn
          this.dialog = true
          break
        case 4:  // 私訊預約
          this.dialogTitle = this.dialogMapping[event].title
          this.dialogContent = this.dialogMapping[event].content
          this.showCustomField = false
          this.disabledAlert = false
          this.directMessageChannelDialog = true
          break
      }
    },
    cancel() {
      this.$nextTick(() => {
        this.initData(this.modeSetting)
      })

      this.dialog = false
      this.directMessageChannelDialog = false
    },
    save(event, directMessageChannel = null, otherConfig = {}) {
      const data = {
        ...this.axiosParamData
      }
      if (this.clinicUser)
        data.doctor_code = this.settingCode
      else
        data.clinic_code = this.settingCode

      switch (this.changeChannel) {
        case 'line':
          data.line_appointment_status = this.decodeChoice(this.lineMode, directMessageChannel)
          if (directMessageChannel && this.webMode === this.lineMode) {
            data.web_appointment_status = data.line_appointment_status
          }
          if (directMessageChannel === 3) {
            data.text_reserve_page = otherConfig.textReservePage
          }
          break
        case 'web':
          data.web_appointment_status = this.decodeChoice(this.webMode, directMessageChannel)
          if (directMessageChannel && this.lineMode === this.webMode) {
            data.line_appointment_status = data.web_appointment_status
          }

          if (directMessageChannel === 3) {
            data.text_reserve_page = otherConfig.textReservePage
          }
          break
        case 'google':
          data.google_map_appointment_status = this.decodeChoice(this.googleMode)
          break
      }
      updateFreeTimeModeAPI(data).then(res => {
        const params = {
          ...this.axiosParamData
        }
        if (this.clinicUser)
          params.doctor_code = this.settingCode
        else
          params.clinic_code = this.settingCode
        this.getFreeTimeMode(params)
        if (directMessageChannel === 3) this.getFreeTimeDetail(params)
        this.dialog = false
        this.directMessageChannelDialog = false
      })
    },
    handleGuideLineSetting(event, directMessageChannel) {
      const directMessageChannelMapping = {
        1: 'clinic_channel',
        2: 'doctor_channel'
      }
      const checkChannelKey = directMessageChannelMapping[directMessageChannel]
      if (this.detailSetting[checkChannelKey]) {
        this.save(event, directMessageChannel)
      } else {
        return this.disabledAlert = `您尚未串接LINE官方帳號，請洽 <a :href="$enum.DENTCO_CS_LINE" target="_blank" class="link_blue--text">客服人員</a>`
      }
    }
  }
}
</script>

<style>
.tooltip--dialogue::before {
  content: '';
  position: absolute;
  top: 100%; /* 調整此值以改變三角形的位置 */
  left: 50%; /* 中心對齊三角形 */
  margin-left: -5px; /* 根據三角形的大小調整 */
  border-width: 5px;
  border-style: solid;
  border-color: rgba(97, 97, 97, .9) transparent transparent transparent; /* 調整箭頭顏色以匹配 tooltip 的背景色 */
}

</style>
