<template>
  <v-row class="fill-height">
    <v-col cols="12">
      <v-sheet :height="$vuetify.breakpoint.xsOnly ? 94 : 64">
        <v-toolbar flat>
          <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">
            今日
          </v-btn>
          <v-btn fab text small color="grey darken-2" @click="prev">
            <v-icon small>
              mdi-chevron-left
            </v-icon>
          </v-btn>
          <v-btn fab text small color="grey darken-2" @click="next">
            <v-icon small>
              mdi-chevron-right
            </v-icon>
          </v-btn>
          <v-toolbar-title v-if="$refs.calendar">
            {{ $refs.calendar.title }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-menu v-if="$vuetify.breakpoint.smAndUp" bottom right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>
                  mdi-cog-outline
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="preparePrint">
                <v-list-item-title>{{ $t('ReserveCalendar.print') }}</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="doctorUser && !liffUser" @click="syncGoogleDialog = true">
                <v-list-item-title>{{ $t('ReserveCalendar.googleCalendar') }}</v-list-item-title>
              </v-list-item>
              <v-divider/>
              <v-list-item @click="showRe = !showRe">
                <v-list-item-title>
                  <v-icon v-if="showRe" small>mdi-check</v-icon>
                  {{ $t('ReserveCalendar.showReschedule') }}
                  <img src="https://cdn.dentco.tw/asset/crm/alert.png" width="12" height="12" alt="">
                </v-list-item-title>
              </v-list-item>
              <v-divider/>
              <v-list-item>
                <a
                  :href="`${$enum.DENTCO_NOTION_HOST}/2-1-2-2-CRM-6856311a57974ea99a4f5af03fa11d85`"
                  target="_blank"
                  class="link_blue--text"
                >操作說明</a>
              </v-list-item>
              <v-list-item>
                <a
                  :href="$enum.DENTCO_CS_LINE"
                  target="_blank"
                  class="link_blue--text"
                >聯繫客服</a>
              </v-list-item>
            </v-list>
          </v-menu>
          <div v-if="$vuetify.breakpoint.smAndUp" style="width: 200px;" class="mr-1">
            <v-select
              v-model="filterReserveTags"
              :items="filterReserveTagItems"
              item-text="content"
              item-value="tag_id"
              label="約診標籤篩選"
              outlined hide-details
              single-line dense multiple
              small-chips
              class="tag-select"
              @change="handleTagBlur"
            >
              <template v-slot:selection="{ item, index }">
                <span v-if="index === 0">{{ item.content }}</span>
                <span
                  v-if="index === 1"
                  class="grey--text text-caption"
                >
                (+{{ filterReserveTags.length - 1 }}...)
                </span>
              </template>
              <template v-slot:no-data>
                <v-list-item disabled>
                  <v-list-item-content>
                    <v-list-item-title>
                      尚未設定約診標籤
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <template v-slot:append-item>
                <v-list-item
                  @mousedown.prevent
                  @click="editReserveTagName"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      新增/調整標籤名稱
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-select>
          </div>
          <v-menu bottom right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                <span>{{ typeToLabel[type] }}</span>
                <v-icon right>
                  mdi-menu-down
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="type = 'day'">
                <v-list-item-title>日</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'week'">
                <v-list-item-title>週</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'month'">
                <v-list-item-title>月</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-btn-toggle
            v-if="$vuetify.breakpoint.smAndUp"
            v-model="calendarMode"
            color="primary"
            dense
            group
            mandatory
            class="px-1"
          >
            <v-btn value="calendar" icon small>
              <v-icon>mdi-calendar-month-outline</v-icon>
            </v-btn>
            <v-btn value="list" icon small>
              <v-icon>mdi-format-list-bulleted-square</v-icon>
            </v-btn>
          </v-btn-toggle>
          <v-btn
            v-if="$vuetify.breakpoint.smAndUp && calendarMode === 'list'"
            color="primary"
            class="mr-1"
            @click="handleCreateEvent"
          >
            新增一筆約診
          </v-btn>
          <div class="ml-4" @mouseover="gtm('reserve_calendar_reserve_status_description')">
            <v-tooltip bottom max-width="500">
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="grey lighten-1" v-bind="attrs" v-on="on">mdi-help-circle-outline</v-icon>
              </template>
              <span>
                患者回覆約診狀態：
                <img :src="assetURL('/asset/crm/question.png')" width="12" height="12" alt="">尚未確認
                <img :src="assetURL('/asset/crm/check.png')" width="12" height="12" alt="">已確認前往
                <img :src="assetURL('/asset/crm/deny.png')" width="12" height="12" alt="">爽約
                <img :src="assetURL('/asset/crm/alert.png')" width="12" height="12" alt="">改約
                <br>
                患者綁定狀態（需展開患者約診資訊）：
                <img :src="assetURL('/asset/crm/bind_info_tooltip.png')" alt="" style="max-width: 100%;">
              </span>
            </v-tooltip>
          </div>
        </v-toolbar>
        <div v-if="$vuetify.breakpoint.xsOnly" class="d-flex">
          <div style="width: 200px;" class="mr-1">
            <v-select
              v-model="filterReserveTags"
              :items="filterReserveTagItems"
              item-text="content"
              item-value="tag_id"
              label="約診標籤篩選"
              outlined hide-details
              single-line dense multiple
              small-chips
              class="tag-select"
              @change="handleTagBlur"
            >
              <template v-slot:selection="{ item, index }">
                <span v-if="index === 0">{{ item.content }}</span>
                <span
                  v-if="index === 1"
                  class="grey--text text-caption"
                >
                (+{{ filterReserveTags.length - 1 }}...)
                </span>
              </template>
              <template v-slot:no-data>
                <v-list-item disabled>
                  <v-list-item-content>
                    <v-list-item-title>
                      尚未設定約診標籤
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <template v-slot:append-item>
                <v-list-item
                  @mousedown.prevent
                  @click="editReserveTagName"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      新增/調整標籤名稱
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-select>
          </div>
          <v-btn-toggle
            v-model="calendarMode"
            color="primary"
            dense
            group
            mandatory
            class="px-1"
          >
            <v-btn value="calendar" icon small>
              <v-icon>mdi-calendar-month-outline</v-icon>
            </v-btn>
            <v-btn value="list" icon small>
              <v-icon>mdi-format-list-bulleted-square</v-icon>
            </v-btn>
          </v-btn-toggle>
          <v-btn
            v-if="calendarMode === 'list'"
            color="primary"
            @click="handleCreateEvent"
          >
            新增一筆約診
          </v-btn>
          <v-menu bottom right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>
                  mdi-cog-outline
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="preparePrint">
                <v-list-item-title>{{ $t('ReserveCalendar.print') }}</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="doctorUser && !liffUser" @click="syncGoogleDialog = true">
                <v-list-item-title>{{ $t('ReserveCalendar.googleCalendar') }}</v-list-item-title>
              </v-list-item>
              <v-divider/>
              <v-list-item @click="showRe = !showRe">
                <v-list-item-title>
                  <v-icon v-if="showRe" small>mdi-check</v-icon>
                  {{ $t('ReserveCalendar.showReschedule') }}
                  <img src="https://cdn.dentco.tw/asset/crm/alert.png" width="12" height="12" alt="">
                </v-list-item-title>
              </v-list-item>
              <v-divider/>
              <v-list-item>
                <a
                  :href="`${$enum.DENTCO_NOTION_HOST}/2-1-2-2-CRM-6856311a57974ea99a4f5af03fa11d85`"
                  target="_blank"
                  class="link_blue--text"
                >操作說明</a>
              </v-list-item>
              <v-list-item>
                <a
                  :href="$enum.DENTCO_CS_LINE"
                  target="_blank"
                  class="link_blue--text"
                >聯繫客服</a>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </v-sheet>
      <v-sheet class="calendar-sheet" :class="{'calendar-list': calendarMode === 'list'}">
        <v-progress-linear
          :active="loading"
          indeterminate
        ></v-progress-linear>
        <v-calendar
          ref="calendar"
          v-model="focus"
          :locale="$i18n.locale"
          color="primary"
          :events="events"
          :event-color="getEventColor"
          :type="type"
          first-time="07:00"
          :interval-count="calendarMode === 'list' ? 0 : 30"
          interval-minutes="30"
          :event-height="22"
          interval-height="66"
          @click:event="showEvent"
          @click:more="viewDay"
          @click:date="viewDay"
          @change="getReserves"
          @mousedown:event="startDrag"
          @click:time="startTime"
          @mousedown:time="startKeep"
          @mousemove:time="mouseMove"
          @mouseup:time="endDrag"
          @mouseleave.native="cancelDrag"
        >
          <template v-slot:event="{ event, timed }">
            <div class="v-event-draggable overflow-hidden">
              <v-tooltip bottom max-width="500">
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on" v-html="showEventHtml(event)"></div>
                </template>
                <div v-html="showEventInfo(event)"></div>
              </v-tooltip>
            </div>
            <div v-if="timed" class="v-event-drag-bottom" @mousedown.stop="extendBottom(event)"/>
          </template>
          <template v-slot:interval>
            <v-divider style="position: relative; top: 50%; border-top: 1px dashed #e0e0e0;"/>
          </template>
        </v-calendar>
        <calendar-list
          v-if="calendarMode === 'list'"
          :events="events"
          @showEvent="showEvent"
        />
        <v-dialog
          v-if="selectedOpen"
          v-model="selectedOpen"
          :max-width="reserveDialogWidth"
          persistent
          scrollable
          :fullscreen="$vuetify.breakpoint.xsOnly"
          :activator="selectedElement"
        >
          <reserve-detail-card
            :reserveId="selectedEvent.reserve_no"
            :selectedOpen.sync="selectedOpen"
            :reserveDialogWidth.sync="reserveDialogWidth"
            @refresh="reload"
            @showAlert="showCreateReserveAlert"
          />
        </v-dialog>
        <v-dialog
          v-if="reserveFormDialog"
          v-model="reserveFormDialog"
          max-width="600"
          scrollable
          :persistent="isPersistent"
        >
          <reserve-form
            :reserve="createEvent"
            :reserveFormDialog.sync="reserveFormDialog"
            mode="create"
            :is-persistent.sync="isPersistent"
            @refresh="reload"
            @showAlert="showCreateReserveAlert"
          />
        </v-dialog>
        <v-dialog v-if="confirmDialog" v-model="confirmDialog" persistent max-width="362">
          <v-card>
            <v-card-title>{{ $t('ReserveCalendar.modifyReserveTime') }}</v-card-title>
            <v-card-text>
              <div>{{ $t('ReserveCalendar.ORIGReserveTime') }}<br>
                <span class="text-subtitle-1">{{ originalTime }}</span>
              </div>
              <div class="red--text text--lighten-1">{{ $t('ReserveCalendar.newReserveTime') }}<br>
                <span class="text-subtitle-1">{{ newTime }}</span>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-spacer/>
              <v-btn color="primary darken-1" text @click="confirm">{{ $t('ReserveCalendar.confirm') }}</v-btn>
              <v-btn text @click="cancel">{{ $t('GENERAL.cancel') }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-if="askDialog" v-model="askDialog" max-width="600">
          <v-card>
            <v-card-title>{{ $t('ReserveCalendar.sendPatientRemind') }}</v-card-title>
            <v-card-text>
              {{ askText }}
            </v-card-text>
            <v-card-actions>
              <v-spacer/>
              <v-btn color="primary darken-1" text @click="sendLine">{{ $t('ReserveCalendar.sendRemind') }}</v-btn>
              <v-btn text @click="askDialog = false; reset()">{{ $t('ReserveCalendar.cancelSendRemind') }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-sheet>
      <v-dialog v-if="printDialog" v-model="printDialog" max-width="400">
        <v-card>
          <v-card-title>
            {{ clinicUser ? $t('ReserveCalendar.clinicChoosePrint') : $t('ReserveCalendar.doctorChoosePrint') }}
          </v-card-title>
          <v-card-text>
            <v-select
              v-model="printObject"
              :items="printSelectItems"
              :item-text="clinicUser ? 'doctor_name' : 'clinic_name'"
              :label="clinicUser ? $t('ReserveCalendar.chooseDoctor') : $t('ReserveCalendar.chooseClinic')"
              return-object
              hide-no-data
            ></v-select>
            <v-menu
              v-model="dateMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="printDate"
                  :label="$t('ReserveCalendar.date')"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="printDate"
                @input="dateMenu = false"
                locale="zh-TW"
              ></v-date-picker>
            </v-menu>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn color="primary darken-1" text @click="handlePrint">{{ $t('ReserveCalendar.print') }}</v-btn>
            <v-btn text @click="cancelPrint">{{ $t('GENERAL.cancel') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-if="print" v-model="print">
        <v-card>
          <print-reserve :print-data="printData" :print-time="printDate" :print-obj="printObject"
                         @close-print="cancelPrint"/>
        </v-card>
      </v-dialog>
      <v-dialog v-if="syncGoogleDialog" v-model="syncGoogleDialog" max-width="425">
        <sync-google-calendar @close="syncGoogleDialog = false"/>
      </v-dialog>
      <reserve-tag-editor
        v-if="editFilterReserveTagsItems.length"
        :editReserveTagNameDialog.sync="editReserveTagNameDialog"
        :items="editFilterReserveTagsItems"
        @getData="getReserveTags"
      />
    </v-col>
    <v-snackbar v-model="successAlert">
      建立成功
      <template v-slot:action="{ attrs }">
        <v-btn
          v-if="$refs.calendar && $refs.calendar.lastStart && (createDate < $refs.calendar.lastStart.date || createDate > $refs.calendar.lastEnd.date)"
          color="red"
          text
          v-bind="attrs"
          @click="showCreateReserve"
        >
          前往查看
        </v-btn>
      </template>
    </v-snackbar>
  </v-row>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import {
RemindChangeAPI,
createReserveAPI,
getClinicListAPI,
getDoctorListAPI,
getReserveTagAPI,
getReservesAPI,
updateReserveAPI
} from '../../api';
import { assetURL, formatDate, formatTimestampToTime } from "../../utils/filters";
import { getBlockListIcon, getReserveIcon, handleFixLengthObjArray } from '../../utils/tool';
import CalendarList from './CalendarList';
import PrintReserve from "./PrintReserve";
import ReserveDetailCard from "./ReserveDetailCard";
import ReserveForm from "./ReserveForm";
import ReserveTagEditor from './ReserveTagEditor';
import SyncGoogleCalendar from "./SyncGoogleCalendar";
import axios from 'axios';

export default {
  name: "ReserveCalendar",

  props: ['tabItems', 'currentTab'],
  components: {
    ReserveTagEditor,
    CalendarList,
    SyncGoogleCalendar,
    PrintReserve,
    ReserveForm,
    ReserveDetailCard
  },
  data: () => ({
    assetURL,
    focus: '',
    type: 'week',
    typeToLabel: {
      month: '月',
      week: '週',
      day: '日',
    },
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    events: [],
    names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],
    dragEvent: null,
    dragStart: null,
    createEvent: null,
    createStart: null,
    extendOriginal: null,
    reserveFormDialog: false,
    isPersistent: false,
    confirmDialog: false,
    askDialog: false,
    askText: null,
    reserveDialogWidth: 600,
    printDialog: false,
    printSelectItems: [],
    printObject: null,
    printDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    dateMenu: false,
    print: false,
    printData: [],
    showRe: true,
    keepTime: null,
    syncGoogleDialog: false,
    filterReserveTags: [],
    editFilterReserveTagsItems: [],
    filterReserveTagItems: [],
    editReserveTagNameDialog: false,
    calendarMode: 'calendar',
    loading: false,
    getDataTimer: null,
    successAlert: false,
    createDate: null,
    cancelTokenSource: null,
  }),
  computed: {
    ...mapState({
      specialPatient: state => state.specialPatient
    }),
    ...mapGetters([
      'axiosParamData',
      'clinicUser',
      'doctorUser',
      'liffUser'
    ]),
    originalTime() {
      let name = 'dragEvent'
      if (this.createEvent) name = 'createEvent'
      if (!this[name]) return
      return `${formatDate(parseInt(this[name].start_time) * 1000)} ~ ${formatDate(parseInt(this[name].end_time) * 1000)}`
    },
    newTime() {
      let name = 'dragEvent'
      if (this.createEvent) name = 'createEvent'
      if (!this[name]) return
      return `${formatDate(this[name].start)} ~ ${formatDate(this[name].end)}`
    }
  },
  watch: {
    currentTab() {
      this.reload()
    },
    showRe() {
      this.reload()
    },
    '$store.state.selectRecord': {
      handler(v) {
        if (v) {
          this.focus = v.start_time
          this.showEvent({nativeEvent: null, event: v})
          this.setSelectRecord(null)
        }
      },
      deep: true,
      immediate: true
    },
  },
  mounted() {
    if (this.$vuetify.breakpoint.xsOnly) this.type = 'day'
    if (this.$route.query.date) this.focus = this.$route.query.date
    this.$refs.calendar.checkChange()
    this.getReserveTags()

    //  DC: https://discord.com/channels/963610149984043019/963615118300680262/1252215994844188732
    if (this.$route.query.sync_google_calendar === '1') {
      if (this.doctorUser) {
        this.syncGoogleDialog = true
      }
      this.$router.replace({query: {sync_google_calendar: undefined}});
    }
  },
  methods: {
    ...mapMutations({
      setSelectRecord: 'setSelectRecord'
    }),
    showEventHtml(event) {
      let blockIcon = getBlockListIcon(event)

      if (Object.values(this.specialPatient).includes(event.patient_code))
        return `<div class="black--text" style="font-size: 14px; user-select: none;">
                  ${event.name}${blockIcon}
                  <span style="font-size: 13px; padding-left: 2px">${event.note}</span>
                </div>`
      let icon = getReserveIcon(event)

      return `<div class="black--text" style="font-size: 14px; user-select: none;">
                <img src="${assetURL(icon)}" width="18" class="mr-1">${event.name}${blockIcon}
                <span style="font-size: 13px; padding-left: 2px">${event.note}</span>
              </div>`
    },
    showEventInfo(event) {
      return `<div>${event.name} ${event.note}</div> <div>${formatTimestampToTime(event.start_time)} - ${formatTimestampToTime(event.end_time)}</div>`
    },
    startDrag({event, timed}) {
      if (event && timed) {
        this.dragEvent = event
        this.dragTime = null
        this.extendOriginal = null
      }
    },
    startTime(tms) {
      const mouse = this.toTime(tms)
      this.handleCreateEvent(mouse)
    },
    handleCreateEvent(createTime = null) {
      if (createTime instanceof PointerEvent) {
        createTime = new Date()
        createTime.setHours(8)
        createTime.setMinutes(0)
        createTime.setMilliseconds(0)
        createTime = createTime.getTime()
      }

      if (this.keepTime && this.roundTime(this.keepTime) !== this.roundTime(createTime)) {
        let duration
        let start_time
        if (this.keepTime < createTime) {
          start_time = this.roundTime(this.keepTime)
          duration = this.$moment.duration(this.$moment(this.roundTime(createTime, false)).diff(this.$moment(start_time))).asMinutes()
        } else {
          start_time = this.roundTime(createTime)
          duration = this.$moment.duration(this.$moment(this.roundTime(this.keepTime, false)).diff(this.$moment(start_time))).asMinutes()
        }

        const data = {
          ...this.axiosParamData,
          start_time: formatDate(start_time),
          work_time: duration,
          patient_code: this.specialPatient.rest,
        }
        if (this.clinicUser) data.doctor_code = this.currentTab
        else data.clinic_code = this.currentTab
        createReserveAPI(data).then(res => {
          this.reload()
        }).finally(() => {
          this.keepTime = null
        })
        return
      }
      this.keepTime = null

      if (this.dragEvent) {
      } else if (this.extendOriginal) {
      } else {
        this.createStart = this.roundTime(createTime)
        this.isPersistent = false
        this.reserveFormDialog = true
        this.createEvent = {
          start: this.createStart,
        }
      }
    },
    startKeep(tms) {
      const mouse = this.toTime(tms)
      if (this.dragEvent && this.dragTime === null) {
        const start = this.dragEvent.start

        this.dragTime = mouse - start
      } else if (this.currentTab !== 'all') {
        this.keepTime = this.toTime(tms)
      }
    },
    extendBottom(event) {
      this.createEvent = event
      this.createStart = event.start
      this.extendOriginal = event.end
    },
    mouseMove(tms) {
      const mouse = this.toTime(tms)

      if (this.dragEvent && this.dragTime !== null) {
        const start = this.dragEvent.start
        const end = this.dragEvent.end
        const duration = end - start
        const newStartTime = mouse - this.dragTime
        const newStart = this.roundTime(newStartTime)
        const newEnd = newStart + duration

        this.dragEvent.start = newStart
        this.dragEvent.end = newEnd
      } else if (this.createEvent && this.createStart !== null) {
        const mouseRounded = this.roundTime(mouse, false)
        const min = Math.min(mouseRounded, this.createStart)
        const max = Math.max(mouseRounded, this.createStart)

        this.createEvent.start = min
        this.createEvent.end = max
      }
    },
    resetEvent() {
      let eventName = 'dragEvent'
      if (this.createEvent) eventName = 'createEvent'
      this[eventName].start = parseInt(this[eventName].start_time) * 1000
      this[eventName].end = parseInt(this[eventName].end_time) * 1000
    },
    endDrag() {
      if (this.extendOriginal && this.extendOriginal !== this.createEvent.end) {
        this.confirmDialog = true
      } else if (this.dragEvent && this.dragTime && this.dragEvent.start !== parseInt(this.dragEvent.start_time) * 1000) {
        this.confirmDialog = true
      } else {
        this.reset()
      }
    },
    cancelDrag() {
      if (((this.createEvent && this.extendOriginal) || this.dragEvent) && !this.confirmDialog) {
        this.resetEvent()
      }
      if (!this.confirmDialog && !this.askDialog) this.reset()
    },
    roundTime(time, down = true) {
      const roundTo = 15 // minutes
      const roundDownTime = roundTo * 60 * 1000

      return down
        ? time - time % roundDownTime
        : time + (roundDownTime - (time % roundDownTime))
    },
    toTime(tms) {
      return new Date(tms.year, tms.month - 1, tms.day, tms.hour, tms.minute).getTime()
    },
    viewDay({date}) {
      this.focus = date
      this.type = 'day'
    },
    getEventColor(event) {
      let fieldName = 'clinic_code'
      if (this.clinicUser) fieldName = 'doctor_code'
      const tabItem = this.tabItems.find(e => e[fieldName] === event[fieldName])

      return tabItem ? tabItem.color_code : 'grey lighten-3'
    },
    setToday() {
      this.focus = ''
    },
    prev() {
      this.$refs.calendar.prev()
    },
    next() {
      this.$refs.calendar.next()
    },
    showEvent({nativeEvent, event}) {
      if (this.confirmDialog) return
      const open = () => {
        this.selectedEvent = event
        this.selectedElement = nativeEvent ? nativeEvent.target : null
        requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
      }

      if (this.selectedOpen) {
        this.selectedOpen = false
        requestAnimationFrame(() => requestAnimationFrame(() => open()))
      } else {
        open()
      }

      if (nativeEvent)
        nativeEvent.stopPropagation()
    },
    getReserves({start, end}) {
      if (this.cancelTokenSource) {
        this.cancelTokenSource.cancel('Operation canceled due to new request.');
      }
      // 創建一個新的取消令牌
      this.cancelTokenSource = axios.CancelToken.source();

      clearTimeout(this.getDataTimer)
      this.loading = true

      const params = {
        start: start.date,
        end: end.date,
        ...this.axiosParamData,
        tag_ids: this.filterReserveTags
      }
      if (this.currentTab !== 'all') {
        if (this.clinicUser) params.doctor_code = this.currentTab
        else params.clinic_code = this.currentTab
      }
      getReservesAPI(params, { cancelToken: this.cancelTokenSource.token }).then(res => {
        let events = res.data || []
        if (!this.showRe) {
          events = events.filter(e => !['4', '5'].includes(e.attend_status))
        }
        this.events = events.map(v => ({
          ...v,
          start: parseInt(v.start_time) * 1000,
          end: parseInt(v.end_time) * 1000,
          timed: true,
        }))
        this.getDataTimer = setTimeout(() => {
          this.getReserves({start, end})
        }, 1000 * 60 * 5)
        this.loading = false
      }).catch(e => {
        if (axios.isCancel(e)) {
          console.log('Request canceled:', e.message);
        } else {
          console.error(e);
          alert('取得約診列表失敗，請再刷新一次。');
          this.loading = false
        }
      })
    },
    showCreateReserve() {
      // const start = this.$refs.calendar.lastStart
      // const end = this.$refs.calendar.lastEnd
      // if (this.createDate < start || this.createDate > end) {
      //   this.focus = this.createDate
      // }
      this.focus = this.createDate
    },
    showCreateReserveAlert(v) {
      this.createDate = v
      this.successAlert = true
    },
    reload() {
      const start = this.$refs.calendar.lastStart
      const end = this.$refs.calendar.lastEnd
      this.getReserves({start, end})
    },
    confirm() {
      const event = this.createEvent ? 'createEvent' : 'dragEvent'
      const newStart = this[event].start
      const askSendLine = newStart !== parseInt(this[event].start_time) * 1000 && newStart > Date.now()
      const duration = this.$moment.duration(this.$moment(this[event].end).diff(this.$moment(newStart))).asMinutes()
      const isSpecialPatient = Object.values(this.specialPatient).includes(this[event].patient_code)
      updateReserveAPI(this[event].reserve_no, {
        ...this.axiosParamData,
        start_time: formatDate(newStart),
        work_time: duration,
      }).then((res) => {
        if (!isSpecialPatient && askSendLine && this[event].line_bot_is_verified && this[event].agree) {
          this.askText = res.data.remind_text
          this.askDialog = true
        } else {
          this.reset()
        }
        this.reload()
      }).finally(() => {
        this.confirmDialog = false
      })
    },
    cancel() {
      this.resetEvent()
      this.reset()
      this.confirmDialog = false
    },
    reset() {
      this.createEvent = null
      this.createStart = null
      this.dragTime = null
      this.dragEvent = null
      this.extendOriginal = null
    },
    sendLine() {
      RemindChangeAPI({
        ...this.axiosParamData,
        reserve_no: this.createEvent ? this.createEvent.reserve_no : this.dragEvent.reserve_no
      })
      this.reset()
      this.askDialog = false
    },
    preparePrint() {
      if (this.clinicUser) {
        getDoctorListAPI(this.axiosParamData).then(res => {
          this.printSelectItems = res.data
          this.printSelectItems.unshift({doctor_name: this.$t('ReserveCalendar.allDoctor'), doctor_code: null})
          this.printObject = this.printSelectItems[0]
        })
      } else {
        getClinicListAPI(this.axiosParamData).then(res => {
          this.printSelectItems = res.data
          this.printSelectItems.unshift({clinic_name: this.$t('ReserveCalendar.allClinic'), clinic_code: null})
          this.printObject = this.printSelectItems[0]
        })
      }
      this.printDialog = true
    },
    cancelPrint() {
      this.print = false
      this.printDialog = false
      this.printObject = null
      this.printDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
    },
    handlePrint() {
      const params = {
        start: this.printDate,
        end: this.printDate,
        ...this.axiosParamData,
      }
      if (this.clinicUser) params.doctor_code = this.printObject.doctor_code
      else params.clinic_code = this.printObject.clinic_code
      getReservesAPI(params).then(res => {
        this.printData = res.data.sort((a, b) => {
          return parseInt(a.start_time) - parseInt(b.start_time)
        })
        this.print = true
      })
    },
    getReserveTags() {
      getReserveTagAPI(this.axiosParamData).then(res => {
        this.filterReserveTagItems = res.data
      }).finally(() => {
        this.editFilterReserveTagsItems = handleFixLengthObjArray(this.filterReserveTagItems, 10, {
          tag_id: null,
          content: null
        })
      })
    },
    editReserveTagName() {
      this.editReserveTagNameDialog = true
    },
    handleTagBlur() {
      this.reload()
    },
    gtm(eventName){
      this.$gtm.trackEvent({
        event: eventName
      });
    }
  },

  beforeDestroy() {
    clearTimeout(this.getDataTimer)
    if (this.cancelTokenSource) {
      this.cancelTokenSource.cancel('Component destroyed.');
    }
  },
}
</script>

<style lang="scss">
.v-event-draggable {
  padding-left: 6px;
}

.v-event-timed {
  user-select: none;
  -webkit-user-select: none;
}

.v-event-drag-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  height: 4px;
  cursor: ns-resize;

  &::after {
    display: none;
    position: absolute;
    left: 50%;
    height: 4px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    width: 16px;
    margin-left: -8px;
    opacity: 0.8;
    content: '';
  }

  &:hover::after {
    display: block;
  }
}

.calendar-sheet:not(.calendar-list) {
  height: calc(100vh - 160px);
  min-height: 460px;

  @media only screen and (max-width: 960px) {
    height: calc(100vh - 210px);
  }

  @media only screen and (max-width: 600px) {
    height: calc(100vh - 238px);
  }
}

.v-toolbar__content {
  @media only screen and (max-width: 768px) {
    padding: 4px 0 !important;
  }
}

.tag-select {
  .v-input__control .v-input__slot {
    min-height: 36px !important;
    padding: 0 18px !important;
  }

  &.v-select .v-input__control .v-input__slot {
    padding: 0 8px 0 18px !important;
  }

  &.v-text-field--outlined.v-input--dense .v-label {
    top: 8px;
  }
}

.tag-input {
  .v-input__control .v-input__slot {
    min-height: 28px !important;
    border-radius: 10px;
    padding: 0 18px !important;
  }

  &.v-select .v-input__control .v-input__slot {
    padding: 0 8px 0 18px !important;
  }
}
</style>
