<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col cols="12" sm="6" md="8">
        <div class="mb-4">{{ review.name }} {{ review.review_update_time | convertToTimezone(undefined, "YYYY-MM-DD (dd) HH:mm") }}</div>
        <div v-if="clinicUser" class="mt-2">{{ $t('ReviewDetailCard.doctor') }} {{ review.doctor_name }}</div>
        <div v-else>{{ $t('ReviewDetailCard.clinic') }} {{ review.clinic_name }}</div>
        <v-row v-if="review.treatment && review.treatment.length" no-gutters dense class="mt-2 flex-nowrap">
          <v-col cols="auto" class="align-self-center">{{ $t('ReviewDetailCard.treatment') }}</v-col>
          <v-col cols="auto" class="flex-shrink-1">
            <v-chip v-for="(item, i) in review.treatment" :key="i" color="primary" label outlined class="mr-1 mb-1">
              {{ item }}
            </v-chip>
          </v-col>
        </v-row>
        <v-row v-if="review.recommend && review.recommend.length" no-gutters dense class="mt-2 flex-nowrap"
               style="gap: 0 6px">
          <v-col cols="auto">
            <v-icon>mdi-emoticon-excited-outline</v-icon>
          </v-col>
          <v-col cols="auto" class="flex-shrink-1">
            <v-chip v-for="(item, i) in review.recommend" :key="i" color="primary" outlined class="mr-1 mb-1">
              {{ item }}
            </v-chip>
          </v-col>
        </v-row>
        <v-row v-if="review.negative && review.negative.length" no-gutters dense class="mt-2 flex-nowrap"
               style="gap: 0 6px">
          <v-col cols="auto">
            <v-icon>mdi-emoticon-sad-outline</v-icon>
          </v-col>
          <v-col cols="auto" class="flex-shrink-1">
            <v-chip v-for="(item, i) in review.negative" :key="i" color="deep-orange lighten-1" outlined
                    class="mr-1 mb-1">
              {{ item }}
            </v-chip>
          </v-col>
        </v-row>
        <v-row no-gutters dense class="my-2 flex-nowrap">
          <v-col cols="auto">{{ $t('ReviewDetailCard.reviewMessage') }}</v-col>
          <v-col cols="auto" class="text-pre-line flex-shrink-1">{{ review.message }}</v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-row v-for="(rating, key, i) in review.star" :key="i" no-gutters dense
               class="flex-nowrap" :class="{'justify-center': $vuetify.breakpoint.smAndUp}" style="gap: 2px">
          <v-col cols="auto" class="align-self-center">{{ key }}</v-col>
          <v-col cols="auto">
            <v-rating
              half-increments
              readonly
              length="5"
              :value="ceilToPoint(rating)"
              dense
              color="yellow darken-2"
              class="ma-auto"
              style="line-height: 30px"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-row no-gutters dense class="my-2">
          <v-col cols="12">
            回覆：
            <span
              v-if="!review.response && review.ai_generate_response"
              class="red-6--text font-weight-medium"
            >(您的回覆尚未送出)</span>
          </v-col>
          <v-col cols="12" class="text-pre-line flex-shrink-1">
            <ResponseEditor
              ref="responseEditor"
              :editMode="editDoctorResponse"
              :response="review.response"
              :editingResponse="doctorResponse"
              :aiResponse="review.ai_generate_response"
              :aiGenerating="!!Number(review.ai_generating)"
              :updated_time="review.response_update_time"
              :userInfo="userInfo"
              :negativeReview="negativeReview"
              @cancelEdit="editDoctorResponse = false"
              @editResponse="editResponse"
              @submitResponse="submitResponse"
              @generateResponse="generateResponse"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-dialog v-if="dialog" v-model="dialog" max-width="300">
      <v-card>
        <v-card-title>
          <template v-if="reviewType === 'dentCo'">{{ $t('ReviewDetailCard.replyFromLine') }}</template>
          <template v-if="reviewType === 'google'">您的回覆內容將傳送至 診所Google商家，點擊確認後送出。</template>
        </v-card-title>
        <v-card-actions>
          <v-spacer/>
          <v-btn text color="primary" @click="submit">{{ $t('ReviewDetailCard.confirm') }}</v-btn>
          <v-btn text @click="dialog = false">{{ $t('GENERAL.cancel') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { generateAiResponseAPI, reviewReplyAPI } from "@/api";
import ResponseEditor from "@/components/review/ResponseEditor.vue";
import { ceilToPoint } from "@/utils/tool";

export default {
  name: "ReviewDetailCard",

  components: {ResponseEditor},

  props: {
    review: {
      type: Object,
      required: true
    },
    userInfo: {
      type: Object
    },
    reviewType: {
      type: String,
      default: 'dentCo'
    }
  },

  data() {
    return {
      editDoctorResponse: false,
      doctorResponse: null,
      dialog: false,
      negativeStar: 3
    }
  },

  computed: {
    ...mapGetters([
      'axiosParamData',
      'clinicUser'
    ]),
    negativeReview() {
      if (this.review.star) {
        return Object.values(this.review.star).some(v => v <= this.negativeStar)
      }

      // for (const key in this.review.star) {
      //   if (this.review.star[key] < 3) {
      //     return true
      //   }
      // }
      return true
    }
  },

  methods: {
    ceilToPoint,
    editResponse(response) {
      this.doctorResponse = response
      this.editDoctorResponse = true
    },
    submitResponse(response) {
      this.doctorResponse = response
      this.dialog = true
    },
    generateResponse() {
      const data = {
        id: this.review.id,
        ...this.axiosParamData
      }
      generateAiResponseAPI(data).then(() => {
        this.$refs.responseEditor.generateLoading = false
        this.$emit('refresh')
      }).catch(e => {
        if (e.response && e.response.data && e.response.data.messages) {
          alert(e.response.data.messages)
        } else {
          alert(this.$t('GENERAL.oops'))
          console.error(e)
        }
        this.$refs.responseEditor.generateLoading = false
      })
    },
    submit() {
      const data = {
        ...this.axiosParamData,
        id: this.review.id,
        text: this.doctorResponse,
      }
      reviewReplyAPI(data).then(() => {
        this.editDoctorResponse = false
        this.$emit('refresh')
      }).catch(e => {
        if (e.response && e.response.data && e.response.data.messages) {
          alert(this.$t('ReviewDetailCard.pleaseInput'))
        } else {
          alert(this.$t('GENERAL.oops'))
          console.error(e)
        }
      }).finally(() => {
        this.dialog = false
      })
    }
  }
}
</script>

<style scoped>

</style>