<template>
  <v-btn v-bind="btnProps" @click="sendMessageDialog = true">
    傳送簡訊給患者

    <v-dialog v-if="sendMessageDialog" v-model="sendMessageDialog" max-width="500">
      <v-card>
        <v-card-title>編輯傳送簡訊</v-card-title>
        <v-card-text>
          <v-textarea
            v-model="messageContent"
            outlined
            clearable
            no-resize
            :messages="countText"
          />
          <div class="red--text text-caption text-pre-line">內文 1～70 字為 1 點，71～140 字為 2 點，141～210 字為 3 點 …. 依此類推
            *備註一：輸入的字數與實際發送的字數可能有些微差異，將會依照實際發送的字數來扣點
            *備註二：近期政府推動反詐騙宣導，若內文涉及敏感字眼，可能會有被阻擋發送之風險，若有遇到發送不成功的情況請再洽詢 <a :href="$enum.DENTCO_CS_LINE" target="_blank">牙醫小幫手客服</a>
            *備註三：承上則，每封客製化簡訊將會經由電信企業審核，無詐騙疑慮才能送出，審核時間長度不一，無法立即送達，敬請耐心等候
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn color="primary" @click="confirmMessage">確定內容</v-btn>
          <v-btn text @click="sendMessageDialog = false">取消</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-if="messageConfirmDialog" v-model="messageConfirmDialog" max-width="300" persistent>
      <v-card :loading="messageLoading">
        <v-card-title v-if="messageResult" v-text="messageResult"/>
        <template v-else>
          <v-card-title>將傳送以下訊息給{{ patientName }}</v-card-title>
          <v-card-text>
            <div class="text-pre-line" v-text="messageContent"/>
          </v-card-text>
        </template>
        <v-card-actions>
          <v-btn v-if="messageResult" color="primary" class="mx-auto" @click="closeMessage">確定</v-btn>
          <template v-else>
            <v-spacer/>
            <v-btn color="primary" :loading="messageLoading" @click="sendMessage">確認傳送</v-btn>
            <v-btn text :loading="messageLoading" @click="messageConfirmDialog = false">取消</v-btn>
          </template>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-btn>
</template>

<script>
import { mapGetters } from "vuex";
import { sendSingleSMSMessageAPI } from "../../api";

export default {
  name: "SendSmsMessage",

  props: {
    patientName: {
      type: String,
      required: true
    },
    reserveNo: {
      type: [String, Number],
      required: true
    },
    btnProps: {
      type: Object,
      required: false,
      default: {
        small: true,
        block: true,
        color: "tiffany-7",
        class: "white--text"
      }
    }
  },

  data() {
    return {
      sendMessageDialog: false,
      messageConfirmDialog: false,
      messageContent: '',
      messageResult: null,
      messageLoading: false,
    }
  },
  computed: {
    ...mapGetters([
      'axiosParamData',
    ]),
    countText() {
      if (this.messageContent)
        return `已編輯 ${this.messageContent.length} 字 / 預計使用 ${Math.ceil(this.messageContent.length / 70)} 點`
    }
  },

  methods: {
    confirmMessage() {
      if (!this.messageContent) return alert('簡訊內容不得為空!')
      this.messageConfirmDialog = true
    },
    sendMessage() {
      this.messageLoading = true
      const data = {
        reserve_no: this.reserveNo,
        content: this.messageContent,
        ...this.axiosParamData,
      }

      sendSingleSMSMessageAPI(data).then(() => {
        this.messageResult = '成功發送'
      }).catch(e => {
        if (e.response && e.response.data && e.response.data.messages)
          this.messageResult = e.response.data.messages
        else
          alert(this.$t('GENERAL.oops'))
      }).finally(() => {
        this.messageLoading = false
      })
    },
    closeMessage() {
      this.messageConfirmDialog = false
      this.sendMessageDialog = false
      this.messageResult = null
      this.messageContent = ''
    }
  }
}
</script>

<style scoped>

</style>
