<template>
  <v-dialog v-model="dialog" persistent max-width="600px" :disabled="disabled">
    <template #activator="{ on, attrs }">
      <v-hover v-slot="{ hover }">
        <v-avatar
          :size="size"
          :color="color"
          :style="imgStyle"
          :class="imgClass"
          style="position: relative;"
          v-bind="attrs"
          v-on="on"
        >
          <slot />
          <div
            v-if="hover && !hideDetail"
            class="d-flex justify-center align-center rounded-circle mx-auto"
            :style="imgStyle"
            :class="imgClass"
            style="z-index:3;position: absolute;background-color: rgba(0, 0, 0, 0.2);top:0px;left: 0px;width: 100%;height: 100%"
          >
            <v-icon
              color="white"
              sm
            >
              mdi-camera
            </v-icon>
          </div>
        </v-avatar>
      </v-hover>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h5 font-weight-bold">新增圖片</span>
        <v-spacer />
        <v-btn text class="px-0" small @click="onClose">
          <v-icon size="15">
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text class="pt-4" style="position: relative">
        <div
          v-if="!file"
          class="dropzone d-flex flex-column align-center justify-center"
          :class="{ 'dropzone-hover': dragOver }"
          @dragover.prevent="dragOver = true"
          @dragleave.prevent="dragOver = false"
          @drop.prevent="onDrop"
          @click="onFileClick"
        >
          <input
            ref="file"
            class="d-none"
            placeholder="拖拉或點擊以上傳圖片"
            accept="image/*"
            type="file"
            @change="onFileChange"
          >
          <v-icon v-if="!file" size="30" color="grey lighten-1">
            mdi-image-area
          </v-icon>
          <p v-if="!file" class="text-subtitle-2">
            {{ placeholder }}
          </p>
        </div>
        <v-btn
          v-if="file"
          fab
          dark
          small
          color="grey lighten-1"
          style="position: absolute;top:30px;right: 30px;z-index: 300;"
          @click="file=null"
        >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
        <vue-cropper
          v-if="file"
          ref="cropper"
          :aspect-ratio="aspectRatio"
          :src="imgSrc"
          :view-mode="1"
          drag-mode="move"
          :crop-box-resizable="true"
          :toggle-drag-mode-on-dblclick="false"
          style="height: 350px"
        />
        <div v-if="file" class="d-flex" style="position: relative">
          <div class="flex-grow-1" />
          <v-slider
            v-model="zoom"
            hide-details
            :min="0"
            :max="3"
            step="0.1"
            color="teal lighten-1"
            append-icon="mdi-magnify-plus-outline"
            prepend-icon="mdi-magnify-minus-outline"
            @input="onZoom"
          />
          <div class="flex-grow-1 text-end">
            <v-btn
              v-if="file"
              class="px-0"
              style="min-width: fit-content;position: absolute;right: 0px"
              text
              @click="zoom=0;onZoom()"
            >
              <v-icon>
                mdi-reload
              </v-icon>
            </v-btn>
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="grey" outlined class="text-subtitle-2 rounded-lg" @click="onClose">
          取消
        </v-btn>
        <v-btn dark color="teal lighten-2" class="text-subtitle-2 rounded-lg" @click="onConfirm">
          儲存
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import VueCropper from 'vue-cropperjs';

export default {
  name: 'UploadImageDialog',
  components: { VueCropper },
  props: {
    img: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: '將圖片拖拉到這裡或選擇檔案'
    },
    size: {
      type: [Number, String],
      default: 100
    },
    imgClass: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: 'grey'
    },
    imgStyle: {
      type: String,
      default: ''
    },
    hideDetail: {
      type: Boolean,
      default: false
    },
    aspectRatio: {
      type: Number,
      default: 1
    },
    maxFileSize: {
      type: Number,
      default: 0
    },
    fileQuality: {
      type: Number,
      default: 1
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      dialog: false,
      file: null,
      dragOver: false,
      imgSrc: '',
      zoom: 0
    };
  },
  methods: {
    onFileClick () {
      this.$refs.file.click();
    },
    validateFileSize (file) {
      if (this.maxFileSize > 0) {
        return file.size <= this.maxFileSize;
      }
      return true;
    },
    onFileChange (e) {
      if (e.target.files) {
        if (!this.validateFileSize(e.target.files[0])) {
          alert(this.$t('Upload.fileLargeError'));
          return;
        }
        this.file = e.target.files[0];
        this.imgSrc = window.URL.createObjectURL(new Blob([this.file]));
      }
      console.log(this.file)
    },
    onDrop (event) {
      const files = event.dataTransfer.files;
      if (files.length) {
        if (!this.validateFileSize(files[0])) {
          alert(this.$t('Upload.fileLargeError'));
          return;
        }
        this.file = files[0];
        this.imgSrc = window.URL.createObjectURL(new Blob([this.file]));
      }
      this.dragOver = false; // 清除 hover 狀態
    },
    onConfirm () {
      console.log(this)
      const aaa = this;
      this.$refs.cropper
        .getCroppedCanvas({
          maxWidth: 1960,
          maxHeight: 1960
        })
        .toBlob(
          (blob) => {
            this.cropImg = URL.createObjectURL(blob);
            const imgFile = new File([blob], this.file.name, {type: this.file.type});
            this.$emit('update:img', this.cropImg);
            this.$emit('update', this.cropImg);
            this.$emit('submit', imgFile);
            this.onClose();
          },
          this.file.type,
          this.fileQuality
        );
    },
    onClose () {
      this.file = null;
      this.dialog = false;
      this.dragOver = false;
    },
    onZoom () {
      this.$refs.cropper.zoomTo(this.zoom); // 使用vue-cropper的zoomTo方法來調整縮放比例
    }
  }
};
</script>
<style scoped>
.dropzone {
  width: 100%;
  height: 350px;
  gap:1rem;
  background: #F5F5F5;
  padding: 20px;
  text-align: center;
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.dropzone-hover {
  background: #e3f2fd !important;
}
</style>
