<template>
  <v-btn v-bind="btnProps" @click="sendMessageDialog = true">
    傳送訊息給患者

    <v-dialog v-if="sendMessageDialog" v-model="sendMessageDialog" max-width="500">
      <v-card>
        <v-card-title>編輯傳送LINE訊息</v-card-title>
        <v-card-text>
          <upload-img v-model="message.image" :aspect-ratio="4/3" class="mx-auto mb-4" style="max-width: 300px;"/>
          <v-textarea v-model="message.content" hide-details outlined clearable no-resize/>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn color="primary" @click="confirmMessage">確定內容</v-btn>
          <v-btn text @click="sendMessageDialog = false">取消</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-if="messageConfirmDialog" v-model="messageConfirmDialog" max-width="300" persistent>
      <v-card :loading="messageLoading">
        <v-card-title v-if="messageResult" v-text="messageResult"/>
        <template v-else>
          <v-card-title>將傳送以下訊息給{{ patientName }}</v-card-title>
          <v-card-text>
            <v-img v-if="messageImageUrl" :src="messageImageUrl" contain/>
            <div class="text-pre-line" v-text="message.content"/>
          </v-card-text>
        </template>
        <v-card-actions>
          <v-btn v-if="messageResult" color="primary" class="mx-auto" @click="closeMessage">確定</v-btn>
          <template v-else>
            <v-spacer/>
            <v-btn color="primary" :loading="messageLoading" @click="sendMessage">確認傳送</v-btn>
            <v-btn text :loading="messageLoading" @click="messageConfirmDialog = false">取消</v-btn>
          </template>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-btn>
</template>

<script>
import UploadImg from "../UploadImg";
import { sendSingleMessageAPI } from "../../api";
import { mapGetters } from "vuex";

export default {
  name: "SendMessage",
  props: {
    patientName: {
      type: String,
      required: true
    },
    reserveNo: {
      type: [String, Number],
      required: true
    },
    btnProps: {
      type: Object,
      required: false,
      default: () => ({
        small: true,
        block: true,
        color: "tiffany-7",
        class: "white--text"
      })
    }
  },
  components: {UploadImg},
  data() {
    return {
      sendMessageDialog: false,
      messageConfirmDialog: false,
      message: {
        image: null,
        content: null
      },
      messageResult: null,
      messageLoading: false,
      messageImageUrl: null,
    }
  },
  computed: {
    ...mapGetters([
      'axiosParamData',
    ]),
  },
  methods: {
    confirmMessage() {
      if (this.message.image || this.message.content) {
        if (this.message.image)
          this.messageImageUrl = URL.createObjectURL(this.message.image)
        this.messageConfirmDialog = true
      } else {
        alert('請至少輸入一項!')
      }
    },
    sendMessage() {
      this.messageLoading = true
      const data = {
        reserve_no: this.reserveNo,
        ...this.message,
        ...this.axiosParamData,
      }
      const formData = new FormData()
      for (const [key, value] of Object.entries(data)) {
        if (value)
          formData.append(key, value)
      }
      sendSingleMessageAPI(formData).then(() => {
        this.messageResult = '成功發送'
      }).catch(e => {
        if (e.response && e.response.data && e.response.data.messages)
          this.messageResult = e.response.data.messages
        else
          alert(this.$t('GENERAL.oops'))
      }).finally(() => {
        this.messageLoading = false
      })
    },
    closeMessage() {
      this.messageConfirmDialog = false
      this.sendMessageDialog = false
      this.messageResult = null
      this.message = this.$options.data().message
      this.messageImageUrl = null
    },
  }
}
</script>

<style scoped>

</style>
