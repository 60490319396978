<template>
  <v-data-table :items="events" :headers="headers">
    <template v-slot:item.attend_status="{ item }">
      <img :src="statusIconSrc(item)" width="18">
    </template>
    <template v-slot:item.reserveDate="{ item }">
      {{ parseInt(item.start_time) * 1000 | formatToDate }}
    </template>
    <template v-slot:item.reserveTime="{ item }">
      <span v-html="showReserveTime(item)"/>
    </template>
    <template v-slot:item.reserve_tag="{ item }">
      <div class="d-flex flex-wrap py-1" style="gap: 2px;">
        <v-chip v-for="tag in item.reserve_tag" :key="tag.tag_id">
          #{{ tag.content }}
        </v-chip>
      </div>
    </template>
    <template v-slot:item.detail="{ item }">
      <v-icon color="primary" @click="$emit('showEvent', {event: item, nativeEvent: null})">mdi-eye</v-icon>
    </template>
  </v-data-table>
</template>

<script>
import { getReserveIcon, showReserveTime } from '../../utils/tool';
import { assetURL } from '../../utils/filters';

export default {
  name: "CalendarList",
  props: {
    events: Array,
  },
  data() {
    return {
      showReserveTime
    }
  },
  computed: {
    headers() {
      return [
        {text: this.$t('CalendarList.status'), align: 'center', value: 'attend_status', sortable: false, width: 60},
        {text: this.$t('CalendarList.reserveDate'), align: 'center', value: 'reserveDate', sortable: false, width: 120},
        {text: this.$t('CalendarList.reserveTime'), align: 'center', value: 'reserveTime', sortable: false, width: 80},
        {
          text: this.$t('CalendarList.reserveDoctor'),
          align: 'center',
          value: 'doctor_name',
          sortable: false,
          width: 100
        },
        {
          text: this.$t('CalendarList.reserveClinic'),
          align: 'center',
          value: 'clinic_name',
          sortable: false,
          width: 150
        },
        {text: this.$t('CalendarList.patientName'), align: 'center', value: 'name', sortable: false, width: 90},
        {text: this.$t('CalendarList.patientPhone'), align: 'center', value: 'phone', sortable: false, width: 120},
        {text: this.$t('CalendarList.note'), align: 'center', value: 'note', sortable: false, width: 150},
        {
          text: this.$t('CalendarList.remindOther'),
          align: 'center',
          value: 'remind_others',
          sortable: false,
          width: 150
        },
        {text: this.$t('CalendarList.reserveTag'), align: 'center', value: 'reserve_tag', sortable: false, width: 150},
        {text: '', align: 'center', value: 'detail', sortable: false},
      ]
    },
  },
  methods: {
    statusIconSrc(item) {
      const iconPath = getReserveIcon(item)
      return assetURL(iconPath)
    }
  }
}
</script>

<style scoped>

</style>