<template>
  <div>
    <v-container style="max-width: 1185px" class="pb-5">
      <guide-tour config-key="review.googleReviewList"/>
      <v-container>
        <v-row class="mt-4">
          <v-col cols="12" class="text-center">
            <div class="text-h2 font-weight-bold">
              {{ overview?.total?.star || 0 }}
            </div>
            <v-rating
              half-increments
              readonly
              length="5"
              large
              color="yellow darken-2"
              :value="ceilToPoint(overview?.total?.star || 0)"
            />
            <div><span class="text-h5">{{ overview?.total?.count || 0 }}</span> {{ $t('Review.reviewCount') }}</div>
          </v-col>
          <v-col cols="12">
            <div class="d-flex align-center">
              <v-spacer/>
              <div>
                評價排序：
              </div>
              <div>
                <v-select
                  v-model="selectSorted"
                  :items="orderOptions"
                  dense
                  outlined
                  hide-details
                />
              </div>
            </div>
          </v-col>
          <v-col cols="12">
            <show-a-i-response-setting v-if="userInfo" :userInfo="userInfo"/>
            <v-divider class="mt-4"/>
            <v-progress-linear :active="loading" indeterminate/>
            <v-tabs v-model="tabs" id="review-list">
              <v-tab>{{ $t('Review.allReview') }}({{ overview?.total?.count || 0 }})</v-tab>
              <v-tab>{{ $t('Review.reviewWithMessage') }}({{ overview?.total?.message || 0 }})</v-tab>
              <v-tab>{{ $t('Review.negativeReview') }}({{ overview?.total?.negative || 0 }})</v-tab>
            </v-tabs>
            <div>
              <template v-if="reviews" v-for="review in reviews">
                <review-detail-card
                  :key="review.id"
                  :review="review"
                  :userInfo="userInfo"
                  review-type="google"
                  @refresh="getReviews"
                />
                <v-divider/>
              </template>
            </div>
            <div>
              <v-pagination
                v-model="page"
                class="my-4"
                :length="maxPage"
                total-visible="10"
              ></v-pagination>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </div>
</template>

<script>
import GuideTour from "@/components/utils/GuideTour.vue";
import { ceilToPoint } from "@/utils/tool";
import ShowAIResponseSetting from "@/pages/review/ShowAIResponseSetting.vue";
import { getGoogleReviewOverviewAPI, getGoogleReviewsAPI, getInfoAPI, getReviewOverviewAPI } from "@/api";
import { mapGetters } from "vuex";
import ReviewDetailCard from "@/components/review/ReviewDetailCard.vue";

export default {
  name: 'GoogleReviewPage',

  components: {ReviewDetailCard, ShowAIResponseSetting, GuideTour},

  data() {
    return {
      userInfo: null,
      tabs: 0,
      overview: null,
      reviews: [],
      page: 1,
      limit: 10,
      maxPage: 1,
      loading: false,
      selectSorted: '0',
      orderOptions: [
        {text: '最新評價', value: '0'},
        {text: '評分(高到低)', value: '1'},
        {text: '評分(低到高)', value: '2'},
        {text: '尚未回覆', value: '3'}
      ]
    }
  },

  computed: {
    ...mapGetters([
      'axiosParamData'
    ]),
  },

  mounted() {
    this.getPermissionsInfo()
    this.getGoogleReviewOverview()
    this.getReviews()
  },

  watch: {
    page(v) {
      if (v === 1)
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
      else
        document.getElementById('review-list').scrollIntoView({behavior: 'smooth', block: 'center'})
      this.getReviews()
    },
    tabs() {
      if (this.page === 1) {
        this.getReviews()
      }
      this.page = 1
    },
    selectSorted() {
      this.getReviews()
    }
  },

  methods: {
    ceilToPoint,
    getPermissionsInfo() {
      getInfoAPI(this.axiosParamData).then(res => {
        this.userInfo = res.data
      })
    },
    getReviews() {
      this.loading = true
      const params = {
        ...this.axiosParamData,
        page: this.page,
        limit: this.limit,
        sort: this.selectSorted,
        kind: this.tabs,
      }
      getGoogleReviewsAPI(params).then(res => {
        this.reviews = res.data.data
        this.maxPage = parseInt(res.data.paginate.page_total)
      }).finally(() => {
        this.loading = false
      })
    },
    getGoogleReviewOverview() {
      getGoogleReviewOverviewAPI(this.axiosParamData).then(res => {
        if (Array.isArray(res.data.data)) {
          this.overview = null
        } else {
          this.overview = res.data.data
        }
      })
    }
  }
};
</script>

<style scoped>
/* Your styles here */
</style>
