<template>
  <v-card flat max-width="1280" class="pl-md-6">
    <guide-tour config-key="message.log"/>
    <div class="px-4 mt-4">
      <div>
        <div class="bullet">
          篩選發送訊息
        </div>
        <v-sheet color="grey-8 pa-5 mt-5">
          <v-row>
            <v-col cols="12" md="6">
              <v-menu
                ref="menu"
                v-model="dateMenu"
                :close-on-content-click="false"
                :return-value.sync="selectDate"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateRangeText"
                    readonly
                    clearable
                    hide-details
                    dense
                    outlined
                    label="請選擇開始日期與結束日期"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="selectDate"
                  no-title
                  scrollable
                  range
                  locale="zh"
                  :allowed-dates="allowedDates"
                >
                  <v-spacer/>
                  <v-btn text color="primary" @click="dateMenu = false">
                    取消
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.menu.save(selectDate)">
                    確認
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="6">
              <v-select
                v-model="selectLogMode"
                :items="logModeOptions"
                return-object
                outlined
                dense
                label="請選擇要瀏覽的訊息"
                hide-details
              />
            </v-col>
            <v-col v-if="selectLogMode && canSearchPhone.includes(selectLogMode.value)" cols="12" md="6">
              <v-text-field
                v-model="searchPhone"
                outlined
                dense
                label="電話號碼(選填)"
                clearable
                hide-details
              />
            </v-col>
          </v-row>
          <div class="text-right mt-3">
            <v-btn
              color="primary"
              :disabled="!(dateRangeText.length && selectLogMode)"
              @click="handleSearch"
            >
              確認篩選
            </v-btn>
          </div>
        </v-sheet>
      </div>
      <div v-if="logTitle" class="mt-8 pb-12">
        <div class="bullet d-flex align-center">
          {{ logTitle }}
          <div class="ml-auto">
            <v-btn color="primary" outlined @click="downloadLog">
              <v-icon>mdi-file-download</v-icon>
              下載記錄
            </v-btn>
          </div>
        </div>
        <div class="mt-2 d-flex flex-column">
          <v-sheet
            v-for="(log, idx) in logData"
            :key="idx"
            class="pa-5 d-flex"
            :color="idx % 2 === 0 ? '#F5F5F5' : ''"
          >
            <div class="pr-4 flex-grow-1">
              <div v-if="['lineRemind', 'smsRemind', 'voiceRemind'].includes(currentSelectName)">
                傳送對象：{{ log.patient_phone }} {{ log.patient_name }}
              </div>
              <div v-else>傳送對象：{{ log.patient_name }}</div>
              <div class="d-flex" :class="{'flex-wrap': $vuetify.breakpoint.smAndDown}">
                <div style="min-width: fit-content;">
                  發送內容：
                </div>
                <div
                  class="text-pre-line"
                  :class="{'pl-4': $vuetify.breakpoint.smAndDown}"
                  style="width: 100%;"
                  v-text="log.content"
                />
              </div>
              <div>發送時間：{{ log.create_time }}</div>
              <div v-if="['smsRemind', 'voiceRemind'].includes(currentSelectName)">發送點數：使用點數 {{ log.point }} 點
              </div>
              <div v-if="['smsRemind', 'voiceRemind'].includes(currentSelectName)">發送狀態：{{ log.status }}</div>
            </div>
            <div v-if="['lineGroup', 'smsGroup'].includes(currentSelectName)" class="flex-shrink-1">
              <v-btn color="grey-3" depressed @click="downloadDetailLog(currentSelectName, log.no)">
                <v-icon>mdi-file-download</v-icon>
                下載詳細名單
              </v-btn>
            </div>
          </v-sheet>

          <v-pagination
            v-model="page"
            :length="length"
            :total-visible="7"
            class="mt-2"
          ></v-pagination>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  downloadLineMessageGroupDetailLogsAPI,
  downloadLineMessageGroupLogsAPI,
  downloadLineMessageRemindLogsAPI,
  downloadSmsMessageGroupDetailLogsAPI, downloadSmsMessageGroupLogsAPI,
  downloadSmsRemindLogsAPI,
  downloadVoiceRemindLogsAPI,
  getLineMessageGroupLogsAPI,
  getLineMessageRemindLogsAPI,
  getSMSMessageGroupLogAPI,
  getSmsRemindLogsAPI,
  getVoiceRemindLogsAPI
} from '../../api';
import GuideTour from "../../components/utils/GuideTour.vue";

export default {
  name: "MessageLog",

  components: {GuideTour},

  data() {
    return {
      dateMenu: false,
      selectDate: [],
      selectLogMode: null,
      currentSelectName: null,
      logModeOptions: [
        {text: 'LINE 群發記錄', value: 'lineGroup'},
        {text: 'LINE 訊息發送記錄', value: 'lineRemind'},
        {text: '簡訊發送記錄', value: 'smsRemind'},
        {text: '簡訊群發記錄', value: 'smsGroup'},
        {text: '語音發送記錄', value: 'voiceRemind'},
      ],
      canSearchPhone: ['lineRemind', 'smsRemind', 'voiceRemind'],
      searchPhone: null,
      logData: [],
      logTitle: null,
      page: 1,
      length: 1,
    }
  },

  computed: {
    ...mapGetters([
      'axiosParamData',
    ]),
    dateRangeText: {
      get: function () {
        return this.selectDate.sort().join(' ~ ')
      },
      set: function () {
        this.selectDate = []
      }
    },
    logAxiosData() {
      const data = {}
      if (this.selectDate.length === 1) {
        data.start = data.end = this.selectDate[0]
      } else {
        data.start = this.selectDate[0]
        data.end = this.selectDate[1]
      }
      return data
    }
  },

  methods: {
    allowedDates(val) {
      const defaultAllow = this.$moment(val).isBetween(this.$moment().subtract(90, 'days'), this.$moment(), 'day', '(]')
      if (this.selectDate.length === 1) {
        return defaultAllow && (this.$moment(val).isBetween(this.$moment(this.selectDate[0]).subtract(30, 'days'), this.$moment(this.selectDate[0]), 'day', '(]') ||
          this.$moment(val).isBetween(this.$moment(this.selectDate[0]), this.$moment(this.selectDate[0]).add(30, 'days'), 'day', '()'))
      }

      return defaultAllow
    },
    handleSearch() {
      if (!(this.selectLogMode && this.selectDate.length))
        return alert('請選擇日期區間與記錄類型')
      this.currentSelectName = this.selectLogMode.value
      this.logTitle = `發送紀錄 - ${this.dateRangeText} ${this.selectLogMode.text}`
      if (this.page === 1)
        this.search()
      else
        this.page = 1
    },
    search() {
      const data = {
        ...this.logAxiosData,
        ...this.axiosParamData,
        page: this.page,
        limit: 10
      }
      let api

      switch (this.selectLogMode.value) {
        case 'lineGroup':
          api = getLineMessageGroupLogsAPI
          break
        case 'lineRemind':
          if (this.searchPhone && this.searchPhone.trim())
            data.phone = this.searchPhone.trim()
          api = getLineMessageRemindLogsAPI
          break
        case 'smsRemind':
          if (this.searchPhone && this.searchPhone.trim())
            data.phone = this.searchPhone.trim()
          api = getSmsRemindLogsAPI
          break
        case 'smsGroup':
          if (this.searchPhone && this.searchPhone.trim())
            data.phone = this.searchPhone.trim()
          api = getSMSMessageGroupLogAPI
          break
        case 'voiceRemind':
          if (this.searchPhone && this.searchPhone.trim())
            data.phone = this.searchPhone.trim()
          api = getVoiceRemindLogsAPI
          break
      }

      api(data).then(res => {
        this.logData = res.data.data
        this.length = Number(res.data.paginate.page_total)
      })
    },
    downloadLog() {
      const data = {
        ...this.logAxiosData,
        ...this.axiosParamData
      }
      let api, fileName

      switch (this.selectLogMode.value) {
        case 'lineGroup':
          api = downloadLineMessageGroupLogsAPI
          fileName = 'grouplog'
          break
        case 'lineRemind':
          if (this.searchPhone && this.searchPhone.trim())
            data.phone = this.searchPhone.trim()
          api = downloadLineMessageRemindLogsAPI
          fileName = 'remindlog'
          break
        case 'smsRemind':
          api = downloadSmsRemindLogsAPI
          fileName = 'smslog'
          break
        case 'smsGroup':
          api = downloadSmsMessageGroupLogsAPI
          fileName = 'grouplog'
          break
        case 'voiceRemind':
          api = downloadVoiceRemindLogsAPI
          fileName = 'voicelog'
          break
      }

      api(data).then(res => {
        const blob = new Blob([res.data], {type: 'text/csv;charset=utf-8;'});
        const link = document.createElement("a");
        const url = URL.createObjectURL(blob);
        const fileName = res.headers['content-disposition'].split("filename=")[1] || fileName
        link.setAttribute("href", url);
        link.setAttribute("download", fileName);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
    },
    downloadDetailLog(downloadType, no) {
      let api

      switch (downloadType) {
        case 'lineGroup':
          api = downloadLineMessageGroupDetailLogsAPI
          break
        case 'smsGroup':
          api = downloadSmsMessageGroupDetailLogsAPI
          break
      }

      if (!api || !no) {
        return alert('請選擇群發記錄')
      }

      api(no, this.axiosParamData).then(res => {
        // 從後端響應中讀取 Content-Type
        const contentType = res.headers['content-type'] || 'application/octet-stream';
        const blob = new Blob([res.data], { type: contentType });

        // 從 Content-Disposition 中提取檔案名稱
        const contentDisposition = res.headers['content-disposition'] || '';
        const fileName = contentDisposition.split("filename=")[1]?.replace(/['"]/g, '') || 'downloaded-file.xlsx';

        // 創建下載鏈接
        const link = document.createElement("a");
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", fileName);
        link.style.visibility = 'hidden';

        // 觸發下載
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    }
  },

  watch: {
    page() {
      this.search()
    },
  }
}
</script>

<style scoped>
.bullet::before {
  content: "";
  background: #000000;
  padding-inline: 5px;
  height: 10px;
  margin-inline-end: 10px;
  display: inline-block;
}
</style>
