<template>
  <setting-layout ref="layout" :title="title" :subtitle="subtitle" :hasPermission="hasPermission" @cancel="cancel" @save="save">
    <div v-if="!hasPermission" class="mt-7">
      <v-btn
        dark
        color="tiffany-10"
        depressed
        :block="$vuetify.breakpoint.smAndDown"
        class="px-md-12 rounded-lg"
        href="https://lin.ee/mWs8BoE"
        target="_blank"
      >
        欲顯示醫療環境，請點我升級為進階方案
      </v-btn>
    </div>
    <div class="mt-7">
      <div
        v-for="(data, category) in spaces"
        class="grey-2 pa-5 rounded-lg mt-4"
      >
        <div class="grey-7--text text-body-2 mb-4">
          [ {{ category }} ]
        </div>
        <v-row>
          <template v-for="(space, n) in data">
            <v-col :key="`space-${n}`" cols="12" sm="6" class="mb-4">
              <v-row>
                <v-col cols="12">
                  <v-checkbox
                    v-model="clinicEquipmentSpaceId"
                    :value="space.id"
                    true-value="1"
                    false-value="0"
                    class="ma-0"
                    hide-details dense
                    :disabled="!canEdit"
                    @change="changeItems($event, space)"
                  >
                    <template v-slot:label>
                      <div v-html="space.title"/>
                    </template>
                  </v-checkbox>
                </v-col>
                <v-col cols="1"></v-col>
                <v-col v-show="clinicEquipmentSpaceId.includes(space.id)" cols="11">
                  <v-textarea
                    :rows="$vuetify.breakpoint.mdAndUp ? 1 : 2"
                    no-resize
                    counter
                    filled dense rounded
                    full-width hide-details="auto"
                    class="setting-input"
                    maxlength="20"
                    :value="(clinicEquipmentSpaceId.includes(space.id)) ? clinicEquipmentSpaceItems[space.id] : ''"
                    :placeholder="'例如: ' + space.intro"
                    :disabled="!canEdit"
                    @input="updateEquipmentSpace($event, space)"
                  />
                </v-col>
              </v-row>
            </v-col>
          </template>
        </v-row>
      </div>

    </div>
  </setting-layout>
</template>

<script>
import { mapGetters } from 'vuex';
import SettingLayout from './SettingLayout';
import {
  getMenuEquipmentAPI,
  getMenuEquipmentSpaceAPI,
  getClinicEquipmentSpaceAPI,
  saveClinicEquipmentSpaceAPI
} from '../../../api';
import { checkPermissions } from '../../../utils/tool';

export default {
  name: "OurEnvironment",
  inject: ['getIsEdit', 'toggleEdit', 'saveAlert', 'toggleLoading'],
  components: {SettingLayout},
  props: {
    title: {
      required: true,
      type: String
    },
    subtitle: {
      required: true,
      type: String
    },
    plan: {
      required: true,
      type: String
    },
    userInfo: {
      required: true,
      type: Object,
    }
  },
  computed: {
    ...mapGetters(['axiosParamData']),
    canEdit() {
      return this.getIsEdit() && this.hasPermission
    },
    hasPermission() {
      return checkPermissions({obj: this.userInfo, type: 'clinic', permissions:this.pagePermission})
    }
  },
  data() {
    const defaultItem = '基本項目'
    const advanceItem = '進階項目'
    const bonusItem = '加分項目'
    const spaces = {}
    spaces[defaultItem] = []
    spaces[advanceItem] = []
    spaces[bonusItem] = []
    return {
      spaces,
      defaultItem,
      advanceItem,
      bonusItem,
      oClinicEquipmentSpaceId: [],
      clinicEquipmentSpaceId: [],
      oClinicEquipmentSpaceItems: {},
      clinicEquipmentSpaceItems: {},
      pagePermission: ['adv_page']
    }
  },
  mounted() {
    this.getMenuEquipmentSpace();
    this.getClinicEquipmentSpace();
  },
  methods: {
    save() {
      this.toggleLoading(true)
      const vm = this
      const filtered = Object.keys(vm.clinicEquipmentSpaceItems)
        .filter(key => vm.clinicEquipmentSpaceId.includes(key))
        .reduce((obj, key) => {
          obj[key] = vm.clinicEquipmentSpaceItems[key]
          return obj
        }, {})
      const settingData = []
      for (const [key, value] of Object.entries(filtered)) {
        settingData.push({
          id: key,
          intro: value
        })
      }
      const data = {
        ...this.axiosParamData,
        setting_data: settingData
      }
      saveClinicEquipmentSpaceAPI(data).then(() => {
        this.toggleEdit(false)
        this.getClinicEquipmentSpace()
        this.saveAlert()
      }).catch(e => {
        console.error(e)
        this.toggleLoading(false)
      })
    },
    cancel() {
      this.resetData()
    },
    resetData() {
      this.clinicEquipmentSpaceId = JSON.parse(JSON.stringify(this.oClinicEquipmentSpaceId))
      this.clinicEquipmentSpaceItems = JSON.parse(JSON.stringify(this.oClinicEquipmentSpaceItems))
    },
    getClinicEquipmentSpace () {
      this.toggleLoading(true)
      const vm = this;

      vm.oClinicEquipmentSpaceId = [];
      vm.oClinicEquipmentSpaceItems = {};

      getClinicEquipmentSpaceAPI({ ...this.axiosParamData }).then(res => {
        const ClinicEquipmentSpaces = res.data;

        if (ClinicEquipmentSpaces.length > 0) {
          ClinicEquipmentSpaces.forEach(function(space){
            vm.oClinicEquipmentSpaceId.push(space.id);
            vm.oClinicEquipmentSpaceItems[space.id] = space.intro;
          });
        }
        this.resetData()
      }).finally(() => {
        this.toggleLoading(false)
      })
    },
    getMenuEquipmentSpace () {
      getMenuEquipmentSpaceAPI(this.axiosParamData).then(res => {
        const groupSpaces = res.data.reduce((group, product) => {
          const { category } = product;
          group[category] = group[category] || [];
          group[category].push(product);
          return group;
        }, {});

        this.spaces[this.defaultItem] = groupSpaces[this.defaultItem];
        this.spaces[this.advanceItem] = groupSpaces[this.advanceItem];
        this.spaces[this.bonusItem] = groupSpaces[this.bonusItem];
      });
    },
    changeItems(event, equipmentSpaceItem) {
      if (event.includes(equipmentSpaceItem.id) && !this.clinicEquipmentSpaceItems[equipmentSpaceItem.id]) {
        this.clinicEquipmentSpaceItems[equipmentSpaceItem.id] = null
      }
    },
    updateEquipmentSpace(value, equipmentSpaceItem) {
      this.clinicEquipmentSpaceItems[equipmentSpaceItem.id] = value
    },
  }
}
</script>

<style lang="scss" scoped>
.col {
  padding: 6px;
}
.setting-input {
  .v-input__control .v-input__slot {
    min-height: 36px !important;
    border-radius: 10px;
    padding: 0 18px !important;
  }

  &.v-select .v-input__control .v-input__slot {
    padding: 0 8px 0 18px !important;
  }
}
</style>
