<template>
  <v-sheet v-else outlined rounded color="red-6">
    <v-card class="d-flex" :class="{'flex-wrap': $vuetify.breakpoint.xsOnly, 'pl-5': $vuetify.breakpoint.smAndUp}" flat>
      <div
        class="align-self-center red-6--text py-5"
        :class="{'text-center': $vuetify.breakpoint.xsOnly}"
        :style="$vuetify.breakpoint.xsOnly ? 'width: 100%;' : ''"
      >
        <v-overlay :value="loading" absolute>
          <v-progress-circular
            indeterminate
            size="32"
          ></v-progress-circular>
        </v-overlay>
        <div v-if="!loading" class="d-flex align-center">
          <v-icon color="red-6" :class="{'d-block': $vuetify.breakpoint.xsOnly}" size="30">mdi-bullhorn</v-icon>
          <div class="px-4 font-weight-medium">
            {{ message }}
          </div>
        </div>
      </div>
      <div :class="{'ml-auto': $vuetify.breakpoint.smAndUp, 'mt-2': $vuetify.breakpoint.xsOnly}"
           :style="$vuetify.breakpoint.xsOnly ? 'width: 100%' : 'unset'">
        <v-btn
          block
          tile
          :height="$vuetify.breakpoint.xsOnly ? 'unset' : '100%'"
          min-height="36"
          class="elevation-0 white--text"
          color="red-6"
          :href="btnLink"
          target="_blank"
        >{{ btnTitle }}
        </v-btn>
      </div>
    </v-card>
  </v-sheet>
</template>

<script>
export default {
  name: "DentcoAlertBar",

  props: {
    message: {
      type: String,
      required: true
    },
    alertIcon: {
      type: String,
      default: 'mdi-bullhorn'
    },
    btnTitle: {
      type: String,
      required: true
    },
    btnLink: {
      type: String,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>

</style>
