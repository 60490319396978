import Vue from 'vue';
import VueRouter from 'vue-router';

import liff from "@line/liff";
import AccountSettings from "./pages/AccountSettings";
import Administrator from "./pages/Administrator";
import Analytics from "./pages/Analytics";
import Home from './pages/Home.vue';
import Login from './pages/Login';
import Notices from "./pages/Notices";
import OnlineReserve from "./pages/OnlineReserve";
import OpenaiConversation from "./pages/OpenaiConversation";
import Patients from "./pages/Patients";
import PostOperativeCare from "./pages/PostOperativeCare";
import Question from "./pages/Question";
import Reschedule from "./pages/Reschedule";
import SuperLink from "./pages/SuperLink";
import Upload from "./pages/Upload";
import Settings from "./pages/settings/index";
import store from './store';
import { loginLiff } from "./utils/tool";
import AICustomerService from './pages/AICustomerService';
import SwitchAccount from "./pages/SwitchAccount.vue";
import LineSendStep from "./pages/message/send/LineSendStep.vue";
import MessageIndex from "./pages/message/MessageIndex.vue";
import SmsRefillLog from "./pages/message/SmsRefillLog.vue";
import MessageLog from "./pages/message/MessageLog.vue";
import MessageSetting from "./pages/message/setting/MessageSetting.vue";
import LineSetting from "./pages/message/setting/LineSetting.vue";
import SmsSetting from "./pages/message/setting/SmsSetting.vue";
import MessengerSetting from "./pages/message/setting/MessengerSetting.vue";
import VoiceSetting from "./pages/message/setting/VoiceSetting.vue";
import OnlineAppointmentsSettingIndex from "@/pages/onlineAppointmentsSetting/OnlineAppointmentsSettingIndex.vue";
import FreeTime from "@/pages/onlineAppointmentsSetting/FreeTime.vue";
import TreatmentSetting from "@/pages/onlineAppointmentsSetting/TreatmentSetting.vue";
import ReviewIndex from "@/pages/review/ReviewIndex.vue";
import ReviewOverview from "@/pages/review/ReviewOverview.vue";
import ReviewSettings from "@/pages/review/setting/ReviewSettings.vue";
import ModeSetting from "@/pages/review/setting/ModeSetting.vue";
import AiSetting from "@/pages/review/setting/AiSetting.vue";
import CloseNotificationSetting from "@/pages/message/CloseNotificationSetting.vue";
import GoogleReviewPage from "@/pages/review/GoogleReviewPage.vue";
import MessageSendIndex from "@/pages/message/send/MessageSendIndex.vue";
import SmsSendStep from "@/pages/message/send/SmsSendStep.vue";

Vue.use(VueRouter);

const inLiff = liff.isInClient()
const liffAuth = sessionStorage.getItem('liffAuth') !== '0'

const router = new VueRouter({
  mode: 'history',
  linkExactActiveClass: 'active',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: {
        auth: true,
        liff: true,
        canSpecial: false
      },
      beforeEnter: (to, from, next) => {
        if (store.getters.isSpecialClinic)
          return router.push({name: 'onlineReserve'}).catch(() => {
          })
        if (inLiff && liffAuth) {
          loginLiff(process.env.VUE_APP_RESERVE_LIFF, next)
        } else {
          next()
        }
      }
    },
    {
      path: '/login',
      name: 'login',
      component: Login
    },
    {
      path: '/switch-account',
      name: 'switchAccount',
      component: SwitchAccount,
      meta: {
        auth: true,
        admin: true
      }
    },
    {
      path: '/online-reserve',
      name: 'onlineReserve',
      component: OnlineReserve,
      meta: {
        auth: true,
        liff: true,
        canSpecial: true
      },
      beforeEnter: (to, from, next) => {
        if (inLiff && liffAuth) {
          loginLiff(process.env.VUE_APP_ONLINE_RESERVE_LIFF, next)
        } else {
          next()
        }
      }
    },
    {
      path: '/reschedule',
      name: 'reschedule',
      component: Reschedule,
      meta: {
        auth: true,
        liff: true,
        canSpecial: true
      },
      beforeEnter: (to, from, next) => {
        if (inLiff && liffAuth) {
          loginLiff(process.env.VUE_APP_RESCHEDULE_LIFF, next)
        } else {
          next()
        }
      }
    },
    {
      path: '/patients',
      name: 'patients',
      component: Patients,
      meta: {
        auth: true,
        canSpecial: false
      }
    },
    {
      path: '/free-time',
      name: 'freeTime',
      component: OnlineAppointmentsSettingIndex,
      meta: {
        auth: true,
        liff: true,
        canSpecial: true
      },
      beforeEnter: (to, from, next) => {
        if (inLiff && liffAuth) {
          loginLiff(process.env.VUE_APP_FREE_TIME_LIFF, next)
        } else {
          next()
        }
      },
      redirect: {name: 'freeTimeSetting'},
      children: [
        {
          path: 'free-time-setting',
          name: 'freeTimeSetting',
          component: FreeTime,
          meta: {
            auth: true,
            canSpecial: true
          }
        },
        {
          path: 'treatment-setting',
          name: 'treatmentSetting',
          component: TreatmentSetting,
          meta: {
            auth: true,
            canSpecial: true
          }
        }
      ]
    },
    {
      path: '/review',
      name: 'review',
      component: ReviewIndex,
      meta: {
        auth: true,
        liff: true,
        canSpecial: true
      },
      redirect: {name: 'reviewList'},
      children: [
        {
          path: 'list',
          name: 'reviewList',
          component: ReviewOverview,
          meta: {
            auth: true,
            canSpecial: true
          }
        },
        {
          path: 'google-review-list',
          name: 'googleReviewList',
          component: GoogleReviewPage,
          meta: {
            auth: true,
            canSpecial: true
          }
        },
        {
          path: 'setting',
          component: ReviewSettings,
          name: 'reviewSettings',
          redirect: {name: 'reviewSettingMode'},
          children: [
            {
              path: 'mode',
              name: 'reviewSettingMode',
              component: ModeSetting,
              meta: {
                auth: true,
                canSpecial: true
              }
            },
            {
              path: 'ai',
              name: 'aiSettingMode',
              component: AiSetting,
              meta: {
                auth: true,
                canSpecial: true
              }
            }
          ]
        }
      ],
      beforeEnter: (to, from, next) => {
        if (inLiff && liffAuth) {
          loginLiff(process.env.VUE_APP_REVIEW_LIFF, next)
        } else {
          next()
        }
      }
    },
    {
      path: '/upload',
      name: 'upload',
      component: Upload,
      meta: {
        auth: true,
        canSpecial: true
      }
    },
    {
      path: '/settings',
      name: 'settings',
      component: Settings,
      meta: {
        auth: true,
        canSpecial: true
      }
    },
    {
      path: '/analytics',
      name: 'analytics',
      component: Analytics,
      meta: {
        auth: true,
        liff: true,
        canSpecial: true
      },
      beforeEnter: (to, from, next) => {
        if (inLiff && liffAuth) {
          loginLiff(process.env.VUE_APP_ANALYSIS_LIFF, next)
        } else {
          next()
        }
      }
    },
    {
      path: '/settings/admin',
      name: 'adminSettings',
      component: Administrator,
      meta: {
        auth: true,
        canSpecial: true
      }
    },
    {
      path: '/settings/account',
      name: 'accountSettings',
      component: AccountSettings,
      meta: {
        auth: true,
        canSpecial: true
      }
    },
    {
      path: '/message',
      name: 'message',
      component: MessageIndex,
      meta: {
        auth: true,
        canSpecial: true
      },
      redirect: {name: 'MessageSendIndex'},
      children: [
        {
          path: 'send',
          name: 'MessageSendIndex',
          component: MessageSendIndex,
          meta: {
            auth: true,
            canSpecial: true
          },
          redirect: {name: 'messageSend'},
          children: [
            {
              path: 'line',
              name: 'messageSend',
              component: LineSendStep,
              meta: {
                auth: true,
                canSpecial: true
              }
            },
            {
              path: 'sms',
              name: 'smsMessageSend',
              component: SmsSendStep,
              meta: {
                auth: true,
                canSpecial: true
              }
            }
          ]
        },
        {
          path: 'sms-refill-log',
          name: 'smsRefillLog',
          component: SmsRefillLog,
          meta: {
            auth: true,
            canSpecial: true
          }
        },
        {
          path: 'setting',
          name: 'messageSetting',
          component: MessageSetting,
          meta: {
            auth: true,
            canSpecial: true
          },
          redirect: {name: 'lineSetting'},
          children: [
            {
              path: 'line',
              name: 'lineSetting',
              component: LineSetting,
              meta: {
                auth: true,
                canSpecial: true
              }
            },
            {
              path: 'sms',
              name: 'smsSetting',
              component: SmsSetting,
              meta: {
                auth: true,
                canSpecial: true
              }
            },
            {
              path: 'messenger',
              name: 'messengerSetting',
              component: MessengerSetting,
              meta: {
                auth: true,
                canSpecial: true
              }
            },
            {
              path: 'voice',
              name: 'voiceSetting',
              component: VoiceSetting,
              meta: {
                auth: true,
                canSpecial: true
              }
            }
          ]
        },
        {
          path: 'message-log',
          name: 'messageLog',
          component: MessageLog,
          meta: {
            auth: true,
            canSpecial: true
          }
        },
        {
          path: 'close-reminders',
          name: 'closeNotificationSetting',
          component: CloseNotificationSetting,
          meta: {
            auth: true,
            canSpecial: true
          }
        }
      ]
    },
    {
      path: '/question',
      name: 'question',
      component: Question,
      meta: {
        auth: true,
        liff: true,
        canSpecial: true
      },
      beforeEnter: (to, from, next) => {
        if (inLiff && liffAuth) {
          loginLiff(process.env.VUE_APP_QUESTION, next)
        } else {
          next()
        }
      }
    },
    {
      path: '/notice',
      name: 'notice',
      component: Notices,
      meta: {
        auth: true,
        canSpecial: true
      },
    },
    {
      path: '/operative-care',
      name: 'operativeCare',
      component: PostOperativeCare,
      meta: {
        auth: true,
        liff: true,
        canSpecial: true
      },
      beforeEnter: (to, from, next) => {
        if (inLiff && liffAuth) {
          loginLiff(process.env.VUE_APP_POST_OPERATIVE_CARE_LIFF, next)
        } else {
          next()
        }
      }
    },
    {
      path: '/superLink',
      name: 'superLink',
      component: SuperLink,
      meta: {
        auth: true,
        canSpecial: true
      },
    },
    {
      path: '/openaiConversation',
      name: 'openaiConversation',
      component: OpenaiConversation,
      meta: {
        auth: true,
        canSpecial: true
      },
    },
    {
      path: '/ai-customer-service',
      name: 'AICustomerService',
      component: AICustomerService,
      meta: {
        auth: true,
        canSpecial: true
      }
    },
    { // hint: Make sure it's the last
      path: '/:catchAll(.*)*',
      redirect: '/'
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  const canLiff = to.matched.find(record => record.meta.liff)
  if (inLiff && canLiff && liffAuth) {
    store.commit('setLiffUSer')
    next()
  } else {
    const loggedIn = localStorage.getItem('user')
    const user = JSON.parse(loggedIn)
    if(to.name !== 'login' && user && !user.kind) {
      return next({name: 'login', query: {next: to.fullPath}})
    }

    if (to.matched.some(record => record.meta.auth) && !loggedIn) {
      next({name: 'login', query: {next: to.fullPath}})
      return
    } else if ('liff.state' in to.query && loggedIn) {
      const urlParams = new URLSearchParams(to.query['liff.state'])
      const type = urlParams.get('type')
      const clinicCode = urlParams.get('clinic_code') || ''
      const doctorCode = urlParams.get('doctor_code') || ''
      if (type === user.type && clinicCode === user.clinic_code && doctorCode === user.doctor_code) {
        store.commit('setUserData', user)
      } else {
        next({name: 'login', query: {next: to.path, type: type}})
        return
      }
    } else if (!Object.keys(store.state.user).length && loggedIn) {
      store.commit('setUserData', user)
    }

    if (from.name === 'login')
      await store.dispatch('afterLogin')
    next()
  }
})

// Sentry.init({
//   Vue,
//   dsn: process.env.VUE_APP_SENTRY_DSN,
//   integrations: [
//     new Integrations.BrowserTracing({
//       routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//       tracingOrigins: ["localhost", process.env.VUE_APP_SENTRY_TRACING_ORIGIN, /^\//],
//     }),
//   ],
//   tracesSampleRate: 1.0,
//   sendDefaultPii: true
// });

export default router;
