<template>
  <v-card flat class="px-2 px-md-4">
    <v-sheet height="calc(100vh - 350px)" class="d-flex align-center justify-center">
      <div class="grey-3--text text-md-h1 text-h3 font-weight-bold text-center">
        COMING SOON
      </div>
    </v-sheet>
  </v-card>
</template>

<script>
export default {
  name: "MessengerSetting"
}
</script>

<style scoped>

</style>
