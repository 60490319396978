<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <template #activator="{ on, attrs }">
      <v-btn
        v-if="!hasDefaultSlot"
        class="text-subtitle-2 teal--text text--lighten-2 text-decoration-underline"
        text
        v-bind="attrs"
        v-on="on"
      >
        新增/編輯分類
      </v-btn>
      <div v-else v-bind="attrs" v-on="on">
        <slot />
      </div>
    </template>
    <v-card rounded="xl">
      <v-overlay :value="loading" absolute z-index="3">
        <v-progress-circular indeterminate size="64"/>
      </v-overlay>
      <v-card-title>
        <span class="text-h5 font-weight-bold my-3">新增/編輯分類</span>
        <v-spacer />
        <v-btn text class="px-0" small @click="onClose">
          <v-icon size="15">
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <div class="py-8 px-8">
        <h3 class="text-subtitle-1 mb-1">
          衛教/案例分類（最多10個）
        </h3>
        <v-form ref="form" v-model="valid">
          <draggable
            v-model="items"
            tag="ul"
            class="px-0"
            handle=".draggable"
            animation="200"
            @start="drag = true"
            @end="drag = false"
          >
            <v-slide-y-transition
              v-for="(e, i) in items"
              :key="JSON.stringify(e)"
            >
              <v-list-item class="grey lighten-4 rounded-lg mb-5" dense>
                <v-btn text class="px-0" small style="min-width: fit-content">
                  <v-icon size="20" color="black" class="draggable">
                    mdi-cursor-move
                  </v-icon>
                </v-btn>
                <v-list-item-title class="flex-grow-1 px-2">
                  <div
                    class="d-flex flex-column justify-center py-2"
                    style="gap: 0.6rem"
                  >
                    <v-text-field
                      v-model="e.name"
                      placeholder="請輸入分類名稱（限制10字內）"
                      background-color="white"
                      dense
                      outlined
                      required
                      aria-required="true"
                      hide-details="auto"
                      :rules="[rules.required]"
                      class="rounded-lg custom-input"
                    />
                  </div>
                </v-list-item-title>
                <delete-health-category-dialog :name="e.name" @confirm="items.splice(i, 1)"/>
              </v-list-item>
            </v-slide-y-transition>
          </draggable>
        </v-form>
        <v-btn v-if="items.length < 10" class="rounded-lg" depressed block @click="addToItems()">
          <v-icon color="grey" size="15">
            mdi-plus-circle
          </v-icon>
          點我新增分類
        </v-btn>
      </div>
      <v-card-actions class="px-8 pb-7">
        <v-spacer />
        <v-btn
          color="grey"
          outlined
          class="text-subtitle-2 rounded-lg"
          @click="onClose"
        >
          取消
        </v-btn>
        <v-btn
          color="teal lighten-2"
          class="text-subtitle-2 rounded-lg white--text"
          :disabled="items.some(i=>!i)"
          @click="onConfirm"
        >
          儲存
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import draggable from 'vuedraggable';
import DeleteHealthCategoryDialog from './DeleteHealthCategoryDialog.vue';

export default {
  name: 'HealthCategoryDialog',

  components: { DeleteHealthCategoryDialog, draggable },

  props: {
    categories: {
      type: Array,
      default: () => []
    }
  },

  data () {
    return {
      valid: false,
      loading: false,
      dialog: false,
      drag: true,
      items: [],
      rules: {
        required: value => !!value || '*請輸入有效文字，不可輸入空白內容'
      }
    };
  },

  computed: {
    hasDefaultSlot () {
      return !!this.$slots.default;
    }
  },

  watch: {
    dialog (dialog) {
      if (dialog) {
        this.items = [...this.categories];
      }
    }
  },

  methods: {
    onConfirm () {
      this.$refs.form.validate()
      if (!this.valid) return

      this.loading = true
      this.$emit('update', {
        categories: this.items,
        callback: (status) => {
          this.loading = false
          if (status === 'success') {
            this.onClose();
          } else {
            alert('更新失敗，請再試一次')
          }
        }
      });
    },
    onClose () {
      this.dialog = false;
    },
    addToItems () {
      this.items.push({name: null});
    }
  }
};
</script>

<style scoped>
.flip-list-move {
  transition: transform 0.5s;
}

.custom-input {
  ::v-deep &.success--text {
    color: var(--v-primary-base) !important; /* 自訂成功文字顏色 */
  }
}
</style>
