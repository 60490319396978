<template>
  <div class="px-2">
    <v-select
      v-if="isEdit"
      v-model="selected"
      :items="formattedItems"
      item-text="name"
      item-value="value"
      outlined
      multiple
      dense
      hide-details="auto"
      placeholder="請選擇治療分類"
      :rules="[v => !!v.length || '*請選擇「治療分類」才能儲存']"
      clearable
    >
      <template v-slot:selection="{ item, index }">
        <span v-if="index===0">{{ showLabel() }}</span>
      </template>
      <template v-slot:item="{ item, on, attrs }">
        <v-list-item
          v-bind="attrs"
          v-on="on"
          :class="{'pl-8': !item.lv2_appointment_items, 'grey-8': item.lv2_appointment_items}"
          dense
          :disabled="disabledSelect(item)"
          @change="item.lv2_appointment_items ? onChangeParentItem(item) : onChangeChildItem(item)"
        >

          <template v-slot:default="{ active, }">
            <v-list-item-action class="my-0">
              <v-checkbox
                :input-value="active"
                color="primary"
                dense
              ></v-checkbox>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title v-text="item.name"></v-list-item-title>
            </v-list-item-content>
          </template>
        </v-list-item>
      </template>
    </v-select>
    <v-text-field
      v-else
      dense
      :value="showLabel()"
      outlined
      disabled
      hide-details="auto"
    />
  </div>
</template>

<script>
export default {
  name: "MultiLayerSelect",

  props: {
    valueList: {
      type: Array,
      required: true
    },
    treatmentList: {
      type: Array,
      required: true
    },
    isEdit: {
      type: Boolean
    }
  },

  data() {
    return {
      selected: [],
      selectedChildren: []
    }
  },

  computed: {
    items() {
      const tmp = JSON.parse(JSON.stringify(this.treatmentList))
      const items = tmp.map(lv1Item => {
        lv1Item.value = String(lv1Item.id);
        lv1Item.lv2_appointment_items = lv1Item.lv2_appointment_items.map(lv2Item => {
          lv2Item.value = lv1Item.id + '-' + lv2Item.id;
          return lv2Item;
        })
        return lv1Item
      })
      items.push({id: 999, name: '其他', value: '999', lv2_appointment_items: []})
      return items
    },
    formattedItems() {
      // 將第一層和第二層的選項展開為單一列表
      return this.items.reduce((acc, item) => {
        acc.push(item);
        acc.push(...item.lv2_appointment_items);
        return acc;
      }, []);
    }
  },

  watch: {
    valueList: {
      handler(newValue) {
        console.log(1)
        this.selected = newValue
        this.setChildren()
      },
      deep: true,
      immediate: true
    },
    selected: {
      handler(newValue) {
        console.log(2)
        this.$emit('update:valueList', newValue)
      },
      deep: true
    }
  },

  methods: {
    setChildren() {
      this.selectedChildren = this.formattedItems.filter(item =>
        this.selected.includes(item.value) && item.value.includes('-')
      );
    },
    onChangeParentItem(item) {
      const firstChild = item.lv2_appointment_items[0];

      if (firstChild?.value) {
        if (this.selected.includes(item.value)) {
          this.selected.push(firstChild.value)
        } else {
          const index = this.selected.indexOf(firstChild.value);
          this.selected.splice(index, 1);
        }
      }

    },
    onChangeChildItem(item) {
      const parent = this.items.find(i => i.lv2_appointment_items.find(c => c.value === item.value));
      const isFirstChild = parent.lv2_appointment_items[0].value === item.value;

      if (isFirstChild) {
        if (this.selected.includes(item.value)) {
          this.selected.push(parent.value);
        } else {
          const index = this.selected.indexOf(parent.value);
          this.selected.splice(index, 1);
        }
      }
    },
    showLabel() {
      // 找出 "爸爸" 元素的名稱（如果有的話）
      const parentValue = this.selected.find(item => !item.includes('-'));
      const parentName = parentValue
        ? this.items.find(item => item.value === parentValue)?.name || ''
        : '';

      // 取得前兩個子元素的名稱，並用 ", " 分隔
      const childNames = this.selectedChildren.slice(0, 2).map(item => item.name).join(', ');

      // 如果有 "爸爸" 名稱且有子項目名稱，則加上分隔符號 "/"
      const displayedNames = parentName && childNames
        ? `${parentName} / ${childNames}`
        : parentName || childNames;

      // 計算剩餘子項目數量，並組合顯示結果
      const remainingCount = this.selectedChildren.length - 2;
      return remainingCount > 0
        ? `${displayedNames} ...(+${remainingCount})`
        : displayedNames;
    },
    disabledSelect(item) {
      if (this.selected.length === 0) return false;

      // 取得選擇項目中的第一個 lv1 的 ID
      const baseLv1Id = Number(String(this.selected[0]).split('-')[0]);

      // 判斷當前項目的 lv1 ID
      const itemLv1Id = item.item_lv1_id || item.id;

      // 如果當前項目的 lv1 ID 不符合已選項目的 lv1 ID，則禁用
      return itemLv1Id !== baseLv1Id;
    }
  }
}
</script>

<style scoped>

</style>