<template>
  <div>
    <div class="d-flex align-center mt-2">
      <v-sheet color="grey-8" height="60" rounded width="100%" class="d-flex align-center px-2">
        <div class="d-flex" style="width: 400px; max-width: 70%;">
          <v-text-field
            v-model="search"
            v-on:keyup.enter="searchBlacklist()"
            single-line
            hide-details
            outlined
            dense
            clearable
            :placeholder="'輸入姓名/手機/民國生日(例801231)'"
          />
        </div>
        <v-btn icon color="primary" class="ml-2" @click="searchBlacklist()">
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
        <v-spacer/>
        <div class="ml-auto" style="width: 320px;">
          <v-text-field v-model="phone" placeholder="輸入手機號碼" outlined hide-details dense clearable/>
        </div>
        <v-btn class="ml-2" color="primary" @click="addBlacklist()">新增黑名單</v-btn>
      </v-sheet>
    </div>

    <v-data-table
      :headers="headers"
      :items="blacklists"
      :options.sync="options"
      :footer-props="{ itemsPerPageOptions: [100] }"
      :loading="loading"
      fixed-header
      height="calc(100vh - 380px)"
      item-key="id"
    >
      <template v-slot:item.action="{ item }">
        <v-btn icon color="primary" @click="deleteBlacklist(item.id)">
          <v-icon dark>
            mdi-delete
          </v-icon>
        </v-btn>
      </template>
      <template v-slot:item.sex="{ item }">
        <span v-show="item.sex == 0">女</span>
        <span v-show="item.sex == 1">男</span>
        <span v-show="item.sex == 9">未知</span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { addBlacklistAPI, deleteBlacklistAPI, getBlacklistsAPI } from '../../api';

export default {
  name: "Blacklist",

  components: {},

  data() {
    return {
      loading: false,
      search: null,
      phone: '',
      blacklists: [],
      options: {},
      totalBlacklist: 0
    }
  },

  computed: {
    ...mapGetters([
      'axiosParamData',
    ]),
    headers() {
      return [
        { text: '姓名', align: 'center', value: 'patient_name', sortable: false, width: 120 },
        { text: '手機', align: 'center', value: 'value', sortable: false, width: 120 },
        { text: '性別', align: 'center', value: 'sex', sortable: false, width: 120 },
        { text: '生日', align: 'center', value: 'birthday', sortable: false, width: 80 },
        { text: '功能', align: 'center', value: 'action', sortable: false, width: 80 }
      ]
    },
  },

  watch: {
    search () {
      if (this.search === '' || this.search === null) {
        this.getBlacklists()
      }
    }
  },

  methods: {
    addBlacklist () {
      if (this.phone === '') {
        alert('請輸入手機號碼')
        return false;
      }
      const vm = this;
      this.loading = true
      const data = {
        ...this.axiosParamData,
        phone: this.phone
      };
      addBlacklistAPI(data).then(res => {
        vm.getBlacklists();
      }).catch(e => {
        const messages = e.response.data.messages || '';

        if (messages !== '') {
          alert(messages)
        } else {
          alert(this.$t('GENERAL.oops'))
        }
      }).finally(() => {
        this.loading = false
      })
    },
    deleteBlacklist (id) {
      if (confirm('確定刪除？')) {
        const vm = this;
        this.loading = true
        const params = {
          ...this.axiosParamData,
          id: id
        };
        deleteBlacklistAPI(params).then(res => {
          vm.getBlacklists();
        }).catch(e => {
          alert(this.$t('GENERAL.oops'))
          console.error(e)
        }).finally(() => {

        })
      }
    },
    searchBlacklist() {
      this.getBlacklists();
    },
    getBlacklists() {
      this.loading = true
      const params = {
        ...this.axiosParamData,
        search: this.search || null
      }
      getBlacklistsAPI(params).then(res => {
        this.totalBlacklist = parseInt(res.data.total)
        this.blacklists = res.data.data
      }).catch(e => {
        alert(this.$t('GENERAL.oops'))
        console.error(e)
      }).finally(() => {
        this.loading = false
      })
    },
  },

  mounted() {
    this.getBlacklists()
  }
}
</script>

<style scoped></style>
