<template>
  <v-card flat max-width="1280" class="pl-md-6">
    <guide-tour config-key="message.closeNotification"/>

    <div v-if="!pageLoading" class="px-4 mt-4">
      <dentco-alert-bar
        v-if="!hasOwnChannel"
        message="此功能僅開放自有LINE使用，請聯繫牙醫小幫手客服詢問切換"
        :btn-link="$enum.DENTCO_CS_LINE"
        btn-title="聯繫牙醫小幫手儲值"
        class="mb-4"
      />

      <v-card outlined rounded :disabled="!hasOwnChannel" :loading="loading">
        <div class="grey-8 px-md-8 py-md-4">
          <v-toolbar flat color="grey-8">
            <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">
              今日
            </v-btn>
            <v-btn fab text small color="grey darken-2" @click="prev">
              <v-icon small>
                mdi-chevron-left
              </v-icon>
            </v-btn>
            <v-btn fab text small color="grey darken-2" @click="next">
              <v-icon small>
                mdi-chevron-right
              </v-icon>
            </v-btn>
            <v-toolbar-title v-if="$refs.calendar">
              {{ $refs.calendar.title }}
            </v-toolbar-title>
            <v-spacer/>
          </v-toolbar>
          <v-sheet height="calc(100vh - 350px)">
            <v-calendar
              ref="calendar"
              v-model="focus"
              :locale="$i18n.locale"
              color="primary"
              @click:day="toggleClosed"
            >
              <template v-slot:day="{ past, date }">
                <div
                  v-if="closedDates.includes(date)"
                  class="d-flex align-center justify-center"
                >
                  <div v-if="isEdit">
                    <v-icon color="red-6" size="32">mdi-close-circle</v-icon>
                  </div>
                  <v-chip v-else small color="red-6 lighten-4 red-6--text">
                    <v-icon small>mdi-close-circle</v-icon>
                    <span class="ml-1">已關閉當日提醒</span>
                  </v-chip>
                </div>
              </template>
            </v-calendar>
          </v-sheet>
        </div>
        <v-card-actions class="py-4">
          <v-spacer/>
          <template v-if="isEdit">
            <v-btn dark color="grey-7" width="100" @click="onCancel">取消</v-btn>
            <v-btn dark color="red-6" width="100" @click="onConfirm">儲存</v-btn>
          </template>
          <v-btn v-else color="primary" width="100" @click="onEdit">
            <v-icon small>mdi-pencil</v-icon>
            編輯
          </v-btn>
        </v-card-actions>
      </v-card>
      <confirm-dialog
        v-if="toggleConfirmDialog"
        v-model="toggleConfirmDialog"
        :title="confirmTitle"
        :content="confirmContent"
        :confirm-btn-text="confirmText"
        :cancel-btn-text="cancelText"
        :confirm-btn-color="confirmColor"
        :cancel-btn-color="cancelColor"
        @confirm="confirmFunc"
      />
    </div>
  </v-card>
</template>

<script>
import GuideTour from "@/components/utils/GuideTour.vue";
import DentcoAlertBar from "@/components/utils/DentcoAlertBar.vue";
import { mapGetters, mapMutations } from "vuex";
import { getMessageSettingAPI, getRemindSettingCloseAPI, setRemindSettingCloseAPI } from "@/api";
import ConfirmDialog from "@/components/utils/ConfirmDialog.vue";

export default {
  name: 'CloseNotificationSetting',

  components: {ConfirmDialog, DentcoAlertBar, GuideTour},

  data() {
    return {
      pageLoading: false,
      loading: false,
      focus: '',
      closedDates: [],
      ocClosedDates: [],
      isEdit: false,
      toggleConfirmDialog: false,
      confirmTitle: '',
      confirmContent: '',
      confirmText: '',
      cancelText: '',
      confirmColor: null,
      cancelColor: null,
      confirmFunc: null,
      cancelFunc: null
    }
  },

  computed: {
    ...mapGetters([
      'axiosParamData',
      'clinicUser',
      'hasOwnChannel'
    ])
  },

  mounted() {
    this.getChannelInfo()
    this.getRemindSettingClose()
  },

  beforeRouteLeave(to, from, next) {
    if (this.isEdit) {
      this.to = to
      this.onLeaveWithoutSave(next)
    } else {
      next()
    }
  },

  methods: {
    ...mapMutations({
      setOwnChannel: 'setOwnChannel'
    }),
    getChannelInfo() {
      this.pageLoading = true
      getMessageSettingAPI(this.axiosParamData).then(res => {
        this.setOwnChannel(res.data.own_channel)
        this.pageLoading = false
      })
    },
    setToday() {
      this.focus = ''
    },
    prev() {
      this.$refs.calendar.prev()
    },
    next() {
      this.$refs.calendar.next()
    },
    onLeaveWithoutSave(next) {
      this.confirmTitle = '尚未儲存，確定要離開嗎？'
      this.confirmContent = '請確認內容是否編輯完成'
      this.confirmText = '確定不儲存'
      this.cancelText = '繼續編輯'
      this.confirmColor = 'primary'
      this.cancelColor = 'grey-4'
      this.confirmFunc = (payload) => {
        const { callback } = payload;
        callback && callback()
        next()
      }
      this.toggleConfirmDialog = true
    },
    onClosePast() {
      this.confirmTitle = '操作失敗'
      this.confirmContent = '此日期的通知已送出，無法關閉'
      this.confirmText = '我知道了'
      this.cancelText = ''
      this.confirmColor = 'grey-4'
      this.confirmFunc = (payload) => {
        const { callback } = payload;
        callback && callback()
      }
      this.toggleConfirmDialog = true
    },
    toggleClosed(date) {
      if (!this.isEdit) return
      console.log(date)
      if (date.past) {
        this.onClosePast()
        return
      }
      if (this.closedDates.includes(date.date)) {
        this.closedDates = this.closedDates.filter(d => d !== date.date)
      } else {
        this.closedDates.push(date.date)
      }
    },
    saveClosed(payload) {
      const { callback } = payload;
      this.loading = true
      setRemindSettingCloseAPI({...this.axiosParamData, dates: this.closedDates}).then(() => {
        this.getRemindSettingClose()
        this.isEdit = false
        callback && callback()
      })
    },
    onConfirm() {
      this.confirmTitle = '請注意！'
      this.confirmContent = '請留意目前的時間，若有看診提醒已發出，是來不及收回的唷！\n可至左側「訊息設定」頁面查看發送時間'
      this.confirmText = '確定關閉提醒'
      this.cancelText = '取消'
      this.confirmColor = 'red-6'
      this.cancelColor = 'grey-7'
      this.confirmFunc = this.saveClosed
      this.toggleConfirmDialog = true
    },
    onCancel() {
      this.closedDates = JSON.parse(JSON.stringify(this.ocClosedDates))
      this.isEdit = false
    },
    onEdit() {
      this.ocClosedDates = JSON.parse(JSON.stringify(this.closedDates))
      this.isEdit = true
    },
    getRemindSettingClose() {
      this.loading = true
      getRemindSettingCloseAPI(this.axiosParamData).then(res => {
        this.closedDates = res.data?.dates || []

        this.loading = false
      })
    }
  }
};
</script>

<style scoped>
/* Your styles here */
</style>
