<template>
  <div>
    <v-overlay :value="loading">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
    <v-container style="max-width: 1185px" class="pb-5">
      <guide-tour config-key="review.overview"/>
      <v-col cols="12">
        <v-row no-gutters dense style="gap: 10px 0">
          <v-col cols="12">
            <v-subheader class="text-subtitle-1">{{ $t('Review.reviewFilter') }}</v-subheader>
          </v-col>
          <v-col cols="12" sm="3" class="px-4">
            <v-select
              v-if="clinicUser"
              v-model="subSelect"
              :items="subItems"
              :label="$t('Review.doctor')"
              item-value="doctor_code"
              item-text="doctor_name"
              hide-details
              outlined
              dense
            />
            <v-select
              v-else
              v-model="subSelect"
              :items="subItems"
              :label="$t('Review.clinic')"
              item-value="clinic_code"
              item-text="clinic_name"
              hide-details
              outlined
              dense
            />
          </v-col>
          <v-col cols="12" sm="3" class="px-4">
            <v-dialog ref="dialog" v-model="modal" persistent width="290px">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="confirmMonth"
                  label="月份篩選"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  hide-details
                  dense
                  clearable
                />
              </template>
              <v-date-picker
                v-model="selectMonth"
                type="month"
                locale="zh-TW"
                scrollable
              >
                <v-spacer/>
                <v-btn text color="primary" @click="modal = false">{{ $t('GENERAL.cancel') }}</v-btn>
                <v-btn text color="primary" @click="confirm">ok</v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
          <v-col cols="12" sm="3" class="px-4">
            <v-select
              v-model="selectTreatment"
              :items="treatmentItems"
              item-value="id"
              item-text="name"
              :label="$t('Review.treatmentItem')"
              hide-details
              outlined
              dense
            />
          </v-col>
          <v-col cols="12" sm="3" class="px-4">
            <v-select
              v-model="selectSorted"
              :items="sortedItems"
              :label="$t('Review.order')"
              hide-details
              outlined
              dense
            />
          </v-col>
        </v-row>
        <v-row v-if="overview" no-gutters dense class="mt-6 mb-3" style="gap: 10px 0">
          <v-col cols="12" sm="6" lg="3" class="text-center ">
            <div class="text-h2 font-weight-bold">
              {{ overview.total.star }}
            </div>
            <v-rating
              half-increments
              readonly
              length="5"
              large
              color="yellow darken-2"
              :value="ceilToPoint(overview.total.star)"
            />
            <div><span class="text-h5">{{ overview.total.count }}</span> {{ $t('Review.reviewCount') }}</div>
          </v-col>
          <v-col cols="12" sm="6" lg="3" :style="$vuetify.breakpoint.lgAndUp ? 'order: 4' : null">
            <v-row v-for="(rating, key, i) in overview.star" :key="i" no-gutters dense
                   class="flex-nowrap justify-center" style="gap: 2px">
              <v-col cols="auto">
                <span class="text-h6">{{ rating }}</span> {{ key }}
              </v-col>
              <v-col cols="auto">
                <v-rating
                  half-increments
                  readonly
                  length="5"
                  :value="ceilToPoint(rating)"
                  dense
                  color="yellow darken-2"
                  class="ma-auto"
                  style="line-height: 30px"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="6" lg="3" class="px-1">
            <v-row v-for="(value, key, i) in overview.recommend" :key="i" no-gutters dense
                   class="flex-nowrap justify-center" style="gap: 2px">
              <v-col cols="6" class="text-end">
                {{ key }}
              </v-col>
              <v-col cols="6" class="align-self-center">
                <v-sheet :width="calcLength(value, maxPositive)" height="20" color="yellow darken-2"
                         class="d-inline-block" style="vertical-align: sub"/>
                <span>{{ value }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="6" lg="3" class="px-1">
            <v-row v-for="(value, key, i) in overview.negative" :key="i" no-gutters dense
                   class="flex-nowrap justify-center" style="gap: 2px">
              <v-col cols="6" class="text-end">
                {{ key }}
              </v-col>
              <v-col cols="6" class="align-self-center">
                <v-sheet :width="calcLength(value, maxNegative)" height="20" color="deep-orange lighten-1"
                         class="d-inline-block" style="vertical-align: sub"/>
                <span>{{ value }}</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <show-a-i-response-setting v-if="userInfo" :userInfo="userInfo"/>
        <v-divider class="mt-4"/>
        <v-tabs v-model="tabs" id="review-list">
          <v-tab>{{ $t('Review.allReview') }}({{ overview ? overview.total.count : 0 }})</v-tab>
          <v-tab>{{ $t('Review.reviewWithMessage') }}({{ overview ? overview.total.message : 0 }})</v-tab>
          <v-tab>{{ $t('Review.negativeReview') }}({{ overview ? overview.total.negative : 0 }})</v-tab>
        </v-tabs>
        <div>
          <template v-if="reviews" v-for="review in reviews">
            <review-detail-card
              :key="review.id"
              :review="review"
              :ceilToPoint="ceilToPoint"
              :userInfo="userInfo"
              @refresh="getReviews"
            />
            <v-divider/>
          </template>
        </div>
        <v-pagination
          v-model="page"
          class="my-4"
          :length="maxPage"
          total-visible="10"
        ></v-pagination>
      </v-col>
    </v-container>
  </div>
</template>

<script>
import Navigation from "@/components/Navigation.vue";
import {
  getClinicListAPI,
  getDoctorListAPI, getInfoAPI,
  getReviewOverviewAPI,
  getReviewsAPI,
  getReviewTreatmentSAPI,
} from "@/api";
import { mapGetters } from "vuex";
import ReviewDetailCard from "@/components/review/ReviewDetailCard.vue";
import GuideTour from "@/components/utils/GuideTour.vue";
import ShowAIResponseSetting from "@/pages/review/ShowAIResponseSetting.vue";
import { ceilToPoint } from "@/utils/tool";

export default {
  name: "ReviewOverview",

  components: {ShowAIResponseSetting, GuideTour, ReviewDetailCard, Navigation},

  data() {
    return {
      userInfo: null,
      subSelect: null,
      subItems: [],
      selectTreatment: null,
      treatmentItems: [{id: null, name: this.$t('Review.all')}],
      selectSorted: 0,
      sortedItems: [
        {text: this.$t('Review.latest'), value: 0},
        {text: this.$t('Review.gradDESC'), value: 1},
        {text: this.$t('Review.gradASC'), value: 2},
      ],
      overview: null,
      reviews: null,
      page: 1,
      limit: 10,
      maxPage: 1,
      tabs: 0,
      loading: true,
      selectMonth: null,
      confirmMonth: null,
      modal: false,
    }
  },
  computed: {
    ...mapGetters([
      'axiosParamData',
      'clinicUser',
      'doctorUser',
      'liffUser'
    ]),
    maxPositive() {
      let arr = Object.values(this.overview.recommend)
      return Math.max(...arr)
    },
    maxNegative() {
      let arr = Object.values(this.overview.negative)
      return Math.max(...arr)
    }
  },
  watch: {
    subSelect() {
      this.getOverview()
      if (this.page === 1) {
        this.getReviews()
      }
      this.page = 1
    },
    page(v) {
      if (v === 1)
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
      else
        document.getElementById('review-list').scrollIntoView({behavior: 'smooth', block: 'center'})
      this.getReviews()
    },
    selectSorted() {
      if (this.page === 1) {
        this.getReviews()
      }
      this.page = 1
    },
    confirmMonth() {
      this.getOverview()
      if (this.page === 1) {
        this.getReviews()
      }
      this.page = 1
    },
    tabs() {
      if (this.page === 1) {
        this.getReviews()
      }
      this.page = 1
    },
    selectTreatment() {
      this.getOverview()
      if (this.page === 1) {
        this.getReviews()
      }
      this.page = 1
    }
  },
  mounted() {
    if (this.clinicUser) {
      getDoctorListAPI(this.axiosParamData).then(res => {
        const subItems = res.data
        subItems.unshift({doctor_code: null, doctor_name: this.$t('Review.allDoctor')})
        this.subItems = subItems
      })
    } else {
      getClinicListAPI(this.axiosParamData).then(res => {
        const subItems = res.data
        subItems.unshift({clinic_code: null, clinic_name: this.$t('Review.allClinic')})
        this.subItems = subItems
      })
    }
    getReviewTreatmentSAPI(this.axiosParamData).then(res => {
      this.treatmentItems.push(...res.data)
    })
    this.getPermissionsInfo()
    this.getOverview()
    this.getReviews()
  },
  methods: {
    ceilToPoint,
    getPermissionsInfo() {
      getInfoAPI(this.axiosParamData).then(res => {
        this.userInfo = res.data
      })
    },
    calcLength(n, max) {
      return n / max * 80 + '%'
    },
    getOverview() {
      const params = {
        ...this.axiosParamData,
        item: this.selectTreatment,
      }
      if (this.clinicUser) params.doctor_code = this.subSelect
      else params.clinic_code = this.subSelect

      if (this.confirmMonth) params.month = this.confirmMonth
      getReviewOverviewAPI(params).then(res => {
        if (Array.isArray(res.data.data)) {
          this.overview = null
        } else {
          this.overview = res.data.data
        }
      })
    },
    getReviews() {
      this.loading = true
      const params = {
        ...this.axiosParamData,
        page: this.page,
        limit: this.limit,
        item: this.selectTreatment,
        sort: this.selectSorted,
        kind: this.tabs,
      }
      if (this.clinicUser) params.doctor_code = this.subSelect
      else params.clinic_code = this.subSelect
      if (this.confirmMonth) params.month = this.confirmMonth
      getReviewsAPI(params).then(res => {
        this.reviews = res.data.data
        this.maxPage = parseInt(res.data.paginate.page_total)
      }).finally(() => {
        this.loading = false
      })
    },
    confirm() {
      this.confirmMonth = this.selectMonth
      this.modal = false
    }
  }
}
</script>

<style scoped>

</style>
