<template>
  <v-card outlined flat height="calc(100vh - 320px)" class="mt-12 px-4 py-10 overflow-y-auto">
    <v-container>
      <v-row style="row-gap: 12px;">
        <v-col v-for="ai in aiVersions" :key="ai.id" cols="12" sm="6" md="3" class="text-center">
          <div :style="isActive(ai.id) ? null : 'cursor: pointer;'"
               @click="isActive(ai.id) ? undefined : handleClickAi(ai.id)">
            <div style="width: 136px; height: 136px;" class="mx-auto">
              <v-img max-height="136" max-width="136" class="mx-auto" :src="assetURL(`ai/${ai.image_url}`)" style="position: absolute"/>
            </div>
            <v-radio-group :value="selectAiVersion" hide-details>
              <v-radio
                readonly
                :disabled="isActive(ai.id)"
                :value="ai.id"
                :label="ai.name"
                style="width: fit-content"
                class="mx-auto"
              />
            </v-radio-group>
          </div>
          <div class="mt-2 grey-7--text" v-html="ai.description"/>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="confirmDialog" max-width="500">
      <v-card class="pa-4">
        <v-card-title class="justify-center text-center" v-html="confirmTitle"/>
        <v-card-actions class="mt-2">
          <v-btn
            color="grey-4"
            dark
            width="45%"
            @click="confirmDialog = false"
          >取消
          </v-btn>
          <v-spacer/>
          <v-btn
            color="primary"
            width="45%"
            @click="changeAi"
          >確認
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="promptDialog" max-width="500">
      <v-card class="pa-4">
        <v-card-title class="justify-center text-center">
            客製化AI 助理
        </v-card-title>
        <v-card-text>
          <v-textarea
            v-model="editPrompt"
            rows="3"
            outlined
            no-resize
            label="個性/能力 (限制250字內)"
            placeholder="請輸入想要怎麼樣的AI助理（250字內）"
            :rules="[v => countWords(v) <= 250 || '長度需小於250字']"
          />
        </v-card-text>
        <v-card-actions class="mt-2">
          <v-btn
            color="grey-4"
            dark
            width="45%"
            @click="promptDialog = false"
          >取消
          </v-btn>
          <v-spacer/>
          <v-btn
            color="primary"
            width="45%"
            :disabled="countWords(editPrompt) > 250"
            @click="onClickChangeCustomAi"
          >儲存並套用
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar"
      centered
      timeout="2000"
      color="tiffany-10"
    >
      {{ snackbarTitle }}
    </v-snackbar>
  </v-card>
</template>

<script>
import { changeAIVersionsAPI, getAIVersionsAPI, getCurrentMedicalAIInfoAPI } from '../../api';
import { mapGetters } from 'vuex';
import { assetURL } from '../../utils/filters';

export default {
  name: "AIServiceVersion",

  props: {
    medicalCode: {
      type: String
    }
  },

  data() {
    return {
      assetURL,
      aiVersions: [],
      selectAiVersion: null,
      newSelectAi: null,

      confirmDialog: false,
      confirmTitle: '',
      promptDialog: false,
      editPrompt: '',

      snackbar: false,
      snackbarTitle: null
    }
  },

  computed: {
    ...mapGetters([
      'axiosParamData',
      'clinicUser'
    ])
  },

  methods: {
    isActive(id) {
      if (Number(id) === 3) return false
      return this.selectAiVersion === id
    },
    handleClickCustomAi() {
      this.promptDialog = true
    },
    handleClickAi(newV) {
      this.newSelectAi = this.aiVersions.find(e => e.id === newV)
      if (newV === 3) {
        return this.handleClickCustomAi()
      }
      this.confirmTitle = `請再次確認是否需要切換為<br>[${this.newSelectAi.name}] 助理`
      this.confirmDialog = true
    },
    countWords(str) {
      return str.length
    },
    onClickChangeCustomAi() {
      if (this.countWords(this.editPrompt) > 250) {
        this.snackbarTitle = '超過250字'
        this.snackbar = true
        return
      }
      this.changeAi()
    },
    changeAi() {
      const data = {
        ...this.axiosParamData,
        default_bot_id: this.newSelectAi.id
      }
      if (this.newSelectAi.id === 3) {
        data.custom_prompt = this.editPrompt
      }
      changeAIVersionsAPI(this.medicalCode, data).then(() => {
        this.getCurrentAIVersion()
        this.snackbarTitle = '切換成功'
        this.snackbar = true
        this.confirmDialog = false
        this.promptDialog = false
      })
    },
    getAIList() {
      getAIVersionsAPI(this.axiosParamData).then(res => {
        this.aiVersions = res.data.data.bots
      })
    },
    getCurrentAIVersion() {
      const params = {
        medical_type: this.clinicUser ? 'clinic' : 'doctor',
        medical_id: this.clinicUser ? this.axiosParamData.clinic_code : this.axiosParamData.doctor_code
      }
      getCurrentMedicalAIInfoAPI(params).then(res => {
        this.$emit('update:medicalCode', res.data.data.medical.id)
        this.selectAiVersion = res.data.data.medical.default_bot_id
        this.editPrompt = res.data.data.medical.custom_prompt ? res.data.data.medical.custom_prompt.prompt : ''
      })
    },
    getData() {
      this.getAIList()
      this.getCurrentAIVersion()
    }
  },

  mounted() {
    this.getData()
  }
}
</script>

<style scoped>

</style>
