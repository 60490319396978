<template>
  <v-card height="100%">
    <v-overlay :value="loading" absolute>
      <v-progress-circular indeterminate size="64"/>
    </v-overlay>

    <v-card-title>
      {{ title }}
    </v-card-title>

    <v-card-text style="height: calc(100% - 64px); max-height: 400px;">
      <pie-chart
        v-if="!isNoData"
        :chart-data="data"
        class="mx-auto"
        style="max-width: 400px;"
      />
      <v-card
        v-else
        flat
        height="100%"
        class="d-flex justify-center align-center text-h6 py-8 grey-7--text"
      >
        {{ noDataText }}
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script>
import PieChart from "../PieChart";
import { getAnalysisMessengerAccountAPI } from "../../api";
import { mapGetters } from "vuex";

export default {
  name: "PatientPieAnalysis",

  props: {
    title: {
      type: String,
      required: true
    },
    data: {
      type: Object,
      required: false,
      default: null
    },
    noDataText: {
      type: String,
      required: false,
      default: '尚無綁定記錄'
    }
  },

  components: {PieChart},

  computed: {
    isNoData() {
      if (this.data) {
        return this.data.datasets[0].data.every(e => Number(e) === 0)
      }
    },
    loading() {
      return !this.data
    }
  }
}
</script>

<style scoped>

</style>
