<template>
  <v-container fluid class="pa-0" style="height: 100%; max-height: 100%;">
    <v-row no-gutters style="height: 100%;">
      <v-col
        cols="12"
        md="2"
        xl="1"
        class="overflow-y-auto pa-2"
        :style="$vuetify.breakpoint.mdAndUp ? 'height: calc(100vh - 112px);' : ''"
      >
        <v-tabs
          v-model="selectedItem"
          :vertical="$vuetify.breakpoint.mdAndUp"
          :show-arrows="$vuetify.breakpoint.smAndDown"
        >
          <v-tab
            v-for="tab in tabItems"
            :key="tab.code"
            :href="`#${tab.code}`"
            class="px-md-12 justify-start"
          >
            {{ tab.name }}
          </v-tab>
        </v-tabs>
      </v-col>

      <v-col
        cols="12"
        md="10"
        xl="11"
        class="pt-1"
      >
        <v-card
          flat
          class="overflow-y-auto px-md-6"
          :height="$vuetify.breakpoint.mdAndUp ? 'calc(100vh - 116px)' : 'calc(100vh - 180px)'"
          :max-height="$vuetify.breakpoint.mdAndUp ? 'calc(100vh - 116px)' : 'calc(100vh - 180px)'"
          color="grey-8"
        >
          <v-card
            flat
            max-width="1680"
            class="px-md-6"
            color="grey-8"
          >
            <div
              :class="{'mt-3': $vuetify.breakpoint.mdAndUp}"
            >
              <div class="d-flex flex-wrap">
                <div :style="$vuetify.breakpoint.mdAndUp ? 'width: auto; max-width: 68%;' : 'width: 100%;'">
                  <guide-tour config-key="onlineAppointmentSetting"/>
                </div>
                <v-spacer/>
                <div
                  class="d-flex align-center px-4"
                  :style="$vuetify.breakpoint.mdAndUp ? 'width: auto;' : 'width: 100%;'"
                  :class="{'pb-3': $vuetify.breakpoint.smAndDown}"
                >
                  <v-btn
                    v-if="selectedItem"
                    :href="websiteUrl()"
                    target="_blank"
                    block
                    color="primary"
                  >{{ clinicUser ? '查看診所目前預約網頁' : '查看醫師目前預約網頁' }}</v-btn>
                </div>
              </div>
            </div>
            <v-card-text>
              <v-container fluid>
                <v-row>
                  <v-col cols="12" md="4">
                    <mode-choice
                      v-if="selectedItem"
                      :setting-code="selectedItem"
                      :card-text-style="cardTextStyle"
                      :client-permissions="clientPermissions"
                    />
                  </v-col>
                  <v-col cols="12" md="8">
                    <setting-detail
                      v-if="selectedItem"
                      :setting-code="selectedItem"
                      :card-text-style="cardTextStyle"
                      :client-permissions="clientPermissions"
                    />
                  </v-col>
                  <v-col cols="12">
                    <manual-setting
                      v-if="selectedItem && canShow([3], clientPermissions, modeSetting)"
                      :setting-code="selectedItem"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Navigation from "@/components/Navigation.vue";
import { mapGetters } from "vuex";
import ModeChoice from "@/components/OnlineAppointmentsSetting/ModeChoice.vue";
import { getClinicListAPI, getDoctorListAPI, getInfoAPI } from "@/api";
import SettingDetail from "@/components/OnlineAppointmentsSetting/SettingDetail.vue";
import ManualSetting from "@/components/OnlineAppointmentsSetting/ManualSetting.vue";
import { canShow } from "@/utils/tool";
import GuideTour from "@/components/utils/GuideTour.vue";

export default {
  name: "FreeTime",

  components: {GuideTour, ManualSetting, SettingDetail, ModeChoice, Navigation},

  data() {
    return {
      selectedItem: null,
      tabItems: [],
      info: null,
      clientPermissions: [],
      canShow
    }
  },

  computed: {
    ...mapGetters([
      'liffUser',
      'clinicUser',
      'axiosParamData',
      'modeSetting',
    ]),
    cardTextStyle() {
      if (this.$vuetify.breakpoint.mdAndUp) {
        return `min-height: 366px; height: 366px; max-height: 366px; gap: 26px;`
      } else {
        return `gap: 18px;`
      }
    },
  },

  watch: {
    selectedItem: {
      async handler(v) {
        if (v) {
          if (!this.info) await this.getInfo()
          this.setClientPermission()
        }
      }
    }
  },

  methods: {
    setClientPermission() {
      let key
      if (this.clinicUser) {
        key = 'doctors'
      } else {
        key = 'clinics'
      }
      const secObj = this.info[key].find(sub => sub.code === this.selectedItem)
      this.clientPermissions = [...this.info.permission_name, ...secObj.permission_name]
    },
    async getInfo() {
      const res = await getInfoAPI(this.axiosParamData)
      this.info = res.data
    },
    websiteUrl() {
      let doctorCode, clinicCode
      if (this.clinicUser) {
        doctorCode = this.selectedItem
        clinicCode = this.axiosParamData.clinic_code
        return `https://www.dentco.tw/appointment/clinic/${clinicCode}?type=clinic&doctorCode=${doctorCode}`
      } else {
        doctorCode = this.axiosParamData.doctor_code
        clinicCode = this.selectedItem
        return `https://www.dentco.tw/appointment/doctor/${doctorCode}?type=doctor&clinicCode=${clinicCode}`
      }
    }
  },

  mounted() {
    if (this.clinicUser) {
      getDoctorListAPI(this.axiosParamData).then(res => {
        this.tabItems = res.data.map(e => {
          return {
            name: e.doctor_name,
            code: e.doctor_code
          }
        })
      })
    } else {
      getClinicListAPI(this.axiosParamData).then(res => {
        this.tabItems = res.data.map(e => {
          return {
            name: e.clinic_name,
            code: e.clinic_code
          }
        })
      })
    }
  }
}
</script>

<style scoped>

</style>