<template>
  <v-main>
    <navigation/>
    <Mypatient />
  </v-main>
</template>

<script>
import { mapGetters } from 'vuex';
import Navigation from '../components/Navigation';
import Mypatient from '../components/patients/Mypatient';

export default {
  name: "Patients",

  components: { Mypatient, Navigation },

  data() {
    return {
      tab: 0
    }
  },

  computed: {
    ...mapGetters([
      'axiosParamData',
    ])
  },

  mounted() {

  }
}
</script>

<style scoped>

</style>