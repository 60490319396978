<template>
  <v-container fluid class="opening-table">
    <v-row dense>
      <v-col cols="12">
        <v-row dense class="flex-nowrap table">
          <v-col :cols="12/8"/>
          <v-col v-for="(day, i) in weeks" :key="i" :cols="12/8">{{ day }}</v-col>
        </v-row>
        <v-row v-for="(v, k, i) in daySegment" :key="i" dense class="flex-nowrap table">
          <v-col :cols="12/8">{{ v }}</v-col>
          <v-col
            v-if="openingDate"
            v-for="n in 7"
            :key="n"
            :cols="12/8"
            :class="{'opening-content': edit, 'open': openingDate[n-1][k]}"
            @click="edit ? openingDate[n-1][k] = !openingDate[n-1][k] : null"
          />
        </v-row>
      </v-col>
      <v-col v-if="time" cols="12" class="mt-2">
        <v-row dense>
          <v-col cols="2">{{ $t('Opening.morningBH') }}</v-col>
          <v-col v-if="openingTime" cols="auto">
            <edit-time v-if="edit" :times="openingTime['morning']"/>
            <template v-else>{{ openingTime['morning'][0] }} ~ {{ openingTime['morning'][1] }}</template>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="2">{{ $t('Opening.afternoonBH') }}</v-col>
          <v-col v-if="openingTime" cols="auto">
            <edit-time v-if="edit" :times="openingTime['afternoon']"/>
            <template v-else>{{ openingTime['afternoon'][0] }} ~ {{ openingTime['afternoon'][1] }}</template>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="2">{{ $t('Opening.nightBH') }}</v-col>
          <v-col v-if="openingTime" cols="auto">
            <edit-time v-if="edit" :times="openingTime['evening']"/>
            <template v-else>{{ openingTime['evening'][0] }} ~ {{ openingTime['evening'][1] }}</template>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import EditTime from "./EditTime";

export default {
  name: "Opening",
  components: {EditTime},
  // props: ['edit', 'date', 'time'],
  props: {
    edit: Boolean,
    date: Array,
    time: [Object, Boolean],
  },
  data() {
    return {
      weeks: [
        this.$t('Opening.Mon'), this.$t('Opening.Tues'), this.$t('Opening.Wed'),
        this.$t('Opening.Thur'), this.$t('Opening.Fri'), this.$t('Opening.Sat'),
        this.$t('Opening.Sun'),
      ],
      daySegment: {
        up: this.$t('Opening.morning'),
        middle: this.$t('Opening.afternoon'),
        down: this.$t('Opening.night')
      },
      openingDate: null,
      openingTime: null
    }
  },
  watch: {
    date: {
      handler: function (val, oldVal) {
        this.reset()
      },
      deep: true
    },
    time: {
      handler: function (val, oldVal) {
        this.reset()
      },
      deep: true
    }
  },
  methods: {
    reset() {
      const d = JSON.parse(JSON.stringify(this.date))
      d.push(d.splice(0, 1)[0])
      this.openingDate = d

      if (this.time)
        this.openingTime = JSON.parse(JSON.stringify(this.time))
    }
  },
  mounted() {
    this.reset()
  }
}
</script>

<style lang="scss" scoped>
.opening-table div.table {
  div {
    border-top: 1px solid black;
    border-left: 1px solid black;

    &.opening-content {
      cursor: pointer;
    }

    &.open {
      background-color: yellow;
    }

    &:last-child {
      border-right: 1px solid black;
    }
  }

  &:last-child {
    border-bottom: 1px solid black;
  }
}

//.table div {
//  border-top: 1px solid black;
//  border-left: 1px solid black;
//
//  &.opening-content {
//    cursor: pointer;
//  }
//
//  &:last-child {
//    border-right: 1px solid black;
//  }
//}
</style>