<template>
  <v-card color="white" :loading="loading" :disabled="loading">
    <v-card-title class="justify-center" style="background-color: #5E676E;">
      <div class="white--text">
        <div class="text-center">細節設定</div>
        <div class="text-center text-body-2">（下滑設定更多內容）</div>
      </div>
    </v-card-title>

    <v-card-text class="py-8 overflow-y-auto d-flex flex-column" :style="cardTextStyle">
      <div v-show="canShow([2, 3], clientPermissions, modeSetting)">
        <div
          class="bullet text-subtitle-1 black--text"
          v-html="$t('FreeTimeSetting.whetherOpenOnline')"
        />
        <v-row no-gutters dense style="gap: 0 10px">
          <v-col cols="12" sm="auto" md="auto">
            <v-checkbox
              :input-value="detailSetting.current_appointment_status"
              true-value="1"
              false-value="0"
              hide-details
              dense
              class="ma-0"
              @change="save('current_appointment_status', $event)"
            >
              <template v-slot:label>
                <div v-html="$t('FreeTimeSetting.openCurrentDay')"/>
              </template>
            </v-checkbox>
          </v-col>
          <v-col cols="12" sm="auto" md="auto">
            <v-checkbox
              :input-value="detailSetting.tomorrow_appointment_status"
              true-value="1"
              false-value="0"
              hide-details
              dense
              class="ma-0"
              @change="save('tomorrow_appointment_status', $event)"
            >
              <template v-slot:label>
                <div v-html="$t('FreeTimeSetting.openTomorrow')"/>
              </template>
            </v-checkbox>
          </v-col>
        </v-row>
      </div>

      <div v-show="canShow([2, 3], clientPermissions, modeSetting)">
        <div
          class="bullet text-subtitle-1 black--text"
          v-html="$t('FreeTimeSetting.freeTimeGapMode')"
        />
        <v-row no-gutters dense style="gap: 0 10px">
          <v-col cols="12" sm="auto" md="auto">
            <v-radio-group
              row
              class="mt-0"
              hide-details
              :value="detailSetting.free_time_gap"
              @change="save('free_time_gap', $event)"
            >
              <v-radio
                :label="$t('FreeTimeSetting.thirtyMinutes')"
                value="1"
              ></v-radio>
              <v-radio
                :label="$t('FreeTimeSetting.fifteenMinutes')"
                value="2"
              ></v-radio>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                {{ $t('FreeTimeSetting.freeTimeGapTooltip') }}
              </v-tooltip>
            </v-radio-group>
          </v-col>
        </v-row>
      </div>

      <div v-show="canShow([1, 2, 3], clientPermissions, modeSetting)">
        <div class="bullet text-subtitle-1 black--text">
          預約注意事項
          <v-dialog v-model="editBookingNoteDialog" max-width="400">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                @click="editBookingNoteContent = detailSetting.booking_note"
              >mdi-pencil
              </v-icon>
            </template>
            <v-card>
              <v-card-title>
                編輯預約注意事項
              </v-card-title>
              <v-card-text>
                <v-textarea
                  v-model="editBookingNoteContent"
                  hide-details
                  outlined
                  rows="4"
                  no-resize
                  full-width
                  clearable
                />
              </v-card-text>
              <v-card-actions>
                <v-spacer/>
                <v-btn color="primary" @click="save('booking_note', editBookingNoteContent)">確認</v-btn>
                <v-btn color="grey-4" @click="editBookingNoteDialog = false">取消</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
        <div>
          <v-sheet outlined class="pa-2 text--darken-1 grey--text">
            <div class="text-pre-line">{{ detailSetting.booking_note || '無' }}</div>
          </v-sheet>
        </div>
      </div>

      <div v-show="canShow([1, 2, 3], clientPermissions, modeSetting)">
        <div class="bullet text-subtitle-1 black--text">
          預約模式
        </div>
        <div class="d-flex flex-wrap">
          <div v-show="canShow([3], clientPermissions, modeSetting)">
            <v-checkbox
              :input-value="detailSetting.smart_mode_status"
              true-value="1"
              false-value="0"
              hide-details
              dense
              class="ma-0"
              @change="save('smart_mode_status', $event)"
            >
              <template v-slot:label>
                {{ $t('FreeTimeSetting.smartMode') }}
              </template>
              <template v-slot:append>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on">
                      mdi-help-circle-outline
                    </v-icon>
                  </template>
                  {{ $t('FreeTimeSetting.smartTooltip') }}
                </v-tooltip>
              </template>
            </v-checkbox>
          </div>
          <div>
            <v-checkbox
              :input-value="detailSetting.single_unprocessed_appointment_status"
              true-value="1"
              false-value="0"
              hide-details
              dense
              class="ma-0"
              label="限制患者只能預約此位醫師一筆線上預約"
              @change="save('single_unprocessed_appointment_status', $event)"
            />
          </div>
        </div>
      </div>

      <div v-show="canShow([2, 3], clientPermissions, modeSetting)">
        <div class="bullet text-subtitle-1 black--text">
          患者可預約的日期範圍
        </div>
        <div class="d-flex  align-center" style="gap: 4px;">
          <div>開放</div>
          <v-select
            v-if="editMonthCount"
            v-model="monthsCount"
            hide-details
            dense
            outlined
            style="max-width: 80px;"
            :items="[1, 2, 3, 4, 5, 6, 7]"
          />
          <div v-else class="text-decoration-underline">{{ detailSetting.months_count }}</div>
          <div>個月內的可預約時段</div>
          <v-btn
            v-if="editMonthCount"
            color="red-6"
            dark
            small
            class="ml-2"
            @click="save('months_count', monthsCount)"
          >確認儲存
          </v-btn>
          <v-btn
            v-else
            icon
            @click="monthsCount = detailSetting.months_count; editMonthCount = true"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </div>
      </div>

      <div v-show="canShow([-100.1, -100.2, -100.9], clientPermissions, modeSetting)">
        <div
          class="bullet text-subtitle-1 black--text"
        >
          加入LINE預約模式選擇
        </div>
        <v-radio-group
          v-model="guideToLineMode"
          class="mt-0"
          hide-details
          @change="checkHasChannel('guideToLine', $event, handleRadioChange)"
        >
          <div class="d-flex" :class="{'flex-wrap': $vuetify.breakpoint.xsOnly}" style="gap: 8px;">
            <v-radio
              label="請患者加入「診所」LINE"
              :value="1"
              class="my-0"
            />
            <v-radio
              label="請患者加入「醫師」LINE"
              :value="2"
              class="my-0"
            />
          </div>
          <div
            class="d-flex align-center mt-2"
            :class="{'flex-wrap': $vuetify.breakpoint.xsOnly}"
            style="gap: 8px;"
          >
            <v-radio
              label="自行輸入預約連結"
              :value="3"
              class="my-0"
            />
            <v-text-field
              ref="textReservePageFieldRef"
              :value="detailSetting.text_reserve_page"
              dense
              outlined
              :disabled="guideToLineMode !== 3"
              append-outer-icon="mdi-content-save-outline"
              hide-details
              style=" max-width: 350px;"
              :class="{'pl-6': $vuetify.breakpoint.xsOnly}"
              @click:append-outer="save('text_reserve_page', $refs.textReservePageFieldRef.internalValue)"
            />
          </div>
        </v-radio-group>
      </div>

      <div v-show="canShow([1, 2, 3], clientPermissions, modeSetting)">
        <div
          class="bullet text-subtitle-1 black--text"
        >
          引導加LINE
        </div>
        <v-radio-group
          :value="Number(detailSetting.appointment_completed_line_page_kind)"
          class="mt-0"
          hide-details
          @change="checkHasChannel('appointmentCompleted', $event, saveAppointmentCompletedLinePageKind)"
        >
          <div class="d-flex" :class="{'flex-wrap': $vuetify.breakpoint.xsOnly}" style="gap: 8px;">
            <v-radio
              label="引導加入「診所」LINE"
              :value="1"
              class="my-0"
            />
            <v-radio
              label="引導加入「醫師」LINE"
              :value="2"
              class="my-0"
            />
          </div>
          <div
            class="d-flex align-center mt-2"
            :class="{'flex-wrap': $vuetify.breakpoint.xsOnly}"
            style="gap: 8px;"
          >
            <v-radio
              label="自行輸入預約連結"
              :value="3"
              class="my-0"
            />
            <v-text-field
              ref="appointmentCompletedLinePageLinkFieldRef"
              :value="detailSetting.appointment_completed_line_page_link"
              dense
              outlined
              :disabled="Number(detailSetting.appointment_completed_line_page_kind) !== 3"
              append-outer-icon="mdi-content-save-outline"
              hide-details
              style=" max-width: 350px;"
              :class="{'pl-6': $vuetify.breakpoint.xsOnly}"
              @click:append-outer="save('appointment_completed_line_page_link', $refs.appointmentCompletedLinePageLinkFieldRef.internalValue)"
            />
          </div>
        </v-radio-group>
      </div>

      <v-dialog v-model="checkChannelDialog" max-width="300">
        <v-card>
          <v-card-title>
            <div>
              您尚未串接LINE官方帳號，請洽 <a :href="$enum.DENTCO_CS_LINE" target="_blank" class="link_blue--text">客服人員</a>
            </div>
          </v-card-title>
          <v-card-actions>
            <v-btn block color="primary" @click="checkChannelDialog = false">確認</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { updateFreeTimeDetailAPI } from "../../api";
import { canShow } from "../../utils/tool";

export default {
  name: "SettingDetail",

  props: {
    clientPermissions: {
      type: Array,
      required: true
    },
    settingCode: {
      type: String,
      required: true
    },
    cardTextStyle: {
      type: String,
      required: false
    }
  },

  data() {
    return {
      loading: false,
      editBookingNoteDialog: false,
      editBookingNoteContent: null,
      monthsCount: null,
      editMonthCount: false,
      guideToLineMode: null,
      guideToLineCondition: [-100.1, -100.2, -100.9],
      canShow,
      checkChannelDialog: false
    }
  },

  computed: {
    ...mapGetters([
      'clinicUser',
      'axiosParamData',
      'modeSetting',
      'detailSetting'
    ]),
  },

  watch: {
    settingCode: {
      handler(v) {
        const params = {
          ...this.axiosParamData
        }
        if (this.clinicUser)
          params.doctor_code = v
        else
          params.clinic_code = v
        this.getFreeTimeDetail(params)
      },
      immediate: true,
    },
    modeSetting: {
      handler() {
        this.setGuideToLineMode();
      },
      immediate: true,
      deep: true
    }
  },

  methods: {
    ...mapActions({
      getFreeTimeDetail: 'getFreeTimeDetail',
      getFreeTimeMode: 'getFreeTimeMode',
    }),
    save(key, value, refreshMode = false) {
      const data = {
        ...this.axiosParamData
      }
      data[key] = value
      if (this.clinicUser)
        data.doctor_code = this.settingCode
      else
        data.clinic_code = this.settingCode

      this.loading = true
      return updateFreeTimeDetailAPI(data).then(res => {
        this.editBookingNoteDialog = false
        this.editMonthCount = false

        const params = {
          ...this.axiosParamData
        }
        if (this.clinicUser)
          params.doctor_code = this.settingCode
        else
          params.clinic_code = this.settingCode

        this.$nextTick(async () => {
          this.loading = true
          if (refreshMode) await this.getFreeTimeMode(params)
          await this.getFreeTimeDetail(params)
          this.loading = false
        })
      }).catch(e => {
        console.error(e)
        alert('儲存失敗')
        this.loading = false
      }).finally(() => {
      })
    },

    handleRadioChange(newValue) {
      this.guideToLineMode = newValue

      this.save('direct_message_channel', newValue, true).then(() => {
      }).catch(error => {
        // 處理保存失敗的情況
        console.error("保存失敗：", error);
        this.setGuideToLineMode();
      }).finally(() => {
        // this.setGuideToLineMode();
      })
    },

    setGuideToLineMode() {
      function filterGuideToLineCondition(modeSetting, guideToLineCondition) {
        // 定義需要檢查的狀態鍵
        const keysToCheck = ["line_appointment_status", "web_appointment_status"];

        // 過濾出匹配 guideToLineCondition 的值
        const filteredValues = keysToCheck
          .filter(key => guideToLineCondition.includes(Number(modeSetting[key])))
          .map(key => Number(modeSetting[key]));

        if (filteredValues.length) {
          return filteredValues[0]
        }

        return null
      }

      const mode = filterGuideToLineCondition(this.modeSetting, this.guideToLineCondition)
      switch (mode) {
        case -100.1:
          this.guideToLineMode = 1
          break
        case -100.2:
          this.guideToLineMode = 2
          break
        case -100.9:
          this.guideToLineMode = 3
          break
        default:
          this.guideToLineMode = null
          break
      }
    },

    saveAppointmentCompletedLinePageKind(value) {
      this.save('appointment_completed_line_page_kind', value)
    },

    checkHasChannel(key, event, action) {
      const directMessageChannelMapping = {
        1: 'clinic_channel',
        2: 'doctor_channel'
      }
      if (key === 'appointmentCompleted') {
        delete directMessageChannelMapping[1]
      }
      if (!Object.keys(directMessageChannelMapping).includes(String(event)) || this.detailSetting[directMessageChannelMapping[event]]) {
        action(event);
      } else {
        this.checkChannelDialog = true
        this.$nextTick(() => {
          if (key === 'guideToLine')
            this.setGuideToLineMode()
          if (key === 'appointmentCompleted') {
            const tmp = Number(this.detailSetting.appointment_completed_line_page_kind)
            this.detailSetting.appointment_completed_line_page_kind = event
            this.$nextTick(() => {
              this.detailSetting.appointment_completed_line_page_kind = tmp
            })
          }
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
