import { aiApiUrl, apiUrl } from './tool';
import axios from 'axios';

const useMock = false

export function loginAPI(data) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "clinic_code": "0000000002",
        "doctor_code": "",
        "clinic_name": "測試診所",
        "doctor_name": "",
        "type": "1",
        "token": "f33b37cd843223bf85cce08e06ef44b612ef8fcd451e1c0dea8fa387682f3f5b",
        "message": "成功"
      }
    })
  }
  const url = apiUrl('login')
  return axios.post(url, data)
}

export function getReservesAPI(params, config = {}) {
  if (useMock) {
    return Promise.resolve({
      data: [
        {
          "reserve_no": "219310",
          "start_time": "1629856800",
          "end_time": "1629858600",
          "attend_status": "0",
          "note": "",
          "tel": "(02)89115812",
          "line_bot_is_verified": "0",
          "address": "123",
          "county": "桃園市",
          "sex": "9",
          "name": "Eric",
          "phone": "0917088950",
          "birthday": "",
          "file_name_photo": "https://profile.line-scdn.net/0m0056e4197251ba7115fee6274fb6e2bb2eb9bb0b2350",
          "clinic_name": "Dent&Co牙醫診所1",
          "clinic_code": "0000000002",
          "doctor_name": "小幫手2號",
          "doctor_code": "5WJynO2zWz7w",
          "agree": "",
        }
      ]
    })
  }
  const url = apiUrl('reserves')
  return axios.get(url, {params, ...config})
}

export function getReserveDetailAPI(id, params) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "reserve_no": "2092162",
        "start_time": "1630036800",
        "end_time": "1630037700",
        "attend_status": "0",
        "note": "test",
        "tel": "(02)89115812",
        "line_bot_is_verified": "1",
        "address": "",
        "county": "",
        "sex": "9",
        "name": "蔡友仁",
        "phone": "0922099195",
        "birthday": "570816000",
        "file_name_photo": "",
        "clinic_name": "Dent&Co牙醫診所1",
        "clinic_code": "0000000002",
        "doctor_name": "王醫師",
        "doctor_code": "eYt0LMKngllg",
        "agree": "1",
        "patient_code": "1f72ilba",
        "remind_cash": "1000",
        "remind_others": "帶一百塊",
        "appointment_count": "23",
        "change_count": "1",
        "stood_up_count": "0"
      }

    })
  }
  const url = apiUrl('reserves', id)
  return axios.get(url, {params})
}

export function printReserveExcel(params) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('reserves', 'download')
  return axios.get(url, {params})
}

export function searchPatientsAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: [
        {
          "patient_code": "xAqwDUiQ",
          "phone": "0932007170",
          "name": "黃顗瑄",
          "sex": "9",
          "birthday": ""
        },
        {
          "patient_code": "L5brrDpQ",
          "phone": "0988705260",
          "name": "黃韋翔",
          "sex": "9",
          "birthday": ""
        }
      ]
    })
  }
  const url = apiUrl('patients', 'search')
  return axios.get(url, {params})
}

export function getBlacklistsAPI(params) {
  if (useMock) {
    return Promise.resolve({"total":1,"data":[{"id":"28457","type":"1","clinic_code":"0000000088","doctor_code":null,"kind":"1","value":"0985617117","create_time":"2023-09-11 14:12:07","update_time":null,"birthday":"1987-12-17","patient_name":"\u5f35\u4fca\u9686,ken,\u5f35"}]})
  }
  const url = apiUrl('blacklist')
  return axios.get(url, {params})
}

export function addBlacklistAPI(data) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('blacklist')
  return axios.post(url, data)
}

export function deleteBlacklistAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('blacklist')
  return axios.delete(url, {params})
}

export function getPatientsAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "paginate": {
          "page": "1",
          "limit": "1",
          "page_total": "449",
          "total": "449"
        },
        "data": [
          {
            "patient_code": "xAqwDUiQ",
            "phone": "0932007170",
            "name": "黃顗瑄",
            "sex": "1",
            "birthday": "",
            "file_name_photo": "https://sprofile.line-scdn.net/0h2tTCNhXTbVt5OkVKZnwTJAlqbjFaSzRJUVtwbUs9NzsUWSgEU1Uhb0toNG9DWn4OVAkiPElvM2t1KRo9Z2yRb34KM2xHCSwLUFsjug",
            "line_bot_is_verified": "1",
            "agree": "1"
          }
        ]
      }
    })
  }
  const url = apiUrl('patients')
  return axios.get(url, {params})
}

export function exportPatientsAPI(data) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('patients', 'download')
  return axios.post(url, data)
}

export function getPatientInfoAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "patient_code": "1f72ilba",
        "phone": "0922099195",
        "name": "蔡友仁",
        "sex": "9",
        "birthday": "570816000",
        "file_name_photo": "",
        "line_bot_is_verified": "1",
        "agree": "1"
      }
    })
  }
  const url = apiUrl('patients', 'info')
  return axios.get(url, {params})
}

export function getPatientNoteAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "note": "2333"
      }
    })
  }
  const url = apiUrl('patients', 'note')
  return axios.get(url, {params})
}

export function updatePatientNoteAPI(data) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('patients', 'note')
  return axios.put(url, data)
}

export function countPatientsAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "total": 441
      }
    })
  }
  const url = apiUrl('patients', 'total')
  return axios.get(url, {params})
}

export function updatePatientAPI(code, data) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('patients', code)
  return axios.put(url, data)
}

export function getLineMembersAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "paginate": {
          "page": "1",
          "limit": "10",
          "page_total": "1",
          "total": "1"
        },
        "data": [
          {
            "photo": "https://xxx",
            "name": "蔡友仁",
            "phone": "0922099195",
            "email": "",
            "bind_status": "1",  //-1封鎖,0未綁定,1綁定
            "create_time": "2021-03-25 15:09:44"
          }
        ]
      }
    })
  }
  const url = apiUrl('members', 'line')
  return axios.get(url, {params})
}

export function countLineMembersAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "total": 441
      }
    })
  }
  const url = apiUrl('members', 'line', 'total')
  return axios.get(url, {params})
}

export function exportLineMembersAPI(data) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('members', 'line', 'download')
  return axios.post(url, data)
}

export function getMessengerMembersAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "paginate": {
          "page": "1",
          "limit": "1",
          "page_total": "6",
          "total": "6"
        },
        "data": [
          {
            "photo": "https://dentco.s3.us-east-2.amazonaws.com/asset/crm/avatar-unknow.png",
            "name": "",
            "phone": "0922099195",
            "email": "",
            "bind_status": "1",  //0未綁定,1綁定
            "create_time": "2023-04-11 16:33:14"
          }
        ]
      }
    })
  }
  const url = apiUrl('members', 'messenger')
  return axios.get(url, {params})
}

export function countBlackListsAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "total": 441
      }
    })
  }
  const url = apiUrl('blacklist')
  return axios.get(url, {params})
}

export function countMessengerMembersAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "total": 441
      }
    })
  }
  const url = apiUrl('members', 'messenger', 'total')
  return axios.get(url, {params})
}

export function exportMessengerMembersAPI(data) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('members', 'messenger', 'download')
  return axios.post(url, data)
}

export function getPreteethListsAPI(params) {
  if (useMock) {
    return Promise.resolve({
      "paginate":{
        "page":"1",
        "limit":"10",
        "page_total":"1",
        "total":"1"
      },
      "data":[
        {
          "line_bot_id":"U507fe8ac883f9b906b65f0ad606caff9",
          "name":"張俊隆",
          "phone":"0985617117",
          "questionnaire_type":"願意體驗",
          "age_range":"26～35歲",
          "original_photo_path":"https://i.imgur.com/6e8Sa6K.jpg",
          "photo_path":"https://i.imgur.com/6e8Sa6K.jpg",
          "accept_q1":"否",
          "accept_q2":"是",
          "accept_q3":"4",
          "note":"嗨我是俊隆",
          "reject_q1":"",
          "reject_q1_note":"",
          "created_at":"2023-11-16 13:55"
        }
      ]
    })
  }
  const url = apiUrl('preteeth', 'log')
  return axios.get(url, {params})
}

export function exportPreteethListsAPI(params) {
  if (useMock) {
    return Promise.resolve({})
  }
  const url = apiUrl('preteeth', 'download')
  return axios.get(url, {params})
}

export function getDoctorListAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: [
        {
          "doctor_code": "8d307RzhvYdT",
          "doctor_name": "測試診所三號"
        },
        {
          "doctor_code": "A2hNxGnD6wV0",
          "doctor_name": "ClearCorrect"
        }
      ]
    })
  }
  const url = apiUrl('doctor', 'list')
  return axios.get(url, {params})
}

export function getClinicListAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: [
        {
          "clinic_code": "3701113129",
          "clinic_name": "皇齊牙醫診所",
          "city": "3"
        },
        {
          "clinic_code": "3731181091",
          "clinic_name": "皇家牙醫診所",
          "city": "2"
        }
      ]
    })
  }
  const url = apiUrl('clinic', 'list')
  return axios.get(url, {params})
}

export function createReserveAPI(data) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('reserves')
  return axios.post(url, data)
}

export function createBatchReserveAPI(data) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('reserves', 'batch')
  return axios.post(url, data)
}

export function updateReserveAPI(id, data) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功",
        "remind_text": "蔡友仁您好，您與 王醫師醫師 的約診時間已更改為 2021-08-25(三)08:55 於 Dent&Co牙醫診所1，如有任何問題請電洽 (02)89115812 確認。"
      }
    })
  }
  const url = apiUrl('reserves', id)
  return axios.put(url, data)
}

export function RemindChangeAPI(data) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('reserves', 'remind_change')
  return axios.post(url, data)
}

export function updateReserveStatusAPI(id, data) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('reserves', 'change_status', id)
  return axios.put(url, data)
}

export function getPatientReserveRecordAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: [
        {
          "start_time": "2021-08-27 12:00",
          "attend_status": "0",
          "clinic_name": "Dent&Co牙醫診所1",
          "doctor_name": "王醫師",
          "title": "醫師"
        },
        {
          "start_time": "2021-08-25 12:00",
          "attend_status": "-1",
          "clinic_name": "Dent&Co牙醫診所1",
          "doctor_name": "王醫師",
          "title": "醫師"
        }
      ]
    })
  }
  const url = apiUrl('patients', 'reserves')
  return axios.get(url, {params})
}

export function getAppointmentAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "paginate": {
          "page": "1",
          "limit": "2",
          "page_total": "49",
          "total": "98"
        },
        "data": [
          {
            "id": "19350",
            "name": "朱耕甫",
            "phone": "0905572657",
            "doctor_name": "測試診所一號",
            "item": "矯正",
            "note": "",
            "book_time": "2021-01-10 09:00",
            "is_np": "1",
            "channel": "LINE預約",
            "inviter_alias": "Dent&Co推薦",
            "create_time": "2020-12-16 23:20",
            "update_time": "2020-12-16 23:20",
            "crm_note": "",
            "completed": "0",
            "week": "",
            "time_slot": "",
            "remind_1": "您的約診已被確認，請於 2020-12-17 10:30 準時前往\n若有疑問可致電Dent&Co牙醫診所1確認：(02)89115812",
            "remind_3": [
              {
                "reason": "此時段已有預約",
                "msg": "此時段已被他人預約，請致電Dent&Co牙醫診所1選擇其他時間：(02)89115812"
              },
              {
                "reason": "治療較為複雜",
                "msg": "您的治療較為複雜，請致電Dent&Co牙醫診所1選擇其他時間：(02)89115812"
              },
              {
                "reason": "醫師有其他安排",
                "msg": "您的醫師有其他安排，請致電Dent&Co牙醫診所1選擇其他時間：(02)89115812"
              },
              {
                "reason": "不修改訊息內容",
                "msg": "您的線上預約不成功，請致電Dent&Co牙醫診所1選擇其他時間：(02)89115812"
              }
            ],
          }
        ]
      }
    })
  }
  const url = apiUrl('appointments')
  return axios.get(url, {params})
}

export function updateAppointmentAPI(id, data) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('appointments', id)
  return axios.put(url, data)

  // return Promise.reject({
  //   response: {
  //     data: {
  //       fail_code: '500',
  //       messages: "不知道三小"
  //     }
  //   }
  // })
}

export function getRescheduleAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: [
        {
          "reserve_no": "2092162",
          "name": "蔡友仁",
          "doctor_name": "王醫師",
          "note": "",
          "phone": "0922099195",
          "start_time": "2021-08-25 00;55",
          "updated_time": "0000-00-00 00:00:00",
          "change_reason": "",
          "call_clinic_status": "0"
        }
      ]
    })
  }
  const url = apiUrl('change_reserves')
  return axios.get(url, {params})
}

export function updateRescheduleAPI(id, data) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('change_reserves', id)
  return axios.put(url, data)
}

export function getRescheduleSettingsAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: {
        time_limit: "1"
      }
    })
  }
  const url = apiUrl('change_reserves', 'settings')
  return axios.get(url, {params})
}

export function updateRescheduleSettingsAPI(data) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('change_reserves', 'settings')
  return axios.put(url, data)
}

export function getFreeTimeAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: [
        {
          "clinic_code": "0000000002",
          "clinic_name": "Dent&Co牙醫診所1",
          "doctor_code": "8d307RzhvYdT",
          "doctor_name": "測試診所三號",
          "use_manual_free_time": true,
          "web_appointment_status": "2",
          "current_appointment_status": "1",
          "tomorrow_appointment_status": "1",
          "weekly": [
            [],
            [],
            ["14:00", "14:30", "15:00", "15:30"],
            [],
            [],
            [],
            []
          ],
          "single": {
            "2021-06-01": ["14:00", "14:30"],
            "2021-06-02": [],
            "2021-06-10": ["07:00", "14:00"],
            "2021-06-18": ["15:00", "15:30", "16:00", "16:30", "17:00"],
            "2021-06-21": ["10:00", "10:30", "11:00"]
          },
          "closed": ["2021-09-23", "2021-09-24"]
        }
      ]
    })
  }
  const url = apiUrl('freetime')
  return axios.get(url, {params})
}

export function updateFreeTimeAPI(data) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('freetime')
  return axios.post(url, data)
}

export function getReviewOverviewAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "data": {
          "total": {
            "count": 36,
            "message": 9,
            "negative": 2,
            "star": 4.8
          },
          "recommend": {
            "醫師專業/治療後效果佳": 23,
            "態度友善": 14,
            "技術熟練/過程迅速": 7,
            "詳細解說/有耐心": 12,
            "舒適": 7,
            "看診準時": 1,
            "設備新穎": 5,
            "好溝通": 8,
            "價格合理": 2
          },
          "negative": {
            "跟診助理：態度不佳": 1,
            "櫃檯人員：態度不佳": 1,
            "櫃檯人員：收費不合理": 1
          },
          "star": {
            "看診醫師": 4.9,
            "跟診助理": 4.9,
            "櫃檯人員": 4.6,
            "環境設備": 4.9,
            "諮詢人員": 4.8
          }
        }
      }
    })
  }
  const url = apiUrl('reviews')
  return axios.get(url, {params})
}

export function getReviewsAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "paginate": {
          "page": "1",
          "limit": "10",
          "page_total": "1",
          "total": "2"
        },
        "data": [
          {
            "id": "7700",
            "name": "小David",
            "clinic_name": "Dent&Co牙醫診所1",
            "doctor_name": "測試醫師2號",
            "message": "",
            "response": "",
            "created_time": "2019-12-19 (四) 17:10:45",
            "star": {
              "看診醫師": 5,
              "跟診助理": 5,
              "櫃檯人員": 2,
              "環境設備": 4,
              "諮詢人員": 4
            },
            "recommend": [
              "醫師專業/治療後效果佳",
              "態度友善",
              "詳細解說/有耐心"
            ],
            "negative": [
              "櫃檯人員：收費不合理"
            ],
            "treatment": [
              "基礎治療",
              "牙周治療"
            ],
            "photo": [
              "https://cdn.dentco.tw/survey/2746173447816179390771232.jpg",
              "https://cdn.dentco.tw/survey/2577448701116179771812817.jpg"
            ]
          }
        ]
      }
    })
  }
  const url = apiUrl('reviews', 'list')
  return axios.get(url, {params})
}

export function getReviewTreatmentSAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: [
        {
          "id": "47",
          "name": "全口洗牙(定期檢查)"
        },
        {
          "id": "48",
          "name": "基礎治療"
        }
      ]
    })
  }
  const url = apiUrl('reviews', 'treatment_list')
  return axios.get(url, {params})
}

export function reviewReplyAPI(data) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('reviews', 'reply')
  return axios.post(url, data)
}

export function getReviewRuleAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: {"doctor_code": "uRf956y92cfu", "review_auth_status": "0"}
    })
  }
  const url = apiUrl('reviews', 'rule')
  return axios.get(url, {params})
}

export function updateReviewRuleAPI(doctorCode, data) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('reviews', 'rule', doctorCode)
  return axios.put(url, data)
}

export function getReviewTypeAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: {"clinic_code": "0000000002", "survey_type": "1"}
    })
  }
  const url = apiUrl('reviews', 'type')
  return axios.get(url, {params})
}

export function updateReviewTypeAPI(clinicCode, data) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('reviews', 'type', clinicCode)
  return axios.put(url, data)
}


export function getCrawlerGoogleReviewModeAPI(dicId, params) {
  if (useMock) {
    return Promise.resolve({
      data: {"google_crawling_mode": [1, 2, 3]}
    })
  }
  const url = apiUrl('reviews', 'google-crawler-mode', dicId)
  return axios.get(url, {params})
}

export function updateCrawlerGoogleReviewModeAPI(dicId, data) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('reviews', 'google-crawler-mode', dicId)
  return axios.put(url, data)
}

export function uploadAngelAPI(data) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('excel', 'angel')
  const formData = new FormData()
  for (const [key, value] of Object.entries(data)) {
    formData.append(key, value)
  }

  return axios.post(url, formData)
}

export function uploadDolphinAPI(data) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('excel', 'dolphin')
  const formData = new FormData()
  for (const [key, value] of Object.entries(data)) {
    formData.append(key, value)
  }

  return axios.post(url, formData)
}

export function uploadFiadigitAPI(data) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('excel', 'fiadigit')
  const formData = new FormData()
  for (const [key, value] of Object.entries(data)) {
    formData.append(key, value)
  }

  return axios.post(url, formData)
}

export function getClinicInfoAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "clinic_code": "0000000002",
        "clinic_name": "Dent&Co牙醫診所1",
        "county": "臺北市",
        "area": "信義區",
        "address": "基隆路一段178號7樓",
        "tel": "(02)89115812",
        "goal": "123456",
        "profile": "https://cdn.dentco.tw/clicic/zm6pVwcUxYXfIawY.jpg",
        "fb_page": "https://www.facebook.com/companyfordentist/2",
        "website": "https://www.facebook.com/companyfordentist/123",
        "link1": "https://search.google.com/local/writereview?openExternalBrowser=1&placeid=https://search.google.com/local/writereview?placeid=ChIJPc3dPCmnQjQRAtuQ02cnDho&openExternalBrowser=1",
        "case_photo": [
          {
            "id": "144",
            "addr": "https://cdn.dentco.tw/case_photo/SJYfK9eA7YTxsYJL.jpg"
          },
          {
            "id": "145",
            "addr": "https://cdn.dentco.tw/case_photo/5k1h8bX9illzogNO.jpg"
          }
        ],
        "doctor": [
          {
            "doctor_code": "IwgUWU8zALU0",
            "doctor_name": "測試診所一號",
            "sort": "0",
            "job_title": ""
          },
          {
            "doctor_code": "heYCsLUF2PBH",
            "doctor_name": "測試診所二號",
            "sort": "1",
            "job_title": ""
          }
        ],
        "banner": [
          {
            "id": "80",
            "banner": "https://cdn.dentco.tw/clinic/banner/qbgQbrk4QsbPhy6r.jpg"
          }
        ],
        "clinic_opening": {
          "morning": ["10:00", "12:00"],
          "afternoon": ["14:00", "17:00"],
          "evening": ["18:00", "21:00"]
        },
        "clinic_open_date": [
          {"up": true, "middle": false, "down": true},
          {"up": false, "middle": false, "down": false},
          {"up": false, "middle": true, "down": false},
          {"up": true, "middle": false, "down": true},
          {"up": false, "middle": true, "down": false},
          {"up": false, "middle": false, "down": false},
          {"up": false, "middle": true, "down": false}
        ]
      }
    })
  }
  const url = apiUrl('clinic')
  return axios.get(url, {params})
}

export function updateClinicInfoAPI(data) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('clinic')
  return axios.post(url, data)
}

export function uploadClinicBannerAPI(data) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('clinic', 'banner')
  return axios.post(url, data)
}

export function deleteClinicBannerAPI(id, params) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('clinic', 'banner', id)
  return axios.delete(url, {params})
}

export function deleteClinicBannerBatchAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('clinic', 'del_banner')
  return axios.delete(url, {params})
}

export function uploadClinicCaseAPI(data) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('clinic', 'case_photo')
  return axios.post(url, data)
}

export function deleteClinicCaseAPI(id, params) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('clinic', 'case_photo', id)
  return axios.delete(url, {params})
}

export function getCitiesAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: [
        {
          "id": "1",
          "name": "基隆市",
          "en_name": "Keelung City",
          "areas": [
            {
              "id": "1",
              "name": "仁愛區",
              "en_name": "Renai District"
            },
            {
              "id": "2",
              "name": "信義區",
              "en_name": "Xinyi District"
            }
          ]
        }
      ]
    })
  }
  const url = apiUrl('selector', 'cities')
  return axios.get(url, {params})
}

export function getDoctorInfoAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "doctor_code": "0UeuIW4r3E04",
        "doctor_name": "朱耕甫",
        "profile": "https://cdn.dentco.tw/doctor/images/profolio/5fc712d63df06.jpg",
        "birthday": "1992-03-23",
        "principle": "Fill the World with Beautiful Smiles",
        "qualification": "國防醫學院牙醫學士,前三軍總醫院牙醫師,台灣牙醫植體醫學會會員,Dent&Co Founder,",
        "education": [
          {
            "year": "2009,2015",
            "school": "國防醫學院",
            "partment": "牙醫學系"
          },
          {
            "year": "2018,至今",
            "school": "無",
            "partment": ""
          }
        ],
        "website": "https://www.facebook.com/%E7%9A%87%E5%AE%B6%E7%89%99%E9%86%AB%E8%A8%BA%E6%89%80-1051395404994928/",
        "fb_page": "https://www.facebook.com/companyfordentist/",
        "case_photo": [],
        "treatment": [
          {
            "description": "1",
            "sort": "99"
          },
          {
            "description": "2",
            "sort": "99"
          }
        ],
        "clinics": [
          {
            "clinic_code": "3731181091",
            "clinic_name": "皇家牙醫診所",
            "county": "新北市",
            "area": "深坑區",
            "address": "北深路3段26號",
            "tel": "(02)26629511"
          }
        ]
      }
    })
  }
  const url = apiUrl('doctor')
  return axios.get(url, {params})
}

export function updateDoctorInfoAPI(data) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('doctor')
  return axios.post(url, data)
}

export function updateDoctorClinicAPI(data) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('doctor', 'clinics')
  return axios.post(url, data)
}

export function uploadDoctorCaseAPI(data) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('doctor', 'case_photo')
  return axios.post(url, data)
}

export function deleteDoctorCaseAPI(id, params) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('doctor', 'case_photo', id)
  return axios.delete(url, {params})
}

export function getAnalysisLateAPI(params) {
  if (useMock) {
    if (params.type === '1')
      return Promise.resolve({
        data: {
          "clinic_patient_late": [
            {
              "name": null,
              "no": 104748,
              "flake_out": 6,
              "on_time": 142,
              "late": 44,
              "late_time": 236,
              "day_avg": 7.87,
              "person_avg": 5.36,
              "ratio": 22.92,
              "clinic_code": "3731181091",
              "doctor_code": null,
              "top_ten_late": "[{\"name\":\"朱至剛\",\"phone\":\"0910754263\",\"count\":1,\"total_time\":21},{\"name\":\"黃錦順\",\"phone\":\"0963058961\",\"count\":1,\"total_time\":16},{\"name\":\"劉宇恩\",\"phone\":\"0973660120\",\"count\":1,\"total_time\":14},{\"name\":\"陳盈之\",\"phone\":\"0931901899\",\"count\":1,\"total_time\":13},{\"name\":\"闕珮庭\",\"phone\":\"0928757138\",\"count\":1,\"total_time\":10},{\"name\":\"郭淑慧\",\"phone\":\"0989355560\",\"count\":1,\"total_time\":9},{\"name\":\"陳睿傑\",\"phone\":\"0984381269\",\"count\":1,\"total_time\":9},{\"name\":\"王駿傑\",\"phone\":\"0910688981\",\"count\":1,\"total_time\":9},{\"name\":\"阮碧乖\",\"phone\":\"0983886223\",\"count\":2,\"total_time\":8},{\"name\":\"陳淑利\",\"phone\":\"0933887513\",\"count\":1,\"total_time\":8}]",
              "city": 2,
              "create_time": "2021-11-02 16:05:45",
              "date": "2021-11-02"
            },
            {
              "name": null,
              "no": 106002,
              "flake_out": 6,
              "on_time": 136,
              "late": 43,
              "late_time": 278,
              "day_avg": 9.27,
              "person_avg": 6.47,
              "ratio": 23.24,
              "clinic_code": "3731181091",
              "doctor_code": null,
              "top_ten_late": "[{\"name\":\"盧正泰\",\"phone\":\"0929893599\",\"count\":1,\"total_time\":51},{\"name\":\"朱至剛\",\"phone\":\"0910754263\",\"count\":1,\"total_time\":21},{\"name\":\"黃錦順\",\"phone\":\"0963058961\",\"count\":1,\"total_time\":16},{\"name\":\"劉宇恩\",\"phone\":\"0973660120\",\"count\":1,\"total_time\":14},{\"name\":\"陳盈之\",\"phone\":\"0931901899\",\"count\":1,\"total_time\":13},{\"name\":\"闕珮庭\",\"phone\":\"0928757138\",\"count\":1,\"total_time\":10},{\"name\":\"郭淑慧\",\"phone\":\"0989355560\",\"count\":1,\"total_time\":9},{\"name\":\"陳睿傑\",\"phone\":\"0984381269\",\"count\":1,\"total_time\":9},{\"name\":\"王駿傑\",\"phone\":\"0910688981\",\"count\":1,\"total_time\":9},{\"name\":\"阮碧乖\",\"phone\":\"0983886223\",\"count\":2,\"total_time\":8}]",
              "city": 2,
              "create_time": "2021-11-03 16:05:44",
              "date": "2021-11-03"
            }
          ],
          "doctor_patient_late": {
            "0UeuIW4r3E04": [
              {
                "name": "朱耕甫",
                "no": 104747,
                "flake_out": 2,
                "on_time": 67,
                "late": 17,
                "late_time": 96,
                "day_avg": 3.2,
                "person_avg": 5.65,
                "ratio": 19.77,
                "clinic_code": "3731181091",
                "doctor_code": "0UeuIW4r3E04",
                "top_ten_late": "[{\"name\":\"朱至剛\",\"phone\":\"0910754263\",\"count\":1,\"total_time\":21},{\"name\":\"劉宇恩\",\"phone\":\"0973660120\",\"count\":1,\"total_time\":14},{\"name\":\"陳睿傑\",\"phone\":\"0984381269\",\"count\":1,\"total_time\":9},{\"name\":\"王駿傑\",\"phone\":\"0910688981\",\"count\":1,\"total_time\":9},{\"name\":\"陳宥曈\",\"phone\":\"0913587580\",\"count\":1,\"total_time\":8},{\"name\":\"鄭宏騏\",\"phone\":\"0905265010\",\"count\":2,\"total_time\":7},{\"name\":\"林竟宇\",\"phone\":\"0972838536\",\"count\":1,\"total_time\":7},{\"name\":\"林琪華\",\"phone\":\"0918852273\",\"count\":1,\"total_time\":4},{\"name\":\"黃宏霖\",\"phone\":\"0919375751\",\"count\":1,\"total_time\":4},{\"name\":\"陳厚瑜\",\"phone\":null,\"count\":2,\"total_time\":3}]",
                "city": 2,
                "create_time": "2021-11-02 16:05:45",
                "date": "2021-11-02"
              },
              {
                "name": "朱耕甫",
                "no": 106001,
                "flake_out": 2,
                "on_time": 67,
                "late": 17,
                "late_time": 96,
                "day_avg": 3.2,
                "person_avg": 5.65,
                "ratio": 19.77,
                "clinic_code": "3731181091",
                "doctor_code": "0UeuIW4r3E04",
                "top_ten_late": "[{\"name\":\"朱至剛\",\"phone\":\"0910754263\",\"count\":1,\"total_time\":21},{\"name\":\"劉宇恩\",\"phone\":\"0973660120\",\"count\":1,\"total_time\":14},{\"name\":\"陳睿傑\",\"phone\":\"0984381269\",\"count\":1,\"total_time\":9},{\"name\":\"王駿傑\",\"phone\":\"0910688981\",\"count\":1,\"total_time\":9},{\"name\":\"陳宥曈\",\"phone\":\"0913587580\",\"count\":1,\"total_time\":8},{\"name\":\"鄭宏騏\",\"phone\":\"0905265010\",\"count\":2,\"total_time\":7},{\"name\":\"林竟宇\",\"phone\":\"0972838536\",\"count\":1,\"total_time\":7},{\"name\":\"林琪華\",\"phone\":\"0918852273\",\"count\":1,\"total_time\":4},{\"name\":\"黃宏霖\",\"phone\":\"0919375751\",\"count\":1,\"total_time\":4},{\"name\":\"陳厚瑜\",\"phone\":null,\"count\":2,\"total_time\":3}]",
                "city": 2,
                "create_time": "2021-11-03 16:05:44",
                "date": "2021-11-03"
              }
            ],
            "1eflyCHao9f3": [
              {
                "name": "盧瑩婕",
                "no": 103496,
                "flake_out": 4,
                "on_time": 72,
                "late": 26,
                "late_time": 132,
                "day_avg": 4.4,
                "person_avg": 5.08,
                "ratio": 25.49,
                "clinic_code": "3731181091",
                "doctor_code": "1eflyCHao9f3",
                "top_ten_late": "[{\"name\":\"黃錦順\",\"phone\":\"0963058961\",\"count\":1,\"total_time\":16},{\"name\":\"陳盈之\",\"phone\":\"0931901899\",\"count\":1,\"total_time\":13},{\"name\":\"闕珮庭\",\"phone\":\"0928757138\",\"count\":1,\"total_time\":10},{\"name\":\"郭淑慧\",\"phone\":\"0989355560\",\"count\":1,\"total_time\":9},{\"name\":\"阮碧乖\",\"phone\":\"0983886223\",\"count\":2,\"total_time\":8},{\"name\":\"程詠蘭\",\"phone\":\"0970586167\",\"count\":1,\"total_time\":8},{\"name\":\"葉歡\",\"phone\":\"0988096446\",\"count\":1,\"total_time\":8},{\"name\":\"洪鳳儀\",\"phone\":\"0980838217\",\"count\":1,\"total_time\":7},{\"name\":\"湯健仁\",\"phone\":\"0926518368\",\"count\":1,\"total_time\":7},{\"name\":\"嚴？銘\",\"phone\":\"0952236305\",\"count\":2,\"total_time\":6}]",
                "city": 2,
                "create_time": "2021-11-01 16:05:42",
                "date": "2021-11-01"
              },
              {
                "name": "盧瑩婕",
                "no": 104746,
                "flake_out": 4,
                "on_time": 75,
                "late": 27,
                "late_time": 140,
                "day_avg": 4.67,
                "person_avg": 5.19,
                "ratio": 25.47,
                "clinic_code": "3731181091",
                "doctor_code": "1eflyCHao9f3",
                "top_ten_late": "[{\"name\":\"黃錦順\",\"phone\":\"0963058961\",\"count\":1,\"total_time\":16},{\"name\":\"陳盈之\",\"phone\":\"0931901899\",\"count\":1,\"total_time\":13},{\"name\":\"闕珮庭\",\"phone\":\"0928757138\",\"count\":1,\"total_time\":10},{\"name\":\"郭淑慧\",\"phone\":\"0989355560\",\"count\":1,\"total_time\":9},{\"name\":\"阮碧乖\",\"phone\":\"0983886223\",\"count\":2,\"total_time\":8},{\"name\":\"陳淑利\",\"phone\":\"0933887513\",\"count\":1,\"total_time\":8},{\"name\":\"程詠蘭\",\"phone\":\"0970586167\",\"count\":1,\"total_time\":8},{\"name\":\"葉歡\",\"phone\":\"0988096446\",\"count\":1,\"total_time\":8},{\"name\":\"洪鳳儀\",\"phone\":\"0980838217\",\"count\":1,\"total_time\":7},{\"name\":\"湯健仁\",\"phone\":\"0926518368\",\"count\":1,\"total_time\":7}]",
                "city": 2,
                "create_time": "2021-11-02 16:05:45",
                "date": "2021-11-02"
              },
              {
                "name": "盧瑩婕",
                "no": 106000,
                "flake_out": 4,
                "on_time": 69,
                "late": 26,
                "late_time": 182,
                "day_avg": 6.07,
                "person_avg": 7,
                "ratio": 26.26,
                "clinic_code": "3731181091",
                "doctor_code": "1eflyCHao9f3",
                "top_ten_late": "[{\"name\":\"盧正泰\",\"phone\":\"0929893599\",\"count\":1,\"total_time\":51},{\"name\":\"黃錦順\",\"phone\":\"0963058961\",\"count\":1,\"total_time\":16},{\"name\":\"陳盈之\",\"phone\":\"0931901899\",\"count\":1,\"total_time\":13},{\"name\":\"闕珮庭\",\"phone\":\"0928757138\",\"count\":1,\"total_time\":10},{\"name\":\"郭淑慧\",\"phone\":\"0989355560\",\"count\":1,\"total_time\":9},{\"name\":\"阮碧乖\",\"phone\":\"0983886223\",\"count\":2,\"total_time\":8},{\"name\":\"葉歡\",\"phone\":\"0988096446\",\"count\":1,\"total_time\":8},{\"name\":\"陳淑利\",\"phone\":\"0933887513\",\"count\":1,\"total_time\":8},{\"name\":\"程詠蘭\",\"phone\":\"0970586167\",\"count\":1,\"total_time\":8},{\"name\":\"洪鳳儀\",\"phone\":\"0980838217\",\"count\":1,\"total_time\":7}]",
                "city": 2,
                "create_time": "2021-11-03 16:05:44",
                "date": "2021-11-03"
              }
            ]
          },
          "city_patient_late": {
            "name": "新北市",
            "no": 107227,
            "flake_out": 5764,
            "on_time": 14347,
            "late": 6285,
            "late_time": 44942,
            "day_avg": 1498.07,
            "person_avg": 7.15,
            "ratio": 23.81,
            "clinic_code": null,
            "doctor_code": null,
            "top_ten_late": null,
            "city": 2,
            "create_time": "2021-11-03 16:33:39"
          },
          "taiwan_patient_late": {
            "no": 107241,
            "flake_out": 24793,
            "on_time": 57413,
            "late": 30730,
            "late_time": 305565,
            "day_avg": 10185.5,
            "person_avg": 9.94,
            "ratio": 27.21,
            "clinic_code": null,
            "doctor_code": null,
            "top_ten_late": null,
            "city": null,
            "create_time": "2021-11-03 16:33:39"
          }
        }
      })
    else
      return Promise.resolve({
        data: {
          "doctor_patient_late": {
            "3701113129": [
              {
                "name": "皇齊牙醫診所",
                "no": "105781",
                "flake_out": "0",
                "on_time": "11",
                "late": "4",
                "late_time": "28",
                "day_avg": "0.93",
                "person_avg": "7",
                "ratio": "26.67",
                "clinic_code": "3701113129",
                "doctor_code": "0UeuIW4r3E04",
                "top_ten_late": "[{\"name\":\"林宥均\",\"phone\":\"0927991553\",\"count\":1,\"total_time\":12},{\"name\":\"許芯禕\",\"phone\":\"0919837450\",\"count\":1,\"total_time\":7},{\"name\":\"楊博勝\",\"phone\":\"0972897017\",\"count\":1,\"total_time\":5},{\"name\":\"林郁琇\",\"phone\":\"0978790251\",\"count\":1,\"total_time\":4}]",
                "city": "3",
                "create_time": "2021-11-02 16:30:47",
                "date": "2021-11-02"
              },
              {
                "name": "皇齊牙醫診所",
                "no": "107047",
                "flake_out": "0",
                "on_time": "9",
                "late": "4",
                "late_time": "28",
                "day_avg": "0.93",
                "person_avg": "7",
                "ratio": "30.77",
                "clinic_code": "3701113129",
                "doctor_code": "0UeuIW4r3E04",
                "top_ten_late": "[{\"name\":\"林宥均\",\"phone\":\"0927991553\",\"count\":1,\"total_time\":12},{\"name\":\"許芯禕\",\"phone\":\"0919837450\",\"count\":1,\"total_time\":7},{\"name\":\"楊博勝\",\"phone\":\"0972897017\",\"count\":1,\"total_time\":5},{\"name\":\"林郁琇\",\"phone\":\"0978790251\",\"count\":1,\"total_time\":4}]",
                "city": "3",
                "create_time": "2021-11-03 16:30:50",
                "date": "2021-11-03"
              }
            ],
            "3731181091": [
              {
                "name": "皇家牙醫診所",
                "no": "104747",
                "flake_out": "2",
                "on_time": "67",
                "late": "17",
                "late_time": "96",
                "day_avg": "3.2",
                "person_avg": "5.65",
                "ratio": "19.77",
                "clinic_code": "3731181091",
                "doctor_code": "0UeuIW4r3E04",
                "top_ten_late": "[{\"name\":\"朱至剛\",\"phone\":\"0910754263\",\"count\":1,\"total_time\":21},{\"name\":\"劉宇恩\",\"phone\":\"0973660120\",\"count\":1,\"total_time\":14},{\"name\":\"陳睿傑\",\"phone\":\"0984381269\",\"count\":1,\"total_time\":9},{\"name\":\"王駿傑\",\"phone\":\"0910688981\",\"count\":1,\"total_time\":9},{\"name\":\"陳宥曈\",\"phone\":\"0913587580\",\"count\":1,\"total_time\":8},{\"name\":\"鄭宏騏\",\"phone\":\"0905265010\",\"count\":2,\"total_time\":7},{\"name\":\"林竟宇\",\"phone\":\"0972838536\",\"count\":1,\"total_time\":7},{\"name\":\"林琪華\",\"phone\":\"0918852273\",\"count\":1,\"total_time\":4},{\"name\":\"黃宏霖\",\"phone\":\"0919375751\",\"count\":1,\"total_time\":4},{\"name\":\"陳厚瑜\",\"phone\":null,\"count\":2,\"total_time\":3}]",
                "city": "2",
                "create_time": "2021-11-02 16:05:45",
                "date": "2021-11-02"
              },
              {
                "name": "皇家牙醫診所",
                "no": "106001",
                "flake_out": "2",
                "on_time": "67",
                "late": "17",
                "late_time": "96",
                "day_avg": "3.2",
                "person_avg": "5.65",
                "ratio": "19.77",
                "clinic_code": "3731181091",
                "doctor_code": "0UeuIW4r3E04",
                "top_ten_late": "[{\"name\":\"朱至剛\",\"phone\":\"0910754263\",\"count\":1,\"total_time\":21},{\"name\":\"劉宇恩\",\"phone\":\"0973660120\",\"count\":1,\"total_time\":14},{\"name\":\"陳睿傑\",\"phone\":\"0984381269\",\"count\":1,\"total_time\":9},{\"name\":\"王駿傑\",\"phone\":\"0910688981\",\"count\":1,\"total_time\":9},{\"name\":\"陳宥曈\",\"phone\":\"0913587580\",\"count\":1,\"total_time\":8},{\"name\":\"鄭宏騏\",\"phone\":\"0905265010\",\"count\":2,\"total_time\":7},{\"name\":\"林竟宇\",\"phone\":\"0972838536\",\"count\":1,\"total_time\":7},{\"name\":\"林琪華\",\"phone\":\"0918852273\",\"count\":1,\"total_time\":4},{\"name\":\"黃宏霖\",\"phone\":\"0919375751\",\"count\":1,\"total_time\":4},{\"name\":\"陳厚瑜\",\"phone\":null,\"count\":2,\"total_time\":3}]",
                "city": "2",
                "create_time": "2021-11-03 16:05:44",
                "date": "2021-11-03"
              }
            ]
          },
          "city_patient_late": {
            "2": {
              "name": "新北市",
              "no": "107227",
              "flake_out": "5764",
              "on_time": "14347",
              "late": "6285",
              "late_time": "44942",
              "day_avg": "1498.07",
              "person_avg": "7.15",
              "ratio": "23.81",
              "clinic_code": null,
              "doctor_code": null,
              "top_ten_late": null,
              "city": "2",
              "create_time": "2021-11-03 16:33:39"
            },
            "3": {
              "name": "臺北市",
              "no": "107228",
              "flake_out": "4008",
              "on_time": "10122",
              "late": "6005",
              "late_time": "54323",
              "day_avg": "1810.77",
              "person_avg": "9.05",
              "ratio": "29.82",
              "clinic_code": null,
              "doctor_code": null,
              "top_ten_late": null,
              "city": "3",
              "create_time": "2021-11-03 16:33:39"
            }
          },
          "taiwanPatientLate": {
            "no": "107241",
            "flake_out": "24793",
            "on_time": "57413",
            "late": "30730",
            "late_time": "305565",
            "day_avg": "10185.5",
            "person_avg": "9.94",
            "ratio": "27.21",
            "clinic_code": null,
            "doctor_code": null,
            "top_ten_late": null,
            "city": null,
            "create_time": "2021-11-03 16:33:39"
          }
        }
      })
  }
  const url = apiUrl('analysis', 'patient_late')
  return axios.get(url, {params})
}

export function getAnalysisLineBindAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "bind_patients": 508,
        "total_patients": 1153
      }
    })
  }
  const url = apiUrl('analysis', 'line_bind')
  return axios.get(url, {params})
}

export function getAnalysisLineInviteAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "invite_list": [
          {
            "name": "鄭哲偉",
            "invite_count": 53,
            "doctor_name": "測試醫師2號",
            "ranking": 1
          },
          {
            "name": "朱耕甫",
            "invite_count": 42,
            "doctor_name": "測試診所一號",
            "ranking": 2
          }
        ],
        "invite_rate": [
          {
            "year": 2021,
            "month": 8,
            "invite_count": 10,
            "total": 435,
            "date": "2021/8"
          },
          {
            "year": 2021,
            "month": 10,
            "invite_count": 1,
            "total": 436,
            "date": "2021/10"
          }
        ]
      }
    })
  }
  const url = apiUrl('analysis', 'invite')
  return axios.get(url, {params})
}

export function getAnalysisLineLobbyAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "people": [
          {
            "month": "2022-06",
            "total": 0
          }
        ],
        "efficiency": [
          {
            "month": "2022-06",
            "rate": 0
          }
        ]
      }
    })
  }
  const url = apiUrl('analysis', 'lobby_efficiency')
  return axios.get(url, {params})
}

export function getAnalysisOnlineReserveNPAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "labels": ["新病人", "舊病人"],
        "datasets": [{
          "data": [1, 1]
        }]
      }
    })
  }
  const url = apiUrl('analysis', 'appointments', 'np')
  return axios.get(url, {params})
}

export function getAnalysisOnlineReserveSourceAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "labels": ["牙醫小幫手", "line", "診所官網", "診所fb", "醫師官網", "醫師fb"],
        "datasets": [{
          "data": [1, 1, 1, 1, 1, 1]
        }]
      }
    })
  }
  const url = apiUrl('analysis', 'appointments', 'source')
  return axios.get(url, {params})
}

export function getAnalysisOnlineReserveAmountAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "columns": ["date", "total", "website", "line", "otherWebsite", "fb"],
        "rows": [
          {"date": "1/1", "total": 1, "website": 1, "line": 1, "otherWebsite": 1, "fb": 1},
          {"date": "2/1", "total": 1, "website": 1, "line": 1, "otherWebsite": 1, "fb": 1},
        ]
      }
    })
  }
  const url = apiUrl('analysis', 'appointments', 'amount')
  return axios.get(url, {params})
}

export function getAnalysisOnlineCompleteAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "labels": ["完成預約", "額外安排", "拒絕預約", "尚未處理"],
        "datasets": [{"data": [2674, 864, 811, 424]}]
      }
    })
  }
  const url = apiUrl('analysis', 'appointments', 'completed')
  return axios.get(url, {params})
}

export function getAnalysisWebViewAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: [
        {
          "month": "2021/01",
          "total": "219"
        },
        {
          "month": "2021/02",
          "total": "309"
        }
      ]
    })
  }
  const url = apiUrl('analysis', 'web_browse')
  return axios.get(url, {params})
}

export function getAnalysisLineAccountAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "labels": ["綁定", "未綁定", "封鎖"],
        "datasets": [{"data": ["43", "139", "14"]}]
      }
    })
  }
  const url = apiUrl('patients', 'analysis', 'line', 'account')
  return axios.get(url, {params})
}

export function getAnalysisMessengerAccountAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "labels": ["綁定", "未綁定", "封鎖"],
        "datasets": [{"data": ["43", "139", "14"]}]
      }
    })
  }
  const url = apiUrl('patients', 'analysis', 'messenger', 'account')
  return axios.get(url, {params})
}

export function getAnalysisLineLangAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "labels": ["綁定", "未綁定", "封鎖"],
        "datasets": [{"data": ["43", "139", "14"]}]
      }
    })
  }
  const url = apiUrl('patients', 'analysis', 'line', 'lang')
  return axios.get(url, {params})
}

export function getAnalysisMessengerLangAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "labels": ["綁定", "未綁定", "封鎖"],
        "datasets": [{"data": ["43", "139", "14"]}]
      }
    })
  }
  const url = apiUrl('patients', 'analysis', 'messenger', 'lang')
  return axios.get(url, {params})
}

export function getAnalysisRegistrationAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: [
        {
          "month":"2023-07",
          "line":"6",
          "messenger":"1"
        },
        {
          "month":"2023-08",
          "line":"37",
          "messenger":"3"
        },
        {
          "month":"2023-09",
          "line":"10",
          "messenger":"0"
        },
        {
          "month":"2023-10",
          "line":"48",
          "messenger":"0"
        },
        {
          "month":"2023-11",
          "line":"0",
          "messenger":"0"
        }
      ]
    })
  }
  const url = apiUrl('patients', 'analysis', 'registration')
  return axios.get(url, {params})
}

export function getAnalysisOnlineTreatmentAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "labels": [
          "一般治療（含定検）",
          "根管治療",
          "牙周治療",
          "短正",
          "假牙",
          "植牙",
          "美白",
          "其他"
        ],
        "datasets": [
          {
            "data": [
              41,
              2,
              2,
              14,
              1,
              2,
              1,
              19
            ]
          }
        ]
      }
    })
  }
  const url = apiUrl('analysis', 'appointments', 'treatment')
  return axios.get(url, {params})
}

export function getAdminAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: {
        clinic: [
          {
            "no": 875,
            "line_bot_id": "U127b7be0199ae2c128b41db3e45d7a9f",
            "name": "蔡孟芬",
            "input_phone": "0979281925"
          },
          {
            "no": 1183,
            "line_bot_id": "U4fb019a47ebc49ac68f75704295558f2",
            "name": "朱耕甫",
            "input_phone": "0905572657"
          }
        ],
        assistant: [
          {
            "no": 1706,
            "line_bot_id": "Uc5e5a15ed59858036e75f1bec481d2f2",
            "name": "duke",
            "input_phone": "0911111111"
          }
        ],
        doctor: [
          {
            "no": 33,
            "line_bot_id": "U972ce716384dbb5ad0143629163649b9",
            "name": "張家華",
            "input_phone": "0912807669"
          },
          {
            "no": 919,
            "line_bot_id": "Ucac6dc5501e0bb54ded4607a0ae9e06e",
            "name": "測試醫師2號",
            "input_phone": "0989000628"
          }
        ]
      }
    })
  }
  const url = apiUrl('auth')
  return axios.get(url, {params})
}

export function deleteAdminAPI(id, params) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('auth', id)
  return axios.delete(url, {params})
}

export function createAdminAPI(data) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功",
        "data": 1
      }
    })
  }
  const url = apiUrl('auth')
  return axios.post(url, data)
}

export function getHealthEduAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: [
        {
          "id": "1",
          "category_name": "植牙"
        },
        {
          "id": "2",
          "category_name": "矯正"
        }
      ]
    })
  }
  const url = apiUrl('message', 'health_education')
  return axios.get(url, {params})
}

export function getMessagePatientAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "count": params.satisfaction ? 499 : 501,
        "data": [
          {
            "patient_code": "1f72ilba",
            "phone": "0922099195",
            "name": "蔡有人",
            "sex": "1",
            "birthday": "",
            "file_name_photo": ""
          }
        ]
      }
    })
  }
  const url = apiUrl('message', 'patient')
  return axios.get(url, {params})
}

export function getMessageSettingAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "own_channel": false,
        "channel_id": "1622774606",
        "remind_treatment": "0",
        "remind_day": "1",
        "remind_1day": "0",
        "remind_2day": "1"
      }
    })
  }
  const url = apiUrl('message', 'auto_setting')
  return axios.get(url, {params})
}

export function updateMessageSettingAPI(data) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('message', 'auto_setting')
  return axios.post(url, data)
}

export function getMessageGroupLogAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: [
        {
          "create_time": "2021-10-31 14:15:12",
          "content": "?1/1(五) 元旦休診一天！\r\n\r\n冷冷冬意，為大家送上暖心祝福～\r\n預㊗️大家2021年～新年快樂?\r\n　\r\n開心跨年後，若有口腔問題，歡迎儘早預約檢查治療，準備迎接農曆年，好好享受美食與家人歡聚時刻～\r\n\r\n?新的一年，英倫牙醫繼續為你守護牙齒健康！\r\n　\r\n#休診公告\r\n#0101全天休診",
          "image_url": "https://dentco.s3.us-east-2.amazonaws.com/line/5fed6b9e12ccc.png"
        }
      ]
    })
  }
  const url = apiUrl('message', 'group_log')
  return axios.get(url, {params})
}

export function getMessageRemindLogAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: [
        {
          "create_time": "2021-11-15 14:40:09",
          "content": "蔡有人您好，您與 王醫師醫師 的約診時間為本日 18:00 於 Dent&Co牙醫診所1，請確認是否依約前往。\n備註：1.請提早五分鐘帶牙刷和牙膏來刷牙。\r\n\r\n2.因應防疫政策，請病患於就診前完成下列評估表，以利診所預防作業。https://tinyurl.com/dsmjwnpu 。\r\n\r\n3.就診前請下載臺灣社交距離 APP：http://onelink.to/wdxwyb 。到診請開給櫃檯確認\r\n"
        }
      ]
    })
  }
  const url = apiUrl('message', 'remind_log')
  return axios.get(url, {params})
}

export function getMessageActivitiesAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: [
        {
          "id": 1,
          "title": "2021父親節活動",
          "image_url": "https://i.imgur.com/A7dIOHo.jpg",
          "intro_url": "https://pse.is/3lv5fh",
          "start_date": "0000-00-00 00:00:00",
          "end_date": "0000-00-00 00:00:00",
          "sort": 99,
          "created_at": "2021-11-23 06:29:38",
          "updated_at": "2021-11-23 06:29:38"
        }
      ]
    })
  }
  const url = apiUrl('message', 'activity_list')
  return axios.get(url, {params})
}

export function uploadPanoImgAPI(data) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "pano_id": "619cb69dbf87f",
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('message', 'pano')
  return axios.post(url, data)
}

export function sendMessageAPI(data) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('message')
  return axios.post(url, data)
}

export function getMessagePermissionAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "send_restriction": "0"
      }
    })
  }
  const url = apiUrl('message', 'send_setting')
  return axios.get(url, {params})
}

export function sendOtpAPI(data) {
  if (useMock) {
    return Promise.resolve({
      data: {
        prefix: "test",
        code: 123
      }
    })
  }
  const url = 'https://api.dentco.tw/api/v1/otps'
  return axios.post(url, data)
}

export function searchClinicAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: [
        {
          "clinic_code": "0000000000",
          "clinic_name": "Dent&Co測試牙醫診所",
          "address": "新北市新店區"
        },
        {
          "clinic_code": "0000000099",
          "clinic_name": "業務測試診所",
          "address": "臺北市信義區"
        }
      ]
    })
  }
  const url = apiUrl('register', 'clinic_list')
  return axios.get(url, {params})
}

export function registerDoctorAPI(data) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('register', 'doctor')
  return axios.post(url, data)
}

export function forgetPasswordAPI(data) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('register', 'forgot_password')
  return axios.post(url, data)
}

export function createDoctorClinicAPI(data) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('doctor', 'clinic')
  return axios.post(url, data)
}

export function deleteDoctorClinicAPI(clinicCode, params) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('doctor', 'clinic', clinicCode)
  return axios.delete(url, {params})
}

export function getQuestionsAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "paginate": {
          "page": "1",
          "limit": "10",
          "page_total": "2",
          "total": "18"
        },
        "data": [
          {
            "id": 1,
            "name": "江江江江",
            "phone": "0911111111",
            "time": "2021-12-30 (四) 15:56:04",
            "content": "三小？",
            "answer": null
          },
          {
            "id": 2,
            "name": "奧克",
            "phone": "0922222222",
            "time": "2021-12-28 (二) 09:10:00",
            "content": "啦！乾！",
            "answer": "文明人生"
          }
        ]
      }
    })
  }
  const url = apiUrl('question')
  return axios.get(url, {params})
}

export function replyQuestionsAPI(data) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('question', 'reply')
  return axios.post(url, data)
}

export function settingSyncGoogleAPI(data) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('doctor', 'calendar')
  return axios.post(url, data)
}

export function settingUnSyncGoogleAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('doctor', 'calendar')
  return axios.delete(url, {params})
}

export function syncGoogleAPI(data) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('doctor', 'sync_calendar')
  return axios.post(url, data)
}

export function getPopupNoticeAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "id": "2",
        "content": "公告d1",
        "file_name": "",
        "link_1": ""
      }
    })
  }
  const url = apiUrl('notice', 'popup')
  return axios.get(url, {params})
}

export function getTopNoticeAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: [
        {
          "id": "2",
          "content": "公告d1",
          "file_name": "https://cdn.dentco.tw/notice/2/123.jpg",
          "link_1": "https://dentco.tw",
          "created_at": "2022-05-06 11:04:52",
          "read": "1"
        },
        {
          "id": "1",
          "content": "公告1",
          "file_name": "",
          "link_1": "",
          "created_at": "2022-05-06 11:04:52",
          "read": "1"
        }
      ]
    })
  }
  const url = apiUrl('notice', 'new')
  return axios.get(url, {params})
}

export function countUnreadAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "count": 100,
        "notices": 1,
        "appointments": 2,
        "change_reserves": 2,
        "care": 1,
      }
    })
  }
  const url = apiUrl('notice', 'unread')
  return axios.get(url, {params})
}

export function getNoticesAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "paginate": {
          "page": "1",
          "limit": "20",
          "page_total": "1",
          "total": "2"
        },
        "data": [
          {
            "id": "2",
            "content": "公告d1",
            "file_name": "https://cdn.dentco.tw/notice/2/123.jpg",
            "link_1": "https://dentco.tw",
            "created_at": "2022-05-06 11:04:52",
            "read": "1"
          },
          {
            "id": "1",
            "content": "公告1",
            "file_name": "",
            "link_1": "",
            "created_at": "2022-05-06 11:04:55",
            "read": "0"
          }
        ]
      }
    })
  }
  const url = apiUrl('notice', 'list')
  return axios.get(url, {params})
}

export function getSuperLinksAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "add_line": {
          "original": "https://liff.line.me/1657424862-o4Ypn466?channel=1657424851&liffid=1657424862-o4Ypn466&c=0000000088&d=&p=IDCGniPB",
          "short": "https://link.dentco.tw/11b46d7b"
        },
        "bind_line": {
          "original": "https://liff.line.me/1657424862-o4Ypn466?channel=1657424851&liffid=1657424862-o4Ypn466&c=0000000088&d=&p=IDCGniPB",
          "short": "https://link.dentco.tw/49c6eddb"
        },
        "appointment": {
          "original": "https://www.dentco.tw/appointment/clinic/0000000088?type=clinic&channel=\u7acb\u724c",
          "short": "https://link.dentco.tw/9d92cce5"
        },
        "add_messenger": {
          "original": "https://m.me/1892999954082106?ref=%7B%22source%22%3A%22IDCGniPB%22%7D",
          "short": "https://link.dentco.tw/7fb9f644"
        }
      }
    })
  }
  const url = apiUrl('super-link')
  return axios.get(url, {params})
}

export function readNoticeAPI(data) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('notice', 'read')
  return axios.post(url, data)
}

export function readAllNoticeAPI(data) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('notice', 'read-all')
  return axios.post(url, data)
}

export function sendSingleMessageAPI(data) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('reserves', 'send_message')
  return axios.post(url, data)
}

export function sendSingleSMSMessageAPI(data) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('sms', 'send_message')
  return axios.post(url, data)
}

export function sendReserveRemindAPI(data) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('reserves', 'remind')
  return axios.post(url, data)
}

export function sendReserveSurveyAPI(data) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('reserves', 'remind_survey')
  return axios.post(url, data)
}

export function sendReserveHealthEducationAPI(data) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('reserves', 'send_health')
  return axios.post(url, data)
}

export function createTelemedicineAPI(data) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('reserves', 'google_meet')
  return axios.post(url, data)
}

export function updateTelemedicineAPI(data) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('reserves', 'google_meet')
  return axios.put(url, data)
}

export function remindTelemedicineAPI(data) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('reserves', 'remind_google_meet')
  return axios.post(url, data)
}

export function getCareAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "paginate": {
          "page": "1",
          "limit": "20",
          "page_total": "1",
          "total": "2"
        },
        "data": [
          {
            "id": "22",
            "name": "蔡有人",
            "phone": "0922099195",
            "start_time": "2022-08-26 18:55",
            "doctor_name": "王醫師",
            "note": "test",  //約診備註
            "create_time": "2022-09-01 17:22",
            "content": "補牙崩落",  //病患不舒服內容
            "clinic_call": "1",   //0:不回電,1:請回電
            "care_note": "已回電",  //關懷備註
            "reply_status": "0"  //0:未回覆,1:已回覆
          }
        ]
      }
    })
  }
  const url = apiUrl('care')
  return axios.get(url, {params})
}

export function updateCareAPI(id, data) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('care', id)
  return axios.put(url, data)
}

export function accountSettingAPI(data) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('account', 'setting')
  return axios.post(url, data)
}

export function getMenuServiceItemAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: [
        {
          "id": "1",
          "img_url": "https://i.imgur.com/jzJskVm.png",
          "category": "預設項目",
          "title": "植牙療程",
          "intro": "植牙是把身體能接受的鈦金屬牙根植入骨頭，作為支撐假牙的根基"
        },
        {
          "id": "2",
          "img_url": "https://i.imgur.com/jzJskVm.png",
          "category": "預設項目",
          "title": "植牙療程",
          "intro": "植牙是把身體能接受的鈦金屬牙根植入骨頭，作為支撐假牙的根基"
        }
      ]
    })
  }
  const url = apiUrl('menu/service_item')
  return axios.get(url, {params})
}

export function getMenuEquipmentAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: [
        {
          "id": "19",
          "img_url": "https://i.imgur.com/4iVswq9.jpg",
          "category": "預設項目",
          "title": "器械消毒鍋  ",
          "intro": "高溫高壓蒸氣滅菌鍋，確保器械安全性"
        },
        {
          "id": "20",
          "img_url": "https://i.imgur.com/4iVswq9.jpg",
          "category": "預設項目",
          "title": "超音波洗淨機",
          "intro": "去除器械上細微死角的髒污"
        }
      ]
    })
  }
  const url = apiUrl('menu/equipment')
  return axios.get(url, {params})
}

export function getMenuEquipmentSpaceAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: [
        {
          "id": "1",
          "img_url": "https://i.imgur.com/RaNABbj.jpg",
          "category": "預設項目",
          "title": "消毒室（消毒供應空間）",
          "intro": "高規格消毒器械，感控流程做到最好"
        },
        {
          "id": "2",
          "img_url": "https://i.imgur.com/RaNABbj.jpg",
          "category": "預設項目",
          "title": "Ｘ光室（醫事放射空間）",
          "intro": "安全X光照射，完整了解口腔狀況"
        }
      ]
    })
  }
  const url = apiUrl('menu/equipment_space')
  return axios.get(url, {params})
}

export function getClinicEquipmentAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: [
        {
          "id": "19",
          "title": "器械消毒鍋",
          "intro": "高溫高壓蒸氣滅菌鍋，確保器械安全性"
        },
        {
          "id": "20",
          "title": "超音波洗淨機",
          "intro": "去除器械上細微死角的髒污"
        }
      ]
    })
  }
  const url = apiUrl('clinic/equipment')
  return axios.get(url, {params})
}

export function getClinicEquipmentSpaceAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: [
        {
          "id": "1",
          "title": "消毒室（消毒供應空間）",
          "intro": "高規格消毒器械，感控流程做到最好"
        },
        {
          "id": "2",
          "title": "Ｘ光室（醫事放射空間）",
          "intro": "安全X光照射，完整了解口腔狀況"
        }
      ]
    })
  }
  const url = apiUrl('clinic/equipment_space')
  return axios.get(url, {params})
}

export function getDentcoIconsAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: [
        {
          "id": 1,
          "path": "dental_icon_other.png",
          "description": "其他",
          "full_path": "https://cdn.dentco.tw/asset/dentco_icon/dental_icon_other.png"
        }
      ]
    })
  }
  const url = apiUrl('dental-icon')
  return axios.get(url, {params})
}

export function getClinicServiceItemAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: [
        {
          "id": "1",
          "title": "植牙療程",
          "intro": "植牙是把身體能接受的鈦金屬牙根植入骨頭，作為支撐假牙的根基"
        },
        {
          "id": "2",
          "title": "植牙療程",
          "intro": "植牙是把身體能接受的鈦金屬牙根植入骨頭，作為支撐假牙的根基"
        }
      ]
    })
  }
  const url = apiUrl('clinic/service_item')
  return axios.get(url, {params})
}

export function saveClinicServiceItemAPI(data) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('clinic', 'setting_service_item')
  return axios.post(url, data)
}

export function saveClinicEquipmentSpaceAPI(data) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('clinic', 'setting_equipment_space')
  return axios.post(url, data)
}

export function saveClinicEquipmentAPI(data) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('clinic', 'setting_equipment')
  return axios.post(url, data)
}

export function getClinicBaseInfoAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: {}
    })
  }
  const url = apiUrl('clinic', 'info')
  return axios.get(url, {params})
}

export function sendReserveCareAPI(data) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('reserves', 'remind_care')
  return axios.post(url, data)
}

export function sendSmsReserveCareAPI(data) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('sms', 'remind_care')
  return axios.post(url, data)
}

export function sendSmsReserveSurveyAPI(data) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('sms', 'remind_survey')
  return axios.post(url, data)
}

export function getColorAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: [
        {
          "id": "1",
          "color_code": "#000000"
        },
        {
          "id": "2",
          "color_code": "#111111"
        }
      ]
    })
  }
  const url = apiUrl('colors', 'list')
  return axios.get(url, {params})
}

export function updateDoctorColorAPI(data) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('doctor', 'color')
  return axios.post(url, data)
}

export function updateClinicColorAPI(data) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('clinic', 'color')
  return axios.post(url, data)
}

export function getReserveTagAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: [
        {
          "tag_id": "3",
          "content": "收取100費用"
        },
        {
          "tag_id": "4",
          "content": "講台語"
        },
        {
          "tag_id": "5",
          "content": "龜毛"
        }
      ]
    })
  }
  const url = apiUrl('tag', 'reserve_tag')
  return axios.get(url, {params})
}

export function updateReserveTagAPI(data) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('tag', 'reserve_tag')
  return axios.post(url, data)
}

export function getClinicQuestionActiveAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "question_active": 1
      }
    })
  }
  const url = apiUrl('clinic', 'question_active')
  return axios.get(url, {params})
}

export function updateClinicQuestionActiveAPI(data) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('clinic', 'question_active')
  return axios.put(url, data)
}

export function getDoctorQuestionActiveAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "question_active": 1
      }
    })
  }
  const url = apiUrl('doctor', 'question_active')
  return axios.get(url, {params})
}

export function updateDoctorQuestionActiveAPI(data) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('doctor', 'question_active')
  return axios.put(url, data)
}

export function getMedicalCategoryAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: [
        {
          "tag_id": "3",
          "content": "收取100費用"
        },
        {
          "tag_id": "4",
          "content": "講台語"
        },
        {
          "tag_id": "5",
          "content": "龜毛"
        }
      ]
    })
  }
  const url = apiUrl('medical', 'list')
  return axios.get(url, {params})
}

export function getHealthEducationAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: [
        {
          "id": "5",
          "keyword": "IMB",
          "text_content": "測試\n換行",
          "json_content": "",
          "lang": "zh",
          "create_time": "2023-06-06 16:55:25",
          "update_time": "2023-06-07 10:49:49"
        }
      ]
    })
  }
  const url = apiUrl('health')
  return axios.get(url, {params})
}

export function createHealthEducationAPI(data) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('health')
  return axios.post(url, data)
}

export function updateHealthEducationAPI(id, data) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('health', id)
  return axios.post(url, data)
}

export function deleteHealthEducationAPI(id, params) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('health', id)
  return axios.delete(url, {params})
}


export function getDentcoHealthEducationAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: [
        {
          "id": "8",
          "keyword": "TTT",
          "text_content": "TTT公版",
          "json_content": "",
          "lang": "zh",
          "create_time": "2023-07-21 10:02:48",
          "update_time": ""
        }
      ]
    })
  }
  const url = apiUrl('health', 'common')
  return axios.get(url, {params})
}

export function getOpenaiConversationAPI(params) {
  if (useMock) {
    return Promise.resolve({
      "current_page": 1,
      "data": [
        {
          "id": 1,
          "system": "1",
          "clinic_code": null,
          "doctor_code": "0UeuIW4r3E04",
          "user_id": "U461e45a0f7e1dfc175ab863a8735d1e0",
          "user_message": "\u77ef\u6b63\u6709\u54ea\u4e9b",
          "openai_message": "\u7259\u9f52\u77ef\u6b63\u4e3b\u8981\u6709\u4ee5\u4e0b\u5e7e\u7a2e\u65b9\u5f0f\uff1a\u50b3\u7d71\u91d1\u5c6c\u7259\u5957\uff0c\u9676\u74f7\u900f\u660e\u7259\u5957\uff0c\u96b1\u5f62\u77ef\u6b63\uff0cInvisalign\uff08\u82f1\u6587\uff0c\u4e5f\u5c31\u662f\u900f\u660e\u7259\u9f52\u77ef\u6b63\u5668\uff09\uff0c\u9f52\u79d1\u690d\u7259\u7b49\u3002\u5177\u9ad4\u7684\u77ef\u6b63\u65b9\u5f0f\u9700\u8981\u4f9d\u64da\u75c5\u4eba\u7684\u7259\u9f52\u72c0\u6cc1\u548c\u9700\u6c42\u7531\u7259\u79d1\u91ab\u751f\u505a\u51fa\u6700\u5408\u9069\u7684\u5efa\u8b70\u3002",
          "created_at": "2023-07-17T02:46:13.000000Z",
          "updated_at": "2023-07-17T02:46:13.000000Z",
          "name": "\u5f35\u4fca\u9686",
          "profile": "https://sprofile.line-scdn.net/0hwSBWBwR5KHxvLgT-9CNWAx9-KxZMX3FuRUlnSVMtJhxWGm4iSx0wT1gqcB8AS2l5RUkzSVl7cU5jPV8acXjUSGgedktWGW4qRE5jkg",
          "user_agree": {
            "id": 861766,
            "channel_id": "1656016759",
            "line_bot_id": "U461e45a0f7e1dfc175ab863a8735d1e0",
            "is_follower": "1",
            "agree": "1",
            "line_bot_verification_code": null,
            "line_bot_is_verified": "1",
            "input_phone": "0985617117",
            "last_sms_time": "0",
            "sms_times": "0",
            "last_send_treament": null,
            "current_clinic": null,
            "current_doctor": null,
            "phone_change": "",
            "phone_change_patient_code": "",
            "phone_change_status": "0",
            "register_time": "2023-05-31 14:53:34",
            "update_time": "2023-05-31 14:53:34",
            "user_email": "qqjameqq1@gmail.com",
            "user_name": "\u5f35\u4fca\u9686",
            "user_nickname": "\u5f35\u4fca\u9686",
            "user_photo": "https://sprofile.line-scdn.net/0hwSBWBwR5KHxvLgT-9CNWAx9-KxZMX3FuRUlnSVMtJhxWGm4iSx0wT1gqcB8AS2l5RUkzSVl7cU5jPV8acXjUSGgedktWGW4qRE5jkg",
            "user_lang": "zh",
            "channel_richmenu_id": null,
            "line_bot_is_verified_icon": "/images/no_line.png"
          }
        }
      ],
      "first_page_url": "http://crm-tw.dentco.local/api/openai/conversation/log?page=1",
      "from": 1,
      "last_page": 1,
      "last_page_url": "http://crm-tw.dentco.local/api/openai/conversation/log?page=1",
      "links": [
        {
          "url": null,
          "label": "pagination.previous",
          "active": false
        },
        {
          "url": "http://crm-tw.dentco.local/api/openai/conversation/log?page=1",
          "label": "1",
          "active": true
        },
        {
          "url": null,
          "label": "pagination.next",
          "active": false
        }
      ],
      "next_page_url": null,
      "path": "http://crm-tw.dentco.local/api/openai/conversation/log",
      "per_page": 10,
      "prev_page_url": null,
      "to": 1,
      "total": 1
    })
  }
  const url = apiUrl('openai/conversation/log')
  return axios.get(url, {params})
}

export function getMessageSettingsAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "survey_type": "1",  // 設定在 clinic，實名優先 or google優先(診所登入才有)
        "mmm_verify": "0",
        "mmm_remind_child": "0",
        "mmm_remind_aldult": "1",
        "setting_data": [
          {
            "remind_setting": "159",
            "clinic_name": "Dent&Co牙醫診所",
            "doctor_name": "ClearCorrect",
            "reserve_day0": "1",  // 當天
            "reserve_day1": "0",  // 一天前
            "reserve_day2": "1",  // 兩天前
            "reserve_day0_time": "08:00",  // 當天
            "reserve_day1_time": "11:00",  // 一天前
            "reserve_day2_time": "12:00",  // 兩天前
            "treatment": "0",  // 是否開啟看診前15分鐘自動衛教
            "care": "0",  // 是否開啟看診後術後關懷
            "survey": "1",  // 是否開啟看診後評價邀請
            "half_year": "1" // 是否開啟半年定檢提醒(半年定檢排程需要判斷這個開關)
          },
          {
            "remind_setting_id": "321",
            "clinic_name": "Dent&Co牙醫診所",
            "doctor_name": "王醫師",
            "reserve_day0": "1",
            "reserve_day1": "0",
            "reserve_day2": "1",
            "reserve_day0_time": "08:00",
            "reserve_day1_time": "11:00",
            "reserve_day2_time": "12:00",
            "treatment": "0",
            "care": "0",
            "survey": "1",
            "half_year": "1"
          }
        ],
        "own_channel": true, //有沒有小OA
        "channel_id": "1655684989"
      }
    })
  }
  const url = apiUrl('message', 'line_setting')
  return axios.get(url, {params})
}

export function updateMessageSettingsAPI(data) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('message', 'line_setting')
  return axios.post(url, data)
}

export function getLineMessageGroupLogsAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "paginate": {
          "page": "1",
          "limit": "1",
          "page_total": "2",
          "total": "2"
        },
        "data": [
          {
            "create_time": "2021-10-31 14:15",
            "content": "?1/1(五) 元旦休診一天！\r\n\r\n冷冷冬意，為大家送上暖心祝福～\r\n預㊗️大家2021年～新年快樂?\r\n　\r\n開心跨年後，若有口腔問題，歡迎儘早預約檢查治療，準備迎接農曆年，好好享受美食與家人歡聚時刻～\r\n\r\n?新的一年，英倫牙醫繼續為你守護牙齒健康！\r\n　\r\n#休診公告\r\n#0101全天休診",
            "image_url": "https://dentco.s3.us-east-2.amazonaws.com/line/5fed6b9e12ccc.png",
            "patient_name": "吳薏凡"
          }
        ]
      }
    })
  }
  const url = apiUrl('message', 'group_log')
  return axios.get(url, {params})
}

export function getLineMessageRemindLogsAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "paginate": {
          "page": "1",
          "limit": "1",
          "page_total": "9",
          "total": "9"
        },
        "data": [
          {
            "create_time": "2023-06-15 10:12",
            "content": "蔡友仁您好，提醒您與 王醫師醫師 的約診時間為 2023-06-17 10:00 於 Dent&Co牙醫診所。\n備註：\n若有以下症狀請配合改約\r\n*發燒/感冒/咳嗽/呼吸道症狀或他國入境，未達14天居家檢疫者無法進行牙科看診。\r\n\r\n若有＊看診陪同者＊，當日亦需攜帶健保卡",
            "patient_name": "蔡友仁"
          }
        ]
      }
    })
  }
  const url = apiUrl('message', 'remind_log')
  return axios.get(url, {params})
}

export function downloadLineMessageGroupDetailLogsAPI(log_no, params) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('message', 'download', 'group_log', log_no)
  return axios.get(url, {params, responseType: 'blob'})
}

export function downloadLineMessageGroupLogsAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('message', 'download', 'group_log')
  return axios.get(url, {params})
}

export function downloadLineMessageRemindLogsAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('message', 'download', 'remind_log')
  return axios.get(url, {params})
}

export function getVoiceSettingsAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: [
        {
          "remind_cht_voice_setting_id": "1",
          "doctor_in_clinic_id": "252",
          "clinic_code": "0000000002",
          "doctor_code": "A2hNxGnD6wV0",
          "clinic_name": "Dent&Co牙醫診所",
          "doctor_name": "ClearCorrect",
          "reserve_day0": "0",
          "reserve_day1": "1",
          "reserve_day2": "0",
          "reserve_day0_time": "08:00",
          "reserve_day1_time": "11:00",
          "reserve_day2_time": "12:00",
          "half_year": "0"
        }
      ]
    })
  }
  const url = apiUrl('cht-voice', 'setting')
  return axios.get(url, {params})
}

export function updateVoiceSettingsAPI(data) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('cht-voice', 'setting')
  return axios.post(url, data)
}

export function getVoiceRemindLogsAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "paginate": {
          "page": "1",
          "limit": "10",
          "page_total": "1",
          "total": "2"
        },
        "data": [
          {
            "phone": "0922099195",
            "content": "測試",
            "create_time": "2023-07-18 11:42",
            "status": "已接通",
            "point": "2"
          },
          {
            "phone": "0922099195",
            "content": "測試",
            "create_time": "2023-07-18 11:42",
            "status": "未接通",
            "point": "0"
          }
        ]
      }
    })
  }

  const url = apiUrl('cht-voice', 'log')
  return axios.get(url, {params})
}

export function downloadVoiceRemindLogsAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('cht-voice', 'download')
  return axios.get(url, {params})
}

export function getSmsSettingsAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "survey_type": "2",
        "setting_data": [
          {
            "sms_setting_id": 4580,
            "doctor_in_clinic_id": 3802,
            "clinic_code": "0000000088",
            "doctor_code": "cN46sm5E0TP3",
            "clinic_name": "Dent&Co Clinic",
            "doctor_name": "Dr. Meng Jung",
            "reserve_type": "1",
            "reserve_day0": "0",
            "reserve_day1": "0",
            "reserve_day2": "0",
            "reserve_day0_time": "08:00",
            "reserve_day1_time": "12:00",
            "reserve_day2_time": "12:00",
            "survey": "0",
            "half_year": "0",
            "quarter_year": "0",
            "three_six_month": "0",
            "reserve_fri_send_mon": "0",
            "care": "0",
            "reserve_create": "0",
            "reserve_update": "0",
            "reserve_cancel": "0",
          }
        ]
      }
    })
  }
  const url = apiUrl('message', 'sms_setting')
  return axios.get(url, {params})
}

export function updateSmsSettingsAPI(data) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('message', 'sms_setting')
  return axios.post(url, data)
}

export function getSmsRemindLogsAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "paginate": {
          "page": "1",
          "limit": "10",
          "page_total": "1",
          "total": "2"
        },
        "data": [
          {
            "create_time": "2023-07-18 11:42",
            "content": "測試",
            "phone": "0922099195",
            "point": "2"
          },
          {
            "create_time": "2023-07-18 11:40",
            "content": "測試簡訊",
            "phone": "0922099195",
            "point": "1"
          }
        ]
      }
    })
  }
  const url = apiUrl('sms', 'log')
  return axios.get(url, {params})
}

export function downloadSmsRemindLogsAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('sms', 'download')
  return axios.get(url, {params})
}

export function getSmsStoreLogsAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: [
        {
          "create_time": "2023-07-18 11:33",
          "type": "贈送",
          "quota": "500"
        },
        {
          "create_time": "2023-07-18 11:49",
          "type": "加值",
          "quota": "1000"
        }
      ]
    })
  }
  const url = apiUrl('sms', 'list')
  return axios.get(url, {params})
}

export function getSmsQuotaAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "quota": "1497"
      }
    })
  }
  const url = apiUrl('sms', 'quota')
  return axios.get(url, {params})
}

export function getSmsStatus(params) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "sms_status": "1"
      }
    })
  }
  const url = apiUrl('sms', 'status')
  return axios.get(url, {params})
}

export function sendLineHalfYearRemindAPI(data) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('reserves', 'remind_half_year')
  return axios.post(url, data)
}

export function sendSmsHalfYearRemindAPI(data) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('sms', 'send_half_year')
  return axios.post(url, data)
}

export function sendSmsReserveRemindAPI(data) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('sms', 'send_reserve')
  return axios.post(url, data)
}

export function sendCallReserveRemindAPI(data) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('cht-voice', 'send_reserve')
  return axios.post(url, data)
}

export function getCallStatus(params) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "sms_status": "1"
      }
    })
  }
  const url = apiUrl('cht-voice', 'status')
  return axios.get(url, {params})
}

export function sendCallHalfYearRemindAPI(data) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('cht-voice', 'send_half_year')
  return axios.post(url, data)
}


export function getAIVersionsAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "status": "success",
        "data": {
          "bots": [
            {
              "id": 1,
              "image_url": "initialize_ai.png",
              "name": "初始化AI",
              "description": "無任何訓練資料，需要從0訓練"
            },
            {
              "id": 2,
              "image_url": "dentco_ai.png",
              "name": "小幫手AI助理",
              "description": "已訓練好的診所櫃檯助理，可以回答牙科相關專業問題"
            },
            {
              "id": 3,
              "image_url": "custom_ai.png",
              "name": "客製化AI",
              "description": "請洽<a href=\"https://lin.ee/RYOoUcr\" target=\"_blank\" class=\"link_blue--text\">客服協助</a>"
            }
          ]
        }
      }
    })
  }
  const url = aiApiUrl('default-bots')
  return axios.get(url, {params})
}

export function changeAIVersionsAPI(medicalCode, data) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = aiApiUrl('medicals', medicalCode, 'default-bot')
  return axios.post(url, data)
}

export function getCurrentMedicalAIInfoAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "status": "success",
        "data": {
          "medical": {
            "id": "5d0386dd-a0ac-451a-a388-b781c34afec3",
            "foreign_medical_type": "clinic",
            "foreign_medical_id": "0000000088",
            "customer_support_prompt_id": 9,
            "default_bot_id": 1
          }
        }
      }
    })
  }
  const url = aiApiUrl('medicals', 'morph')
  return axios.get(url, {params})
}

export function getAIDataPacksAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "data": [
          {
            id: 1,
            name: '隱適美資料包',
            image_url: 'https://cdn.dentco.tw/ai/friendly_warm_ai.png',
            description: '123'
          },
          {
            id: 2,
            name: '測試用資料包',
            image_url: 'https://cdn.dentco.tw/ai/friendly_warm_ai.png',
            description: '223'
          }
        ]
      }
    })
  }
  const url = aiApiUrl('packages')
  return axios.get(url, {params})
}

export function getUseAIDataPacksAPI(medicalCode) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "status": "success",
        "data": {
          "packages": [
            {
              "id": 1,
              "name": "隱適美資料包"
            },
            {
              "id": 2,
              "name": "測試用資料包"
            }
          ]
        }
      }
    })
  }
  const url = aiApiUrl('medicals', medicalCode, 'packages')
  return axios.get(url, {})
}

export function changeUseAIDataPacksAPI(medicalCode, data) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = aiApiUrl('medicals', medicalCode, 'packages')
  return axios.patch(url, data)
}

export function listAIQuestionAnswerAPI(medicalCode, params) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "status": "success",
        "data": {
          "data": [
            {
              "id": 2,
              "question": "有推薦吃什麼東西嗎？",
              "answer": "我推薦吃滷肉飯"
            },
            {
              "id": 3,
              "question": "請問今天天氣如何呢？",
              "answer": "今天天氣非常悶熱"
            },
            {
              "id": 4,
              "question": "這是問題",
              "answer": "這是答案"
            }
          ],
          "links": {
            "first": "http://localhost/api/medicals/5d0386dd-a0ac-451a-a388-b781c34afec3/faqs?page=1",
            "last": "http://localhost/api/medicals/5d0386dd-a0ac-451a-a388-b781c34afec3/faqs?page=1",
            "prev": null,
            "next": null
          },
          "meta": {
            "current_page": 1,
            "from": 1,
            "last_page": 1,
            "links": [
              {
                "url": null,
                "label": "&laquo; Previous",
                "active": false
              },
              {
                "url": "http://localhost/api/medicals/5d0386dd-a0ac-451a-a388-b781c34afec3/faqs?page=1",
                "label": "1",
                "active": true
              },
              {
                "url": null,
                "label": "Next &raquo;",
                "active": false
              }
            ],
            "path": "http://localhost/api/medicals/5d0386dd-a0ac-451a-a388-b781c34afec3/faqs",
            "per_page": 15,
            "to": 3,
            "total": 3
          }
        }
      }
    })
  }
  const url = aiApiUrl('medicals', medicalCode, 'faqs')
  return axios.get(url, {params})
}

export function createAIQuestionAnswerAPI(medicalCode, data) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = aiApiUrl('medicals', medicalCode, 'faqs')
  return axios.post(url, data)
}

export function updateAIQuestionAnswerAPI(id, data) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = aiApiUrl('faqs', id)
  return axios.patch(url, data)
}

export function deleteAIQuestionAnswerAPI(id) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = aiApiUrl('faqs', id)
  return axios.delete(url)
}

export function listAIQuestionAnswerHistoryAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data:  {
        "status": "success",
        "data": {
          "data": [
            {
              "medical_type": "clinic",
              "medical_id": "0000000088",
              "channel_type": "line",
              "user_id": "test",
              "user_profile": null,
              "question": "美白牙齒會痛嗎?",
              "answer": "美白牙齒通常不會引起疼痛。在治療過程中，可能會感到一些敏感或不適，但這是暫時的。如果有任何不適，請向牙醫尋求建議。",
              "created_at": "2023-08-19 09:54:29"
            },
            {
              "medical_type": "clinic",
              "medical_id": "0000000088",
              "channel_type": "messenger",
              "user_id": "8922095971195291",
              "user_profile": {
                "name": "EJ",
                "image_url": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=8922095971195291&width=1024&ext=1685609093&hash=AeReJr5TNw-Fl11HrrQ"
              },
              "question": "請問今天天氣如何？",
              "answer": "非常抱歉，我們是一家牙科診所，無法提供天氣資訊。如果您有任何牙科問題，我們很樂意為您解答。",
              "created_at": "2023-08-16 09:54:08"
            }
          ],
          "links": {
            "first": "http://localhost/api/conversation-logs?page=1",
            "last": "http://localhost/api/conversation-logs?page=1",
            "prev": null,
            "next": null
          },
          "meta": {
            "current_page": 1,
            "from": 1,
            "last_page": 1,
            "links": [
              {
                "url": null,
                "label": "&laquo; Previous",
                "active": false
              },
              {
                "url": "http://localhost/api/conversation-logs?page=1",
                "label": "1",
                "active": true
              },
              {
                "url": null,
                "label": "Next &raquo;",
                "active": false
              }
            ],
            "path": "http://localhost/api/conversation-logs",
            "per_page": 15,
            "to": 2,
            "total": 2
          }
        }
      }
    })
  }
  const url = aiApiUrl('conversation-logs')
  return axios.get(url, {params})
}

export function downloadAIQuestionAnswerHistoryAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = aiApiUrl('conversation-logs', 'download')
  return axios.post(url, {}, {params})
}

export function getInfoAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "name": "Dent&Co牙醫診所",
        "code": "0000000002",
        "type": 1,
        "plan": "clinic_base",
        "permission_name": [
          "course_certified",
          "base_survey",
          // "line_appointment"
        ],
        "doctors": [
          {
            "name": "測試診所二號",
            "code": "heYCsLUF2PBH",
            "plan": "doctor_adv",
            "permission_name": [
              "line_appointment",
              "base_survey",
              "web_appointment"
            ]
          },
          {
            "name": "測試醫師2號",
            "code": "yl937eDOTbem",
            "plan": "doctor_base",
            "permission_name": [
              // "line_appointment",
              "base_survey"
            ]
          }
        ]
      }
    })
  }
  const url = apiUrl('info')
  return axios.get(url, {params})
}

export function listClinicsAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: []
    })
  }
  const url = apiUrl('clinic-lists')
  return axios.get(url, {params})
}

export function listDoctorsAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: []
    })
  }
  const url = apiUrl('doctor-lists')
  return axios.get(url, {params})
}

export function switchAccountAPI(data) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('switch_account')
  return axios.post(url, data)
}

export function getFreeTimeModeAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "line_appointment_status": 0,
        "web_appointment_status": 0,
        "google_map_appointment_status": 0,
        "use_manual_free_time":false
      }
    })
  }
  const url = apiUrl('freetime-mode')
  return axios.get(url, {params})
}

export function updateFreeTimeModeAPI(data) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('freetime-mode')
  return axios.put(url, data)
}

export function getFreeTimeDetailAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "clinic_channel": "1657424851",
        "clinic_line_page": "https://www.facebook.com/",
        "doctor_channel": "1657558159",
        "doctor_line_page": "https://doctor.dentco.tw/",
        // ...
      }
    })
  }
  const url = apiUrl('freetime-detail')
  return axios.get(url, {params})
}

export function updateFreeTimeDetailAPI(data) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('freetime-detail')
  return axios.put(url, data)
}

export function getManualSettingAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "weekly": [],
        "closed": [],
        "single": []
      }
    })
  }
  const url = apiUrl('manual-freetime-setting')
  return axios.get(url, {params})
}

export function updateManualSettingAPI(data) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('manual-freetime-setting')
  return axios.put(url, data)
}

export function getLastDate(params) {
  if (useMock) {
    return Promise.resolve({
      data: {}
    })
  }
  const url = apiUrl('manual-freetime-month-count')
  return axios.get(url, {params})
}

export function getChannelQuotaAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: {}
    })
  }
  const url = apiUrl('channel', 'quota')
  return axios.get(url, {params})
}

export function getTreatmentListAPI() {
  if (useMock) {
    return Promise.resolve({
      data: {}
    })
  }
  const url = apiUrl('www-appointment-items')
  return axios.get(url)
}

export function getDoctorSearchTreatmentSettingAPI(doctorCode, params) {
  if (useMock) {
    return Promise.resolve({
      data: {}
    })
  }
  const url = apiUrl('www-appointment-items', doctorCode)
  return axios.get(url, {params})
}

export function updateDoctorSearchTreatmentSettingAPI(data) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('www-appointment-items')
  return axios.put(url, data)
}

export function getSelectTreatmentAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: {}
    })
  }
  const url = apiUrl('dic-appointment-items')
  return axios.get(url, {params})
}

export function updateSelectTreatmentAPI(data) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('dic-appointment-items')
  return axios.put(url, data)
}

export function getOnlineTreatmentAlertAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: {}
    })
  }
  const url = apiUrl('dic-need-setup-treatment')
  return axios.get(url, {params})
}

export function getSearchEngineOnlineTreatmentAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: {}
    })
  }
  const url = apiUrl('dic-search-engine-treatment')
  return axios.get(url, {params})
}

export function updateSearchEngineOnlineTreatmentAPI(data) {
  if (useMock) {
    return Promise.resolve({
      data: {}
    })
  }
  const url = apiUrl('dic-search-engine-treatment')
  return axios.put(url, data)
}
export function getExtensionAIPromptSettingAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "id": "1",
        "clinic_code": "0000000088",
        "doctor_code": null,
        "style_prompt": "溫暖親切",
        "use_emoji_prompt": "1",
        "seo_prompt": "超猛診所,診所一桶金",
        "use_seo": "0",
        "ai_generate_mode": "1",
        "created_at": "2024-11-12 13:19:16",
        "updated_at": null
      }
    })
  }
  const url = apiUrl('extension-ai-prompt', 'setting')
  return axios.get(url, {params})
}

export function updateExtensionAIPromptSettingAPI(data) {
  const url = apiUrl('extension-ai-prompt', 'setting')
  return axios.put(url, data)

}

export function getUnreadNegativeReviewAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: [
        {
          "id": "516192",
          "clinic_code": "0000000088",
          "doctor_code": "2f8Dcapm",
          "created_at": "2023-02-16 08:34:33",
          "patient_code": "jHEguvAe",
          "patient_name": "李孟蓉",
          "clinic_name": "Dent&Co Clinic",
          "doctor_name": "沐傾",
          "doctor_title": "通用的title",
          "min_score": "3"
        }
      ]
    })
  }
  const url = apiUrl('reviews', 'unread_negative_review')
  return axios.get(url, {params})
}

export function generateAiResponseAPI(data) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('reviews', 'ai-generate')
  return axios.post(url, data)
}

export function generatePastAiResponseAPI(data) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('reviews', 'ai-regenerate')
  return axios.post(url, data)
}

export function submitPastAiResponseAPI(data) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('reviews', 'ai-response-to-response')
  return axios.post(url, data)
}

export function getDoctorHealthEduCategoryAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: [
        {
          "id": 1,
          "doctor_code": "0UeuIW4r3E04",
          "name": "分類1",
          "sort": "1",
          "created_at": "2024-12-18 07:51:35"
        }
      ]
    })
  }
  const url = apiUrl('doctor-health-education-category')
  return axios.get(url, {params})
}

export function createDoctorHealthEduCategoryAPI(data) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('doctor-health-education-category')
  return axios.post(url, data)
}

export function getDoctorHealthEduPostsAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: [
        {
          "id": 1,
          "doctor_code": "0UeuIW4r3E04",
          "title": "文章1",
          "content": "文章1",
          "gallery": [
            "https://cdn.dentco.tw/clinic/639c3d5c48062.jpeg",
            "https://cdn.dentco.tw/clinic/639c3d5c48062.jpeg"
          ],
          "created_at": "2024-12-19 09:43:55"
        }
      ]
    })
  }
  const url = apiUrl('doctor-health-education-post')
  return axios.get(url, {params})
}

export function createDoctorHealthEduPostAPI(data) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('doctor-health-education-post')
  return axios.post(url, data)
}

export function updateDoctorHealthEduPostAPI(id, data) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('doctor-health-education-post', id)
  return axios.post(url, data)
}

export function deleteDoctorHealthEduPostAPI(id, params) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('doctor-health-education-post', id)
  return axios.delete(url, {params})
}

export function getRemindSettingCloseAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "clinic_code": null,
        "doctor_code": "0UeuIW4r3E04",
        "dates": [
          "2025-01-02",
          "2025-01-03"
        ]
      }
    })
  }
  const url = apiUrl('remind-setting-close')
  return axios.get(url, {params})
}

export function setRemindSettingCloseAPI(data) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('remind-setting-close')
  return axios.post(url, data)
}

export function getGoogleReviewsAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "paginate": {
          "page": "1",
          "limit": "10",
          "page_total": "1",
          "total": "2"
        },
        "data": [
          {
            "id": "7700",
            "name": "小David",
            "clinic_name": "Dent&Co牙醫診所1",
            "doctor_name": "測試醫師2號",
            "message": "",
            "response": "",
            "created_time": "2019-12-19 (四) 17:10:45",
            "star": {
              "看診醫師": 5,
              "跟診助理": 5,
              "櫃檯人員": 2,
              "環境設備": 4,
              "諮詢人員": 4
            },
            "recommend": [
              "醫師專業/治療後效果佳",
              "態度友善",
              "詳細解說/有耐心"
            ],
            "negative": [
              "櫃檯人員：收費不合理"
            ],
            "treatment": [
              "基礎治療",
              "牙周治療"
            ],
            "photo": [
              "https://cdn.dentco.tw/survey/2746173447816179390771232.jpg",
              "https://cdn.dentco.tw/survey/2577448701116179771812817.jpg"
            ]
          }
        ]
      }
    })
  }
  const url = apiUrl('reviews', 'google-api-list')
  return axios.get(url, {params})
}

export function getGoogleReviewOverviewAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "paginate": {
          "page": "1",
          "limit": "10",
          "page_total": "1",
          "total": "2"
        },
        "data": [

        ]
      }
    })
  }
  const url = apiUrl('reviews', 'google-api-info')
  return axios.get(url, {params})
}

export function getSMSMessagePatientAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "count": 499,
        "data": [
          {
            "patient_code": "1f72ilba",
            "phone": "0922099195",
            "name": "蔡有人",
            "sex": "1",
            "birthday": "",
            "file_name_photo": ""
          }
        ]
      }
    })
  }
  const url = apiUrl('sms_message', 'patient')
  return axios.get(url, {params})
}

export function sendSMSMessageAPI(data) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('sms_message')
  return axios.post(url, data)
}

export function getSMSMessageGroupLogAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "count": 499,
        "data": [
          {
            "patient_code": "1f72ilba",
            "phone": "0922099195",
            "name": "蔡有人",
            "sex": "1",
            "birthday": "",
            "file_name_photo": ""
          }
        ]
      }
    })
  }
  const url = apiUrl('sms_message', 'group_log')
  return axios.get(url, {params})
}

export function downloadSmsMessageGroupDetailLogsAPI(log_no, params) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('sms_message', 'download', 'group_log', log_no)
  return axios.get(url, {params, responseType: 'blob'})
}

export function downloadSmsMessageGroupLogsAPI(params) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('sms_message', 'download', 'group_log')
  return axios.get(url, {params})
}


export function uploadDrcooperAPI(data) {
  if (useMock) {
    return Promise.resolve({
      data: {
        "messages": "成功"
      }
    })
  }
  const url = apiUrl('excel', 'drcooper')
  const formData = new FormData()
  for (const [key, value] of Object.entries(data)) {
    formData.append(key, value)
  }

  return axios.post(url, formData)
}