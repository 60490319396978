import { render, staticRenderFns } from "./QuestionDetailCard.vue?vue&type=template&id=a3ce53fe&scoped=true"
import script from "./QuestionDetailCard.vue?vue&type=script&lang=js"
export * from "./QuestionDetailCard.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a3ce53fe",
  null
  
)

export default component.exports